// Dependencies
import React from 'react';


const ZoomOut = ({ width, height, color, orientation, bgColor}) => {

  return (

    <svg
      width={width ? width : '18px'}
      height={height ? height : '4px'}
      viewBox="0 0 18 4"
    >
<defs><clipPath id="1081055212095100010412145"><path d="M16.2,0 C17.1941125,0 18,0.8954305 18,2 C18,3.1045695 17.1941125,4 16.2,4 L1.8,4 C0.80588745,4 0,3.1045695 0,2 C0,0.8954305 0.80588745,0 1.8,0 L16.2,0 Z"></path></clipPath></defs><g clipPath="url(#1081055212095100010412145)"><polygon points="0,0 18,0 18,4 0,4 0,0" stroke="none" fill="#FFFFFF"></polygon></g>
    </svg>
  );

}

export default ZoomOut
