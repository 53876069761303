// Dependencies
import React from "react";
import { connect } from "react-redux";

// Actions
import { getBCValuations, destroyAsset } from "../../actions/balance";

// Components
import PieGraphic from "../../components/pieGraphic";
import ContentBlock from "../../components/contentBlock";
import TableBlock from "../../components/tableBlock";
import ActionSheet from "../../components/actionSheet";
import FloatButton from "../../components/floatButton";
import Icon from "../../components/icons";
import LoadingScreen from "../../components/loading";
import AlertModal from "../../components/alertModal";

// Helpers
import ActionSheetOnPressAction from "../../helpers/actionSheetOnPressAction";

class BalanceValuationsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pieColorsScale: [],
      loading: true,
      balance_valuations: [],
      options_action_sheet: [],
      showDeleteModal: false,
      url: "",
      method: "",
      showDeletedModal: false
    };

    this.actionSheet = React.createRef();
  }

  _actionSheetPressed(item) {
    let response = ActionSheetOnPressAction(
      item,
      this.props.balance_valuations,
      this.props,
      () => this._deleteAsset(item.url, item.method)
    );
    this.actionSheet.current.show();
    if (response.status) this.setState({ modalData: response.modalData });
  }

  _showActionSheetContent(state) {
    let that = this;
    let optionsActionSheet = [];
    state.balance_valuations.actions.map((item, i) => {
      return optionsActionSheet.push({
        type: "icon button",
        code: item.code,
        iconName: item.code.includes("add")
          ? "Close"
          : item.code.includes("edit")
          ? "Pencil"
          : item.code.includes("show")
          ? "Eye"
          : "Garbage",
        message: item.title,
        onClick: () => {
          this._actionSheetPressed(item);
        }
      });
    });

    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true
        }
      ]
    });

    setTimeout(function() {
      that.actionSheet.current.show();
    }, 100);
  }

  _deleteAsset(url, method) {
    this.setState({
      showDeleteModal: true,
      url: url,
      method: method
    });
  }

  _successDeleteAsset() {
    this.setState({showDeletedModal: true})
  }

  _setPieColorsScale() {
    let graphData =
      this.state.balance_valuations.graphs &&
      this.state.balance_valuations.graphs.map((graph, i) => graph);

    let colors = graphData[0].graph_data.data.map(
      (element, j) => element.color
    );

    this.setState({
      pieColorsScale: colors
    });
  }

  _success() {
    this.setState({
      loading: false,
      balance_valuations: this.props.balance_valuations
    });
    console.log(this.props.balance_valuations)
    this.state.balance_valuations.graphs && this._setPieColorsScale();
  }

  _error() {
    alert("error");
  }

  componentDidMount() {

    this._fetch();
    // this.props.history.location.state.last_location
     // this.props.setSpecialParent(this.props.history.location.state.last_breadcrumb)
     // this.props.getBreadcrumbs({
     //     url:
     //     this.props.history.location.pathname +
     //     this.props.history.location.search,
     //     assetUrl: this.props.history.location.pathname
     // });
     let new_bread=[]
     
     if(this.props.current_breadcrumb !== undefined && this.props.current_breadcrumb.length > 0){
       try{
        new_bread = this.props.current_breadcrumb
        new_bread.pop()
        
        let last_url = new_bread[new_bread.length - 1].url
        if(last_url == this.props.history.location.state.last_breadcrumb.url){
          new_bread.pop()   
        }
        
        new_bread.push(this.props.history.location.state.last_breadcrumb)  
        
        // new_bread.push({
        //      url:this.props.history.location.pathname + this.props.history.location.search,
        //      assetUrl: this.props.history.location.pathname
        //   })
        // 
        
       
       }catch(e){
        new_bread = this._getLocalStorageCurrentBreadcrumb()
       }
     }
     else{
      new_bread = this._getLocalStorageCurrentBreadcrumb()
     }
     this.props.setSpecialParent(new_bread)
     this.props.getBreadcrumbs({
           url:this.props.history.location.pathname +
           this.props.history.location.search,
           assetUrl: this.props.history.location.pathname
       });


  }

  _fetch() {
    this.props.getBCValuations(
      this.props.authentication_token,
      () => this._success(),
      () => this._error(),
      this.props.history.location.state.url,
      this.props.history.location.state.resourceable_id,
      this.props.history.location.state.resourceable_type
    );
  }


  _getLocalStorageCurrentBreadcrumb(){
      let current_breadcrumb_localstorage = localStorage.getItem("special_parent")
      if(current_breadcrumb_localstorage){
        
        try{
          current_breadcrumb_localstorage = JSON.parse(current_breadcrumb_localstorage)  
        }catch(e){
           current_breadcrumb_localstorage = []
        }
        
      }else{
        current_breadcrumb_localstorage = []
      }
    return  current_breadcrumb_localstorage  
  }  

  _selectedRow(element) {
    var that = this;
    let optionsActionSheet = [];
    if (element.edit) {
      optionsActionSheet.push({
        type: "icon button",
        iconName: "Pencil",
        message: "Edit",
        onClick: () => {
          that.props.history.push("/balance-edit", {
            form: element.form.inputs,
            resource_key: element.form.resource_key,
            url: element.url,
            method: "PUT",
            code: "valuation",
            date_minimum: this.props.location.state.date_minimum,
            go_back: true
          });
          that.actionSheet.current.show();
        }
      });
    }
    if (element.destroy) {
      optionsActionSheet.push({
        type: "icon button",
        iconName: "Garbage",
        message: "Delete",
        onClick: () => {
          this._deleteAsset(
            "balance_principal/valuations/" + element.id,
            "DELETE"
          );

          that.actionSheet.current.show();
        }
      });
    }
    if (optionsActionSheet.length > 0) {
      this.setState({
        options_action_sheet: [
          ...optionsActionSheet,
          {
            type: "noIcon button",
            message: this.props.internalization.buttons.cancel,
            close: true,
            danger: true
          }
        ]
      });
      setTimeout(function() {
        that.actionSheet.current.show();
      }, 100);
    }
  }

  render() {
    return (
      <div className="main-wrapper">
        {this.state.loading && <LoadingScreen  text={this.props.internalization.loading}  show={this.state.loading} />}
        {!this.state.loading &&
          Object.keys(this.state.balance_valuations).length > 0 && (
            <>
              <p className="headingH1" style={{ paddingVertical: 24 }}>
                {this.state.balance_valuations.title}
              </p>

              {this.state.balance_valuations.tables &&
                this.state.balance_valuations.tables.map((table, i) => {
                  return (
                    <div
                      key={"valuation_table"+table.rows.length + "_" +i}
                      className={
                        this.state.balance_valuations.tables.length !== i + 1
                          ? "wrapper-separator"
                          : "centrate"
                      }
                    >
                      {table.rows.length > 0 ? (
                        <TableBlock

                          data={table}
                          // onShow={true}
                          onSelect={element => {
                            this._selectedRow(element);
                          }}
                          history={this.props.history}
                        />
                      ) : (
                        <p>No data</p>
                      )}
                    </div>
                  );
                })}
              {this.state.balance_valuations.graphs !== undefined &&
                this.state.balance_valuations.graphs.length > 0 &&
                this.state.balance_valuations.graphs && (
                  <ContentBlock header={false}>
                    {this.state.balance_valuations.graphs &&
                      this.state.balance_valuations.graphs.map((graph, i) => {
                        return (
                          <div
                            key={i}
                            className={
                              this.state.balance_valuations.graphs.length !==
                              i + 1
                                ? "wrapper-separator"
                                : ""
                            }
                          >
                            {graph.graph_type === "pie" &&
                              graph.graph_data.data.length > 0 && (
                                <PieGraphic
                                  showTotal={false}
                                  data={graph.graph_data}
                                  innerRadius={90}
                                  centerLabel={true}
                                  colorScale={this.state.pieColorsScale}
                                  from="valuations"
                                />
                              )}
                          </div>
                        );
                      })}
                  </ContentBlock>
                )}
            </>
          )}

        {this.state.balance_valuations.actions !== undefined && (
          <>
            <ActionSheet
              ref={this.actionSheet}
              render={this.state.options_action_sheet}
            />
            <FloatButton
              children={
                <Icon
                  name="Close"
                  color="white"
                  iconBig={true}
                  className="wrapper-icon-floatButton"
                  close={false}
                />
              }
              action={() => this._showActionSheetContent(this.state)}
            />
          </>
        )}

        {this.state.showDeleteModal && (
          <AlertModal
            isShowed={this.state.showDeleteModal}
            title={this.props.internalization.modals.valuation.delete.title}
            msg={this.props.internalization.modals.valuation.delete.body}
            action={() => {
              this.props.destroyAsset(
                this.props.authentication_token,
                () => this._successDeleteAsset(this.state.url),
                () => alert("error"),
                this.state.url,
                this.state.method
              );
              this.setState({
                showDeleteModal: false
              });
            }}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={true}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={false}
            actionCancelButton={() => {
              this.setState({
                showDeleteModal: false
              });
            }}
          />
        )}
        {this.state.showdeletedmodal && (
          <AlertModal
            isShowed={this.state.showdeletedmodal}
            title={this.props.internalization.modals.valuation.deleted.title}
            msg={this.props.internalization.modals.valuation.deleted.body}
            action={() => {
              this._fetch();
              this.setState({
                showDeletedModal: false
              });
            }}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={true}
            actionCancelButton={() => {
              this.setState({
                showDeletedModal: false
              });
            }}
          />
        )}        
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    balance_valuations: state.balance.balance_valuations,
    authentication_token: state.session.authentication_token,
    current_breadcrumb: state.session.current_breadcrumb,
    internalization: state.session.internalization,    
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getBCValuations: (
      authentication_token,
      success,
      error,
      url,
      resourceable_id,
      resourceable_type
    ) =>
      dispatch(
        getBCValuations(
          authentication_token,
          success,
          error,
          url,
          resourceable_id,
          resourceable_type
        )
      ),
    destroyAsset: (authentication_token, success, error, url, method) =>
      dispatch(destroyAsset(authentication_token, success, error, url, method))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BalanceValuationsScreen);
