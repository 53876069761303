// Dependencies
import React from 'react';

const Close = ({ width, height, color, close }) => {

    return (

        <svg 
            width={ width ? width : '20px'}
            height={ height ? height : '21px'}
            viewBox="0 0 20 21"
            style={ close === false ? {transform:  'rotate(45deg)'}: {transform:  'rotate(0deg)'}}
        >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group" transform="translate(-1.000000, -0.000000)" fill={ color ? color : "#80abba" } fillRule="nonzero">
                    <path d="M-0.5,12 C-1.32842712,12 -2,11.3284271 -2,10.5 C-2,9.67157288 -1.32842712,9 -0.5,9 L22.5,9 C23.3284271,9 24,9.67157288 24,10.5 C24,11.3284271 23.3284271,12 22.5,12 L-0.5,12 Z" id="Icono" transform="translate(11.000000, 10.500000) rotate(-315.000000) translate(-11.000000, -10.500000) "></path>
                    <path d="M-0.5,12 C-1.32842712,12 -2,11.3284271 -2,10.5 C-2,9.67157288 -1.32842712,9 -0.5,9 L22.5,9 C23.3284271,9 24,9.67157288 24,10.5 C24,11.3284271 23.3284271,12 22.5,12 L-0.5,12 Z" id="Icono" transform="translate(11.000000, 10.500000) rotate(-45.000000) translate(-11.000000, -10.500000) "></path>
                </g>
            </g>
        </svg>
    );

}

export default Close;
