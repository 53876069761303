// Dependencies
import React from 'react';

const Star = ({ width, height, color }) => {

  return (

    <svg
      width={width ? width : '20px'}
      height={height ? height : '20px'}
      viewBox="0 0 20 20"
    >
      <g transform="translate(-8.000000, -8.000000)" stroke="none" strokeWidth="1" fillRule="evenodd" fill={color ? color : "#80abba"} >

        <polygon
          id="a"
          points="18.0146222 24.2705237 11.8252453 27.5244717 13.0073111 20.6324992 8 15.7515772 14.9199338 14.746052 18.0146222 8.47552826 21.1093107 14.746052 28.0292445 15.7515772 23.0219334 20.6324992 24.2039992 27.5244717"
        />
      </g>
    </svg>

  );

}

export default Star;
