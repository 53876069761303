// Dependencies
import React from "react";

// Components
import Modal from "../components/modal";
import Loading from "../components/loading";
import {max} from "moment";

function AlertModal({
  title,
  titleRegular,
  msg,
  msgBold,
  action,
  textButton,
  cancelButton,
  cancelTextButton,
  actionCancelButton,
  textButtonAlertColor,
  cancelButtonAlertColor,
  thirdButton,
  textButtonAlertColorThird,
  textButtonThird,
  actionThirdButton,
  isShowed,
  loading,
  size,
  maxHeight
}) {

  const removeBodyClass = () => {
    let body = document.body;
    body.classList.remove('has-modal');
  }

  return (
    <React.Fragment>
    {
      loading && <Loading />
    }
    <Modal modalType="alert" isShowed={isShowed} size={size} maxHeight={maxHeight}>
      <div className="o-padding--hor-fourteen">
        <p className={titleRegular ? "title regular" :  "title"}>{title}</p>
        <div className={msgBold ? "msg bold" : "msg"} dangerouslySetInnerHTML={{ __html: msg }} />
      </div>

      <div className="wrapper-modal-button has-separation">
        {cancelButton && (
          <button
            className={
              cancelButton
                ? `btn-text alert cancel ${cancelButtonAlertColor && "orange"}`
                : "btn-text alert"
            }
            onClick={() => {
              removeBodyClass();
              actionCancelButton();
            }}
          >
            {cancelTextButton}
          </button>
        )}
        <button
          className={
            cancelButton
              ? `btn-text alert cancel ${textButtonAlertColor && "orange"}`
              : "btn-text alert"
          }
          onClick={() => {
            removeBodyClass();
            action();
          }}
        >
          {textButton}
        </button>
        {thirdButton && (
          <button
            className={
              cancelButton
                ? `btn-text alert cancel ${
                    textButtonAlertColorThird && "orange"
                  }`
                : "btn-text alert"
            }
            onClick={() => {
              removeBodyClass();
              actionThirdButton();
            }}
          >
            {textButtonThird}
          </button>
        )}
      </div>
    </Modal>
    </React.Fragment>
  );
}

export default AlertModal;
