// Dependencies
import React from 'react';

const DocDownload = ({ width, height, color, bgColor, arrowColor, arrowCircleColor, arrowCircleBgColor }) => {

    return (

        <svg 
            width={ width ? width : '26px'}
            height={ height ? height : '28px'}
            viewBox="0 0 26 28"
        >
      <path d="M1.16339 0.960732L10.9349 1.50001L17.6626 8.50001V22.7102H1.16339V0.960732Z" fill={bgColor ? bgColor : "#FBFBFB"}/>
      <path d="M8.73531 0C9.99272 0 10.6827 0.190281 11.5284 1.0634L17.6709 7.34328C18.5611 8.26116 18.7169 8.88807 18.7169 10.3321V20.4962C18.7169 22.8246 17.5708 24 15.2673 24H3.44961C1.15729 24 0 22.8358 0 20.4962V3.51494C0 1.18658 1.15729 0 3.44961 0H8.73531ZM8.49045 1.80222H3.54974C2.39245 1.80222 1.79156 2.44031 1.79156 3.54851V20.4627C1.79156 21.5821 2.39245 22.1977 3.53863 22.1977H15.1783C16.3356 22.1977 16.9254 21.5821 16.9254 20.4627V10.4104H10.5825C9.20263 10.4104 8.49045 9.71639 8.49045 8.30596V1.80222ZM13.4535 17.3955C13.8429 17.3955 14.1434 17.7089 14.1434 18.1007C14.1434 18.4813 13.8429 18.7724 13.4535 18.7724H5.00747C4.6069 18.7724 4.31756 18.4813 4.31756 18.1007C4.31756 17.7089 4.6069 17.3955 5.00747 17.3955H13.4535ZM13.4535 13.4888C13.8429 13.4888 14.1434 13.791 14.1434 14.1717C14.1434 14.5634 13.8429 14.8657 13.4535 14.8657H5.00747C4.6069 14.8657 4.31756 14.5634 4.31756 14.1717C4.31756 13.791 4.6069 13.4888 5.00747 13.4888H13.4535ZM10.1708 2.14925V8.09328C10.1708 8.54104 10.3488 8.72014 10.7828 8.72014H16.5804L10.1708 2.14925Z" fill={color ? color : "#80ABBA"}/>
      <path d="M17.8935 12.7619C21.8674 12.7619 25.0888 16.0024 25.0888 20C25.0888 23.9976 21.8674 27.2381 17.8935 27.2381C13.9196 27.2381 10.6983 23.9976 10.6983 20C10.6983 16.0024 13.9196 12.7619 17.8935 12.7619Z" fill={arrowCircleBgColor ? arrowCircleBgColor : "white"}/>
      <path d="M17.8935 12C22.2856 12 25.8462 15.5818 25.8462 20C25.8462 24.4182 22.2858 28 17.8935 28C13.5015 28 9.94089 24.4182 9.94089 20C9.94089 15.5818 13.5015 12 17.8935 12ZM17.8935 13.6C14.3799 13.6 11.5314 16.4654 11.5314 20C11.5314 23.5346 14.3799 26.4 17.8935 26.4C21.4072 26.4 24.2557 23.5346 24.2557 20C24.2557 16.4654 21.4072 13.6 17.8935 13.6Z" fill={arrowCircleColor ? arrowCircleColor : "#80ABBA"}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9947 16.4211C18.3415 16.4211 18.6226 16.7038 18.6226 17.0526L18.6226 23.1511C18.6226 23.4999 18.3415 23.7827 17.9947 23.7827C17.648 23.7827 17.3669 23.4999 17.3669 23.1511L17.3669 17.0526C17.3669 16.7038 17.648 16.4211 17.9947 16.4211Z" fill={arrowColor ? arrowColor : "#80ABBA"}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.2367 20.3589C21.4819 20.6056 21.4819 21.0055 21.2367 21.2521L18.6224 23.882C18.2757 24.2308 17.7136 24.2306 17.367 23.8819L14.7528 21.2521C14.5076 21.0055 14.5076 20.6056 14.7528 20.3589C14.9979 20.1123 15.3955 20.1123 15.6407 20.3589L17.9947 22.727L20.3488 20.3589C20.594 20.1123 20.9915 20.1123 21.2367 20.3589Z" fill={arrowColor ? arrowColor : "#80ABBA"}/>

        </svg>

    );

}

export default DocDownload;
