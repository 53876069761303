// Dependencies
import React from "react";

// Components
import MediaBlock from "./mediaBlock";
import Icon from "./icons";
import { avatars } from "../assets/resources";
import RenderDocIcon from "../helpers/renderDocIcon";

//helper
import { highlightWord } from "../helpers/highlightWord";

const SearchBlock = props => {

  const _getImage = (item) => {
    if (
      item.avatar_default_id !== null &&
      item.avatar_default_id !== undefined
    ) {
      return avatars[item.avatar_default_id].default
    } else if (item.url !== null) {
      return item.url
    }
  }
  return (
    <>
      {props.documentSection === "contact" ? (
        <>
          {props.avatar !== undefined && (
            <div className="wrapper-avatar little">
              <img
                className="avatar"
                src={_getImage(props.avatar.avatar_url)}
                alt="avatar"
              /> 
            </div>
          )}
          <div className="section-item-texts">
            {props.title !== undefined && (
              <p className="title">
                {props.isSearch ? 
                    highlightWord(props.title, props.isSearch) :
                    props.title
                  }
              </p>
            )}
            {props.description !== undefined && 
              <p>
                {props.isSearch ? 
                  highlightWord(props.description, props.isSearch) :
                  props.description
                }
              </p>
            }
            {props.rightText !== undefined && <p>{props.rightText}</p>}
            {props.documentType !== undefined && <p>{props.documentType}</p>}
          </div>
          {props.checked && props.checked != null && (
            <div className="checked">{props.checked}</div>
          )}
        </>
      ) : (
        <MediaBlock
          alignLeft={true}
          fullWidth={true}
          image={props.documentSection === 'document' ? <RenderDocIcon type={props.documentType} /> :<Icon name={props.iconName} />}
          title={props.title}
          description={props.description}
          rightText={props.documentSection === 'document' ? props.documentSize : ''}
          documentType={props.documentSection === 'document' ? props.documentType : ''}
          isSearch={props.isSearch}
        />
      )}
    </>
  );
};

export default SearchBlock;
