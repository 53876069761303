import {HOST_URL, DESTROY_SESSION, SET_SESSION} from '../constants';
 
import get_header from './api_header'
import {parseHeaders} from './api_header'

export function validateAttribute(authentication_token, type, attributes, success, error) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/validate_attributes', {
      method: 'POST',
      headers: get_header(authentication_token),
      body: JSON.stringify({ type: type, attributes: attributes})
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })	
      }
      else{
      	error()
      }

    })
    .catch((error) => {
      console.error(error);
    });
  }
}

