import { HOST_URL, API_TOKEN, URL_VERSION } from '../constants';

import get_header from './api_header'
import {parseHeaders} from './api_header'


//API



export function getInvitation(id, authentication_token, success, error) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/profile/invitations/'+id, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson);
        })
      }
      else{
        error();
      }

    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function updateInvitation(id, user, authentication_token, success, error) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/profile/update_invitation/'+id, {
      method: 'PUT',
      headers: get_header(authentication_token),
      body: JSON.stringify({user})
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success()          
        })
      }
      else{
        error();
      }

    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function deleteInvitation(id, authentication_token, success, error) {
  
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/profile/destroy_invitation/'+id, {
      method: 'DELETE',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        
        response.json().then((responseJson) => {
          success()          
        })
      }
      else{
        error();
      }

    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function resendInvitation(id, authentication_token, success, error) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/profile/resend_invitation/'+id, {
      method: 'POST',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {                    
          success(responseJson) 
        })
        
      }
      else{
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}