import { HOST_URL, URL_VERSION } from '../constants';

import get_header from './api_header'
import {parseHeaders} from './api_header'

export function getTenant(authentication_token, success, error, url) {
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson.data)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function destroyTenants(authentication_token, ids, resourceable_id, resourceable_type, success, error) {
  
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + '/balance_principal/tenants/destroy_tenants', {
      method: 'POST',
      headers: get_header(authentication_token),
      body: JSON.stringify({ ids: ids, resourceable_id: resourceable_id, resourceable_type: resourceable_type})
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
  
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

