// Dependencies
import React, {useState, useEffect, useRef} from "react";
import ContentBlock from "../components/contentBlock";
import Table from "../components/Table";
import Pagination from "../components/pagination";
import Loading from "../components/loading";

import { connect } from "react-redux";
import {getFinancialStatements} from "../actions/financial_statements";
import { getDocDetail } from "../actions/documents";
import { destroyAsset, createAssetResource} from "../actions/balance";

import AssetsResourcesModal from "./balance/details/assetsResourcesModal";
import AlertModal from "../components/alertModal";

//Components
import Tabs from "../components/Tabs";
import SelectMultiple from "../components/selectMultiple";
import Icon from "../components/icons/";

const FinancialStatements = (props) => {
  let date = new Date()
  date.setFullYear( date.getFullYear() - 1 );


  const [step, setStep] = useState("daily");
  const [elementType, setElementType] = useState("general");
  const [tabActive, setTabActive] = useState(0);
  const [yearSelected, setYearSelected] = useState(""+date.getFullYear());
  const [data, setData] = useState({});
  const [modalData, setModalData] = useState({});
  const [showDeleteModalDocument, setShowDeleteModalDocument] = useState({});
  const [successModal, setSuccessModal] = useState({});

  const [monthSelected, setMonthSelected] = useState("");
  const [monthSelectedId, setMonthSelectedId] = useState(null);
  const [showMonthSelect, setShowMonthSelect] = useState(true);
  const [loading, setLoading] = useState(false)
  

  const selectMultiple = useRef(null)

  useEffect(() => {
    let frequency_selected = getUrlVars()["frequency"]
    let type_selected = getUrlVars()["type"]

    let document_id = getUrlVars()["document_id"]
    
    let params = {}
    if(frequency_selected !== undefined){
        params["frequency"] = frequency_selected
       
    }
    if(type_selected !== undefined){
        params["type"] = type_selected
        setElementType(type_selected)
    }
    setLoading(true)
    props.getFinancialStatements(props.authentication_token, (responseJSON) => success(responseJSON, frequency_selected, document_id), () => error(), params)


    // props.getBreadcrumbs({
    //   assetUrl: "/financial-statements",
    //   url:"/financial-statements",
    //   assetTitle: ""
    // });

  }, []);  

  const error = () => {
    console.log("error")
    setLoading(false)
  }

  const success = (responseJSON, frequency_selected, document_id) => {
    if(frequency_selected !== undefined){
        setStep(frequency_selected);
        setTabActive(getTabActiveByStep(responseJSON, frequency_selected))
    }
    if(document_id !== undefined){
      showDocument("documents/documents/"+document_id)
    }
    setData(responseJSON)
    setLoading(false)
  }

  const getTabActiveByStep = (responseJSON, frequency_selected) => {
    return responseJSON.tabs.map(e => e.code).indexOf(frequency_selected);
  }
  const getUrlVars = () => {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
  }
  const changeStepTab = (tab, index) => {
    console.log(tab, index)
    // pieChart && resetPieChart();
    // setPieSelectedElement(-1);
    setStep(data.tabs[index].code);
    setTabActive(index)
    setYearSelected("")
    setMonthSelected("")
    setMonthSelectedId(null)
    setShowMonthSelect(false)
    setLoading(true)
    props.getFinancialStatements(props.authentication_token, (responseJSON) => success(responseJSON), () => error(), {type: elementType, frequency: data.tabs[index].code})
    // props.setStep(data.tabs[index].code);
    // window.history.pushState(
    //   "",
    //   "",
    //   "balance-show?url=balance_principal/financial_assets&code=" +
    //   data.tabs[index].code
    // );
    // setPieChart(null);
  };

  const successDetailDoc = (responseJSON, url, edit_mode = false) => {
    setModalData({
        idMain: "",
        id: responseJSON.identifier.id,
        form: responseJSON.form.edit_document.inputs,
        resource_key: responseJSON.form.edit_document.resource_key,
        can_manage: responseJSON.form.edit_document.can_manage,        
        url: url,
        title: responseJSON.title,
        show_title: responseJSON.show_title,
        edit_title: responseJSON.edit_title,        
        method: responseJSON.actions[0].method,
        code: responseJSON.actions[0].code,
        linked: responseJSON.assets.length > 1,
        edit_mode: edit_mode
      }
    )
  }

  const showDocument = (url, edit_mode = false) =>  {
    props.getDocDetail(
      props.authentication_token,
      responseJSON => successDetailDoc(responseJSON, url, edit_mode),
      () => this._error(),
      url

    );
  }

  const deleteDocument = (item, url) => {
    setShowDeleteModalDocument({
        title: props.internalization.alerts.delete_asset,
        subtitle: item.linked ? props.internalization.alerts.delete_document_linked : props.internalization.alerts.delete_asset_confirmation,
        url: url,
        method: "DELETE"
      })
    setModalData({})
  }

  const successDeleteAssetDocument = () => {
    setLoading(true)
    props.getFinancialStatements(props.authentication_token, (responseJSON) => success(responseJSON), () => error(), {type: elementType, frequency: step})
    setShowDeleteModalDocument({})

  }

  const successCreateAssetResource = (edit, key, modalData) => {
    let key_fix = ["owner_beneficiary"].includes(key)
      ? modalData.code === "edit_owner"
        ? "owner_life_insurance"
        : "beneficiary_life_insurance"
      : key;
      setModalData({})
    setSuccessModal({
      modal_title: edit
        ? props.internalization.modals[key_fix].updated.title
        : props.internalization.modals[key_fix].created.title,
      modal_body: edit
        ? props.internalization.modals[key_fix].updated.body
        : props.internalization.modals[key_fix].created.body,
    });
  }
  const updateDocument = (data) => {
   props.createAssetResource(
      props.authentication_token,
      () =>
        successCreateAssetResource(
          modalData.method === "PUT",
          modalData.resource_key,
          modalData
        ),
      () => console.log("error updating"),
      modalData.url,
      modalData.method,
      data
    ); 
  }
   
  return (
    <div>
    { loading && 
      <Loading 
            simple 
            width={96} 
            height={96} 
            style={{marginTop: -12}}
          />
    }
      {/*<span className="truncate headingH1 balance-show-title">
        {data.title}
      </span>/*}

      {/*
        data.tabs &&
        <Tabs
          tabs={data.tabs}
          onClick={(tab, index) => changeStepTab(tab, index)}
          tabActive={tabActive}
        // location={props.location}
        />
      */}
      

      {data.table && (
        <div>
          <div className="c-tabs__content-top">
            <ContentBlock
              header={false}
              maxHeight={false}
              className="centered-and-full"
              wrapperStyle={{ marginRight: 0 }}
            >

              <div>
              <p style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{props.language !== "es-ES" ? "Choose the year of the report you want to check" :  "Escoge el año del reporte que quieres consultar"}</p>
                <div style={{marginBottom: 24}}>

                  <SelectMultiple
                    colorGrey={true}
                    setRef={(ref) => selectMultiple}
                    defaultValue={yearSelected}
                    label={"Year"}
                    options={data.filter_year}
                    multiple={false}
                    openOptions={() =>
                      console.log("open options")
                      // etState({
                      //   modalOptions: !modalOptions,
                      // })
                    }
                    disabled={false}
                    // modalOptions={this.state.modalOptions}
                    getOptions={(items) => {
                        setData({})
                        setYearSelected(items.item.value)
                        setLoading(true)
                        props.getFinancialStatements(
                          props.authentication_token, 
                          (responseJSON) => {console.log("asfsda", responseJSON); success(responseJSON)}, 
                          () => error(), 
                          {type: elementType, frequency: step, year: items.item.value, step: step}
                        )                      
                        setShowMonthSelect(true);
                        }
                    }
                    // itemsSelected={
                    //   this.state.inputsValue.type !== undefined
                    //     ? this.state.inputsValue.type
                    //     : []
                    // }
                  />
                  {showMonthSelect && (
                    <div style={{marginTop: 18, display: 'flex', justifyContent: "center", alignItems: "center"}}>

                      <SelectMultiple
                        colorGrey={true}
                        setRef={(ref) => selectMultiple}
                        defaultValue={monthSelected}
                        label={"Month"}
                        options={data.filter_months}
                        multiple={false}
                        openOptions={() =>
                          console.log("open options")
                          // etState({
                          //   modalOptions: !modalOptions,
                          // })
                        }
                        disabled={false}
// modalOptions={this.state.modalOptions}
                        getOptions={(items) => {
                          setData({})
                          setMonthSelected(items.item.value)
                          setMonthSelectedId(items.item.id)
                          setLoading(true)
                          props.getFinancialStatements(props.authentication_token, (responseJSON) => success(responseJSON), () => error(), {type: elementType, frequency: step, year: yearSelected, month: items.item.id, step: step})                      
                        }}
// itemsSelected={
                        //   this.state.inputsValue.type !== undefined
                        //     ? this.state.inputsValue.type
                        //     : []
                        // }
                      />
                    {
                      monthSelected !== "" &&
                      <div style={{ position: "relative"}}>
                      <div
                        className=""
                        style={{
                          widht: 20,
                          transform: "rotate(-45deg) translate(-3px, 12px)",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setData({})
                          setMonthSelected("")
                          setMonthSelectedId(null)
                          setLoading(true)
                          props.getFinancialStatements(props.authentication_token, (responseJSON) => success(responseJSON), () => error(), {type: elementType, frequency: step, year: yearSelected, month: "", step: step})                      
                        }
                        }
                      >
                        <Icon name="Plus" noWrapper={true} />
                      </div>
                  </div>
                    }
                    
                    </div>

                  )}


                </div>

                <Table
                  // showSearcher={
                  //   props.dataTable.documents.table.total_pages !== 1 ||
                  //     (searchText["documents"] !== undefined && searchText["documents"] !== "")
                  // }
                  dataTable={data.table}
                  fullWidth={true}
                  action={{
                    title: "Action",
                    show: (el) => showDocument(el.url, false),
                    edit: (el) => showDocument(el.url, true),
                  }}
                  // searchText={searchText["documents"]}
                  // search={(text) =>
                  //   props.paginateTab(
                  //     { text: text, source: "documents", page: 1, type: "get_documents" },
                  //     () => setSearchText({ ...searchText, ["documents"]: text }),
                  //     () => ""
                  //   )
                  // }
                  all_ellipsis={true}
                />
                {data.table.rows.length > 0 &&
                  data.table.total_pages > 1 && (
                    <div className="c-tabs__content-bottom">
                      <Pagination
                        goTo={(page) => {
                          setData({})
                          setLoading(true)
                          props.getFinancialStatements(props.authentication_token, (responseJSON) => success(responseJSON), () => error(), {year: yearSelected, month: monthSelectedId, page: page, step: step})
                        }}
                        pages={Array.from(
                          { length: data.table.total_pages },
                          (v, k) => k + 1
                        )}
                        currentPage={data.table.current_page}
                      />
                    </div>
                  )}
              </div>
            </ContentBlock>
          </div>
        </div>
      )}
      {Object.keys(showDeleteModalDocument).length > 0 && (
        <AlertModal
          isShowed={Object.keys(showDeleteModalDocument).length > 0}
          title={props.internalization.modals.document.delete.title}
          msg={props.internalization.modals.document.delete.body}
          action={() => {
            props.destroyAsset(
              props.authentication_token,
              () => successDeleteAssetDocument(),
              () => alert("error"),
              showDeleteModalDocument.url,
              showDeleteModalDocument.method
            );
          }}
          textButton="Yes"
          textButtonAlertColor={true}
          cancelButton={true}
          cancelTextButton="Cancel"
          cancelButtonAlertColor={false}
          actionCancelButton={() => {
            setShowDeleteModalDocument({})
          }}
        />
        )}
        {Object.keys(modalData).length > 0 && (
            <AssetsResourcesModal
              language={props.language}
              identifier={""}
              colorGrey={true}
              data={modalData}
              errors={props.errors}
              closeModal={e => {
                if (
                  e.target.className === "modal-wrapper" ||
                  (e.target.closest(".modal-header") !== null &&
                    e.target.closest(".modal-header").className.includes("modal-header"))
                ) {
                  setModalData({})
                }
              }}
              uploadResource={data => updateDocument(data)}
              resources={[]}
              goToResource={asset => ""}
              polymorphic={
                modalData.resource_key === "contact"
                  ? "resourceable_"
                  : "ownerable_"
              }
              showDocument={url => props.showDocument(url)}
              showContact={(url, idMain) => ""}
              resourceToSelectDestroy={true}
              resourceDestroyAction={url => ""}
              destroyAssetAssociated= {() => ""}
              deleteDocument={(url) => modalData.resource_key === "document" ? deleteDocument(modalData, modalData.url) : ""}
              buttons={props.internalization.buttons}
              showModalSessionExpire={(time_left, diffMins, checkTime) => props.showModalSessionExpire(time_left, diffMins, () => checkTime())}
              getLinkForm = {(url, success_fromblock, error_fromblock) => props.getLinkForm(url, props.authentication_token, (responseJSON) => "", () => "")}
            />
          )}
        {Object.keys(successModal).length > 0 && (
          <AlertModal
            isShowed={true}
            title={successModal.modal_title}
            msg={successModal.modal_body}
            action={() => {
              setSuccessModal({})
              setLoading(true)
              props.getFinancialStatements(props.authentication_token, (responseJSON) => success(responseJSON), () => error(), {type: elementType, frequency: step})

            }}
            textButton={props.internalization.buttons.ok}
          />
        )}        
    </div>
  );

};

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    internalization: state.session.internalization,
    errors: state.session.errors,

  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFinancialStatements: (authentication_token, success, error, params) =>
      dispatch(getFinancialStatements(authentication_token, success, error, params)),
    getDocDetail: (authentication_token, success, error, url) =>
      dispatch(getDocDetail(authentication_token, success, error, url)),
    destroyAsset: (authentication_token, success, error, url, method) =>
      dispatch(destroyAsset(authentication_token, success, error, url, method)),
    createAssetResource: ( authentication_token, success, error, url, method, attributes) => dispatch( createAssetResource( authentication_token, success, error,url, method, attributes)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialStatements);
