// Dependencies
import React from "react";
import { connect } from "react-redux";
import {
  DETAIL_TYPE_ONE_CODES,
  NOT_PASSIVES,
  internalization,
} from "../../constants";

// Actions
import { createAsset } from "../../actions/balance";
import { getLinkForm, getConvertedValue, getCapitalAmortizationValue } from "../../actions/formblock";

//Components
import Loading from "../../components/loading";
import AlertModal from "../../components/alertModal";
import FormBlock from "../../components/formBlock";
import Modal from "../../components/modal";

// Variables
let data = {};

class BalanceNewScreen extends React.Component {
  constructor(props) {
    super(props);

    data = this.props.history.location.state;

    this.state = {
      loading: true,
      showSuccesModal: false,
      detail_type: "",
      infoModal: {},
      // scaleButton: [{ scale: 1 }]
    };
  }

  _success(responseJSON = {}) {
    let detail_type;
    let reg = new RegExp(NOT_PASSIVES);
    if (this.props.history.location.state.code !== "add_valuation") {
      if (this.props.history.location.state.code === "add_owner") {
        detail_type = "owner_life_insurance";
      } else if (this.props.history.location.state.code === "add_beneficiary") {
        detail_type = "beneficiary_life_insurance";
      } else if (this.props.history.location.state.code === "add_insured") {
        detail_type = "insured";
      } else if (this.props.history.location.state.code === "add_tenant") {
        detail_type = "tenant";   
      } else {
        detail_type = reg.test(this.props.history.location.state.url)
          ? "asset"
          : "liability";
      }
    } else {
      detail_type = "valuation";
    }

    let is_ownership_linked = responseJSON.is_ownership_linked || false

    this.setState({
      showSuccesModal: true,
      detail_type: detail_type,
      loading: false,
      infoModal: {
        title: this.props.internalization.modals[detail_type].created.title,
        msg: this.props.internalization.modals[detail_type].created.body,
        action: () => {
          this.props.history.push(
                "/balance-detail?url=" +
                  this._getIdentifier("url") +
                  "&id=" +
                  this._getIdentifier("id") + 
                  "&code="+ detail_type,
                {
                  url: this._getIdentifier("url"),
                  id: this._getIdentifier("id"),
                  is_ownership_linked: is_ownership_linked
                }
              )
        },
      },
    });
  }

  _error(responseJSON) {
    this.setState({ loading: false });
    if (responseJSON && responseJSON.hasOwnProperty("error")) {
      // alert(responseJSON.error);
      this.setState({
        showSuccesModal: true,
        loading: false,
        infoModal: {
          title: "Error",
          msg: responseJSON.error,
          action: () => this.setState({ showSuccesModal: false }),
        },
      });
    } else {
      alert("Error");
    }
  }

  _getIdentifier(type) {
    if(type === "url"){
      return this.props.balance_detail.identifier ? this.props.balance_detail.identifier.url : this.props.location.state.temp_identifier.url
    }
    if(type === "id"){
      return this.props.balance_detail.identifier ? this.props.balance_detail.identifier.id : this.props.location.state.temp_identifier.id
    }
  }

  _createNewAsset(formData, state) {
    if (!state.loading) {
      this.setState({ loading: true });
      this.props.createAsset(
        this.props.authentication_token,
        (responseJSON) => this._success(responseJSON),
        (responseJSON) => this._error(responseJSON),
        this.props.history.location.state.url,
        this.props.history.location.state.method,
        {
          [this.props.history.location.state.resource_key]: formData,
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0);
    if (prevProps.current_breadcrumb === undefined) {
      // this._setBreadcrumb()
    }
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
    this._setBreadcrumb();
  }

  _getLocalStorageCurrentBreadcrumb() {
    let current_breadcrumb_localstorage = localStorage.getItem(
      "special_parent"
    );
    if (current_breadcrumb_localstorage) {
      try {
        current_breadcrumb_localstorage = JSON.parse(
          current_breadcrumb_localstorage
        );
      } catch (e) {
        current_breadcrumb_localstorage = [];
      }
    } else {
      current_breadcrumb_localstorage = [];
    }
    return current_breadcrumb_localstorage;
  }
  _setBreadcrumb() {
    let new_bread = [];
    try {
      let languageUserAgent = /^es/.test(this.props.user.language)
        ? "es"
        : "en";
      if (this.props.current_breadcrumb === undefined) {
        if (
          this.props.history.location.state.from !== undefined &&
          this.props.history.location.state.from === "BalanceIndexScreen"
        ) {
          new_bread = [
            {
              title: internalization[languageUserAgent].models.home,
              code: "home",
              url: "/home",
            },
            {
              title: internalization[languageUserAgent].models.balance,
              code: "balance_index",
              url: "/balance-index",
            },
          ];
        } else if (
          this.props.history.location.state.from !== undefined &&
          this.props.history.location.state.from === "BalanceShowScreen"
        ) {
          new_bread = [
            {
              title: internalization[languageUserAgent].models.home,
              code: "home",
              url: "/home",
            },
            {
              title: internalization[languageUserAgent].models.balance,
              code: "balance_index",
              url: "/balance-index",
            },
            {
              title: internalization[languageUserAgent].models.fixed_assets,
              code: "fixed_assets",
              url: "/balance-show?url=balance_principal/fixed_assets",
            },
          ];
        } else {
          new_bread = [
            {
              title: internalization[languageUserAgent].models.home,
              code: "home",
              url: "/home",
            },
          ];
        }
      } else {
        if (
          this.props.history.location.state.from !== undefined &&
          this.props.history.location.state.from === "Home"
        ) {
          new_bread = [
            {
              title: internalization[languageUserAgent].models.home,
              code: "home",
              url: "/home",
            },
          ];
        } else {
          new_bread =
            this.props.current_breadcrumb.length === 0
              ? this._getLocalStorageCurrentBreadcrumb()
              : this.props.current_breadcrumb;
        }
      }

      // new_bread.push({
      //      url:this.props.history.location.pathname + this.props.history.location.search,
      //      assetUrl: this.props.history.location.pathname
      //   })

      let check_from_content_manager =
        this.props.history.location.state.from !== undefined &&
        this.props.history.location.state.from === "content_manager";
      if (new_bread.length > 0 && !check_from_content_manager)
        new_bread = new_bread.filter((e) => e.code !== "");
      let reg = new RegExp(NOT_PASSIVES);

      if (reg.test(this.props.history.location.state.resource_key)) {
        new_bread = new_bread.filter((e) => e.code !== "liabilities");
      }
      this.props.setSpecialParent(new_bread);
      this.props.getBreadcrumbs({
        url: "/balance-new",
        assetUrl: "/balance-new",
        assetTitle: this.props.history.location.state.title || data.title,
      });
    } catch (e) {}
  }

  render() {

    return (
      <div className="main-wrapper white">
        {this.state.loading && (
          <Loading
            text={this.props.internalization.loading}
            show={this.state.loading}
          />
        )}

        {data !== undefined && (
          <FormBlock
            language={this.props.user.language}
            colorGrey={true}
            data={data}
            errors={this.props.errors}
            loadMultiple={true}
            actionSubmitButton={(formData) =>
              this._createNewAsset(formData, this.state)
            }
            buttons={this.props.internalization.buttons}
            internalization={this.props.internalization}
            getLinkForm={(url, success_fromblock, error_fromblock) =>
              this.props.getLinkForm(
                url,
                this.props.authentication_token,
                (responseJSON) => success_fromblock(responseJSON),
                () => error_fromblock("error")
              )
            }
            getConvertedValue={(value, currency_type, date, success, error) => this.props.getConvertedValue(value, currency_type, date, this.props.authentication_token, (responseJSON) => success(responseJSON),() => error("error"))}
            getCapitalAmortizationValue={(data, success, error) => this.props.getCapitalAmortizationValue(data, this.props.authentication_token, (responseJSON) => success(responseJSON),() => error("error"))}            
          />
        )}

        {this.state.showSuccesModal && (
          <AlertModal
            isShowed={this.state.showSuccesModal}
            title={this.state.infoModal.title}
            msg={this.state.infoModal.msg}
            action={() => {
              this.setState({
                showSuccesModal: false,
              });
              this.state.infoModal.action();
            }}
            textButton={this.props.internalization.buttons.ok}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    balance_detail: state.balance.balance_detail,
    errors: state.session.errors,
    internalization: state.session.internalization,
    current_breadcrumb: state.session.current_breadcrumb,
    user: state.session.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getConvertedValue: (value, currency_type, date, authentication_token, success, error) => dispatch(getConvertedValue(value, currency_type, date, authentication_token, success, error)),
    getCapitalAmortizationValue: (data, authentication_token, success, error) => dispatch(getCapitalAmortizationValue(data, authentication_token, success, error)),    
    getLinkForm: (url, authentication_token, success, error) =>
      dispatch(getLinkForm(url, authentication_token, success, error)),
    createAsset: (
      authentication_token,
      success,
      error,
      url,
      method,
      attributes
    ) =>
      dispatch(
        createAsset(
          authentication_token,
          success,
          error,
          url,
          method,
          attributes
        )
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceNewScreen);
