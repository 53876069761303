// Dependencies
import React from 'react';

const Messages = ({ width, height, color }) => {

    return (

        <svg 
            width={ width ? width : '24px'}
            height={ height ? height : '22px'}
            viewBox="0 0 24 22" 
        >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M14.6704102,16.9510755 C17.1075033,20.3170252 18.7034098,22 19.4581299,22 C20.2128499,22 20.59021,21.5180664 20.59021,20.5541992 L20.59021,16.9510755 L14.6704102,16.9510755 Z" id="path-2" fill={ color ? color : "#80abba" }></path>
                <rect id="Rectangle" fill={ color ? color : "#80abba" } x="0" y="0" width="24" height="17" rx="2"></rect>
            </g>
        </svg>
    );

}

export default Messages;
