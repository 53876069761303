// Dependencies
import React from 'react';

// Components
import Icon from './icons/';


const IconToolBox = ({ download, setting, filter }) => {
  return (
    <div className="wrapper-iconToolBox">
    <button onClick={download}>
      <Icon name="Download"/>
    </button>
    <button onClick={setting}>
      <Icon name="Gear" stroke="white"/>
    </button>
    <button onClick={filter}>
      <Icon name="Filter"/>
    </button>
    </div>
  );
}

export default IconToolBox;