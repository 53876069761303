
function AutoLogin(success, error) {
  
  let localToken = localStorage.getItem("authentication_token") || sessionStorage.getItem("authentication_token");

  localToken === null ? 
    error()
    : 
    success(localToken)
  };

export default AutoLogin;
