import React, { useState, useEffect, useRef } from "react";
import Search from "../components/search";

const TableDropdownSearcher = (props) => {
  const tableSearcherRef = useRef(null);

  useEffect(() => {
    setWidth();
    window.addEventListener("resize", setWidth);
  }, []);

  useEffect(() => {
    if(props.fromSearchText !== undefined && props.fromSearchText !== ""){
      search(props.fromSearchText)  
    }
    
  }, [props.fromSearchText])

  const setWidth = () => {
    // get dropdown or alternatives table
    const table =
      document.querySelector(".c-table-dropdown") ||
      document.querySelector(".c-table-alternatives");

    if (table) {
      const wrapperWidth = document
        .querySelector(".main-wrapper") // get main wrapper
        .getBoundingClientRect().width; // get main wrapper width
      let tableBodyWidth = table
        .querySelector(`.${table.classList.value}__body`)
        if(tableBodyWidth){
          tableBodyWidth = tableBodyWidth.getBoundingClientRect().width; // get table body width  
         // get table body
        }
        

      // set searcher width
      if (tableSearcherRef && tableSearcherRef.current && tableBodyWidth)
        {
          tableSearcherRef.current.style.width =
        tableBodyWidth > wrapperWidth ? `100%` : `${tableBodyWidth}px`;
        }
    }
  };

  const search = (text) => {
    if (text !== "" && text.length > 1) {
      let result = [];

      props.originData.map((row) => {
        row[0].showIt = null;

        row.map((cell) => {
          if ((cell.value && typeof cell.value === "string") || cell.main) {
            let matchText = cell.value.toLowerCase().includes(text.toLowerCase());
            if (matchText && cell.main) {
              result.push(row);
              row[0].showIt = true;
            } else if (matchText) {
              if (!row[0].showIt) {
                row[0].showIt = true;
              }
              result.push(row);
            } else if (cell.main) {
              result.push(row);
            }
          }
        });
      });

      result.length > 0 && props.result(result, true);
    } else {
      props.result(props.originData, false);
    }
  };

  return (
    <div className="c-table__searcher" ref={tableSearcherRef}>
      <Search searchText={props.fromSearchText} getValue={(text) => search(text)} />
    </div>
  );
};

export default TableDropdownSearcher;
