import { HOST_URL, URL_VERSION } from '../constants';

import get_header from './api_header'
import {parseHeaders} from './api_header'

export function getContact(authentication_token, success, error, url) {
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson.bc_show)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function destroyContactAsset(authentication_token, success, error, url) {
  
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: 'DELETE',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
  
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function createContactAsset(authentication_token, success, error, id, resourceable_id, resourceable_type) {
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + "/balance_principal/contacts_assets", {
      method: 'POST',
      headers: get_header(authentication_token),
      body: JSON.stringify({ id: id, resourceable_id: resourceable_id, resourceable_type: resourceable_type})
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function destroyAssignAssetByResourceable(authentication_token, success, error, id, resourceable_id, resourceable_type, url, type) {
  
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + url + id, {
      method: 'DELETE',
      headers: get_header(authentication_token),
      body: JSON.stringify({ [type]: id, resourceable_id: resourceable_id, resourceable_type: resourceable_type})
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

