// Dependencies
import React, { useState, useEffect, useRef } from "react";
import { unLocalize } from "../../../helpers/getLocale";
import Slider from "react-slick";
import {internalization} from '../../../constants'

//helpers
import { getIconAsset } from "../../../helpers/renderIconAssets";

// Components
import DetailContentBlock from "../../../components/detailContentBlock";
import TableBlock from "../../../components/tableBlock";
import MediaBlock from "../../../components/mediaBlock";
import RenderDocIcon from "../../../helpers/renderDocIcon";
import { avatars } from "../../../assets/resources";
import Modal from "../../../components/modal";
import BoxResources from "../../../components/boxResources";
import AlertModal from "../../../components/alertModal";
import CustomModal from "../../../components/customModal";
import Icon from "../../../components/icons";
import ContentModal from "../../../components/contentModal";
import Tabs from "../../../components/Tabs";
import Table from "../../../components/Table";
import Pagination from "../../../components/pagination";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

function DetailTypeTwo(props) {
  console.log("DetailTypeTwo",props)
  const [showImageModal, setShowImageModal] = useState(false);
  const [imagesToZoom, setImagesToZoom] = useState([]);
  const [linkedResource, setLinkedResource] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertAsk, setShowAlertAsk] = useState(false);
  const [showToolsTable, setShowToolsTable] = useState(false);
  const [btnShowAllItems, setBtnShowAllItems] = useState({})
  const [dataRowActualPerformance, setDataRowActualPerformance] = useState({})
  const [contentStep, setContentStep] = useState('main')
  const [screenStepIndex, setScreenStepIndex] = useState(props.screen_step_index || 0)
  const [inputs, setInputs] = useState({})
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [searchText, setSearchText] = useState({})
  const [carouselImageSelected, setCarouselImageSelected] = useState(0)

  let languageUserAgent = (/^es/).test(props.language) ? 'es' : 'en';


  const MAX_SHOW = 12

  useEffect(() => {


    let obj = {}
    Object.keys(props.data).map(item => {
      obj = {...obj, [item] : {show: false, lengthItems: MAX_SHOW}}
    })

    setBtnShowAllItems(obj)
  }, []);


  useEffect(() => {
    if(Object.keys(props.inputsActualPerformance).length > 0){
      _openModalActualPerformance(props.inputsActualPerformance)
    }

  }, [props.inputsActualPerformance])


  useEffect(() => {
    try{

      if(props.data.tabs[props.screen_step_index] === undefined){
        setContentStep(props.data.tabs[0].code)  
        setScreenStepIndex(0)
      }
      else{
        setScreenStepIndex(props.screen_step_index)
        setContentStep(props.data.tabs[props.screen_step_index].code)      
      }
    

      if(props.screen_step_index !== 0 && contentStep === "main" && props.data && props.data.tabs){
        if(props.data.tabs[props.screen_step_index] === undefined){
          setContentStep(props.data.tabs[0].code)  
          setScreenStepIndex(0)
        }
        else{
          setScreenStepIndex(props.screen_step_index)
          setContentStep(props.data.tabs[props.screen_step_index].code)  
        }        
      }
    }catch(e){
      setScreenStepIndex(0)
      props.data && props.data.tabs && setContentStep(props.data.tabs[0].code)  
    }     
  }, [props.screen_step_index])  

  useEffect(() => {
    setShowImageModal(false)
  }, [props.successDeleteImage])

  // const _getImage = (item) => {
  //   if (
  //     item.avatar_url.avatar_default_id !== null &&
  //     item.avatar_url.avatar_default_id !== undefined
  //   ) {
  //     return avatars[item.avatar_url.avatar_default_id];
  //   } else if (item.avatar_url.url !== null) {
  //     return item.avatar_url.url;
  //   }
  // };

  const _createImagesToZoom = (item) => {
    let imagesToZoom = [
      {
        id: item.id,
        url: item.url_image.large,
      },
    ];

    setImagesToZoom(imagesToZoom);
    setShowImageModal(true);
  };

  const _openModalActualPerformance = (inputs) => {

    let keyAction = dataRowActualPerformance.action === "edit" ? "edit_valuation" : "add_valuation"
    let getInputsValue = inputs.bc_show.form[keyAction].inputs

    setInputs(getInputsValue)

    setShowToolsTable(true);
  }

  const _getUrlActualPerformance = (index, url, action, id) => {
    props.getInputsActualPerformance(url)
    let getTitle = props.data.table_evolution.map(
      (item) => item.titles[index].title
    );
    setDataRowActualPerformance({index: index, action: action, id: id, title: getTitle})
  };

  const _saveActualPerformance = (inputs) => {

    let _inputsActualPerformance = inputs;

    let actualPerformance = {};

    let getTitle = props.data.table_evolution.map(
      (item) => item.titles[dataRowActualPerformance.index].title
    );
    _inputsActualPerformance.life_insurance_id = parseInt(props.identifier.id);
    _inputsActualPerformance.budget_id = parseInt(dataRowActualPerformance.id);

    actualPerformance["actual_performance"] = _inputsActualPerformance;
    setShowToolsTable(false);

    props.getDataActualPerformance(
      actualPerformance,
      dataRowActualPerformance.action,
      getTitle
    );
  };

  const _btnShowAllItems = (type, show, length) => {

    setBtnShowAllItems({...btnShowAllItems, [type]: {show: show, lengthItems: show ? length : MAX_SHOW}})
  }

  const handleTabsContent = (tab, index) => {
    if(tab.code == "wealth_tab"){
      props.history.push("/wealth-map", {"is_ownership_linked": true, "id": props.data.identifier.resourceable_id, class: props.data.identifier.resourceable_type});
    }
    else{
      setContentStep(tab.code)
      setScreenStepIndex(index)
      props.handleTabChange && props.handleTabChange(index)    
    }
  }
  // 
  const mainContent = () => {
    return( 
      <DetailContentBlock header={true}>
        {props.data.summary !== undefined && (
          <div className="wrapper-summary">
            {props.data.summary.map((item, i) => {
              return (
                <div key={i} className="summary-item">
                  <span className="summary-titles">
                    {item.title} :{" "}
                    <span className="summary-values">{item.value}</span>
                  </span>
                </div>
              );
            })}
          </div>
        )}
        {
          props.data.table_evolution !== undefined &&
            <div className="header-table-evolution">
              {props.data.table_evolution !== undefined &&
                  props.data.header_table_evolution.left.map((item, index) => {
                    if (item.title === "") {
                      return (
                        <p
                          key={index + "_ev"}
                          className="header-table-evolution__item-title"
                        >
                          <span className="o-font--bold">{item.value}</span>
                        </p>
                      );
                    } else {
                    }
                  })}
              <div className="header-table-evolution__item">
                {props.data.table_evolution !== undefined &&
                    props.data.header_table_evolution.left.map((item, index) => {
                      if (item.title !== "") {
                        return (
                          <p
                            key={index + "_ev_value"}
                            className="header-table-evolution__item-text"
                          >
                            <span className="o-font--bold header-table-evolution__item-text-title">{item.title}</span>:{" "}
                            {item.value}
                          </p>
                        );
                      }
                    })}
              </div>
              <div className="header-table-evolution__item header-table-evolution__item--align-right">

                {props.data.table_evolution !== undefined &&
                    props.data.header_table_evolution.right.map((item, index) => {
                      if(!["Billing Mode"].includes(item.title)){
                        return (
                          <div style={{display: 'flex'}}>
                            <p
                              key={index + "_ev:title"}
                              className="header-table-evolution__item-text"
                            >
                              <span className="o-font--bold header-table-evolution__item-text-title">{item.title}</span>:{" "}
                              {item.value}
                            </p>
                            {
                              item.title === "Initial Planned Premium" &&
                                props.data.header_table_evolution.right.map((a,i) => {
                                  if(["Billing Mode"].includes(a.title)){
                                    return(
                                      <p
                                        key={i + "_ev:title"}
                                        className="header-table-evolution__item-text"
                                        style={{marginLeft: '5px'}}
                                      >
                                        <span className="o-font--bold header-table-evolution__item-text-title">/ {a.title}</span>:{" "}
                                        {a.value}
                                      </p>
                                    )
                                  }
                                })
                            }
                          </div>
                        );
                      }
                    })}
              </div>
            </div>
        }

        {
          props.data.table_evolution !== undefined && props.data.table_evolution.map((table, i) => {

            return (
              <div
                key={i}
                className={
                  props.data.table_evolution.length !== i + 1
                    ? "wrapper-separator"
                    : ""
                }
              >
                <TableBlock
                  data={table}
                  lengthShowActive={table.rows.length > 12}
                  notScroll={
                    i === 0 &&
                      props.data.identifier &&
                      ["ReceivableAccount", "Liability"].includes(
                        props.data.identifier.resourceable_type
                      )
                  }
                  showBtn={table.rows.length > 12}
                  secondTable={
                    props.data.table_evolution.length !== i + 1 ? false : true
                  }
                  detail={true}
                  tableEvolution={true}
                  showToolsTable={(index, url, action, id) =>
                      _getUrlActualPerformance(index, url, action, id)
                  }
                  titlesTableEvolution={props.data.titles_table_evolution}
                  proposal={props.data.proposal}
                />
              </div>
            );
          })
        }
        {props.data.tables !== undefined &&
            props.data.tables.map((table, i) => {
              return (
                <div
                  key={i}
                  className={
                    props.data.tables.length !== i + 1 ? "wrapper-separator" : ""
                  }
                >
                  <TableBlock
                    id_key={i}
                    data={table}
                    lengthShowActive={i === 0 ? false : table.rows.length > 15}
                    notScroll={
                      i === 0 &&
                        props.data.identifier &&
                        ["ReceivableAccount", "Liability"].includes(
                          props.data.identifier.resourceable_type
                        )
                    }
                    showBtn={i === 0 ? false : table.rows.length > 15}
                    secondTable={
                      props.data.tables.length !== i + 1 ? false : true
                    }
                    detail={true}
                  />
                </div>
              );
            })}
      </DetailContentBlock>
    )
  }

  const imageContent = () => {
    return(

      <DetailContentBlock title={props.data.section_titles.images}>
        <div className="box-wrapper-color">
          {props.data.images.slice(0, btnShowAllItems["images"].lengthItems).map((element, i) => {
            return (
              <div className="box-wrapper-color__content">
                <MediaBlock
                  key={"images_"+i+"_"+element.urls.medium}
                  image={
                    <img
                      src={element.urls.medium}
                      className="mediaBlock-image size-big"
                      alt="contactImage"
                    />
                  }
                  title={element.title}
                  //description={element.description}
                  rightText={element.size}
                  documentType={element.type}
                  action={() => _createImagesToZoom(element)}
                  imageSeparator={true}
                  titleFullWidth={true}
                  center={true}
                />
              </div>
            );
          })}
        </div>
        {
          props.data.images.length > MAX_SHOW &&
            <button
              className="btn primary"
              onClick={() => _btnShowAllItems("images",!btnShowAllItems["images"].show, props.data.images.length)}
              style={{ marginTop: 24 }}
            >
              <span className="btn-text" style={{ padding: "20px 0" }}>
                {!btnShowAllItems["images"].show ? "Show all Images" : "Show less Images"}
              </span>
            </button>
        }
      </DetailContentBlock>
    )

  }


  //Carousel arrows//
  const _next = () => {
    nav1.slickNext()
    nav2.slickNext()

    setTimeout(() => {
      let currentSlide = document.querySelector('.slick-current')
      setCarouselImageSelected(currentSlide.getAttribute('data-index'))
    }, 1000)

  }

  const _previous = () => {
    nav1.slickPrev()
    nav2.slickPrev()

    setTimeout(() => {
      let currentSlide = document.querySelector('.slick-current')
      setCarouselImageSelected(currentSlide.getAttribute('data-index'))
    }, 1000)

  }

  //@Carousel arrows//
  const renderCarousel = (images) => {
    console.log(images)
    return (
      <>
        <div className="zoom-images__tools">
          <div
            className="zoom-images__tools-icon"
            onClick={() => props.deleteImage([{id: images[carouselImageSelected][1].value, url : images[carouselImageSelected][0].value.large}])}
          >
            <Icon
              width={"20px"}
              height={"20px"}
              noWrapper={true}
              color={"#f3b01c"}
              name="Garbage"
              orientation={"right"}
            />
          </div>
        </div>
        <div className="wrapper-slider" style={{display: images.length === 1 ? 'flex' : 'block', justifyContent: 'center'}}>
          {
            images.length === 1 && (
              <img
                className="img-carousel"
                src={images[0][0].value.large}
                alt="carousel image"
              />
            )
          }
          {
            images.length >= 2 && (
              <Slider
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
              >
                {
                  images !== undefined && images.map((img, index) => {
                    return (
                      <img
                        key={img[1].value}
                        className="img-carousel"
                        src={img[0].value.large}
                        alt="carousel image"
                      />
                    )
                  })
                }
              </Slider>
            )
          }
          {
            images.length >= 2 && (
              <Slider
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
                slidesToShow={images.length > 2 ? 3 : 2}
                swipeToSlide={true}
                focusOnSelect={true}
                adaptiveHeight={true}
                centerMode={true}
                centerPadding="0px"
              >
                {
                  images !== undefined && images.map((img, index) => {
                    return (
                      <div className="container-img-carousel">
                        <img
                          name={img[1].value}
                          key={`carousel-${img[1].value}`}
                          className="img-carousel"
                          src={img[0].value.medium}
                          alt=""
                        />
                      </div>
                    );
                  })
                }
              </Slider>
            )
          }
          {
            images.length >= 2 && (
              <>
                <div
                  onClick={() => _previous()}
                  className="arrow-container-left"
                >
                  <Icon name="ChevronArrow" orientation="left" />
                </div>
                <div
                  onClick={() => _next()}
                  className="arrow-container-right"
                >
                  <Icon name="ChevronArrow" orientation="right" />
                </div>
              </>
            )
          }
        </div>
      </>
    )
  }

  return (
    <>
      <div className="detailTypeTwo-header">
        <span className="headingH2">
          {props.data !== undefined && props.data.title}
        </span>
      </div>

      {
        (props.data.tabs && props.data.tabs.length > 0) && (
          <Tabs 
            tabs={props.data.tabs} 
            onClick={(tab, index) => handleTabsContent(tab, index)}
            tabActive={screenStepIndex}
            location={props.location}             
          />
        )
      }

      {contentStep === 'main' && (
        <div className="c-tabs__content is-selected">
          { mainContent() }
        </div>                             
      )}

      {contentStep === 'owners' && props.data.tablesv2.owners && (
        <div className="c-tabs__content is-selected">
          <div className="c-tabs__content-top">
          <Table 
            dataTable={props.data.tablesv2.owners}
            action={{
              title: internalization[languageUserAgent].action, 
              show: (element) => props.showLifeInsuranceElement( element.url, "owner_beneficiary", false),
              edit: (element) => props.showLifeInsuranceElement( element.url, "owner_beneficiary", true)
            }}
            searchText={searchText}
            search={(text)=> props.paginateTab({text: text, page: 1, type: "get_owners"}, () => setSearchText({...searchText, ["owners"]: text}), () => "")}
          />
          </div>
          <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    alert(page)
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: 10 }, (v, k) => k + 1)}
                  currentPage={1}
                />
          </div>
        </div>                             
      )}
      {contentStep === 'beneficiaries' && props.data.tablesv2.beneficiaries && (
        <div className="c-tabs__content is-selected">
          <div className="c-tabs__content-top">
          <Table 
            dataTable={props.data.tablesv2.beneficiaries} 
            action={{
              title: internalization[languageUserAgent].action, 
              show: (element) => props.showLifeInsuranceElement( element.url, "owner_beneficiary", false),
              edit: (element) => props.showLifeInsuranceElement( element.url, "owner_beneficiary", true)
            }}
            search={(text)=> console.log(text)}
          />
          </div>
          <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    alert(page)
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: 10 }, (v, k) => k + 1)}
                  currentPage={1}
                />
          </div>
        </div>                             
      )}
      {contentStep === 'insured' && props.data.tablesv2.insured && (
        <div className="c-tabs__content is-selected">
          <div className="c-tabs__content-top">
          <Table 
            dataTable={props.data.tablesv2.insured} 
            action={{
              title: internalization[languageUserAgent].action, 
              show: (element) => props.showLifeInsuranceElement( element.url, "insured", false),
              edit: (element) => props.showLifeInsuranceElement( element.url, "insured", true)
            }}
            search={(text)=> console.log(text)}
          />
          </div>
          <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    alert(page)
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: 10 }, (v, k) => k + 1)}
                  currentPage={1}
                />
          </div>
        </div>                             
      )}
        {contentStep === 'stake_holders' && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
              <Table 
                key={"stake_holders"+props.data.tablesv2.stake_holders.current_page}              
                dataTable={props.data.tablesv2.stake_holders}
                showSearcher={props.data.tablesv2.stake_holders.total_pages !== 1 || (searchText["stake_holders"] !== undefined && searchText["stake_holders"] !== "")}                
                action={{
                  title: internalization[languageUserAgent].action, 
                  show: (el) => props.showStakeHolder(el.url, false),
                  edit: (el) => props.showStakeHolder(el.url, true)
                }} 
                searchText={searchText["stake_holders"]}
                search={(text)=> props.paginateTab({text: text, source: 'stake_holders', page: 1, type: "get_stake_holders"}, () => setSearchText({...searchText, ["stake_holders"]: text}), () => "")}
              />
            </div>
            {
              props.data.tablesv2.stake_holders.rows.length > 0 && props.data.tablesv2.stake_holders.total_pages > 1 &&(
              <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    props.paginateTab({text: searchText["stake_holders"], source: 'stake_holders', page: page, type: "get_stake_holders"}, () => "", () => "")
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: props.data.tablesv2.stake_holders.total_pages }, (v, k) => k + 1)}
                  currentPage={props.data.tablesv2.stake_holders.current_page}
                />
              </div>
              )
            }
          </div>                             
        )}    
      {contentStep === 'beneficiary_accounts' && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
              <Table 
                key={"beneficiary_accounts"+props.data.tablesv2.beneficiary_accounts.current_page}              
                dataTable={props.data.tablesv2.beneficiary_accounts}
                showSearcher={props.data.tablesv2.beneficiary_accounts.total_pages !== 1 || (searchText["beneficiary_accounts"] !== undefined && searchText["beneficiary_accounts"] !== "")}                
                action={{
                  title: internalization[languageUserAgent].action, 
                  show: (el) => props.showStakeHolder(el.url, false),
                  edit: (el) => props.showStakeHolder(el.url, true)
                }} 
                searchText={searchText["beneficiary_accounts"]}
                search={(text)=> props.paginateTab({text: text, source: 'beneficiary_accounts', page: 1, type: "get_beneficiary_accounts"}, () => setSearchText({...searchText, ["beneficiary_accounts"]: text}), () => "")}
              />
            </div>
            {
              props.data.tablesv2.beneficiary_accounts.rows.length > 0 && props.data.tablesv2.beneficiary_accounts.total_pages > 1 &&(
              <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    props.paginateTab({text: searchText["beneficiary_accounts"], source: 'beneficiary_accounts', page: page, type: "get_beneficiary_accounts"}, () => "", () => "")
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: props.data.tablesv2.beneficiary_accounts.total_pages }, (v, k) => k + 1)}
                  currentPage={props.data.tablesv2.beneficiary_accounts.current_page}
                />
              </div>
              )
            }
          </div>                             
        )}             
      {contentStep === 'linked' && props.data.tablesv2.linked && (
        <div className="c-tabs__content is-selected">
          <div className="c-tabs__content-top">
          <Table 
            key={"linked"+props.data.tablesv2.linked.current_page}                      
            dataTable={props.data.tablesv2.linked}
            showSearcher={props.data.tablesv2.linked.total_pages !== 1 || (searchText["linked"] !== undefined && searchText["linked"] !== "")}                            
            action={{
              title: internalization[languageUserAgent].action, 
              show: (element) =>  { 
                setShowAlertAsk(true)
                setLinkedResource(element)
              }
            }}
            searchText={searchText["linked"]}
            search={(text)=> props.paginateTab({text: text, source: 'linked', page: 1, type: "get_linked_assets"}, () => setSearchText({...searchText, ["linked"]: text}), () => "")}
            all_ellipsis={true}
          />
          </div>
          {
            props.data.tablesv2.linked.rows.length > 0 && props.data.tablesv2.linked.total_pages > 1 &&(
            <div className="c-tabs__content-bottom">
              <Pagination
                goTo={page => {
                  props.paginateTab({text: searchText["linked"], source: 'linked', page: page, type: "get_linked_assets"}, () => "", () => "")
                  // goToPage(page)
                }}
                pages={Array.from({ length: props.data.tablesv2.linked.total_pages }, (v, k) => k + 1)}
                currentPage={props.data.tablesv2.linked.current_page}
              />
            </div>
            )
          }
        </div>                             
      )}
      {contentStep === 'documents' && props.data.tablesv2.documents && (
        <div className="c-tabs__content is-selected">
          <div className="c-tabs__content-top">
          <Table 
            key={"documents"+props.data.tablesv2.documents.current_page}                      
            dataTable={props.data.tablesv2.documents}
            showSearcher={props.data.tablesv2.documents.total_pages !== 1 || (searchText["documents"] !== undefined && searchText["documents"] !== "")}             
            action={{
              title: internalization[languageUserAgent].action, 
              show: (element) =>  props.showDocument(element.url, false),
              edit: (element) => props.showDocument(element.url, true)
            }}
            searchText={searchText["documents"]}
            search={(text)=> props.paginateTab({text: text, source: 'documents', page: 1, type: "get_documents"}, () => setSearchText({...searchText, ["documents"]: text}), () => "")}
            all_ellipsis={true}
              hasFirstColumnBig={true}
          />
          </div>
          {
            props.data.tablesv2.documents.rows.length > 0 && props.data.tablesv2.documents.total_pages > 1 &&(
            <div className="c-tabs__content-bottom">
              <Pagination
                goTo={page => {
                  props.paginateTab({text: searchText["documents"], source: 'documents', page: page, type: "get_documents"}, () => "", () => "")
                  // goToPage(page)
                }}
                pages={Array.from({ length: props.data.tablesv2.documents.total_pages }, (v, k) => k + 1)}
                currentPage={props.data.tablesv2.documents.current_page}
              />
            </div>
            )
          }
        </div>                             
      )}

      {contentStep === 'contacts' && props.data.tablesv2.contacts && (
        <div className="c-tabs__content is-selected">
          <div className="c-tabs__content-top">
          <Table 
            key={"contact"+props.data.tablesv2.contacts.current_page}          
            dataTable={props.data.tablesv2.contacts}
            showSearcher={props.data.tablesv2.contacts.total_pages !== 1 || (searchText["contacts"] !== undefined && searchText["contacts"] !== "")}             
            action={{
              title: internalization[languageUserAgent].action, 
              show: (element) => props.showContact(element.url, false),
              edit: (element) => props.showContact(element.url, true),
              unlink: (el) => props.unlinkContact(el)
            }}
            searchText={searchText["contacts"]}
            search={(text)=> props.paginateTab({text: text, source: 'contacts', page: 1, type: "get_contacts"}, () => setSearchText({...searchText, ["contacts"]: text}), () => "")}
            all_ellipsis={true}
          />
          </div>
          {
            props.data.tablesv2.contacts.rows.length > 0 && props.data.tablesv2.contacts.total_pages > 1 &&(
            <div className="c-tabs__content-bottom">
              <Pagination
                goTo={page => {
                  props.paginateTab({text: searchText["contacts"], source: 'contacts', page: page, type: "get_contacts"}, () => "", () => "")
                  // goToPage(page)
                }}
                pages={Array.from({ length: props.data.tablesv2.contacts.total_pages }, (v, k) => k + 1)}
                currentPage={props.data.tablesv2.contacts.current_page}
              />
            </div>
            )
          }
        </div>                             
      )}
      {contentStep === 'alerts' && props.data.tablesv2.alerts &&(
        <div className="c-tabs__content is-selected">
          <div className="c-tabs__content-top">
          <Table 
            key={"alerts"+props.data.tablesv2.alerts.current_page}                      
            dataTable={props.data.tablesv2.alerts}
            showSearcher={props.data.tablesv2.alerts.total_pages !== 1 || (searchText["alerts"] !== undefined && searchText["alerts"] !== "")}             
            action={{
              title: internalization[languageUserAgent].action, 
              show: (element) => props.showAlert(element.id, false),
              edit: (element) => props.showAlert(element.id, true)
            }}
            searchText={searchText["alerts"]}
            search={(text)=> props.paginateTab({text: text, source: 'alerts', page: 1, type: "get_alerts"}, () => setSearchText({...searchText, ["alerts"]: text}), () => "")}
            all_ellipsis={true}
          />
          </div>
          {
            props.data.tablesv2.alerts.rows.length > 0 && props.data.tablesv2.alerts.total_pages > 1 && (
              <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    props.paginateTab({text: searchText["alerts"], source: 'alerts', page: page, type: "get_alerts"}, () => "", () => "")
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: props.data.tablesv2.alerts.total_pages }, (v, k) => k + 1)}
                  currentPage={props.data.tablesv2.alerts.current_page}
                />
              </div>
            )
          }
        </div>                             
      )}
      {contentStep === 'images' && props.data.tablesv2.images &&(
        <div className="c-tabs__content is-selected">
          {renderCarousel(props.data.tablesv2.images.rows)}
        </div>                             
      )}

      {showImageModal === true && (
        <Modal
          modalType="zoom-images"
          showBackButton={true}
          backAction={(e) => setShowImageModal(false)}
          showDeleteButton={false}
          //deleteAction={(e) => props.deleteImage(imagesToZoom)}
          isShowed={showImageModal}
          buttonText={props.internalization.buttons.back}
          paddingS={true}
          paddingBody={true}
          heightAuto={true}
        >
          {imagesToZoom.map((img, index) => {
            return (
              <>
                <div className="zoom-images__tools">

                  <div
                    className="zoom-images__tools-icon"
                    onClick={() => props.deleteImage(imagesToZoom)}
                  >
                    <Icon
                      width={"20px"}
                      height={"20px"}
                      noWrapper={true}
                      color={"#f3b01c"}
                      name="Garbage"
                      orientation={"right"}
                    />
                  </div>
                </div>
                <div className="zoom-images__wrapper">
                  <div className="zoom-images__wrapper-img">
                    <img
                      name={img.id}
                      key={index}
                      className="img-carousel"
                      src={img.url}
                      alt=""
                    />
                  </div>
                </div>
              </>
            );
          })}
        </Modal>
      )}
      {showAlertAsk && (
        <AlertModal
          isShowed={showAlertAsk}
          title={props.insurance_user ? props.internalization.alerts.navigate_to_asset : props.internalization.alerts.navigate_to_or_delete}
          // msg={"Mensaje"}
          action={() => {
            setShowAlertAsk(false);
            props.navigateTo(linkedResource);
          }}
          textButton={props.internalization.alerts.navigate_to}
          textButtonAlertColor={false}
          cancelButton={true}
          cancelTextButton={props.internalization.buttons.cancel}
          cancelButtonAlertColor={false}
          actionCancelButton={() => setShowAlertAsk(false)}
          thirdButton={props.insurance_user ? false : true}
          textButtonAlertColorThird={true}
          textButtonThird={props.internalization.buttons.unlink}
          actionThirdButton={() => {
            setShowAlertAsk(false);
            props.deleteLinkedResource(linkedResource);
          }}
        />
      )}

      {showToolsTable && (
        <ContentModal
          closeModal={() => setShowToolsTable(false)}
          toolTableTitle={dataRowActualPerformance.title}
          saveActualPerformance={(inputsActualPerformance) =>
              _saveActualPerformance(inputsActualPerformance)
          }
          inputs={inputs}
          dataRowActualPerformance={dataRowActualPerformance}
        />
      )}
    </>
  );
}

export default DetailTypeTwo;
