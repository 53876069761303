// Dependencies
import React from "react";

const Airplane = (props) => {
  return (
    <svg
      width={props.width ? props.width : "75px"}
      height={props.height ? props.height : "47px"}
      viewBox="0 0 75 47"
    >
      
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" stroke-linejoin="round">
            <g id="Vehicles---Airplane" transform="translate(-14.000000, -8.000000)" stroke={props.color ? props.color : "#80abba"}>
                <g id="Group" transform="translate(15.955078, 9.664702)">
                    <path d="M8.73486328,1.3343213 C7.84570313,0.721691088 4.93408203,0.276917322 0,1.42108547e-14 C5.77978516,12.4363757 8.90893555,18.9560192 9.38745117,19.5589307 C9.8659668,20.1618421 14.2229004,22.4120863 22.458252,26.3096631 C51.5230306,26.5308545 66.6615397,26.5308545 67.8737793,26.3096631 C69.6921387,25.977876 71.958252,25.0931104 71.958252,22.9517529 C71.958252,20.8103955 69.6073255,20.6042199 65.6796875,17.9954541 C61.7520495,15.3866883 59.5786387,14.1789443 57.1967773,13.6352979 C54.814916,13.0916514 21.9282227,13.8621963 19.9746094,13.2352979 C18.0209961,12.6083994 10.0686035,2.25326661 8.73486328,1.3343213 Z" id="Path-14" strokeWidth="2"></path>
                    <path d="M43.4245605,26.4755566 C30.8068848,36.9569206 23.7336426,42.5054639 22.204834,43.1211865 C20.6760254,43.7369092 16.2667643,43.8927523 8.97705078,43.5887158 L24.3730469,26.4755566 L43.4245605,26.4755566 Z" id="Path-15" strokeWidth="2"></path>
                    <path d="M26.9997559,13.4203135 L23.7683105,3.72006348 C29.4324544,3.54037598 32.6411133,3.70964682 33.3942871,4.22787598 C34.1474609,4.74610515 38.1092936,7.74940593 45.2797852,13.2377783 L26.9997559,13.4203135 Z" id="Path-16" strokeWidth="2"></path>
                    <polygon id="Path-17" fill={props.color ? props.color : "#80abba"} points="53.5235469 16.9613291 53.5235469 20.4188916 59.9900156 20.4188916 59.9900156 18.7115479 56.9581406 16.9613291"></polygon>
                    <polygon id="Path-18" fill={props.color ? props.color : "#80abba"} points="44.9424219 16.9597979 44.9424219 20.4188916 50.1869531 20.4188916 50.1869531 16.9613291"></polygon>
                    <polygon id="Path-18-Copy" fill={props.color ? props.color : "#80abba"} points="36.0449219 16.9597979 36.0449219 20.4188916 41.2894531 20.4188916 41.2894531 16.9613291"></polygon>
                    <polygon id="Path-18-Copy-2" fill={props.color ? props.color : "#80abba"} points="27.2449219 16.9597979 27.2449219 20.4188916 32.4894531 20.4188916 32.4894531 16.9613291"></polygon>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default Airplane;
