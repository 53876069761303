// Dependencies
import React from "react";
import { connect } from "react-redux";
import {
  TYPE_BUY,
  TYPE_DEPOSIT,
  TYPE_SELL,
  TYPE_SHELL_SHORT,
  TYPE_WITHDRAWAL,
  TYPE_ASSET_CREATED,
  TYPE_ASSET_DELETED,
  TYPE_VALUE_PLUS,
  TYPE_VALUE_MINUS
} from "../../constants";

// Actions
import {
  getNotification,
  getInputsNotification,
  filterNotification
} from "../../actions/notifications";

// Components
import ContentBlock from "../../components/contentBlock";
import Pagination from "../../components/pagination";
import LinkBlock from "../../components/linkBlock";
import NotificationModalShow from "./notificationsModalShow";
import { canManage, canRead } from "../../helpers/permits";
import Input from "../../components/input";
import Icon from "../../components/icons";
import SelectMultiple from "../../components/selectMultiple";
import Close from "../../components/icons/close";
import StackHeader from "../../components/stackHeader";
import EmptySearch from "../../components/emptySearch"

const moment = require("moment");

var blockScrollCall = false;
let page = 1;
var timer = null;
var notificationsNum = 0;

class NotificationsIndexScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showModal: false,
      notification_show: {},
      formData: {},
      form: [],
      inputsValue: {},
      activities: [],
      modalOptions: false,
      selectedOptions: [],
      total_pages: 0,
      current_page: 1,
      test: false,
      title: "", 
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  _showNotifications(notification) {
    this.setState({ showModal: true, notification_show: notification.show });
  }

  _goToResource(resource_url) {
    //go to resource
    if(resource_url.url.indexOf("ownership_structure") !== -1){
      window.location.href = "/"+resource_url.url+"/"+resource_url.id;
    }
    else{
      this.props.history.push("/balance-detail", {
        url: resource_url.url,
        id: resource_url.id
      });  
    }

    this.setState({ showModal: false });
  }

  _success(responseJSON) {
    this.setState({
      loading: false,
      activities: responseJSON.activities,
      modalOptions: false,
      total_pages: responseJSON.total_pages,
      test: !this.state.test,
      current_page: responseJSON.page,
      title: responseJSON.title
    });

    this.props.getBreadcrumbs({
      assetUrl: '/notifications',
      url: '/notifications',
      assetTitle: responseJSON.title
    });    

    blockScrollCall = true;

    //
  }

  _error() {
    alert("error");
  }

  _fetch(page) {
    blockScrollCall = false;
    this.setState({loading: true})
    this.props.getNotification(
      this.props.authentication_token,
      page,
      this._getUrl(),
      responseJSON => this._success(responseJSON),
      () => this._error()
    );
  }

  _fetchInputsNotification(url) {
    this.props.getInputsNotification(
      this.props.authentication_token,
      "",
      responseJson => this._successInputNotification(responseJson),
      () => this._error()
    );
  }

  _successInputNotification(responseJson) {
    this.setState({ form: responseJson });
  }

  componentDidMount() {
    page = 1;
    this._fetch(1);
    const $ = window.$;
    var that = this;
    document.addEventListener("click", this.handleClickOutside);

    $(window).scroll(function() {
      // How much the user has scrolled
      const scrollTop = $(window).scrollTop();

      if (scrollTop > 130) {
        $(".wrapper-filter").addClass("fixed");
      } else {
        $(".wrapper-filter").removeClass("fixed");
      }
    });

    this._fetchInputsNotification();
  }

  handleClickOutside(e) {

    let allMultiSelects = document.querySelectorAll('.options-multiple-select');
    let containerMultiSelects = document.querySelectorAll('.container-multiple-select')
    let multiSelectIcon = document.querySelectorAll('.icons-multiple-select')

    for (let i = 0; i < allMultiSelects.length; i++) {
      if (allMultiSelects.length > 0 && multiSelectIcon.length > 0 && containerMultiSelects.length > 0 && containerMultiSelects && !containerMultiSelects[i].contains(e.target)) {

        containerMultiSelects[i].classList.remove('open')
         multiSelectIcon[i] && multiSelectIcon[i].classList.remove('rotate')
        allMultiSelects[i].style.height = 0

        setTimeout(function () { containerMultiSelects[i].style.zIndex = 0 }, 500);

        allMultiSelects[i].classList.remove('open')

      }
    }
      
  }

  _isPositiveByType(type) {
    return [TYPE_VALUE_PLUS, TYPE_ASSET_CREATED].includes(type);
  }

  _getInputValue(value, bigger, element, state) {
    let data = state.inputsValue;
    if (element.id === "start_date_activity") {
      data.start_date = moment(value).format("MM/DD/YYYY");
    } else if (element.id === "end_date_activity") {
      data.end_date = moment(value).format("MM/DD/YYYY");
    }

    this.setState({
      inputsValue: data
    });
  }

  _getUrl() {
    var inputsValue2 = Object.assign({}, this.state.inputsValue);

    if (inputsValue2.type !== undefined) {
      var newTypes = inputsValue2.type.map(item => item.id);
      inputsValue2.type = newTypes;
    }

    var url = "";
    for (let key in inputsValue2) {
      if (inputsValue2[key] !== undefined) {
        url += "&" + key + "=" + inputsValue2[key];
      }
    }

    return url;
  }

  _prepare_dates(state){
    let current_date = new Date();
    let current_tomorrow = new Date();
    current_tomorrow = current_tomorrow.setDate(current_tomorrow.getDate() + 1)
    current_tomorrow = moment(current_tomorrow).toDate()

    let start_date = state.inputsValue.hasOwnProperty("start_date") ? state.inputsValue["start_date"] : ""
    let end_date = state.inputsValue.hasOwnProperty("end_date") ? state.inputsValue["end_date"] : ""
    
    start_date = moment(start_date).format("MM/DD/YYYY")
    end_date = moment(end_date).format("MM/DD/YYYY")

    if (start_date === "Invalid date") start_date = ""
    if (end_date === "Invalid date") end_date = ""

    return{
      start_date_activity: start_date, 
      end_date_activity: end_date
    }

  }

  _getOptions(items, state) {
    let data = state.inputsValue;
    data.type = items;
    this.setState({ inputsValue: data, selectedOptions: items });

    clearTimeout(timer);
    var that = this;
    timer = setTimeout(() => {
      that._fetch(1);
    }, 1000);
  }

  _deleteFilter(item, state) {
    let filters = state.inputsValue.type;
    let newInputsValue = state.inputsValue;

    filters = filters.filter(element => element.value !== item.value);

    if (filters.length > 0) {
      newInputsValue.type = filters;
    } else {
      delete newInputsValue.type;
    }

    this.setState({
      inputsValue: newInputsValue,
      selectedOptions: newInputsValue
    });

    page = 1;
    this._fetch(1);
    
  }

  _goToPage(page) {
    this._fetch(page);
  }

  _checkNotificationTitle(notification){
    let title = notification.index.title
    if (title == "" || (Array.isArray(title) && title.every(element => element === null)) ){
      try{
        title = notification.show.summary + " " + notification.show.asset_name
      }catch (e) {
        title = "-"
      }
    }
    return title;
  }

  render() {
    
    return (
      <>
        <div className="main-wrapper">
          <StackHeader
            title={this.state.title}
            icon={<Icon name="LastActivity" colorPrimary={'#005774'} colorSecondary="#f3b01c" iconBig={true} />}
            help={false}
            helpAction={() => alert("boton de info del stackHeader")}
          />
          <div className="wrapper-filter">
            <div className="container-filter-inputs">
              {this.state.form.length > 0 &&
                this.state.form.map((item, index) => {
                  return item.type === "date" ? (
                    <div className="wrapper-form-item">
                      <span className="input-label">{item.label}</span>
                      <Input
                        colorGrey={true}
                        id={item.id}
                        type={item.type}
                        name={item.name}
                        value=""
                        defaultValue=""
                        formData={this.state.formData}
                        onChangeInput={(date, bigger) =>
                          this._getInputValue(date, bigger, item, this.state)
                        }
                        prepare_dates={this._prepare_dates(this.state)}
                      />
                    </div>
                  ) : (
                    <SelectMultiple
                      colorGrey={true}
                      setRef={ref => (this.selectMultiple = ref)}
                      label={item.label}
                      defaultValue={"Select"}
                      options={item.options}
                      disabled={false}
                      multiple={true}
                      openOptions={() =>
                        this.setState({
                          modalOptions: !this.state.modalOptions
                        })
                      }
                      modalOptions={this.state.modalOptions}
                      getOptions={items => this._getOptions(items, this.state)}
                      itemsSelected={
                        this.state.inputsValue.type !== undefined
                          ? this.state.inputsValue.type
                          : []
                      }
                    />
                  );
                })}
              <div className="wrapper-btn">
                <div
                  className="icon-notification"
                  onClick={() => this._fetch(1)}
                >
                  <Icon
                    name="Search"
                    width={20}
                    height={20}
                   
                  />
                </div>
                <div
                  className="icon-notification"
                  onClick={() => {
                    
                    this.setState({
                      inputsValue: { type: [] },
                      modalOptions: false,
                      selectedOptions: []
                    }, () => this._fetch(1));
                  }}
                >
                  <Icon
                    name="Close"
                    close={true}
                    width={16}
                    height={16}
                   
                  />
                </div>
              </div>
            </div>
            {
              this.state.inputsValue.type !== undefined &&
              <div className="container-filter-options">
              {this.state.inputsValue.type !== undefined &&
                this.state.inputsValue.type.map((item, index) => {
                  return (
                    <p
                      className="filter-option"
                      onClick={() => this._deleteFilter(item, this.state)}
                    >
                      {item.value}{" "}
                      <span className="filter-option-icon">
                        <Close
                          close={true}
                          width={8}
                          height={8}
                          color={"#787D7F"}
                        />
                      </span>
                    </p>
                  );
                })}
            </div>
            }
            
          </div>

            <ContentBlock className="centered-and-full">
            {(canRead(this.props.permits, "rest_assets") ||
              canRead(this.props.permits, "ofinancial_assets")) &&
              this.props.notifications &&
              this.state.activities.map((notification, index) => {
                console.log(notification.index.title)
                return (
                  <LinkBlock
                    key={index}
                    balance={true}
                    currency={true}
                    date={notification.index.date}
                    dateBig={true}
                    value={notification.index.value}
                    description={this._checkNotificationTitle(notification)}
                    action={() => this._showNotifications(notification)}
                    colorValue={notification.index.value > 0 ? "blue" : "orange"}
                  />
                );
              })}
            {!canRead(this.props.permits, "rest_assets") &&
              !canRead(this.props.permits, "financial_assets") && (
                <p> no access </p>
              )}
          </ContentBlock>


          {
            this.state.activities.length === 0 && !this.state.loading &&
            <EmptySearch nameIcon="BoxEmpty" text="No data" />
          }

          
        </div>

        <Pagination
          goTo={page => this._goToPage(page)}
          pages={Array.from(
            { length: this.state.total_pages },
            (v, k) => k + 1
          )}
          currentPage={this.state.current_page}
        />

        {this.state.showModal && (
          <NotificationModalShow
            internalization={this.props.internalization}
            notification={this.state.notification_show}
            go_to_resource_text={this.props.internalization.buttons.go_to_resource}
            goToResource={resource_url => this._goToResource(resource_url)}
            hideModal={e => {
              if (
                e.target.className === "modal-wrapper" ||
                (e.target.closest(".modal-header") !== null &&
                  e.target.closest(".modal-header").className.includes("modal-header"))
              ) {
                this.setState({
                  notification_show: {},
                  showModal: false
                });
              }
            }}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    notifications: state.notifications.notifications,
    total_pages: state.notifications.total_pages,
    permits: state.session.permits,
    internalization: state.session.internalization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getNotification: (authentication_token, page, url, success, error) =>
      dispatch(
        getNotification(authentication_token, page, url, success, error)
      ),
    getInputsNotification: (authentication_token, url, success, error) =>
      dispatch(getInputsNotification(authentication_token, url, success, error))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsIndexScreen);
