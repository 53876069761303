const initialState = {
  documents_index: [],
  documents_show: [],
  documents_detail: [],
  contacts_detail: []
};

export default function documentsReducer(state = initialState, action) {

  switch (action.type) {
    case "DOCUMENTS_INDEX":
      return Object.assign({}, state, {
        documents_index: action.payload,
      });
    case "DOCUMENTS_SHOW":
      return Object.assign({}, state, {
        documents_show: action.payload,
      });
      case "DOCUMENTS_DETAIL":
      return Object.assign({}, state, {
        documents_detail: action.payload,
      });
    case "CONTACTS_DETAIL":
      return Object.assign({}, state, {
        contacts_detail: action.payload,
      });      
    case "CLEAN_DOCUMENTS_SHOW": 
      return Object.assign({}, state, {
        documents_show: [],
      });
    default:
      return state;
  }
}