// Dependencies
import React from 'react';

const Lock = ({ width, height, color }) => {

    return (

        <svg 
            width={ width ? width : '72px'}
            height={ height ? height : '94px'}
            viewBox="0 0 72 94"
        >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="locked-padlock" fill={color ? color : "#80abba" } fillRule="nonzero">
                    <path d="M66.79296,37.740096 L64.972032,37.740096 L64.972032,29.2176 C64.972032,13.454592 52.46304,0.286848 36.828288,0.00576 C36.401088,-0.00192 35.547456,-0.00192 35.120448,0.00576 C19.485312,0.286848 6.976512,13.454592 6.976512,29.2176 L6.976512,37.740096 L5.155392,37.740096 C2.34912,37.740096 0.060096,40.63584 0.060096,44.212224 L0.060096,86.957568 C0.060096,90.52992 2.34912,93.452736 5.155584,93.452736 L66.79296,93.452736 C69.599232,93.452736 71.888448,90.52992 71.888448,86.957568 L71.888448,44.212224 C71.888448,40.636032 69.599424,37.740096 66.79296,37.740096 Z M41.748864,65.541504 L41.748864,78.457536 C41.748864,79.936512 40.510656,81.18816 39.030528,81.18816 L32.9184,81.18816 C31.43808,81.18816 30.199872,79.936512 30.199872,78.457536 L30.199872,65.541504 C28.764288,64.128384 27.928512,62.1744 27.928512,60.012864 C27.928512,55.916736 31.094784,52.396992 35.12064,52.234368 C35.547264,52.217088 36.401856,52.217088 36.82848,52.234368 C40.854336,52.396992 44.020608,55.916736 44.020608,60.012864 C44.020224,62.1744 43.184448,64.128384 41.748864,65.541504 Z M52.970304,37.740096 L36.828288,37.740096 L35.120448,37.740096 L18.978624,37.740096 L18.978624,29.2176 C18.978624,19.82784 26.609472,12.063936 35.974272,12.063936 C45.339072,12.063936 52.970304,19.82784 52.970304,29.2176 L52.970304,37.740096 L52.970304,37.740096 Z" id="Shape"></path>
                </g>
            </g>
        </svg>
    );

}

export default Lock;
