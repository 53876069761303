// Dependencies
import React from "react";
import { connect } from "react-redux";
import { HOST_URL, internalization } from "../../constants";

// Actions
import { getHome, getFilteredDataBarsGraph, inputsForm } from "../../actions/home";
import { sendDeviceToken } from "../../actions/send_device_info";
import { setCurrentBreadcrumb } from "../../actions/session";
import {updateAlertInvited} from "../../actions/alerts";
import {
  updateAutomaticLayout,
  getWealthMap,
  saveOwnershipStructure,
  getListElement,
  createWildCardStructure,
  editElement,
  getPalettes,
  deleteNode,
} from "../../actions/ownership";

// Components
import Icon from "../../components/icons";
import ContentBlock from "../../components/contentBlock";
import Advertisement from "../../components/advertisement";
import LinkBlock from "../../components/linkBlock";
import TreeGraphic from "../../components/treeGraphic";
import VideoBlock from "../../components/videoBlock";
import NestedListOwnership from "../../components/nestedListOwnership";
import NewNestedList from "../../components/newNestedList";
import NoResources from "../../components/noResources";
import BarsGraphic from "../../components/barsGraphic";
import ActionSheet from "../../components/actionSheet";
import FloatButton from "../../components/floatButton";
import Loading from "../../components/loading";
import CustomModal from "../../components/customModal";
import ModalPendingAlerts from "../../components/modalPendingAlerts";
import ModalDeleteAlerts  from "../../components/modalDeleteAlerts";
import ModalPermission from "../../components/modalPermission";
import ModalChangeUser from "../../components/modalChangeUser";
import AlertModal from "../../components/alertModal";
import Modal from "../../components/modal"
import NotificationModalShow from "../notifications/notificationsModalShow";
import treeGraphic_fake from "../../assets/images/treegraphic_fake.png";
import lastActivity_fake from "../../assets/images/lastActivity_fake.png";
import linkBlock_fake from "../../assets/images/linkBlock_fake.png";
import barsGraphic_fake from "../../assets/images/barGraphic_fake.png";
import DownloadApp from "../../components/downloadApp";
import GojsCanvas from "../../components/goJs/gojsCanvas";

import { canManage, canRead } from "../../helpers/permits";

import googlePlay from "../../assets/images/google-play.png";
import appleStore from "../../assets/images/apple-store.png";

// Helpers
import ActionSheetOnPressAction from "../../helpers/actionSheetOnPressAction";

var languageUserAgent = /^es/.test(
  navigator.language || navigator.userLanguage || "en"
)
  ? "es"
  : "en";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      refreshing: false,
      notification_show: {},
      showModalNotifications: false,
      filteredDataBarsGraph: {},
      options_action_sheet_short_cut: [],
      showModalInfo: false,
      showModalChangeUser: false,
      palettes: {},
      isNew: false,
      diagram: null,      
    };

    this.actionSheet = React.createRef();
  }
  
  componentWillMount() {
    this._fetchHome();
  }

  _fetchHome() {
    this.props.getHome(
      this.props.authentication_token,
      () => this._success(),
      () => this._error()
    );
        
  }

  _goToResource(resource_url) {
      //go to resource
      if(resource_url.url.indexOf("ownership_structure") !== -1){
        window.location.href = "/"+resource_url.url+"/"+resource_url.id;
      }
      else{
        this.props.history.push("/balance-detail", {
          url: resource_url.url,
          id: resource_url.id
        });  
      }

      this.setState({ showModal: false });
    }
    
  _success() {
    
    this.setState({ loading: false, refreshing: false, showModalChangeUser: true });
    let languageUserAgent = /^es/.test(this.props.user.language) ? "es" : "en";
    this.props.setSpecialParent([
      {
        title: internalization[languageUserAgent].models.home,
        code: "home",
        url: "/home",
      },
    ]);
    this.props.setCurrentBreadcrumb([
      {
        title: internalization[languageUserAgent].models.home,
        code: "home",
        url: "/home",
      },
    ]);
    this.props.getWealthMap(
      this.props.authentication_token,
      (responseJSON) => {
        this._filteredBarsFinancialAction("balance_principal/net_worths?frecuency=month&times=10")
      },
      () => this._error()
    );
    
    setTimeout(() => {
      this.props.inputsForm(this.props.authentication_token, () => this._fillActionSheetStart(), () => console.log("ko"))  
    }, 2000)
    this._sendDeviceInfo();
  }

  _sendDeviceInfo() {
    var sys = "";
    var ua = navigator.userAgent.toLowerCase();

    let s = ua.match(/msie ([\d.]+)/)
      ? "msie: " + ua.match(/msie ([\d.]+)/)[1]
      : ua.match(/firefox\/([\d.]+)/)
      ? "firefox: " + ua.match(/firefox\/([\d.]+)/)[1]
      : ua.match(/chrome\/([\d.]+)/)
      ? "chrome: " + ua.match(/chrome\/([\d.]+)/)[1]
      : ua.match(/opera.([\d.]+)/)
      ? "opera: " + ua.match(/opera.([\d.]+)/)[1]
      : ua.match(/version\/([\d.]+).*safari/)
      ? "safari: " + ua.match(/version\/([\d.]+).*safari/)[1]
      : 0;

    this.props.sendDeviceToken(
      this.props.authentication_token,
      "",
      "web",
      s,
      ""
    );
  }

  _isSubcategories(item){
    
    if(item.form !== undefined){
      if(item.code === "life_insurance"){
        if(this.props.bulltick_insurance_agency && this.props.real_user_insurance){
          return false
        }else if(this.props.bulltick_insurance_agency && !this.props.real_user_insurance) {
          return true
        }else {
          return false
        }
      }else {
        return false
      }
      
    }else {
      return true
    }
  }


  _fillActionSheetStart() {
    
    let options_action_sheet_short_cut = [];
    if (Object.keys(this.props.home_data).length > 0) {
      options_action_sheet_short_cut = this.props.home_data.tree_graphic_data.short_cuts && this.props.home_data.tree_graphic_data.short_cuts.map(
        (item) => {
          return {
            type: "icon button",
            iconName: "Close",
            message: item.title,
            code: item.code,
            onClick: () => {
              this._isSubcategories(item)
                ? this._showSubcategoriesSheet(item)
                : this.gotToAsset(item) ;
              this.actionSheet.current.show();
            },
          };
        }
      );
    }
    
    if(options_action_sheet_short_cut === undefined){
      options_action_sheet_short_cut = []
    }
    options_action_sheet_short_cut.push({
      type: "noIcon button",
      message: this.props.internalization.buttons.cancel,
      close: true,
      danger: true,
    });

    this.setState({
      options_action_sheet_short_cut: options_action_sheet_short_cut,
    });
  }

  _prepareAddPlusLink(item) {
    let multiple_new = this.props.home_data.tree_graphic_data.multiple_new[
      item.code
    ];
    let associated_elements = [];
    try {
      associated_elements = [
        { add_document: multiple_new["form_document"].add_document },
        { add_contact: multiple_new["form_contact"].add_contact },
      ];
      if (
        multiple_new["form_alerts"] &&
        Object.keys(multiple_new["form_alerts"]).length > 0
      ) {
        associated_elements.push({
          add_alert: multiple_new["form_alerts"].add_alerts,
        });
      }
    } catch (e) {}
    return associated_elements;
  }

  gotToAsset(item) {
    this.props.history.push("balance-new", {
      form: item.form.add_asset.inputs,
      resource_key: item.form.add_asset.resource_key,
      url: item.url,
      method: "POST",
      code: "add_asset",
      from: "Home",
      modal: item.form.add_asset.modal,
      resource_constant: item.code ? this.props.home_data.tree_graphic_data.multiple_new[
        item.code
      ] ? this.props.home_data.tree_graphic_data.multiple_new[
        item.code
      ].resource_constant : "" : "",
      title: this.props.internalization.buttons.add + " " + item.title,
      associated_elements: this._prepareAddPlusLink(item),
    });
  }

  _restartActionSheet() {
    this._fillActionSheetStart();
    // this.setState({
    //   options_action_sheet_short_cut: options_action_sheet_short_cut
    // });
  }

  _showSubcategoriesSheet(item) {
    
    var that = this;
    let options_action_sheet_short_cut = item.subcategories.map(
      (item_subcategory) => {
        return {
          type: "icon button",
          iconName: "Close",
          message: item_subcategory.title,
          onClick: () => {
            if (item_subcategory.form !== undefined) {
              this.gotToAsset(item_subcategory);
              this.actionSheet.current.show();
              this._restartActionSheet();
            } else {
              this.props.history.push("balance-show", {
                url: item_subcategory.url,
                open_actions: true,
              });
              this.actionSheet.current.show();
              this._restartActionSheet();
            }
          },
        };
      }
    );
    options_action_sheet_short_cut.push({
      type: "noIcon button",
      message: this.props.internalization.buttons.cancel,
      close: true,
      danger: true,
      onClick: () => this._restartActionSheet(),
    });

    this.setState({
      options_action_sheet_short_cut: options_action_sheet_short_cut,
    });
    setTimeout(function () {
      that.actionSheet.current.show();
    }, 100);
  }

  _error() {
    alert("error");
    this.setState({ refreshing: false });
  }

  _ownershipAction(id, url) {
    if (url.indexOf("https://") != -1 || url.indexOf("http://") != -1) {
      window.location = url;
    }
  }

  _filteredBarsFinancialAction(url) {
    this.props.getFilteredDataBarsGraph(
      this.props.authentication_token,
      url,
      () => this._successFilteredBars(),
      () => this._errorFilteredBars()
    );
  }

  _successFilteredBars() {
    this.setState({
      filteredDataBarsGraph: this.props.filtered_data_bars_graph,
    });
  }

  _errorFilteredBars() {
    console.log("error");
  }


  _success_check_length() {
    if(this.props.alerts_deleted.length === 0 && this.props.alerts_pending.length === 0){
      document.body.classList.remove("has-modal")
    }
  }

  _checkNotificationTitle(notification){
    let title = notification.index.title
    if (title == "" || (Array.isArray(title) && title.every(element => element === null)) ){
      try{
        title = notification.show.summary + " " + notification.show.asset_name
      }catch (e) {
        title = "-"
      }
    }
    return title;
  }

  _isInsuranceUser(type){

    if(type === "structure"){
        if(this.isStructure()){
            return true
          }else {
            return false
          }
    }

    if(type === "add"){
      if(!this.isStructure()){
        if(canRead(this.props.permits, "ownership")){
          return true
        }
      }
    }

    if(type === "modal"){
      if(this.isStructure()){
        return false
      }else {
        if(!canRead(this.props.permits, "ownership")){
          return true
        }else {
         return false
        }
      }
    }

    if(type === "lock"){
      if(this.isStructure()){
        if(!canRead(this.props.permits, "ownership")){
          return true
        }else {
          return false
        }
        
      }else {
        if(!canRead(this.props.permits, "ownership")){
          return false
        }else {
          return true
        }
      }
    }
  }

  isStructure() {
    if(this.props.home_data.ownership_structure && this.props.home_data.ownership_structure.data[0] &&
      this.props.home_data.ownership_structure.data[0].descendants.length > 0){
        return true
      }else{
        return false
      }
  }

  showButtonOwnership(){
    if(this.props.insurance_user) {
      if(canRead(this.props.permits, "ownership")){
        return true
      }else {
        return false
      }
      
    }else {
      if(canRead(this.props.permits, "ownership") && this.isStructure()){
        return true
      }else {
        return false
      }
    }

  }

  actionButtonOwnership() {
    if((this.props.insurance_user && !canRead(this.props.permits, "ownership")) || (this.props.admin_insurance && !canRead(this.props.permits, "ownership")) || !canRead(this.props.permits, "ownership")) {
     return this.setState({showModalInfo: true})
    }else {
      if(canRead(this.props.permits, "ownership")){
        if(this.isStructure()){
          return this.props.history.push("/ownership-index")
        }else {
          //falta meter el create de ownership
          return this.props.history.push("/ownership-index")
        }
        
      }else {
        return false
      }
    }
  }

  render() {

    const { home_data } = this.props;
    console.log(canRead(this.props.permits, "ownership"))
    return (
      <div>
        {this.state.loading && (
          <Loading text={this.props.internalization.loading} />
        )}

        <DownloadApp
          mainText={internalization[languageUserAgent].download_app_text}
          textButton={internalization[languageUserAgent].download_app_button}
          text1={internalization[languageUserAgent].download_app_question}
          text2={internalization[languageUserAgent].download_app}
        />

        {Object.keys(home_data).length > 0 && !this.state.noAccess && (
          <div className="main-wrapper">
            <ContentBlock
              className={"centered-and-full"}
              title={this.props.home_data.tree_graphic_data.title}
              titleExtraBig={true}
              icon={
                <Icon
                  name="Balance"
                  iconBig={true}
                  colorPrimary={"#005774"}
                  colorSecondary="#f3b01c"
                />
              }
              help={false}
              helpAction={() => alert("Net Worth Summary action")}
              button={
                canRead(this.props.permits, "rest_assets") &&
                this.props.real_user_insurance !== true
              }
              buttonAction={
                this.props.real_user_insurance !== true
                  ? () => this.props.history.push("/balance-index")
                  : undefined
              }
              buttonText={this.props.home_data.tree_graphic_data.buttons.footer}
              buttonTextSecond={
                this.props.home_data.tree_graphic_data.buttons.add
              }
              buttonActionSecond={() => this.actionSheet.current.show()}
              header={true}
              multipleButtons={canManage(this.props.permits, "rest_assets")}
              style={{ paddingTop: 48 }}
            >
              {
                Object.keys(this.props.home_data.tree_graphic_data).length >
                  0 &&
                (this.props.home_data.tree_graphic_data.counters.active !=
                  "0" ||
                  this.props.home_data.tree_graphic_data.counters.pasive !=
                    "0") && (
                  <TreeGraphic
                    data={home_data.tree_graphic_data}
                    history={this.props.history}
                    bulltick_insurance_agency={
                      this.props.bulltick_insurance_agency
                    }
                    real_user_insurance={this.props.real_user_insurance}
                    positive_balance={
                      this.props.home_data.tree_graphic_data.positive_balance
                    }
                    insuranceUser={this.props.insurance_user}
                    add_translation={
                      this.props.internalization.buttons
                        ? this.props.internalization.buttons.add
                        : "add"
                    }
                    total={this.props.home_data.tree_graphic_data.total}
                    canManage={
                      this.props.insurance_user
                        ? true
                        : canManage(this.props.permits, "rest_assets")
                    }
                    permits={this.props.permits}
                    from={"Home"}
                    language={this.props.user.language}
                    openModalPermissions={() =>
                      this.setState({ showModalInfo: true })
                    }
                  />
                )}

              {/*!canRead(this.props.permits, "rest_assets") &&
                !canRead(this.props.permits, "financial_assets") && (
                  <div className="content-fake">
                    <div className="content-fake__icon">
                      <Icon name="Lock" color="#005774" />
                      <p className="headingH4">Not granted</p>
                    </div>
                    <img
                      src={treeGraphic_fake}
                      className="content-fake__image"
                    />
                  </div>
                )*/}
              {/*(canRead(this.props.permits, "rest_assets") ||
                canRead(this.props.permits, "financial_assets")) &&
                Object.keys(this.props.home_data.tree_graphic_data).length >
                  0 &&
                this.props.home_data.tree_graphic_data.type.active.length ==
                  0 &&
                this.props.home_data.tree_graphic_data.type.pasive.length ==
                  0 && <span style={{ lineHeight: 16 }}>No data yet</span>*/}
              {/* <NoResources text="No assets yet" button={true} btnText="add new asset" class="height" buttonAction={() => {}}/> */}
            </ContentBlock>
            {
              canRead(this.props.permits, "wealth_map") &&
                <ContentBlock
                className={"centered-and-full"}              
                anchor={"net_worth_evolution"}
                title={this.props.home_data.wealth_map.title}
                header={true}
                icon={
                  <Icon
                    name="Structure"
                    colorPrimary={"#005774"}
                    colorSecondary="#f3b01c"
                    iconBig={true}
                  />
                }
                // className="centered-and-full"
                help={false}
                buttonAction={() => this.props.history.push("/wealth-map")}
                header_pointer={false}
                balance={true}
                maxHeight={false}
              >
              <div className="main-wrapper ownership">
              {
                this.props.ownership_index && this.props.ownership_index.ownership_structure &&
              <GojsCanvas
                isHome={true}
                isMobile={false}
                internalization={this.props.internalization}
                palettes={this.state.palettes}
                ownership_structure={this.props.ownership_index.ownership_structure}
                saveOwnershipStructure={(ownership_structure, success) =>
                  ""
                }
                getListElement={(url, success_list, error_list) =>
                  this.props.getListElement(
                    this.props.authentication_token,
                    url,
                    (responseJSON) => success_list(responseJSON),
                    () => error_list()
                  )
                }
                createWildCardStructure={(object, success_create_wild_card, error_list) =>
                  this.props.createWildCardStructure(
                    this.props.authentication_token,
                    object,
                    (responseJSON) => success_create_wild_card(responseJSON),
                    () => error_list()
                  )
                }
                deleteNode={(object, success_delete_noe, error_list) =>
                  this.props.deleteNode(
                    this.props.authentication_token,
                    object,
                    (responseJSON) => success_delete_noe(responseJSON),
                    () => error_list()
                  )
                }
                editElement={(attributes, success_edit, error_edit) =>
                  this.props.editElement(
                    this.props.authentication_token,
                    attributes,
                    () => success_edit(),
                    () => error_edit()
                  )
                }
                getPalettes={(success_palettes) =>
                  this.props.getPalettes(this.props.authentication_token, (responseJSON) =>
                    this.setState({ palettes: responseJSON, isNew: false })
                  )
                }
                isNew={this.state.isNew}
                location={this.props.location}
                history={this.props.history}
                fetch={() => this._fetch()}
                inputs_creation={this.state.inputs_creation}
                showModalCreation={(data) => this.setState({ showModal: true, modalData: data })}
                setDiagram={(diagram) => this.setState({ diagram: diagram })}
                removeLastElement={this.state.remove_last_element}
                updateAutomaticLayout={(value) => this._updateAutomaticLayout(value)}
                currentAutomaticLayout={this.props.user.ownership_automatic_layout_enabled}
                avatar={this.props.user.avatar_real_user}
              />
              }

                </div>
              </ContentBlock>
            }
            <div className="super-wrapper-content-block">
              <ContentBlock
                anchor={"net_worth_evolution"}
                title={Object.keys(this.state.filteredDataBarsGraph).length == 0 ? this.props.home_data.bars_graphic_data.title : this.state.filteredDataBarsGraph.title}
                header={true}
                icon={
                  <Icon
                    name="Performance"
                    colorPrimary={"#005774"}
                    colorSecondary="#f3b01c"
                    iconBig={true}
                  />
                }
                // className="centered-and-full"
                help={false}
                buttonAction={
                  this.props.insurance_user !== true
                    ? () =>
                        this.props.history.push(
                          "/balance-index#net_worth_evolution"
                        )
                    : undefined
                }
                header_pointer={false}
                balance={true}
                maxHeight={false}
              >
                {
                  Object.keys(this.state.filteredDataBarsGraph).length == 0 &&
                  <Loading simple={true} text={this.props.internalization.loading} />
                }
                {Object.keys(this.state.filteredDataBarsGraph).length > 0 && (
                  <BarsGraphic
                    data={
                      Object.keys(this.state.filteredDataBarsGraph).length > 0
                        ? this.state.filteredDataBarsGraph
                        : this.props.home_data.bars_graphic_data
                    }
                    filterAction={(url) =>
                      this._filteredBarsFinancialAction(url)
                    }
                    xTickValues={
                      Object.keys(this.state.filteredDataBarsGraph).length > 0
                        ? this.state.filteredDataBarsGraph.type.pasive.map(
                            (elem) => elem.x
                          )
                        : this.props.home_data.bars_graphic_data.type.pasive.map(
                            (elem) => elem.x
                          )
                    }
                    yTickValues={
                      Object.keys(this.state.filteredDataBarsGraph).length > 0
                        ? this.state.filteredDataBarsGraph.type.pasive.map(
                            (elem) => elem.y
                          )
                        : this.props.home_data.bars_graphic_data.type.pasive.map(
                            (elem) => elem.y
                          )
                    }
                  />
                )}

                {/*!canRead(this.props.permits, "rest_assets") &&
                  !canRead(this.props.permits, "financial_assets") && (
                    <div className="content-fake">
                      <div className="content-fake__icon">
                        <Icon name="Lock" color="#005774" />
                        <p className="headingH4">Not granted</p>
                      </div>
                      <img
                        src={barsGraphic_fake}
                        className="content-fake__image"
                      />
                    </div>
                  )*/}
              </ContentBlock>
              <ContentBlock
                title={home_data.ownership_structure.title}
                icon={
                  <Icon
                    name="OwnershipBig"
                    colorPrimary={"#005774"}
                    colorSecondary="#f3b01c"
                    iconBig={true}
                  />
                }
                button={this.showButtonOwnership()}
                buttonAction={() => this.actionButtonOwnership()}
                buttonText={this.props.home_data.tree_graphic_data.buttons.footer}
                isInsurance={!this._isInsuranceUser()}
                animationButton={true}
                header={true}
                maxHeight={true}
                contentClass={
                  canRead(this.props.permits, "ownership") &&
                  this.props.home_data.ownership_structure.data[0] &&
                  this.props.home_data.ownership_structure.data[0].descendants
                    .length === 0
                    ? "centered scroll js-limit-scroll"
                    : "scroll  js-limit-scroll"
                }
              >
                {canRead(this.props.permits, "ownership") &&
                  this._isInsuranceUser("structure") &&
                  this.props.home_data.ownership_structure.data[0] &&
                  this.props.home_data.ownership_structure.data[0].descendants
                    .length > 0 && (
                    <NestedListOwnership
                      mainClass={"nestedlist--padding"}
                      color={"nestedlist__item--grey"}
                      data={home_data.ownership_structure.data}
                      ownership={true}
                      action={(id, url) => this._ownershipAction(id, url)}
                      levelColors={true}
                      square={"nestedlist__item--square"}
                    />
                  )}

                {this._isInsuranceUser("add") &&
                  this.props.home_data.ownership_structure.data[0] &&
                  this.props.home_data.ownership_structure.data[0].descendants
                    .length === 0 && (
                    <div>
                      <div className="block-empty block-empty--margin-botom">
                        <div className="block-empty__content">
                          <Icon
                            name="Close"
                            close={false}
                            color={"white"}
                            width={"24px"}
                          />
                        </div>
                      </div>
                      <p className="headingH2">
                        {this.props.home_data.ownership_structure.no_data_title}
                        {/* <br />
                        <br />
                        {
                          this.props.home_data.ownership_structure
                            .no_data_title_2
                        } */}
                      </p>
                    </div>
                  )}

                {!canRead(this.props.permits, "ownership") &&
                  this._isInsuranceUser("lock") && (
                    <div className="content-fake">
                      <div className="content-fake__icon">
                        <Icon name="Lock" color="#005774" />
                        <p className="headingH4">Not granted</p>
                      </div>
                      <img
                        src={linkBlock_fake}
                        className="content-fake__image"
                      />
                    </div>
                  )}

                {this._isInsuranceUser("modal") && (
                  <div className="block-empty">
                    <div className="block-empty__content">
                      <Icon
                        name="Close"
                        close={false}
                        color={"white"}
                        width={"24px"}
                      />
                    </div>
                  </div>
                )}
              </ContentBlock>
            </div>
            <div className="super-wrapper-content-block">
              <ContentBlock
                title={home_data.activities.title}
                icon={
                  <Icon
                    name="LastActivity"
                    colorPrimary={"#005774"}
                    colorSecondary="#f3b01c"
                    iconBig={true}
                  />
                }
                help={false}
                helpAction={() => alert("Últimas Actividades action help")}
                button={
                  canRead(this.props.permits, "rest_assets") ||
                  canRead(this.props.permits, "financial_assets")
                }
                buttonAction={() => this.props.history.push("/notifications")}
                buttonText={
                  this.props.home_data.tree_graphic_data.buttons.footer
                }
                header={true}
                maxHeight={true}
              >
                {/* <NoResources text="No assets yet" btnText="add new asset" button={true} buttonAction={() => {}}/> */}
                {home_data.activities.data.map((activity, index) => {
                    return (
                      <LinkBlock
                        key={`linkBlock-${index}`}
                        balance={true}
                        currency={true}
                        date={activity.index.date}
                        value={activity.index.value}
                        description={this._checkNotificationTitle(activity)}
                        action={() =>
                          this.setState({
                            showModalNotifications: true,
                            notification_show: activity.show,
                          })
                        }
                        className="last-activities"
                      />
                    );
                  })}
                {                  home_data.activities.data.length === 0 && (
                    <NoResources
                      text="No assets yet"
                      btnText="add new asset"
                      button={true}
                      buttonAction={() => {}}
                    />
                  )}
                {/*!canRead(this.props.permits, "rest_assets") &&
                  !canRead(this.props.permits, "financial_assets") && (
                    <div className="content-fake">
                      <div className="content-fake__icon">
                        <Icon name="Lock" color="#005774" />
                        <p className="headingH4">Not granted</p>
                      </div>
                      <img
                        src={lastActivity_fake}
                        className="content-fake__image"
                      />
                    </div>
                  )*/}
              </ContentBlock>
              <ContentBlock
                title={this.props.home_data.bulltick_research.title}
                icon={<Icon name="LogoB" iconBig={true} color="#005774" />}
                button={true}
                buttonAction={() =>
                  this.props.history.push("/researchs/"+this.props.home_data.bulltick_research.data.id)
                }
                buttonText={
                  this.props.home_data.tree_graphic_data.buttons.footer
                }
                header={true}
                maxHeight={true}
                className="fr-wrapper only-text"
                contentClass="fr-element fr-view"
              >
                <div className="wrapper-temp-info" style={{padding: 0}}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                      `<style>
.wrapper-content-block.fr-wrapper ul li:before {top: 6px; transform: translateY(0%);} .wrapper-temp-info strong, .wrapper-temp-info h1, .wrapper-temp-info h2, .wrapper-temp-info h3, .wrapper-temp-info h4 {font-family: "Averta-bold", sans-serif !important;}</style>

${this.props.home_data.bulltick_research.data && this.props.home_data.bulltick_research.data.home_description_web}
`
                      ,
                    }}
                  />
                </div>
              </ContentBlock>
              {/*<ContentBlock
                title="Private Investments"
                icon={<Icon name="LastActivity" iconBig={true} />}
                help={false}
                helpAction={() => alert("Private Investments action help")}
                button={true}
                buttonAction={() => alert("Private Investments action button")}
                buttonText={this.props.home_data.tree_graphic_data.buttons.footer}
                header={true}
                maxHeight={true}
            >
                <LinkBlock
                    balance={true}
                    date="4/03"
                    description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit"
                    action={() => alert("Private Investments link")}
                />
                <LinkBlock
                    balance={true}
                    date="4/03"
                    description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit"
                    action={() => alert("Private Investments link")}
                />
                <LinkBlock
                    balance={true}
                    date="26/02"
                    description="Ut wisi enim ad minim veniam, quis nostrud exerci enim ad minim veniam, quis nost"
                    action={() => alert("Private Investments link")}
                />
                <LinkBlock
                    balance={true}
                    date="4/03"
                    description="Ut wisi enim ad minim veniam, quis nostrud exerci enim ad minim veniam, quis nost"
                    action={() => alert(" Private Investments link")}
                />
        </ContentBlock>*/}
              {this.props.home_data.market_information.news.length > 0 && (
                <ContentBlock
                  title={this.props.home_data.market_information.title}
                  icon={
                    <Icon
                      name="News"
                      colorPrimary={"#005774"}
                      colorSecondary="#f3b01c"
                      iconBig={true}
                    />
                  }
                  button={true}
                  buttonAction={() => this.props.history.push("/market")}
                  buttonText={
                    this.props.home_data.tree_graphic_data.buttons.footer
                  }
                  header={true}
                >
                  <div className="wrapper-temp-info">
                    <div className="content">
                      <img
                        className="img"
                        src={
                          this.props.home_data.market_information.news[0].image
                        }
                        alt=""
                      />
                      <div className="container-text padding">
                        <div className="wrapper-news">
                          <p className="headingH4 title">
                            <a
                              style={{ color: "#005774" }}
                              href={ this.props.home_data.market_information.news[0].url }
                              target="_blank"
                            >
                              { this.props.home_data.market_information.news[0].title }
                            </a>
                          </p>
                          <p className="text">
                            {this.props.home_data.market_information.news[0].description
                              ? this.props.home_data.market_information.news[0].description.substring(0, 260)
                              : ""
                            }
                            {" "}
                            {this.props.home_data.market_information.news[0].description &&
                            this.props.home_data.market_information.news[0].description.length > 260
                              ? "..."
                              : ""
                            }
                          </p>
                          <span className="legend">
                            { this.props.home_data.market_information.news[0].source }
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="content">
                      <div className="container-text">
                        <div className="wrapper-news">
                          <p>
                            <a
                              style={{ color: "#005774" }}
                              className="headingH5 title u-text-ellipsis-3"
                              href={ this.props.home_data.market_information.news[1].url }
                              target="_blank"
                            >
                              { this.props.home_data.market_information.news[1].title }
                            </a>
                          </p>
                          <p className="text u-text-ellipsis-3">
                            {this.props.home_data.market_information.news[1].description}
                          </p>
                          <span className="legend">
                            {
                              this.props.home_data.market_information.news[1].source
                            }
                          </span>
                        </div>
                        <div className="wrapper-news">
                          <p>
                            <a
                              style={{ color: "#005774" }}
                              className="headingH5 title u-text-ellipsis-3"
                              href={ this.props.home_data.market_information.news[2].url }
                              target="_blank"
                            >
                              { this.props.home_data.market_information.news[2].title }
                            </a>
                          </p>
                          <p className="text u-text-ellipsis-3">
                            {this.props.home_data.market_information.news[2].description}
                          </p>
                          <span className="legend">
                            {
                              this.props.home_data.market_information.news[2].source
                            }
                          </span>
                        </div>
                        <div className="wrapper-news">
                          <p>
                            <a
                              style={{ color: "#005774" }}
                              className="headingH5 title u-text-ellipsis-3"
                              href={ this.props.home_data.market_information.news[3].url }
                              target="_blank"
                            >
                              { this.props.home_data.market_information.news[3].title }
                            </a>
                          </p>
                          <p className="text u-text-ellipsis-3">
                            {this.props.home_data.market_information.news[3].description}
                          </p>
                          <span className="legend">
                            { this.props.home_data.market_information.news[3].source }
                          </span>
                        </div>

                        <div className="wrapper-news">
                          <p>
                            <a
                              style={{ color: "#005774" }}
                              className="headingH5 title u-text-ellipsis-3"
                              href={ this.props.home_data.market_information.news[4].url }
                              target="_blank"
                            >
                              { this.props.home_data.market_information.news[4].title }
                            </a>
                          </p>
                          <p className="text u-text-ellipsis-3">
                            {this.props.home_data.market_information.news[4].description}
                          </p>
                          <span className="legend">
                            {
                              this.props.home_data.market_information.news[4].source
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ContentBlock>
              )}
              <ContentBlock
                title={this.props.home_data.services.title}
                icon={<Icon name="LogoB" iconBig={true} color="#005774" />}
                button={true}
                buttonAction={() =>
                  this.props.history.push(
                    "/services/" + this.props.home_data.services.main_article
                  )
                }
                buttonText={
                  this.props.home_data.tree_graphic_data.buttons.footer
                }
                header={true}
                maxHeight={true}
              >
                <div className="wrapper-temp-info">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        this.props.home_data.services.data &&
                        this.props.home_data.services.data.length > 0 &&
                        this.props.home_data.services.data[0]
                          .home_description_web,
                    }}
                  />
                </div>
              </ContentBlock>
              {/*<ContentBlock
                title="New BullTick Training"
                icon={<Icon name="Training" iconBig={true} />}
                button={true}
                buttonAction={() =>
                        alert("New BullTick Training action button")
                }
                buttonText={this.props.home_data.tree_graphic_data.buttons.footer}
                header={true}
                maxHeight={true}
            >
                <LinkBlock
                    balance={false}
                    description="Ut wisi enim ad minim veniam, quis nostrud exerci"
                    action={() => alert("New BullTick Training link")}
                    icon={
                        <Icon
                            name="Plus"
                            noWrapper={true}
                            className="description-icon"
                        />
                    }
                    className="new-bulltick-training"
                />
                <LinkBlock
                    balance={false}
                    description="Ut wisi enim ad minim veniam, quis nostrud exerci Ut wisi enim ad minim veniam, quis nostrud exerci"
                    action={() => alert("New BullTick Training link")}
                    icon={
                        <Icon
                            name="Plus"
                            noWrapper={true}
                            className="description-icon"
                        />
                    }
                    className="new-bulltick-training"
                />
                <LinkBlock
                    balance={false}
                    description="Ut wisi enim ad minim veniam, quis nostrud exerci"
                    action={() => alert("New BullTick Training link")}
                    icon={
                        <Icon
                            name="Plus"
                            noWrapper={true}
                            className="description-icon"
                        />
                    }
                    className="new-bulltick-training"
                />
        </ContentBlock>*/}
            </div>
          </div>
        )}
        {this.state.showModalNotifications && (
          <NotificationModalShow
            internalization={this.props.internalization}
            notification={this.state.notification_show}
            go_to_resource_text={
              this.props.internalization.buttons.go_to_resource
            }
            goToResource={(resource_url) => this._goToResource(resource_url)}
            hideModal={() =>
              this.setState({
                notification_show: {},
                showModalNotifications: false,
              })
            }
          />
        )}

        <ActionSheet
          ref={this.actionSheet}
          render={this.state.options_action_sheet_short_cut}
        />

        {this.state.showModalInfo && (
          <CustomModal isShowed={true} size={'small'}>
            <ModalPermission language={this.props.user.language} />  
          </CustomModal> 
        )}
        {this.props.alerts_pending && this.props.alerts_pending.length > 0 && (
          <CustomModal isShowed={true} size={'middle'}>
            <div>
              <ModalPendingAlerts updatePending={(id, attributes, success, error) => this.props.updateAlertInvited(this.props.authentication_token, id, attributes, () => this._success_check_length(), () => error())} language={this.props.user.language} alerts_pending={this.props.alerts_pending} />  
            </div>
          </CustomModal> 
        )}

        {this.props.alerts_deleted && this.props.alerts_deleted.length > 0 && (
          <CustomModal isShowed={true} size={'middle'}>
            <ModalDeleteAlerts 
              updateDeletedFromSource={(id, attributes, success, error) => this.props.updateAlertInvited(this.props.authentication_token, id, attributes, () => this._success_check_length(), () => error())} 
              language={this.props.user.language} 
              alerts_pending={this.props.alerts_deleted} 
            />  
          </CustomModal>
        )}

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    home_data: state.home.home_data,
    authentication_token: state.session.authentication_token,
    permits: state.session.permits,
    user: state.session.user,
    internalization: state.session.internalization,
    filtered_data_bars_graph: state.home.filtered_data_bars_graph,
    insurance_user: state.session.insurance_user,
    bulltick_insurance_agency: state.session.bulltick_insurance_agency,
    real_user_insurance: state.session.real_user_insurance,
    assigned_users: state.session.assigned_users,
    admin_insurance: state.session.admin_insurance,
    ownership_index: state.ownership.ownership_index,
    alerts_pending: state.session.alerts_pending,
    alerts_deleted: state.session.alerts_deleted

  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendDeviceToken: (
      authentication_token,
      device_token,
      type,
      device_info,
      uuid
    ) =>
      dispatch(
        sendDeviceToken(
          authentication_token,
          device_token,
          type,
          device_info,
          uuid
        )
      ),
    getFilteredDataBarsGraph: (authentication_token, url, success, error) =>
      dispatch(
        getFilteredDataBarsGraph(authentication_token, url, success, error)
      ),
    getHome: (authentication_token, success,error) => dispatch(getHome(authentication_token, success,error)),
    setCurrentBreadcrumb: (current_bradcrumb) =>
      dispatch(setCurrentBreadcrumb(current_bradcrumb)),
    inputsForm: (authentication_token, success, error) => dispatch(inputsForm(authentication_token, success, error)),
    updateAutomaticLayout: (authentication_token, enabled, success, error) =>
      dispatch(updateAutomaticLayout(authentication_token, enabled, success, error)),
    deleteNode: (authentication_token, object, success, error) =>
      dispatch(deleteNode(authentication_token, object, success, error)),
    getPalettes: (authentication_token, success) =>
      dispatch(getPalettes(authentication_token, success)),
    editElement: (authentication_token, attributes, success, error) =>
      dispatch(editElement(authentication_token, attributes, success, error)),
    createWildCardStructure: (authentication_token, object, success, error) =>
      dispatch(createWildCardStructure(authentication_token, object, success, error)),
    getListElement: (authentication_token, class_name, success, error) =>
      dispatch(getListElement(authentication_token, class_name, success, error)),
    saveOwnershipStructure: (authentication_token, ownership_structure, success, error) =>
      dispatch(saveOwnershipStructure(authentication_token, ownership_structure, success, error)),
    getWealthMap: (authentication_token, success, error) =>
      dispatch(getWealthMap(authentication_token, success, error)),
    updateAlertInvited: (authentication_token, id, attributes, success, error) =>
      dispatch(updateAlertInvited(authentication_token, id, attributes, success, error)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
