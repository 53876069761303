// Dependencies
import React from 'react';

const News = ({ width, height, colorPrimary, colorSecondary, strokeWidth }) => {

  return (

    <svg
      width={width ? width : '47px'}
      height={height ? height : '47px'}
      viewBox="0 0 47 47"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons-/-Section-/-Novedades" transform="translate(-7.000000, -7.000000)" fillRule="nonzero" strokeWidth={strokeWidth ? strokeWidth : 1.5}>
          <polygon id="Star" stroke={colorPrimary ? colorPrimary : "#80abba"} points="30.5 48.538923 24.6765715 52.2333311 21.4805385 46.1221656 14.5900974 46.4099026 14.8778344 39.5194615 8.76666891 36.3234285 12.461077 30.5 8.76666891 24.6765715 14.8778344 21.4805385 14.5900974 14.5900974 21.4805385 14.8778344 24.6765715 8.76666891 30.5 12.461077 36.3234285 8.76666891 39.5194615 14.8778344 46.4099026 14.5900974 46.1221656 21.4805385 52.2333311 24.6765715 48.538923 30.5 52.2333311 36.3234285 46.1221656 39.5194615 46.4099026 46.4099026 39.5194615 46.1221656 36.3234285 52.2333311"></polygon>
          <polyline id="Path-3" stroke={colorSecondary ? colorSecondary : "#80abba"} strokeLinecap="round" points="19 38.0117188 27.0625 28.7451172 31.6005859 33.378418 41.6992188 22"></polyline>
        </g>
      </g>
    </svg>

  );

}

export default News;
