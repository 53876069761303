// Dependencies
import React from "react";

const Tooltip = (props) => {
  return (
    <div className="container-tooltip" >
        {props.children}
    </div>
  );
};

export default Tooltip;
