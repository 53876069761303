export function getTitle(data) {
  let title_found;
  if (data.form !== undefined) {
    title_found = data.form.filter(input => input.id === "name" || input.id === "policy_number");
  } else {
    title_found = [{ value: "asset" }];
  }
  
  let code = data.resource_key === "life_insurance" ? "add_life_insurance" : data.code

  switch (code) {
    case "add_asset":
    case "add_vehicle":
    case "add_art":
    case "add_watch":
    case "add_other":
    case "add_short_term_liability":
    case "add_liability":
      if (data.resource_key === "receivable_account") {
        return {
          title: "Add account receivable",
          button: "Save"
        };
      } else {
        return {
          title: "Add " + data.resource_key.replace(/_/g, " "),
          button: "Save"
        };
      }
    case "edit_asset":
      return { title: "Edit " + title_found[0].value, button: "Update", uploadButton: "Upload file" };
    case "add_document":
      return {
        title: "Add document",
        button: "Save",
        uploadButton: "Upload file"
      };
    case "edit_document":
    case "add_life_insurance":
      return {
        title: "Edit document",
        button: "Update",
        uploadButton: "Upload file"
      };
    case "add_contact":
      return { title: "Add contact", button: "Save" };
    case "edit_contact":
      return { title: "Edit contact", button: "Update" };
    default:
      return { title: "Add", button: "Save" };
  }
}
