// Dependencies
import React from "react";
import { connect } from "react-redux";
// Components
import { internalization } from "../../constants";

import ContentBlock from "../../components/contentBlock";
import Tooltip from "../../components/tooltip";
import Icon from "../../components/icons";
import StackHeader from "../../components/stackHeader";

// Actions
import { getArticle, buttonClicked } from "../../actions/articles";

class ServicesShowScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            content: -1,
            showTabs: true,
            showTooltip: false,
            indexTooltip: "",
            title: ""
        }
    }

    componentDidMount() {
        let id = ""
        
        try{
            id = this.props.location.state.id
        }catch(e){
            if(id === undefined || id === ""){
                let path = window.location.pathname.split('/');
                id = path[path.length - 1]
            } 
        }

        this.props.getArticle(this.props.authentication_token,(responseJSON) => this._success(responseJSON) ,() =>{},id)
    }

    _success(responseJSON){
        console.log(responseJSON)
        this.setState({title: responseJSON.title})
        this._prepareBreadcrumb(responseJSON)
    }

    _prepareBreadcrumb(responseJSON){
        console.log(this.props.bulltick_research_id)
        let languageUserAgent = (/^es/).test(this.props.user.language) ? 'es' : 'en';
        let current_id = this.props.location.pathname.split("services/")[1]
        let service = this.props.menu.list.filter(element => element.code === "service")
        let url = "";
        if(this.props.location.pathname.includes("services/")){
            url = '/services'
        }
        else{
            url = '/researchs'
            current_id = this.props.location.pathname.split("researchs/")[1]
        }
        
        // if(""+current_id === ""+service[1].navigation_id || ""+current_id === ""+this.props.bulltick_research_id){
        //     this.props.setSpecialParent([{title: internalization[languageUserAgent].models.home, code: "home", url: "/home"}])
        // }
        // else{
        //   this.props.setSpecialParent([{title: internalization[languageUserAgent].models.home, code: "home", url: "/home"}, {title: service[1].title, code: "main_service", url: "/services/"+service[1].navigation_id}])  
        // }
        
        let article_selected = responseJSON.articles.filter(article => ""+article.id === ""+current_id)
         this.props.getBreadcrumbs({
          assetUrl: url,
          url: url,
          assetTitle: ""+current_id === ""+service[1].navigation_id ? responseJSON.title : article_selected.length > 0 ? article_selected[0].title : responseJSON.title
        });

    }

    handleTooltip(e, index) {
        e.stopPropagation();
        this.setState({ showTooltip: !this.state.showTooltip, indexTooltip : index});
        let body = document.body;
        body.addEventListener('click', e => this.setState({ showTooltip: false }) );
    }

    _buttonClicked(item){
        
        if(item.action === "2"){
            window.open(item.url_download, '_blank');
        }
        else{
            this.props.buttonClicked(item.url, this.props.authentication_token, () => console.log("Ok"), () => console.log("KO"))    
        }
        
    }
    _changeTab(id){
        this.setState({ content: id });
        let article_selected = this.props.showArticle.filter((e) => e.id === id)
         this.props.getBreadcrumbs({
          assetUrl: '/services',
          url: '/services',
          assetTitle: article_selected[0].title
        });        
    }

    
    render() {

        let article_selected = this.state.content === -1 ? this.props.showArticle[0] :  this.props.showArticle.filter((e) => e.id === this.state.content)[0]
        console.log(article_selected)
        return (
            <div className="main-wrapper services">

                <StackHeader
                    title={this.state.title}
                    icon={<Icon name="LogoB" iconBig={true} />}
                    help={false}
                    helpAction={() => alert("boton de info del stackHeader")}
                />

                {
                    this.props.showArticle.length > 1 &&

                        <div className="wrapper-tabs">

                            {
                                this.props.showArticle && this.props.showArticle.length > 0 && this.props.showArticle.map((article, index) => {
                                    return(
                                        <button 
                                            type="button" 
                                            className={this.state.content === article.id || this.state.content === -1 && index == 0 ? "btn primary border" : "btn primary"}
                                            onClick={(e)=> {
                                              this._changeTab(article.id)
                                                
                                            }}
                                        >
                                            <span class="btn-text">{article.title}</span>
                                        </button>
                                    )
                                })
                            }                            

                        </div>
                }

                        <div className="super-wrapper-content-block froala">
                            <div className={Object.keys(this.props.showArticle).length > 0 && article_selected && article_selected.section_medium.html != "" && (article_selected.section_medium !== "" || article_selected.links.length > 0) ? "sixty-percent" : ""}>
                                <ContentBlock
                                    title={article_selected ? this.props.showArticle.length > 1 ?  article_selected && article_selected.section_large.subtitle_large : article_selected.title : ""}
                                    header={this.props.showArticle.length > 1 ? article_selected && article_selected.section_large.subtitle_large === "" ? false : true : true}
                                    maxHeight={false}
                                    className="fr-wrapper only-text"
                                    contentClass="fr-element fr-view"
                                    date={article_selected ? article_selected.published_date_section_large ? article_selected.published_date_section_large : article_selected.created_at : undefined}
                                    
                                >
                                  {
                                     Object.keys(this.props.showArticle).length > 0 && article_selected && article_selected.section_large !== undefined &&
                                    <div dangerouslySetInnerHTML={{ __html: article_selected.section_large.html }}></div>
                                  }
                                  <div className="centered">
                                        
                                        {
                                          Object.keys(this.props.showArticle).length > 0 && article_selected && article_selected.section_large !== undefined &&

                                          article_selected.section_large.buttons.map((item,index) => {
                                            return(
                                                <p className="tooltip-link" onClick={e => {this._buttonClicked(item); item.action !== "2" && this.handleTooltip(e, index)} }>
                                                  {item.text}

                                                {
                                                  (this.state.showTooltip && this.state.indexTooltip === index) &&
                                                    <Tooltip>
                                                      <span className="tooltip-text" dangerouslySetInnerHTML={{ __html: item.feedback }}></span>                                                        
                                                    </Tooltip>
                                                }
                                              </p>
                                            )
                                          })
                                        }
                                    </div>
                                  
                                
                                </ContentBlock>
                            </div>
                            {
                                Object.keys(this.props.showArticle).length > 0 && (article_selected || article_selected.section_medium !== undefined && article_selected.section_medium !== "" && article_selected.section_medium.html != "") &&

                            <div className="fourty-percent">
                                {
                                    article_selected.section_medium !== undefined && article_selected.section_medium !== "" && article_selected.section_medium.html != "" &&
                                    <ContentBlock
                                    title={article_selected && article_selected.section_medium.subtitle_medium === "" ? article_selected.texts.fund_manager : article_selected.section_medium.subtitle_medium}
                                    header={true}
                                    maxHeight={false}
                                    className="fr-wrapper only-text"
                                    contentClass="fr-element fr-view"
                                    date={article_selected ? article_selected.published_date_section_medium ? article_selected.published_date_section_medium : article_selected.created_at : undefined}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: article_selected.section_medium.html }}></div>
                                    
                                    <div className="centered">
                                        
                                        {
                                          Object.keys(this.props.showArticle).length > 0 && article_selected && article_selected.section_medium !== undefined &&

                                          article_selected.section_medium.buttons.map((item,index) => {
                                            return(
                                                <p className="tooltip-link" onClick={e => {this._buttonClicked(item); item.action !== "2" && this.handleTooltip(e, index)} }>
                                                  {item.text}

                                                {
                                                  (this.state.showTooltip && this.state.indexTooltip === index) &&
                                                    <Tooltip>
                                                      <span className="tooltip-text" dangerouslySetInnerHTML={{ __html: item.feedback }}></span>
                                                      <span className="tooltip-text"> For more information please visit: <a href="https://bulltick.com" className="link" target='_blank'> https://bulltick.com</a></span>

                                                    </Tooltip>
                                                }
                                                </p>
                                            )
                                          })
                                        }
                                    </div>
                                </ContentBlock>
                                }
                                    
                                {
                                    article_selected.links !== undefined && article_selected.links.length > 0 &&
                                <ContentBlock
                                    title={article_selected && article_selected.texts.subtitle_files === "" ? article_selected.texts.fund_documents : article_selected.texts.subtitle_files}                                
                                    header={true}
                                    maxHeight={false}
                                    className="only-text"
                                    //date={article_selected ? article_selected.published_date_section_files ? article_selected.published_date_section_files : article_selected.created_at : undefined}
                                >
                                  {
                                    Object.keys(this.props.showArticle).length > 0 && article_selected && article_selected.links !== undefined &&

                                    article_selected.links.slice(0,3).map((item,index) => {
                                      return(
                                        <>
                                          <ul className="list">
                                            <li className="list-item"><a href={item.link} target="_blank" className="link" style={{marginBottom: 0}}><p style={{marginBottom: '5px'}} dangerouslySetInnerHTML={{ __html: item.text }}></p></a></li>
                                          </ul>
                                          <p className="date" style={{paddingLeft: '25px'}}>{item.published_date}</p>
                                        </>
                                        )
                                    })
                                    }
                                    {
                                        article_selected.links.length > 3 &&
                                        <button 
                                            type="button" 
                                            className={"btn primary border"}
                                            onClick={(e)=> {
                                                this.props.history.push("/services/"+article_selected.id+"/files",{id: article_selected.id })
                                            }}
                                        >
                                            <span class={"btn-text"}>{article_selected.texts.read_more}</span>
                                        </button>
                                    }
                                    
                                </ContentBlock>

                                }
                            </div>
                            }                            


                        </div>
                
            </div>
        )
    }

}

function mapStateToProps(state) {
  return {
    showArticle: state.articles.showArticle,
    authentication_token: state.session.authentication_token,
    user: state.session.user,
    menu: state.session.menu,
    bulltick_research_id: state.session.bulltick_research_id
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getArticle: (authentication_token,success, error,id) => dispatch(getArticle(authentication_token,success, error,id)),
    buttonClicked: (url, authentication_token, success, error) => dispatch(buttonClicked(url, authentication_token, success, error))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesShowScreen);

