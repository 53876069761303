import { HOST_URL, URL_VERSION } from '../constants';

import get_header from './api_header'
import {parseHeaders} from './api_header'

export function ownershipIndexSuccess(payload) {
  return {
      type: "OWNERSHIP_INDEX",
      payload
  }
}


export function getOwnershipIndex(authentication_token, success, error) {
  return dispatch => {
    return fetch(HOST_URL + "/api/v1/ownership_structure/home", {
      method: "GET",
      headers: get_header(authentication_token)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            dispatch(ownershipIndexSuccess(responseJson));
            success(responseJson);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function getWealthMap(authentication_token, success, error) {
  return dispatch => {
    return fetch(HOST_URL + "/api/v1/ownership_structure/ownership_structure/home", {
      method: "GET",
      headers: get_header(authentication_token)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            dispatch(ownershipIndexSuccess(responseJson));
            success(responseJson);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}


export function saveOwnershipStructure(authentication_token, ownership_structure, success, error) {
  return dispatch => {
    return fetch(HOST_URL + "/api/v1/ownership_structure/ownership_structure", {
      method: "POST",
      headers: get_header(authentication_token),
      body: JSON.stringify(ownership_structure)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            // dispatch(ownershipIndexSuccess(responseJson));
            success(responseJson);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}


export function getOwnershipEdit(authentication_token, url, success, error) {
  return dispatch => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: "GET",
      headers: get_header(authentication_token)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            // dispatch(ownershipIndexSuccess(responseJson));
            success(responseJson.data);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function getElementDetail(authentication_token, url, success, error) {
  return dispatch => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: "GET",
      headers: get_header(authentication_token)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            // dispatch(ownershipIndexSuccess(responseJson));
            success(responseJson.data);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function getListElement(authentication_token, class_name, success, error) {
  return dispatch => {
    return fetch(HOST_URL + URL_VERSION + "/ownership_structure/ownership_structure/retrieve_elements?type="+class_name, {
      method: "GET",
      headers: get_header(authentication_token)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            // dispatch(ownershipIndexSuccess(responseJson));
            success(responseJson);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function getPalettes(authentication_token, success, error) {
  return dispatch => {
    return fetch(HOST_URL + URL_VERSION + "/ownership_structure/ownership_structure/ownership_palettes", {
      method: "GET",
      headers: get_header(authentication_token)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            // dispatch(ownershipIndexSuccess(responseJson));
            success(responseJson);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}


export function createWildCardStructure(authentication_token, object, success, error) {
  return dispatch => {
    return fetch(HOST_URL + "/api/v1/ownership_structure/ownership_structure/wild_card", {
      method: "POST",
      headers: get_header(authentication_token),
      body: JSON.stringify(object)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            // dispatch(ownershipIndexSuccess(responseJson));
            success(responseJson);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function editElement(authentication_token, object, success, error) {
  console.log("object", object)
  return dispatch => {
    return fetch(HOST_URL + "/api/v1/ownership_structure/ownership_structure/edit_element", {
      method: "POST",
      headers: get_header(authentication_token),
      body: JSON.stringify(object)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            // dispatch(ownershipIndexSuccess(responseJson));
            success(responseJson);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function deleteNode(authentication_token, object, success, error) {
  return dispatch => {
    return fetch(HOST_URL + "/api/v1/ownership_structure/ownership_structure/delete_node", {
      method: "POST",
      headers: get_header(authentication_token),
      body: JSON.stringify(object)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            // dispatch(ownershipIndexSuccess(responseJson));
            success(responseJson);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function updateAutomaticLayout(authentication_token, enabled, success, error) {
  
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + '/ownership_structure/ownership_structure/update_automatic_layout/', {
      method: 'PUT',
      headers: get_header(authentication_token),
      body: JSON.stringify({enabled})
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success()   
        })
      }
      else{
        error();
      }

    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function createNewNode(authentication_token, success, error, object) {
  return dispatch => {
    return fetch(HOST_URL + "/api/v1/ownership_structure/ownership_structure/create_new_node", {
      method: "POST",
      headers: get_header(authentication_token),
      body: JSON.stringify(object)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            // dispatch(ownershipIndexSuccess(responseJson));
            success(responseJson);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}





