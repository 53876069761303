// Dependencies
import React from 'react';

const Help = ({ width, height, color }) => {

  return (

    <svg
      width={width ? width : '20px'}
      height={height ? height : '20px'}
      viewBox="0 0 20 20"
    >
      <path
        d="M10 18.095a8.095 8.095 0 1 0 0-16.19 8.095 8.095 0 0 0 0 16.19zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm-.952-6.667h1.904v1.905H9.048v-1.905zm-2.381-5.11c0-.52.08-.992.24-1.418.16-.426.393-.79.699-1.092a3.154 3.154 0 0 1 1.106-.703 4.019 4.019 0 0 1 1.448-.248c.447 0 .861.064 1.245.191.383.128.718.313 1.004.554.286.241.512.541.677.9.165.36.247.767.247 1.22 0 .332-.036.618-.109.86-.073.24-.17.449-.291.624-.121.175-.26.33-.415.468-.155.137-.31.272-.466.404-.184.151-.354.298-.51.44-.154.142-.29.3-.407.475-.116.175-.206.381-.269.617a3.404 3.404 0 0 0-.095.866H9.2c0-.416.022-.769.066-1.057.044-.289.114-.542.211-.76.097-.217.216-.408.357-.574.14-.165.308-.328.502-.49.155-.132.303-.255.444-.368a2.55 2.55 0 0 0 .378-.376c.112-.137.2-.291.262-.461.063-.17.095-.374.095-.61 0-.284-.051-.528-.153-.731a1.452 1.452 0 0 0-.837-.76 1.367 1.367 0 0 0-.437-.084c-.601 0-1.045.191-1.332.574-.286.383-.429.897-.429 1.54h-1.66z"
        fill={color ? color : "#80abba"}
        fillRule="nonzero"
      />
    </svg>

  );

}

export default Help;
