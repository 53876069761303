// Dependencies
import React from 'react';

const Diagram = ({ width, height, color }) => {

  return (
    <svg
      width={width ? width : '25px'}
      height={height ? height : '24px'}
      viewBox="0 0 25 24"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd" fill={color ? color : '#80abba'}>
        <g id="icons-/-menu-/-structure" transform="translate(-6.000000, -6.000000)">
          <path d="M19.6904762,16.8 L26.8333333,16.8 C27.4908152,16.8 28.0238095,17.3372583 28.0238095,18 L28.0238095,21.6 L30,21.6 C30.5522847,21.6 31,22.0477153 31,22.6 L31,29 C31,29.5522847 30.5522847,30 30,30 L23.6666667,30 C23.1143819,30 22.6666667,29.5522847 22.6666667,29 L22.6666667,22.6 C22.6666667,22.0477153 23.1143819,21.6 23.6666667,21.6 L25.6428571,21.6 L25.6428571,19.2 L11.3571429,19.2 L11.3571429,21.6 L13.3333333,21.6 C13.8856181,21.6 14.3333333,22.0477153 14.3333333,22.6 L14.3333333,29 C14.3333333,29.5522847 13.8856181,30 13.3333333,30 L7,30 C6.44771525,30 6,29.5522847 6,29 L6,22.6 C6,22.0477153 6.44771525,21.6 7,21.6 L8.97619048,21.6 L8.97619048,18 C8.97619048,17.3372583 9.50918482,16.8 10.1666667,16.8 L17.3095238,16.8 L17.3095238,14.4 L15.3333333,14.4 C14.7810486,14.4 14.3333333,13.9522847 14.3333333,13.4 L14.3333333,7 C14.3333333,6.44771525 14.7810486,6 15.3333333,6 L21.6666667,6 C22.2189514,6 22.6666667,6.44771525 22.6666667,7 L22.6666667,13.4 C22.6666667,13.9522847 22.2189514,14.4 21.6666667,14.4 L19.6904762,14.4 L19.6904762,16.8 Z" id="path-1"></path>
        </g>
      </g>
    </svg>

  );

}

export default Diagram;
