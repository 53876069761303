// Dependencies
import React from "react";

const OwnerShip = ({ width, height, color }) => {
  return (
    <svg width={width ? width : "20px"} height={height ? height : "27px"} viewBox="0 0 20 27">
      <defs>
        <clipPath id="12251103114451001135657100">
          <path d="M5,0 C5.55228475,-1.01453063e-16 6,0.44771525 6,1 L6,6 C6,6.55228475 5.55228475,7 5,7 L1,7 C0.44771525,7 6.76353751e-17,6.55228475 0,6 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L5,0 Z"></path>
        </clipPath>
        <clipPath id="11995112055515511757121">
          <path d="M2,0 C3.1045695,0 4,0.8954305 4,2 C4,3.1045695 3.1045695,4 2,4 C0.8954305,4 0,3.1045695 0,2 C0,0.8954305 0.8954305,0 2,0 Z"></path>
        </clipPath>
        <clipPath id="52105575210110849122560">
          <path d="M4,0 L4,5 L0,5 L0,0 L4,0 Z"></path>
        </clipPath>
        <clipPath id="10705511553011910750103">
          <path d="M5.94932674,0.80816907 C6.05537606,1.35017644 5.70196223,1.87553018 5.15995486,1.9815795 C2.85974945,2.43163835 2,3.17420897 2,4.12867931 C2,5.38348288 4.7141331,6.62867931 9.00751114,6.62867931 C13.304493,6.62867931 16.0358849,5.38028122 16.0358849,4.12867931 C16.0358849,3.18257262 15.1679023,2.43642414 12.8705997,1.98462635 C12.3286951,1.87805298 11.9757895,1.35235774 12.0823629,0.81045317 C12.1889362,0.268548602 12.7146315,-0.0843570609 13.256536,0.022216311 C16.3886484,0.63819151 18.0358849,2.05421427 18.0358849,4.12867931 C18.0358849,6.91248424 14.2809918,8.62867931 9.00751114,8.62867931 C3.73838542,8.62867931 0,6.91357397 0,4.12867931 C0,2.04831355 1.63919841,0.632528314 4.77591632,0.0187971902 C5.31792368,-0.0872521267 5.84327743,0.266161702 5.94932674,0.80816907 Z"></path>
        </clipPath>
      </defs>
      <g transform="translate(1.7988077241415112 1.3396053314208913)">
        <g transform="translate(5.1567400156897065 3.0108603216888667)">
          <g transform="translate(0.0 5.16514179608609)">
            <g clipPath="url(#12251103114451001135657100)">
              <polygon points="0,0 6,0 6,7 0,7 0,0" stroke="none" fill="#80ABBA"></polygon>
            </g>
          </g>
          <g transform="translate(1.1228460268102936 0.0)">
            <g clipPath="url(#11995112055515511757121)">
              <polygon points="0,0 4,0 4,4 0,4 0,0" stroke="none" fill="#80ABBA"></polygon>
            </g>
          </g>
          <g transform="translate(1.0 12.0)">
            <g clipPath="url(#52105575210110849122560)">
              <polygon points="0,0 4,0 4,5 0,5 0,0" stroke="none" fill="#80ABBA"></polygon>
            </g>
          </g>
        </g>
        <g transform="translate(-0.8063188630575269 16.562321482135662)">
          <g clipPath="url(#10705511553011910750103)">
            <polygon
              points="0,-1.77635684e-15 18.0358849,-1.77635684e-15 18.0358849,8.62867931 0,8.62867931 0,-1.77635684e-15"
              stroke="none"
              fill="#80ABBA"
            ></polygon>
          </g>
        </g>
        <g transform="">
          <path
            d="M0,4.85439863 L8.36215694,0 L16.6674174,4.85439863"
            stroke="#80ABBA"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeMiterlimit="10"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default OwnerShip;
