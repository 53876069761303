// Dependencies
import React from 'react';


const FolderBig = ({ width, height, color, style }) => {

  return (
      <svg
          width={width ? width : 45}
          height={height ? height : 36}
          viewBox="0 0 45 36"
          style={style}
      >


      <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Home" transform="translate(-338.000000, -184.000000)" fillRule="nonzero">
              <g id="folder" transform="translate(338.000000, 184.000000)">
                  <path d="M42.3783429,11.755102 L39.8057143,11.755102 L39.8057143,7.15004082 C39.8057143,6.04285714 38.9019771,5.14285714 37.79184,5.14285714 L19.5453429,5.14285714 L15.8596286,0 L2.01387429,0 C0.903737143,0 0,0.900734694 0,2.00718367 L0,34.1037551 L0.00442285714,34.1037551 C0.000737142857,34.5173878 0.129737143,34.9222041 0.389211429,35.260898 C0.750411429,35.731102 1.29737143,36 1.89077143,36 L34.8845486,36 C35.7366857,36 36.4870971,35.4276735 36.6905486,34.668 L44.2285714,14.0686531 L44.2285714,13.9386122 C44.2285714,12.6734694 43.4501486,11.755102 42.3783429,11.755102 Z M1.47428571,2.00718367 C1.47428571,1.71036735 1.71680571,1.46938776 2.01387429,1.46938776 L15.1003714,1.46938776 L18.7860857,6.6122449 L37.7911029,6.6122449 C38.0889086,6.6122449 38.3306914,6.85322449 38.3306914,7.15004082 L38.3306914,11.755102 L9.38456571,11.755102 C9.27841714,11.755102 9.17300571,11.7639184 9.07054286,11.781551 C8.35330286,11.9013061 7.75695429,12.4229388 7.57930286,13.0863673 L1.47428571,29.680898 L1.47428571,2.00718367 Z M35.2862914,34.2242449 C35.23764,34.4042449 35.0717829,34.5306122 34.8845486,34.5306122 L1.89077143,34.5306122 C1.71164571,34.5306122 1.60697143,34.4284898 1.56053143,34.3675102 C1.51409143,34.3072653 1.44258857,34.1794286 1.46986286,34.0677551 L8.98208571,13.5308571 C9.03073714,13.3508571 9.19659429,13.2244898 9.38382857,13.2244898 L39.8057143,13.2244898 L42.3783429,13.2244898 C42.6584571,13.2244898 42.72996,13.6256327 42.7483886,13.8262041 L35.2862914,34.2242449 Z" id="Shape" fill={ color ? color : "#80abba" }></path>
                  <path d="M0,33.3770266 L0,2.00718367 C0,0.900734694 0.903737143,0 2.01387429,0 L15.8596286,0 L19.5453429,5.14285714 L37.79184,5.14285714 C38.9019771,5.14285714 39.8057143,6.04285714 39.8057143,7.15004082 L39.8057143,11.755102 L42.3783429,11.755102 C42.3988181,11.755102 42.4191863,11.7554372 42.4394441,11.7561035 L9.3227001,11.7561035 C9.3432832,11.7554359 9.3639105,11.755102 9.38456571,11.755102 L38.3306914,11.755102 L38.3306914,7.15004082 C38.3306914,6.85322449 38.0889086,6.6122449 37.7911029,6.6122449 L18.7860857,6.6122449 L15.1003714,1.46938776 L2.01387429,1.46938776 C1.71680571,1.46938776 1.47428571,1.71036735 1.47428571,2.00718367 L1.47428571,29.2153655 L-2.33146835e-15,33.3770266 Z" id="Combined-Shape" fill={ color ? color : "#80abba" }></path>
              </g>
          </g>
      </g>

  </svg>

  );

}

export default FolderBig;
