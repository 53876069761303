// Dependencies
import React from "react";

const TableEditableHeaderRow = (props) => {
  return (
    <div className="c-table-editable__row c-table-editable__row-header">
      {props.headers.map((text, index) => {
        return (
          <div key={`header-cell-${index}`} className="c-table-editable__cell">
            {text}
          </div>
        );
      })}
    </div>
  );
};

export default TableEditableHeaderRow;
