// Dependencies
import React from "react";
import { connect } from "react-redux";
import Input from "./input";
import { validateAttribute } from '../actions/validate_attribute';
import {deleteAccount} from '../actions/session'
//Components
import Modal from "./modal";


// Actions

class ModalDeleteUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      step: 0,
      password: "",
      keyInput: [],
      password_error: "",
      confirm_error: "",
      submiting: false
    };
  }

  componentDidMount() {
  }

  _disableOfuscated(e, key, state){
    let keys = state.keyInput
    if(!keys.includes(key)){
        keys.push(key)
    }else {
        keys = keys.filter(i => i !== key)
    }
    this.setState({
        keyInput: keys,
    })
  }

  _password_check(state){
		this.props.validateAttribute(this.props.authentication_token, 'check', [{name: 'password', value: state.password}], (responseJSON) => this.setState({step:2}), () => this.setState({"password_error": "Password error"}))  	
  	//validate attributes
  	// this.setState({step:2})
  }

  _confirm(state){

  	if(state.confirmation_text === "DELETE"){
      this.setState({submiting: true})
    	this.props.deleteAccount(this.props.id, this.props.authentication_token, () => this._successDelete(this.props.id), () => this._errorDelete() )
  	}
  	else{
  		this.setState({confirm_error: this.props.delete_word_not_match})
  	}
  }

  _successDelete(id){
    if(this.props.user.id === id){
      localStorage.removeItem("authentication_token");
      sessionStorage.removeItem("authentication_token")
      window.location.replace("/")
    }
    else{
      this.props.hideModal(true)
    }
		  
  }

  _errorDelete(){
    this.setState({submiting: false, confirm_error: "Something went wrong"})
  }



  render() {
    return (
      <Modal 
        buttonText={this.props.buttons.back} 
        showBackButton={true} 
        backAction={e => {
        if (
          e.target.className === "modal-wrapper" ||
          (e.target.closest(".modal-header") !== null &&
            e.target.closest(".modal-header").className.includes("modal-header"))
        ) {
          this.props.hideModal(false);
        }
      }}>
      {
      	this.state.step === 0 &&
	       <div className="wrapper-profile modal" style={{padding: 16}}>
	        <h5 className="headingH2" style={{paddingBottom: 24}}>{this.props.delete_modal.title}</h5>
           <div>
	        	<p>{this.props.delete_modal.description}</p>
	        </div>
          <div className="flex wrapper-tabs" style={{marginTop: 20}}>
		        <button className="btn primary" onClick={()=> this.props.hideModal(false)}>
		          <span className="btn-text">{this.props.internalization.buttons.negative}</span>
		        </button>
						<button className="btn secondary" onClick={()=> this.setState({step: 1})}>
		          <span className="btn-text">{this.props.internalization.buttons.ok}</span>
		        </button> 
	        </div>       
	       </div>

      }
      {
      	this.state.step === 1 &&
      	<div className="wrapper-profile modal" style={{padding: 16}}>
          <p className="headingH2" style={{paddingBottom: 24}}>{this.props.delete_modal.password_text}</p>
 					<div className="wrapper-form-item" onClick={ () => ""}
          >
              <span className="input-label">Password</span>
              <Input 
                colorGrey={true}
                key={"password"}
                name={"password"}
                type={this.state.keyInput.includes(1) ? 'text' : 'password'}
                defaultValue={this.state.password}
                onChangeInput={e => this.setState({password: e.target.value})}
                // onFocusInput={e => _focusedInput(e, element)}
                // onBlurInput={e => _bluredInput(e, element)}
                value={this.state.password}
                onFocus={() => {}}
                onBlur={() => {}}
                disabled={false}
                showErrorLabelNow={this.state.password_error.length !== 0}
                showErrorLabel={this.state.password_error.length !== 0}
                //showFormatError={formFormatErrors[element.id]}
                errorLabel={this.state.password_error}
                withButtonIcon={true}
                iconName={"Eye"}
                colorIcon={'#408197'}
								slashed={this.state.keyInput.includes(1) ? true : false}
                buttonIconAction={e => this._disableOfuscated(e,1,this.state)}                
              />              
	        </div> 
          <div className="flex wrapper-tabs">
            <button className="btn primary" onClick={()=> this.props.hideModal(false)}>
              <span className="btn-text">{this.props.internalization.buttons.cancel}</span>
            </button>
            <button className="btn secondary" onClick={()=> this._password_check(this.state)}>
              <span className="btn-text">{this.props.internalization.buttons.confirm}</span>
            </button> 
          </div>
	       </div>
      }
      {
      	this.state.step === 2 &&
          <div className="wrapper-profile modal"style={{padding: 16}}>
              <p className="headingH2" style={{paddingBottom: 24}}>{this.props.delete_modal.delete_confirmation}</p>
            <div className="wrapper-form-item" onClick={ () => ""} >
              <span
                className="input-label o-display--block o-margin--bottom-size-l"
                dangerouslySetInnerHTML={{ __html:this.props.delete_modal.delete_confirmation_2}}
              ></span>
              <Input
                colorGrey={true}
                key={"text"}
                name={"text"}
                type={"text"}
                defaultValue={this.state.confirmation_text}
                onChangeInput={e => this.setState({confirmation_text: e.target.value})}
                // onFocusInput={e => _focusedInput(e, element)}
                // onBlurInput={e => _bluredInput(e, element)}
                value={this.state.confirmation_text}
                onFocus={() => {}}
                onBlur={() => {}}
                disabled={false}
                showErrorLabel={false}
                showErrorLabelNow={this.state.confirm_error.length !== 0}
                showErrorLabel={this.state.confirm_error.length !== 0}

                //showFormatError={formFormatErrors[element.id]}
                errorLabel={this.state.confirm_error}
              />              
            </div> 
            <div
              style={{marginTop: 40}}
              className="flex wrapper-tabs"
            >
              <button className="btn primary" onClick={()=> this.props.hideModal(false)}>
                <span className="btn-text">{this.props.internalization.buttons.cancel}</span>
              </button>
              <button disabled={this.state.submiting} className="btn secondary" onClick={()=> this._confirm(this.state)}>
                <span className="btn-text">{this.props.internalization.buttons.confirm}</span>
              </button> 
            </div>
          </div>
      }
      </Modal>
      
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    internalization: state.session.internalization,
    user: state.session.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    validateAttribute: (authentication_token, type, attributes, success, error) => dispatch(validateAttribute(authentication_token, type, attributes , success, error)),
    deleteAccount: (id, authentication_token, success, error) => dispatch(deleteAccount(id, authentication_token, success, error)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalDeleteUser);
