export function deviceLanguage() {
  return window.navigator.language;
}

export function getDateLocalized(locale, date) {
  var day = ("0" + date.getDate()).slice(-2); //Current Date
  var month = ("0" + (date.getMonth() + 1)).slice(-2); //Current Month
  var year = date.getFullYear(); //Current Year

  switch (true) {
    case /es_ES/.test(locale):
      return `${day}/${month}/${year}`;
    case /es_US/.test(locale):
      return `${month}/${day}/${year}`;
    case /en_US/.test(locale):
      return `${month}/${day}/${year}`;
    case /en_GB/.test(locale):
      return `${month}/${day}/${year}`;
    case /^es_/.test(locale):
      return `${day}/${month}/${year}`;
    case /^en_/.test(locale):
      return `${month}/${day}/${year}`;
    default:
      return `${month}/${day}/${year}`;
  }
}

export function getHoursToUTC(){
  var date = new Date();
  var offsetInHours = date.getTimezoneOffset() / 60;
  return offsetInHours * (-1)
  // return Intl.DateTimeFormat().resolvedOptions().timeZone //only working on ios android ? => https://github.com/formatjs/react-intl
}

export function getValueLocalized(locale, value, with_symbol, style, decimals = true) {

  value = unLocalize(locale, value);
  //https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Objetos_globales/NumberFormat
  //example: getValueLocalized(deviceLanguage(), 23000, true, 'currency')
  let options = {};
  locale = locale.replace("_", "-");
  if (style === "currency") {
    locale = "en-US";
  }
  // var formatter = new Intl.NumberFormat(locale) -> sin moneda, pero si separación en comas y tal.
  if (with_symbol) {
    switch (true) {
      case /es-ES/.test(locale):
        options = {
          style: style,
          currency: "EUR"
        };
        break;
      case /^es-/.test(locale):
        options = {
          style: style,
          currency: "EUR"
        };
        break;
      default:
        options = {
          style: style,
          currency: "USD",
          minimumFractionDigits: decimals ? 2 : 0
        };
    }
  }

  var formatter = new Intl.NumberFormat(locale, options);
  if (isNaN(value)) {
    return { original: 0, formated: "" };
  } else if (value === "") {
    return { original: "", formated: "" };
  } else {
    return {
      original: value,
      formated:
        style === "percent"
          ? formatter.format(value / 100)
          : formatter.format(value)
    };
  }
}

export function unLocalize(locale, stringNumber) {
  try {
    if (stringNumber === "") return "";
    locale = locale.replace("_", "-");
    var thousandSeparator = (1111).toLocaleString(locale).replace(/1/g, "");
    var decimalSeparator = (1.1).toLocaleString(locale).replace(/1/g, "");

    return parseFloat(
      stringNumber
        .replace(new RegExp("\\" + thousandSeparator, "g"), "")
        .replace(new RegExp("\\" + decimalSeparator), ".")
        .replace("€", "")
        .replace("$", "")
        .replace("%", "")
    );
  } catch (ex) {
    return stringNumber;
  }
}
