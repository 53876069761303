// Dependencies
import React from "react";



//Components
import Icon from "../components/icons";
import ActionSheet from "../components/actionSheet";
import { NOT_PASSIVES, internalization } from "../constants";
import addeparLogo from "../assets/images/addepar.png";
// Helpers
import ActionSheetAction from "../helpers/actionSheetAction";



class AssetsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    this.actionSheet = React.createRef();
  }

  _actionSheetPressed(item, action) {
    ActionSheetAction(item, action, this.props, () => this._setBreadCrumb(item));

    this.actionSheet.current.show();
  }

  _setBreadCrumb(item){
    let languageUserAgent = (/^es/).test(this.props.language) ? 'es' : 'en';  
    let reg = new RegExp(NOT_PASSIVES); 
    let new_bread =[{title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},{title: internalization[languageUserAgent].models.settings, code: "profile", url: "/profile"},{title: internalization[languageUserAgent].models.content_manager, code: "content_manager", url: "/profile?code=cm"}]
    this.props.setSpecialParent && this.props.setSpecialParent(new_bread)
  }

  _showActionSheetContent(item) {
    if(item.code === "financial_assets"){
      window.open(
        'https://bulltick.addepar.com/',
        '_blank' // <- This is what makes it open in a new window.
      );
      return "";
    }

    if(item.code === "wealth_map"){
      this.props.history.push("/wealth-map");
      return "";
    }
    let that = this;
    let optionsActionSheet = [];
    let actions;
    let languageUserAgent = (/^es/).test(this.props.language) ? 'es' : 'en'; 

    if (item.code === "documents") {
      actions = this.props.actions.filter(action => action.code !== "new");
    } else {
      actions = this.props.actions;
    }
    
    actions.map(action => {
      return optionsActionSheet.push({
        type: "icon button",
        code: item.code,
        iconName: action.code.includes("new")
          ? "Close"
          : action.code.includes("edit")
          ? "Pencil"
          : action.code.includes("show")
          ? "Eye"
          : "Garbage",
        message: action.code.includes("new")
          ? this.props.add_translation + " " + item.title
          : action.code.includes("show")
          ? this.props.show_translation + " " + item.title
          : item.title,
        onClick: () => {
          this._actionSheetPressed(item, action);
        }
      });
    });

    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: internalization[languageUserAgent].cancel,
          close: true,
          danger: true
        }
      ]
    });

    setTimeout(function() {
      that.actionSheet.current.show();
    }, 100);
  }

  render() {
    console.log(this.props)
    return (
      <div className="wrapper-asstesList">
        {this.props.cm !== undefined &&
          Object.keys(this.props.cm).length > 0 &&
          Object.values(this.props.cm).map(element => {
            return (
              <>
                <h2
                  className={`headingH2 blueSoft`}
                >
                  {element.title}
                </h2>
                <>
                  {element.items !== undefined &&
                    element.items.length > 0 &&
                    element.items.map((item, index) => {
                      return (
                        <div
                          className="wrapper-asstesList-item"
                          key={index}
                          onClick={() => {
                            this._showActionSheetContent(item);
                          }}
                        >
                          <p
                            className={`truncate`}
                          >
                            {item.title}
                          </p>
                          <button>
                            {
                              item.code === "financial_assets" &&
                              <img src={addeparLogo} width="100px"/>
                            }
                            {
                              item.code !== "financial_assets" &&
                              <Icon
                                name="ChevronArrow"
                                orientation="right"
                                iconSmall={true}
                                bgColor={"#408197"}
                                color={"#408197"}
                              />
                            }
                            
                          </button>
                        </div>
                      );
                    })}
                </>
              </>
            );
          })}
        <ActionSheet
          ref={this.actionSheet}
          render={this.state.options_action_sheet}
        />
      </div>
    );
  }
}

export default AssetsList;
