import { OWNERSHIP_INDEX } from "../constants";


const initialState = {
  ownership_index: {},
};

export default function ownershipReducer(state = initialState, action) {
  switch (action.type) {
    case "OWNERSHIP_INDEX":
      return Object.assign({}, state, {
        ownership_index: action.payload
      });
    default:
      return state;
  }
}