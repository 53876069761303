// Dependencies
import React from 'react';

const Pencil = ({ width, height, color }) => {

  return (

    <svg
      width={width ? width : '20px'}
      height={height ? height : '20px'}
      viewBox="0 0 20 20"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons-/-tools-/-pencil" transform="translate(-8.000000, -8.000000)" fill={color ? color : "#80abba"}>
          <path d="M20.7516444,11 L25,15.2477145 L14.2461443,26 L10,21.7522855 L20.7516444,11 Z M27.6237521,10.2175113 L25.9497716,8.49839363 C25.302888,7.83386879 24.2524871,7.83386879 23.6034638,8.49839363 L22,10.1450871 L25.7533389,14 L27.6237521,12.079184 C28.125416,11.5639026 28.125416,10.7327449 27.6237521,10.2175113 Z M8.01109896,27.4554709 C7.93855414,27.7770519 8.23312859,28.0652049 8.55950622,27.98706 L13,26.9263147 L9.01744232,23 L8.01109896,27.4554709 Z" id="Fill-1"></path>
        </g>
      </g>
    </svg>

  );

}

export default Pencil;
