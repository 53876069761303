// Dependencies
import React from "react";
import Icon from "./icons";
import TextEllipsis from "react-text-ellipsis";

//helper
import { highlightWord } from "../helpers/highlightWord";

const MediaBlock = ({
  image,
  title,
  subtitle,
  description,
  rightText,
  documentType,
  fullWidth,
  action,
  deleteItem,
  deleteIconAction,
  date,
  notImage,
  titleFullWidth,
  imageSeparator,
  alignLeft,
  titleBold,
  center,
  isSearch
}) => {
  const classMediaBlockWrapper = [
    "mediaBlock-wrapper",
    fullWidth && "full-width",
    titleFullWidth && "mediaBlock-wrapper--full-content",
    center && "mediaBlock-wrapper--full-content center"
  ]
    .filter((elem) => elem !== false)
    .join(" ");

  const classMediaBlockInfoHeader = [
      "mediaBlock-info-header",
      titleFullWidth && "mediaBlock-info-header--full-width",
      imageSeparator && "mediaBlock-info-header--margintop-ten",
      
    ]
      .filter((elem) => elem !== false)
      .join(" ");

  const classMediaBlockInfoHeaderText = [
      "mediaBlock-info-header-text",
      alignLeft && "mediaBlock-info-header-text--left",
      titleBold && "mediaBlock-info-header-text--bold",
      
    ]
      .filter((elem) => elem !== false)
      .join(" ");

  return (
    <div
      onClick={() => (action !== undefined ? action() : "")}
      className={classMediaBlockWrapper}
    >
      <div className={alignLeft ? "mediaBlock-header mediaBlock-header--left" : "mediaBlock-header"}>
        {image && !notImage && image}
        <div className={classMediaBlockInfoHeader}>
          {/* <span className="title2">{title && title}</span> */}
          {
            <TextEllipsis
            lines={ (description || (description && description !== "")) ? 2 : 3}
            tag={"p"}
            ellipsisChars={"..."}
            debounceTimeoutOnResize={200}
            useJsOnly={true}
            tagClass={classMediaBlockInfoHeaderText}
            >
            {
              isSearch ? 
              highlightWord(title, isSearch) :
              title && title
            }
            
            </TextEllipsis>
          }
          {deleteItem && (
            <div
              className="mediaBlock-info-header-icon"
              onClick={(e) => deleteIconAction && deleteIconAction(e)}
              style={{ width: 20, height: 20 }}
            >
              <Icon
                width={"16px"}
                height={"16px"}
                color={"#f3b01c"}
                name={"Garbage"}
              />
            </div>
          )}
        </div>
      </div>
      {(subtitle || description) && (
        <div className={titleFullWidth ? "mediaBlock-content mediaBlock-content--full-width" : "mediaBlock-content"}>
          {subtitle && (
            <span className="mediaBlock-content-text mediaBlock-content-text__subtitle">{subtitle && subtitle}</span>
          )}
          {description && (
            <p className={alignLeft ? "mediaBlock-content-text mediaBlock-content-text--left body grey-dark" : "mediaBlock-content-text body grey-dark"}>

            {isSearch ?  highlightWord(description, isSearch) : description && description} 
            </p>
          )}
        </div>
      )}

      {date && <div className="mediaBlock-date">{date}</div>}
    </div>
  );
};

export default MediaBlock;
