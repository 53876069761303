// Dependencies
import React, { useState } from "react";
import { IMaskInput } from "react-imask";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";

import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import PhoneInput, {
  getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input";

// Components
import Icon from "./icons/";

// Helpers
import { unLocalize } from "../helpers/getLocale";
const moment = require("moment");

const colorIcon = "#408197";

function Input({
  withIcon,
  success,
  error,
  type,
  iconName,
  defaultValue,
  value,
  placeholder,
  placeholderTextColor,
  onChangeInput,
  name,
  withButtonIcon,
  onBlur,
  onFocus,
  onFocusInput,
  onBlurInput,
  buttonIconAction,
  closeIcon,
  options,
  showErrorLabel,
  showErrorLabelNow,
  errorLabel,
  disabled,
  showFormatError,
  colorIcon,
  bgColorIcon,
  prepare_dates,
  datesToEdit,
  actionCheckbox,
  id,
  slashed,
  autoComplete,
  requirementOptions,
  requirementCheck,
  showRequirementCheck,
  passwords_match_enabled,
  passwords_match,
  password_match_text,
  multipleOptions,
  itemsSelected,
  resource_key,
  showFormatErrorLabel,
  colorGrey,
  required,
  hasTooltip,
  helperTooltip,
  key,
  multipleInputs,
  minimum_date_valuation,
  maxLength,
  allowNegative,
  currency_type,
  sqft_type,
  builded_surface_type,
  crypto_type
}) {
  const [focus, setFocus] = useState(false);
  const [hint, setHint] = useState("");
  const [showHint, setShowHint] = useState(false);
  const [inputTransparent, setInputTransparent] = useState(true);
  const [todayDate, setTodayDate] = useState(new Date());
  const [items, setItems] = useState(
    itemsSelected !== undefined && itemsSelected.length > 0 ? itemsSelected : []
  );
  const [showTooltip, setShowTooltip] = useState(false);

  const inputClass = [
    "input",
    (showFormatError || error) === true && "error",
    (showErrorLabelNow && showErrorLabel && defaultValue === "") === true &&
      "error",
    success === true && "succes",
    focus === true && "focus",
    placeholderTextColor === "red-placeholder"
      ? "red-placeholder"
      : /*
         * : placeholderTextColor === "aquamarine-placeholder"
         * ? "aquamarine-placeholder"
         */
        "grey-placeholder",
    (type === "currency" || type === "percentage" || type === "number") &&
      inputTransparent &&
      "transparent",
  ]
    .filter((elem) => elem !== false)
    .join(" ");

  const wrapperInputClass = [
    "wrapper-input",
    (showFormatError || error) === true && "error",
    (showErrorLabelNow && showErrorLabel && defaultValue === "") === true &&
      "error",
    success === true && "succes",
    focus === true && "focus",
    withButtonIcon === true && "with-icon",
  ]
    .filter((elem) => elem !== false)
    .join(" ");

  // withIconButton
  const constWithButtonIcon = (
    <button onClick={(e) => buttonIconAction(e)} className="input-button">
      <Icon
        name={iconName}
        iconSmall={true}
        iconClass="icon-centered"
        opacity={false}
        color={colorIcon}
        bgColor={bgColorIcon}
        close={closeIcon}
        slashed={slashed}
      />
    </button>
  );
  // @withIconButton

  // withIcon
  const constWithIcon = (
    <Icon
      name={iconName}
      onInput={withIcon}
      color={
        error ? "#FE4040" : success ? "#7BA900" : focus !== true && "#787D7F"
      }
    />
  );
  // @withIcon

  const addDays = (date, days, type = "") => {
    
    
    var result = new Date(date);

    if (["receivable_account", "liability"].includes(resource_key) && type === "max") {
      result.setFullYear(result.getFullYear() + 30);
      return result
    }    
    if (date !== "") {
      result.setDate(result.getDate() + days);
    } else {
      result = "";
    }

    return result;
  };

  const checkDateMax = (name, next_date_temp) => {
    if (name == "end_date" && ["receivable_account", "liability"].includes(resource_key)) {
       var result = new Date(next_date_temp);
       result.setFullYear(result.getFullYear() + 30);
      return result
    }
    else{
      return ""
    }
  }

  const getOptions = (option) => {
    if (!multipleOptions) {
      onChangeInput(option);
    } else {
      let index = option.indexOf(option.value);
      if (index !== -1) {
        let allItems = option.filter((value) => value !== option.value);
        setItems(allItems);
      } else {
        setItems([...items, option.value]);
      }
    }
  };

  let next_date = new Date();
  if (prepare_dates) {
    if (
      [
        "end_date",
        "rent_end_date",
        "end_date_activity",
        "end_date_alert",
        "lease_end_date",
        "date_bought",
        "sold_date",
        "date",
      ].includes(name)
    ) {
      let next_date_temp;
      if (name === "date_bought") {
        next_date_temp = "date";
      } else if (name === "date") {
        next_date_temp = "date_bought";
      } else {
        next_date_temp = name.replace("end", "start");
      }

      next_date = prepare_dates.hasOwnProperty(next_date_temp)
        ? prepare_dates[next_date_temp]
        : new Date();
    } else {
      try {
        next_date = prepare_dates[name];
      } catch (e) {}
    }
  }

  const _handleTooltip = (e) => {
    e.stopPropagation();

    if (showTooltip !== false) {
      let modalContent = document.querySelector(".modal-content");
      if (modalContent) {
        let modalBody = document.querySelector(".modal-body");
        modalBody.style.paddingTop = "0px";
      }
      setShowTooltip(false);
    } else {
      setShowTooltip(true);

      let modalContent = document.querySelector(".modal-content");
      if (modalContent) {
        setTimeout(() => {
          h();
        }, 0);
      }
    }
  };

  const h = () => {
    let tooltip = document.querySelector(".container-tooltip");
    let wrapperHelper = document.querySelector(".wrapper-helper");
    let containerTooltip = document.querySelector(".container-tooltip");
    let containerTootlipHeight = containerTooltip.offsetHeight + 36;
    let containerTootlipTop = containerTooltip.getBoundingClientRect().y;
    let modalContent = document.querySelector(".modal-content");
    let modalContentTop = modalContent.getBoundingClientRect().y;
    let dif = containerTootlipTop - (modalContentTop + 112);

    if (dif < 0) {
      let difPositive = -dif;
      let modalBody = document.querySelector(".modal-body");
      modalBody.style.paddingTop = `${difPositive}px`;
    }
  };

  const getNumberSuffix = () => {
    if(id === "builded_surface" && builded_surface_type){
      if(builded_surface_type.value === 0 || builded_surface_type.value == 1){
        return builded_surface_type.value === 0 ? " Sq Ft" : " ㎡"
      }
    }
    if(id === "case_size"){
      return " mm"
    }
    if((resource_key === "real_estate" || resource_key === "real_estates") && id === "sqft" && sqft_type){
      if(sqft_type.value === 0 || sqft_type.value == 1){
        return sqft_type.value === 0 ? " ha" : " ac"
      }
      if(sqft_type.value === 2 || sqft_type.value == 3){
        return sqft_type.value === 2 ? " Sq Ft" : " ㎡"
      }
    }
    
    if(id === "sqft" && sqft_type){
      if(sqft_type.value === 0 || sqft_type.value == 1){
        return sqft_type.value === 0 ? " Sq Ft" : " ㎡"
      }
    }
    return ""
    
  }

  const navigateWebPage = (value) => {
    window.open(value, '_blank').focus();
  }
  return (
    <>
      <div
        className={`${
          hasTooltip
            ? "has-tooltip"
            : multipleInputs
            ? "wrapper-input-multiple-items"
            : ""
        }`}
      >
        {/* input */}
        {(type === "text" ||
          type === "password" ||
          type === "geo_location" ||
          type === "email" ||
          type === "tel") &&
          id !== "ssn" && (
            <div
              onClick={id === "web_page" && disabled ? () => navigateWebPage(value) : ''}
              className={
                colorGrey ? ` ${wrapperInputClass} grey` : wrapperInputClass
              }
            >
              <input

                id={type === "geo_location" ? "address_input" : id}
                type={id === "year" ? "number" : type === "geo_location" ? "text" : type}
                name={name}
                key={key}
                defaultValue={defaultValue}
                //value={value}
                placeholder={placeholder}
                style={{pointerEvents: disabled ? 'none' : 'all'}}
                onFocus={
                  onFocus
                    ? (e) => {
                        setFocus(true);
                      }
                    : () => {}
                }
                onBlur={
                  onBlur
                    ? (e) => {
                        setFocus(false);
                      }
                    : () => {}
                }
                className={colorGrey ? ` ${inputClass} grey` : inputClass}
                onChange={(event) => {
                  onChangeInput(event);
                }}
                disabled={disabled === true ? "disabled" : ""}
                autocomplete={autoComplete == true ? 'on' : 'off'}
                autoComplete={autoComplete == true ? 'on' : 'off'}
                autofill={autoComplete == true ? 'on' : 'off'}
                role="presentation"
                maxLength={maxLength && maxLength}
              />
              {withButtonIcon && constWithButtonIcon}
              {withIcon && constWithIcon}
            </div>
          )}
        {/* @input */}

        {/* @phone */}
        {type === "phone" && (
          <PhoneInput
            key={"phone_" + name}
            placeholder="Enter phone number"
            value={defaultValue || ""}
            onChange={(value) => {
              onChangeInput(value);
            }}
          />
        )}
        {/* @phone */}
        {/* requiremntOptions */}
        {showRequirementCheck && requirementOptions && (
          <div className="wrapper-requirements-options">
            <ul className="wrapper-requirements-options__list">
              {requirementOptions.map((option, index) => (
                <li className="wrapper-requirements-options__list-item">
                  {requirementCheck.includes(option) && (
                    <Icon
                      name="Check"
                      color={colorIcon}
                      width="12px"
                      height="12px"
                      className="wrapper-requirements-options__list-icon"
                    />
                  )}
                  {!requirementCheck.includes(option) && (
                    <Icon
                      name="Close"
                      color="#f3b01c"
                      width="12px"
                      height="12px"
                      className="wrapper-requirements-options__list-icon"
                    />
                  )}
                  {option}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* @requiremntOptions */}

        {passwords_match_enabled !== undefined && passwords_match_enabled && (
          <div className="wrapper-requirements-options">
            <ul className="wrapper-requirements-options__list">
              <li className="wrapper-requirements-options__list-item">
                {passwords_match ? (
                  <Icon
                    name="Check"
                    color="#408197"
                    width="12px"
                    height="12px"
                    className="wrapper-requirements-options__list-icon"
                  />
                ) : (
                  <Icon
                    name="Close"
                    color="#f3b01c"
                    width="12px"
                    height="12px"
                    className="wrapper-requirements-options__list-icon"
                  />
                )}
                {password_match_text}
              </li>
            </ul>
          </div>
        )}

        {/* input number, currency and percentage */}

        {type === "currency" && (
          <div
            className={
              colorGrey
                ? "wrapper-input grey currency-wrapper"
                : "wrapper-input currency-wrapper"
            }
          >
            <NumberFormat
              id={id}
              className={colorGrey ? "input-number grey" : "input-number"}
              onValueChange={(values) => onChangeInput(values)}
              disabled={disabled === true && "disabled"}
              defaultValue={crypto_type ? defaultValue : unLocalize("en-US", defaultValue)}
              thousandSeparator={","}
              decimalSeparator={"."}
              prefix={currency_type && currency_type !== 'USD' ? ""+currency_type+" " : "USD "}
              inputMode="numeric"
              allowNegative={allowNegative}
              onBlur={(e) => onBlurInput(e)}
              autocomplete={autoComplete == true ? 'on' : 'off'}
              autoComplete={autoComplete == true ? 'on' : 'off'}
             // value={value}
            />
          </div>
        )}
        {type === "percentage" && (
          <div
            className={
              colorGrey
                ? "wrapper-input grey currency-wrapper"
                : "wrapper-input currency-wrapper"
            }
          >
            <NumberFormat
              allowNegative={false}
              className={colorGrey ? "input-number grey" : "input-number"}
              disabled={disabled === true && "disabled"}
              onValueChange={(values) => onChangeInput(values)}
              defaultValue={unLocalize("en-US", defaultValue)}
              thousandSeparator={","}
              decimalSeparator={"."}
              suffix={"%"}
              inputMode="numeric"
              //value={value}
            />
          </div>
        )}
        {type === "number" && (
          <div
            className={
              colorGrey
                ? "wrapper-input grey currency-wrapper"
                : "wrapper-input currency-wrapper"
            }
          >
            <NumberFormat
              className={colorGrey ? "input-number grey" : "input-number"}
              disabled={
                ["age", "age_owner"].includes(id) &&
                ["owner_beneficiary", "insured"].includes(resource_key)
                  ? "disabled"
                  : disabled === true && "disabled"
              }
              suffix={getNumberSuffix()}
              onValueChange={(values) => onChangeInput(values)}
              defaultValue={unLocalize("en-US", defaultValue)}
              thousandSeparator={["year","case_size"].includes(id) ? false : ","}
              decimalSeparator={id === "year" ? "" : "."}
              inputMode="numeric"
              allowNegative={id === "year" ? false :allowNegative}
              // value={
              //   ["age", "age_owner"].includes(id) &&
              //   ["owner_beneficiary", "insured"].includes(resource_key)
              //     ? defaultValue
              //     : value
              // }
            />
          </div>
        )}


        {id === "ssn" && (
          <div
            className={
              colorGrey
                ? "wrapper-input with-icon grey currency-wrapper"
                : "wrapper-input with-icon currency-wrapper"
            }
          >
            <IMaskInput
              className={colorGrey ? ` ${inputClass} grey` : inputClass}
              mask={"***-**-****"}
              radix="."
              value={defaultValue}
              unmask={true}
              lazy={false}
              placeholderChar={" "}
              onAccept={(value) => onChangeInput(value)}
              style={{ fontSize: disabled ? "16px" : "14px" }}
            />
            {withButtonIcon && constWithButtonIcon}
            {withIcon && constWithIcon}
          </div>
        )}

        {/* @input number, currency  and percentage */}

        {/* textarea */}
        {type === "textarea" && (
          <textarea
            name={name}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onFocus={onFocus ? () => setFocus(true) : () => {}}
            onBlur={onBlur ? () => setFocus(false) : () => {}}
            className={colorGrey ? ` ${inputClass} grey` : inputClass}
            onChange={(event) => onChangeInput(event)}
            disabled={disabled === true && "disabled"}
          />
        )}
        {/* @textarea */}

        {/* radio */}
        {type === "radio" &&
          options &&
          options !== undefined &&
          options.map((option, index) => {
            return (
              <div key={index} className="wrapper-input-radio">
                <label className="container-radio-button">
                  <input
                    name={name}
                    type={type}
                    value={option.value}
                    defaultChecked={option.value === defaultValue}
                    onChange={(e) => onChangeInput(e)}
                    disabled={disabled === true && "disabled"}
                  />
                  <span className="checkmark"></span>
                  <span className="radio-label">{option.label}</span>
                </label>
              </div>
            );
          })}
        {/* @radio */}

        {/* checkbox */}
        {type === "checkbox" &&
          options &&
          options !== undefined &&
          options.map((option, index) => {
            return (
              <div
                key={index}
                className="wrapper-input-checkbox"
                style={{
                  display: option.items !== undefined ? "flex" : "",
                  flexDirection: option.items !== undefined ? "column" : "",
                }}
              >
                <div>
                  <label className="container-check">
                    <input
                      id={option.id}
                      name={name + "_" + index}
                      type={type}
                      defaultChecked={option.checked}
                      // checked={option.checked}
                      onChange={(e) => actionCheckbox(e)}
                    />
                    <span className="checkmark"></span>
                    <span className="checkbox-label">{option.label}</span>
                  </label>
                </div>
                <div
                  style={{
                    marginTop: option.items !== undefined ? "36px" : "",
                  }}
                >
                  {option.items !== undefined &&
                    option.items.length > 0 &&
                    option.items.map((item, index2) => {
                      return (
                        <div key={index2} className="wrapper-input-checkbox">
                          <label className="container-check">
                            <input
                              id={item.owner_id}
                              name={name + "_" + index + "_nested_" + index2}
                              type={type}
                              defaultChecked={item.checked}
                              // checked={item.checked}
                              onChange={(e) => actionCheckbox(e)}
                            />
                            <span className="checkmark"></span>
                            <span className="checkbox-label">{item.label}</span>
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}

        {/* select */}
        {type === "select" && options !== undefined && (
          <div
            className={
              colorGrey ? ` ${wrapperInputClass} grey` : wrapperInputClass
            }
          >
            <select
              name={name}
              className={colorGrey && "grey"}
              onChange={(event) => getOptions(event)}
              onFocus={onFocus ? () => setFocus(true) : () => {}}
              onBlur={onBlur ? () => setFocus(false) : () => {}}
              disabled={disabled === true && "disabled"}
              defaultValue={defaultValue !== "" ? defaultValue : ""}
            >
              {options.map((option, index) => {
                return (
                  <option
                    disabled={option.value === "Select"}
                    key={index}
                    value={option.id}
                  >
                    {option.value}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        {/* @select */}

        {/* date */}
        {type === "date" && (
          <div
            className={
              colorGrey ? ` ${wrapperInputClass} grey` : wrapperInputClass
            }
          >
            <DatePicker
              selected={
                prepare_dates[id] !== "" ? new Date(prepare_dates[id]) : ""
              }
              showTimeSelect={
                resource_key === "alert" &&
                (name === "start_date" || name === "end_date")
              }
              todayButton={
                resource_key === "alert" &&
                (name === "start_date" || name === "end_date")
                  ? false
                  : "Today"
              }
              onChange={(date) => {
                onChangeInput(
                  date,
                  new Date(date) > new Date(prepare_dates.end_date)
                );
                setTodayDate(date);
              }}
              timeFormat="hh:mm aa"
              timeIntervals={5}
              dateFormat={
                resource_key === "alert" &&
                (name === "start_date" || name === "end_date")
                  ? "MM/dd/y hh:mm aa"
                  : "MM/dd/y"
              }
              maxDate={
                [
                  "end_date",
                  "rent_end_date",
                  "end_date_activity",
                  "end_date_alert",
                  "sold_date",
                  "lease_end_date",
                ].includes(name)
                  ? checkDateMax(name, next_date)
                  : resource_key === "alert"
                  ? ""
                  : name === "date_bought"
                  ? addDays(next_date, -1, "max")
                  : new Date()
              }
              minDate={
                // ["end_date","rent_end_date","end_date_activity", "end_date_alert", "lease_end_date"].includes(name) ? addDays(datesToEdit.start_date, 1) :
                [
                  "end_date",
                  "rent_end_date",
                  "end_date_activity",
                  "end_date_alert",
                  "lease_end_date",
                ].includes(name) && prepare_dates.start_date !== undefined
                  ? addDays(next_date, 1, "min")
                  : [
                      "end_date",
                      "rent_end_date",
                      "end_date_activity",
                      "end_date_alert",
                      "lease_end_date",
                      "date",
                    ].includes(name)
                  ? addDays(next_date, 1, "min")
                  : resource_key === "alert"
                  ? new Date()
                  : resource_key === "valuation" &&
                    minimum_date_valuation !== ""
                  ? moment(minimum_date_valuation, "MM/DD/YYYY").toDate()
                  : ""
              }
              onFocus={onFocus ? () => setFocus(true) : () => {}}
              onBlur={onBlur ? () => setFocus(false) : () => {}}
              disabled={disabled === true && "disabled"}
              className={colorGrey && "grey"}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
        )}
        {/* @date */}

        {/* Delete */}
        {type === "delete" && (
          <div
            className={
              colorGrey ? ` ${wrapperInputClass} grey` : wrapperInputClass
            }
          >
            <input
              type={type === "delete" && "text"}
              className={colorGrey ? ` ${inputClass} grey` : inputClass}
              name={name}
              value={value}
              placeholder={placeholder}
              onFocus={onFocus ? () => setFocus(true) : () => {}}
              onBlur={onBlur ? () => setFocus(false) : () => {}}
              onChange={(event) => onChangeInput(event)}
              disabled={disabled === true && "disabled"}
            />
            {withButtonIcon && constWithButtonIcon}
          </div>
        )}
        {/* @Delete */}
        {hasTooltip && (
          <span
            className="wrapper-helper"
            onClick={(e) => {
              _handleTooltip(e);
            }}
          >
              <div className="helper-text">
                <Icon name="InfoTooltip" noWrapper={true}/>
              </div>

            {showTooltip === true && (
              <div
                onClick={(e) => {
                  _handleTooltip(e);
                }}
                className="container-tooltip right"
              >
                <span className="tooltip-title">{helperTooltip[0]}</span>
                <span
                  className="tooltip-text"
                  dangerouslySetInnerHTML={{ __html: helperTooltip[1] }}
                ></span>
              </div>
            )}
          </span>
        )}
      </div>
      {/* Error */}
      {showErrorLabelNow && showErrorLabel && (
        <span className="label error">{errorLabel}</span>
      )}
      {/* @Error */}

      {/* Error format */}
      {showFormatError && (
        <span className="label error">{showFormatErrorLabel}</span>
      )}
      {/* @Error format */}

      {/* Hint */}
      {showHint && <span className="label hint">{hint}</span>}
      {/* @Hint */}
    </>
  );
}

export default Input;
