// Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Components
import ContentBlock from "../../components/contentBlock";
import AssetsResourcesModal from "../balance/details/assetsResourcesModal";
import Search from "../../components/search";
import SectionList from "../../components/sectionList";
import { searchAll } from "../../actions/search";
import AlertModal from "../../components/alertModal";
import EmptySearch from '../../components/emptySearch'

//Actions
import { getContact, destroyAssignAssetByResourceable } from "../../actions/contact";
import { createAssetResource } from "../../actions/balance";
import { getArticleFiles } from "../../actions/articles";


function ServicesDetailScreen(props) {

	const [data, setData] = useState("")
  const [value, setValue] = useState("")
  const [isSearching, setIsSearching] = useState(false)

  useEffect(() => {
   props.getArticleFiles(props.authentication_token, props.location.state.id, "", (responseJSON) => setData(responseJSON), () => console.log("error"))
  },[])
  
  const  _search = (text) =>{
    setValue(text)
    setIsSearching(true)
    props.getArticleFiles(props.authentication_token, props.match.computedMatch.params.id, text, (responseJSON) => setData(responseJSON), () => console.log("error"))
  } 


  const viewItem = (item, type) => {
    window.open(item.link, '_blank')
  }

  return(
    <div className="main-wrapper">
      <Search 
        colorGrey={true}
        iconLeft="with-icon-left"
        iconRight={false}
        placeholder="Search"
        getValue={(value) => _search(value)}
        />

        <ContentBlock className="centered-and-full">

            <ul className="section-list-list">
              <SectionList
                data={[{data: data, title: ""}]}
                title={false}
                modal={true}
                location={props.location}
                actionContact={item => {
                  // this._showContact(item.id, item.url, this.state);
                }}
                actionContent={(item,type) =>  viewItem(item,type)
                  
                  // item.model_class == "contact" ? _showContact(item.id, item.url, this.state) :
                  // item.model_class == "image" ? this._showImage(item) : this._showDocument(item.url)
                }
                actionTitle={() => ""}
                history={props.history}
                createImageAsset={""}
                fetch={""}
                authentication_token={props.authentication_token}
                type={""}
                searchAll={false}
                assignDataType={"file"}
              />
              
            </ul>
              
          
          {
            data.length === 0 && isSearching &&
            <EmptySearch nameIcon="BoxEmpty" text="No data" />
          }

        </ContentBlock>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    internalization: state.session.internalization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    searchAll: (authentication_token, text, success, error) => dispatch(searchAll(authentication_token, text, success, error)),
    getArticleFiles: (authentication_token, id, text, success, error) => dispatch(getArticleFiles(authentication_token, id, text, success, error)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesDetailScreen);
