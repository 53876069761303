import {HOST_URL} from '../constants';
 
import get_header from './api_header'
import {parseHeaders} from './api_header'


export function sendDeviceToken(authentication_token, device_token, type, device_info, uuid) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/device_tokens', {
      method: 'POST',
      headers: get_header(authentication_token),
      body: JSON.stringify({ type: type, device_token: device_token, device_info: device_info, device_uuid: uuid})
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          //dispatch(getTeamProfile(responseJson));
        })	
      };

    })
    .catch((error) => {
      console.error(error);
    });
  }
}
