// Dependencies
import React from "react";

// Components
import Icon from './icons/';


const successRecovery = (props) => {

  return (

    <div className="signIn-content">

      <div className="wrapper-signIn-logo">
        <Icon name="Logo" className="logo-big" width={160} height={52} />
      </div>

	    	<Icon name={props.icon} color='#f3b01c'/>
	    	
    
    	<div className="container-text" >
    		<p className="headingH2 o-padding--bottom-zero" >{props.successText}</p>
    	</div>
      {
        props.lockedTitle != undefined && props.lockedTitle != "" &&
        <div className="container-text" >
          <p className="text" >{props.lockedTitle}</p>
        </div>
      }
      {
        props.lockedSubtitle != undefined && props.lockedSubtitle != "" &&
        <div className="container-text" >
          <p className="text o-text-align--center" >{props.lockedSubtitle}</p>
        </div>
      }      
      {
        props.successTextSubtitle != undefined && props.successTextSubtitle != "" &&
        <div className="container-text" >
          <p className="text" style={{textAlign: 'center'}}>{props.successTextSubtitle}</p>
        </div>

      }
      <div className="signIn-wrapper-buttons">
        <button
          type="submit"
          className="btn primary"
          onClick={e => {e.preventDefault();props.action(e)}}
        >
          <span className="btn-text">{props.buttonText}</span>
        </button>
      </div> 
    </div>

  );

}

export default successRecovery;
