// Dependencies
import React from "react";

const OpenApp = ({ width, height, color }) => {
  return (
    <svg
      width={width ? width : "490px"}
      height={height ? height : "512px"}
      viewBox="0 0 490 512"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="smartphone-(1)">
          <path
            d="M261.4375,511.441406 L400.289062,456.5 C455.054688,411.570312 490,343.367188 490,267 C490,131.691406 380.308594,22 245,22 C109.691406,22 0,131.691406 0,267 C0,402.3125 109.691406,512 245,512 C250.527344,512 256.003906,511.800781 261.4375,511.441406 Z"
            id="Path"
            fill="#80abba"
          ></path>
          <path
            d="M388.742188,318.949219 C390.695312,295.304688 387.09375,271.613281 376.625,250.320312 C349.582031,195.308594 355.785156,191.316406 354.945312,136.71875 C354.945312,125.5625 345.902344,116.523438 334.75,116.523438 C323.59375,116.523438 314.554688,125.5625 314.554688,136.71875 C314.554688,143.921875 314.554688,340.550781 314.554688,339.78125 C314.554688,342.929688 314.21875,346.003906 313.597656,348.96875 L313.597656,378.039062 C313.597656,402.535156 293.667969,422.46875 269.171875,422.46875 C252.902344,422.46875 196.796875,422.46875 158.621094,422.46875 C175.601562,446.085938 198.835938,464.582031 225.800781,475.78125 C225.96875,475.847656 226.136719,475.917969 226.304688,475.988281 C242.527344,482.84375 254.914062,495.769531 261.4375,511.441406 C313.972656,507.960938 361.984375,487.933594 400.289062,456.503906 L385.238281,401.210938 C383.089844,393.308594 382.332031,385.09375 383,376.933594 L388.742188,318.949219 Z"
            id="Path"
            fill="#F9CFA9"
          ></path>
          <path
            d="M83.609375,117.230469 L77.457031,111.53125 C67.976562,102.742188 53.027344,103.3125 44.242188,112.796875 C35.457031,122.277344 36.027344,137.226562 45.507812,146.011719 L83.609375,181.316406 L83.609375,117.230469 Z"
            id="Path"
            fill="#F9CFA9"
          ></path>
          <path
            d="M285.621094,0 L112.90625,0 C96.148438,0 82.515625,13.636719 82.515625,30.394531 L82.515625,394.375 C82.515625,411.132812 96.148438,424.769531 112.90625,424.769531 L285.621094,424.769531 C302.378906,424.769531 316.015625,411.132812 316.015625,394.375 L316.015625,30.394531 C316.015625,13.636719 302.378906,-2.84217094e-14 285.621094,-2.84217094e-14 Z"
            id="Path"
            fill="#005774"
          ></path>
          <path
            d="M141.035156,22.539062 C139.390625,20.09375 138.429688,17.152344 138.429688,13.984375 C138.429688,7.765625 142.132812,2.421875 147.445312,0 L112.90625,0 C96.121094,0 82.511719,13.609375 82.511719,30.394531 L82.511719,394.375 C82.511719,411.160156 96.121094,424.765625 112.90625,424.765625 L149.625,424.765625 L149.625,49.175781 C149.625,39.617188 146.371094,30.472656 141.035156,22.539062 Z"
            id="Path"
            fill="#005774"
          ></path>
          <path
            d="M100.109375,191.976562 L100.109375,394.375 C100.109375,401.445312 105.839844,407.175781 112.90625,407.175781 L285.621094,407.175781 C292.6875,407.175781 298.417969,401.445312 298.417969,394.375 C298.417969,387.429688 298.417969,286.039062 298.417969,191.976562 L100.109375,191.976562 Z"
            id="Path"
            fill="#F7F7F7"
          ></path>
          <path
            d="M100.109375,191.976562 L100.109375,394.375 C100.109375,401.445312 105.839844,407.175781 112.90625,407.175781 L143.179688,407.175781 L143.179688,191.976562 L100.109375,191.976562 Z"
            id="Path"
            fill="#E4E4E4"
          ></path>
          <path
            d="M169.675781,253.03125 C169.675781,264.105469 160.699219,273.082031 149.625,273.082031 C138.550781,273.082031 129.574219,264.105469 129.574219,253.03125 C129.574219,241.957031 138.550781,232.976562 149.625,232.976562 C160.699219,232.976562 169.675781,241.957031 169.675781,253.03125 Z"
            id="Path"
            fill="#005774"
          ></path>
          <path
            d="M169.675781,327.382812 C169.675781,338.457031 160.699219,347.4375 149.625,347.4375 C138.550781,347.4375 129.574219,338.457031 129.574219,327.382812 C129.574219,316.308594 138.550781,307.332031 149.625,307.332031 C160.699219,307.332031 169.675781,316.308594 169.675781,327.382812 Z"
            id="Path"
            fill="#005774"
          ></path>
          <path
            d="M147.359375,233.113281 C137.351562,234.242188 129.574219,242.722656 129.574219,253.03125 C129.574219,264.105469 138.550781,273.082031 149.625,273.082031 C156.53125,273.082031 162.617188,269.589844 166.222656,264.277344 C148.933594,266.226562 137.925781,247.007812 147.359375,233.113281 Z"
            id="Path"
            fill="#005774"
          ></path>
          <path
            d="M147.359375,307.46875 C137.351562,308.59375 129.574219,317.078125 129.574219,327.382812 C129.574219,338.460938 138.550781,347.4375 149.625,347.4375 C156.53125,347.4375 162.617188,343.945312 166.222656,338.632812 C149.039062,340.566406 137.863281,321.453125 147.359375,307.46875 Z"
            id="Path"
            fill="#005774"
          ></path>
          <g
            id="Group"
            transform="translate(195.000000, 229.000000)"
            fill="#A2A5AF"
          >
            <path
              d="M36.566406,15.695312 L8.097656,15.695312 C3.957031,15.695312 0.597656,12.335938 0.597656,8.195312 C0.597656,4.050781 3.957031,0.695312 8.097656,0.695312 L36.566406,0.695312 C40.707031,0.695312 44.066406,4.050781 44.066406,8.195312 C44.066406,12.335938 40.707031,15.695312 36.566406,15.695312 Z"
              id="Path"
            ></path>
            <path
              d="M65.035156,47.367188 L8.097656,47.367188 C3.953125,47.367188 0.597656,44.007812 0.597656,39.867188 C0.597656,35.722656 3.953125,32.367188 8.097656,32.367188 L65.035156,32.367188 C69.175781,32.367188 72.535156,35.722656 72.535156,39.867188 C72.535156,44.007812 69.175781,47.367188 65.035156,47.367188 Z"
              id="Path"
            ></path>
            <path
              d="M36.566406,90.046875 L8.097656,90.046875 C3.957031,90.046875 0.597656,86.691406 0.597656,82.546875 C0.597656,78.402344 3.957031,75.046875 8.097656,75.046875 L36.566406,75.046875 C40.707031,75.046875 44.066406,78.402344 44.066406,82.546875 C44.066406,86.691406 40.707031,90.046875 36.566406,90.046875 Z"
              id="Path"
            ></path>
            <path
              d="M65.035156,121.71875 L8.097656,121.71875 C3.953125,121.71875 0.597656,118.363281 0.597656,114.21875 C0.597656,110.078125 3.953125,106.71875 8.097656,106.71875 L65.035156,106.71875 C69.175781,106.71875 72.535156,110.078125 72.535156,114.21875 C72.535156,118.363281 69.175781,121.71875 65.035156,121.71875 Z"
              id="Path"
            ></path>
          </g>
          <path
            d="M285.519531,17.597656 L259.851562,17.597656 C257.429688,17.597656 255.46875,19.558594 255.46875,21.976562 C255.46875,27.785156 250.71875,32.535156 244.914062,32.535156 L153.417969,32.535156 C147.609375,32.535156 142.859375,27.785156 142.859375,21.976562 C142.859375,19.558594 140.898438,17.597656 138.476562,17.597656 L112.808594,17.597656 C105.738281,17.597656 100.007812,23.328125 100.007812,30.394531 L100.007812,193.976562 L298.320312,193.976562 L298.320312,30.394531 C298.320312,23.328125 292.589844,17.597656 285.519531,17.597656 Z"
            id="Path"
            fill="#f3b01c"
          ></path>
          <path
            d="M143.179688,24.507812 C142.976562,23.695312 142.859375,22.851562 142.859375,21.980469 C142.859375,19.558594 140.898438,17.597656 138.476562,17.597656 L112.808594,17.597656 C105.738281,17.597656 100.007812,23.328125 100.007812,30.394531 L100.007812,193.976562 L143.179688,193.976562 L143.179688,24.507812 Z"
            id="Path"
            fill="#BF8332"
          ></path>
          <path
            d="M285.519531,17.597656 L259.851562,17.597656 C257.429688,17.597656 255.46875,19.558594 255.46875,21.976562 C255.46875,27.785156 250.71875,32.535156 244.914062,32.535156 L194.449219,32.535156 L298.320312,162.738281 L298.320312,30.394531 C298.320312,23.328125 292.589844,17.597656 285.519531,17.597656 Z"
            id="Path"
            fill="#FFBE68"
          ></path>
          <path
            d="M166.917969,77.207031 L132.332031,77.207031 C128.191406,77.207031 124.832031,73.851562 124.832031,69.707031 C124.832031,65.5625 128.191406,62.207031 132.332031,62.207031 L166.917969,62.207031 C171.058594,62.207031 174.417969,65.5625 174.417969,69.707031 C174.417969,73.847656 171.058594,77.207031 166.917969,77.207031 Z"
            id="Path"
            fill="#FFBE68"
          ></path>
          <path
            d="M117.816406,241.191406 C110.085938,249.53125 97.054688,250.03125 88.710938,242.300781 L54.214844,210.335938 C45.871094,202.605469 45.375,189.574219 53.105469,181.230469 C60.835938,172.886719 73.867188,172.390625 82.210938,180.121094 L116.707031,212.085938 C125.046875,219.816406 125.546875,232.847656 117.816406,241.191406 Z"
            id="Path"
            fill="#F9CFA9"
          ></path>
          <path
            d="M116.707031,212.085938 L100.296875,196.882812 C102.457031,204.707031 100.664062,213.425781 94.746094,219.8125 C88.824219,226.203125 80.265625,228.652344 72.304688,227.097656 L88.710938,242.300781 C97.054688,250.03125 110.082031,249.53125 117.816406,241.191406 C125.546875,232.847656 125.046875,219.816406 116.707031,212.085938 Z"
            id="Path"
            fill="#FBBC8D"
          ></path>
          <path
            d="M117.816406,297.363281 C110.085938,305.707031 97.054688,306.203125 88.710938,298.472656 L54.214844,266.507812 C45.871094,258.777344 45.375,245.746094 53.105469,237.40625 C60.835938,229.0625 73.867188,228.566406 82.210938,236.296875 L116.707031,268.261719 C125.046875,275.992188 125.546875,289.019531 117.816406,297.363281 Z"
            id="Path"
            fill="#F9CFA9"
          ></path>
          <path
            d="M117.816406,353.539062 C110.085938,361.882812 97.054688,362.378906 88.710938,354.648438 L54.214844,322.683594 C45.871094,314.953125 45.375,301.921875 53.105469,293.578125 C60.835938,285.234375 73.867188,284.738281 82.210938,292.46875 L116.707031,324.433594 C125.046875,332.164062 125.546875,345.195312 117.816406,353.539062 Z"
            id="Path"
            fill="#F9CFA9"
          ></path>
          <path
            d="M385.238281,401.207031 C384.171875,397.277344 383.449219,393.273438 383.070312,389.234375 C344.90625,451.890625 270.8125,480.988281 201.214844,462.894531 C208.976562,467.882812 217.199219,472.203125 225.800781,475.777344 C225.96875,475.847656 226.136719,475.917969 226.304688,475.988281 C242.527344,482.839844 254.914062,495.765625 261.4375,511.441406 C313.972656,507.960938 361.984375,487.929688 400.289062,456.5 L385.238281,401.207031 Z"
            id="Path"
            fill="#FBBC8D"
          ></path>
          <path
            d="M116.707031,268.261719 L100.296875,253.058594 C102.457031,260.878906 100.664062,269.597656 94.746094,275.988281 C88.824219,282.378906 80.265625,284.828125 72.304688,283.273438 L88.710938,298.472656 C97.054688,306.203125 110.085938,305.707031 117.816406,297.363281 C125.546875,289.019531 125.046875,275.992188 116.707031,268.261719 Z"
            id="Path"
            fill="#FBBC8D"
          ></path>
          <path
            d="M116.707031,324.433594 L100.296875,309.230469 C102.457031,317.054688 100.664062,325.773438 94.746094,332.160156 C88.824219,338.550781 80.265625,341.003906 72.304688,339.445312 L88.710938,354.648438 C97.054688,362.378906 110.085938,361.882812 117.816406,353.539062 C125.546875,345.195312 125.046875,332.164062 116.707031,324.433594 Z"
            id="Path"
            fill="#FBBC8D"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default OpenApp;
