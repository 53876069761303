// Dependencies
import React from "react";

const RealEstate = (props) => {
  return (
    <svg
      width={props.width ? props.width : "70px"}
      height={props.height ? props.height : "48px"}
      viewBox="0 0 70 48"
    >
    
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
            <g id="Real-Estate" transform="translate(-7.000000, -8.000000)" stroke={props.color ? props.color : "#80abba"}>
                <g id="Group" transform="translate(8.000000, 9.000000)">
                    <polyline id="Path-5" strokeWidth="1.8" strokeLinejoin="round" points="67.2248535 23.2299805 39.9842463 0 12.9067383 22.7738626"></polyline>
                    <polyline id="Path-6" strokeWidth="1.8" strokeLinejoin="round" points="33.6080322 5.34033203 27.3193359 0 0 22.7738626"></polyline>
                    <path d="M19.7193359,45.2692871 L19.7193359,16.9494629" id="Path-8" strokeWidth="1.8"></path>
                    <polyline id="Path-7" strokeWidth="1.8" strokeLinejoin="round" points="7.14004882 16.9494629 7.14004882 45.2692871 60.3378906 45.2692871 60.3378906 17.4951172"></polyline>
                    <path d="M34.2128906,44.7958984 L34.2128906,31.6898945 C35.0585849,28.4272305 36.9823701,26.7958984 39.9842463,26.7958984 C42.9861225,26.7958984 44.8965658,28.3244644 45.7155762,31.3815963 L45.7155762,39.2524779" id="Path-9" strokeWidth="2" stroke-linejoin="round"></path>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default RealEstate;
