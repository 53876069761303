import { HOST_URL, URL_VERSION } from '../constants';

import get_header from './api_header'
import {parseHeaders} from './api_header'


export function createActualPerformance(authentication_token, success, error, data) {
  
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + "/balance_principal/deferred_assets/actual_performances", {
      method: 'POST',
      headers: get_header(authentication_token),
      body: JSON.stringify(data)
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}


export function editActualPerformance(authentication_token, success, error, data) {
  
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + 'balance_principal/deferred_assets/actual_performances/'+ data.actual_performance.budget_id, {
      method: 'PUT',
      headers: get_header(authentication_token),
      body: JSON.stringify(data)
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success()          
        })
      }
      else{
        error();
      }

    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function getInputsActualPerformance(authentication_token, url, success,error) {
  return (dispatch) => {
    return fetch(HOST_URL+ url, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}