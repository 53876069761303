// Dependencies
import React from "react";

const Watch = (props) => {
  return (
    <svg
      width={props.width ? props.width : "27px"}
      height={props.height ? props.height : "48px"}
      viewBox="0 0 27 48"
    >
      
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Watch" transform="translate(-19.000000, -7.000000)" fill={props.color ? props.color : "#80abba"} fillRule="nonzero">
                <g id="noun_Watch_476839" transform="translate(19.000000, 7.000000)">
                    <g id="Group">
                        <path d="M26.5531915,24 C26.5531915,19.6488889 24.4581447,15.7902222 21.2381277,13.3555556 C21.2381277,13.3475556 21.2425532,13.3413333 21.2425532,13.3333333 L21.2425532,0.888888889 C21.2425532,0.398222222 20.8469106,0 20.3574468,0 L6.19574468,0 C5.70716596,0 5.3106383,0.398222222 5.3106383,0.888888889 L5.3106383,13.3333333 C5.3106383,13.3413333 5.31506383,13.3475556 5.31506383,13.3555556 C2.09504681,15.7911111 0,19.6488889 0,24 C0,28.3511111 2.09504681,32.2088889 5.31506383,34.6453333 C5.31506383,34.6524444 5.3106383,34.6586667 5.3106383,34.6666667 L5.3106383,47.1111111 C5.3106383,47.6026667 5.70716596,48 6.19574468,48 L20.3574468,48 C20.8469106,48 21.2425532,47.6026667 21.2425532,47.1111111 L21.2425532,34.6666667 C21.2425532,34.6586667 21.2381277,34.6524444 21.2381277,34.6453333 C24.4581447,32.2097778 26.5531915,28.3511111 26.5531915,24 Z M7.08085106,1.77777778 L19.4723404,1.77777778 L19.4723404,12.2168889 C17.6206979,11.2311111 15.5141447,10.6666667 13.2765957,10.6666667 C11.0390468,10.6666667 8.93249362,11.2311111 7.08085106,12.2168889 L7.08085106,1.77777778 Z M19.4723404,46.2222222 L7.08085106,46.2222222 L7.08085106,35.784 C8.93249362,36.7688889 11.0390468,37.3333333 13.2765957,37.3333333 C15.5141447,37.3333333 17.6206979,36.7688889 19.4723404,35.784 L19.4723404,46.2222222 Z M13.2765957,35.5555556 C6.93215319,35.5555556 1.77021277,30.3715556 1.77021277,24 C1.77021277,17.6284444 6.93215319,12.4444444 13.2765957,12.4444444 C19.6210383,12.4444444 24.7829787,17.6284444 24.7829787,24 C24.7829787,30.3715556 19.6210383,35.5555556 13.2765957,35.5555556 Z" id="Shape"></path>
                        <path d="M13.2765957,14.2222222 C12.788017,14.2222222 12.3914894,14.6204444 12.3914894,15.1111111 L12.3914894,23.632 L9.1104,26.9271111 C8.7643234,27.2746667 8.7643234,27.8364444 9.1104,28.184 C9.28299574,28.3573333 9.50958298,28.4444444 9.73617021,28.4444444 C9.96275745,28.4444444 10.1893447,28.3573333 10.3619404,28.184 L13.902366,24.6284444 C14.068766,24.4622222 14.1617021,24.2355556 14.1617021,24 L14.1617021,15.1111111 C14.1617021,14.6204444 13.7651745,14.2222222 13.2765957,14.2222222 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default Watch;
