// Dependencies
import React, { useState, useEffect, useRef } from "react";

import { internalization } from "../constants";
import { canManage, canRead } from "../helpers/permits";

// Components
import ActionSheet from './actionSheet'
import Icon from './icons'

// Variables
let proportionalExcessActive = 0;
let proportionalExcessPasive = 0;
let counterBigActiveTotalPercentages = 0;
let percetageToRest = 0;



function TreeGraphic(props) {
    const [netWorthActive, setNetWorthActive] = useState(!props.positive_balance);
    const [netWorthPasive, setNetWorthPasive] = useState(props.positive_balance);
    const [netWorthPasivePercentage, setNetWorthPasivePercentage] = useState(44);
    const [optionsActionSheet, setOptionsActionSheet] = useState([])
    const actionSheetRef = useRef(null);

    useEffect(() => {
        proportionalExcessActive = 0;
        proportionalExcessPasive = 0;
        counterBigActiveTotalPercentages = 0;
        percetageToRest = 0;


        _handleActiveHeight(props.data.type.active);
        _handlePasiveHeight(props.data.type.pasive);

        getActivePasiveAverage();

    }, [props.data.type.active, props.data.type.pasive]);


    const getActivePasiveAverage = () => {
        let active = parseInt(props.data.counters.active.split(',').join(''));
        let pasive = Math.abs(parseInt(props.data.counters.pasive.split(',').join('')));
        let total = active - pasive;
        let result = (total * 100) / active;
        setNetWorthPasivePercentage(result <= 86 ? result : 84); // 86 less 2px of borders

    }

    const _handleActiveHeight = value => {
        let counterSmallActive = 0;
        let counterBigActive = 0;
        let counterSmallActiveTotalPercentages = 0;

        value.map(data => {
            if (data.y > 7) {
                counterBigActiveTotalPercentages += Math.floor(data.y); // Get total of big values
                data.y > 7 && counterBigActive++;
            }
            data.y <= 7 && counterSmallActive++;
        });

        counterSmallActiveTotalPercentages = 7 * counterSmallActive;
        let getTotalPercentages = counterBigActiveTotalPercentages + counterSmallActiveTotalPercentages;
        percetageToRest = (getTotalPercentages - 100) / counterBigActive; // Get the rest
    };

    const _handlePasiveHeight = value => {
        let counterSmallPasive = 0;
        let edge = netWorthPasivePercentage > 70 ? 40 : 14
        let counterBigPasive = 0;
        let minHeightPasive = 0;

        // value.map(data => return data.y <= 7 ? counterSmallPasive++ : counterBigPasive++);
        /*
         * minHeightPasive = 7 * counterSmallPasive;
         * proportionalExcessPasive = minHeightPasive / counterBigPasive;
         */

        value.map((data, index) => Math.floor(data.y) <= edge && counterSmallPasive++ );
        proportionalExcessPasive = edge * counterSmallPasive;
    };

    const _prepareAddPlusLink = (item) => {
    let multiple_new = props.data.multiple_new[item.code]
    let associated_elements = []
      try{
        associated_elements = [{add_document: multiple_new["form_document"].add_document}, {add_contact: multiple_new["form_contact"].add_contact}]
        if(multiple_new["form_alerts"] && Object.keys(multiple_new["form_alerts"]).length > 0){
          associated_elements.push({add_alert: multiple_new["form_alerts"].add_alerts})
        }
      }catch(e){

      }
      return associated_elements;
    }

    const goToCreateAsset = (selected, item) => {
      props.history.push("/balance-new", {
        form: selected.form.add_asset.inputs,
        resource_key: selected.form.add_asset.resource_key,
        url: selected.url,
        method: 'POST',
        code: 'add_asset',
        from: props.from,
        resource_constant: item.code ? props.data.multiple_new[item.code].resource_constant : "",
        title: props.add_translation + " "+ selected.title,
        associated_elements: _prepareAddPlusLink(selected),
        modal: selected.form.add_asset.modal
      });
   }

    const createAsset = (item) => {
      console.log(item)
      console.log(props.permits)
      if(!canManage(props.permits, item.code)){
         props.openModalPermissions()
         return;
      }
      if(props.real_user_insurance && ["bank_account", "receivable_account", "fixed_asset", "other", "financial_asset"].includes(item.code)){
        props.openModalPermissions()
      } else {
        if(item.code === "deferred_asset") item.code = "life_insurance"

        let selected = props.data.short_cuts && props.data.short_cuts.filter(i => i.code === item.code)
        if(selected && selected.length === 0){
            selected = props.data.short_cuts && props.data.short_cuts.filter(i => i.code === 'liability')
        }

        if(selected && selected[0].subcategories !== undefined){
          if(props.canManage){
            if(selected[0].code === "life_insurance"){
              if(props.bulltick_insurance_agency && props.real_user_insurance){
                goToCreateAsset(selected[0], item)
              }else if(props.bulltick_insurance_agency && !props.real_user_insurance){
                _showActionSheetContent(selected[0])
              }else {
                goToCreateAsset(selected[0], item)
              }
            }else {
              _showActionSheetContent(selected[0])
            }
            
          }
        }else {
           if(props.canManage && selected){
            goToCreateAsset(selected[0], item)
        }
      }
      
    }

    
    }

    const _showActionSheetContent = (asset) => {
      const languageUserAgent = (/^es/).test(props.language) ? 'es' : 'en'; 
        let options = []
        asset.subcategories.map((item,index) => {
            options.push({
                type: 'icon button',
                iconName: 'Close',
                code: item.code,
                message: item.title,
                onClick: item.form !== undefined ? () => {
                        props.history.push("/balance-new", {
                        form: item.form.add_asset.inputs,
                        modal: item.form.add_asset.modal,
                        resource_key: item.form.add_asset.resource_key,
                        url: item.url,
                        method: 'POST',
                        resource_constant: item.code ? props.data.multiple_new[item.code].resource_constant : "",
                        code: 'add_asset',
                        title: props.add_translation + " "+ item.title,
                        associated_elements: _prepareAddPlusLink(item)
                      });
                } : () => {props.history.push("/balance-show?url=" + item.url, {
                        url: item.url, open_actions: true,
                      
                    });},
                },
                
            )
        })

        options.push({
            close: 'true',
            danger: 'true',
            message: internalization[languageUserAgent].cancel,
            type: 'noIcon button'   
            })

        setOptionsActionSheet(options)
    

        setTimeout(function() {
          actionSheetRef.current.show();
        }, 100);

      }

      const goToAsset = (item) => {
        if (props.real_user_insurance && ["bank_account", "receivable_account", "fixed_asset", "other", "financial_asset"].includes(item.code)) {
          props.openModalPermissions();
        } else {
          props.history.push("/balance-show?url=" + item.url, {
            url: item.url,
          });
        }
      };

  const _showIcon = (index, empty, code) => {
    if((props.insuranceUser && empty) || (props.insuranceUser && code === "financial_asset") || (props.real_user_insurance && code === "financial_asset")){
      return true
    }else {
      return empty && code !== "financial_asset"
    }
  }

  const actionItemActive = (item) => {
    if(!item.empty && item.code === "deferred_asset" && !props.insuranceUser){
      goToAsset(item)
    }else if(!item.empty && (props.insuranceUser && item.code !== "financial_asset")){
      goToAsset(item)
    }else if(!props.insuranceUser && !item.empty){
      goToAsset(item)
    } else {
      createAsset(item)
    }
  }

  const showAmount = (item) => {
    if(!props.insuranceUser && (props.real_user_insurance && item.code !== "financial_asset") && (!item.empty || item.code === "deferred_asset")){
      return true
    }else if((props.insuranceUser && item.code !== "financial_asset") && (!item.empty || item.code === "deferred_asset")){
      return true
    }else if(props.real_user_insurance && item.code === "financial_asset"){
      return false
    }else {
      return true
    }
  }

  const showProgressBar = (item) => {
    if((!item.empty || item.code === "deferred_asset") && (!props.real_user_insurance && !props.insuranceUser)){
      return true
    }else if(props.real_user_insurance && ["bank_account", "receivable_account", "fixed_asset", "other", "financial_asset"].includes(item.code)){
      return false
    }
  }

  const _renderItemActive = data_active => {

    return (

      data_active.active.map((item, index) => {
        return (
          <div
            key={index}
            className={`graphic-item ${_showIcon(index, item.empty,item.code) && props.canManage && 'empty'}`}
            onClick={() => {
                // index === 0 && !props.insuranceUser ? goToAsset(item) : !item.empty && (props.insuranceUser && item.code !== "financial_asset") ? goToAsset(item) : createAsset(item)
                actionItemActive(item)
            }}
          >
              {
                _showIcon(index, item.empty, item.code) && props.canManage &&
                  <Icon name='Close' close={false} color={'white'} width={'24px'}/>
              }

            <div className="text-container">

              <p className="text">{item.x}</p> 

              {
                (!item.empty || item.code === "deferred_asset" ) && 
                 showAmount(item) &&
                  <p className="amount"> { !item.empty && `${item.value} (${item.y}%)` } </p>
              }
              {
                item.value_to_show !== "" &&
                <p className="text-value">{ item.value_to_show}</p>
              }
            </div>

            
            

            {
              showProgressBar(item) &&
                <div className="progress-bar-container">
                  <div className="progress-bar" style={{right: `${100 - item.y}%`}}></div>
                </div>
            }
          </div>
        );
      })
    )
  }

  const _renderItemPasive = data_pasive => {

    return(

      data_pasive.pasive.map((item, index) => {
        return (
          <div
            key={index}
            className={`graphic-item pasive ${(_showIcon(index, item.empty) && props.canManage) && 'empty'}`}
            onClick={() => {
              !item.empty ? goToAsset(item) : createAsset(item)
            }}
          >
              {
                _showIcon(index, item.empty) && props.canManage &&
                  <Icon name='Close' close={false} color={'white'} width={'24px'}/>
              }

            <div className="text-container">

              <p className="text">{item.x}</p> 

              {
                !item.empty &&
                  <p className="amount"> { !item.empty && `${item.value} (${item.y}%)` } </p>
              }
            </div>

            {
              !item.empty &&
                <div className="progress-bar-container">
                  <div className="progress-bar" style={{left: `${100 - item.y}%`}}></div>
                </div>
            }
          </div>
        );
      })
    )
  }

       
  return (

    <div className="wrapper-tree-graphic">

      <span className={`text-total ${ props.data.signTotal === "negative" ? "orange" : "blue" }`} >
        {props.data.total}
      </span>

      <div className="wrapper-graphic">

        {props.data.counters.percentage.left !== undefined && (

          // Assets
          <div className="first-graphic-block" >

            {/* Header */}
            <div className="wrapper-header-side">
              <span className="title">
                {props.data.counters.active_title}:
                <span className="amount"> {props.data.counters.active}</span>
              </span>
            </div>
            {/* @Header */}

            <div className="wrapper-body-side">
                {_renderItemActive(props.data.type)}
            </div>

          </div>
          // @Assets
        )}

        {props.data.counters.percentage.right !== undefined && (

          // Liabilities
          <div className="second-graphic-block">

            {/* Header */}
            <div className="wrapper-header-side">
              <span className="title">
                {props.data.counters.pasive_title}:
                <span className="amount"> {props.data.counters.pasive}</span>
              </span>
            </div>
            {/* @Header */}

            {
              props.data.type.pasive.length > 0 &&
                <div className="wrapper-body-side">
                  {_renderItemPasive(props.data.type)}

                  {
                    netWorthPasive &&
                      <div className="graphic-item networth-container" 
                        style={{
                          height: ((props.data.type.active.length - props.data.type.pasive.length) * 150) + (((props.data.type.active.length - props.data.type.pasive.length) * 24) - 24)
                        }}>
                        <div className="text-container">
                          <p className="text">{props.data.counters.net_worth_title}</p>
                          <p className="amount">{props.total}</p>
                        </div>

                        <div className="progress-bar-container">
                          {
                            props.data.counters.percentage.left > props.data.counters.percentage.right &&
                              <div className="progress-bar" style={{right: `${100 - props.data.counters.percentage}%`}}></div>
                          }
                          {
                            props.data.counters.percentage.left < props.data.counters.percentage.right && 
                              <div className="progress-bar" style={{left: `${100 - props.data.counters.percentage}%`}}></div>
                          }
                        </div>
                      </div>
                  }



                </div>                    
            }




          </div>
          // @Liabilities
        )}
      </div>
      <ActionSheet ref={actionSheetRef} render={optionsActionSheet} />
    </div>

  );
}

export default TreeGraphic;
