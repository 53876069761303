// Dependencies
import React, {useState} from "react";

const WarningBanner = (props) => {

  return (
    <div class="c-warning-banner">
      {props.children}
    </div>
  );
};

export default WarningBanner;
