const initialState = {
  articles: {},
  showArticle: {}
};

export default function articleReducer(state = initialState, action) {

  switch (action.type) {
    case "ARTICLES":
      return Object.assign({}, state, {
        articles: action.payload
      });
    case "ARTICLE":
      return Object.assign({}, state, {
        showArticle: action.payload
      });

    default:
      return state;
  }
}