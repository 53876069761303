import { HOST_URL, URL_VERSION } from '../constants';

import get_header from './api_header'
import {parseHeaders} from './api_header'

export function cleanDocShow() {
  return {
    type: "CLEAN_DOCUMENTS_SHOW",
  }
}

export function getDocIndexSuccess(payload) {
  return {
    type: "DOCUMENTS_INDEX",
    payload
  }
}

export function getDocShowSuccess(payload) {
  return {
    type: "DOCUMENTS_SHOW",
    payload
  }
}

export function getDocDetailSuccess(payload) {
  return {
    type: "DOCUMENTS_DETAIL",
    payload
  }
}

export function getDocIndex(authentication_token, success, error) {
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + 'documents/categories', {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
      .then((response) => {
        if (response.ok) {
          response.json().then((responseJson) => {
            dispatch(getDocIndexSuccess(responseJson.categories))
            success(responseJson.title)
          })
        }
        else if(response.status !== 401){
          error()
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export function getDocShow(authentication_token, success, error, url) {
  
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
      .then((response) => {
        if (response.ok) {
          response.json().then((responseJson) => {
            console.log(responseJson)
            dispatch(getDocShowSuccess(responseJson.show))
            success(responseJson.category_title)
          })
        }
        else if(response.status !== 401){
          error()
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export function getDocSearch(authentication_token, text, success, error, url) {
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + "documents/documents?text="+text, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {          
          success(responseJson)
        })
      }
      else{
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function getDocDetail(authentication_token, success, error, url) {
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
      .then((response) => {
        if (response.ok) {
          response.json().then((responseJson) => {
            dispatch(getDocDetailSuccess(responseJson.bc_show))
            success(responseJson.bc_show)
          })
        }
        else if(response.status !== 401){
          error()
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export function getDocNew(authentication_token, parent_category, category_id, success, error, url) {
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + url + "?parent_category=" + parent_category + "&category_id=" + category_id, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
      .then((response) => {
        if (response.ok) {
          response.json().then((responseJson) => {
            success(responseJson)
          })
        }
        else if(response.status !== 401){
          error()
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export function updateDoc(authentication_token, attributes, success, error, url) {
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: 'PUT',
      headers: get_header(authentication_token),
      body: JSON.stringify(attributes)
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson.bc_show)
        })
      }
      else{
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}


export function createDocumentAsset(authentication_token, success, error, id, resourceable_id, resourceable_type) {
  
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + "/documents/documents_assets", {
      method: 'POST',
      headers: get_header(authentication_token),
      body: JSON.stringify({ id: id, resourceable_id: resourceable_id, resourceable_type: resourceable_type})
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })
      }
      else{
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}


