// Dependencies
import React from "react";

// Components
import Icon from "./icons";

let timeInterval = 5000;
let startTime = 0;
var timer = null;

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancel: false,
      text: this.props.searchText || "",
     
    };
  }

  _sendValue(e, state) {

    this.setState({ text: e.target.value });
    this._onChangeSearch(e.target.value, state)
    
  }

  _clearSearchInput(text) {   
     
    this.setState({ text: text, showIcon: false });

    this.props.getValue(text);
  }

  _action() {
    this.props.action();
  }

  _search(text, state){
    this.props.getValue(text)
  }

  _onChangeSearch(text, state){
    clearTimeout(timer); 
    var that = this;
    timer = setTimeout(() => {that._search(text, state)}, 1000)   
  }  

  render() {
    return (
      <div className="wrapper-search">
        <div
          className={`wrapper-input with-border transparent ${
            this.props.iconLeft
          } ${this.props.colorGrey && "grey"}`}
        >
          <Icon name="Search" width={20} height={20} />
          <input
            className="background-transparent"
            placeholder={this.props.placeholder}
            ref={input => (this.searcherInput = input)}
            onChange={e => this._sendValue(e, this.state)}
            value={this.state.text}
          />
          {this.state.text.length > 0 && (
            <button className="btn-clear" onClick={() => this._clearSearchInput("")} >
            <Icon name="Plus" close={true} width={20} height={20} />
          </button>
          )}
          
        </div>
        {this.props.iconRight && (
          <button className="btn-icon-right" onClick={() => this._action()} >
            <Icon name="Filter" />
          </button>
        )}
      </div>
    );
  }
}

export default Search;
