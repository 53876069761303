// Dependencies
import React from 'react';

const Notifications = ({ width, height, colorPrimary, colorSecondary, strokeWidth }) => {

  return (

    <svg
      width={width ? width : '39px'}
      height={height ? height : '46px'}
      viewBox="0 0 39 46"
    >

      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="notification" fillRule="nonzero">
          <path d="M23.3741992,38.80675 C23.1039492,38.3506133 22.5193359,38.2011133 22.0613125,38.4636367 C21.6031992,38.732 21.4499258,39.3203867 21.7181992,39.7765234 C21.9673359,40.2001367 22.1034492,40.7080234 22.1034492,41.2082734 C22.1034492,42.7933867 20.8135625,44.0832734 19.2284492,44.0832734 C17.6433359,44.0832734 16.3534492,42.7933867 16.3534492,41.2082734 C16.3534492,40.7080234 16.4895625,40.2001367 16.7386992,39.7765234 C17.0050859,39.3184102 16.8518125,38.7319102 16.3955859,38.4636367 C15.9336992,38.2010234 15.3509727,38.3505234 15.0826992,38.80675 C14.6590859,39.53125 14.4348359,40.3611367 14.4348359,41.2083633 C14.4348359,43.8513867 16.5834492,46 19.2265625,46 C21.8696758,46 24.0181992,43.8513867 24.0220625,41.2083633 C24.0220625,40.3611367 23.7978125,39.53125 23.3741992,38.80675 Z" id="Path" fill="#f3b01c"></path>
          <path d="M38.2571758,38.7991133 L34.9643125,33.3136133 C33.4463125,30.7817266 32.6431992,27.8837266 32.6431992,24.9301133 L32.6431992,20.125 C32.6431992,12.7286133 26.6229492,6.70836328 19.2265625,6.70836328 C11.8301758,6.70836328 5.80992578,12.7286133 5.80992578,20.125 L5.80992578,24.9301133 C5.80992578,27.8837266 5.0068125,30.7817266 3.4888125,33.3136133 L0.195949219,38.7991133 C0.0176992187,39.09425 0.0138359375,39.4642266 0.1825625,39.7632266 C0.353175781,40.0641133 0.673199219,40.2500898 1.01819922,40.2500898 L37.4348359,40.2500898 C37.7798359,40.2500898 38.0999492,40.0642031 38.2704727,39.7632266 C38.4391992,39.4641367 38.4354258,39.09425 38.2571758,38.7991133 Z M2.71067578,38.3333633 L5.13142578,34.29875 C6.8295625,31.46975 7.7265625,28.2306133 7.7265625,24.9301133 L7.7265625,20.125 C7.7265625,13.78275 12.8843125,8.625 19.2265625,8.625 C25.5688125,8.625 30.7265625,13.78275 30.7265625,20.125 L30.7265625,24.9301133 C30.7265625,28.2306133 31.6235625,31.46975 33.3198125,34.29875 L35.7424492,38.3333633 L2.71067578,38.3333633 Z" id="Shape" fill="#005774"></path>
          <path d="M19.2265625,0 C17.1124492,0 15.3931992,1.71925 15.3931992,3.83336328 L15.3931992,7.66672656 C15.3931992,8.19563672 15.8225625,8.625 16.3515625,8.625 C16.8805625,8.625 17.3099258,8.19563672 17.3099258,7.66663672 L17.3099258,3.83336328 C17.3099258,2.77536328 18.1685625,1.91672656 19.2265625,1.91672656 C20.2845625,1.91672656 21.1431992,2.77536328 21.1431992,3.83336328 L21.1431992,7.66672656 C21.1431992,8.19563672 21.5725625,8.625 22.1015625,8.625 C22.6305625,8.625 23.0599258,8.19563672 23.0599258,7.66663672 L23.0599258,3.83336328 C23.0599258,1.71925 21.3406758,0 19.2265625,0 Z" id="Path" fill="#005774"></path>
        </g>
      </g>
    </svg>

  );

}

export default Notifications;
