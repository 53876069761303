// Dependencies
import React, { useState, useEffect } from "react";

// Components
import Input from "./input";
import Icon from "./icons";

function MultipleInputs(props) {
  const [items, setItems] = useState([]);


  
  useEffect(()=> {
    let newItem = []
    if(props.defaultValue && props.defaultValue.length > 0){
      _generateItem(props.defaultValue)
    }else {
      newItem = [{name: props.name, id:props.name,type:props.type, value:""}]
      setItems(newItem)
    }
  },[])

  
  const _addItem = () => {
    
      if(items.length < 3){
        if(props.defaultValue !== undefined && props.defaultValue.length >= items.length){
          setItems([...items, {name: props.name, id:props.name,type:props.type, value: ""}]);
        }
      }
  };
  

  const _deleteItem = (index, name) => {
    
    let tempValues = props.defaultValue
    tempValues.splice(index,1)

    _generateItem(tempValues)

    props.updateMultiple(name,tempValues)
  }

  const _generateItem = (tempItem) => {
    let newItem = []
    tempItem.map((item,index) => {
      newItem = [...newItem,{name: props.name, id:props.name,type:props.type, value:item}]

    setItems(newItem)
    })
  }

  const _btnIconAction = (e, index, name) => {
    e.currentTarget.parentElement.children[0].value = ""
    let tempValues = props.defaultValue
    tempValues[index] = ""
    props.updateMultiple(name,tempValues)
  }

  const _checkGaps = () => {
    if(!props.required) return false;
    if (props.defaultValue && props.defaultValue.length > 0) return props.defaultValue.every(e => e === "")
  }

  return (
    <div className={props.disabled ? "wrapper-add-item disabled" : "wrapper-add-item"}>
      <div className="content">
        {items.map((item, index) => {
          return (
            <div key={index+"_"+item.value} className={props.manualDisabled ? "multiple-item" : "multiple-item edit"}>
              <p className="text">{props.name}{!props.manualDisabled && props.required && <span className="icon">*</span>} </p>
              <div className={props.showErrorLabel ? "wrapper-form-item error" : "wrapper-form-item" }>
              <div className="icon-multiple-item " onClick={() => _deleteItem(index, item.name)}>
                {index !== 0 && !props.manualDisabled &&
                  <Icon width="8" height="8" name="Minus" color="white" className="add-icon"/>
                }
            </div>
            <Input {...props} multipleInputs={true} id={item.name+"_"+index} onChangeInput={(e) => props.onChangeInput(e,index)} name={item.name} type={item.type} label="Name" iconName="Plus" defaultValue={item.value} value={item.value} closeIcon={props.closeIcon} buttonIconAction={(e)=> _btnIconAction(e,index, item.name)} datesToEdit={props.datesToEdit}  showErrorLabel={index === 0 && _checkGaps() ? true : false} showErrorLabelNow={index === 0 && _checkGaps() ? true : false} 
            disabled={props.manualDisabled != null ? props.manualDisabled : item.disabled}
            />
              </div>
            </div>
          );
        })}
        <div className="add-item">
          <div className="container-icon " onClick={() => _addItem()}>
            {
              !props.manualDisabled &&
              <Icon width="10" height="10" name="Close" color="white" iconBig={false} className="add-icon" close={false}/>
            }
            
          </div>
       
            <p className={props.manualDisabled ? "text hidden" : "text"}>{props.name}{!props.manualDisabled && props.required && <span className="icon">*</span>} </p>

        </div>
      </div>
    </div>
  );
}

export default MultipleInputs;
