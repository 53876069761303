// Dependencies
import React from 'react';

const DocWord = ({ width, height, color, bgColor }) => {

  return (

    <svg
      width={width ? width : '30px'}
      height={height ? height : '30px'}
      viewBox="0 0 30 30"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons-/-docs-/-word">
          <g id="doc-word">
            <circle id="Combined-Shape" fill={bgColor ? bgColor : "#80abba"} cx="15" cy="15" r="15"></circle>
            <path d="M20.25,8.25 L9.75,8.25 C8.925,8.25 8.25,8.925 8.25,9.75 L8.25,20.25 C8.25,21.075 8.925,21.75 9.75,21.75 L20.25,21.75 C21.075,21.75 21.75,21.075 21.75,20.25 L21.75,9.75 C21.75,8.925 21.075,8.25 20.25,8.25 L20.25,8.25 Z M17.625,18.75 L16.5,18.75 L15,13.125 L13.5,18.75 L12.375,18.75 L10.575,11.25 L11.85,11.25 L12.975,16.875 L14.475,11.25 L15.525,11.25 L17.025,16.875 L18.15,11.25 L19.425,11.25 L17.625,18.75 L17.625,18.75 Z" id="Shape" fill={color ? color : '#FFFFFF'}></path>
          </g>
        </g>
      </g>
    </svg>

  );

}

export default DocWord;
