import React from "react";

const LogoDomum = (props) => {
  const { width, height, colorText, colorIcon, textColor = "#6F6F6E", isoTypeColor = "#4EA5DC"} = props;
  return (
    <svg
      width={width ? width : "150px"}
      height={height ? height : "36px"}
      viewBox={"0 0 213 52"}
      className={"c-logo-dark"}
    >

      <path d="M0.601562 0.199951V51.4H10.8016V19.9L17.7016 12.2V51.4H22.7016V19.9L29.6016 12.2V51.4H34.7016V19.9L41.6016 12.2V51.4H51.8016V0.199951H0.601562Z" fill={isoTypeColor} className="c-logo__isotype"/>
      <path d="M73.1016 7.8999H82.1016C84.4016 7.8999 86.4016 8.2999 88.1016 9.1999C89.9016 10.0999 91.2016 11.3999 92.2016 12.9999C93.2016 14.5999 93.7016 16.4999 93.7016 18.5999C93.7016 20.6999 93.2016 22.5999 92.2016 24.1999C91.2016 25.7999 89.9016 27.0999 88.1016 27.9999C86.3016 28.8999 84.3016 29.2999 82.1016 29.2999H73.1016V7.8999ZM81.9016 26.5999C83.6016 26.5999 85.2016 26.2999 86.5016 25.5999C87.8016 24.8999 88.8016 23.9999 89.5016 22.7999C90.2016 21.5999 90.6016 20.1999 90.6016 18.5999C90.6016 16.9999 90.2016 15.5999 89.5016 14.3999C88.8016 13.1999 87.8016 12.1999 86.5016 11.5999C85.2016 10.8999 83.7016 10.5999 81.9016 10.5999H76.1016V26.6999H81.9016V26.5999Z" fill={textColor}/>
      <path d="M105.202 28.0999C103.502 27.1999 102.102 25.8 101.102 24.2C100.102 22.5 99.6016 20.6999 99.6016 18.5999C99.6016 16.4999 100.102 14.7 101.102 13C102.102 11.3 103.502 9.99995 105.202 9.09995C106.902 8.19995 108.902 7.69995 111.002 7.69995C113.102 7.69995 115.102 8.19995 116.802 9.09995C118.502 9.99995 119.902 11.4 120.902 13C121.902 14.7 122.402 16.4999 122.402 18.5999C122.402 20.6999 121.902 22.6 120.902 24.2C119.902 25.9 118.602 27.1999 116.802 28.0999C115.102 28.9999 113.102 29.5 111.002 29.5C108.902 29.6 107.002 29.0999 105.202 28.0999ZM115.302 25.7999C116.602 25.0999 117.502 24.0999 118.302 22.7999C119.002 21.4999 119.402 20.0999 119.402 18.5999C119.402 17.0999 119.002 15.6 118.302 14.4C117.602 13.1 116.602 12.2 115.302 11.4C114.002 10.7 112.602 10.2999 111.102 10.2999C109.602 10.2999 108.102 10.7 106.802 11.4C105.502 12.1 104.502 13.1 103.802 14.4C103.102 15.7 102.702 17.0999 102.702 18.5999C102.702 20.0999 103.102 21.5999 103.802 22.7999C104.502 24.0999 105.502 24.9999 106.802 25.7999C108.102 26.4999 109.502 26.9 111.102 26.9C112.702 26.9 114.102 26.4999 115.302 25.7999Z" fill={textColor}/>
      <path d="M149.999 29.2999V13.6999L142.299 26.6999H140.899L133.099 13.7999V29.2999H130.199V7.8999H132.699L141.599 22.8999L150.399 7.8999H152.999V29.2999H149.999Z" fill={textColor}/>
      <path d="M164.701 27.0999C163.101 25.4999 162.301 23.0999 162.301 20.0999V7.8999H165.401V19.9999C165.401 24.5999 167.401 26.8999 171.401 26.8999C173.401 26.8999 174.901 26.2999 175.901 25.1999C176.901 24.0999 177.501 22.2999 177.501 19.9999V7.8999H180.501V20.0999C180.501 23.1999 179.701 25.4999 178.101 27.1999C176.501 28.7999 174.301 29.5999 171.401 29.5999C168.501 29.5999 166.301 28.6999 164.701 27.0999Z" fill={textColor}/>
      <path d="M209.999 7.8999L201.199 22.8999L192.299 7.8999H189.699V29.2999H192.699V13.7999L200.399 26.6999H201.799L209.599 13.6999V29.2999H212.599L212.499 7.8999H209.999Z" fill={textColor}/>
      <path d="M78.1 46.0999L76.5 43.7999C76.3 43.7999 76.1 43.7999 76 43.7999H74.1V46.0999H73V38.5999H76C77 38.5999 77.7 38.7999 78.3 39.2999C78.9 39.7999 79.1 40.3999 79.1 41.1999C79.1 41.7999 79 42.2999 78.7 42.6999C78.4 43.0999 78 43.3999 77.5 43.5999L79.3 46.0999H78.1ZM77.5 42.4999C77.9 42.1999 78 41.7999 78 41.1999C78 40.6999 77.8 40.1999 77.5 39.8999C77.1 39.5999 76.6 39.4999 75.9 39.4999H74.1V42.8999H75.9C76.6 42.8999 77.1 42.7999 77.5 42.4999Z" fill={textColor}/>
      <path d="M91.7008 45.1999V46.0999H86.3008V38.5999H91.6008V39.4999H87.4008V41.7999H91.1008V42.6999H87.4008V45.1999H91.7008Z" fill={textColor}/>
      <path d="M103.9 44.1999H99.9L99.1 46.0999H98L101.4 38.5999H102.5L105.9 46.0999H104.8L103.9 44.1999ZM103.5 43.3999L101.9 39.6999L100.3 43.3999H103.5Z" fill={textColor}/>
      <path d="M112.199 38.5999H113.199V45.1999H117.299V46.0999H112.199V38.5999Z" fill={textColor}/>
      <path d="M143.801 45.1V46H138.301V38.5H143.701V39.4H139.401V41.7H143.201V42.7H139.401V45.1H143.801Z" fill={textColor}/>
      <path d="M153.098 45.7999C152.598 45.5999 152.198 45.3999 151.898 45.0999L152.298 44.2999C152.598 44.5999 152.998 44.7999 153.398 44.8999C153.798 45.0999 154.298 45.0999 154.798 45.0999C155.398 45.0999 155.898 44.9999 156.198 44.7999C156.498 44.5999 156.698 44.2999 156.698 43.9999C156.698 43.6999 156.598 43.4999 156.398 43.3999C156.198 43.1999 155.998 43.0999 155.798 42.9999C155.598 42.8999 155.198 42.7999 154.798 42.6999C154.198 42.5999 153.798 42.3999 153.398 42.2999C153.098 42.1999 152.798 41.9999 152.498 41.6999C152.298 41.3999 152.098 40.9999 152.098 40.5999C152.098 40.1999 152.198 39.7999 152.398 39.4999C152.598 39.1999 152.898 38.8999 153.398 38.6999C153.798 38.4999 154.398 38.3999 154.998 38.3999C155.398 38.3999 155.898 38.4999 156.298 38.5999C156.698 38.6999 157.098 38.8999 157.398 39.0999L156.998 39.9999C156.698 39.7999 156.298 39.5999 155.998 39.4999C155.598 39.3999 155.298 39.2999 154.898 39.2999C154.298 39.2999 153.798 39.3999 153.498 39.5999C153.198 39.7999 152.998 40.0999 152.998 40.4999C152.998 40.7999 153.098 40.9999 153.298 41.0999C153.498 41.2999 153.698 41.3999 153.898 41.4999C154.198 41.5999 154.498 41.6999 154.898 41.7999C155.498 41.8999 155.898 42.0999 156.298 42.1999C156.598 42.2999 156.898 42.4999 157.198 42.7999C157.398 43.0999 157.598 43.4999 157.598 43.8999C157.598 44.2999 157.498 44.6999 157.298 44.9999C157.098 45.2999 156.798 45.5999 156.298 45.7999C155.898 45.9999 155.298 46.0999 154.698 46.0999C154.098 46.0999 153.598 45.9999 153.098 45.7999Z" fill={textColor}/>
      <path d="M167.799 39.4H165.199V38.5H171.499V39.4H168.899V46H167.799V39.4Z" fill={textColor}/>
      <path d="M184 44.1H180L179.2 46H178L181.4 38.5H182.5L185.9 46H184.8L184 44.1ZM183.6 43.3L182 39.6L180.4 43.3H183.6Z" fill={textColor}/>
      <path d="M195.1 39.4H192.5V38.5H198.7V39.4H196.1V46H195.1V39.4Z" fill={textColor}/>
      <path d="M206.5 38.5V46H212V45.1H207.6V42.7H211.4V41.7H207.6V39.4H211.8V38.5H206.5Z" fill={textColor}/>

    </svg>
  );
};

export default LogoDomum;
