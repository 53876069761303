// Dependencies
import React from "react";
import { connect } from "react-redux";
import {TYPE_BUY, TYPE_DEPOSIT, TYPE_SELL, TYPE_SHELL_SHORT, TYPE_WITHDRAWAL, TYPE_ASSET_CREATED, TYPE_ASSET_DELETED, TYPE_VALUE_PLUS, TYPE_VALUE_MINUS} from '../../constants'

// Actions
import { allAlerts, getAlert} from "../../actions/alerts";
import { createAssetResource, destroyAsset} from "../../actions/balance";

// Components
import {canManage, canRead} from '../../helpers/permits'
import Input from '../../components/input'
import Icon from '../../components/icons'
import { Calendar, formatDate } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import AssetsResourcesModal from "../../screens/balance/details/assetsResourcesModal";
import AlertModal from "../../components/alertModal";
import StackHeader from "../../components/stackHeader";

// Constants
import {internalization} from '../../constants.js'

const moment = require("moment");

var blockScrollCall = false;
let page = 1;
var timer = null;
var notificationsNum = 0

class AlertsIndexScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      alerts: {},
      showModal:false,
      showDeleteModal:false,
      showSuccesModalTitle: "",
      showSuccesModalMessage: "",
      showDeleteModal: false,
      asset: "",
      destoyedSuccess: false,
    };
    this.calendar = null;
  }

  componentDidMount() {
    this._fetch()
  }

  _fetch(){
    this.props.allAlerts(this.props.authentication_token, undefined, (responseJSON) => this.setState({alerts: responseJSON}, () => this._successFetch(responseJSON)), () => alert("KO"))
  }

  _successFetch(responseJSON){
    this._initCalendar()
    this.props.getBreadcrumbs({
      assetUrl: '/alerts',
      url: '/alerts',
      assetTitle: responseJSON.title
    });
    this.setState({      
      showModal:false,
      showDeleteModal: false,
      showSuccesModal:false,
      destoyedSuccess: false
    })
  }

  _initCalendar(button){
    var that = this;
    var calendarEl = document.getElementById('calendar');
    let languageUserAgent = (/^es/).test(this.props.user.language) ? 'es' : 'en';
    let buttonText = {
          today:    'today',
          month:    'month',
          week:     'week',
          day:      'day',
          list:     'list'
        }
    if(languageUserAgent === "es"){
      buttonText = {
          today:    'hoy',
          month:    'mes',
          week:     'semana',
          day:      'día',
          list:     'lista'
        }
    }
    this.calendar = new Calendar(calendarEl, {
      locale: languageUserAgent,
      plugins: [ dayGridPlugin ],
      events: Object.values(this.state.alerts.alertByDay).flat(),
      eventClick: function(info) {


        that._showAlert(info.event.extendedProps.id_alert)
        // change the border color just for fun
        // info.el.style.borderColor = '#005774';
      },
      viewSkeletonRender: function (info) {
        // let date = this.getDate().getFullYear() + "/" + this.getDate().getMonth() + "/" + this.getDate().getDay()
        
        // that.props.allAlerts(that.props.authentication_token, date, (responseJSON) => that.setState({alerts: responseJSON}, () => that._successFetch(responseJSON)), () => alert("KO"))
      },
      buttonText:buttonText,
      eventColor: "#408197"
    });

    this.calendar.render();
    var that = this;
    document.getElementsByClassName("fc-prev-button")[0].addEventListener('click', function() {
      that._loadNewDates(that.calendar.getDate())

    });
    document.getElementsByClassName("fc-next-button")[0].addEventListener('click', function() {
      // let date = that.calendar.getDate().getFullYear() + "/" + (that.calendar.getDate().getMonth() + 1) + "/" + that.calendar.getDate().getDay()
      that._loadNewDates(that.calendar.getDate())
      // that.props.allAlerts(that.props.authentication_token, date, (responseJSON) => that.setState({alerts: responseJSON}, () => that._successFetch(responseJSON)), () => alert("KO"))
    });  
  }

  _loadNewDates(date){
    this.props.allAlerts(this.props.authentication_token, moment(date).format("YYYY-MM-DD"), (responseJSON) => this.setState({alerts: responseJSON}, () => this._setNewEvents(responseJSON)), () => alert("KO"))
  }

  _setNewEvents(responseJSON){
    var that = this;
    
    this.calendar.getEventSources()[0].remove()
    this.calendar.addEventSource(Object.values(responseJSON.alertByDay).flat())
    

    this.calendar.render();
    document.getElementsByClassName("fc-prev-button")[0].addEventListener('click', function() {
      that._loadNewDates(that.calendar.getDate())

    });
    document.getElementsByClassName("fc-next-button")[0].addEventListener('click', function() {
      // let date = that.calendar.getDate().getFullYear() + "/" + (that.calendar.getDate().getMonth() + 1) + "/" + that.calendar.getDate().getDay()
      that._loadNewDates(that.calendar.getDate())
      // that.props.allAlerts(that.props.authentication_token, date, (responseJSON) => that.setState({alerts: responseJSON}, () => that._successFetch(responseJSON)), () => alert("KO"))
    });
    document.getElementsByClassName("fc-today-button")[0].addEventListener('click', function() {
      // let date = that.calendar.getDate().getFullYear() + "/" + (that.calendar.getDate().getMonth() + 1) + "/" + that.calendar.getDate().getDay()
      that.props.allAlerts(that.props.authentication_token, undefined, (responseJSON) => that.setState({alerts: responseJSON}, () => that._setNewEvents(responseJSON)), () => alert("KO"))
      // that.props.allAlerts(that.props.authentication_token, date, (responseJSON) => that.setState({alerts: responseJSON}, () => that._successFetch(responseJSON)), () => alert("KO"))
    });    
    

  }

  _showAlert(id) {
    this.props.getAlert( this.props.authentication_token, id, (responseJSON) => this._successAlert(responseJSON), () => alert("error"))
  }


  _destroyAssets(modalData){
    this.setState({
      showModal: false, showSuccesModal: true,
      showSuccesModalTitle: this.props.internalization.alerts.success,
      showSuccesModalMessage: this.props.internalization.alerts.delete_success
    });
  }

  _successAlert(responseJSON){
   
    this.setState({
      showModal: true,
      modalData: {
        id: responseJSON.bc_show.identifier.id,
        form: responseJSON.bc_show.form.edit_alert.inputs,
        can_manage: responseJSON.bc_show.can_manage,        
        resource_key: responseJSON.bc_show.form.edit_alert.resource_key,
        url: /alerts/+responseJSON.bc_show.identifier.id,
        title: responseJSON.bc_show.title,
        method: "PUT",
        code: "edit_alert",
        resources: responseJSON.bc_show.resource_related
      }
    });    

  }  

  _uploadResource(data){
    this.props.createAssetResource(
        this.props.authentication_token,
        () => this._successCreateAssetResource(this.state.modalData.method === "PUT"),
        () => this._errorCreateAssetResource(),
        this.state.modalData.url,
        this.state.modalData.method,
        data
      );    
  }
  _successCreateAssetResource(){
    this.setState({
      showModal: false, showSuccesModal: true,
      showSuccesModalTitle: this.props.internalization.alerts.success,
      showSuccesModalMessage: this.props.internalization.alerts.edit_success
    })
    window.location.reload()
  }
  _errorCreateAssetResource(){

  }

  _goToResource(asset) {
    this.setState({
      showNavigateModal: true,
      asset: asset
    });
 
  }

  _navigateToAsset(state){
    let asset = state.asset
    if(asset.url.indexOf('ownership_structure') !== -1){
      window.location.href = asset.url+"/"+asset.resourceable_id
    }else {
      this.props.history.push("balance-detail?url="+asset.url+"&id="+asset.resourceable_id, {
        url: asset.url,
        id: asset.resourceable_id
      });
      setTimeout(function(){
        window.location.reload()
      },200)
    }
    
    this.setState({
      showNavigateModal: false,
      showModal: false
    });
  }


  _destroyAssetAssociated(modalData){
    this.setState({
      showDeleteModal: true,
      modal_title: this.props.internalization.modals[modalData.resource_key].delete.title,
      modal_body: this.props.internalization.modals[modalData.resource_key].delete.body,
      modal_url: modalData.url,
      modal_action: () => this._successDestroyAssetAssociated(modalData)
    });
  }

  _successDestroyAssetAssociated(modalData){
    this.setState({destoyedSuccess: true,
      modal_title: this.props.internalization.modals[modalData.resource_key].deleted.title,
      modal_body: this.props.internalization.modals[modalData.resource_key].deleted.body,
      modal_action: () => {this._afterDelete(this.state.modalData.id)}
    })

  }  

  _removeIterative(id){
    try{
      this.calendar.getEventById(id).remove(); 
      this._removeIterative(id)
    }catch(e){

    }
    
  }

  _afterDelete(id){
    this._removeIterative(id)
    this.setState({destoyedSuccess: false, showModal: false})
  }

  render() {
    var languageUserAgent = (/^es/).test(this.props.user.language) ? 'es' : 'en';
    console.log(this.alerts)

    return (
        <div className="main-wrapper">
          <StackHeader
            title={this.state.alerts.description_text}
            icon={<Icon name="Notifications" iconBig={true}/>}
            help={false}
            helpAction={() => alert("boton de info del stackHeader")}
          />
          <div id="calendar"/>
          <div className="legend">
            <p className="legend-text">{internalization[languageUserAgent].legend}:</p>
          {
            this.state.alerts.legend &&
            this.state.alerts.legend.map((item, index) => {
              return(
                <div className="legend__item" key={"legendCalendar-"+index}>
                  <span className="legend__item-color" style={{backgroundColor: item.color}}></span>
                  <p className="legend__item-text">{item.title}</p>
                </div>
              )
            }) 
          }
          </div>
          
          {this.state.showModal === true && (
            <AssetsResourcesModal
              colorGrey={true}
              data={this.state.modalData}
              errors={this.props.errors}
              closeModal={e => {
                if (
                  e.target.className === "modal-wrapper" ||
                  (e.target.closest(".modal-header") !== null &&
                    e.target.closest(".modal-header").className.includes("modal-header"))
                ) {
                  this.setState({ showModal: false, resources:{}, deletedAssets: [] });
                }
              }}
              resources={this.state.modalData.resources}
              goToResource={asset => this._goToResource(asset)}
              polymorphic={
                this.state.modalData.resource_key === "contact"
                  ? "resourceable_"
                  : "ownerable_"
              }
              uploadResource={data => this._uploadResource(data)}
              resourceToSelectDestroy={true}
              resourceDestroyAction={url => this.setState({showDeleteModal:true, modal_action: () => this._destroyAssets(this.state.modalData)})}
              destroyAssetAssociated= {() => this._destroyAssetAssociated(this.state.modalData)}
              buttons={this.props.internalization.buttons}
              showModalSessionExpire={(time_left, diffMins, checkTime) => this.props.showModalSessionExpire(time_left, diffMins, () => checkTime())}

            />
          )}
          {this.state.showSuccesModal && (
            <AlertModal
              isShowed={this.state.showSuccesModal}
              title={this.state.showSuccesModalTitle}
              msg={this.state.showSuccesModalMessage}
              action={() => {
                this.setState({
                  showSuccesModal: false
                });
                
              }}
              textButton="OK"
            />
          )}
            {this.state.showDeleteModal && (
            <AlertModal
              isShowed={this.state.showDeleteModal}
              title={this.state.modal_title}
              msg={this.state.modal_body}
              action={() => {
                this.props.destroyAsset(
                  this.props.authentication_token,
                  () => this.state.modal_action(),
                  () => alert("error"),
                  this.state.modalData.url,
                  "DELETE"
                );
                this.setState({
                  showDeleteModal: false
                });
              }}
              textButton="Yes"
              textButtonAlertColor={true}
              cancelButton={true}
              cancelTextButton="Cancel"
              cancelButtonAlertColor={false}
              actionCancelButton={() => {
                this.setState({
                  showDeleteModal: false
                });
              }}
            />
          )}  
          {this.state.destoyedSuccess && (
            <AlertModal
              isShowed={this.state.destoyedSuccess}
              title={this.state.modal_title}
              msg={this.state.modal_body}
              action={() => {
                  this.state.modal_action()
              }}
              textButton={this.props.internalization.buttons.ok}
            />
          )}                  
        {this.state.showNavigateModal && (
          <AlertModal
            isShowed={this.state.showNavigateModal}
            title={this.props.internalization.alerts.navigate_to_asset}
            msg={this.props.internalization.alerts.navigate_to+" "+this.state.asset.name}          
            action={() => {
              this._navigateToAsset(this.state)
            }}
            textButton="Yes"
            textButtonAlertColor={false}
            cancelButton={true}
            cancelTextButton="Cancel"
            cancelButtonAlertColor={true}
            actionCancelButton={() => {
              this.setState({
                showNavigateModal: false
              });
            }}
          />
        )}
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    permits: state.session.permits,
    errors: state.session.errors,
    internalization: state.session.internalization,
    user: state.session.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAlert:(authentication_token, id, success, error) => dispatch(getAlert(authentication_token, id, success, error)),    
    allAlerts: (authentication_token, date, success, error) => dispatch(allAlerts(authentication_token,date,  success, error)),
    createAssetResource: (authentication_token, success,error,url,method,attributes) =>dispatch(createAssetResource(authentication_token,success,error,url,method,attributes)),
    destroyAsset: (authentication_token, success, error, url, method) => dispatch(destroyAsset(authentication_token, success, error, url, method)),    
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertsIndexScreen);
