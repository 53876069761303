// Dependencies
import React from "react";

const Art = (props) => {
  return (
    <svg
      width={props.width ? props.width : "51px"}
      height={props.height ? props.height : "43px"}
      viewBox="0 0 51 43"
    >
      
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Art" transform="translate(-6.000000, -10.000000)" stroke={props.color ? props.color : "#80abba"} strokeWidth="2">
                <g id="Group-2" transform="translate(6.000000, 11.381104)">
                    <rect id="Rectangle" x="1" y="4.61889648" width="49" height="36" rx="2"></rect>
                    <rect id="Rectangle" x="6" y="9.61889648" width="39" height="27" rx="2"></rect>
                    <polyline id="Path-23" stroke-linecap="round" stroke-linejoin="round" points="44.909668 26.6478012 29.8272727 16.865983 12.9189453 35.8500977"></polyline>
                    <polyline id="Path-24" stroke-linecap="round" stroke-linejoin="round" points="21.657959 25.3632813 12.9189453 20.4370783 6.4375 26.3580403"></polyline>
                    <path d="M21.640625,4.17016602 C22.0706852,1.39005534 23.3904805,1.37834548e-16 25.600011,0 C27.8095414,0 29.2186287,1.39005534 29.8272727,4.17016602" id="Path-25"></path>
                    <circle id="Oval" cx="20.5" cy="16.1188965" r="3.5"></circle>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default Art;
