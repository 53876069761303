// Dependencies
import React, {useState} from "react";

// Components
import Icon from "../components/icons";
import MediaBlock from "../components/mediaBlock";
import RenderDocIcon from "../helpers/renderDocIcon";

const Accordion = (props) => {

  const open = (e)=> {
    e.preventDefault()
      e.cancelBubble = true
      e.stopPropagation()
    
    let currentTarget = e.currentTarget.parentElement
    if(currentTarget.classList.contains('is-open')){
      currentTarget.classList.remove('is-open')
    }else {
      currentTarget.classList.add('is-open')
    }
  }



  return (
    <div className="accordion">
      {
        props.data &&
        props.data.map((list, index) => {
          return(
            <div key={'accordion'+'_'+index} className={props.data.length === 1 ? `accordion__list is-open` : list.data.length === 1 ? `accordion__list is-open` : `accordion__list`}>
              <div className="accordion__list-title" onClick={(e) => open(e)}>
                <p className="headingH5">{list.title}</p>
                <Icon width={'20px'} height={'20px'} noWrapper={false} className="accordion__list-icon" name="SingleArrow" orientation={"down"}/>
              </div>
              <ul className="accordion__list-content">
                {
                  list.data.map((item, index) => {
                    return(
                      <li key={'accordion-item'+'_'+index}  className="accordion__list-item" onClick={() => props.actionContent(item)}>
                          <MediaBlock
                            fullWidth={true}
                            image={ props.type === "photo" ? <img className="avatar" src={item.urls.medium} alt="avatar" /> : <RenderDocIcon type={item.type} />}
                            title={item.title}
                            description={props.type !== "photo" && item.description}
                            rightText={item.size}
                            documentType={item.type}
                            alignLeft={true}
                          />
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          )
        })
      }
      
    </div>
  );
};

export default Accordion;
