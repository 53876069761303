// Dependencies
import React from "react";
import { connect } from "react-redux";
import { Treant } from "treant-js";

// import Raphael from '../../assets/js/raphael';

//Components
import Loading from "../../components/loading";
import ActionSheet from "../../components/actionSheet";
import FloatButton from "../../components/floatButton";
import Icon from "../../components/icons";
import AlertModal from "../../components/alertModal";
import AssetsResourcesModal from "../../screens/balance/details/assetsResourcesModal";
import NoResources from "../../components/noResources";
import { canManage, canRead } from "../../helpers/permits";
import linkBlock_fake from "../../assets/images/linkBlock_fake.png";
import ContentBlock from "../../components/contentBlock";
import TableDropdownOwnerShip from "../../components/TableDropdownOwnerShip";
import StackHeader from "../../components/stackHeader";

// Actions
import { createAssetResource } from "../../actions/balance";
import {
  getOwnershipIndex,
  saveOwnershipStructure,
  getListElement,
  createWildCardStructure,
  editElement,
  deleteNode,
} from "../../actions/ownership";
import { getLinkForm } from "../../actions/formblock";
import { updateUser } from "../../actions/session";

// Helpers
import ActionSheetOnPressAction from "../../helpers/actionSheetOnPressAction";
import ellipsisText from "../../helpers/ellipsisText";

let ids = [];
class OwnershipIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      options_action_sheet: [],
      showModal: false,
      showSuccesModal: false,
      actions: [],
      form: {},
      show: false,
      title: "",
      modal_title: "",
      modal_body: "",
      palettes: {},
      isNew: false,
      inputs_creation: {},
      diagram: null,
      remove_last_element: false,
      showCorporationInCorporationModal: false
    };
    this.actionSheet = React.createRef();
  }

  _success(responseJson) {
    console.log("responseJson", responseJson);

    this.setState({
      actions: responseJson.actions,
      form: responseJson.form,
      title: responseJson.title,
      loading: false,
      inputs_creation: responseJson.inputs_creation,
      // loading: loading_temp,
    });

    this.props.getBreadcrumbs({
      url: "/ownership-index",
      assetUrl: "/ownership-index",
      assetTitle: responseJson.title,
    });
  }

  _successCreateAssetResource(edit, key, responseJson) {
    console.log("key", key);
    if (
      ["vehicle", "art", "watch", "real_estate", "liability", "other", "bank_account"].includes(key)
    ) {
      key = "asset";
    } else {
      key = "ownership";
    }
    this.setState({
      showSuccesModal: true,
      modal_title: this.props.internalization.modals[key].created.title,
      modal_body: this.props.internalization.modals[key].created.body,
    });
  }

  _errorCreateAssetResource() {
    alert("error");
  }

  _actionSheetPressed(item, state) {
    let response = ActionSheetOnPressAction(item, { form: state.form }, this.props);

    this.actionSheet.current.show();
    if (response.status) {
      console.log("actionsheet", response);
      this.setState({ showModal: true, modalData: response.modalData });
    }
  }

  _getCorporationInCorporationMessage() {
    let languageUserAgent = (/^es/).test(this.props.user.language) ? 'es' : 'en';
    if(languageUserAgent === "es"){
      return "Si está estructura, fidecomiso o corporación, depende de otra ya existente en bt360, por favor navega a la ficha de la estructura de la cual depende y desde ahí crea esta para que estén vinculadas"
    }else{
      return "If this structure, trust or corporation, depends on another already existing in bt360, please navigate to the file of the structure on which it depends and from there create this one so that they are linked"
    }
  }

  _showActionSheetContent(state) {
    this.setState({showCorporationInCorporationModal: true})
    let that = this;
    let optionsActionSheet = [];
    
    state.actions.map((item) => {
      return optionsActionSheet.push({
        type: "icon button",
        code: item.code,
        iconName: item.code.includes("add")
          ? "Close"
          : item.code.includes("edit")
          ? "Pencil"
          : "Garbage",
        message: item.title,
        onClick: () => {
          this._actionSheetPressed(item, state);
        },
      });
    });

    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true,
        },
      ],
    });

    setTimeout(function () {
      that.actionSheet.current.show();
    }, 100);
  }

  _error() {
    alert("error");
  }

  _nodeAdd(item_id) {}

  componentDidMount() {
    this._fetch();
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.is_ownership_linked
    ) {
      this.setState({ isNew: true });
    }
    this.props.setSpecialParent([]);
  }
  _fetch(refresh_diagram = false) {
    this.props.getOwnershipIndex(
      this.props.authentication_token,
      (responseJSON) => {
        this._success(responseJSON);
      },
      () => this._error()
    );
  }

  _uploadResource(data) {
    this.setState({ showModal: false, isNew: true });
    this.props.createAssetResource(
      this.props.authentication_token,
      (responseJson) =>
        this._successCreateAssetResource(
          this.state.modalData.method === "PUT",
          this.state.modalData.resource_key,
          responseJson
        ),
      () => this._errorCreateAssetResource(),
      this.state.modalData.url,
      this.state.modalData.method,
      data
    );
  }

  render() {
    console.log(this.props.ownership_index)
    return (
      <React.Fragment>
        {this.state.loading && (
          <Loading text={this.props.internalization.loading} show={this.state.loading} />
        )}
        {!this.state.loading &&
          this.props.ownership_index &&
          Object.keys(this.props.ownership_index).length > 0 && (
            <div className="main-wrapper">
              <StackHeader
                title={this.state.title}
                icon={<Icon name="OwnershipBig" iconBig={true} />}
                help={false}
                helpAction={() => alert("boton de info del stackHeader")}
              />
              <ContentBlock header={false} maxHeight={false} className="centered-and-full">
                <div className="c-tabs__content is-selected">
                  <div className="c-tabs__content-top">
                    <TableDropdownOwnerShip
                      key={"assetClass"}
                      selectedIndex={-1}
                      dataTable={this.props.ownership_index.table}
                      pieSliceSelected={-1}
                      search={(text) => console.log(text)}
                      history={this.props.history}
                      showUrlIcons={true}
                    />
                  </div>
                </div>
              </ContentBlock>
            </div>
          )}
        {this.state.showModal === true && (
          <AssetsResourcesModal
            language={this.props.user.language}
            colorGrey={true}
            data={this.state.modalData}
            errors={this.props.errors}
            closeModal={(e) => {
              if (
                e.target.className === "modal-wrapper" ||
                (e.target.closest(".modal-header") !== null &&
                  e.target.closest(".modal-header").className.includes("modal-header"))
              ) {
                this.setState({ showModal: false });
              }
            }}
            buttons={this.props.internalization.buttons}
            uploadResource={(data) => this._uploadResource(data)}
            showModalSessionExpire={(time_left, diffMins, checkTime) =>
                this.props.showModalSessionExpire(time_left, diffMins, () =>
                  checkTime()
                )
            }
            getLinkForm={(url, success_fromblock, error_fromblock) =>
                this.props.getLinkForm(
                  url,
                  this.props.authentication_token,
                  (responseJSON) => success_fromblock(responseJSON),
                  () => error_fromblock("error")
                )
            }
          />
        )}
        {this.state.showSuccesModal && (
          <AlertModal
            isShowed={this.state.showSuccesModal}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.setState({
                showSuccesModal: false,
              });
              window.location.reload();
            }}
            textButton={this.props.internalization.buttons.ok}
          />
        )}          
        {this.state.actions !== undefined && this.state.actions.length > 0 && (
          <>
            <ActionSheet
              ref={this.actionSheet}
              render={this.state.options_action_sheet}
            />
            <FloatButton
              children={
                <Icon
                  name="Close"
                  color="white"
                  iconBig={true}
                  className="wrapper-icon-floatButton"
                  close={false}
                />
              }
              action={() => this._showActionSheetContent(this.state)}
            />
          </>
        )}  
        { this.state.showCorporationInCorporationModal && 
          <AlertModal
            isShowed={true}
            title={"ALERT"}
            msg={this._getCorporationInCorporationMessage()}
            action={() => {
              this.setState({
                showCorporationInCorporationModal: false,
              });
            }}
            textButton={"OK"}
          />
        }         
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    ownership_index: state.ownership.ownership_index,
    authentication_token: state.session.authentication_token,
    errors: state.session.errors,
    internalization: state.session.internalization,
    user: state.session.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateUser: (authentication_token, attributes, success, error) =>
      dispatch(updateUser(authentication_token, attributes, success, error)),
    deleteNode: (authentication_token, object, success, error) =>
      dispatch(deleteNode(authentication_token, object, success, error)),
    editElement: (authentication_token, attributes, success, error) =>
      dispatch(editElement(authentication_token, attributes, success, error)),
    createWildCardStructure: (authentication_token, object, success, error) =>
      dispatch(createWildCardStructure(authentication_token, object, success, error)),
    getListElement: (authentication_token, class_name, success, error) =>
      dispatch(getListElement(authentication_token, class_name, success, error)),
    saveOwnershipStructure: (authentication_token, ownership_structure, success, error) =>
      dispatch(saveOwnershipStructure(authentication_token, ownership_structure, success, error)),
    getLinkForm: (url, authentication_token, success, error) =>
      dispatch(getLinkForm(url, authentication_token, success, error)),
    getOwnershipIndex: (authentication_token, success, error) =>
      dispatch(getOwnershipIndex(authentication_token, success, error)),
    createAssetResource: (authentication_token, success, error, url, method, attributes) =>
      dispatch(createAssetResource(authentication_token, success, error, url, method, attributes)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnershipIndex);
