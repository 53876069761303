// Dependencies
import React from 'react';

// Components
import Icon from '../components/icons';


const RenderDocIcon = (props) => {
  switch (props.type) {
    case "jpg":
    case "png":
      return <Icon width={props.sizeBig && "35px"} height={props.sizeBig && "35px"} name="DocImage" />
    case "application/pdf":  
    case "pdf":
      return <Icon width={props.sizeBig && "35px"} height={props.sizeBig && "35px"}  name="DocPdf" />
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":  
    case "xls":
    case "xlsx":
      return <Icon width={props.sizeBig && "35px"} height={props.sizeBig && "35px"}  name="DocExcel" />
    case "pptx":
    case "ppt":
      return <Icon width={props.sizeBig && "35px"} height={props.sizeBig && "35px"}  name="DocPowerPoint" />
    case "application/msword":  
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":  
    case "docx":
    case "doc":
      return <Icon width={props.sizeBig && "35px"} height={props.sizeBig && "35px"}  name="DocWord" />
    case "link":
      return <Icon name="Link"  width="16px" height="16px"/>
    case "alert":
      return <Icon noWrapper={true} name="Notification"  width={props.sizeBig ? '25px' : "16px"} height={props.sizeBig ? '25px' : "16px"}/>
    case "user":
    case "RelatedPermit":
      return <Icon name="User"  width="16px" height="16px"/>      
    default:
      return <Icon width={props.sizeBig && "35px"} height={props.sizeBig && "35px"}  name="ClosedFolder" />
  }
}

export default RenderDocIcon;
