// Dependencies
import React from "react";
import { connect } from "react-redux";
import {internalization} from '../../constants.js'
import 'react-phone-number-input/style.css'
import PhoneInput, {getCountryCallingCode, parsePhoneNumber} from 'react-phone-number-input'

//Components
import Input from "../../components/input";
import Icon from "../../components/icons";
import AlertModal from "../../components/alertModal";
import AlertModalUniken from "../../components/alertModalUniken";

//Actions
import { validateAttribute } from '../../actions/validate_attribute';
import { updateUser, profileAuthorization, checkNotificationStatus } from "../../actions/session";
import { resendSMS } from "../../actions/recovery";

var intervalUnikenNotification = undefined;

class ProfileChangeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showModal: false,
            user_attributes: {},
            step_code: false,
            code: "",
            pin: "",
            questions_selected: [],
            step_answers: {},
            // step_answers: {},
            form_errors: {} ,
            question_1_code: "",
            question_1_answer: "",
            question_2_code: "",
            question_1_answer: "",
            showInput: false,
            keyInput: [],
            checksPasswordItems: [],
            showAlertModal: false,
            showAlertModalTitle: "",
            showAlertModalMessage: "",
            showAlertModalOnPress: "",
            waiting_uniken: false,
          showModalUniken: false,
        };
    }

    componentDidMount() {

        // this.props.getBreadcrumbs({
        //     url:
        //     this.props.history.location.pathname +
        //     this.props.history.location.search,
        //     assetUrl: this.props.history.location.pathname
        // });

    }
    _prepareErrors(responseJSON){
        if(responseJSON.hasOwnProperty("errors") && responseJSON.errors.hasOwnProperty("password")){
            
            this.setState({
                showAlertModal: true,
                showAlertModalTitle: this.props.internalization.alerts.error,
                showAlertModalMessage: this.props.errors.password_repeated,
                showAlertModalOnPress:""
            })
        }
        else if(responseJSON.hasOwnProperty("errors") && responseJSON.errors.hasOwnProperty("email")){
            this.setState({
                showAlertModal: true,
                showAlertModalTitle: this.props.internalization.alerts.error,
                showAlertModalMessage: this.props.errors.email_used,
                showAlertModalOnPress:""
            })
        }
        else{
            this.setState({
                showAlertModal: true,
                showAlertModalTitle: this.props.internalization.alerts.error,
                showAlertModalMessage: "",
                showAlertModalOnPress: ""
            })
        }
    }

    _checkNotificationStatus(uuid, state, key){
        this.props.checkNotificationStatus(uuid, this.props.user.email, false, (responseJSON) => this._checkNotificationStatusSuccess(responseJSON, uuid, state, key), () => this._checkNotificationStatusError())
    }

    _checkNotificationStatusError(){
        alert("Error")
        if(intervalUnikenNotification !== undefined){
          clearInterval(intervalUnikenNotification);  
        }
    }
    _checkNotificationStatusSuccess(data, uuid, attributes, key){
    var languageUserAgent = (/^es/).test(this.props.user.language) ? 'es' : 'en'; 
        var that = this; 
          // STATUS_PENDING = 0
        // STATUS_UPDATED = 1
        // STATUS_EXPIRED = 2 
        console.log("check", data)

        // ACTION_APPROVED = 0
        // ACTION_REJECTED = 1 
        if(data.status !== 0){
          if(intervalUnikenNotification !== undefined){
            clearInterval(intervalUnikenNotification);  
          }
          
          if(data.action === 0){
            clearInterval(intervalUnikenNotification);
            this._updateUserAfterAuthorization(attributes, key)
          }
          else{
            this.setState({
              waiting_uniken: false,
              showModalUniken: true,
              modalInfo: {
                title: internalization[languageUserAgent].alerts.uniken_not_authorized_profile_title,
                message: `<p>${internalization[languageUserAgent].alerts.uniken_not_authorized_profile_subtitle}</p>`,
                // subtitle: `<p>${internalization[languageUserAgent].alerts.uniken_waiting_subtitle_2}</p>`,
                dissapproved: true,
                action: () => window.location.href = "/profile?code=profile"
              }
            })
          }
        }else{
         if(intervalUnikenNotification === undefined){
          intervalUnikenNotification = setInterval(function(){ that._checkNotificationStatus(uuid, attributes, key) }, 5000); 
         }     
          // call again each 1 sec
        }
    }

  _saveAuthorization(attributes, key){
    var languageUserAgent = (/^es/).test(this.props.user.language) ? 'es' : 'en'; 
    this.setState({
      waiting_uniken: true,
      loading: false,
      refreshing: false,
      showModalUniken: true,
      modalInfo: {
        title: internalization[languageUserAgent].alerts.uniken_waiting_title,
        message: `<p>${internalization[languageUserAgent].alerts.uniken_waiting_subtitle}</p>`,
        loading: true
      },        
    });    
    this.props.profileAuthorization(this.props.authentication_token, (uuid) => this._checkNotificationStatus(uuid, attributes, key), () => alert("error"))    
  }
    _updateUserAfterAuthorization(attributes, key){
        this.props.updateUser(this.props.authentication_token, {user: attributes}, () => this._success(key), (responseJSON) => this._prepareErrors(responseJSON))
    }

    _updateUser(attributes,key){
        this._saveAuthorization(attributes, key)
    }    
    _submitCode(state){
        let attributes = {}
        let type = this.props.location.state.type
        if(this._validateCode(state)){
            switch (type) {
                case 'password':{
                    attributes['password'] = state.user_attributes.password
                    attributes['password_confirmation'] = state.user_attributes.password_confirmation
                    break;
                }
                case 'pin':{
                    attributes['pin'] = state.user_attributes.pin
                    break;
                }
                case 'email':{
                    attributes['email'] = state.user_attributes.email
                    break;
                }
                case 'phone_number':{
                    attributes['phone_number'] = state.user_attributes.phone_number
                    break;
                }

                default: {console.log("error")}
            }

            this.props.updateUser(this.props.authentication_token, {user: attributes, code: state.code}, () => this._success(type), (responseJSON) => this._prepareErrors(responseJSON))
        }
    }

    _validateCode(state){
        let type = this.props.location.state.type
        let error_object = {};
        state["code"].length === 0  ? error_object["code_error"] = this.props.errors.blank : (delete error_object["code_error"])
        if(Object.keys(error_object).length === 0){
            this.setState({form_errors: {}})
            return true
        }
        else{
            this.setState({form_errors: error_object})
            return false
        }

    }

    _success(key){
        this.setState({
            showAlertModal: true,
            showAlertModalTitle: this.props.internalization.modals.profile[key].title,
            showAlertModalMessage: this.props.internalization.modals.profile[key].body,
            showAlertModalOnPress: () =>  window.location.href = "/profile?code=profile",
            waiting_uniken: false
        })
        
    }

    _missmatchAlert(key){
        
        this.setState({
            showAlertModal: true,
            showAlertModalTitle: this.props.internalization.modals.profile[key+"_missmatch"].title,
            showAlertModalMessage: this.props.internalization.modals.profile[key+"_missmatch"].body,
            showAlertModalOnPress: ""
        })        
    }    
    _submit(state){
        let type = this.props.location.state.type
       
        switch (type) {
            case 'password':{
                if(this._validateGeneric(state)){
                    this.props.validateAttribute(this.props.authentication_token, 'check', [{name: 'password', value: state.user_attributes.old_password}], (responseJSON) => this._updateUser({password: state.user_attributes.password}, 'password'), () => this._missmatchAlert('password'))
                }
                break;
            }
            case 'pin':{
                if(this._validateGeneric(state)){
                    
                    this.props.validateAttribute(this.props.authentication_token, 'check', [{name: 'pin', value: state.user_attributes.old_pin}], (responseJSON) => this._updateUser({pin: state.user_attributes.pin}, 'pin'), () => this._missmatchAlert('pin'))  
                }
                break;
            }
            case 'email':{
                if(this._validateGeneric(state)){
                    this.props.validateAttribute(this.props.authentication_token, 'check', [{name: 'email', value: state.user_attributes.old_email}], (responseJSON) => this._updateUser({email: state.user_attributes.email}, 'email'), () => this._missmatchAlert('email'))  
                }
                break;
            }
            case 'phone_number':{
                if(this._validateGeneric(state)){
                    this.props.validateAttribute(this.props.authentication_token, 'check', [{name: 'phone_number', value: state.user_attributes.old_phone_number}], (responseJSON) => this._updateUser({phone_number: state.user_attributes.phone_number}, 'phone_number'), () => this._missmatchAlert('phone_number'))  
                }
                break;
            }
            default: {console.log("error")}
        }

    }

    _testRegexp(text){
        let reg = new RegExp(/^(?!.*([A-Za-z0-9])\1{3})(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[@#_*()+{}\/?~:,.-]).{8,70}$/)
        return reg.test(text)
    }

    _validatePassword(state, error_object){
        if(!this._testRegexp(state.user_attributes.password)){
            error_object['password_error'] = this.props.errors.password_requirement
        }
        if(!this._testRegexp(state.user_attributes.old_password)){
            error_object['old_password_error'] = this.props.errors.password_requirement
        }  	
        return error_object
    }

    _validateGeneric(state){
        
        let type = this.props.location.state.type
        let error_object = {};
        !state.user_attributes["old_"+type] || state.user_attributes["old_"+type].length === 0  
            ? error_object["old_"+type+"_error"] = this.props.errors.blank 
            : (delete error_object["old_"+type+"_error"])
        !state.user_attributes[type] || state.user_attributes[type].length === 0 || !state.user_attributes[type+"_confirmation"] || state.user_attributes[type+"_confirmation"].length === 0 || state.user_attributes[type] !== state.user_attributes[type+"_confirmation"] ? error_object[type+'_error'] = this.props.errors.blank+" / "+this.props.errors.no_match  : (delete error_object[type+'_error'])
        if(type === "password"){
            error_object = this._validatePassword(state, error_object)
        }
        if(type === "pin" && Object.keys(error_object).length === 0){
            error_object = this._validatePIN(state, error_object)
            
        }
        if(type === "phone_number" && Object.keys(error_object).length === 0){
            error_object = this._validatePhoneNumber(state, error_object)
        }    
        
        if(Object.keys(error_object).length === 0){
            this.setState({form_errors: {}})
            return true
        }
        else{
            this.setState({form_errors: error_object})
            return false
        }

    }  

    _validatePIN(state, error_object){
        let onlyNumbers = new RegExp(/^[0-9]*$/)
        state.user_attributes.pin.length < 5 ? error_object['pin_error'] = this.props.errors.equal_to.replace('%{count}',6) : (delete error_object['pin_error'])
        state.user_attributes.old_pin.length < 5 ? error_object['old_pin_error'] = this.props.errors.equal_to.replace('%{count}',6) : (delete error_object['old_pin_error'])
         
        if(!onlyNumbers.test(state.user_attributes.pin)){
            error_object['pin_error'] = this.props.errors.only_numbers
        }
        if(!onlyNumbers.test(state.user_attributes.pin_confirmation)){
            error_object['pin_error'] = this.props.errors.only_numbers
        }
  
        return error_object
    }

    _validatePhoneNumber(state, error_object){
        var phoneno =  /^\+?.{8,70}$/;
        if(!phoneno.test(state.user_attributes.old_phone_number)){
            error_object["old_phone_number_error"] = "Not valid phone"
        }
        if(!phoneno.test(state.user_attributes.phone_number)){
            error_object["phone_number_error"] = "Not valid phone"
        }

        return error_object;

    }

    _getInputValues(state, e) {
        let name = this.setTargetName(e.target.name)
        this.setState({
            user_attributes: {
                ...state.user_attributes,
                [name]: e.target.value
            }
        });

    }

    setTargetName(name){
        switch (name) {
            case "first":
                return "old_email"
            case "second":
                return "email"
            case "third":
                return "email_confirmation"
            default:
                return name
        }
    }

    _validateSecurityQuestions(state){
        let error_object = {};

        !state.hasOwnProperty('question_1_code') || state.question_1_code.length === 0  ? error_object['question_1_code_error'] = this.props.errors.blank : (delete error_object['question_1_code_error'])
        !state.hasOwnProperty('question_2_code') || state.question_2_code.length === 0 ? error_object['question_2_code_error'] = this.props.errors.blank : (delete error_object['question_2_code_error'])

        !state.hasOwnProperty('question_1_answer') || state.question_1_answer.length === 0  ? error_object['question_1_answer_error'] = this.props.errors.blank : (delete error_object['question_1_answer_error'])
        !state.hasOwnProperty('question_2_answer') || state.question_2_answer.length === 0  ? error_object['question_2_answer_error'] = this.props.errors.blank   : (delete error_object['question_2_answer_error'])
        !state.hasOwnProperty('pin') || state.pin.length < 5 ? error_object['pin_error'] = this.props.errors.equal_to.replace('%{count}',6) : (delete error_object['pin_error'])

        if(Object.keys(error_object).length === 0){
            this.setState({form_errors: {}})
            return true
        }
        else{
            this.setState({form_errors: error_object})
            return false
        }

    }

    _errorUpdate(responseJSON, state){
        let error_object = {};
        if(responseJSON && responseJSON.hasOwnProperty('errors') && responseJSON.errors){
            if(responseJSON.errors.includes(state.question_1_code)){
                error_object["question_1_answer_error"] = this.props.errors.no_match
            }
            if(responseJSON.errors.includes(state.question_2_code)){
                error_object["question_2_answer_error"] = this.props.errors.no_match
            }   
        }

        if(Object.keys(error_object).length !== 0){
            this.setState({form_errors: error_object})
        } 
        else{
            alert("Error")
        }   


    }
    _submitQuestions(state){
        if(this._validateSecurityQuestions(state)){
            let	prepare_hash = {}
            prepare_hash = [
                {question_code: state.question_1_code, answer: state.question_1_answer},
                {question_code: state.question_2_code, answer: state.question_2_answer}
            ]
            this.props.updateUser(this.props.authentication_token, {security_questions: prepare_hash, pin: state.pin, user: {phone_number: state.user_attributes.phone_number}}, () => this._success('questions'), (responseJSON) => this._errorUpdate(responseJSON, state))
        }

    }

    _reSendCode(state){
        this.props.resendSMS(this.props.authentication_token, {}, () => alert("resent"), () => alert("error"))
    }

    _disableOfuscated(e, key, state){
        let keys = state.keyInput
        if(!keys.includes(key)){
            keys.push(key)
        }else {
            keys = keys.filter(i => i !== key)
        }
        this.setState({
            keyInput: keys,
        })
    }

    _passwords_match() {
        return this.state.user_attributes['password_confirmation'] !== "" ? this.state.user_attributes['password'] === this.state.user_attributes['password_confirmation'] : undefined ;
    }

    _pin_match() {
        return this.state.user_attributes['pin_confirmation'] !== "" ? this.state.user_attributes['pin'] === this.state.user_attributes['pin_confirmation'] : undefined ;
    }

   _checksPassword(text, passwordTexts, type) {
    let checkeds = []

    let regLength = type === 'pin' ? new RegExp(/^.{6}$/) : new RegExp(/[A-Za-z0-9@#_*()+{}\/?~:,.-].{8,70}$/)
    let regUppercase = new RegExp(/^(?=.*?[A-Z])/)
    let regLowercase = new RegExp(/^(?=.*?[a-z])/)
    let regDigit = new RegExp(/^(?=.*?[0-9])/)
    let regSpecial = new RegExp(/[@#_*()+{}\/?~:,.-]/)
    let regRepeated = new RegExp(/^(?!.*([A-Za-z0-9])\1{2})/)

   if(type === 'pin'){
    if(regLength.test(text)) checkeds.push(passwordTexts[0])
   }else {
    if(regLength.test(text)) checkeds.push(passwordTexts[0])
    if(regUppercase.test(text) && regLowercase.test(text) && regDigit.test(text) && regSpecial.test(text)) checkeds.push(passwordTexts[1])
    if(regSpecial.test(text)) checkeds.push(passwordTexts[2])
    if(regRepeated.test(text) && text.length > 0) checkeds.push(passwordTexts[3])   
   }
    
   
    return checkeds;
  }  

  _checkPhoneConfirmation(){
    return this.state.user_attributes.phone_number === this.state.user_attributes.phone_number_confirmation
  }

  _passwordMatchText(type, languageUserAgent){
    if(type === 'pin'){
        return this._pin_match() ? internalization[languageUserAgent].pin_match : internalization[languageUserAgent].pin_not_match
    }else {
        return this._passwords_match() ? internalization[languageUserAgent].password_match : internalization[languageUserAgent].password_not_match
    }
    
  }


    render() {
        var languageUserAgent = (/^es/).test(this.props.user.language) ? 'es' : 'en';

        let type = this.props.location.state.type
        let title = this.props.location.state.title
        let selected_country = ""
        try{
            if(this.state.user_attributes.phone_number.length > 0) {
                selected_country = parsePhoneNumber(this.state.user_attributes.phone_number) && parsePhoneNumber(this.state.user_attributes.phone_number).country
            }
        }
        catch(ex){
            selected_country = ""
        }   

        console.log("title ChangeScreen", title)
        
        return (
            
            <div className="main-wrapper white">
                <div className="wrapper-profile">
                    <div className="container-btn">
                        <button className="btn-back" onClick={e => this.props.history.goBack()}>
                            <Icon
                                name="ChevronArrow"
                                orientation="left"
                                bgColor="transparent"
                            
                            />
                            <span className="title2">{internalization[languageUserAgent].back}</span>
                        </button>
                    </div>
                    <p className="headingH3 margin">{internalization[languageUserAgent].change} {title}</p>
                    {
                        Object.keys(this.state.step_answers).length == 0 && !this.state.step_code &&
                            <>
                            {
                              type === "phone_number" &&
                              <>
                                <div className="wrapper-form-item">
                                  <span className="input-label">{internalization[languageUserAgent].old_o.replace("${element}", title)} </span>
                                  <PhoneInput
                                    key={"old_"+type}
                                    //placeholder="Enter phone number"
                                    value={ this.state.user_attributes.old_phone_number || "" }
                                    className=" wrapper-input grey"
                                    onChange={ value => 
                                      this.setState({
                                            user_attributes: {
                                                ...this.state.user_attributes,
                                                ["old_phone_number"]: value
                                            }
                                        })
                                     } />
                                     {
                                      this.state.form_errors["old_"+type+"_error"] && this.state.form_errors["old_"+type+"_error"].length > 0 &&
                                        <span className="label error">{this.state.form_errors["old_"+type+"_error"]}</span>
                                     }
                                </div>
                              <div className="wrapper-form-item  has-requirementOptions">
                                  <span className="input-label">{internalization[languageUserAgent].new_o.replace("${element}", title)}</span>
                                  <PhoneInput
                                    key={type}
                                    //placeholder="Enter phone number"
                                    className=" wrapper-input grey"
                                    value={ this.state.user_attributes.phone_number || "" }
                                    onChange={ value => 
                                      this.setState({
                                            user_attributes: {
                                                ...this.state.user_attributes,
                                                ["phone_number"]: value
                                            }
                                        })
                                     } />
                                     {
                                      this.state.form_errors[type+"_error"] && this.state.form_errors[type+"_error"].length > 0 &&
                                        <span className="label error">{this.state.form_errors[type+"_error"]}</span>
                                     }
                              </div>

                              <div className="wrapper-form-item  has-requirementOptions">

                                  <span className="input-label">{internalization[languageUserAgent].confirm} {internalization[languageUserAgent].new_o.replace("${element}", title)}</span>
                                  <PhoneInput
                                    key={"new_"+type}
                                    country={selected_country}
                                    //placeholder="Enter phone number"
                                    className=" wrapper-input grey"
                                    value={ this.state.user_attributes.new_phone_number || "" }
                                    onChange={ value => 
                                      this.setState({
                                            user_attributes: {
                                                ...this.state.user_attributes,
                                                ["phone_number_confirmation"]: value
                                            }
                                        })
                                     } />
                                     {
                                      this.state.form_errors[type+"_confirmation_error"] && this.state.form_errors[type+"_confirmation_error"].length > 0 &&
                                        <span className="label error">{this.state.form_errors[type+"_confirmation_error"]}</span>
                                     }
                                     {
                                        this._checkPhoneConfirmation() ? 
                                        <div className="requirements-access">
                                            <Icon name="Check" color="#408197" width="12px" height="12px" className="wrapper-requirements-options__list-icon"/>
                                            <p className="text">{internalization[languageUserAgent].errors.match}</p>
                                            </div>  : 
                                        <div className="requirements-access">
                                            <Icon name="Close" color="#f3b01c" width="12px" height="12px" className="wrapper-requirements-options__list-icon" />
                                            <p className="text">{internalization[languageUserAgent].errors.no_match}</p>
                                        </div>
                                        }
                              </div>   

                              </>
                            }
                            {
                              type !== "phone_number" &&
                              <>
                              <div className="wrapper-form-item">
                                <span className="input-label">{this.props.location.state.type === 'password' ? internalization[languageUserAgent].old_a.replace("${element}", title) : internalization[languageUserAgent].old_o.replace("${element}", title)}</span>
                                  <Input
                                    key={1}
                                    colorGrey={true}
                                    maxLength={type === 'pin' ? 6 : false}
                                    name={type === "email" ? "first" : "old_"+type}
                                    type={type !== 'email' ? this.state.keyInput.includes(1) ? 'text' : 'password' : 'text'}
                                    defaultValue={""}
                                    onChangeInput={e => this._getInputValues(this.state, e)}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    errorLabel={this.state.form_errors["old_"+type+"_error"]}
                                    showErrorLabelNow={this.state.form_errors["old_"+type+"_error"]}
                                    showErrorLabel={Object.keys(this.state.form_errors).includes("old_"+type+"_error")}
                                    withButtonIcon={type !== 'email' ? true : false}
                                    iconName={"Eye"}
                                    colorIcon={'#408197'}
                                    slashed={this.state.keyInput.includes(1) ? true : false}
                                    buttonIconAction={type !== 'email' ? e => this._disableOfuscated(e,1,this.state) : {}}
                                    // onFocusInput={e => _focusedInput(e, element)}
                                    // onBlurInput={e => _bluredInput(e, element)}
                              />
                              </div>
                              <div className="wrapper-form-item  has-requirementOptions">
                                  <span className="input-label">{this.props.location.state.type === 'password' ? internalization[languageUserAgent].new_a.replace("${element}", title) : internalization[languageUserAgent].new_o.replace("${element}", title)}</span>
                                  <Input
                                      key={2}
                                      maxLength={type === 'pin' ? 6 : false}
                                      colorGrey={true}
                                      name={type === "email" ? "second" : type}
                                      type={type !== 'email' ? this.state.keyInput.includes(2)  ? 'text' : 'password' : 'text'}
                                      defaultValue={""}
                                      onChangeInput={e => {this._getInputValues(this.state, e); this.setState({checksPasswordItems: this._checksPassword(e.target.value,type === 'pin' ? internalization[languageUserAgent].pin_requirements_texts : internalization[languageUserAgent].password_requirements_texts, type)}) }}
                                      // onFocusInput={e => _focusedInput(e, element)} 
                                      // onBlurInput={e => _bluredInput(e, element)} 
                                      onFocus={() => {}}
                                      onBlur={() => {}}
                                      errorLabel={this.state.form_errors[type+'_error']}
                                      showErrorLabelNow={this.state.form_errors[type+'_error']}
                                      showErrorLabel={Object.keys(this.state.form_errors).includes(type+'_error')}
                                      withButtonIcon={type !== 'email' ? true : false}
                                      iconName={"Eye"}
                                      colorIcon={'#408197'}
                                      slashed={this.state.keyInput.includes(2) ? true : false}
                                      buttonIconAction={type !== 'email' ? e => this._disableOfuscated(e,2,this.state) : {}}
                                      requirementOptions={type === 'pin' ? internalization[languageUserAgent].pin_requirements_texts : internalization[languageUserAgent].password_requirements_texts}
                                      showRequirementCheck={type === 'email' ? false : true} 
                                      requirementCheck={this.state.checksPasswordItems}

                                  />
                              </div>

                              <div className="wrapper-form-item  has-requirementOptions">

                                  <span className="input-label">{this.props.location.state.type === 'password' ? `${internalization[languageUserAgent].confirm} ${internalization[languageUserAgent].new_a.replace("${element}", title)}` : `${internalization[languageUserAgent].confirm} ${internalization[languageUserAgent].new_o.replace("${element}", title)}`}</span>
                                  <Input
                                      key={3}
                                      maxLength={type === 'pin' ? 6 : false}
                                      colorGrey={true}
                                      name={type === "email" ? "third" : type+"_confirmation"}
                                      type={type !== 'email' ? this.state.keyInput.includes(3)  ? 'text' : 'password' : 'text'}
                                      defaultValue={""}
                                      onChangeInput={e => this._getInputValues(this.state, e)}
                                      //onFocusInput={e => _focusedInput(e, element)}
                                      //onBlurInput={e => _bluredInput(e, element)}
                                      onFocus={() => {}}
                                      onBlur={() => {}}
                                      errorLabel={this.state.form_errors[type+'_confirmation_error']}
                                      showErrorLabelNow={this.state.form_errors[type+'_confirmation_error']}
                                      showErrorLabel={Object.keys(this.state.form_errors).includes(type+'_confirmation_error')}
                                      withButtonIcon={type !== 'email' ? true : false}
                                      iconName={"Eye"}
                                      colorIcon={'#408197'}
                                      slashed={this.state.keyInput.includes(3) ? true : false}
                                      buttonIconAction={type !== 'email' ? e => this._disableOfuscated(e,3,this.state) : {}}
                                      password_match_text={this._passwordMatchText(type, languageUserAgent)}
                                      passwords_match={type === 'pin' ? this._pin_match() : this._passwords_match()}
                                      passwords_match_enabled={type === 'password' || type === 'pin'}
                                  />
                              </div>   
                            </>
                            }
                            <button
                                className="btn primary margin-top"
                                disabled={
                                    Object.keys(this.state.user_attributes).length === 0
                                }
                                onClick={() => this._submit(this.state)
                                    // this._sendInvitation(this.state)
                                }
                            >
                                <span className="btn-text">{this.state.waiting_uniken ? "Waiting..." : internalization[languageUserAgent].next}</span>
                            </button>	 
                            </>
                    }
                    {
                        Object.keys(this.state.step_answers).length == 0 && this.state.step_code &&
                            <>	
                            <div className="wrapper-form-item">
                                <span className="input-label">{internalization[languageUserAgent].sms_code}</span>
                                <Input
                                    colorGrey={true}
                                    name={"code"}
                                    type={'text'}
                                    defaultValue={""}
                                    onChangeInput={e => this.setState({code:e.target.value})}
                                    // onFocusInput={e => _focusedInput(e, element)}
                                    // onBlurInput={e => _bluredInput(e, element)}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    errorLabel={this.state.form_errors["code_error"]}
                                    showErrorLabelNow={this.state.form_errors["code_error"]}
                                    showErrorLabel={Object.keys(this.state.form_errors).includes("code_error")}
                                />
                            </div>	 
                            <div style={{ display: 'flex', width: '60%'}}>
                                <button
                                    className="btn primary"
                                    onClick={() => this._reSendCode(this.state)
                                        // this._sendInvitation(this.state)
                                    }
                                >
                                    <span className="btn-text">{internalization[languageUserAgent].resend}</span>
                                </button>
                                <button
                                    className="btn primary"
                                    disabled={
                                        Object.keys(this.state.code).length === 0
                                    }
                                    onClick={() => this._submitCode(this.state)
                                        // this._sendInvitation(this.state)
                                    }
                                >
                                    <span className="btn-text">{internalization[languageUserAgent].next}</span>
                                </button>
                            </div>
                            </>	 
                    }
                    {
                        Object.keys(this.state.step_answers).length > 0 &&
                            <>	
                            <div className="wrapper-form-item">
                                <span className="input-label">{internalization[languageUserAgent].question} 1</span>
                                <Input
                                    name={"question_1_code"}
                                    type={'select'}
                                    defaultValue={this.state.question_1_code}
                                    options={Object.keys(this.state.step_answers["pool_1"]).filter((q) => q != this.state.question_2_code).map((e,index) => ({id: e, value: this.state.step_answers["pool_1"][e] }) )}
                                    onChangeInput={e => this.setState({question_1_code:e.target.value})}
                                    // onFocusInput={e => _focusedInput(e, element)}
                                    // onBlurInput={e => _bluredInput(e, element)}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    errorLabel={this.state.form_errors["question_1_code_error"]}
                                    showErrorLabelNow={this.state.form_errors["question_1_code_error"]}
                                    showErrorLabel={Object.keys(this.state.form_errors).includes("question_1_code_error")}
                                />
                             </div>	
                            <div className="wrapper-form-item">
                                <span className="input-label">{internalization[languageUserAgent].answer}</span>
                                <Input
                                    name={"question_1_answer"}
                                    type={'text'}
                                    defaultValue={""}
                                    onChangeInput={e => this.setState({question_1_answer:e.target.value})}
                                    // onFocusInput={e => _focusedInput(e, element)}
                                    // onBlurInput={e => _bluredInput(e, element)}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    errorLabel={this.state.form_errors["question_1_answer_error"]}
                                    showErrorLabelNow={this.state.form_errors["question_1_answer_error"]}
                                    showErrorLabel={Object.keys(this.state.form_errors).includes("question_1_answer_error")}
                                />
                            </div>
                            <div className="wrapper-form-item">
                                <span className="input-label">{internalization[languageUserAgent].question} 1</span>
                                <Input
                                    name={"question_2_code"}
                                    type={'select'}
                                    defaultValue={this.state.question_2_code}
                                    options={Object.keys(this.state.step_answers["pool_2"]).filter((q) => q != this.state.question_1_code).map((e,index) => ({id: e, value: this.state.step_answers["pool_2"][e]}) )}
                                    onChangeInput={e => this.setState({question_2_code:e.target.value})}
                                    // onFocusInput={e => _focusedInput(e, element)}
                                    // onBlurInput={e => _bluredInput(e, element)}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    errorLabel={this.state.form_errors["question_2_code_error"]}
                                    showErrorLabelNow={this.state.form_errors["question_2_code_error"]}
                                    showErrorLabel={Object.keys(this.state.form_errors).includes("question_2_code_error")}
                                />
                            </div>	
                            <div className="wrapper-form-item">
                                <span className="input-label">{internalization[languageUserAgent].answer}</span>
                                <Input
                                    name={"question_2_answer"}
                                    type={'text'}
                                    defaultValue={""}
                                    onChangeInput={e => this.setState({question_2_answer:e.target.value})}
                                    // onFocusInput={e => _focusedInput(e, element)}
                                    // onBlurInput={e => _bluredInput(e, element)}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    errorLabel={this.state.form_errors["question_2_answer_error"]}
                                    showErrorLabelNow={this.state.form_errors["question_2_answer_error"]}
                                    showErrorLabel={Object.keys(this.state.form_errors).includes("question_2_answer_error")}
                                />	
                            </div>
                            <div className="wrapper-form-item">
                                <span className="input-label">{internalization[languageUserAgent].question}</span>
                                <Input
                                    name={"pin"}
                                    type={'text'}
                                    defaultValue={""}
                                    onChangeInput={e => this.setState({pin:e.target.value})}
                                    // onFocusInput={e => _focusedInput(e, element)}
                                    // onBlurInput={e => _bluredInput(e, element)}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    errorLabel={this.state.form_errors["pin_error"]}
                                    showErrorLabelNow={this.state.form_errors["pin_error"]}
                                    showErrorLabel={Object.keys(this.state.form_errors).includes("pin_error")}
                                />	
                            </div>				        			        
                            <button
                                className="btn primary margin-top"
                                disabled={
                                    false
                                }
                                onClick={() => this._submitQuestions(this.state)
                                    // this._sendInvitation(this.state)
                                }
                            >
                                <span className="btn-text">{internalization[languageUserAgent].next}</span>
                            </button>
                            </>
                    }
                </div>
              {this.state.showModalUniken && (
                <AlertModalUniken
                  isShowed={this.state.showModal}
                  title={this.state.modalInfo.title}
                  msg={this.state.modalInfo.message}
                  subtitle={this.state.modalInfo.subtitle}
                  dissapproved={this.state.modalInfo.dissapproved}
                  loading={this.state.modalInfo.loading}
                  action={() => {
                    this.state.modalInfo.action && this.state.modalInfo.action()
                    this.setState({
                      showModalUniken: false,
                      resetPassword: false
                    });
                  }}
                  textButton="Ok"
                />
              )}          
                {this.state.showAlertModal && (
                <div style={{zIndex: 9999999}}>
                    <AlertModal
                      isShowed={this.state.showAlertModal}
                      title={this.state.showAlertModalTitle}
                      msg={this.state.showAlertModalMessage}
                      action={() => {
                        this.setState({
                          showAlertModal: false,
                        });
                        this.state.showAlertModalOnPress != "" && this.state.showAlertModalOnPress()
                      }}
                      textButton="OK"
                    />
                </div>    
              )}                
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authentication_token: state.session.authentication_token,
        errors: state.session.profile ? state.session.profile.errors : "",
        internalization: state.session.internalization,
        user: state.session.user 
    };
}

function mapDispatchToProps(dispatch) {
    return {
        checkNotificationStatus: (uuid, user_name, remember_me, success, error) =>
          dispatch(checkNotificationStatus(uuid, user_name, remember_me, success, error)),    
        profileAuthorization: (authentication_token, success, error) => dispatch(profileAuthorization(authentication_token, success, error)),
        resendSMS:(authentication_token, attributes, success, error) => dispatch(resendSMS(authentication_token, attributes, success, error)),
        validateAttribute: (authentication_token, type, attributes, success, error) => dispatch(validateAttribute(authentication_token, type, attributes , success, error)),
        updateUser: (authentication_token, attributes, success, error) => dispatch(updateUser(authentication_token, attributes, success, error)),  	
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileChangeScreen);
