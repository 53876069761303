import * as go from "gojs";

// Images
import editButtonImage from "../../assets/images/goJs/edit_button_white.png";
import goButtonImageWhite from "../../assets/images/goJs/go_button_white.png";
import avatar from "../../assets/images/avatars/0.png";
import man from "../../assets/images/avatars/0.png";
import woman from "../../assets/images/avatars/1.png";

const $ = go.GraphObject.make;

const categoryItem = (
  diagram,
  editButtonClick,
  goButtonClick,
  nodeStyle,
  titleStyle,
  textStyle,
  makePort,
  colors,
  isEdit,
  old_avatar,
  avoidNodeOverlap
) => {
  return diagram.nodeTemplateMap.add(
    "Main", // Avoid to default category
    $(
      go.Node,
      "Table",
      nodeStyle(),
      {
        selectionAdornmentTemplate: $(
          go.Adornment,
          "Auto",
          $(
            go.Shape,
            "RoundedRectangle",
            {
              strokeWidth: 0,
              parameter1: 18,
              fill: colors.blueDark,
              opacity: 0.25,
            },
            new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 0 : NaN))
          ),
          $(go.Placeholder)
        ), // end Adornment
      },
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      $(
        go.Shape,
        "RoundedRectangle",
        {
          name: "Default shape",
          fill: colors.blueLight,
          strokeWidth: 0,
          // desiredSize: new go.Size(224, NaN),
        },
        new go.Binding("desiredSize", "isPalette", (isPalette) =>
          isPalette ? new go.Size(222, 40) : new go.Size(224, NaN)
        ),
        new go.Binding("parameter1", "isPalette", (isPalette) => (isPalette ? 4 : 18))
      ),
      $(
        go.Shape,
        "Ellipse",
        {
          name: "Default shape",
          fill: "#fff",
          strokeWidth: 0,
          parameter1: 24,
          alignment: go.Spot.TopLeft,
        },
        new go.Binding("desiredSize", "isPalette", (isPalette) =>
          isPalette ? new go.Size(24, 24) : new go.Size(48, 48)
        ),
        new go.Binding("margin", "isPalette", (isPalette) =>
          isPalette ? new go.Margin(8, 12, 0, 8) : new go.Margin(28, 72, 0, 8)
        )
      ),
      $(
        go.Panel,
        "Horizontal",
        {
          width: 208,
        },
        new go.Binding("margin", "isPalette", (isPalette) =>
          isPalette ? new go.Margin(2, 8, 0, 0) : new go.Margin(4, 8, 0, 8)
        ),
        new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 24 : 72)),
        $(
          go.Picture,
          {
            alignment: go.Spot.TopRight,
            
            // source: prepareAvatar(avatar),
          },
          new go.Binding("source", "avatar", (avatar) =>
            prepareAvatar(avatar)
          ),
          new go.Binding("desiredSize", "isPalette", (isPalette) =>
            isPalette ? new go.Size(18, 18) : new go.Size(32, 32)
          ),
          new go.Binding("margin", "isPalette", (isPalette) =>
            isPalette ? new go.Margin(2, 0, 0, 8) : new go.Margin(18, 18, 0, 8)
          )
        ),
        $(
          go.Panel,
          "Horizontal",
          {
            height: 24,
            margin: new go.Margin(0, 8, 0, 8),
          },
          $(
            go.TextBlock,
            titleStyle(),
            new go.Binding("font", "isPalette", (isPalette) => (isPalette ? "14px Averta-Regular" : "14px Averta-Bold")),
            {
              stroke: "#fff",
              margin: new go.Margin(0, 8, 0, 0),
            },
            new go.Binding("text", "name")
          )
        )
      ),
      $(
        go.Panel,
        "Position",
        {
          margin: new go.Margin(8, 8, 0, 0),
          alignment: go.Spot.TopRight,
        },
        new go.Binding("width", "isPalette", (isPalette) => (isPalette ? 0 : 212)),
        new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 0 : 24)),
        $(
          go.Panel,
          "Button",
          {
            name: "Edit button",
            position: new go.Point(188, 0),
            width: 24,
            height: 24,
            opacity: isEdit ? 0 : 1,
            cursor: "pointer",
            // margin: 0,
            click: editButtonClick,
          },
          $(go.Picture, editButtonImage, {
            width: 24,
            height: 24,
          })
        )
        // $(go.Panel,"Button",
        //   {
        //     name: 'Go button',
        //     position: new go.Point(188, 0),
        //     width: 24,
        //     height: 24,
        //     // margin: 0,
        //     click: goButtonClick,
        //   },
        //   $(
        //     go.Picture,
        //     goButtonImageWhite,
        //     {
        //       width: 24,
        //       height: 24,
        //     },
        //   ),
        // ),
      ),
      { dragComputation: avoidNodeOverlap },
      new go.Binding("isLayoutPositioned", "isPalette", (isPalette) => (isPalette ? true : false)),
      // four named ports, one on each side:
      makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
      makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
      makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
      makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
    )
  );
};

const prepareAvatar = (avatar) => {
  if (avatar.avatar_default_id !== null) {
    return avatar.avatar_default_id === 0 ? man : woman;
  } else {
    console.log(avatar.url);
    return avatar.url;
  }
};
export default categoryItem;
