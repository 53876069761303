// Dependencies
import React from "react";
import { connect } from "react-redux";
import { Treant } from "treant-js";

// import Raphael from '../../assets/js/raphael';

//Components
import Loading from "../../components/loading";
import ActionSheet from "../../components/actionSheet";
import FloatButton from "../../components/floatButton";
import Icon from "../../components/icons";
import AlertModal from "../../components/alertModal";
import AssetsResourcesModal from "../../screens/balance/details/assetsResourcesModal";
import NoResources from "../../components/noResources";
import { canManage, canRead } from "../../helpers/permits";
import linkBlock_fake from "../../assets/images/linkBlock_fake.png";
import StackHeader from "../../components/stackHeader";
import GojsCanvas from "../../components/goJs/gojsCanvas";

// Actions
import { createAssetResource } from "../../actions/balance";
import {
  updateAutomaticLayout,
  getWealthMap,
  saveOwnershipStructure,
  getListElement,
  createWildCardStructure,
  editElement,
  getPalettes,
  deleteNode,
  createNewNode
} from "../../actions/ownership";
import { getLinkForm } from "../../actions/formblock";

// Helpers
import ActionSheetOnPressAction from "../../helpers/actionSheetOnPressAction";
import ellipsisText from "../../helpers/ellipsisText";

let ids = [];
class WealthMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      options_action_sheet: [],
      showModal: false,
      showSuccesModal: false,
      actions: [],
      form: {},
      show: false,
      title: "",
      modal_title: "",
      modal_body: "",
      palettes: {},
      isNew: false,
      inputs_creation: {},
      diagram: null,
      remove_last_element: false,
    };
    this.actionSheet = React.createRef();
  }

  _success(responseJson) {
    console.log("responseJson", responseJson);
    this.props.getPalettes(this.props.authentication_token, (json) =>
      this.setState({ palettes: json, isNew: false })
    );

    this.setState({
      actions: responseJson.actions,
      form: responseJson.form,
      title: "Wealth Map",
      loading: false,
      inputs_creation: responseJson.inputs_creation,
      // loading: loading_temp,
    });

    this.props.getBreadcrumbs({
      url: "/wealth-map",
      assetUrl: "/wealth-map",
      assetTitle: "Wealth Map",
    });
  }

  centerHorizontalScroll(container, elem, target) {
    const chartContainer = document.querySelector("#tree");
    chartContainer.scrollLeft = chartContainer.scrollWidth / 2 - chartContainer.clientWidth / 2;
  }

  _updateAutomaticLayout(value) {
    this.props.updateAutomaticLayout(
      this.props.authentication_token,
      value,
      () => window.location.reload(),
      (responseJSON) => alert("Error")
    );
  }
  _centerOnNode(nodeDiv) {
    try {
      document
        .getElementById("trusts - 22")
        .querySelector(".chevron")
        .dispatchEvent(new Event("click"));
      document
        .getElementById("trusts - 22")
        .querySelector(".chevron")
        .dispatchEvent(new Event("click"));
    } catch (e) {}
    try {
      nodeDiv = document.getElementsByClassName("user")[0];
      const chartContainer = document.querySelector("#tree");

      const boundingRect = nodeDiv.getBoundingClientRect();
      chartContainer.scrollLeft +=
        boundingRect.left + nodeDiv.clientWidth / 2 - chartContainer.clientWidth / 2;
      chartContainer.scrollTop +=
        boundingRect.top + nodeDiv.clientHeight / 2 - chartContainer.clientHeight / 2;
    } catch (e) {}
    this.setState({ loading: false });
    setTimeout(() => {
      document
        .getElementById("trusts - 22")
        .querySelector(".chevron")
        .dispatchEvent(new Event("click"));
      document
        .getElementById("trusts - 22")
        .querySelector(".chevron")
        .dispatchEvent(new Event("click"));
    }, 1500);
  }

  _checkNodeStructure(node) {
    node.children.map((item, index) => {
      if (item.children.length > 0) {
        this._checkNodeStructure(item);
      } else {
        ids.push(item.code);
      }
    });
  }

  _createNodeNewSuccess() {
    this.setState({isNew: true})
    this._fetch();
  }

  _successCreateAssetResource(edit, key, responseJson) {
    console.log("key", key);
    if (
      ["vehicle", "art", "watch", "real_estate", "liability", "other", "bank_account"].includes(key)
    ) {
      key = "asset";
    } else {
      key = "ownership";
    }
    this.props.createNewNode(this.props.authentication_token, () => this._createNodeNewSuccess(), () => console.log("ko"), responseJson)
    this.setState({
      showSuccesModal: true,
      modal_title: this.props.internalization.modals[key].created.title,
      modal_body: this.props.internalization.modals[key].created.body,
    });
  }

  _errorCreateAssetResource() {
    alert("error");
  }

  _actionSheetPressed(item, state) {
    let response = ActionSheetOnPressAction(item, { form: state.form }, this.props);

    this.actionSheet.current.show();
    if (response.status) {
      console.log("actionsheet", response);
      this.setState({ showModal: true, modalData: response.modalData });
    }
  }

  _showActionSheetContent(state) {
    let that = this;
    let optionsActionSheet = [];
    state.actions.map((item) => {
      return optionsActionSheet.push({
        type: "icon button",
        code: item.code,
        iconName: item.code.includes("add")
          ? "Close"
          : item.code.includes("edit")
          ? "Pencil"
          : "Garbage",
        message: item.title,
        onClick: () => {
          this._actionSheetPressed(item, state);
        },
      });
    });

    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true,
        },
      ],
    });

    setTimeout(function () {
      that.actionSheet.current.show();
    }, 100);
  }

  _error() {
    alert("error");
  }

  _nodeAdd(item_id) {}

  componentDidMount() {
    this._fetch();
    console.log("location", this.props.location);
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.is_ownership_linked
    ) {
      this.setState({ isNew: true });
    }
  }
  _fetch(refresh_diagram = false) {
    this.props.getWealthMap(
      this.props.authentication_token,
      (responseJSON) => {
        this._success(responseJSON);
      },
      () => this._error()
    );
  }

  _uploadResource(data) {
    this.setState({ showModal: false, isNew: true });
    this.props.createAssetResource(
      this.props.authentication_token,
      (responseJson) =>
        this._successCreateAssetResource(
          this.state.modalData.method === "PUT",
          this.state.modalData.resource_key,
          responseJson
        ),
      () => this._errorCreateAssetResource(),
      this.state.modalData.url+"?from_wealthmap=true",
      this.state.modalData.method,
      data
    );
  }

  render() {
    console.log("this.state.isNew", this.state.isNew);
    return (
      <React.Fragment>
        {this.state.loading && (
          <Loading text={this.props.internalization.loading} show={this.state.loading} />
        )}
        <div className="main-wrapper ownership">
          <StackHeader
            title={this.state.title}
            icon={<Icon name="Structure" iconBig={true} />}
            help={false}
            helpAction={() => alert("boton de info del stackHeader")}
          />

          {/* <NoResources text="No structure yet. 
You can create a structure in the web" button={false} class="height" /> */}
          {!canRead(this.props.permits, "wealth_map") && (
            <div className="content-fake">
              <div className="content-fake__icon">
                <Icon name="Lock" />
                <p className="headingH4">Not granted</p>
              </div>
            </div>
          )}

          {!this.state.loading && (
            <GojsCanvas
              can_manage={canManage(this.props.permits, "wealth_map")}
              internalization={this.props.internalization}
              palettes={this.state.palettes}
              ownership_structure={this.props.ownership_index.ownership_structure}
              saveOwnershipStructure={(ownership_structure, success) =>
                this.props.saveOwnershipStructure(
                  this.props.authentication_token,
                  ownership_structure,
                  (responseJSON) => {
                    console.log("ok - saved in back");
                    success(responseJSON);
                  },
                  () => console.log("ko")
                )
              }
              getListElement={(url, success_list, error_list) =>
                this.props.getListElement(
                  this.props.authentication_token,
                  url,
                  (responseJSON) => success_list(responseJSON),
                  () => error_list()
                )
              }
              createWildCardStructure={(object, success_create_wild_card, error_list) =>
                this.props.createWildCardStructure(
                  this.props.authentication_token,
                  object,
                  (responseJSON) => success_create_wild_card(responseJSON),
                  () => error_list()
                )
              }
              deleteNode={(object, success_delete_noe, error_list) =>
                this.props.deleteNode(
                  this.props.authentication_token,
                  object,
                  (responseJSON) => success_delete_noe(responseJSON),
                  () => error_list()
                )
              }
              editElement={(attributes, success_edit, error_edit) =>
                this.props.editElement(
                  this.props.authentication_token,
                  attributes,
                  () => success_edit(),
                  () => error_edit()
                )
              }
              getPalettes={(success_palettes) =>
                this.props.getPalettes(this.props.authentication_token, (responseJSON) =>
                  this.setState({ palettes: responseJSON, isNew: false })
                )
              }
              isNew={this.state.isNew}
              location={this.props.location}
              history={this.props.history}
              fetch={() => this._fetch()}
              inputs_creation={this.state.inputs_creation}
              showModalCreation={(data) => this.setState({ showModal: true, modalData: data })}
              setDiagram={(diagram) => this.setState({ diagram: diagram })}
              removeLastElement={this.state.remove_last_element}
              updateAutomaticLayout={(value) => this._updateAutomaticLayout(value)}
              currentAutomaticLayout={this.props.user.ownership_automatic_layout_enabled}
              avatar={this.props.user.avatar_real_user}
            />
          )}
        </div>
        {/*<div className="wrapper-pieGraphic-preLegend">
          {Object.keys(this.props.ownership_index).length > 0 &&
              this.props.ownership_index.legends &&
              this.props.ownership_index.legends.length > 0 &&
              this.props.ownership_index.legends.map((item, index) => {
                return (
                  <div className="alone-item" key={index}>
                    <div
                      className="legend-item-color"
                      style={{ backgroundColor: item.color }}
                    />
                    <span className="$title2">{item.title}</span>
                  </div>
                );
              })}
        </div>*/}
        {this.state.showModal === true && (
          <AssetsResourcesModal
            language={this.props.user.language}
            colorGrey={true}
            data={this.state.modalData}
            errors={this.props.errors}
            closeModal={(e) => {
              if (
                e.target.className === "modal-wrapper" ||
                (e.target.closest(".modal-header") !== null &&
                  e.target.closest(".modal-header").className.includes("modal-header"))
              ) {
                this.setState({ showModal: false, remove_last_element: true }, () => {
                  this.setState({ remove_last_element: false });
                });
              }
            }}
            buttons={this.props.internalization.buttons}
            uploadResource={(data) => this._uploadResource(data)}
            showModalSessionExpire={(time_left, diffMins, checkTime) =>
              this.props.showModalSessionExpire(time_left, diffMins, () => checkTime())
            }
            getLinkForm={(url, success_fromblock, error_fromblock) =>
              this.props.getLinkForm(
                url,
                this.props.authentication_token,
                (responseJSON) => success_fromblock(responseJSON),
                () => error_fromblock("error")
              )
            }
          />
        )}
        {this.state.showSuccesModal && (
          <AlertModal
            isShowed={this.state.showSuccesModal}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.setState({
                showSuccesModal: false,
              });
              this._fetch(true);
              // this.props.getPalettes(this.props.authentication_token, (json) => this.setState({palettes: json}))
              // window.location.reload();
            }}
            textButton={this.props.internalization.buttons.ok}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    permits: state.session.permits,    
    ownership_index: state.ownership.ownership_index,
    authentication_token: state.session.authentication_token,
    errors: state.session.errors,
    internalization: state.session.internalization,
    user: state.session.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateAutomaticLayout: (authentication_token, enabled, success, error) =>
      dispatch(updateAutomaticLayout(authentication_token, enabled, success, error)),
    deleteNode: (authentication_token, object, success, error) =>
      dispatch(deleteNode(authentication_token, object, success, error)),
    getPalettes: (authentication_token, success) =>
      dispatch(getPalettes(authentication_token, success)),
    editElement: (authentication_token, attributes, success, error) =>
      dispatch(editElement(authentication_token, attributes, success, error)),
    createWildCardStructure: (authentication_token, object, success, error) =>
      dispatch(createWildCardStructure(authentication_token, object, success, error)),
    getListElement: (authentication_token, class_name, success, error) =>
      dispatch(getListElement(authentication_token, class_name, success, error)),
    saveOwnershipStructure: (authentication_token, ownership_structure, success, error) =>
      dispatch(saveOwnershipStructure(authentication_token, ownership_structure, success, error)),
    getLinkForm: (url, authentication_token, success, error) =>
      dispatch(getLinkForm(url, authentication_token, success, error)),
    getWealthMap: (authentication_token, success, error) =>
      dispatch(getWealthMap(authentication_token, success, error)),
    createAssetResource: (authentication_token, success, error, url, method, attributes) =>
      dispatch(createAssetResource(authentication_token, success, error, url, method, attributes)),
    createNewNode: (authentication_token, success, error, attributes) =>
      dispatch(createNewNode(authentication_token, success, error, attributes)),      
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WealthMap);
