const initialState = {
  news: {},
  showNews: {},
  indices: {},
  currencies: {},
  article: {},
  text: ""
};

export default function marketReducer(state = initialState, action) {

  switch (action.type) {
    case "NEWS":
      return Object.assign({}, state, {
        news: action.payload
      });
    case "NEW":
      return Object.assign({}, state, {
        showNews: action.payload
      });
    case "INDECES":
        return Object.assign({}, state, {
          indices: action.payload
        });
    case "CURRENCIES":
       return Object.assign({}, state, {
          currencies: action.payload
        });
    case "ALL_MARKET":
       return Object.assign({}, state, {
          currencies: action.payload.market.currencies,
          indices: action.payload.market.indices,
          news: action.payload.market.news,
          article: action.payload.market.article,
          text: action.payload.text,
        });       

    default:
      return state;
  }
}