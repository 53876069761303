import { HOST_URL, URL_VERSION } from '../constants';

import get_header from "./api_header";

export function cleanNotificationsAdeppar() {
  return {
    type: "CLEAR_NOTIFICATIONS_ADDEPAR"
  };
}
export function getFinancialStatements(authentication_token, success, error, params) {
  var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');  
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/balance_principal/financial_statements?'+queryString, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        dispatch(cleanNotificationsAdeppar());
        response.json().then((responseJson) => {
          success(responseJson)
        })
        
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function postCleanNotificationsAdeppar(authentication_token) {

  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/balance_principal/financial_statements/clean_notifications', {
      method: 'POST',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        dispatch(cleanNotificationsAdeppar());
      }
      else if(response.status !== 401){
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

