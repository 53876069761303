// Dependencies
import React from 'react';

const Learning = ({ width, height, color }) => {

  return (

    <svg
      width={width ? width : '26px'}
      height={height ? height : '18px'}
      viewBox="0 0 26 18"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons-/-menu-/-learninG" transform="translate(-5.000000, -9.000000)" fill={color ? color : "#80abba"}>
          <g id="Group" transform="translate(5.300000, 9.000000)">
            <path d="M12.7145055,-1.76747506e-13 C12.3577219,-1.76731387e-13 2.15770782,2.90086437 1.07912506,3.22959972 C0.553703915,3.38047863 -2.13162821e-14,3.95101929 -2.13162821e-14,4.63397217 C-2.12059949e-14,5.31692505 0.39831543,5.86706543 1.07912506,6.0791626 C2.08364531,6.3921075 12.1531036,9.40017763 12.7145055,9.40254211 C13.2759073,9.4049066 24.2819214,6.1955719 24.5854797,6.0791626 C24.7642902,6.01059191 25.4213104,5.63022585 25.4213104,4.7328238 C25.4213104,3.83542175 24.6931,3.46258545 24.4885712,3.37207031 C24.2840424,3.28155518 13.0712891,-1.76747506e-13 12.7145055,-1.76747506e-13 Z" id="Path-2"></path>
            <path d="M20.548345,8.74429321 L20.548345,17.1503197 C18.1702257,16.1554329 15.5576625,15.6579895 12.7106552,15.6579895 C9.86364797,15.6579895 7.00319786,16.1554329 4.12930487,17.1503197 L4.12930487,8.58529663 C9.52356692,10.158905 12.3853005,10.9457092 12.7145055,10.9457092 C13.0437105,10.9457092 15.6549903,10.2119039 20.548345,8.74429321 Z" id="Path-3"></path>
          </g>
        </g>
      </g>
    </svg>

  );

}

export default Learning;
