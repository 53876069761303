import * as go from "gojs";

// Images
import editButtonImage from "../../assets/images/goJs/edit_button.png";
import goButtonImage from "../../assets/images/goJs/go_button.png";

import account from "../../assets/images/goJs/icon_investment_account.png";

import alternatives from "../../assets/images/goJs/icon_alternatives.png";
import artCollection from "../../assets/images/goJs/icon_art.png";
import bankAccount from "../../assets/images/goJs/icon_bank_account.png";
import beneficiaryAccount from "../../assets/images/goJs/icon_beneficiary.png";
import investmentAccount from "../../assets/images/goJs/icon_investment.png";
import privateEquity from "../../assets/images/goJs/icon_investment.png";
import vehicleCollections from "../../assets/images/goJs/icon_vehicle.png";
import realEstate from "../../assets/images/goJs/icon_real_estate.png";
import accountReceivable from "../../assets/images/goJs/icon_account_receivable.png";
import directInvestment from "../../assets/images/goJs/icon_direct_investment.png";
import lifeInsurance from "../../assets/images/goJs/icon_life_insurance.png";
import stakeHolder from "../../assets/images/goJs/icon_stake_holder.png";
import watchCollection from "../../assets/images/goJs/icon_watch.png";
import otherAsset from "../../assets/images/goJs/icon_asset_other.png";

const icons = {
  Account: account,
  InvestmentAccount: investmentAccount,
  PrivateEquity: privateEquity,
  Alternatives: alternatives,
  BankAccount: bankAccount,
  ReceivableAccount: accountReceivable,
  DirectInvestment: directInvestment,
  Vehicle: vehicleCollections,
  Art: artCollection,
  LifeInsurance: lifeInsurance,
  StakeHolder: stakeHolder,
  BeneficiaryAccount: beneficiaryAccount,
  RealEstate: realEstate,
  Watch: watchCollection,
  Other: otherAsset,
};

const $ = go.GraphObject.make;

const categoryItem = (
  diagram,
  collapseButtonClick,
  editButtonClick,
  goButtonClick,
  nodeStyle,
  badgeStyle,
  titleStyle,
  textStyle,
  makePort,
  colors,
  avoidNodeOverlap,
  showAmount,
  canReadElement
) => {
  return diagram.nodeTemplateMap.add(
    "Investment", // Avoid to default category
    $(
      go.Node,
      "Table",
      nodeStyle(),
      {
        selectionAdornmentTemplate: $(
          go.Adornment,
          "Auto",
          $(
            go.Shape,
            "RoundedRectangle",
            {
              // fill: null,
              // stroke: colors.blueLight,
              // strokeWidth: 8,
              strokeWidth: 0,
              parameter1: 18,
              fill: colors.blueLight,
              opacity: 0.25,
            },
            new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 0 : 86))
          ),
          $(go.Placeholder)
        ), // end Adornment
      },
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      $(
        go.Shape,
        "RoundedRectangle",
        {
          name: "Default shape",
          fill: colors.greyDarkMedium,
          strokeWidth: 0,
          alignment: go.Spot.TopRight,
        },
        new go.Binding("desiredSize", "isPalette", (isPalette) =>
          isPalette ? new go.Size(222, 40) : new go.Size(224, 86)
        ),
        new go.Binding("parameter1", "isPalette", (isPalette) => (isPalette ? 4 : 18))
      ),
      $(
        go.Shape,
        "Rectangle",
        {
          name: "Default shape",
          fill: colors.greyDarkMedium,
          strokeWidth: 0,
          alignment: go.Spot.TopLeft,
        },
        new go.Binding("desiredSize", "isPalette", (isPalette) =>
          isPalette ? new go.Size(32, 40) : new go.Size(32, 86)
        )
      ),
      $(
        go.Shape,
        "Rectangle",
        {
          name: "Default shape",
          strokeWidth: 0,
          alignment: go.Spot.TopLeft,
          fill: colors.blueLight,
        },
        new go.Binding("desiredSize", "isPalette", (isPalette) =>
          isPalette ? new go.Size(4, 40) : new go.Size(4, 86)
        )
      ),
      $(
        go.Panel,
        "Horizontal",
        {
          width: 208,
        },

        new go.Binding("margin", "isPalette", (isPalette) =>
          isPalette ? new go.Margin(2, 8, 0, 8) : new go.Margin(20, 8, 0, 8)
        ),
        new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 24 : 48)),
        $(
          go.Panel,
          "Vertical",
          new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 24 : 48)),
          $(
            go.Picture,
            // To insert source attribute dynamically
            new go.Binding("source", "", function (data) {
              if(canReadElement(data.class_name) || data.isPalette){
                return icons[data.investmentType];
              }else{
                return ""
              }
              
            }),
            new go.Binding("desiredSize", "isPalette", (isPalette) =>
              isPalette ? new go.Size(24, 24) : new go.Size(48, 48)
            )
          )
        ),
        $(
          go.Panel,
          "Vertical",
          {
            margin: new go.Margin(10, 8, 0, 8),
          },
          new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 24 : 48)),
          $(
            go.TextBlock,
            titleStyle(),
            {
              wrap: go.TextBlock.None,
              width: 148,
              isMultiline: false,
              overflow: go.TextBlock.OverflowEllipsis,
            },
             new go.Binding("text", "", function(data) {
              // Access the desired property from the node data
              if(canReadElement(data.class_name) || data.isPalette){
                return data.title
              }else{
                return ""
              }
            })
          ),
          $(go.TextBlock, textStyle(), new go.Binding("text", "", function(data) {
            if(canReadElement(data.class_name) || data.isPalette){
              return showAmount() ? data.amount : ""
            }else{
              return ""
            }
          }))
        )
      ),
      $(
        go.Panel,
        "Position",
        {
          // width: 212,
          // height: 24,
          margin: new go.Margin(8, 8, 0, 0),
          alignment: go.Spot.TopRight,
        },
        new go.Binding("width", "isPalette", (isPalette) => (isPalette ? 0 : 212)),
        new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 0 : 24)),
        $(
          go.Panel,
          "Button",
          {
            name: "Edit button",
            position: new go.Point(156, 0),
            cursor: "pointer",
            click: editButtonClick,
          },
          new go.Binding("width", "", function(data) {
              if(canReadElement(data.class_name)){
                return 24
              }else{
                return 0
              }
            }),
          new go.Binding("height", "", function(data) {
              if(canReadElement(data.class_name)){
                return 24
              }else{
                return 0
              }
            }),
          $(go.Picture, editButtonImage, {
            width: 24,
            height: 24,
          })
        ),
        $(
          go.Panel,
          "Button",
          {
            name: "Go button",
            position: new go.Point(188, 0),
            cursor: "pointer",
            click: goButtonClick,
          },
          new go.Binding("width", "", function(data) {
              if(canReadElement(data.class_name)){
                return 24
              }else{
                return 0
              }
            }),
          new go.Binding("height", "", function(data) {
              if(canReadElement(data.class_name)){
                return 24
              }else{
                return 0
              }
            }),
          $(go.Picture, goButtonImage, {
            width: 24,
            height: 24,
          })
        )
      ),
      { dragComputation: avoidNodeOverlap },
      // four named ports, one on each side:
      makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
      makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
      makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
      makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
    )
  );
};

export default categoryItem;
