// Dependencies
import React from "react";
import { connect } from "react-redux";
import { Treant } from "treant-js";

// import Raphael from '../../assets/js/raphael';
// Actions
import { getOwnershipIndex } from "../../actions/ownership";

// Components
import Loading from "../../components/loading";
import FormBlock from "../../components/formBlock";
import FloatButton from "../../components/floatButton";
import Icon from "../../components/icons";

let data = {
  code: "Modify data",
  resource_key: "Modify",
  url: "",
  method: "PUT",
  form: [
    {
      id: "name",
      value: "Casa Huertas",
      type: "text",
      label: "Name",
      hidden: false,
      style: "text",
      required: true,
      parent: "",
      disabled: false,
    },
    {
      id: "Type stake holder",
      type: "select",
      style: "select",
      label: "Type stake holder",
      hidden: false,
      disabled: false,
      required: true,
      parent: "",
      options: [
        { id: 0, value: "Settlor" },
        { id: 1, value: "Protector" },
        { id: 2, value: "Trustee" },
        { id: 3, value: "Beneficiaries" },
        { id: 4, value: "Investmente Advisor" },
      ],
    },
    {
      id: "Company",
      value: "Company",
      type: "text",
      label: "Company",
      hidden: false,
      style: "text",
      required: true,
      parent: "",
      disabled: false,
    },
    {
      id: "Phone",
      value: "Phone",
      type: "text",
      label: "Phone",
      hidden: false,
      style: "text",
      required: true,
      parent: "",
      disabled: false,
    },
    {
      id: "Email",
      value: "Email",
      type: "text",
      label: "Email",
      hidden: false,
      style: "text",
      required: true,
      parent: "",
      disabled: false,
    },
    {
      id: "Address",
      value: "Address",
      type: "text",
      label: "Address",
      hidden: false,
      style: "text",
      required: true,
      parent: "",
      disabled: false,
    },
    {
      id: "Notes",
      value: "Notes",
      type: "text",
      label: "Notes",
      hidden: false,
      style: "text",
      required: true,
      parent: "",
      disabled: false,
    },
  ],
};

class OwnershipEdit extends React.Component {
  constructor(props) {
    super(props);
  }

  _updateEditedAsset() {
    alert("beeep");
  }

  _floatButtonAction() {
    alert("added FormBlock!");
  }

  render() {
    return (
      <div>
        {data !== undefined && (
          <FormBlock
            data={data}
            errors={this.props.errors}
            actionSubmitButton={(formData) => this._updateEditedAsset(formData)}
            formType="edit"
          />
        )}
        {data !== undefined && (
          <FormBlock
            data={data}
            errors={this.props.errors}
            actionSubmitButton={(formData) => this._updateEditedAsset(formData)}
            formType="edit"
          />
        )}
        <FloatButton
          children={
            <Icon
              name="Close"
              color="white"
              iconBig={true}
              className="wrapper-icon-floatButton"
              close={false}
            />
          }
          action={() => this._floatButtonAction(this.state)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ownership_index: state.ownership.ownership_index,
    authentication_token: state.session.authentication_token,
    errors: state.session.errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOwnershipIndex: (authentication_token, success, error) =>
      dispatch(getOwnershipIndex(authentication_token, success, error)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnershipEdit);
