// Dependencies
import React from 'react';

const DocNew = ({ width, height, color, bgColor }) => {

  return (

      <svg
          width={width ? width : '23px'}
          height={height ? height : '24px'}
          viewBox="0 0 23 24"
      >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="add-new-file" fill={color ? color : "#80abba"} fillRule="nonzero">
              <path d="M2.1392316,21.8342262 L11.6323734,21.8342262 L11.6323734,23.9966391 L0.662520646,23.9966391 C0.469141831,23.9966391 0.310922801,23.9306715 0.187863555,23.7988654 C0.0648043088,23.66693 0.00327468582,23.5044022 0.00327468582,23.3110233 L0.00327468582,6.46061041 L0.00327468582,5.16848833 L0.00327468582,5.08937882 L0.0296445242,5.08937882 C0.0296445242,5.05421903 0.0472244165,5.01905925 0.0823842011,4.98389946 L5.03991382,0.105479354 L5.03991382,0.0263698384 L5.19813285,0.0263698384 C5.21571275,0.00878994614 5.23329264,0 5.25087253,0 L6.51662478,0 L6.56936445,0.0263698384 L16.273465,0.0263698384 C16.4667145,0.0263698384 16.6250628,0.0878994614 16.7481221,0.210958707 C16.8711813,0.334017953 16.932711,0.492236984 16.932711,0.685615799 L16.932711,8.01643088 L14.796754,8.01643088 L14.796754,2.16232675 L6.75395332,2.16232675 L6.75395332,6.46061041 C6.75395332,6.63640934 6.67484381,6.7243088 6.51662478,6.7243088 L2.1392316,6.7243088 L2.1392316,21.8342262 Z M22.4440503,15.2154399 L17.908395,15.2154399 L17.908395,10.6534147 C17.908395,10.4248761 17.7942549,10.3106068 17.5655871,10.3106068 L14.3748366,10.3106068 C14.146298,10.3106068 14.0320287,10.4248761 14.0320287,10.6534147 L14.0320287,15.2154399 L9.47004668,15.2154399 C9.24150808,15.2154399 9.12723878,15.3295368 9.12723878,15.5582047 L9.12723878,18.7489982 C9.12723878,18.9774937 9.24150808,19.091763 9.47004668,19.091763 L14.0320287,19.091763 L14.0320287,23.6537882 C14.0320287,23.8822837 14.146298,23.996553 14.3748366,23.996553 L17.5655871,23.996553 C17.7942549,23.996553 17.908395,23.8822837 17.908395,23.6537882 L17.908395,19.091763 L22.4440934,19.091763 C22.672632,19.091763 22.7869013,18.9774937 22.7869013,18.7489982 L22.7869013,15.5582047 C22.7869013,15.329623 22.6725458,15.2154399 22.4440503,15.2154399 Z" id="Shape"></path>
          </g>
      </g>
  </svg>

  );
}

export default DocNew;
