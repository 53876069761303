// Dependencies
import React from 'react';

const Info = ({ width, height, color }) => {

  return (

    <svg
      width={width ? width : '28px'}
      height={height ? height : '28px'}
      viewBox="0 0 28 19"
    >
      <g transform="translate(-4.000000, -9.000000)" stroke="none" strokeWidth="1" fillRule="evenodd" fill={ color ? color : '#80abba' } >
        <path
          d="M22.472 27.947h-6.37v-.894c.175-.016.346-.036.514-.06.168-.025.313-.066.434-.123a.882.882 0 0 0 .453-.422c.088-.184.131-.427.131-.729v-7.123a1.67 1.67 0 0 0-.16-.753 1.536 1.536 0 0 0-.404-.52 1.964 1.964 0 0 0-.55-.282 3.04 3.04 0 0 0-.67-.17v-.894l4.939-.319.151.184v9.718c0 .286.05.529.151.728.101.2.246.35.434.447.134.074.282.139.443.196.161.057.33.098.504.122v.894zm-1.104-16.695c0 .62-.239 1.149-.716 1.585a2.428 2.428 0 0 1-1.695.655c-.661 0-1.23-.218-1.708-.655-.477-.436-.716-.965-.716-1.585s.24-1.15.716-1.591A2.431 2.431 0 0 1 18.957 9c.66 0 1.228.22 1.701.66.473.442.71.972.71 1.592zM6.068 21.83a1.253 1.253 0 0 1-1.63-1.905l4.924-4.21a1.253 1.253 0 0 1 1.818.201l4.462 5.961a1.253 1.253 0 1 1-2.007 1.502l-3.66-4.89-3.907 3.34zm23.621-10.223a1.253 1.253 0 1 1 2.115 1.346l-4.387 6.893a1.253 1.253 0 0 1-1.876.277l-2.306-1.99a1.253 1.253 0 0 1 1.637-1.898l1.209 1.043 3.608-5.67z"
          id="a"
        />
      </g>
    </svg>


  );

}

export default Info;
