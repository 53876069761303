// Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Components
import ContentBlock from "../../components/contentBlock";
import AssetsResourcesModal from "../balance/details/assetsResourcesModal";
import Search from "../../components/search";
import SectionList from "../../components/sectionList";
import { searchAll, getSearch } from "../../actions/search";
import AlertModal from "../../components/alertModal";
import EmptySearch from "../../components/emptySearch"
import StackHeader from "../../components/stackHeader";
import Icon from '../../components/icons'

//Actions
import { getContact, destroyAssignAssetByResourceable } from "../../actions/contact";
import { createAssetResource } from "../../actions/balance";
import { getDocDetail } from "../../actions/documents";
import { getAlert} from "../../actions/alerts";


function SearchIndexScreen(props) {

  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [updatedSuccess, setUpdatedSuccess] = useState(false)
  const [modalData, setModalData] = useState({})
  const [resources, setResources] = useState([])
  const [urlContact, setUrlContact] = useState("")
  const [deletedAssets, setDeletedAssets] = useState([])
  const [asset, setAssets] = useState({})
  const [showNavigateModal, setShowNavigateModal] = useState(false)
  const [value, setValue] = useState("")
  const [noResults, setNoResults] = useState(false)
  const [textSubtitle, setTextSubtitle] = useState("")

  useEffect(() => {
    props.getSearch(props.authentication_token, (responseJSON) => setTextSubtitle(responseJSON.text), () => console.log("error getsearch"))
    props.getBreadcrumbs({
          assetUrl: '/search',
          url: '/search',
          assetTitle: props.internalization.buttons.search
        });
  }, []);
      
  const  _search = (text) =>{

    if(text !== ""){
      props.searchAll(props.authentication_token, text, (responseJSON) => success(responseJSON, text), () => error())
    }
    else{
      setNoResults(false)
      setData([])
    }

    // setValue(text)
  } 


  const success = (responseJSON, text) => {
      setData(responseJSON)  
      if(responseJSON.length === 0 || text.length === 0){
        setNoResults(true)
      }else {
        setNoResults(false)
      }
  }

  const error = (responseJSON) => {
   console.log("error") 
   if(data.length === 0){
    setNoResults(true)
   }
          
  }  

  const _destroyAssets = (url) => {

    let allAssets = resources
    let deletedAsset = deletedAssets

    allAssets = allAssets.filter(deleted => deleted.resourceable_id !== url.resourceable_id)

    deletedAsset.push(url)

    setResources(allAssets)
    setDeletedAssets(deletedAsset)

  }

  const _goToResource = (asset) => {
    setAssets(asset)
    setShowNavigateModal(true)
   // props.history.push("balance-detail", {url: asset.url});
  }

  const _uploadResource = (data) => {

    let id = urlContact.split('/')

    setShowModal(false)
    props.createAssetResource(
      props.authentication_token,
      () => {console.log("success")},
      () => {console.log("error")},
      modalData.url,
      modalData.method,
      data
    );


    if(deletedAssets.length > 0){
      if(modalData.code === 'edit_contact'){
        deletedAssets.map((item,index) => {
          props.destroyAssignAssetByResourceable(
            props.authentication_token,
            () => setUpdatedSuccess(true),
            () => alert("ko delete"),
            id[2],
            item.resourceable_id,
            item.resourceable_type, 
            '/balance_principal/contacts_assets/',
            'contact_id'
          );
        })
      }else {
        deletedAssets.map((item,index) => {
          props.destroyAssignAssetByResourceable(
            props.authentication_token,
            () => setUpdatedSuccess(true),
            () => alert("ko delete"),
            id[2],
            item.resourceable_id,
            item.resourceable_type, 
            '/documents/documents_assets/',
            'document_id'
          );
        })
      }
      
    }

  }

  const  _showContactSuccess = (responseJSON, url) => {
    setModalData({
      form: responseJSON.form.edit_contact,
      resource_key: responseJSON.form.edit_contact.resource_key,
      url: url,
      method: responseJSON.actions[0].method,
      code: responseJSON.actions[0].code
    })
    setResources(responseJSON.assets)
    setUrlContact(url)
    setShowModal(true)

  }

  const _successAlert = (responseJSON) => {
    setModalData({
      form: responseJSON.bc_show.form.edit_alert.inputs,
      resource_key: responseJSON.bc_show.form.edit_alert.resource_key,
      url: /alerts/+responseJSON.bc_show.identifier.id,
      method: "PUT",
      code: "edit_alert",
    })
    setShowModal(true)
  }

  const _successDetailDoc = (responseJSON, url) => {
    setModalData({
      form: responseJSON.form.edit_document.inputs,
      resource_key: responseJSON.form.edit_document.resource_key,
      url: url,
      method: responseJSON.actions[0].method,
      code: responseJSON.actions[0].code
    })
    setResources(responseJSON.assets)
    setUrlContact(url)
    setShowModal(true)
  }

  const viewItem = (item, type) => {
    if(type === 'contact'){
      props.getContact(
        props.authentication_token,
        responseJSON => _showContactSuccess(responseJSON, item.url),
        () => alert("error"),
        item.url
      ); 
    }else if(type === 'document'){
      props.getDocDetail(
        props.authentication_token,
        responseJSON => _successDetailDoc(responseJSON, item.url),
        () => alert("error"),
        item.url
      );
    }else if(type === 'alert'){
      props.getAlert( props.authentication_token, item.id, (responseJSON) => _successAlert(responseJSON), () => alert("error"))      
    }else if(type === 'ownership') {
      //setAssets({name: item.title, url: item.url.resource+'/edit'})
      setAssets({type: 'ownership',name: item.title, url: item.url.resource})
      setShowNavigateModal(true)
      
      // props.history.push(item.url.resource);
    }else {
      let type_temp = 'balance-detail'
      if(type === "image") type_temp = "image";
      if(type === "related_permit") type_temp = "related_permit";
      //props.history.push('balance-detail?url='+item.url.collection+'&id='+item.id);
      setAssets({type: type_temp, name:item.title, id: item.id, url: item.url.collection, ownerable_id: item.ownerable_id})
      setShowNavigateModal(true)
    }
    
  }


  const navigateTo = () => {
    let prepare_asset = asset
    if(prepare_asset.url.indexOf('ownership_structure') !== -1){
      window.location.href = prepare_asset.url
    }else if (prepare_asset.url.indexOf('financial_asset') !== -1){
      props.history.push("balance-show?url="+prepare_asset.url);
    }else if (prepare_asset.type === "related_permit" ){
      props.history.push("profile?code=access");
    }else {
      let url_asset = prepare_asset.url.slice()
      url_asset = url_asset.split("/")
      // url_asset.pop()
      // url_asset = url_asset.join("/")
      if(asset.type === "image"){
        props.history.push("balance-detail?url="+prepare_asset.url+"&id="+prepare_asset.ownerable_id
, {
        url: prepare_asset.url,
        id: prepare_asset.ownerable_id
      });    
      }else{
        props.history.push("balance-detail?url="+prepare_asset.url+"&id="+prepare_asset.id, {
        url: prepare_asset.url,
        id: prepare_asset.id
      });      
      }
      
    }    
    // if(asset.type === 'ownership'){
    //   window.location.href = asset.url
    // }else {
    //   props.history.push("balance-detail?url="+asset.url+"&id="+asset.id, {
    //     url: asset.url,
    //     id: asset.id
    //   });
      
    // }

    setShowNavigateModal(false)
    setShowModal(false)
  }

  return(
    <div className="main-wrapper">
    <StackHeader
            title={props.internalization.buttons.search}
            icon={<Icon name="Search" iconBig={true} color={"#005774"}/>}
            help={false}
            helpAction={() => alert("boton de info del stackHeader")}
          />
      <div style={{textAlign:'center'}}>{textSubtitle}</div>
      <Search 
        colorGrey={true}
        iconLeft="with-icon-left"
        iconRight={false}
        placeholder={props.internalization.buttons.search}
        getValue={(value_search) => _search(value_search)}
        />

        <ContentBlock className="centered-and-full">
          {
            data.length > 0 &&
            <ul className="section-list-list">
              <SectionList
                data={[{data: data, title: ""}]}
                title={false}
                modal={true}
                location={props.location}
                actionContact={item => {
                  // this._showContact(item.id, item.url, this.state);
                }}
                actionContent={(item,type) =>   viewItem(item,type)
                  
                  // item.model_class == "contact" ? _showContact(item.id, item.url, this.state) :
                  // item.model_class == "image" ? this._showImage(item) : this._showDocument(item.url)
                }
                actionTitle={() => ""}
                history={props.history}
                createImageAsset={""}
                fetch={""}
                authentication_token={props.authentication_token}
                type={""}
                searchAll={true}
                isSearch={value !== "" ? value : false}
              />
            </ul>

          }
          {
            noResults &&
            <EmptySearch nameIcon="BoxEmpty" text="No data" />
          }
        </ContentBlock>
        {showModal === true && (
          <AssetsResourcesModal
            colorGrey={true}
            data={modalData}
            errors={props.errors}
            closeModal={e => {
              if (
                e.target.className === "modal-wrapper" ||
                (e.target.closest(".modal-header") !== null &&
                  e.target.closest(".modal-header").className.includes("modal-header"))
              ) {
                setShowModal(false)
              }
            }}
            uploadResource={data => _uploadResource(data)}
            resources={resources}
            resourceToSelectDestroy={
              true
            }

            resourceDestroyAction={url => _destroyAssets(url)}
            //deleteImage={(id) => this.props.destroyImageAsset(this.props.authentication_token, () => "", () => "", id)}
            polymorphic={
              "resourceable_"
            }
            goToResource={asset => _goToResource(asset)}
            // deleteDocument={(url) => this.state.modalData.resource_key === "document" ? this._deleteDocument(this.state.modalData, this.state.modalData.url) : ""}
            buttons={props.internalization.buttons}
            showModalSessionExpire={(time_left, diffMins, checkTime) => this.props.showModalSessionExpire(time_left, diffMins, () => checkTime())}            
          />
        )}
        {showNavigateModal && (
          <AlertModal
            isShowed={showNavigateModal}
            title={props.internalization.alerts.navigate_to_asset}
            msg={props.internalization.alerts.navigate_to+" "+asset.name}                    
            action={() => {navigateTo()}}
            textButton={props.internalization.buttons.ok}
            textButtonAlertColor={false}
            cancelButton={true}
            cancelTextButton={props.internalization.buttons.cancel}
            cancelButtonAlertColor={true}
            actionCancelButton={() => {
              setShowNavigateModal(false)
            }}
          />
        )}
        {updatedSuccess && (
          <AlertModal
            isShowed={updatedSuccess}
            title={props.internalization.alerts.success}
            msg={props.internalization.alerts.edit_success}
            action={() => {
               setUpdatedSuccess(false)
            }}
            textButton="OK"
          />
        )}  
    </div>
  )
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    internalization: state.session.internalization,
    errors: state.session.errors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAlert:(authentication_token, id, success, error) => dispatch(getAlert(authentication_token, id, success, error)),     
    getSearch: (authentication_token, text, success, error) => dispatch(getSearch(authentication_token, text, success, error)),
    searchAll: (authentication_token, text, success, error) => dispatch(searchAll(authentication_token, text, success, error)),
    getContact: (authentication_token, success, error, url) => dispatch(getContact(authentication_token, success, error, url)), 
    createAssetResource: (authentication_token,success,error,url,method,attributes) => dispatch(createAssetResource(authentication_token,success,error,url,method,attributes)),
    destroyAssignAssetByResourceable: (authentication_token,success,error,id,resourceable_id,resourceable_type,url, type) => dispatch(destroyAssignAssetByResourceable(authentication_token,success,error,id,resourceable_id,resourceable_type, url, type)),
    getDocDetail: (authentication_token, success, error, url) =>
    dispatch(getDocDetail(authentication_token, success, error, url)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchIndexScreen);
