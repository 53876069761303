// Dependencies
import React from 'react';

const Chart = ({ width, height, color }) => {

  return (

    <svg
      width={ width ? width : '24px'}
      height={ height ? height : '24px'}
      viewBox="0 0 24 24"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd" fill={ color ? color : '#80abba' }>
        <g id="icons-/-menu-/-net-worth" transform="translate(-6.000000, -6.000000)">
          <path d="M18,8.52631516 C18,11.7603255 18,18 18,18 C18,18 24.216323,18 27.4554817,18 C27.1498391,12.7132233 23.0333124,8.52631579 18,8.52631579 Z M18,30 C11.372583,30 6,24.627417 6,18 C6,11.372583 11.372583,6 18,6 C24.627417,6 30,11.372583 30,18 C30,24.627417 24.627417,30 18,30 Z" id="path-1"></path>
        </g>
      </g>
    </svg>

  );

}

export default Chart;
