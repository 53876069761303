// Dependencies
import React from 'react';

//components

import Icon from './icons'

const EmptySearch = (props) => {

  return (

    <div className="block__empty-search">
      <div className="block__empty-search--icon">
        <Icon name={props.nameIcon} noWrapper={true} width="68px" height="65px"/>
      </div>
      <p className="block_empty-search--text">{props.text}</p>
    </div>

  );

}

export default EmptySearch;
