// Dependencies
import React from "react";
import {gsap} from 'gsap';

// Components
import Icon from "./icons/";
import SingleArrow from "./icons/singleArrow";
import Check from "./icons/check";
import LinkBlock from "./linkBlock";

let usersModified = []

class SelectMultipleInviteds extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items:
        props.itemsSelected !== undefined && props.itemsSelected.length > 0
          ? props.itemsSelected
          : [],
      showTooltip: false,
      inputValue: {},
      canEdit: false,
    };
    this.checkIconButton = React.createRef();
    this.editIconButton = React.createRef();
  }

  componentDidMount() {
    const body = document.querySelector('body')
    // body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    const body = document.querySelector('body')
    body.style.overflow = 'visible'
  }

  componentDidUpdate(prevProps) {
    if (this.props.itemsSelected !== prevProps.itemsSelected) {
      this.setState({ items: this.props.itemsSelected });
    }
  }

  _openOptionsModal(e) {
    if (!this.props.disabled) {
      this.props.openOptions(e);

      this.dropdown(e);
    }
  }

  dropdown(e) {
    if (!this.containerElement.classList.contains("open")) {
      this.optionsElement.style.height =
        this.props.options.filter((item) => item.id !== "").length * 41 +
        32 +
        "px";

      this.containerElement.style.zIndex = 10;
      this.containerElement.classList.add("open");
      this.iconElement.classList.add("rotate");
      if (
        this.props.options.filter((item) => item.id !== "").length * 41 + 32 >
        217
      ) {
        this.optionsElement.classList.add("scroll");
      }
    } else {
      this.optionsElement.style.height = 0;
      
      // this.containerElement.classList.remove("open");

      this.iconElement.classList.remove("rotate");
    }
  }
 
  modalPaddingTop() {
    let tooltip = document.querySelector(".container-tooltip");
    let wrapperHelper = document.querySelector(".wrapper-helper");
    let containerTooltip = document.querySelector(".container-tooltip");
    let containerTootlipHeight = containerTooltip.offsetHeight + 36;
    let containerTootlipTop = containerTooltip.getBoundingClientRect().y;
    let modalContent = document.querySelector(".modal-content");
    let modalContentTop = modalContent.getBoundingClientRect().y;
    let dif = containerTootlipTop - (modalContentTop + 112);

    if (dif < 0) {
      let difPositive = -dif;
      let modalBody = document.querySelector(".modal-body");
      modalBody.style.paddingTop = `${difPositive}px`;
    }
  }

  _getLanguageLegible(language) {
    switch (language) {
      case "es-ES":
        return "Español";
      case "en-US":
        return "English";
      default:
        return "English";
    }

  }


  // Option type input

    render() {
      let mirrors = this.props.options.filter(e => e.id !== "" && e.key === "mirror")
      let inviteds = this.props.options.filter(e => e.id !== "" && e.key === "invited")
      return (
        <div
          ref={(ref) => this.props.setRef(ref)}
          className={
            this.props.colorGrey
            ? "wrapper-multiple-select grey"
            : this.props.noBorder
            ? "wrapper-multiple-select no-border"
            : "wrapper-multiple-select"
        }
      >
        <p className="label-multiple-select">
          {this.props.label && this.props.label}
        </p>

        <div className={this.props.hasTooltip ? "has-tooltip" : ""}>
          <div
            ref={(el) => (this.containerElement = el)}
            className="container-multiple-select"
            onClick={(e) => {
              this.props.options !== undefined &&
                this.props.options.length > 0 &&
                this._openOptionsModal(e)
            }}
          >
            <p className="text-multiple-select">{this.props.defaultValue}</p>
            {!this.props.disabled && (
              <div
                ref={(el) => (this.iconElement = el)}
                className="icons-multiple-select"
              >
                <Icon
                  className={"icon"}
                  noWrapper={true}
                  name="SingleArrow"
                  orientation="down"
                  width="20px"
                  height="20px"
                />
              </div>
            )}
          </div>

          <div
            ref={(el) => (this.optionsElement = el)}
            className={`${
              this.props.label
                ? "options-multiple-select"
                : "options-multiple-select top"
              } 
              ${this.props.hasTooltip ? "with-helper" : ""}
              ${this.props.optionsNoAbsolute ? "no-absolute" : ""}`
            }
          >

            

              <div  >
      { inviteds.length > 0 && <div style={{borderBottom: "1px solid"}}><label>Inviteds</label></div> }
      { 
        inviteds.map((option, index) => {
          return (
            <LinkBlock
            key={"linkinviteds_"+option.id}
            id={"linkinviteds_"+option.id}
            className={"wrapper-link-block__inline"}
            balance={false}
            date={""}
            description={option.value}
            action={() => this.props.setCheckboxesInviteds(option.id) }
            checkBox={true}
            editable={true}
            isChecked={this.props.formData["inviteds"] ? this.props.formData["inviteds"].includes(""+option.id) : this.props.element.value.includes(""+option.id)}
            // 
          />
          )
        })
      }
      {
        mirrors.length > 0 && <div style={{borderBottom: "1px solid"}}><label>Bulltick Team</label></div>
      }
      { 
        mirrors.map((option, index) => {
          return (
            <LinkBlock
            key={"linkinviteds_"+option.id}
            id={"linkinviteds_"+option.id}
            className={"wrapper-link-block__inline"}
            balance={false}
            date={""}
            description={option.value}
            action={() => this.props.setCheckboxesInviteds(option.id) }
            checkBox={true}
            editable={true}
            isChecked={this.props.formData["inviteds"] ? this.props.formData["inviteds"].includes(""+option.id) : this.props.element.value.includes(""+option.id)}
            // 
          />
          )
        })
      }
      </div>
          </div>

          
        </div>



      </div>
    );
  }
}

export default SelectMultipleInviteds;
