// Dependencies
import React from "react";

function NoResources(props) {
  return (
    <div className={`container-info-assets ${props.class}`}>
      <p className="text">{props.text}</p>
      {
        props.button &&
        <button className="btn primary" onClick={() => props.buttonAction()}>{props.btnText}</button>
      } 
      
    </div>
  );
}

export default NoResources;
