// Dependencies
import React from "react";

const BeneficiaryAccount = (props) => {
  return (
    <svg
      width={props.width ? props.width : "44px"}
      height={props.height ? props.height : "48px"}
      viewBox="0 0 44 48"
    >
     
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Beneficiary-Account" transform="translate(-10.000000, -7.000000)">
                <g id="noun_payment_417156" transform="translate(11.000000, 7.000000)">
                    <path d="M36.6678082,7.73103987 L18.4075342,7.73103987 L24.8869863,1.12587959 C26.359589,-0.375293198 28.7157534,-0.375293198 30.1392694,1.12587959 L36.6678082,7.73103987 Z M8.05022831,5.32916341 L8.05022831,7.73103987 L15.0205479,7.73103987 L21.1073059,1.52619234 L11.7808219,1.52619234 C9.71917808,1.52619234 8.05022831,3.2275215 8.05022831,5.32916341 Z M21.9908676,46.6614543 L31.0228311,46.6614543 C32.9863014,46.6614543 34.6061644,45.0101642 34.6061644,43.0086005 L34.6061644,40.5566849 L28.0285388,40.5566849 L21.9908676,46.6614543 Z M6.23401826,40.5066458 L12.5171233,46.9116497 C13.9406393,48.3627834 16.1986301,48.3627834 17.6221461,46.9116497 L23.9052511,40.5066458 L6.23401826,40.5066458 Z" id="Shape" fill={props.color ? props.color : "#80abba"} fillRule="nonzero"></path>
                    <g id="Group-2" transform="translate(0.000000, 10.500000)">
                        <g id="Group" transform="translate(3.500000, 3.000000)">
                            <g id="Group-3" transform="translate(4.000000, 4.000000)">
                                <path d="M25,4 C23.9130435,4 23,4.91304348 23,6 C23,7.08695652 23.9130435,8 25,8 C26.0869565,8 27,7.08695652 27,6 C27,4.91304348 26.0869565,4 25,4 Z" id="Path" stroke={props.color ? props.color : "#80abba"}></path>
                                <path d="M2,4 C0.913043478,4 0,4.91304348 0,6 C0,7.08695652 0.913043478,8 2,8 C3.08695652,8 4,7.08695652 4,6 C4,4.91304348 3.13043478,4 2,4 Z" id="Path" stroke={props.color ? props.color : "#80abba"}></path>
                                <path d="M20,6.5 C20,10.1024096 17.063253,13 13.5,13 C9.89759036,13 7,10.063253 7,6.5 C7,2.89759036 9.93674699,0 13.5,0 C17.1024096,0 20,2.93674699 20,6.5 Z" id="Path" stroke={props.color ? props.color : "#80abba"}></path>
                                <polygon id="Path" fill={props.color ? props.color : "#80abba"} points="17.5 4.89130435 15.9852071 3.5 12.4822485 6.7173913 11.0147929 5.36956522 9.5 6.76086957 10.9674556 8.10869565 12.4822485 9.5 13.9970414 8.10869565"></polygon>
                            </g>
                            <path d="M35,4.77966102 C34.7184987,4.88135593 34.4369973,4.88135593 34.1085791,4.88135593 C32.1380697,4.88135593 30.4959786,3.15254237 30.4959786,0.966101695 C30.4959786,0.661016949 30.5428954,0.305084746 30.5898123,0 L4.17560322,0 C4.22252011,0.254237288 4.22252011,0.457627119 4.22252011,0.711864407 C4.22252011,2.84745763 2.62734584,4.62711864 0.609919571,4.62711864 C0.375335121,4.62711864 0.18766756,4.62711864 0,4.57627119 L0,16.4237288 C0.18766756,16.3728814 0.422252011,16.3728814 0.609919571,16.3728814 C2.58042895,16.3728814 4.22252011,18.1016949 4.22252011,20.2881356 C4.22252011,20.5423729 4.22252011,20.7966102 4.17560322,21 L30.5428954,21 C30.5428954,20.8474576 30.4959786,20.6949153 30.4959786,20.4915254 C30.4959786,18.3559322 32.0911528,16.5762712 34.1085791,16.5762712 C34.4369973,16.5762712 34.7184987,16.6271186 35,16.6779661 L35,4.77966102 Z" id="Path" stroke={props.color ? props.color : "#80abba"}></path>
                        </g>
                        <path d="M42,3.78597786 L42,23.2140221 C42,25.3062731 40.369863,27 38.3561644,27 L3.64383562,27 C1.63013699,27 0,25.3062731 0,23.2140221 L0,3.78597786 C0,1.69372694 1.63013699,0 3.64383562,0 L38.3561644,0 C40.369863,0.0498154982 42,1.74354244 42,3.78597786 Z" id="Path" stroke={props.color ? props.color : "#80abba"}></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default BeneficiaryAccount;
