// Dependencies
import React from 'react';

const Notification = ({ width, height, color }) => {

    return (

        <svg 
            width={ width ? width : '25px'}
            height={ height ? height : '28px'}
            viewBox="0 0 25 28" 
        >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M8.50039172,24.8431599 C8.50039172,24.8500705 8.5,24.8569043 8.5,24.8638149 C8.5,26.5959959 10.2906282,28 12.4998041,28 C14.7089801,28 16.5,26.5959959 16.5,24.8638149 C16.5,24.8569043 16.4992166,24.8500705 16.4992166,24.8431599 L16.4992166,23 L8.5,23 L8.50039172,24.8431599 Z" id="path" fill={ color ? color : "#80abba" }></path>
                <path d="M24.6985923,21.2696716 L21.2601983,16.7178095 C21.2601983,15.3243496 21.2601983,11.9193038 21.2601983,11.0385185 C21.2601983,7.2088009 18.2332929,4.01617538 14.2175615,3.29664399 L14.2175615,1.547886 C14.2175615,0.693147448 13.4484643,0 12.4998612,0 C11.5512581,0 10.7821609,0.693147448 10.7821609,1.547886 L10.7821609,3.29664399 C6.76642953,4.01642827 3.73989825,7.2088009 3.73989825,11.0388557 C3.73989825,12.5380199 3.73989825,15.8489096 3.73989825,16.7178095 L0.30113012,21.2696716 C-0.0609685533,21.7491344 -0.0993205183,22.3697892 0.20132147,22.8824638 C0.502431166,23.3954755 1.09333205,23.715876 1.73708381,23.715876 L23.2626386,23.715876 C23.9063904,23.715876 24.4972912,23.3951383 24.7984009,22.8824638 C25.0994171,22.3697892 25.0610651,21.7491344 24.6985923,21.2696716 Z" id="Fill-3" fill={ color ? color : "#80abba" }></path>
            </g>
        </svg>
    );

}

export default Notification;
