// Dependencies
import React from "react";
import { connect } from "react-redux";

// Components
import FormBlock from "../../../components/formBlock";
import Modal from "../../../components/modal";
import ContactForm  from '../../../components/contactForm'
import DragAndDrop  from '../../../components/dragAndDrop'

import {initSessionMonitor} from '../../../helpers/checkTimeOutSession'
import { destroySession } from "../../../actions/session";


class AssetsResourcesModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
     
  }

  componentDidMount(){
 
     initSessionMonitor(() => this.props.destroySession( this.props.authentication_token, () => console.log("out"), () => console.log("error") ), (time_left, diffMins, checkTime) => this.props.showModalSessionExpire(time_left, diffMins, () => checkTime())    );
  }

  _createResource = formData => {
    this.props.uploadResource({
      [this.props.data.resource_key]: formData
    });
  };

  render() {
    console.log("this.props", this.props)
    let resource_constant = this.props.data.resource_key == "tenant" ? "RealEstate" : undefined
    resource_constant = this.props.data.resource_key == "tenant" ? "stake_holder" : resource_constant
    return (
      <Modal showBackButton={true} backAction={(e) => this.props.closeModal(e)} isShowed={true} buttonText={this.props.buttons.back}>
        {
          this.props.data.resource_key === 'contact' &&
            <ContactForm 
            language={this.props.language}
            identifier={this.props.identifier}
            data={this.props.data}
            buttons={this.props.buttons}
            actionSubmitButton={formData => this._createResource(formData)}
            resourcesToSelect={this.props.resources}
            resourceToSelectDestroy={this.props.resourceToSelectDestroy}
            resourceDestroyAction={this.props.resourceDestroyAction}
            polymorphic={this.props.polymorphic}
            goToResource={asset => this.props.goToResource(asset)}
            destroyAssetAssociated={() => this.props.destroyAssetAssociated()} 
            /> 
          }
          {
            this.props.data.resource_key === 'document' && this.props.data.code === "add_document" &&
            <DragAndDrop 
              language={this.props.language}            
              categorieOptions={this.props.categorieOptions}
              identifier={this.props.identifier}
              successSendFiles={() => this.props.successSendFiles()}
            />
          }
          {
             !(this.props.data.resource_key === 'document' && this.props.data.code === "add_document") && this.props.data.resource_key !== 'contact' &&
          
            <FormBlock
            language={this.props.language}
            contactForm={this.props.contactForm}
            colorGrey={this.props.colorGrey}
            data={this.props.data}
            disabled={this.props.data.method == "PUT"}
            actionSubmitButton={formData => this._createResource(formData)}
            resourcesToSelect={this.props.resources}
            resourceToSelectDestroy={this.props.resourceToSelectDestroy}
            resourceDestroyAction={this.props.resourceDestroyAction}
            polymorphic={this.props.polymorphic}
            goToResource={asset => this.props.goToResource(asset)}
            deleteImage={(id) => this.props.deleteImage && this.props.deleteImage(id)}
            deleteDocument= {() => this.props.deleteDocument ? this.props.deleteDocument() : ""}
            errors={this.props.errors || {}}
            buttons={this.props.buttons}
            resource_constant={this.props.data.resource_key == "tenant" ? "RealEstate" : undefined}
            showDocument={url => this.props.showDocument ? this.props.showDocument(url) : ""}
            showContact={(url) => this.props.showContact ? this.props.showContact(url, this.props.data.idMain) : ""}
            destroyAssetAssociated={() => this.props.destroyAssetAssociated()} 
            getLinkForm = {this.props.getLinkForm ? (url, success_fromblock, error_fromblock) => this.props.getLinkForm(url, (responseJSON) => success_fromblock(responseJSON), () => error_fromblock("error")) : undefined }
            getConvertedValue={(value, currency_type, date, success_fromblock, error_fromblock) => this.props.getConvertedValue(value, currency_type, date, (responseJSON) => success_fromblock(responseJSON),() => error_fromblock("error"))}
          /> 
        }
      
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    destroySession: (authentication_token, success, error) => dispatch(destroySession(authentication_token, success, error)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetsResourcesModal);
