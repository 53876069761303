import { HOST_URL, URL_VERSION } from '../constants';

import get_header from "./api_header";
import {parseHeaders} from './api_header'
import {setSession} from './session'

export function allAlerts(authentication_token, date, success,error) {
  let params = ""
  if(date != undefined){
    params = "?date="+date
  }
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/alerts'+params, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function getAlert(authentication_token, id, success,error) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/alerts/'+id, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function updateAlertInvited(authentication_token, id, attributes, success, error) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/alerts/'+id, {
      method: 'PUT',
      headers: get_header(authentication_token),
      body: JSON.stringify(attributes)
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          dispatch(setSession(responseJson));
          success()
        })
      }
      else{

      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}
