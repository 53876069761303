// Dependencies
import React from 'react';

const DocPowerPointSquare = ({ width, height, color, bgColor }) => {

  return (

    <svg
      width={width ? width : '29px'}
      height={height ? height : '29px'}
      viewBox="0 0 29 29"
    >

        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="SVG-Layer" transform="translate(-0.390164, 0.147375)" fill={color ? color : "#80abba"}>
                <g id="Symbols">
                    <g id="icons-/-docs-/-powerpoint">
                        <g id="doc-powerpoint">
                            <path d="M25.375,0.517857143 L3.625,0.517857143 C1.91607143,0.517857143 0.517857143,1.91607143 0.517857143,3.625 L0.517857143,25.375 C0.517857143,27.0839286 1.91607143,28.4821429 3.625,28.4821429 L25.375,28.4821429 C27.0839286,28.4821429 28.4821429,27.0839286 28.4821429,25.375 L28.4821429,3.625 C28.4821429,1.91607143 27.0839286,0.517857143 25.375,0.517857143 Z M11.0821429,16.675 L11.0821429,22.2678571 L8.28571429,22.2678571 L8.28571429,6.73214286 L14.9660714,6.73214286 C17.2964286,6.73214286 18.3839286,7.19821429 19.3160714,8.13035714 C20.4035714,9.0625 20.7142857,10.3053571 20.7142857,11.7035714 C20.7142857,13.2571429 20.2482143,14.5 19.3160714,15.2767857 C18.3839286,16.0535714 17.2964286,16.5196429 14.9660714,16.5196429 L11.0821429,16.5196429 L11.0821429,16.675 Z" id="Shape" fillRule="nonzero"></path>
                            <path d="M11.3928571,14.6553571 L11.3928571,9.0625 L14.9660714,9.0625 C16.0535714,9.0625 16.8303571,9.37321429 17.2964286,9.99464286 C17.7625,10.6160714 18.0732143,11.0821429 18.0732143,11.8589286 C18.0732143,12.7910714 17.7625,13.2571429 17.2964286,13.8785714 C16.8303571,14.3446429 16.2089286,14.6553571 15.1214286,14.6553571 L11.3928571,14.6553571 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>

  );

}

export default DocPowerPointSquare;
