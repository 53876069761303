// Dependencies
import React from "react";
import { connect } from "react-redux";


// Actions
import {
  updateDoc,
  getDocShow,
  getDocDetail,
  cleanDocShow,
  getDocNew,
  createDocumentAsset,
} from "../actions/documents";
import {
  getContact,
  destroyContactAsset,
  createContactAsset,
  destroyAssignAssetByResourceable,
} from "../actions/contact";
import {
  destroyAsset,
  createAssetResource,
  getBCDetail,
} from "../actions/balance";
import { destroyImageAsset, createImageAsset } from "../actions/image";

// Components
import ContentBlock from "./contentBlock";
import SectionList from "./sectionList";
import Search from "./search";
import ActionSheet from "./actionSheet";
import FloatButton from "./floatButton";
import Icon from "./icons";
import AlertModal from "./alertModal";
import AssetsResourcesModal from "../screens/balance/details/assetsResourcesModal";
import EmptySearch from "./emptySearch";
import Accordion from "./accordion";
import Modal from "./modal";

// Helpers
import ActionSheetOnPressAction from "../helpers/actionSheetOnPressAction";

import { canManage, canRead } from "../helpers/permits";

class DocumentsShowScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showModal: false,
      section_id: -1,
      options_action_sheet: [],
      url_contact: "",
      tempData: {},
      formData: {},
      resources: [],
      firstImages: [],
      assigned_contacts:
        Object.keys(this.props.assignData).length > 0 &&
        this.props.assignData.resourceable_id !== undefined &&
        this.props.assignData.resourceable_id > 0
          ? this.props.assignData.assigned_contacts.slice(0)
          : this.props.assignData.specialAssign !== undefined
          ? this.props.assignData.assigned_contacts.slice(0)
          : [],
      new_clicked: [],
      isSearching: false,
      showNavigateModal: false,
      asset: {},
      category_title: "",
      changeIds: false,
      showAlert: false,
      deleteContacts: [],
      modalData: {},
      deletedAssets: [],
      showRecoverAlert: false,
      recoverObject: {},
      showSuccesModal: false,
      showDeleteModalDocument: false,
      showDestroyPermanentlyAlert: false,
      deletePermanently: {},
      modal_title: "",
      modal_body: "",
      modal_action: undefined,
      documentCreate: false,
      imagesToZoom: [],
      showDeleteModalImage: false,
      showNavigateImageModal: false,
      textSearch: false
    };

    this.actionSheet = React.createRef();
    this.matchedItems = [];
  }

  componentDidMount() {
    this._fetch();
    if (
      !this.props.assignData &&
      this.props.history.location.state.from === "profile"
    ) {
      this.setState({
        showModal: true,
        modalData: this.props.history.location.state.modalData,
      });
    }
  }

  componentWillUnmount() {
    this.props.cleanDocShow();
  }

  _success(category_title) {
    let data = {};
    let visible = false;
    let resources = [];

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.type === "create" &&
      this.props.documents_show &&
      this.props.documents_show.form &&
      this.props.documents_show.form.add_document &&
      !this.state.documentCreate
    ) {
      data = this.props.documents_show.form.add_document;
      data.form = this.props.documents_show.form.add_document.inputs;
      data.code = "add_document";
      data.from = "DocShow";
      data.method = "POST";
      visible = true;
      resources = this.props.documents_show.form.add_document.assets;
    }

    this.setState({
      loading: false,
      tempData: {},
      formData: {},
      modalData: data,
      resources: resources,
      firstImages: [],
      category_title: category_title,
      deletedAssets: [],
      showSuccesModal: false,
      showModal: visible,
      showDeleteModal: false,
      destoyedSuccess: false,
      showDeleteModalImage: false,
      showImageModal: false
    });

    this.props.setBradcrumbTitle &&
      this.props.setBradcrumbTitle(
        category_title || this.props.documents_show.title
      );
  }

  _error() {
    alert("error");
  }

  _actionSheetPressed(item) {
    let response = ActionSheetOnPressAction(
      item,
      this.props.documents_show,
      this.props,
      () => this._deleteAsset(item.url, item.method)
    );
    this.actionSheet.current.show();
    if (response.status) {
      this.setState({
        showModal: true,
        modalData: response.modalData,
        resources: response.modalData.assets || [],
      });
    }
  }

  _showActionSheetContent() {
    let that = this;
    let optionsActionSheet = [];
    this.props.documents_show.actions.map((item, i) => {
      return optionsActionSheet.push({
        type: "icon button",
        code: item.code,
        iconName: item.code.includes("add")
          ? "Close"
          : item.code.includes("edit")
          ? "Pencil"
          : item.code.includes("show")
          ? "Eye"
          : "Garbage",
        message: item.title,
        onClick: () => {
          this._actionSheetPressed(item);
        },
      });
    });

    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true,
        },
      ],
    });

    setTimeout(function () {
      that.actionSheet.current.show();
    }, 100);
  }

  _successCreateAssetResource(edit, key) {
    this.setState({
      modal_title: edit
        ? this.props.internalization.modals[key].updated.title
        : this.props.internalization.modals[key].created.title,
      modal_body: edit
        ? this.props.internalization.modals[key].updated.body
        : this.props.internalization.modals[key].created.body,
      showSuccesModal: true,
      loading: false,
      documentCreate: true,
    });
  }

  _errorCreateAssetResource() {
    alert("error");
  }

  _uploadResource(data) {
    let promises = [];

    this.setState({ showModal: false });

    if (this.state.deletedAssets.length > 0) {
      if (this.state.modalData.code === "edit_contact") {
        this.state.deletedAssets.map((item, index) => {
          let promiseMap = new Promise((resolve, reject) => {
            this.props.destroyAssignAssetByResourceable(
              this.props.authentication_token,
              () => {
                resolve();
              },
              () => {
                reject();
              },
              this.state.modalData.id,
              item.resourceable_id,
              item.resourceable_type,
              "/balance_principal/contacts_assets/",
              "contact_id"
            );
          });
          promises.push(promiseMap);
        });
        Promise.all(promises).then((completed) =>
          this._updatedSuccess(this.state.modalData.resource_key, undefined)
        );
      } else {
        this.state.deletedAssets.map((item, index) => {
          let promiseMap = new Promise((resolve, reject) => {
            this.props.destroyAssignAssetByResourceable(
              this.props.authentication_token,
              () => resolve(),
              () => reject(),
              this.state.modalData.id,
              item.resourceable_id,
              item.resourceable_type,
              "/documents/documents_assets/",
              "document_id"
            );
          });
          promises.push(promiseMap);
        });
        Promise.all(promises).then((completed) =>
          this._updatedSuccess(this.state.modalData.resource_key, undefined)
        );
      }
    }

    this.props.createAssetResource(
      this.props.authentication_token,
      () =>
        this._successCreateAssetResource(
          this.state.modalData.method === "PUT",
          this.state.modalData.resource_key
        ),
      () => this._errorCreateAssetResource(),
      this.state.modalData.url,
      this.state.modalData.method,
      data
    );
  }

  _updatedSuccess(key, action) {
    this.setState({
      showSuccesModal: true,
      modal_title: this.props.internalization.modals[key].assigned.title,
      modal_body: this.props.internalization.modals[key].assigned.body,
      modal_action: () =>
        this._modalAction(action ? () => action() : undefined),
    });
  }
  _modalAction(action) {
    if (action) {
      action();
      this.props.assignData.backAction();
    }
  }
  _checkArray(elem, text) {
    let find = false;

    elem.map((e) => {
      if (e && e.toLowerCase().includes(text.toLowerCase())) {
        find = true;
        return;
      }
    });
    return find;
  }

  _getSearchValue(text) {
    this.matchedItems = [];
    let search;
    if (
      this.props.assignData.id === "contact" ||
      this.props.assignData.id === "photo"
    ) {
      this.props.documents_show &&
        this.props.documents_show.data.map((item, i) => {
          return item.data.map((elem) => {
            return (
              ((elem.title &&
                elem.title.toLowerCase().includes(text.toLowerCase())) ||
                (elem.description &&
                  elem.description
                    .toLowerCase()
                    .includes(text.toLowerCase())) ||
                (elem.email && this._checkArray(elem.email, text)) ||
                (elem.phone && this._checkArray(elem.phone, text))) &&
              this.matchedItems.push(elem)
            );
          });
        });
    } else {
      this.props.documents_show.data.map((item, i) => {
        return item.data.map((elem) => {
          return (
            (elem.title !== null && elem.title !== undefined && elem.title.toLowerCase().includes(text.toLowerCase()) ||
              (elem.description !== null && elem.description !== undefined &&
                elem.description.toLowerCase().includes(text.toLowerCase())) ||
              elem.type !== null && elem.type !== undefined && elem.type.toLowerCase().includes(text.toLowerCase())) &&
            this.matchedItems.push(elem)
          );
        });
      });
    }

    if (text.length > 0) {
      search = true;
    } else {
      search = false;
      this.matchedItems = [];
    }

    this.setState({
      isSearching: search,
      textSearch: text
    });
    this.matchedItems = [{ title: "Búsqueda", data: this.matchedItems }];
  }

  _assignContact(id, state) {
    let ids = state.assigned_contacts;
    let deleteIds = state.deleteContacts;
    let new_clicked = state.new_clicked
    if (ids.indexOf(id) === -1) {
      ids.push(id);
      if (deleteIds.indexOf(id) !== -1) {
        deleteIds.splice(deleteIds.indexOf(id), 1);
        this.setState({ deleteContacts: deleteIds });
      }
    } else {
      ids.splice(ids.indexOf(id), 1);
      if (deleteIds.indexOf(id) === -1) {
        deleteIds.push(id);
        this.setState({ deleteContacts: deleteIds });
      }
    }
    if (new_clicked.indexOf(id) === -1) {
      new_clicked.push(id);
    } else {
      new_clicked.splice(new_clicked.indexOf(id), 1);
    }    

    this.setState({ assigned_contacts: ids, changeIds: true, new_clicked: new_clicked });
  }

  _showContactSuccess(responseJSON, url) {
    this.setState({
      url_contact: url,
      firstImages: {
        images: responseJSON.form.edit_contact.inputs.filter(
          (e) => e.id === "image"
        ),
      },
      formData: {
        images: responseJSON.form.edit_contact.inputs.filter(
          (e) => e.id === "image"
        ),
      },
      showModal: true,
      resources: responseJSON.assets,
      modalData: {
        //idMain: this.props.balance_detail.identifier.id,
        files: this.props.files,
        id: responseJSON.identifier.id,
        form: responseJSON.form.edit_contact,
        can_manage: responseJSON.form.edit_contact.can_manage,
        resource_key: responseJSON.form.edit_contact.resource_key,
        title: responseJSON.title,
        show_title: responseJSON.show_title,
        edit_title: responseJSON.edit_title,
        url: url,
        method:
          responseJSON.actions.length > 0 ? responseJSON.actions[0].method : "",
        code:
          responseJSON.actions.length > 0 ? responseJSON.actions[0].code : "",
      },
    });
  }

  _showContact(id, url, state) {
    if (this.props.assignData.resourceable_id > 0) {
      if (state.assigned_contacts.indexOf(id) === -1) {
        this._addContact(id, () => this._successAssignContact(id, state));
      } else {
        this._deleteContact(id, () => this._successAssignContact(id, state));
      }
    } else {
      this.props.getContact(
        this.props.authentication_token,
        (responseJSON) => this._showContactSuccess(responseJSON, url),
        () => alert("error"),
        url
      );
    }

    this.setState({
      deletedAssets: [],
    });
  }

  _successDetailDoc(responseJSON, url) {
    this.setState({
      url_contact: url,
      showModal: true,
      resources: responseJSON.assets,
      modalData: {
        files: this.props.files,
        id: responseJSON.identifier.id,
        form: responseJSON.form.edit_document.inputs,
        resource_key: responseJSON.form.edit_document.resource_key,
        url: url,
        can_manage: responseJSON.form.edit_document.can_manage,
        method: responseJSON.actions[0].method,
        code: responseJSON.actions[0].code,
        title: responseJSON.title,
        show_title: responseJSON.show_title,
        edit_title: responseJSON.edit_title,
      },
    });
  }

  _showDocument(item) {
    this.props.getDocDetail(
      this.props.authentication_token,
      (responseJSON) => this._successDetailDoc(responseJSON, item.url),
      () => this._error(),
      item.url
    );
  }

  _successNew(responseJSON, cateogory_id) {
    this.setState({
      showModal: true,
      modalData: {
        code: "add_document",
        resource_key: responseJSON.form.resource_key,
        form: responseJSON.form.inputs,
        url: responseJSON.form.url,
        method: responseJSON.form.method,
      },
      resources: responseJSON.resources,
    });
  }

  _getResourcesForCategory(category_id, parent_category_id) {
    this.props.getDocNew(
      this.props.authentication_token,
      parent_category_id,
      category_id,
      (responseJSON) => this._successNew(responseJSON, category_id),
      () => this._errorNew(),
      "documents/documents/new"
    );
  }

  _goToResource(asset) {
    this.setState({
      showNavigateModal: true,
      asset: asset,
    });
  }

  _fetch() {
    
    this.props.getDocShow(
      this.props.authentication_token,
      (category_title) => this._success(category_title),
      () => this._error(),
      this.props.assignData.url
    );
  }

  _back(e, state) {
    if (state.changeIds) {
      this.setState({ showAlert: true });
    } else {
      this.props.assignData.backAction(e);
    }
  }

  _checkIds(contacts) {
    let assignedIds = this.props.assignData.assigned_contacts;
    let ids = [];

    assignedIds.map((item, index) => {
      if (contacts.includes(item)) {
        ids.push(item);
      }
    });

    return ids;
  }

  _addContact(item, success) {
    this.props.createContactAsset(
      this.props.authentication_token,
      () => this._updatedSuccess("contact", () => success()),
      () => alert("ko delete 1"),
      item,
      this.props.assignData.resourceable_id,
      this.props.assignData.resourceable_type
    );
  }

  success() {}

  _addDocument(item, success) {
    this.props.createDocumentAsset(
      this.props.authentication_token,
      () => this._updatedSuccess("document", () => success()),
      () => alert("ko delete 2"),
      item,
      this.props.assignData.resourceable_id,
      this.props.assignData.resourceable_type
    );
  }

  _deleteContact(item, success) {
    this.props.destroyAssignAssetByResourceable(
      this.props.authentication_token,
      () => success(),
      () => alert("ko delete 3"),
      item,
      this.props.assignData.resourceable_id,
      this.props.assignData.resourceable_type,
      "/balance_principal/contacts_assets/",
      "contact_id"
    );
  }
  _deleteDocument(item, success) {
    this.props.destroyAssignAssetByResourceable(
      this.props.authentication_token,
      () => success(),
      () => alert("ko delete 4"),
      item,
      this.props.assignData.resourceable_id,
      this.props.assignData.resourceable_type,
      "/documents/documents_assets/",
      "document_id"
    );
  }

  _saveIds(type, state) {
    let assignedIds = this.props.assignData.assigned_contacts;
    let saveIds = [];
    let deleteIds = [];
    if (assignedIds.length > 0) {
      state.assigned_contacts.map((item, index) => {
        if (!this._checkIds(state.assigned_contacts).includes(item)) {
          saveIds.push(item);
        }
      });

      if (type === "contact") {
        if (this.props.assignData.specialAssign !== undefined) {
          this.props.assignData.specialAssign(
            saveIds,
            type,
            this.props.documents_show.data,
            this.state.new_clicked
          );
        } else {
          this._addContact(saveIds, () => this.props.assignData.fetch());
        }
      } else {
        if (this.props.assignData.specialAssign !== undefined) {
          this.props.assignData.specialAssign(
            saveIds,
            type,
            this.props.documents_show.data,
            this.state.new_clicked
          );
        } else {
          this._addDocument(saveIds, () => this.props.assignData.fetch());
        }
      }

      if (state.deleteContacts.length > 0) {
        state.deleteContacts.map((item, index) => {
          if (this._checkIds(state.deleteContacts).includes(item)) {
            deleteIds.push(item);
          }
        });
        if (this.props.assignData.specialAssign !== undefined) {
          let new_ids = this.props.assignData.assigned_contacts.slice(0);

          deleteIds.map((id) => {
            let position = new_ids.splice(new_ids.indexOf(id), 1);
          });
          if(this.props.assignData.special_type !== true){
            this.props.assignData.specialAssign(
              new_ids,
              type,
              this.props.documents_show.data,
              this.state.new_clicked
            );  
          }
          
        } else {
          if (type === "contact") {
            this._deleteContact(deleteIds, () => this.props.assignData.fetch());
          } else {
            this._deleteDocument(deleteIds, () =>
              this.props.assignData.fetch()
            );
          }
        }
      }
    } else {
      if (type === "contact") {
        if (this.props.assignData.specialAssign !== undefined) {
          this.props.assignData.specialAssign(
            state.assigned_contacts,
            type,
            this.props.documents_show.data,
            this.state.new_clicked
          );
        } else {
          this._addContact(state.assigned_contacts, () =>
            this.props.assignData.fetch()
          );
        }
      } else {
        if (this.props.assignData.specialAssign !== undefined) {
          this.props.assignData.specialAssign(
            state.assigned_contacts,
            type,
            this.props.documents_show.data,
            this.state.new_clicked
          );
        } else {
          this._addDocument(state.assigned_contacts, () =>
            this.props.assignData.fetch()
          );
        }
      }
    }

    // this.props.assignData.backAction()
  }

  _destroyAssets(url, state) {
    let allAssets = state.resources;
    let deletedAssets = state.deletedAssets;

    allAssets = allAssets.filter(
      (deleted) => deleted.resourceable_id !== url.resourceable_id
    );

    deletedAssets.push(url);

    this.setState({
      resources: allAssets,
      deletedAssets: deletedAssets,
    });
  }

  _showDocumentDeletedAlert(item) {
    
    this.setState({ recoverObject: item, showRecoverAlert: true });

    
  }

  _recoverObject(item) {
    this.props.updateDoc(
      this.props.authentication_token,
      { deleted: false },
      (responseJson) => {
        this._recovered();
      },
      () => alert("error"),
      "" + item.url_recover + item.id
    );
  }

  _deleteObject(item) {
    this.props.destroyAsset(
      this.props.authentication_token,
      () => this._deleted(),
      () => alert("error"),
      item.url_recover+item.id,
      "delete",
      true
    )
  }

  _deleted() {
    this.setState({
      showSuccesModal: true,
      showRecoverAlert: false,
      modal_title: this.props.internalization.modals.file.deleted.title,
      modal_body: this.props.internalization.modals.file.deleted.body,
    });
  }

  _recovered() {
    this.setState({
      showSuccesModal: true,
      showRecoverAlert: false,
      modal_title: this.props.internalization.modals.file.recovered.title,
      modal_body: this.props.internalization.modals.file.recovered.body,
    });
  }

  _navigateToAsset(state) {
    let asset = state.asset;
    if (asset.url.indexOf("ownership_structure") !== -1) {
      window.location.href = asset.url;
    }else if(asset.url.indexOf("financial_assets") !== -1){
      this.props.history.push(
        "balance-show?url=" + asset.url
      );
    }
    else {
      let url_asset = asset.url.slice();
      url_asset = url_asset.split("/");
      url_asset.pop();
      url_asset = url_asset.join("/");

      this.props.history.push(
        "balance-detail?url=" + url_asset + "&id=" + asset.resourceable_id,
        {
          url: url_asset,
          id: asset.resourceable_id,
        }
      );
    }

    this.setState({
      showNavigateModal: false,
      showModal: false,
    });
  }
  _deletePermanently(item, e) {
    this.setState({
      showDestroyPermanentlyAlert: true,
      deletePermanently: item,
    });
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  _deletePermanentlyCall(item) {
    this.props.destroyAsset(
      this.props.authentication_token,
      () => this._deletedPermanently(),
      () => alert("error"),
      item.url_recover + "/" + item.id,
      "delete",
      true
    );
  }

  _deletedPermanently() {
    this.setState({
      showSuccesModal: true,
      showDestroyPermanentlyAlert: false,
      modal_title: this.props.internalization.modals.file.deleted.title,
      modal_body: this.props.internalization.modals.file.deleted.body,
    });
  }

  _destroyAssetAssociated(modalData) {
    this.setState({
      showDeleteModal: true,
      modal_title: this.props.internalization.modals[modalData.resource_key]
        .delete.title,
      modal_body: this.props.internalization.modals[modalData.resource_key]
        .delete.body,
      modal_url: modalData.url,
      modal_action: () => this._successDestroyAssetAssociated(modalData),
    });
  }

  _successDestroyAssetAssociated(modalData) {
    this.setState({
      destoyedSuccess: true,
      modal_title: this.props.internalization.modals[modalData.resource_key]
        .deleted.title,
      modal_body: this.props.internalization.modals[modalData.resource_key]
        .deleted.body,
      modal_action: () => this._fetch(),
    });
  }

  _closeModal(e) {
    if (
      e.target.className === "modal-wrapper" ||
      (e.target.closest(".modal-header") !== null &&
        e.target.closest(".modal-header").className.includes("modal-header"))
    ) {
      if (this.props.location.state.type === "create") {
        this.props.history.push("/documents-index");
      }
      this.setState({ showModal: false, modalData: {}, deletedAssets: [] });
    }
  }

  _deleteDocumentSuccess() {
    this.setState({
      showModal: false,
      showDeleteModalDocument: false,
      showDeleteModal: false,
      showSuccesModal: true,
      modal_title: this.props.internalization.modals.document.deleted.title,
      modal_body: this.props.internalization.modals.document.deleted.body,
    });

    if (
      this.props.documents_show.data.length === 1 &&
      this.props.documents_show.data[0].data.length === 1
    ) {
      this.props.history.push("/documents-index");
    }
  }

  _checkType() {
    switch (this.props.assignData.id) {
      case "contact":
        return "contact";
      case "photo":
        return "photo";
      default:
        return "files";
    }
  }

  removeBodyClass() {
    let body = document.body;
    body.classList.remove("has-modal");
  }

  _createImagesToZoom(item) {

    this.setState({
      imagesToZoom: [item],
      showImageModal: true,
    });
  }

  _hideModal = (e) => {
    if (
      e.target.className === "modal-wrapper" ||
      (e.target.closest(".modal-header") !== null &&
        e.target.closest(".modal-header").className.includes("modal-header"))
    ) {
      this.setState({
        imagesToZoom: [],
        showImageModal: false,
      });
    }
  };

  _deleteImage(id) {

    //this.setState({showDeleteModalImage: true, modalData: {id: id}})
    this.props.destroyImageAsset(
      this.props.authentication_token,
      () => {this.setState({showDeleteModalImage: true})},
      () => console.log("ERROR"),
      id)
  }

  _showNavigateModalImg(data) {
    if(data.url_asset.resource){
      this.setState({showNavigateImageModal: true, modalData: data})
    }
  }

  _navigateToImg(state){

    let url = state.modalData.url_asset.collection
    let id = state.modalData.ownerable_id
    this.props.history.push(
      "balance-detail?url=" + url + "&id=" + id,
      {
        url: url,
        id: id,
      }
    );
  }


  render() {
    console.log(this.props.documents_show)
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };

    let is_empty =
      this.state.isSearching === true
        ? this.matchedItems.length === 0
        : this.props.documents_show.hasOwnProperty("data")
        ? !this.props.documents_show.data
            .map((e) => e.data.length !== 0)
            .includes(true)
        : this.props.documents_show
        ? this.props.documents_show.length === 0
        : false;

    //
    return (
      <div
        className={
          this.props.assignData.modal ? "main-wrapper contact" : "main-wrapper"
        }
      >
        {this.state.showAlert && (
          <AlertModal
            isShowed={this.state.showAlert}
            title={this.props.assignData.messageAlert}
            msg="Your changes will be lost"
            action={() => {
              this.props.assignData.backAction();
            }}
            textButton="Yes"
            cancelButton={true}
            cancelTextButton="Cancel"
            actionCancelButton={() => {
              this.setState({
                showAlert: false,
              });
            }}
          />
        )}
        {this.props.assignData.modal && (
          <button
            className="btn--back-modal"
            onClick={(e) => {
              this.removeBodyClass();
              this._back(e, this.state);
            }}
          >
            <Icon name="ChevronArrow" orientation="left" bgColor="white" />
            <span className="title2">Back</span>
          </button>
        )}

        <Search
          colorGrey={true}
          iconLeft="with-icon-left"
          iconRight={false}
          placeholder={this.props.internalization.buttons.search}
          action={() => this._actionTags()}
          getValue={(value) => this._getSearchValue(value)}
        />
        <div style={{ marginBottom: 10 }}>
          <span className="headingH2">
            {this.props.documents_show.title}
          </span>
        </div>

        {((this._checkType() !== "files" && this._checkType() !== "photo") ||
          (this.matchedItems.length > 0 && this.state.isSearching) ||
          this.props.assign) && (
          <ContentBlock className="centered-and-full">
            {Object.keys(this.props.documents_show).length > 0 && (
              <ul className="section-list-list">
                <SectionList
                  data={
                    this.state.isSearching === true
                      ? this.matchedItems
                      : this.props.documents_show.hasOwnProperty("data")
                      ? this.props.documents_show.data
                      : this.props.documents_show
                  }
                  internalization={this.props.internalization}
                  modal={this.props.assignData.modal}
                  location={this.props.assignData.location}
                  actionContact={
                    this.props.assignData.modal
                      ? (item, type) => {
                          this._assignContact(item.id, this.state);
                        }
                      : (item) =>
                          this._showContact(item.id, item.url, this.state)
                  }
                  actionContent={(item) => {
                    if (item.deleted === true) {
                      this._showDocumentDeletedAlert(item);
                    } else {
                      ["tenant", "trust", "account"].includes(this.props.assignData.id) ? this._assignContact(item.id, this.state) : this._showDocument(item);
                      
                    }
                  }}
                  actionTitle={(item) =>
                    this.props.assignData.id !== "photo"
                      ? this._getResourcesForCategory(
                          item.category_id,
                          item.parent_category_id
                        )
                      : ""
                  }
                  // id={this.props.assignData.id}
                  history={this.props.history}
                  createImageAsset={this.props.createImageAsset}
                  fetch={() => this._fetch()}
                  authentication_token={this.props.authentication_token}
                  assigned_contacts={this.state.assigned_contacts}
                  type={this.props.assignData.id}
                  assignDataType={
                    Object.keys(this.props.assignData).length > 0 &&
                    this.props.assignData.type
                  }
                  resource_key={this.props.assignData.id}
                  searchAll={false}
                  deleteIconAction={(item, e) =>
                    this._deletePermanently(item, e)
                  }
                  isSearch={this.matchedItems.length > 0 && this.state.isSearching ? this.state.textSearch : false}
                />
              </ul>
            )}
          </ContentBlock>
        )}

        {this._checkType() === "photo" &&
          this.matchedItems.length === 0 &&
          !is_empty &&
          !this.state.isSearching &&
          !this.props.assign && (
            <Accordion
              data={this.props.documents_show.data}
              type={this._checkType()}
              actionContent={(item) => this._createImagesToZoom(item)}
            />
          )}

        {this._checkType() === "files" &&
          this.matchedItems.length === 0 &&
          !is_empty &&
          !this.state.isSearching &&
          !this.props.assign && (
            <Accordion
              data={this.props.documents_show.data}
              actionContent={(item) => {
                if (item.deleted === true) {
                  this._showDocumentDeletedAlert(item);
                } else {
                  this._showDocument(item);
                }
              }}
            />
          )}

        {!this.state.loading && is_empty && (
          <EmptySearch nameIcon="BoxEmpty" text="No data" />
        )}

        {
          this.props.documents_show.actions !== undefined &&
          this.props.documents_show.actions != null &&
          this.props.documents_show.actions.length > 0 &&
          !this.props.assignData.modal && (
            <>
              <ActionSheet
                ref={this.actionSheet}
                render={this.state.options_action_sheet}
              />
              <FloatButton
                children={
                  <Icon
                    name="Close"
                    color="white"
                    iconBig={true}
                    className="wrapper-icon-floatButton"
                    close={false}
                  />
                }
                action={() => this._showActionSheetContent(this.state)}
              />
            </>
          )}
        {this.state.showModal === true && (
          <AssetsResourcesModal
            identifier={this.props.documents_show.identifier}
            categorieOptions={this.props.documents_show.form}
            successSendFiles={() => this._fetch()}
            language={this.props.user.language}
            contactForm={this.props.documents_show.form}
            colorGrey={this.props.colorGrey}
            data={this.state.modalData}
            errors={this.props.errors}
            closeModal={(e) => this._closeModal(e)}
            uploadResource={(data) => this._uploadResource(data)}
            resources={this.state.resources}
            resourceToSelectDestroy={this.props.assignData.id == "contact"}
            resourceDestroyAction={(url) =>
              this._destroyAssets(url, this.state)
            }
            deleteImage={(id) =>
              this.props.destroyImageAsset(
                this.props.authentication_token,
                () => "",
                () => "",
                id
              )
            }
            polymorphic={
              this.props.assignData.id == "contact"
                ? "resourceable_"
                : "ownerable_"
            }
            goToResource={(asset) => this._goToResource(asset)}
            deleteDocument={() =>
              this.setState({ showDeleteModalDocument: true })
            }
            destroyAssetAssociated={() =>
              this._destroyAssetAssociated(this.state.modalData)
            }
            buttons={this.props.internalization.buttons}
            showModalSessionExpire={(time_left, diffMins, checkTime) =>
              this.props.showModalSessionExpire(time_left, diffMins, () =>
                checkTime()
              )
            }
          />
        )}
        {this.state.showSuccesModal && (
          <AlertModal
            isShowed={this.state.showSuccesModal}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.setState({
                showSuccesModal: false,
                modal_action: undefined,
                showModal: false,
              });
              this.state.modal_action
                ? this.state.modal_action()
                : this._fetch();
            }}
            textButton={this.props.internalization.buttons.ok}
          />
        )}
        {this.state.showDeleteModalDocument && (
          <AlertModal
            isShowed={this.state.showDeleteModalDocument}
            title={this.props.internalization.modals.document.delete.title}
            msg={this.props.internalization.modals.document.delete.body}
            action={() => {
              !this.state.loading &&
                this.setState(
                  {
                    showDeleteModal: false,
                    loading: true,
                  },
                  () =>
                    this.props.destroyAsset(
                      this.props.authentication_token,
                      () => this._deleteDocumentSuccess(),
                      () => alert("error"),
                      this.state.modalData.url,
                      "delete",
                      false
                    )
                );
            }}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={true}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={false}
            actionCancelButton={() => {
              this.setState({
                showDeleteModalDocument: false,
              });
            }}
          />
        )}

        {this.state.showNavigateModal && (
          <AlertModal
            isShowed={this.state.showNavigateModal}
            title={this.props.internalization.alerts.navigate_to_asset}
            msg={
              this.props.internalization.alerts.navigate_to +
              " " +
              this.state.asset.name
            }
            action={() => this._navigateToAsset(this.state)}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={false}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={true}
            actionCancelButton={() => {
              this.setState({
                showNavigateModal: false,
              });
            }}
          />
        )}
        {this.state.showRecoverAlert && (
          <AlertModal
            isShowed={this.state.showRecoverAlert}
            title={this.props.internalization.modals.file.recover.title}
            msg={this.props.internalization.modals.file.recover.body}
            action={() => {
              this._recoverObject(this.state.recoverObject);
            }}
            textButton={this.props.internalization.buttons.recover}
            textButtonAlertColor={false}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={false}
            thirdButton={true}
            textButtonThird={this.props.internalization.buttons.destroy}
            textButtonAlertColorThird={true}
            actionCancelButton={() => {
              this.setState({
                showRecoverAlert: false,
              });
            }}
            actionThirdButton={() => {
              this._deleteObject(this.state.recoverObject);
            }}
          />
        )}
        {this.state.showDestroyPermanentlyAlert && (
          <AlertModal
            isShowed={this.state.showDestroyPermanentlyAlert}
            title={this.props.internalization.modals.file.delete.title}
            msg={this.props.internalization.modals.file.delete.body}
            action={() => {
              this._deletePermanentlyCall(this.state.deletePermanently);
            }}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={true}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={false}
            actionCancelButton={() => {
              this.setState({
                showDestroyPermanentlyAlert: false,
              });
            }}
          />
        )}
        {this.state.showDeleteModal && (
          <AlertModal
            isShowed={this.state.showDeleteModal}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.props.destroyAsset(
                this.props.authentication_token,
                () => this.state.modal_action(),
                () => alert("error"),
                this.state.modal_url,
                "DELETE"
              );
              this.setState({
                showDeleteModal: false,
              });
            }}
            textButton="Yes"
            textButtonAlertColor={true}
            cancelButton={true}
            cancelTextButton="Cancel"
            cancelButtonAlertColor={false}
            actionCancelButton={() => {
              this.setState({
                showDeleteModal: false,
              });
            }}
          />
        )}
        {this.state.destoyedSuccess && (
          <AlertModal
            isShowed={this.state.destoyedSuccess}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.state.modal_action();
            }}
            textButton={this.props.internalization.buttons.ok}
          />
        )}
        {this.props.assignData.modal &&
          Object.keys(this.props.documents_show).length > 0 &&
          this.props.documents_show.data.length > 0 && (
            <div>
              <button
                className={`btn ${this.props.assignData.id == "tenant" ? "secondary" : "primary" }`}
                onClick={() => {
                  this.removeBodyClass();
                  this._saveIds(this.props.assignData.id, this.state);
                }}
              >
                {this.props.assignData.id == "tenant" ? this.props.internalization.buttons.destroy : this.props.internalization.buttons.save}
              </button>
            </div>
          )}

        {this.state.showImageModal === true && (
          <Modal
            modalType="zoom-images"
            showBackButton={true}
            backAction={(e) => this._hideModal(e)}
            buttonText={this.props.internalization.buttons.back}
            paddingS={true}
            paddingBody={true}
            heightAuto={true}
          >
            
              {this.state.imagesToZoom.map((img, index) => {
                return (
                  <>
                  <div className="zoom-images__tools">
                    {
                      img.url_asset.resource &&
                      <div 
                          className="zoom-images__tools-icon"
                          onClick={() => this._showNavigateModalImg(img)}>
                          <Icon
                            width={"20px"}
                            height={"20px"}
                            name={"Link"}
                            color={"#408197"}
                            noWrapper={true}
                          />
                        </div>
                    }
                      
                      <div
                        className="zoom-images__tools-icon"
                        onClick={() => this._deleteImage(img.id)}
                      >
                        <Icon
                          width={"20px"}
                          height={"20px"}
                          noWrapper={true}
                          color={"#f3b01c"}
                          name="Garbage"
                          orientation={"right"}
                        />
                      </div>
                    </div>
                  <div className="zoom-images__wrapper">
                    <div className="zoom-images__wrapper-img">
                      <img
                        name={img.id}
                        key={index}
                        className="img-carousel"
                        src={img.urls.large}
                        alt=""
                      />
                    </div>
                  </div>
                  </>
                );
              })}
           
          </Modal>
        )}

        {this.state.showDeleteModalImage && (
          <AlertModal
            isShowed={this.state.showDeleteModalImage}
            title={this.props.internalization.modals.deleted_image.title}
            msg={this.props.internalization.modals.deleted_image.body}
            action={() => {this._fetch()}}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={true}
            cancelButton={false}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={false}
            actionCancelButton={() => {
              this.setState({
                showDeleteModalImage: false,
              });
            }}
          />
        )}

        {this.state.showNavigateImageModal && (
          <AlertModal
            isShowed={this.state.showNavigateImageModal}
            title={this.props.internalization.alerts.navigate_to_asset}
            action={() => this._navigateToImg(this.state)}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={false}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={true}
            actionCancelButton={() => {
              this.setState({
                showNavigateImageModal: false,
              });
            }}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    documents_show: state.documents.documents_show,
    authentication_token: state.session.authentication_token,
    balance_detail: state.balance.balance_detail,
    documents_detail: state.documents.documents_detail,
    internalization: state.session.internalization,
    errors: state.session.errors,
    user: state.session.user,
    permits: state.session.permits,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDocShow: (authentication_token, success, error, url) =>
      dispatch(getDocShow(authentication_token, success, error, url)),
    getContact: (authentication_token, success, error, url) =>
      dispatch(getContact(authentication_token, success, error, url)),
    destroyContactAsset: (authentication_token, success, error, url) =>
      dispatch(destroyContactAsset(authentication_token, success, error, url)),
    getDocNew: (
      authentication_token,
      parent_category,
      category_id,
      success,
      error,
      url
    ) =>
      dispatch(
        getDocNew(
          authentication_token,
          parent_category,
          category_id,
          success,
          error,
          url
        )
      ),
    cleanDocShow: () => dispatch(cleanDocShow()),
    createAssetResource: (
      authentication_token,
      success,
      error,
      url,
      method,
      attributes
    ) =>
      dispatch(
        createAssetResource(
          authentication_token,
          success,
          error,
          url,
          method,
          attributes
        )
      ),
    createContactAsset: (
      authentication_token,
      success,
      error,
      id,
      resourceable_id,
      resourceable_type
    ) =>
      dispatch(
        createContactAsset(
          authentication_token,
          success,
          error,
          id,
          resourceable_id,
          resourceable_type
        )
      ),
    destroyAssignAssetByResourceable: (
      authentication_token,
      success,
      error,
      id,
      resourceable_id,
      resourceable_type,
      url,
      type
    ) =>
      dispatch(
        destroyAssignAssetByResourceable(
          authentication_token,
          success,
          error,
          id,
          resourceable_id,
          resourceable_type,
          url,
          type
        )
      ),
    createDocumentAsset: (
      authentication_token,
      success,
      error,
      id,
      resourceable_id,
      resourceable_type
    ) =>
      dispatch(
        createDocumentAsset(
          authentication_token,
          success,
          error,
          id,
          resourceable_id,
          resourceable_type
        )
      ),
    createImageAsset: (
      authentication_token,
      success,
      error,
      images,
      ownerable_id,
      ownerable_type
    ) =>
      dispatch(
        createImageAsset(
          authentication_token,
          success,
          error,
          images,
          ownerable_id,
          ownerable_type
        )
      ),
    getBCDetail: (authentication_token, success, error, url, id) =>
      dispatch(getBCDetail(authentication_token, success, error, url, id)),
    destroyImageAsset: (authentication_token, success, error, id) =>
      dispatch(destroyImageAsset(authentication_token, success, error, id)),
    getDocDetail: (authentication_token, success, error, url) =>
      dispatch(getDocDetail(authentication_token, success, error, url)),
    destroyAsset: (authentication_token, success, error, url, method, force) =>
      dispatch(
        destroyAsset(authentication_token, success, error, url, method, force)
      ),
    updateDoc: (authentication_token, attributes, success, error, url) =>
      dispatch(
        updateDoc(authentication_token, attributes, success, error, url)
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsShowScreen);
