// Dependencies
import React from 'react';

const ClosedFolder = ({ width, height, color, bgColor }) => {

  return (

    <svg
      width={width ? width : '30px'}
      height={height ? height : '30px'}
      viewBox="0 0 30 30"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons-/-docs-/-folder">
          <g id="doc-folder">
            <circle id="Combined-Shape" fill={bgColor ? bgColor : "#80abba"} cx="15" cy="15" r="15"></circle>
            <path d="M13.5,9 L9,9 C8.175,9 7.5,9.675 7.5,10.5 L7.5,19.5 C7.5,20.325 8.175,21 9,21 L21,21 C21.825,21 22.5,20.325 22.5,19.5 L22.5,12 C22.5,11.175 21.825,10.5 21,10.5 L15,10.5 L13.5,9 L13.5,9 Z" id="Shape" fill={color ? color : '#FFFFFF'}></path>
          </g>
        </g>
      </g>
    </svg>

  );

}

export default ClosedFolder;
