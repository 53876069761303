import React, { useState, useEffect, useRef } from "react";
import TextEllipsis from "react-text-ellipsis";
import classNames from "classnames";

//Helpers
import Icon from "./icons";
import TableSearcher from "./TableSearcher";
import Pagination from "./pagination";
import EmptySearch from "./emptySearch";

import man from "../assets/images/avatars/0.png";
import woman from "../assets/images/avatars/1.png";

const Table = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [widthHeaderCell, setWidthHeaderCell] = useState(182);
  const [showSecurity, setShowSecurity] = useState(false);

  const tableRef = useRef();
  const headerCell = useRef();

  useEffect(() => {
    if (headerCell.current) {
      setWidthHeaderCell(headerCell.current.offsetWidth);
    }

    window.addEventListener("resize", () => {
      if (headerCell.current) {
        setWidthHeaderCell(headerCell.current.offsetWidth);
      }
    });
    tableRef.current.style.opacity = 1;
  }, []);

  useEffect(() => {
    tableClasses();
  }, [props.selectedIndex]);

  const tableClasses = () => {
    let cl = classNames(
      "c-table",
      props.selectedIndex >= 0 && "has-row-selected",
      props.hasFirstColumnBig && "is-first-column-big",
      props.marginRightFirstColumn && "is-first-column-margin",
    );

    return cl;
  };

  const headerCellsClasses = (index, isTag, key) => {
    let cl = classNames(
      "c-table__head-cell",
      isTag && "c-table__head-cell--tag",
      index === 0 && "c-table__head-cell--first",
      key === "date_uploaded" && "c-table__head-cell--date-uploaded",
      key === "url_avatar" && "c-table__head-cell--avatar",
    );

    return cl;
  };

  const rowClasses = (index, row) => {
    let cl = classNames(
      "c-table__row",
      isTotals() && "c-table__row c-table__row--totals",
      props.selectedIndex === index && "is-selected",
      props.fromSearch !== undefined && row[0].entity_id+"" === props.fromSearch+"" && "preselected"
    );
    return cl;
  };

  const rowCellClasses = (value, index) => {
    let cl = classNames(
      "c-table__cell",
      value.key === "date_uploaded" && "c-table__cell--date-uploaded",
      value.key === "url_avatar" && "c-table__cell--avatar",
    );
    return cl;
  };

  const rowCellTextClasses = (indexRows, indexRow, value) => {
    let cl = classNames(
      "c-table__text",
      value.highlighted && "c-table__text--highlighted",
      ["email", "alert_title"].includes(value.key) && "c-table__text--ellipsis"
    );
    return cl;
  };

  const cellHeaderTextClasses = (tooltip) => {
    let cl = classNames(
      "c-table__text",
      "c-table__head-cell-text",
      tooltip && "c-table__text--tooltip"
    );
    return cl;
  };

  const isTotals = () => {
    return props.dataTable.rows.find((row) => row[0].value === "Total");
  };

  const isHighlighted = (index) => {
    let rowIndex;

    props.dataTable.rows.map((rows, indexRows) =>
      rows.map((row) => {
        if (row.iconLink) {
          rowIndex = indexRows;
        }
      })
    );

    return rowIndex;
  };

  const handleTooltip = (e, index) => {
    e.stopPropagation();

    if (showTooltip === false || showTooltip !== index) {
      setShowTooltip(index);
    } else {
      setShowTooltip(false);
    }
  };

const handleSecurity = (e, entity_id) => {
    e.stopPropagation();

    if (showSecurity === false || showSecurity !== entity_id) {
      setShowSecurity(entity_id);
    } else {
      setShowSecurity(false);
    }
  }

  const getWidthHeaderCell = () => {
    return {
      width: `${widthHeaderCell * 3 - 16}px`,
      right: `${widthHeaderCell + 8}px`,
    };
  };

  const _navigateToAsset = (row) => {
    props.history.push("balance-detail?url=" + row.link, {
      url: "balance_principal/alternative_assets/",
      id: row.id,
    });
  };

  const _isAction = (key) => {
    return ["url", "url_link", "unlink"].includes(key);
  };

  useEffect(() => {
    checkWidthToStikcyFirstColumn()
  }, [])

  const checkWidthToStikcyFirstColumn = () => {
    let wrapperWidth = document.querySelector(".main-wrapper").offsetWidth;
    let tableWidth = tableRef.current.offsetWidth;

    if(tableWidth > wrapperWidth) {
      tableRef.current.classList.add("is-first-column-sticky")
    }
  }


  return (
    <>
      {props.search && props.showSearcher && (
        <TableSearcher
          originData={props.dataTable.rows}
          searchText={props.fromSearchText}
          
          search={(text) => props.search(text)}
          result={(result) => ""}
        />
      )}
      {/*if(props.forceShow || (header.show === true || props.special === true) && header.key !== 'url' && header.key !== 'url_link') {*/}

      <div
        className={tableClasses()}
        style={props.fullWidth === true ? { width: "100%" } : {}}
        ref={tableRef}
      >
        <div className="c-table__head">
          <div className="c-table__head-row">
            {props.dataTable.headerTag && (
              <div
                className="c-table__tag"
                style={{
                  width: getWidthHeaderCell().width,
                  right: getWidthHeaderCell().right,
                }}
              >
                <span className="c-table__tag__text">
                  {props.dataTable.extra_data["total_weighted_return"].title}
                </span>
                <span
                  className="wrapper-helper height-auto no-margin"
                  onClick={(e) => handleTooltip(e, -1)}
                >
                  <div className="helper-text white">
                    <Icon name="InfoTooltip" noWrapper={true} />
                  </div>
                  {showTooltip === -1 && (
                    <div
                      className="container-tooltip top container-tooltip--no-triangle"
                      onClick={(e) => handleTooltip(e, false)}
                    >
                      <span className="tooltip-title">
                        {
                          props.dataTable.extra_data["total_weighted_return"]
                            .title
                        }
                      </span>
                      <span
                        className="tooltip-text"
                        dangerouslySetInnerHTML={{
                          __html:
                            props.dataTable.extra_data["total_weighted_return"]
                              .tooltip_text,
                        }}
                      ></span>
                    </div>
                  )}
                </span>
              </div>
            )}

            {props.dataTable.headers &&
              props.dataTable.headers.map((header, indexHead) => {
                let tooltipWidth = header.tooltip ? 40 : 0;
                let sortWidth = header.sort ? 24 : 0;

                if (props.forceShow || (header.show && header.key !== "url")) {
                  return (
                    <div
                      key={`${indexHead}-indexHead-${props.selectedIndex}`}
                      ref={headerCell}
                      className={headerCellsClasses(
                        indexHead,
                        header.tag,
                        header.key
                      )}
                      style={{
                        paddingLeft: (tooltipWidth + sortWidth) / 2,
                        paddingRight: (tooltipWidth + sortWidth) / 2,
                      }}
                    >
                      <div
                        className={cellHeaderTextClasses(header.tooltip)}
                        style={{
                          maxWidth: `calc(100% - (${tooltipWidth}px + ${sortWidth}px))`,
                        }}
                      >
                        <span>{header.title}</span>
                      </div>

                      <div className="c-table__head-actions">
                        {header.tooltip && (
                          <span
                            className="wrapper-helper no-margin"
                            onClick={(e) => handleTooltip(e, indexHead)}
                          >
                            <div className="helper-text white">
                              <Icon name="InfoTooltip" noWrapper={true} />
                            </div>
                            {showTooltip === indexHead && (
                              <div
                                className="container-tooltip top container-tooltip--no-triangle"
                                onClick={(e) => handleTooltip(e, false)}
                              >
                                <span className="tooltip-title">
                                  {header.title}
                                </span>
                                <span className="tooltip-text">
                                  {header.tooltip_text}
                                </span>
                              </div>
                            )}
                          </span>
                        )}

                        {header.sort && (
                          <div className="c-table__head-sort">
                            <div
                              className="c-table__head-sort--up"
                              onClick={(e) => alert("Sorting up...")}
                            >
                              <Icon
                                name="SingleArrow"
                                orientation="up"
                                noWrapper={true}
                              />
                            </div>
                            <div
                              className="c-table__head-sort--down"
                              onClick={(e) => alert("Sorting down...")}
                            >
                              <Icon
                                name="SingleArrow"
                                orientation="down"
                                noWrapper={true}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              })}

            {props.action && props.action.title && (
              <div
                className="c-table__head-cell c-table__head-cell--last"
                ref={headerCell}
              >
                {/* <span className={cellHeaderTextClasses()}> {action.title} </span> */}
                <span className={cellHeaderTextClasses()}></span>
              </div>
            )}
          </div>
        </div>

        <div className="c-table__body">
          {props.dataTable.rows &&
            props.dataTable.rows.map((row, indexRows) => {
              let element = {};
              let actions = [];

              return (
                <div
                  key={`${indexRows}-indexHead-${props.selectedIndex}`}
                  className={rowClasses(indexRows, row)}
                >
                  {row.map((value, indexRow) => {
                    element[value.key] = value.value;

                    if (_isAction(value.key)) {
                      actions.push(value);
                    }

                    if ( props.forceShow || ((value.show === true || props.special === true) && value.key !== "url" && !_isAction(value.key))) {
                      return (
                        <div
                          onClick={(e) => props.onClickSecurity ? handleSecurity(e, value.entity_id) : ""}
                          key={`${indexRow}-indexRow-${props.selectedIndex}`}
                          className={rowCellClasses(value, indexRow)}
                          style={props.onClickSecurity ? {cursor: 'pointer'} : {}}
                        >
                          {value.key === "url_avatar" && value.value.url && (
                            <img
                              alt="avatar"
                              src={value.value.url}
                              className="c-table__avatar"
                            />
                          )}
                          {value.key === "url_avatar" && !value.value.url && (
                            <img
                              alt="default avatar"
                              src={
                                value.value.avatar_default_id === 0
                                  ? man
                                  : woman
                              }
                              className="c-table__avatar"
                            />
                          )}

                          {value.key === "url_link" && (
                            <a
                              href={value.value}
                              target={"_blank"}
                              className="c-table__icon-link"
                            >
                              <Icon
                                name="Link"
                                color={"#005774"}
                                width={"16px"}
                                height={"16px"}
                              />
                            </a>
                          )}
                          {value.key !== "url_avatar" && (
                            <>
                              {value.key === "phone_number" && (
                                <a
                                  href={`tef:${value.value}`}
                                  className={rowCellTextClasses(
                                    indexRows,
                                    indexRow,
                                    value
                                  )}
                                >
                                  {value.value}
                                </a>
                              )}
                              {value.key === "email" && (
                                <a
                                  href={`mailto:${value.value}`}
                                  className={rowCellTextClasses(
                                    indexRows,
                                    indexRow,
                                    value
                                  )}
                                >
                                  {value.value}
                                </a>
                              )}
                              {value.key !== "phone_number" &&
                                value.key !== "email" && (
                                  <TextEllipsis
                                    lines={
                                      ["name"].includes(value.key)
                                        ? 2
                                        : props.all_ellipsis === true
                                          ? 2
                                          : 10
                                    }
                                    tag={"span"}
                                    tagClass={rowCellTextClasses(
                                      indexRows,
                                      indexRow,
                                      value
                                    )}
                                    ellipsisChars={"..."}
                                    debounceTimeoutOnResize={200}
                                    useJsOnly={props.hasFirstColumnBig ? false : true}
                                  >
                                    {value.value}
                                  </TextEllipsis>
                                )}
                              {value.iconLink && (
                                <div
                                  className="c-table__icon-link"
                                  onClick={() => _navigateToAsset(value)}
                                >
                                  <Icon
                                    name="Link"
                                    color={"#005774"}
                                    width={"16px"}
                                    height={"16px"}
                                  />
                                </div>
                              )}
                              {value.tooltip && (
                                <span
                                  className="wrapper-helper no-margin"
                                  onClick={(e) =>
                                    handleTooltip(
                                      e,
                                      `${indexRow}-indexRow-${indexRows}`
                                    )
                                  }
                                >
                                  <div className="helper-text">
                                    <Icon name="InfoTooltip" noWrapper={true} />
                                  </div>
                                  {showTooltip ===
                                    `${indexRow}-indexRow-${indexRows}` && (
                                    <div
                                      className="container-tooltip top container-tooltip--no-triangle"
                                      onClick={(e) => handleTooltip(e, false)}
                                    >
                                      <span className="tooltip-title">
                                        {value.value}
                                      </span>
                                      <span className="tooltip-text">
                                        {value.tooltip_text}
                                      </span>
                                    </div>
                                  )}
                                </span>
                              )}
                              {
                                props.onClickSecurity && showSecurity === value.entity_id && props.security_info[value.entity_id] &&(
                                  <span
                                  className="wrapper-helper no-margin"
                                  onClick={(e) => setShowSecurity(false)}
                                  style={{minWidth: 0}}
                                >
                                    <div
                                      className="container-tooltip top container-tooltip--no-triangle"
                                      onClick={(e) => setShowSecurity(false)}
                                      style={{left: 20, top: 0}}
                                    >
                                      <span className="tooltip-title">{props.security_info[value.entity_id].name}</span>
                                      { props.security_info[value.entity_id].ticker_symbol && <span className="tooltip-text"><span style={{fontFamily: 'Averta-Bold'}}> Ticker Symbol:</span> {props.security_info[value.entity_id].ticker_symbol}</span>}
                                      { props.security_info[value.entity_id].cusip && <span className="tooltip-text"><span style={{fontFamily: 'Averta-Bold'}}> CUSIP:</span>  {props.security_info[value.entity_id].cusip}</span>}
                                      { props.security_info[value.entity_id].isin && <span className="tooltip-text"><span style={{fontFamily: 'Averta-Bold'}}> ISIN:</span>  {props.security_info[value.entity_id].isin}</span>}
                                    </div>
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      );
                    }

                    if ( props.action && props.action.title && row.length - 1 === indexRow) {
                      return (
                        <div
                          key={`${indexRow}-indexRow-${props.selectedIndex}`}
                          className="c-table__cell c-table__cell--is-last"
                        >
                          {actions.map((value) => {
                            return (
                              <>
                                {value.key === "url" && (
                                  <>
                                    {props.action.show && (
                                      <div
                                        onClick={
                                          props.action && props.action.show
                                            ? () => props.action.show(element)
                                            : ""
                                        }
                                        className="c-table__icon-link"
                                      >
                                        <Icon
                                          name="Eye"
                                          color={"#005774"}
                                          width={"16px"}
                                          height={"16px"}
                                        />
                                      </div>
                                    )}
                                    {props.action.edit && (
                                      <div
                                        onClick={
                                          props.action && props.action.show
                                            ? () => props.action.edit(element)
                                            : ""
                                        }
                                        className="c-table__icon-link"
                                      >
                                        <Icon
                                          name="Edit"
                                          color={"#f3b01c"}
                                          width={"16px"}
                                          height={"16px"}
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                                {value.key === "url_link" && (
                                  <a
                                    href={value.value}
                                    target={"_blank"}
                                    className="c-table__icon-link"
                                  >
                                    <Icon
                                      name="Link"
                                      color={"#005774"}
                                      width={"16px"}
                                      height={"16px"}
                                    />
                                  </a>
                                )}
                                {props.action.unlink && value.key === "unlink" && (
                                  <div
                                    onClick={() => props.action.unlink(element)}
                                    className="c-table__icon-link"
                                  >
                                    <Icon
                                      name="Unlink"
                                      color={"#f3b01c"}
                                      width={"16px"}
                                      height={"16px"}
                                    />
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      );
                    }
                  })}
                </div>
              );
            })}
          {props.dataTable.rows && props.dataTable.rows.length === 0 && (
            <EmptySearch nameIcon="BoxEmpty" text="No data" />
          )}
        </div>
      </div>
    </>
  );
};

export default Table;
