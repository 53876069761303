import { HOST_URL, URL_VERSION } from '../constants';

import get_header from "./api_header";

export function newsSuccess(payload) {
  
  return {
      type: 'NEWS',
      payload
  }
}

export function getNewsSuccess(payload) {
  return {
      type: 'NEW',
      payload
  }
}

export function indecesSuccess(payload) {
  
  return {
      type: 'INDECES',
      payload
  }
}

export function currenciesSuccess(payload) {
  
  return {
      type: 'CURRENCIES',
      payload
  }
}

export function allMarketSuccess(payload) {
  
  return {
      type: 'ALL_MARKET',
      payload
  }
}

export function allNews(authentication_token, success,error) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/market/news', {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {

          dispatch(newsSuccess(responseJson.news));
          success()
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}


export function allIndeces(authentication_token, success,error, id) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/market/market_indeces', {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
        
          dispatch(indecesSuccess(responseJson.market_index));
          success()
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function allCurrencies(authentication_token, success,error, id) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/market/currencies', {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
        
          dispatch(currenciesSuccess(responseJson.currencies_index));
          success()
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function allMarket(authentication_token, success,error, id) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/market/home', {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          dispatch(allMarketSuccess(responseJson));
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}



