import { HOST_URL, URL_VERSION } from '../constants';

import get_header from './api_header'
import {parseHeaders} from './api_header'

export function destroyImageAsset(authentication_token, success, error, id) {
  
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + "images/images/" + id, {
      method: 'DELETE',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      
      if (response.ok) {
        response.json().then((responseJson) => {
       
          success()
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function createImageAsset(authentication_token, success, error, images, ownerable_id, ownerable_type) {
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + "/images/images", {
      method: 'POST',
      headers: get_header(authentication_token),
      body: JSON.stringify({ images: images, ownerable_id: ownerable_id, ownerable_type: ownerable_type})
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}
