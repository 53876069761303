// Dependencies
import React, {useState,} from "react";

// Components
import Icon from "./icons";

const HeaderNavigation = (props) => {

  if (props.menu === undefined) {
    return <div className="wrapper-preheader" />;
  }

  return (
    <React.Fragment>
      {props.assignedUsers.length > 1 && (
        <div className="c-header__content-block">
          <p className="o-color--orange">{props.nameCurrentUser}</p>
          <div className="c-header__content-navigation">
            <Icon
              name="ChangeUser"
              iconSmall={true}
              action={() => props.selectedUser()}
            />
            <div className="c-header__content-navigation-tooltip">
              <span className="c-header__content-navigation-tooltip-text">Switch User</span>
            </div>
          </div>
        </div>
      )}

      <div className="c-header__content-navigation">
        <Icon
          name="Notification"
          iconSmall={true}
          width={21}
          height={24}
          action={() => props.alertsAction()}
        />
        <div className="c-header__content-navigation-tooltip">
          <span className="c-header__content-navigation-tooltip-text">
            {props.menu.alerts}
          </span>
        </div>
      </div>
      <div className="c-header__content-navigation">
        <Icon
          name="LastActivity"
          iconSmall={true}
          width={24}
          height={24}
          strokeWidth={5}
          action={() => props.notificationsAction()}
        />
        <div className="c-header__content-navigation-tooltip">
          <span className="c-header__content-navigation-tooltip-text">
            {props.menu.last_activity}
          </span>
        </div>
      </div>
      <div className="c-header__content-navigation">
        <Icon
          name="Folder"
          iconSmall={true}
          width={24}
          height={16}
          action={() => props.documentsAction()}
        />
        <div className="c-header__content-navigation-tooltip">
          <span className="c-header__content-navigation-tooltip-text">
            {props.menu.files}
          </span>
        </div>
      </div>

      {
        props.financial_statements_enabled && 
          <div className="c-header__content-navigation">
            {props.newStatementLoaded && (
              <div className="c-header__content-navigation-alert-point"></div>
            )}
            <Icon
              name="DocOk"
              iconSmall={true}
              style={{width: 24, height: 28, paddingTop: 2}}
              width={24}
              height={26}
              action={() => {
                props.statementAction();
              }}
            />
            <div className="c-header__content-navigation-tooltip">
              <span className="c-header__content-navigation-tooltip-text">
                {props.menu.financial_statements}
              </span>
            </div>
          </div>        
      }


      <div className="c-header__content-navigation">
        <Icon
          name="Search"
          iconSmall={true}
          width={24}
          height={24}
          action={() => props.searchAction()}
        />
        <div className="c-header__content-navigation-tooltip">
          <span className="c-header__content-navigation-tooltip-text">
            {props.menu.search}
          </span>
        </div>
      </div>
      <div className="c-header__content-navigation">
        <Icon
          name="Home"
          iconSmall={true}
          width={24}
          height={24}
          action={() => props.history.push("/home")}
        />
        <div className="c-header__content-navigation-tooltip">
          <span className="c-header__content-navigation-tooltip-text">
            {props.menu.home}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeaderNavigation;
