// Dependencies
import React from "react";
import { connect } from "react-redux";

// Components
import Icon from "./icons";

const InProgress = (props) => {
  return (
    <div className="main-wrapper in-progress">
      <div className="content">
        <Icon name={props.insurance_user ? "LogoInsurance" : "Logo"} />
        <p className="text">In Progress</p>
        <p className="text">Coming Soon</p>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    insurance_user: state.session.insurance_user,
  };
}

export default connect(mapStateToProps, null)(InProgress);

