// Dependencies
import React from "react";
import { connect } from "react-redux";

// Components
import Icon from "./icons/";

const Footer = (props) => {
  return (
    <div className="wrapper-footer">
      <div>
        <span className="chart-text-caption grey">
          © Copyright {new Date().getFullYear()} Bulltick
        </span>
      </div>
      <div>
        {props.menu &&
          props.menu.map((element, index) => {
            return (
              <span key={`menu_element_${index}`}>
                <a target="_blank" className="title2" href={element.link}>
                  {element.title}
                </a>
              </span>
            );
          })}
      </div>

      <Icon
        name={props.insurance_user ? "LogoInsurance" : "Logo"}
        className="logo-small"
        width={96}
        height={31}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    insurance_user: state.session.insurance_user,
  };
}

export default connect(mapStateToProps, null)(Footer);
