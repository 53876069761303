import React from "react";

export function highlightWord(title, textSearch) {

  if (title && title !== "") {
    let index = title.toLowerCase().indexOf(textSearch.toLowerCase());
    if (index >= 0) {
      return (
        <React.Fragment>
          {title.substring(0, index)}
          <span class="highlight-text">
            {title.substring(index, index + textSearch.length)}
          </span>
          {title.substring(index + textSearch.length)}
        </React.Fragment>
      );
    } else {
      return title;
    }
  }else {
    return title
  }
}
