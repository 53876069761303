import { BALANCE_VALUATIONS } from "../constants";

const initialState = {
  balance_index: {},
  balance_show: {},
  balance_detail: {},
  balance_valuations: {}
};

export default function balanceReducer(state = initialState, action) {
  switch (action.type) {
    case "BALANCE_INDEX":
      return Object.assign({}, state, {
        balance_index: action.payload
      });
    case "BALANCE_SHOW":
      return Object.assign({}, state, {
        balance_show: action.payload
      });
    case "BALANCE_SHOW_FORM":
      let balance_show = state.balance_show
      balance_show.form = action.payload.form
      balance_show.multiple_new = action.payload.multiple_new
      balance_show.actions = action.payload.actions
       return Object.assign({}, state, {
        balance_show: balance_show
      });   
    case "BALANCE_DETAIL":
      return Object.assign({}, state, {
        balance_detail: action.payload
      });
    case "UPDATE_ASSETS":
      return Object.assign({}, state, {
        balance_detail: action.response
      });
    case BALANCE_VALUATIONS:
      return Object.assign({}, state, {
        balance_valuations: action.payload
      });
    case "BALANCE_DETAIL_TAB": 
      let new_balance_detail = state.balance_detail;
      
      if(Object.keys(action.payload).length === 0 ){
         new_balance_detail.tablesv2[action.source].rows = [];
      }else{
        new_balance_detail.tablesv2 = {...new_balance_detail.tablesv2, ...action.payload};
      }
      
      new_balance_detail["force_update"] = new_balance_detail["force_update"] !== undefined ? !new_balance_detail["force_update"] : true
      console.log("new_balance_detail", new_balance_detail)
      return Object.assign({}, state, {
        balance_detail: new_balance_detail
      });
    default:
      return state;
  }
}
