// Dependencies
import React from "react";

const Calendar = ({ width='40px', height='40px', color="#80AAB9"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 41"
    >
      <g clip-path="url(#clip0_175_19)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.43 37.82C1.44 38.77 2.21 39.55 3.16 39.56H37.34C38.29 39.55 39.06 38.78 39.07 37.82V16.07H1.45L1.43 37.82ZM3.16 41C1.41 41 0 39.57 0 37.82V7.05999C0 5.30999 1.42 3.88999 3.16 3.88999H8.56V0.519987C8.69 0.119987 9.11 -0.0900131 9.51 0.0399869C9.74 0.109987 9.92 0.289987 9.99 0.519987V9.31999C9.99 9.71999 9.67 10.04 9.28 10.04C8.89 10.04 8.57 9.71999 8.57 9.31999V5.31999H3.16C2.21 5.32999 1.44 6.09999 1.43 7.05999V14.61H39.05V7.04999C39.04 6.09999 38.27 5.31999 37.32 5.30999H33.94V3.86999H37.34C39.08 3.86999 40.5 5.28999 40.5 7.03999V37.79C40.5 39.54 39.09 40.97 37.34 40.97H3.16V41Z" fill={color}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M28.81 9.99999C28.42 9.99999 28.1 9.68999 28.08 9.27999V5.29999H24.01V3.86999H28.12V0.519987C28.25 0.119987 28.66 -0.0900131 29.04 0.0399869C29.26 0.109987 29.43 0.289987 29.51 0.519987V9.28999C29.49 9.66999 29.19 9.97999 28.82 9.99999H28.81Z" fill={color}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3 10C18.92 9.98002 18.62 9.67002 18.61 9.29002V5.32002H14.51V3.89002H18.61V0.530018C18.73 0.130018 19.14 -0.0899817 19.53 0.0300183C19.76 0.100018 19.94 0.290018 20.01 0.530018V9.29002C19.99 9.68002 19.68 9.98002 19.31 10H19.3Z" fill={color}/>
        <path d="M9.5 27H7.5V28.5H9.5V27Z" fill={color}/>
        <path d="M15.5 27H13.5V28.5H15.5V27Z" fill={color}/>
        <path d="M21 27H19.5V28.5H21V27Z" fill={color}/>
        <path d="M27 27H25.5V28.5H27V27Z" fill={color}/>
        <path d="M33 27H31.5V28.5H33V27Z" fill={color}/>
        <path d="M15.5 21H13.5V22.5H15.5V21Z" fill={color}/>
        <path d="M21 21H19.5V22.5H21V21Z" fill={color}/>
        <path d="M27 21H25.5V22.5H27V21Z" fill={color}/>
        <path d="M33 21H31.5V22.5H33V21Z" fill={color}/>
        <path d="M9.5 33H7.5V34.5H9.5V33Z" fill={color}/>
        <path d="M15.5 33H13.5V34.5H15.5V33Z" fill={color}/>
        <path d="M21 33H19.5V34.5H21V33Z" fill={color}/>
        <path d="M27 33H25.5V34.5H27V33Z" fill={color}/>
      </g>
      <defs>
        <clipPath id="clip0_175_19">
          <rect width="40.5" height="41" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Calendar;
