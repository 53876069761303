import * as go from "gojs";

// Images
import editButtonImage from "../../assets/images/goJs/edit_button.png";
import goButtonImage from "../../assets/images/goJs/go_button.png";

import icon from "../../assets/images/goJs/icon_trust.png";

const $ = go.GraphObject.make;

const categoryItem = (
  diagram,
  collapseButtonClick,
  editButtonClick,
  goButtonClick,
  nodeStyle,
  badgeStyle,
  titleStyle,
  textStyle,
  makePort,
  colors,
  avoidNodeOverlap,
  canReadElement
) => {
  return diagram.nodeTemplateMap.add(
    "Trust", // Avoid to default category
    $(
      go.Node,
      "Table",
      nodeStyle(),
      {
        selectionAdornmentTemplate: $(
          go.Adornment,
          "Auto",
          $(go.Shape, "RoundedRectangle", {
            strokeWidth: 0,
            parameter1: 18,
            fill: "#90B77D",
            opacity: 0.25,
          }),
          $(go.Placeholder)
        ), // end Adornment
      },
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      $(
        go.Shape,
        "RoundedRectangle",
        {
          name: "Default shape",
          fill: colors.greyDarkMedium,
          strokeWidth: 0,
          alignment: go.Spot.TopRight,
        },
        new go.Binding("desiredSize", "isPalette", (isPalette) =>
          isPalette ? new go.Size(222, 40) : new go.Size(224, 86)
        ),
        new go.Binding("parameter1", "isPalette", (isPalette) => (isPalette ? 4 : 18))
      ),
      $(
        go.Shape,
        "Rectangle",
        {
          name: "Default shape",
          fill: colors.greyDarkMedium,
          strokeWidth: 0,
          alignment: go.Spot.TopLeft,
        },
        new go.Binding("desiredSize", "isPalette", (isPalette) =>
          isPalette ? new go.Size(32, 40) : new go.Size(32, 86)
        )
      ),
      $(
        go.Shape,
        "Rectangle",
        {
          name: "Default shape",
          strokeWidth: 0,
          alignment: go.Spot.TopLeft,
          fill: "#90B77D",
        },
        new go.Binding("desiredSize", "isPalette", (isPalette) =>
          isPalette ? new go.Size(4, 40) : new go.Size(4, 86)
        )
      ),
      $(
        go.Panel,
        "Horizontal",
        {
          width: 208,
        },
        new go.Binding("margin", "isPalette", (isPalette) =>
          isPalette ? new go.Margin(2, 8, 0, 8) : new go.Margin(20, 8, 0, 8)
        ),
        $(
          go.Panel,
          "Vertical",
          new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 24 : 48)),
          $(
            go.Picture,
            new go.Binding("source", "", function (data) {
              if(canReadElement(data.class_name) || data.isPalette){
                return icon;
              }else{
                return ""
              }
              
            }),
            new go.Binding("desiredSize", "isPalette", (isPalette) =>
              isPalette ? new go.Size(24, 24) : new go.Size(48, 48)
            )
          )
        ),
        $(
          go.Panel,
          "Vertical",
          {
            margin: new go.Margin(10, 8, 0, 8),
          },
          new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 24 : 48)),
          $(go.TextBlock, titleStyle(), new go.Binding("text", "", function(data) {
              // Access the desired property from the node data
              if(canReadElement(data.class_name) || data.isPalette){
                return data.title
              }else{
                return ""
              }
            })
          ),
          $(go.TextBlock, textStyle(),  new go.Binding("text", "", function(data) {
            if(canReadElement(data.class_name) || data.isPalette){
              return  data.amount
            }else{
              return ""
            }
          }))
        )
      ),
      $(
        go.Panel,
        "Position",
        {
          alignment: go.Spot.TopRight,
        },
        new go.Binding("width", "isPalette", (isPalette) => (isPalette ? 0 : 212)),
        new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 0 : 24)),
        new go.Binding("margin", "isPalette", (isPalette) =>
          isPalette ? new go.Margin(0, 8, 0, 0) : new go.Margin(8, 8, 0, 0)
        ),
        $(
          go.Panel,
          "Button",
          {
            name: "Edit button",
            position: new go.Point(156, 0),
            width: 24,
            height: 24,
            cursor: "pointer",
            // margin: 0,
            click: editButtonClick,
          },
          $(go.Picture, editButtonImage, {
            width: 24,
            height: 24,
          })
        ),
        $(
          go.Panel,
          "Button",
          {
            name: "Go button",
            position: new go.Point(188, 0),
            width: 24,
            height: 24,
            cursor: "pointer",
            // margin: 0,
            click: goButtonClick,
          },
          $(go.Picture, goButtonImage, {
            width: 24,
            height: 24,
          })
        )
      ),
      { dragComputation: avoidNodeOverlap },

      // four named ports, one on each side:
      makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
      makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
      makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
      makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
    )
  );
};

export default categoryItem;
