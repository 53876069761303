import { HOST_URL, URL_VERSION } from "../constants";

import get_header from "./api_header";
import { setSession } from "./session";

export function selectUser(authentication_token, success, error, id) {
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + "profile/set_assigned_user/", {
      method: "PUT",
      headers: get_header(authentication_token),
      body: JSON.stringify({ user: { id: id } }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((responseJson) => {
            success();
            dispatch(setSession(responseJson.user));
          });
        } else {
          error();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function changeMirrorName(authentication_token, related_permit_id, name, success, error) {
  console.log(JSON.stringify({ related_permit: { name: name } }));
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + "related_permits/" + related_permit_id, {
      method: "PUT",
      headers: get_header(authentication_token),
      body: JSON.stringify({ related_permit: { name: name } }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((responseJson) => {
            success();
          });
        } else {
          error();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
