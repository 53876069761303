// Dependencies
import React from "react";

const Trust = (props) => {
  return (
    <svg
      width={props.width ? props.width : "46px"}
      height={props.height ? props.height : "49px"}
      viewBox="0 0 46 49"
    >
      
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Trust" transform="translate(-9.000000, -7.000000)" fill={props.color ? props.color : "#80abba"} fillRule="nonzero">
                <g id="noun_contract_2828538" transform="translate(9.000000, 7.000000)">
                    <path d="M31.4934307,48.350365 C31.1064282,48.350365 30.7927007,48.0366375 30.7927007,47.649635 C30.7927007,47.2626326 31.1064282,46.9489051 31.4934307,46.9489051 C33.8928459,46.9489051 35.8379562,45.0037948 35.8379562,42.6043796 L35.8379562,32.7007299 C35.8379562,32.3137275 36.1516837,32 36.5386861,32 C36.9256886,32 37.2394161,32.3137275 37.2394161,32.7007299 L37.2394161,42.6043796 C37.2394161,45.7777997 34.6668508,48.350365 31.4934307,48.350365 Z" id="Path"></path>
                    <path d="M36.5386861,25.4729927 C36.1516837,25.4729927 35.8379562,25.161191 35.8379562,24.7765641 L35.8379562,13.1694213 C35.8379562,12.7847944 36.1516837,12.4729927 36.5386861,12.4729927 C36.9256886,12.4729927 37.2394161,12.7847944 37.2394161,13.1694213 L37.2394161,24.7765641 C37.2394161,25.161191 36.9256886,25.4729927 36.5386861,25.4729927 Z" id="Path"></path>
                    <path d="M6.08029197,39.9416058 C5.69328952,39.9416058 5.37956204,39.6278784 5.37956204,39.2408759 L5.37956204,5.97956204 C5.40521579,2.79860024 7.99119753,0.233473198 11.1722628,0.233576642 L40.7430657,0.233576642 C41.1300681,0.233576642 41.4437956,0.547304117 41.4437956,0.934306569 C41.4437956,1.32130902 41.1300681,1.6350365 40.7430657,1.6350365 L11.1722628,1.6350365 C8.76517436,1.63490028 6.80662922,3.57260984 6.7810219,5.97956204 L6.7810219,39.2408759 C6.79514264,39.430699 6.72586361,39.6172554 6.59126755,39.7518515 C6.45667148,39.8864476 6.27011506,39.9557266 6.08029197,39.9416058 Z" id="Path"></path>
                    <path d="M41.5372263,13.6875912 L35.8379562,13.6875912 L35.8379562,5.13868613 C35.8379562,2.42966896 38.0340485,0.233576642 40.7430657,0.233576642 C43.4520829,0.233576642 45.6481752,2.42966896 45.6481752,5.13868613 L45.6481752,9.57664234 C45.6481752,11.8470567 43.8076407,13.6875912 41.5372263,13.6875912 Z M37.2394161,12.2861314 L41.5372263,12.2861314 C43.0336358,12.2861314 44.2467153,11.0730518 44.2467153,9.57664234 L44.2467153,5.13868613 C44.2467153,3.20367387 42.678078,1.6350365 40.7430657,1.6350365 C38.8080534,1.6350365 37.2394161,3.20367387 37.2394161,5.13868613 L37.2394161,12.2861314 Z" id="Shape"></path>
                    <path d="M30.7927007,48.350365 L7.1080292,48.350365 C3.26380484,48.350365 0.147445255,45.2340054 0.147445255,41.389781 L0.147445255,40.8291971 C0.147445255,39.5649891 1.17228834,38.540146 2.43649635,38.540146 L22.3839416,38.540146 C22.9868292,38.5400151 23.5643715,38.7826624 23.9862601,39.2133403 C24.4081486,39.6440183 24.6388375,40.2264403 24.6262774,40.8291971 C24.65194,44.2165611 27.4052395,46.9490023 30.7927007,46.9489051 L30.7927007,48.350365 Z M2.43649635,39.9416058 C2.20109252,39.9416058 1.97533021,40.0351196 1.80887457,40.2015753 C1.64241892,40.3680309 1.54890511,40.5937932 1.54890511,40.8291971 L1.54890511,41.389781 C1.57429762,44.4494208 4.04838939,46.9235126 7.1080292,46.9489051 L26.4014599,46.9489051 C24.414828,45.5431 23.2312174,43.2629089 23.2248175,40.8291971 C23.2254724,40.3566727 22.8558115,39.9664751 22.3839416,39.9416058 L2.43649635,39.9416058 Z" id="Shape"></path>
                    <path d="M29.1109489,42.1372263 C28.8014926,42.1256928 28.4984061,42.0459332 28.2233577,41.9036496 C27.688078,41.5412766 27.3997286,40.9105123 27.4759124,40.2686131 L27.9430657,37.0919708 L28.0364964,36.9985401 L39.1547445,19.4335766 L43.8729927,22.4233577 L32.6613139,40.1284672 L29.9985401,41.8569343 C29.7386231,42.0395856 29.4286253,42.1374796 29.1109489,42.1372263 Z M29.2978102,37.6058394 L28.8773723,40.4554745 C28.8773723,40.5956204 28.9240876,40.6890511 28.9708029,40.6890511 C29.0062754,40.7292731 29.0573196,40.7523153 29.1109489,40.7523153 C29.1645782,40.7523153 29.2156224,40.7292731 29.2510949,40.6890511 L31.6335766,39.1007299 L41.9576642,22.8905109 L39.5751825,21.3956204 L29.2978102,37.6058394 Z" id="Shape"></path>
                    <path d="M43.1255474,23.6379562 L38.4072993,20.6481752 L40.0423358,17.9854015 C40.3445971,17.5497045 40.791487,17.2352264 41.3036496,17.0978102 C41.834502,16.9984625 42.3833481,17.0982527 42.8452555,17.3781022 L44.1532847,18.2189781 C45.0769882,18.8341699 45.363349,20.0614303 44.8072993,21.0218978 L43.1255474,23.6379562 Z M40.3226277,20.1810219 L42.7051095,21.6759124 L43.5927007,20.2744526 C43.779026,19.9764417 43.6965022,19.5844536 43.4058394,19.3868613 L42.0978102,18.5459854 C41.9587226,18.4560324 41.7848028,18.4386404 41.6306569,18.4992701 C41.4748767,18.5097829 41.3346473,18.5974263 41.2569343,18.7328467 L40.3226277,20.1810219 Z" id="Shape"></path>
                    <path d="M27.8029197,32.7007299 L12.8540146,32.7007299 C12.4670121,32.7007299 12.1532847,32.3870025 12.1532847,32 C12.1532847,31.6129975 12.4670121,31.2992701 12.8540146,31.2992701 L27.8029197,31.2992701 C28.1899222,31.2992701 28.5036496,31.6129975 28.5036496,32 C28.5036496,32.3870025 28.1899222,32.7007299 27.8029197,32.7007299 Z" id="Path"></path>
                    <path d="M30.1386861,28.4963504 L12.8540146,28.4963504 C12.4670121,28.4963504 12.1532847,28.1826229 12.1532847,27.7956204 C12.1532847,27.408618 12.4670121,27.0948905 12.8540146,27.0948905 L30.1386861,27.0948905 C30.5256886,27.0948905 30.8394161,27.408618 30.8394161,27.7956204 C30.8394161,28.1826229 30.5256886,28.4963504 30.1386861,28.4963504 Z" id="Path"></path>
                    <path d="M30.1386861,24.2919708 L12.8540146,24.2919708 C12.4670121,24.2919708 12.1532847,23.9782433 12.1532847,23.5912409 C12.1532847,23.2042384 12.4670121,22.8905109 12.8540146,22.8905109 L30.1386861,22.8905109 C30.5256886,22.8905109 30.8394161,23.2042384 30.8394161,23.5912409 C30.8394161,23.9782433 30.5256886,24.2919708 30.1386861,24.2919708 Z" id="Path"></path>
                    <path d="M30.1386861,20.0875912 L12.8540146,20.0875912 C12.4670121,20.0875912 12.1532847,19.7738638 12.1532847,19.3868613 C12.1532847,18.9998589 12.4670121,18.6861314 12.8540146,18.6861314 L30.1386861,18.6861314 C30.5256886,18.6861314 30.8394161,18.9998589 30.8394161,19.3868613 C30.8394161,19.7738638 30.5256886,20.0875912 30.1386861,20.0875912 Z" id="Path"></path>
                    <path d="M30.1386861,15.8832117 L12.8540146,15.8832117 C12.4670121,15.8832117 12.1532847,15.5694842 12.1532847,15.1824818 C12.1532847,14.7954793 12.4670121,14.4817518 12.8540146,14.4817518 L30.1386861,14.4817518 C30.5256886,14.4817518 30.8394161,14.7954793 30.8394161,15.1824818 C30.8394161,15.5694842 30.5256886,15.8832117 30.1386861,15.8832117 Z" id="Path"></path>
                    <path d="M30.1386861,11.6788321 L12.8540146,11.6788321 C12.4670121,11.6788321 12.1532847,11.3651046 12.1532847,10.9781022 C12.1532847,10.5910997 12.4670121,10.2773723 12.8540146,10.2773723 L30.1386861,10.2773723 C30.5256886,10.2773723 30.8394161,10.5910997 30.8394161,10.9781022 C30.8394161,11.3651046 30.5256886,11.6788321 30.1386861,11.6788321 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default Trust;
