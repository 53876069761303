// Dependencies
import React from "react";
import Slider from "react-slick";

// Constants
import {internalization} from '../constants.js'

// Components
import Icon from "./icons";

const ModalDeleteAlerts = (props) => {
  let languageUserAgent = /^es/.test(props.language) ? "es" : "en";
  let reject_alert = internalization[languageUserAgent].alerts.reject_alert
  return (
    <div style={{textAlign: 'center'}}>
      <h2 className="headingH2 o-margin--bottom-size-s">{props.alerts_pending[0].invited_by} {reject_alert.title}</h2>
      <p className="title1">{props.alerts_pending[0].ownerable_title} | {props.alerts_pending[0].ownerable}</p>
      <p className="title2 o-margin--bottom-size-xs">{props.alerts_pending[0].title} | {props.alerts_pending[0].recurrence} | {props.alerts_pending[0].start_date}</p>
      <p className="body big o-margin--bottom-size-l">{reject_alert.question}</p>
      <div className="wrapper-tabs o-margin--top-size-m o-padding--bottom-size-s">
        <button className="btn secondary" onClick={() => props.updateDeletedFromSource(props.alerts_pending[0].id, {"alert": {deleted_from_source: true}}, () => alert("ok"), () => alert("ko"))}><span className="btn-text">{reject_alert.delete}</span></button>
        <button className="btn"onClick={() => props.updateDeletedFromSource(props.alerts_pending[0].id, {"alert": {deleted_from_source: null}}, () => alert("ok"), () => alert("ko"))}><span className="btn-text">{reject_alert.confirm}</span></button>
      </div>
      <p className="body small o-text-align--center">{reject_alert.footer}</p>
    </div>
  );
};

export default ModalDeleteAlerts;
