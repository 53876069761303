import * as go from "gojs";

// Images
import editButtonImage from "../../assets/images/goJs/edit_button.png";

const $ = go.GraphObject.make;

const categoryItem = (diagram, editButtonClick, nodeStyle, commentStyle, makePort, colors) => {
  return diagram.nodeTemplateMap.add(
    "Comment", // Avoid to default category
    $(
      go.Node,
      "Auto",
      nodeStyle(),
      // new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      // SHAPE
      $(go.Shape, "RoundedRectangle", {
        fill: "#fff",
        strokeWidth: 0,
      }),

      // BUTTON
      $(
        go.Panel,
        "Position",
        {
          height: 24,
          alignment: go.Spot.TopRight,
        },
        $(
          go.Panel,
          "Button",
          {
            name: "Edit button",
            width: 24,
            height: 24,
            cursor: "pointer",
            click: editButtonClick,
          },
          new go.Binding("width", "isPalette", (isPalette) => (isPalette ? 0 : 24)),
          new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 0 : 24)),
          $(go.Picture, editButtonImage, {
            width: 24,
            height: 24,
          })
        )
      ),
      $(
        go.TextBlock,
        commentStyle(),
        new go.Binding("margin", "isPalette", (isPalette) =>
          isPalette ? new go.Margin(8, 8, 8, 8) : new go.Margin(32, 8, 8, 8)
        ),
        new go.Binding("minSize", "isPalette", (isPalette) =>
          isPalette ? new go.Size(200, NaN) : new go.Size(32, NaN)
        ),

        new go.Binding("text", "title")
      ),
      new go.Binding("isLayoutPositioned", "isPalette", (isPalette) => (isPalette ? true : false))
      // // four named ports, one on each side:
      // makePort("T", go.Spot.Top, go.Spot.TopSide, false, true),
      // makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
      // makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
      // makePort("B", go.Spot.Bottom, go.Spot.BottomSide, false, false)
    )
  );
};

export default categoryItem;
