// Dependencies
import React, { useState, useEffect } from "react";
import TextEllipsis from "react-text-ellipsis";

// Components
import Icon from "./icons";
import NoResources from "./noResources";
import CustomAvatar from "./customAvatar";
import AddData from "./addData";
import Input from "./input";

const moment = require("moment");

const ContactForm = (props) => {
  const [edit, setEdit] = useState(
    props.data.code === "add_contact" ? false : true
  );
  const [manualDisabled, setManualDisabled] = useState(false);
  const [data, setData] = useState({});
  const [dataDeleted, setDataDeleted] = useState([]);
  const [elementsRequired, setElementsRequired] = useState({});
  const [allElementsRequired, setAllelementsRequired] = useState([]);

  useEffect(() => {
    getRequiredData(props.data.form.address.inputs);
    getRequiredData(props.data.form.inputs);
    getRequiredData(props.data.form.profile.inputs);
    createRequiredObj();
  }, []);

  useEffect(() => {
    if(props.data.edit_mode === true){
      setEdit(true)
      setManualDisabled(true)
    }
  }, []);
  const getRequiredData = (inputs) => {
    let _allElements = allElementsRequired;
    let items = inputs.filter((item) => item.required === true);
    if (items.length > 0) {
      _allElements.push(items);
    }

    setAllelementsRequired(_allElements);
  };

  const createRequiredObj = () => {
    allElementsRequired.map((item) => {
      item.map((elem) => {
        setElementsRequired({
          ...elementsRequired,
          [elem.id]: elem.required,
        });
      });
    });
  };

  const _newImage = (value, type) => {
    setData({
      ...data,
      ["images"]: [value],
    });
  };

  const _getInputValues = (e, element) => {
    setData({
      ...data,
      [element.id]: e.currentTarget.value,
    });

    if (element.required) {
      setElementsRequired({
        ...elementsRequired,
        [element.id]: e.currentTarget.value !== "" ? false : true,
      });
    }
  };

  const _getDataInputs = (added, type, deleteItem) => {
    let _added = added && JSON.parse(JSON.stringify(added));

    _added.length > 0 &&
      _added.map((item) => {
        delete item["order"];
        delete item["tag"];
        delete item["input"];
      });

    setData({
      ...data,
      [type]: _added,
    });
  };

  const _sendData = () => {
    var _data = data;
    _data["resourceable_id"] = props.identifier
      ? props.identifier.resourceable_id
      : "";
    _data["resourceable_type"] = props.identifier
      ? props.identifier.resourceable_type
      : "";

    if (_data.hasOwnProperty("special_date")) {
      if (_data.special_date.length > 0) {
        _data.special_date.map(
          (item) => {
            if(item.value ){
              item.value = moment(item.value).format("MM/DD/YYYY")}
            }
        );
      }
    }

    for (const prop in _data) {
      if (prop !== "address_contact") {
        if (["special_date", "phone", "email"].includes(prop)) {
          if (_data[prop].length > 0) {
            _data[prop].map((item) => {
              if (item.value === "") {
                item["destroy"] = true;
              } else if (item.value === "Invalid date") {
                item["destroy"] = true;
              }
            });
          }
        }
      } else {
        if (_data[prop].length > 0) {
          _data[prop].map((item) => {
            if (checkEmptyValues(item)) {
              item["destroy"] = true;
            }
          });
        }
      }
    }

    props.actionSubmitButton(_data);
  };

  const checkEmptyValues = (value) => {
    let _value = JSON.parse(JSON.stringify(value));
    delete _value["catalogue_id"];
    delete _value["id"];

    return Object.values(_value).every((elem) => elem === "");
  };

  const assetList = () => {
    if (!props.data.files && props.resourcesToSelect.length === 1) {
      return [];
    } else {
      if (props.data.idMain) {
        let all = props.resourcesToSelect;
        let index = props.resourcesToSelect.findIndex(
          (item) => item.resourceable_id === parseInt(props.data.idMain)
        );
        let current = props.resourcesToSelect[index];
        all.splice(index, 1);
        all.splice(0, 0, current);
        return all;
      } else {
        return props.resourcesToSelect;
      }
    }
  };

  const currentAsset = (id) => {
    return id === parseInt(props.data.idMain);
  };

  const navigateToAsset = (asset) => {
    if(asset && asset.resourceable_type === "FinancialAssetAssociated"){
      window.location.href = "/balance-show?url=balance_principal/financial_assets&dropdown_code=contacts" 
    }else{
      props.goToResource && props.goToResource(asset);  
    }
    
  };

  const getInputValue = (data) => {
    return props.data.form.profile.inputs.filter((item) => item.id === data)[0]
      .value;
  };

  const _checkValidated = () => {
    let result = Object.values(elementsRequired).every(
      (value) => value === false
    );
    return result;
  };

  const _showInputsEdit = () => {
    if (edit) {
      if (manualDisabled) {
        return ["description"];
      } else {
        return ["description", "name", "surname"];
      }
    } else {
      return ["description"];
    }
  };

  const showInputs = (item) => {
    if (manualDisabled) {
      return true;
    } else {
      if (item.value.length > 0) {
        return true;
      }

      if (props.data.code === "add_contact") {
        return true;
      }
    }
  };

  const showInputAddress = (form) => {
    if (manualDisabled) {
      return true;
    } else {
      if (props.data.code === "add_contact") {
        return true;
      }

      return !form.every((item) => item.value.length === 0);
    }
  };

  const showWrapperInputs = () => {
    if (manualDisabled) {
      return true;
    } else {
      if (props.data.code === "add_contact") {
        return true;
      }
      let inputs = props.data.form.inputs.filter((item) =>
        ["phone", "email", "special_date"].includes(item.id)
      );

      if (
        inputs.every((item) => item.value.length === 0) &&
        props.data.form.address.inputs.every((item) => item.value.length === 0)
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const getLabel = (form, id) => {
    return form.filter((elem) => elem.id === id)[0].label;
  };

  return (
    <div className="contact-form">
      <div className="contact-form__item">
        <div className="contact-form__item-image">
          {props.data &&
            props.data.form.inputs &&
            props.data.form.inputs.map((element, index) => {
              if (element.type === "image") {
                return (
                  <CustomAvatar
                    key={"contactForm-image" + "_" + index}
                    text={{
                      defaultAvatars: props.buttons.default_avatars,
                      yourSelection: props.buttons.your_selection,
                    }}
                    disabled={!edit ? edit : !manualDisabled}
                    avatar_user={element.value}
                    size="big"
                    space={false}
                    title={false}
                    newImage={(value, type) => _newImage(value, type)}
                  />
                );
              }
            })}
        </div>

        <div className="contact-form__item-data">
          {!manualDisabled && (
            <p className="contact-form__item-data-title">
              {getInputValue("name")} {getInputValue("surname")}
            </p>
          )}
          {props.data &&
            props.data.form.profile.inputs.map((item, index) => {
              if (!_showInputsEdit().includes(item.id) && showInputs(item)) {
                return (
                  <div style={{ marginBottom: "10px" }}>
                    <p>{item.label}</p>
                    <Input
                      key={"contactForm-profile" + "_" + index}
                      colorGrey={true}
                      name={item.name}
                      type={item.type}
                      defaultValue={item.value}
                      value={item.value}
                      onChangeInput={(e) => _getInputValues(e, item)}
                      onFocus={() => {}}
                      onBlur={() => {}}
                      showErrorLabel={false}
                      errorLabel={"Este campo no puede estar vacío"}
                      disabled={!edit ? edit : !manualDisabled}
                    />
                  </div>
                );
              }
            })}
        </div>
      </div>

      {showWrapperInputs() && (
        <div className="contact-form__wrapper">
          {props.data &&
            props.data.form.inputs.map((item, index) => {
              if (item.id === "phone" && showInputs(item)) {
                return (
                  <>
                    {!manualDisabled && edit && (
                      <p className="contact-form__wrapper-label">
                        {item.label}
                      </p>
                    )}

                    <AddData
                      key={"contactForm-input1" + "_" + index}
                      id={props.data.id}
                      edit={edit}
                      language={props.language}
                      disabled={manualDisabled}
                      sendData={(data, type, deleteItem) =>
                        _getDataInputs(data, type, deleteItem)
                      }
                      form={item}
                      type={item.id}
                    />
                  </>
                );
              } else if (item.id === "email" && showInputs(item)) {
                return (
                  <>
                    {!manualDisabled && edit && (
                      <p className="contact-form__wrapper-label">
                        {item.label}
                      </p>
                    )}
                    <AddData
                      key={"contactForm-input2" + "_" + index}
                      edit={edit}
                      disabled={manualDisabled}
                      id={props.data.id}
                      sendData={(data, type, deleteItem) =>
                        _getDataInputs(data, type, deleteItem)
                      }
                      form={item}
                      type={item.id}
                      language={props.language}
                    />
                  </>
                );
              } else if (item.id === "special_date" && showInputs(item)) {
                return (
                  <>
                    {!manualDisabled && edit && (
                      <p className="contact-form__wrapper-label">
                        {item.label}
                      </p>
                    )}
                    <AddData
                      key={"contactForm-input3" + "_" + index}
                      edit={edit}
                      disabled={manualDisabled}
                      id={props.data.id}
                      sendData={(data, type, deleteItem) =>
                        _getDataInputs(data, type, deleteItem)
                      }
                      form={item}
                      type={item.id}
                      language={props.language}
                    />
                  </>
                );
              }
            })}

          {showInputAddress(props.data.form.address.inputs) && (
            <>
              {!manualDisabled && edit && (
                <p className="contact-form__wrapper-label">
                  {getLabel(props.data.form.address.inputs, "address_contact")}
                </p>
              )}
              <AddData
                form={props.data.form.address.inputs}
                label={getLabel(
                  props.data.form.address.inputs,
                  "address_contact"
                )}
                disabled={manualDisabled}
                edit={edit}
                id={props.data.id}
                sendData={(data, type, deleteItem) =>
                  _getDataInputs(data, type, deleteItem)
                }
                type="address_contact"
                language={props.language}
              />
            </>
          )}
        </div>
      )}

      {props.data &&
        props.data.form.inputs.map((item, index) => {
          if (item.id === "notes" && showInputs(item)) {
            return (
              <div style={{ marginBottom: "10px" }}>
                <p>{item.label}</p>
                <Input
                  key={"contactForm-notes" + "_" + index}
                  colorGrey={true}
                  name={item.name}
                  type={item.type}
                  defaultValue={item.value}
                  value={""}
                  onChangeInput={(e) => _getInputValues(e, item)}
                  onFocus={() => {}}
                  onBlur={() => {}}
                  showErrorLabel={false}
                  errorLabel={"Este campo no puede estar vacío"}
                  disabled={!edit ? edit : !manualDisabled}
                />
              </div>
            );
          }
        })}

      {props.resourcesToSelect !== undefined &&
        props.resourcesToSelect.length > 0 && (
          <div className="block__box block__box--top block__box--bottom">
            {assetList().map((asset, index) => {
              return (
                <div className="block__box-item">
                  <div
                    className={"block__box-item-content"}
                    style={{
                      border: currentAsset(asset.resourceable_id)
                        ? "1px solid #408197"
                        : "",
                      cursor: currentAsset(asset.resourceable_id)
                        ? "auto"
                        : "pointer",
                    }}
                    key={"asset_" + index}
                    onClick={() =>
                      currentAsset(asset.resourceable_id)
                        ? {}
                        : navigateToAsset(asset)
                    }
                  >
                    <div className="block__box-item-content-icon">
                      <Icon
                        name={`${
                          asset.resourceable_type !== undefined
                            ? asset.resourceable_type
                            : props.data.resource_key === "alert"
                            ? "Notification"
                            : asset.ownerable_type
                        }`}
                      />
                    </div>

                    <TextEllipsis
                      lines={2}
                      tag={"p"}
                      ellipsisChars={"..."}
                      tagClass={"block__box-item-content-text"}
                      debounceTimeoutOnResize={200}
                      useJsOnly={true}
                    >
                      {asset.name}
                    </TextEllipsis>

                    {/* {asset.hasOwnProperty("description") && (
                      <p
                        className="block__box-item-content-text truncate"
                        
                      >
                        {asset.description}
                      </p>
                    )} */}
                  </div>

                  {manualDisabled &&
                    (props.data.can_manage ||
                      props.data.can_manage === undefined) && (
                      <div
                        className="block__box-item-icon"
                        onClick={() => props.resourceDestroyAction(asset)}
                      >
                        <Icon
                          name="Plus"
                          width={"20px"}
                          height={"20px"}
                          color="#f3b01c"
                          bgColor="#F5F5F7"
                          close={true}
                        />
                      </div>
                    )}
                  {/* {
                    props.data.resource_key === 'document' && props.resourcesToSelect.length > 1 && props.data.code !== 'add_document' && !edit && (props.data.can_manage || props.data.can_manage === undefined) &&
                    <button
                      className="block__box-item-icon"
                      onClick={() =>
                        props.resourceDestroyAction(asset)
                      }
                    >
                      <Icon name="Plus" width={"20px"} height={"20px"} color="#f3b01c" bgColor="#F5F5F7" close={true} />
                    </button>
                  } */}
                </div>
              );
            })}
          </div>
        )}

      <div className="contact-form__btn">
        {manualDisabled &&
          (props.data.can_manage || props.data.can_manage === undefined) && (
            <button
              className="btn secondary"
              onClick={() => props.destroyAssetAssociated()}
            >
              <span className="btn-text">{props.buttons.destroy}</span>
            </button>
          )}
        {(props.data.can_manage || props.data.can_manage === undefined) && (
          <>
            <button
              className="btn primary"
              disabled={!edit ? !_checkValidated() : false}
              onClick={() =>
                props.data.code.indexOf("add") != -1
                  ? _sendData()
                  : !manualDisabled
                  ? setManualDisabled(true)
                  : _sendData()
              }
            >
              <span className="btn-text">
                {props.data.code.indexOf("add") != -1
                  ? props.buttons.add
                  : !manualDisabled
                  ? props.buttons.edit
                  : props.buttons.update}
              </span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
