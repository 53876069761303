// Dependencies
import React from 'react';

const Save = ({ width, height, color }) => {

  return (

    <svg
      width={width ? width : '18px'}
      height={height ? height : '18px'}
      viewBox="0 0 18 18"
    >
      <g transform="translate(-9.000000, -9.000000)" stroke="none" strokeWidth="1" fillRule="evenodd" fill={color ? color : "#80abba"} >
        <path
          d="M22.2 9.001V9H11.4v7.2h10.8V9.001c.3.016.582.154.779.384l3.757 4.387c.17.2.264.453.264.715V25.9a1.1 1.1 0 0 1-1.1 1.1h-2.5v-7.2H12.6V27h-2.5A1.1 1.1 0 0 1 9 25.9V10.1A1.1 1.1 0 0 1 10.1 9h12.043l.057.001zM18.6 10.2H21V15h-2.4v-4.8z"
          id="a"
        />
      </g>
    </svg>

  );

}

export default Save;
