// Dependencies
import React from "react";

const LogoAddepar = ({ width, height, color, waveColor }) => {
  return (
    <svg
      width={width ? width : "154px"}
      height={height ? height : "29px"}
      viewBox="0 0 154 29"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="addepar-logo-vector" fill={ color ? color : "#000"} fillRule="nonzero">
          <path
            d="M58.8,20.7 L57.6,17.7 L52,17.7 L50.8,20.7 L47.8,20.7 L53.4,7.4 L56.1,7.4 L61.8,20.7 L58.8,20.7 Z M54.8,10.9 L53,15.2 L56.5,15.2 L54.8,10.9 Z M70.3,20.7 L65.2,20.7 L65.2,7.5 L70.3,7.5 C74.4,7.5 77.3,10.3 77.3,14.1 C77.3,17.8 74.4,20.7 70.3,20.7 Z M74.3,14.1 C74.3,11.8 72.7,10.1 70.3,10.1 L68.1,10.1 L68.1,18 L70.3,18 C72.7,18.1 74.3,16.5 74.3,14.1 Z M86.6,20.7 L81.5,20.7 L81.5,7.5 L86.6,7.5 C90.7,7.5 93.6,10.3 93.6,14.1 C93.6,17.8 90.7,20.7 86.6,20.7 Z M90.5,14.1 C90.5,11.8 88.9,10.1 86.5,10.1 L84.3,10.1 L84.3,18 L86.5,18 C88.9,18.1 90.5,16.5 90.5,14.1 Z M97.8,20.7 L97.8,7.5 L107.8,7.5 L107.8,10.1 L100.7,10.1 L100.7,12.8 L106.9,12.8 L106.9,15.4 L100.7,15.4 L100.7,18.1 L107.8,18.1 L107.8,20.7 L97.8,20.7 Z M117.8,16.8 L115.6,16.8 L115.6,20.8 L112.7,20.8 L112.7,7.5 L118.1,7.5 C121.2,7.5 123.1,9.4 123.1,12.1 C123.1,15.2 120.8,16.8 117.8,16.8 Z M120.2,12.1 C120.2,10.8 119.3,10.1 117.8,10.1 L115.6,10.1 L115.6,14.1 L117.9,14.1 C119.4,14.2 120.2,13.3 120.2,12.1 Z M135.7,20.7 L134.5,17.7 L128.9,17.7 L127.7,20.7 L124.7,20.7 L130.4,7.4 L133.1,7.4 L138.8,20.7 L135.7,20.7 Z M131.7,10.9 L129.9,15.2 L133.4,15.2 L131.7,10.9 Z M150.3,20.7 L147.5,16.5 L145.2,16.5 L145.2,20.7 L142.3,20.7 L142.3,7.5 L148.3,7.5 C151.4,7.5 153.3,9.1 153.3,11.9 C153.3,14 152.1,15.4 150.5,16 L153.7,20.7 L150.3,20.7 Z M150.3,12 C150.3,10.8 149.4,10.1 148,10.1 L145.1,10.1 L145.1,13.9 L148,13.9 C149.5,13.9 150.3,13.2 150.3,12 Z M12.9,28.6 L0,28.6 L18.2,0 L31.1,0 L12.9,28.6 Z M30,28.6 L42.9,28.6 L34.4,15.2 L21.5,15.2 L30,28.6 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default LogoAddepar;
