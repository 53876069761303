// Dependencies
import React from "react";

const Box = ({ width, height, color }) => {
  return (
    <svg
      width={width ? width : "36px"}
      height={height ? height : "34px"}
      viewBox="0 0 36 34"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="archive-black-box"
          fill={color ? color : "#80abba"}
          fillRule="nonzero"
        >
          <path
            d="M33.1665882,9.69405882 L2.76405882,9.69405882 C2.38953782,9.69405882 2.06561345,9.83072269 1.79221008,10.1042773 C1.51865546,10.3776807 1.3819916,10.701605 1.3819916,11.0758992 L1.3819916,31.8049412 C1.3819916,32.1791597 1.51865546,32.5032353 1.79221008,32.776563 C2.06561345,33.0501176 2.38953782,33.187084 2.76405882,33.187084 L33.1665882,33.187084 C33.5408824,33.187084 33.8645798,33.0501176 34.1382101,32.776563 C34.4115378,32.5033109 34.5482017,32.1792353 34.5482017,31.8049412 L34.5482017,11.0758992 C34.5482017,10.7013782 34.4120672,10.3777563 34.1382101,10.1042773 C33.8648824,9.83072269 33.5408824,9.69405882 33.1665882,9.69405882 Z M21.7003613,16.1934202 C21.4271092,16.4668992 21.1030336,16.6036387 20.7287395,16.6036387 L15.201,16.6036387 C14.8269328,16.6036387 14.5030084,16.4668235 14.2293782,16.1934202 C13.9559748,15.9198655 13.8191597,15.5960924 13.8191597,15.2215714 C13.8191597,14.8473529 13.9559748,14.5234286 14.2293782,14.2499496 C14.5029328,13.976395 14.8268571,13.8395798 15.201,13.8395798 L20.7292689,13.8395798 C21.1032605,13.8395798 21.4271092,13.976395 21.7008908,14.2499496 C21.9742185,14.5233529 22.1108824,14.8472773 22.1108824,15.2215714 C22.1108824,15.5960168 21.973916,15.9198655 21.7003613,16.1934202 Z"
            id="Shape"
          />
          <path
            d="M35.5203529,0.430714286 C35.2467227,0.157159664 34.9230252,0.0204957983 34.5486555,0.0204957983 L1.38191597,0.0204957983 C1.00769748,0.0204957983 0.683773109,0.157159664 0.410294118,0.430714286 C0.136815126,0.704117647 0,1.02804202 0,1.40233613 L0,6.93007563 C0,7.30429412 0.136815126,7.62806723 0.410369748,7.90169748 C0.683848739,8.1752521 1.00769748,8.31191597 1.3819916,8.31191597 L34.5482017,8.31191597 C34.9224202,8.31191597 35.2464958,8.1752521 35.5203529,7.90169748 C35.793605,7.62814286 35.9302689,7.30436975 35.9302689,6.93007563 L35.9302689,1.40233613 C35.9302689,1.02781513 35.793605,0.704117647 35.5203529,0.430714286 Z"
            id="Path"
          />
        </g>
      </g>
    </svg>
  );
};

export default Box;
