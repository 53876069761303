// Dependencies
import React from 'react';

const Off = ({ width, height, color }) => {

  return (

    <svg
      width={ width ? width : '22px'}
      height={ height ? height : '24px'}
      viewBox="0 0 22 24"
    >
      <g transform="translate(-7.000000, -6.000000)" stroke="none" strokeWidth="1" fillRule="evenodd" fill={ color ? color : '#80abba' } >
      <path
          d="M12.318 10.743a1.333 1.333 0 0 1 1.306 2.325c-2.436 1.368-3.957 3.865-3.957 6.609 0 3.125 1.976 5.908 4.956 7.083a8.283 8.283 0 0 0 3.044.573c1.04 0 2.051-.19 2.993-.553 3.006-1.162 5.006-3.96 5.006-7.103 0-2.772-1.551-5.29-4.027-6.648a1.333 1.333 0 1 1 1.284-2.338c3.312 1.819 5.41 5.222 5.41 8.986 0 4.267-2.697 8.038-6.711 9.59a10.952 10.952 0 0 1-3.955.733 10.95 10.95 0 0 1-4.022-.76C9.665 27.673 7 23.919 7 19.678c0-3.727 2.057-7.102 5.318-8.934zM19 18.667a1.333 1.333 0 0 1-2.667 0V7.333a1.333 1.333 0 0 1 2.667 0v11.334z"
          id="a"
        />
      </g>
    </svg>

  );

}

export default Off;
