// Dependencies
import React from 'react';

const Structure = ({ width, height, colorPrimary, colorSecondary }) => {

  return (

    <svg
      width={width ? width : '44px'}
      height={height ? height : '43px'}
      viewBox="0 0 44 43"
    >
      <g
        transform="translate(-8.000000, -9.000000) translate(8.000000, 9.000000)"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        fill="none"
      >
        <path
          d="M36 32.5a2.5 2.5 0 0 0-2.5 2.5v4a2.5 2.5 0 0 0 2.5 2.5h4a2.5 2.5 0 0 0 2.5-2.5v-4a2.5 2.5 0 0 0-2.5-2.5h-4zm0-1.5h4a4 4 0 0 1 4 4v4a4 4 0 0 1-4 4h-4a4 4 0 0 1-4-4v-4a4 4 0 0 1 4-4zM4 32.5A2.5 2.5 0 0 0 1.5 35v4A2.5 2.5 0 0 0 4 41.5h4a2.5 2.5 0 0 0 2.5-2.5v-4A2.5 2.5 0 0 0 8 32.5H4zM4 31h4a4 4 0 0 1 4 4v4a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4v-4a4 4 0 0 1 4-4z"
          fill={colorPrimary ? colorPrimary : "#005774"}
        />
        <path
          d="M7.25 25.25v3.88a.75.75 0 1 1-1.5 0V24.5a.75.75 0 0 1 .75-.75h31a.75.75 0 0 1 .75.75v4.63a.75.75 0 1 1-1.5 0v-3.88H7.25z"
          fill={colorSecondary ? colorSecondary : "#f3b01c"}
        />
        <path
          d="M21.25 19.5a.75.75 0 1 1 1.5 0v5a.75.75 0 1 1-1.5 0v-5z"
          fill={colorSecondary ? colorSecondary : "#f3b01c"}
        />
        <path
          d="M17 1.5A2.5 2.5 0 0 0 14.5 4v10a2.5 2.5 0 0 0 2.5 2.5h10a2.5 2.5 0 0 0 2.5-2.5V4A2.5 2.5 0 0 0 27 1.5H17zM17 0h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H17a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
          fill={colorPrimary ? colorPrimary : "#005774"}
        />
      </g>
    </svg>

  );

}

export default Structure;
