// Dependencies
import React from 'react';

const Dollar = ({ width, height, color }) => {
  return (
    <svg 
      width={ width ? width : '11px'}
      height={ height ? height : '25px'}
      viewBox="0 0 11 25"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46615 2.79041C5.78463 2.79041 6.04281 3.04859 6.04281 3.36707V5.11328C7.00264 5.16906 7.8284 5.37085 8.49143 5.74973C9.30417 6.21415 9.83291 6.92309 10.0623 7.84056C10.1395 8.14953 9.95166 8.46263 9.64269 8.53987C9.33371 8.61712 9.02062 8.42926 8.94337 8.12028C8.7883 7.49997 8.45203 7.05557 7.91921 6.7511C7.36599 6.43498 6.5646 6.25042 5.46615 6.25042C3.87208 6.25042 3.04423 6.76298 2.59906 7.31461C2.13446 7.89032 2.00613 8.60805 2.00613 9.13376C2.00613 9.64639 2.11356 10.0306 2.2805 10.3312C2.44818 10.6332 2.6932 10.8817 3.01274 11.0966C3.67597 11.5426 4.58541 11.8009 5.61811 12.083C5.64612 12.0907 5.67423 12.0983 5.70243 12.106C6.66321 12.3682 7.73223 12.66 8.55648 13.1978C8.9932 13.4827 9.38071 13.8475 9.65654 14.3299C9.93348 14.8143 10.0795 15.3853 10.0795 16.0538C10.0795 16.7315 9.91948 17.7438 9.23075 18.5972C8.59845 19.3808 7.58163 19.9555 6.04281 20.0697V21.8205C6.04281 22.139 5.78463 22.3971 5.46615 22.3971C5.14766 22.3971 4.88948 22.139 4.88948 21.8205V20.0743C3.92965 20.0185 3.10389 19.8167 2.44086 19.4378C1.62812 18.9734 1.09938 18.2644 0.870015 17.347C0.792771 17.038 0.980627 16.7249 1.2896 16.6477C1.59858 16.5704 1.91167 16.7583 1.98892 17.0673C2.14399 17.6876 2.48026 18.132 3.01308 18.4364C3.5663 18.7526 4.36769 18.9371 5.46615 18.9371C7.06021 18.9371 7.88806 18.4246 8.33323 17.8729C8.79783 17.2972 8.92616 16.5795 8.92616 16.0538C8.92616 15.5588 8.81988 15.1902 8.6553 14.9024C8.48963 14.6126 8.2464 14.3726 7.92625 14.1637C7.26093 13.7296 6.35039 13.4787 5.31418 13.1956L5.29494 13.1903C4.31413 12.9224 3.21411 12.6219 2.36911 12.0536C1.93178 11.7595 1.54606 11.3843 1.27223 10.8912C0.997665 10.3968 0.852798 9.81492 0.852798 9.13376C0.852798 8.45599 1.01281 7.44371 1.70154 6.59029C2.33384 5.80678 3.35066 5.232 4.88948 5.11784V3.36707C4.88948 3.04859 5.14766 2.79041 5.46615 2.79041Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70945 0.875315C10.0091 0.983034 10.1648 1.3133 10.057 1.61298L1.96061 24.1376C1.85289 24.4373 1.52262 24.5929 1.22294 24.4852C0.92326 24.3775 0.767644 24.0472 0.875363 23.7476L8.97179 1.22289C9.07951 0.923211 9.40977 0.767595 9.70945 0.875315Z" fill="white"/>
    </svg>
  );

}

export default Dollar;
