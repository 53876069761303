  // Dependencies
import React, { useState, useEffect, createRef } from "react";
import TextEllipsis from "react-text-ellipsis";
import printJS from "print-js";

// Constants
// Helpers
import { getValueLocalized } from "../helpers/getLocale";
import { getTitle } from "../helpers/getTitle";
import { canManage, canRead } from "../helpers/permits";
import { getIconAsset } from "../helpers/renderIconAssets";

// Components
import AlertInviteds from "./alert_inviteds"

import Input from "./input";
import LoadFileButton from "../components/loadFileButton";
import Icon from "../components/icons";
import MultipleInputs from "../components/multipleInputs";
import CustomAvatar from "../components/customAvatar";
import SelectMultiple from "../components/selectMultiple";
import CustomModal from "../components/customModal";
import ActionSheet from "../components/actionSheet";
import FloatButton from "../components/floatButton";
import DetailContentBlock from "../components/detailContentBlock";
import MediaBlock from "../components/mediaBlock";
import { avatars } from "../assets/resources";
import RenderDocIcon from "../helpers/renderDocIcon";
import ViewList from "../components/viewList";
import Modal from "../components/modal";
import BoxResources from "../components/boxResources";
import ContactForm from "./contactForm";
import TableEditable from "../components/TableEditable";
import AlertModal from "../components/alertModal";

import ActionSheetOnPressAction from "../helpers/actionSheetOnPressAction";
import AssetsResourcesModal from "../screens/balance/details/assetsResourcesModal";
import { internalization } from "../constants.js";

// variables
const moment = require("moment");
var autocomplete;
var autocomplete_lstnr;

function FormBlock(props) {

  const subDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  };

  const _initializeFormToSend = () => {

    let newDate = new Date();
    //A?o
    let year = newDate.getFullYear();
    //Mes
    let month =
      newDate.getMonth() + 1 < 10
      ? "0" + (newDate.getMonth() + 1)
      : newDate.getMonth() + 1;
    //D?a
    let day = newDate.getDate();

    let FormatTodayDate = month + "/" + day + "/" + year;

    let initialInputValues = {};
    let initialFormValidation = {};
    let initialDynamicInputs = {};
    let initialformDataErrors = {};
    let initialShowErrorLabelNow = {};
    let initialformFormatErrors = {};
    let initialDatesToEdit = {};
    let initialResourceToSelect = [];

    props.data.form &&
      props.data.form.map((input) => {

        if (input.hidden && input.required) {
          initialInputValues[input.id] = input.value;
          initialFormValidation[input.id] = true;
        }

        if (
          [
            "add_beneficiary_account",
            "add_investment_account",
            "add_trust",
            "add_corporation",
            "add_liability",
            "add_valuation",
            "add_asset",
            "add_life_insurance",
            "add_other",
            "add_alert",
            "add_vehicle",
            "add_art",
            "add_watch",
          ].includes(props.data.code) &&
          input.type === "date" &&
          input.required
        ) {
          if (input.id === "start_date") {
            let sub = subDays(FormatTodayDate, 1);
            let sub_year = sub.getFullYear();
            //Mes
            let sub_month =
              sub.getMonth() + 1 < 10
              ? "0" + (sub.getMonth() + 1)
              : sub.getMonth() + 1;
            //D?a
            let sub_day = sub.getDate();

            let subFormatTodayDate = sub_month + "/" + sub_day + "/" + sub_year;
            // initialInputValues[input.id] = subFormatTodayDate;
          } else {
            // initialInputValues[input.id] = FormatTodayDate;
          }
          initialFormValidation[input.id] = false;
          if (
            input.id === "end_date" &&
            props.data.resource_key === "life_insurance"
          ) {
            initialFormValidation[input.id] = true;
          }
        }

        if (input.type === "file" && input.id !== "import_xls") {
          initialFormValidation["file"] = input.value !== "";
        }

        if (
          input.required &&
          !input.hidden &&
          input.type !== "date" &&
          (input.parent === "" || input.parent === undefined)
        ) {
          initialFormValidation[input.id] = input.value !== "";
          if (input.id === "content") {
            initialformDataErrors["file"] = input.value === "";
          } else {
            initialformDataErrors[input.id] = input.value === "";
          }
          initialShowErrorLabelNow[input.id] = false;
        }

        if (
          input.required &&
          !input.hidden &&
          (input.type === "currency" || input.type === "percentage")
        ) {
          initialformFormatErrors[input.id] = false;
        }

        if (input.parent !== "") {
          let parent = props.data.form.filter((e) => e.id === input.parent)[0];
          //parent.value
          if (parent) {
            let options_selected = parent.options.filter(
              (e) => e.id === parent.value
            );

            if (options_selected[0] !== undefined) {
              initialDynamicInputs[input.parent] =
                options_selected[0].related_fields;
            }
          } else {
            initialDynamicInputs[input.parent] = [];
          }
        }

        if (
          props.data.code === "edit_asset" &&
          input.type === "date" &&
          input.required
        ) {
          initialDatesToEdit[input.id] = input.value;
        }

        if (input.type === "select" && input.value !== "") {
          initialDatesToEdit[input.id] = input.value;
          initialFormValidation[input.id] = true;
          initialformDataErrors[input.id] = false;
          initialInputValues[input.id] = input.value;

          try {
            let item = input.options.filter((e) => e.id === input.value)[0];
            item.related_fields.map((elem) => {
              let real_elem = props.data.form.filter(
                (input) => input.id === elem
              )[0];
              initialFormValidation[elem] =
                elem === "start_date"
                ? true
                : real_elem.required
                ? real_elem.value !== ""
                : true;
              initialformDataErrors[elem] =
                elem === "start_date"
                ? false
                : real_elem.required
                ? real_elem.value === ""
                : false;
            });
          } catch (e) {}
        }
      });
    if (
      props.resourcesToSelect !== undefined &&
      props.resourcesToSelect.length > 0 &&
      props.data.code === "add_document"
    ) {
      let temp_res = props.resourcesToSelect[0];
      initialInputValues["ownerable_id"] = temp_res.ownerable_id;
      initialInputValues["ownerable_type"] = temp_res.ownerable_type;
      // setResourceToSelect(props.resourcesToSelect)
    }

    return {
      initialInputValues,
      initialFormValidation,
      initialDynamicInputs,
      initialformDataErrors,
      initialShowErrorLabelNow,
      initialformFormatErrors,
      initialDatesToEdit,
      initialResourceToSelect,
    };
  };


  const initialOwnereable = (initial) => {
    if (props.data.code === "add_document") {
      if (initial["ownerable_id"] !== undefined) {
        return initial["ownerable_id"];
      }
      let value = props.data.form.filter((item) => item.id === "ownerable_id");
      if (value && value.length > 0) {
        value = value[0].value;
        return value;
      }
    }
  };

  const createOfuscated = (value) => {

    let valueNew =""+value
    let valueOfuscated = ""


    for (let index = 0; index < valueNew.length; index++) {
      valueOfuscated += "*"
    }

    return valueOfuscated

  }

  const initialOfuscated = () => {
    let getValue = props.data.form.filter(item => item.id === "ssn")[0]

    if(getValue !== undefined && getValue.value !== ""){

      return createOfuscated(getValue.value)  
    }else {
      return ""
    }
  }

  var languageUserAgent = /^es/.test(props.language) ? "es" : "en";
  const [installmentValueLabel, setInstallmentValueLabel] = useState("")
  const [forceUpdate, setForceUpdate] = useState(true);
  const [showResourceBox, setShowResourceBox] = useState(
    props.data.code === "edit_document" ||
    props.data.code === "edit_owner" ||
    props.data.code === "edit_alert" ||
    props.data.code === "edit_beneficiary"
    ? true
    : false
  );
  const [actionSheetOptions, setActionSheetOptions] = useState([]);
  const [actionSheet, setActionSheet] = useState(React.createRef());
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [showModalAssign, setShowModalAssign] = useState(false);
  const [showModalConfirmText, setShowModalConfirmText] = useState(false)
  const [showTenantModal, setShowTenantModal] = useState(false)
  const [showTenantData, setShowTenantData] = useState({})
  const [assignData, setAssignData] = useState(false);
  const [assignDataTitles, setAssignDataTitles] = useState({});
  const [multipleRef, setMultipleRef] = useState(null);
  const [loadFileRef, setLoadFileRef] = useState(null);
  const initialStatus = _initializeFormToSend();
  const [formLinkable, setFormLinkable] = useState({});
  const [formData, setFormData] = useState(initialStatus.initialInputValues);
  const [xlsUploaded, setXlsUploaded] = useState(false);
  const [tableEditableData, setTableEditableData] = useState([]);
  const [formValidation, setFormValidation] = useState(
    initialStatus.initialFormValidation
  );
  const [formDataErrors, setFormDataErrors] = useState(
    initialStatus.initialformDataErrors
  );
  const [formFormatErrors, setFormFormatErrors] = useState(
    initialStatus.initialformFormatErrors
  );
  const [dynamicInputs, setDynamicInputs] = useState(
    initialStatus.initialDynamicInputs
  );
  const [showErrorLabelNow, setShowErrorLabelNow] = useState(
    initialStatus.initialShowErrorLabelNow
  );
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState([]);

  const [resourcesToSelect, setResourceToSelect] = useState(
    initialStatus.initialResourceToSelect
  );

  const [ofuscated, setOfuscated] = useState(true)
  const [elementOfuscated, setElementOfuscated] = useState(initialOfuscated())
  const [keyCount, setKeyCount] = useState(0)

  const [selectOwnerable, setSelectOwnerable] = useState(
    initialOwnereable(initialStatus.initialInputValues)
  );
  const [secondModal, setSecondModal] = useState(false);
  //const [manualDisabled, setManualDisabled] = useState(props.disabled || !props.data.code.indexOf("add_") || false);
  const [manualDisabled, setManualDisabled] = useState(
    props.data.code != "edit_ownership" ? props.disabled || false : false
  );
  const datesToEdit = initialStatus.initialDatesToEdit;

  const [keyAssetLink, setKeyAssetLink] = useState("");

  const [showAddBudgetModal, setShowAddBudgetModal] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);

  const _checkParentSelected = (element) => {

    if (
      formData.hasOwnProperty("vehicle_type") &&
      formData["vehicle_type"] !== 0 &&
      element.id === "license_plate_state"
    ) {
      return false;
    }
    if (
      formData.hasOwnProperty("type_loan") &&
      formData["type_loan"] === 0 &&
      element.id === "capital_amortizations_converted"
    ) {
      return false;
    }
    if (
      element.id === "sold_price_converted" && ((formData.hasOwnProperty("is_sold") &&
      formData["is_sold"] === 0) || (!formData.hasOwnProperty("is_sold"))) 
    ) {
      return false;
    }
    if (element.parent !== undefined && element.parent !== "") {
      let parent = dynamicInputs[element.parent];
      if (
        parent !== undefined &&
        parent &&
        parent.length !== 0 &&
        parent.includes(element.id)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const _checkTitleEnabled = (element) => {
    if ((element.id === "title" || element.id === "name") && manualDisabled) {
      return false;
    } else {
      return true;
    }
  };

  const _showErrorLabel = (e, required) => {
    required === true && (e.target.value.length === 0 || e.target.value === -1)
      ? setFormDataErrors({
        ...formDataErrors,
        [e.target.name]: true,
      })
      : required === true &&
      (e.target.value.length > 0 || e.target.value === -1) &&
      setFormDataErrors({
        ...formDataErrors,
        [e.target.name]: false,
      });
  };

  const _showErrorLabelNow = (e, element) => {
    if (element.required && e.target.value === "") {
      setShowErrorLabelNow({
        ...showErrorLabelNow,
        [e.target.name]: true,
      });
    }
  };

  const _showFormatError = (e, element) => {
    if (!element.required) return;
    if (
      element.type === "currency" ||
      element.type === "percentage" ||
      element.type === "number"
    ) {
      new RegExp(e.target.getAttribute("pattern")).test(e.target.value) ||
        e.target.value === ""
        ? setFormFormatErrors({
          ...formFormatErrors,
          [e.target.name]: false,
        })
        : setFormFormatErrors({
          ...formFormatErrors,
          [e.target.name]: true,
        });
    }
  };

  const capitalize = (str) => {
    str = str.toLowerCase();
    str = str.split(" ");

    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }

    return str.join(" ");
  };

  const _getInputOfuscated = (values,element) => {

    if (values === ""){
      formData[element.id] = ""
      setFormData(formData);
      setElementOfuscated("")
      return
    } 


    let newValues =  ""
    let valuesReplaceAsterisk = ""

    let hasDigit = /.*\d.*/.test(values)

    let defaultValue = 
      formData["ssn"] !== undefined ? 
      formData["ssn"] : 
      elementOfuscated === "" ? 
      "" : 
      element.value !== "" ? 
      element.value : ""

    if(values.indexOf('*') !== -1 && hasDigit){
      valuesReplaceAsterisk = values.replace(/(\*+)/,"")
      newValues = defaultValue += valuesReplaceAsterisk

    }else{
      newValues = values
    }

    if(props.data.code === "edit_owner" || props.data.code === "edit_beneficiary"){
      if(values.length < defaultValue.length){
        newValues = defaultValue.slice(0, -1)
      }
    }

    let copyNewValues = JSON.parse( JSON.stringify( newValues ) )
    let parseValueToAsterisk = createOfuscated(copyNewValues)

    setElementOfuscated(ofuscated ? parseValueToAsterisk : newValues)

    if(!newValues.includes("*")){
      setFormData({
        ...formData,
        [element.name]: newValues,
      });
    }  
  }


  const _getInputValuesNumeric = (values, element) => {
    let value = values.value

    if (formData["crypto_type"] && formData["crypto_type"] !== "" && element.id === "value"){
      // value = element.value
    }

    setFormData({
      ...formData,
      [element.name]: value,
    });

    if (element.required) {
      setFormValidation({
        ...formValidation,
        [element.name]: true,
      });
    }

    element.required === true && values.value.length === 0
      ? setFormDataErrors({
        ...formDataErrors,
        [element.name]: true,
      })
      : element.required === true &&
      values.value.length > 0 &&
      setFormDataErrors({
        ...formDataErrors,
        [element.name]: false,
      });

    if (element.required === true && values.value.length === 0) {
      setShowErrorLabelNow({
        ...showErrorLabelNow,
        [element.name]: true,
      });
    }

    if (element.max_percentage && element.max_percentage !== "") {
      let valuationFormError;
      let valuationForm;
      if (values.floatValue > element.max_percentage) {
        valuationFormError = true;
        valuationForm = false;
      } else {
        valuationFormError = false;
        valuationForm = true;
      }
      setFormDataErrors({
        ...formDataErrors,
        [element.name]: valuationFormError,
      });
      setShowErrorLabelNow({
        ...showErrorLabelNow,
        [element.name]: valuationFormError,
      });

      setFormValidation({
        ...formValidation,
        [element.name]: valuationForm,
      });
    }
  };

  const validateNumber = (value) => {
    let re = /^[0-9][^.,]*$/;
    return re.test(value);
  };

  const _getInputValues = (e, element, index, related_fields) => {
    e.target.parentNode.setAttribute("data-mask-value", "");

    let symbol =
      element.style === "currency" || element.style === "percent"
      ? true
      : false;

    if (new RegExp(e.target.getAttribute("pattern")).test(e.target.value)) {
      e.target.parentNode.setAttribute(
        "data-mask-value",
        getValueLocalized("en-US", e.target.value, symbol, element.style)
        .formated
      );
    }

    if (dynamicInputs[e.target.name] !== undefined) {
      dynamicInputs[e.target.name].length > 0 &&
        dynamicInputs[e.target.name].map((element) => {
          if (
            dynamicInputs[element] !== undefined &&
            dynamicInputs[element].length > 0
          ) {
            dynamicInputs[element].map((subelement) => {
              delete formValidation[subelement];
              delete formData[subelement];
              delete dynamicInputs[element];
            });
          }
          delete formValidation[element];
          delete formData[element];
        });
      related_fields.length > 0 &&
        related_fields.map((elem) => {
          if (elem !== "start_date" && elem !== "end_date") {
            let real_elem = props.data.form.filter(
              (input) => input.id === elem
            )[0];
            Object.assign(formValidation, {
              [elem]: real_elem && real_elem.required ? false : true,
            });
          }
        });
    }

    if (e.target.type !== "date") {
      if (!element.multiple || element.multiple === undefined) {

          if (element.id === "year" && e.target.value !== "") {
            let valuationFormError;
            let valuationForm;
            if (!validateNumber(e.target.value)) {
              valuationFormError = true;
              valuationForm = false;
            } else {
              valuationFormError = false;
              valuationForm = true;
            }
            setFormDataErrors({
              ...formDataErrors,
              [element.name]: valuationFormError,
            });
            setShowErrorLabelNow({
              ...showErrorLabelNow,
              [element.name]: valuationFormError,
            });
      
            setFormValidation({
              ...formValidation,
              [element.name]: valuationForm,
            });
          }


        setFormData({
          ...formData,
          [e.target.name]:
          (props.data.code === "add_contact" && e.target.name !== "email") ||
          (props.data.code === "edit_contact" && e.target.name !== "email")
          ? e.target.value
          : e.target.value,
        });
      } else {
        let value = element.value;
        value[index] = e.target.value;
        setFormData({
          ...formData,
          [e.target.name]: [...value],
        });
      }
    }

    setFormValidation({
      ...formValidation,
      [e.target.name]:
      e.target.type !== "select"
      ? element.required
      ? e.target.value.length > 0
      : true
      : e.target.value !== -1,
    });

    setDynamicInputs({
      ...dynamicInputs,
      [e.target.name]: related_fields,
    });

    _showErrorLabel(e, element.required);
    _showErrorLabelNow(e, element);
    _showFormatError(e, element);

    if (element.multiple_resources === true) {
      let selected = element.options.filter(
        (element) => "" + element.value === "" + e.target.value
      );
      if (selected.length > 0) {
        let related = selected[0].related_fields;
        let final_resource = [];
        let element = 0;
        for (element = 0; element < related.length; element++) {
          for (let i = 0; i < props.resourcesToSelect.length; i++) {
            if (
              related[element] == Object.keys(props.resourcesToSelect[i])[0]
            ) {
              final_resource.push(props.resourcesToSelect[i][related[element]]);
            }
            // if(element2[element] !== undefined){
            //   final_resource.push(props.resourcesToSelect[element2][element])
            // }
          }
        }

        // let new_elements = props.resourcesToSelect
        // props.resourcesToSelect(final_resource)
        setResourceToSelect(final_resource.flat());
      }
    }

    if (element.type === "radio") {
      setShowResourceBox(true);
    }
  };

  const _clearInputs = (e, type) => {
    if (type === "currency" || type === "percentage" || type === "number") {
      e.currentTarget.parentElement.setAttribute("data-mask-value", "");
    }

    let emptyInput = (e.currentTarget.parentElement.children[0].value = "");

    if (type === "geo_location") {
      setFormData({
        ...formData,
        [e.currentTarget.parentElement.children[0].name]: emptyInput,
        address_lat: "",
        address_long: "",
      });

      setFormValidation({
        ...formValidation,
        [e.currentTarget.parentElement.children[0].name]: false,
      });
    } else {
      setFormData({
        ...formData,
        [e.currentTarget.parentElement.children[0].name]: emptyInput,
      });

      setFormValidation({
        ...formValidation,
        [e.currentTarget.parentElement.children[0].name]: false,
      });
    }
  };

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const _dateShowed = (date, element, bigger, resource_key) => {
    let nextDay = addDays(date, 1);
    let value_age = "";
    let formatDate = moment(date).format("MM/DD/YYYY");
    let nextDate = moment(nextDay).format("MM/DD/YYYY");
    if (
      ["date_birth"].includes(element.id) &&
      ["owner_beneficiary", "insured"].includes(resource_key)
    ) {
      var ageDifMs = date !== null && Date.now() - date.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      value_age = Math.abs(ageDate.getUTCFullYear() - 1970);
      setFormData({
        ...formData,
        [resource_key === "insured" ? "age" : "age_owner"]: value_age,
      });
    }

    if (formatDate === "Invalid date") {
      setFormData({
        ...formData,
        [element.id]: "",
      });
      if (!element.required) {
        setFormValidation({
          ...formValidation,
          [element.id]: true,
        });
        setFormDataErrors({
          ...formDataErrors,
          [element.id]: false,
        });
      } else {
        setFormValidation({
          ...formValidation,
          [element.id]: false,
        });
        setFormDataErrors({
          ...formDataErrors,
          [element.id]: true,
        });
      }
    } else {
      if (
        resource_key === "alert" &&
        (element.id === "start_date" || element.id === "end_date")
      ) {
        formatDate = moment(date).format("MM/DD/YYYY HH:mm");
        nextDate = moment(nextDay).format("MM/DD/YYYY HH:mm");
      }
      if (element.id === "start_date" && bigger) {
        setFormData({
          ...formData,
          start_date: formatDate,
          end_date: nextDate,
        });
        setFormValidation({
          ...formValidation,
          [element.id]: true,
        });
        setFormDataErrors({
          ...formDataErrors,
          [element.id]: false,
        });
      } else {
        if (value_age !== "") {
          setFormData({
            ...formData,
            [element.id]: formatDate,
            [resource_key === "insured" ? "age" : "age"]: "" + value_age,
          });
        } else {
          setFormData({
            ...formData,
            [element.id]: formatDate,
          });
        }

        setFormValidation({
          ...formValidation,
          [element.id]: true,
        });
        setFormDataErrors({
          ...formDataErrors,
          [element.id]: false,
        });


      }
    }
  };



  const _focusedInput = (e, element) => {
    if (
      element.type === "currency" ||
      element.type === "percentage" ||
      element.type === "number"
    ) {
      !e.target.parentNode.getAttribute("class").includes("transparent") &&
        !e.target.parentNode.getAttribute("class").includes("opaque") &&
        e.target.parentNode.setAttribute(
          "class",
          e.target.parentNode.getAttribute("class") + " transparent"
        );

      e.target.parentNode.getAttribute("class").includes("opaque") &&
        e.target.parentNode.setAttribute(
          "class",
          e.target.parentNode
          .getAttribute("class")
          .replace("opaque", "transparent")
        );
    }
  };

  const _bluredInput = (e, element) => {
    if (
      element.type === "currency" ||
      element.type === "percentage" ||
      element.type === "number"
    ) {
      !e.target.parentNode.getAttribute("class").includes("opaque") &&
        e.target.parentNode.setAttribute(
          "class",
          e.target.parentNode
          .getAttribute("class")
          .replace("transparent", "opaque")
        );
    }
    let valuation_converted = props.data.form.filter(
      (e) => ["valuation_converted", "currency_value", "purchase_price", "sold_price", "principal_amount", "death_benefit", "premium", "policy_value", "capital_amortizations"].includes(e.id)
    );
    if(valuation_converted.length > 0){
      let current_value = undefined
      let current_currency = undefined
      let date_field = formData["date"]
      if(element.id === "value" && formData["crypto_type"] !== "" && formData["type_other_assets"] === 5){
        // "crypto_converted"
        props.getConvertedValue(formData["value"], formData["crypto_type"], formData["date"], (responseJSON) => convertedValueSuccess(responseJSON, element) , () => {})
      }
      if(element.id === "value" && formData["currency_type"] !== "USD"){
        current_value = formData["value"]
        current_currency = formData["currency_type"]
      }
      if(element.id === "principal_amount" && formData["currency_type"] !== "USD"){
        current_value = formData["principal_amount"]
        current_currency = formData["currency_type"]
      }
      if(element.id === "purchase_price" && formData["currency_type"] !== "USD"){
        current_value = formData["purchase_price"]
        current_currency = formData["currency_type"]
        date_field = formData["date_bought"]
      }
      if(element.id === "sold_price" && formData["currency_type"] !== "USD"){
        current_value = formData["sold_price"]
        current_currency = formData["currency_type"]
      }
      if(element.id === "death_benefit" && formData["currency_type"] !== "USD"){
        current_value = formData["death_benefit"]
        current_currency = formData["currency_type"]
      }
      if(element.id === "premium" && formData["currency_type"] !== "USD"){
        current_value = formData["premium"]
        current_currency = formData["currency_type"]
      }
      if(element.id === "policy_value" && formData["currency_type"] !== "USD"){
        current_value = formData["policy_value"]
        current_currency = formData["currency_type"]
      }
      if(element.id === "capital_amortizations" && formData["currency_type"] !== "USD"){
        current_value = formData["capital_amortizations"]
        current_currency = formData["currency_type"]
      }
      if(current_value !== undefined && current_currency !== undefined){
        props.getConvertedValue(current_value, current_currency, date_field, (responseJSON) => convertedValueSuccess(responseJSON, element) , () => {})
      }      
    }

    let final_value_capital_amortization = undefined;
    let final_value_capital_amortization_raw = undefined;

    if(element.id === "principal_amount" && formData["capital_amortization_frecuency"] !== "" && formData["start_date"] !== ""  &&  formData["installment_type"] !== "" && formData["installment_value"] !== "" ){      
      let new_result = undefined;
      let data = formData
      data["frecuency"] = formData["capital_amortization_frecuency"]
      data["resource_id"] = props.resource_id
      data["resource_type"] = props.resource_key
      let temporal_form_data = {}
      props.getCapitalAmortizationValue(data, (responseJSON) => capitalAmortizationValueSuccess(responseJSON, temporal_form_data), () => console.log("error getCapitalAmortizationValue")) 
    }
    
  };

  const setNativeValue = (element, value) => {
    try{
      const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
      const prototype = Object.getPrototypeOf(element);
      const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

      if (valueSetter && valueSetter !== prototypeValueSetter) {
          prototypeValueSetter.call(element, value);
      } else {
          valueSetter.call(element, value);
      }
    }catch(e){

    }
    
  }

  const convertedValueSuccess = (responseJSON, element = undefined) => {
    if(props.data.resource_key === "valuation"){
      let valuation_converted_html = document.querySelector("#currency_value");
      setNativeValue(valuation_converted_html, responseJSON.amount_formatted)
    }
    else if(element && element.id === "purchase_price"){
      let valuation_converted_html = document.querySelector("#purchase_price_converted");
      setNativeValue(valuation_converted_html, responseJSON.amount_formatted) 
    }
    else if(element && element.id === "sold_price"){
      let valuation_converted_html = document.querySelector("#sold_price_converted");
      setNativeValue(valuation_converted_html, responseJSON.amount_formatted) 
    }
    else if(element && element.id === "principal_amount"){
      let valuation_converted_html = document.querySelector("#currency_value");
      if(props.data.resource_key === "liability" || props.data.resource_key === "receivable_account"){
        valuation_converted_html = document.querySelector("#principal_amount_converted");
      }
      setNativeValue(valuation_converted_html, responseJSON.amount_formatted) 
    }
    else if(element && element.id === "death_benefit"){
      let valuation_converted_html = document.querySelector("#death_benefit_converted");
      setNativeValue(valuation_converted_html, responseJSON.amount_formatted) 
    }
    else if(element && element.id === "capital_amortizations"){
      let valuation_converted_html = document.querySelector("#capital_amortizations_converted");
      setNativeValue(valuation_converted_html, responseJSON.amount_formatted) 
    }
    else if(element && element.id === "premium"){
      let valuation_converted_html = document.querySelector("#premium_converted");
      setNativeValue(valuation_converted_html, responseJSON.amount_formatted) 
    }
    else if(element && element.id === "policy_value"){
      let valuation_converted_html = document.querySelector("#policy_value_converted");
      setNativeValue(valuation_converted_html, responseJSON.amount_formatted) 
    }
    else if(element && element.id === "value" && formData["crypto_type"] !== "" && formData["type_other_assets"] === 5){
      let valuation_converted_html = document.querySelector("#crypto_converted");
      setNativeValue(valuation_converted_html, responseJSON.crypto_formatted) 
    }
    else{
      let valuation_converted_html = document.querySelector("#valuation_converted");
      setNativeValue(valuation_converted_html, responseJSON.amount_formatted) 
    }

    // setForceUpdate(!forceUpdate)
    
  }

  const navigateToAsset = (asset) => {
    props.goToResource && props.goToResource(asset);
  };

  const setPolymorphic = (asset) => {
    let polymorphic_id = props.polymorphic + "id";
    let polymorphic_type = props.polymorphic + "type";
    let id = asset[polymorphic_id];
    let type = asset[polymorphic_type];
    setSelectOwnerable(asset.id);
    setFormData({
      ...formData,
      [polymorphic_id]: id,
      [polymorphic_type]: type,
    });
  };

  useEffect(() => {
    if(props.data.edit_mode !== undefined && props.data.edit_mode){
      setManualDisabled(false)
    }
  }, []);

  useEffect(() => {
    _googleChecker();
  }, [formData]);

  useEffect(() => {
    document.removeEventListener("click", handleClickOutside.bind(multipleRef));
    document.addEventListener("click", handleClickOutside.bind(multipleRef));

    return () => {
      document.removeEventListener(
        "click",
        handleClickOutside.bind(multipleRef)
      );
    };
  }, []);

  const handleClickOutside = (e) => {
    let allMultiSelects = document.querySelectorAll(".options-multiple-select");
    let containerMultiSelects = document.querySelectorAll(
      ".container-multiple-select"
    );
    let multiSelectIcon = document.querySelectorAll(".icons-multiple-select");
    let wrapperMultiSelect = document.querySelectorAll(
      ".wrapper-multiple-select"
    );

    for (let i = 0; i < allMultiSelects.length; i++) {
      if (
        allMultiSelects.length > 0 &&
        multiSelectIcon.length > 0 &&
        containerMultiSelects.length > 0 &&
        containerMultiSelects &&
        !containerMultiSelects[i].contains(e.target)
      ) {
        containerMultiSelects[i].classList.remove("open");
        multiSelectIcon[i] && multiSelectIcon[i].classList.remove("rotate");
        allMultiSelects[i].style.height = 0;

        setTimeout(function () {
          containerMultiSelects[i].style.zIndex = 0;
        }, 100);

        allMultiSelects[i].classList.remove("open");
      }
    }
  };

  const _googleChecker = () => {
    if (!window.google) {
      setTimeout(_googleChecker.bind(this), 100);
    } else {
      _setAutocomplete();
    }
  };

  const _setAutocomplete = () => {
    const google = window.google;
    var old_element = document.getElementById("address_input");
    if (old_element == null) return;
    var new_element = old_element.cloneNode(true);
    old_element.parentNode.replaceChild(new_element, old_element);
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address_input"),
      { types: ["geocode"] }
    );
    google.maps.event.removeListener(autocomplete_lstnr);
    autocomplete_lstnr = autocomplete.addListener(
      "place_changed",
      _fillAdress.bind(this)
    );
  };

  const _fillAdress = () => {
    var place = autocomplete.getPlace();
    var location = place.geometry.location;

    let response = _parseGoogleResponse(place.address_components);

    _setAddress(
      response,
      place.formatted_address,
      location.lat(),
      location.lng()
    );
  };

  const _setAddress = (
    address_title,
    address_subtitle,
    address_latitude,
    address_longitude
  ) => {
    let geoLocationInputs = {
      address_lat: address_latitude,
      address_long: address_longitude,
      address_description: address_subtitle,
    };

    setFormData({
      ...formData,
      ...geoLocationInputs,
    });

    setFormValidation({
      ...formValidation,
      address_description: address_subtitle.length != 0,
    });
    setFormDataErrors({
      ...formDataErrors,
      address_description: address_subtitle.length == 0,
    });
  };

  const _parseGoogleResponse = (components) => {
    var province = "";
    var community = "";

    components.map((component) => {
      component.types.map((type) => {
        let response;
        if (type === "administrative_area_level_1") {
          community = component.long_name;
          response = community;
        }
        if (type === "administrative_area_level_2") {
          province = component.long_name;
          response = province;
        }
        return response;
      });
    });

    return province !== "" ? province : community;
  };

  let resourceTexts = getTitle(props.data);

  const _updateFromMultiple = (type, items) => {
    setFormData({
      ...formData,
      [type]: items,
    });
  };

  const _newImage = (value, imgDefault) => {
    setFormData({ ...formData, ...{ images: [value] } });
    setFormValidation({ ...formValidation, ["images"]: true });
  };

  const _getModalContent = (label, tooltip) => {
    setModalTitle(label);
    setModalDescription(tooltip);
  };

  const _prepare_dates = () => {
    let current_date = new Date();
    let current_tomorrow = new Date();
    current_tomorrow = current_tomorrow.setDate(current_tomorrow.getDate() + 1);
    current_tomorrow = moment(current_tomorrow).toDate();

    //reset default to ""
    current_date = "";
    current_tomorrow = "";

    let current_start_date = props.data.form.filter(
      (e) => e.id === "start_date"
    );
    current_start_date =
      current_start_date.length > 0
      ? current_start_date[0].value != ""
      ? current_start_date[0].value
      : current_date
      : current_date;

    //START

    let current_date_date = props.data.form.filter((e) => e.id === "date");
    current_date_date =
      current_date_date.length > 0
      ? current_date_date[0].value != ""
      ? current_date_date[0].value
      : current_date_date[0].required
      ? current_date
      : ""
      : current_date;

    let current_date_created = props.data.form.filter(
      (e) => e.id === "date_created"
    );
    current_date_created =
      current_date_created.length > 0
      ? current_date_created[0].value != ""
      ? current_date_created[0].value
      : current_date_created[0].required
      ? current_date
      : ""
      : current_date;

    let current_start_date_activity = props.data.form.filter(
      (e) => e.id === "start_date_activity"
    );
    current_start_date_activity =
      current_start_date_activity.length > 0
      ? current_start_date_activity[0].value != ""
      ? current_start_date_activity[0].value
      : current_start_date_activity[0].required
      ? current_date
      : ""
      : current_date;

    let current_rent_start_date = props.data.form.filter(
      (e) => e.id === "rent_start_date"
    );
    current_rent_start_date =
      current_rent_start_date.length > 0
      ? current_rent_start_date[0].value != ""
      ? current_rent_start_date[0].value
      : current_rent_start_date[0].required
      ? current_date
      : ""
      : current_date;

    let current_start_date_alert = props.data.form.filter(
      (e) => e.id === "start_date_alert"
    );
    current_start_date_alert =
      current_start_date_alert.length > 0
      ? current_start_date_alert[0].value != ""
      ? current_start_date_alert[0].value
      : current_start_date_alert[0].required
      ? current_date
      : ""
      : current_date;

    let current_lease_start_date = props.data.form.filter(
      (e) => e.id === "lease_start_date"
    );
    current_lease_start_date =
      current_lease_start_date.length > 0
      ? current_lease_start_date[0].value != ""
      ? current_lease_start_date[0].value
      : current_lease_start_date[0].required
      ? current_date
      : ""
      : current_date;

    let current_date_bought = props.data.form.filter(
      (e) => e.id === "date_bought"
    );
    current_date_bought =
      current_date_bought.length > 0
      ? current_date_bought[0].value != ""
      ? current_date_bought[0].value
      : current_date_bought[0].required
      ? current_date
      : ""
      : current_date;
    let current_date_proposal = props.data.form.filter(
      (e) => e.id === "date_proposal"
    );
    current_date_proposal =
      current_date_proposal.length > 0
      ? current_date_proposal[0].value != ""
      ? current_date_proposal[0].value
      : current_date_proposal[0].required
      ? current_date
      : ""
      : current_date;

    let current_next_payment_date = props.data.form.filter(
      (e) => e.id === "next_payment_date"
    );
    current_next_payment_date =
      current_next_payment_date.length > 0
      ? current_next_payment_date[0].value != ""
      ? current_next_payment_date[0].value
      : current_next_payment_date[0].required
      ? current_date
      : ""
      : current_date;
      
    let current_date_birth = props.data.form.filter(
      (e) => e.id === "date_birth"
    );
    current_date_birth =
      current_date_birth.length > 0
      ? current_date_birth[0].value != ""
      ? current_date_birth[0].value
      : current_date_birth[0].required
      ? current_date
      : ""
      : current_date;

    let current_date_registration = props.data.form.filter(
      (e) => e.id === "date_registration"
    );
    current_date_registration =
      current_date_registration.length > 0
      ? current_date_registration[0].value != ""
      ? current_date_registration[0].value
      : current_date_registration[0].required
      ? current_date
      : ""
      : current_date;

    //END
    let current_rent_end_date = props.data.form.filter(
      (e) => e.id === "rent_end_date"
    );
    current_rent_end_date =
      current_rent_end_date.length > 0
      ? current_rent_end_date[0].value != ""
      ? current_rent_end_date[0].value
      : current_rent_end_date[0].required
      ? current_tomorrow
      : ""
      : current_tomorrow;

    let current_end_date = props.data.form.filter((e) => e.id === "end_date");
    current_end_date =
      current_end_date.length > 0
      ? current_end_date[0].value != ""
      ? current_end_date[0].value
      : current_end_date[0].required
      ? current_tomorrow
      : ""
      : current_tomorrow;

    let current_end_date_activity = props.data.form.filter(
      (e) => e.id === "end_date"
    );
    current_end_date_activity =
      current_end_date_activity.length > 0
      ? current_end_date_activity[0].value != ""
      ? current_end_date_activity[0].value
      : current_end_date_activity[0].required
      ? current_tomorrow
      : ""
      : current_tomorrow;

    let current_end_date_alert = props.data.form.filter(
      (e) => e.id === "end_date_alert"
    );
    current_end_date_alert =
      current_end_date_alert.length > 0
      ? current_end_date_alert[0].value != ""
      ? current_end_date_alert[0].value
      : current_end_date_alert[0].required
      ? current_tomorrow
      : ""
      : current_tomorrow;

    let current_lease_end_date = props.data.form.filter(
      (e) => e.id === "lease_end_date"
    );
    current_lease_end_date =
      current_lease_end_date.length > 0
      ? current_lease_end_date[0].value != ""
      ? current_lease_end_date[0].value
      : current_lease_end_date[0].required
      ? current_tomorrow
      : ""
      : current_tomorrow;

    let current_sold_date = props.data.form.filter(
      (e) => e.id === "sold_date"
    );
    current_sold_date =
      current_sold_date.length > 0
      ? current_sold_date[0].value != ""
      ? current_sold_date[0].value
      : current_sold_date[0].required
      ? current_date
      : ""
      : current_date;

    //,, :date_bought
    let start_date = formData.hasOwnProperty("start_date")
      ? formData["start_date"]
      : current_start_date;
    let end_date = formData.hasOwnProperty("end_date")
      ? formData["end_date"]
      : current_end_date;

    let rent_start_date = formData.hasOwnProperty("rent_start_date")
      ? formData["rent_start_date"]
      : current_rent_start_date;
    let rent_end_date = formData.hasOwnProperty("rent_end_date")
      ? formData["rent_end_date"]
      : current_rent_end_date;
    let date = formData.hasOwnProperty("date")
      ? formData["date"]
      : current_date_date;
    let start_date_activity = formData.hasOwnProperty("start_date_activity")
      ? formData["start_date_activity"]
      : current_start_date_activity;
    let end_date_activity = formData.hasOwnProperty("end_date_activity")
      ? formData["end_date_activity"]
      : current_end_date_activity;
    let start_date_alert = formData.hasOwnProperty("start_date_alert")
      ? formData["start_date_alert"]
      : current_start_date_alert;
    let end_date_alert = formData.hasOwnProperty("end_date_alert")
      ? formData["end_date_alert"]
      : current_end_date_alert;
    let lease_start_date = formData.hasOwnProperty("lease_start_date")
      ? formData["lease_start_date"]
      : current_lease_start_date;
    let lease_end_date = formData.hasOwnProperty("lease_end_date")
      ? formData["lease_end_date"]
      : current_lease_end_date;
    let sold_date = formData.hasOwnProperty("sold_date")
      ? formData["sold_date"]
      : current_sold_date;
    let date_proposal = formData.hasOwnProperty("date_proposal")
      ? formData["date_proposal"]
      : current_date_proposal;
    let next_payment_date = formData.hasOwnProperty("next_payment_date")
      ? formData["next_payment_date"]
      : current_next_payment_date;
    let date_bought = formData.hasOwnProperty("date_bought")
      ? formData["date_bought"]
      : current_date_bought;
    let date_created = formData.hasOwnProperty("date_created")
      ? formData["date_created"]
      : current_date_created;
    let date_birth = formData.hasOwnProperty("date_birth")
      ? formData["date_birth"]
      : current_date_birth;
    let date_registration = formData.hasOwnProperty("date_registration")
      ? formData["date_registration"]
      : current_date_registration;

    if (
      formData["start_date"] != start_date &&
      props.data.form.filter((e) => e.id === "start_date").length > 0 &&
      start_date !== ""
    ) {
      let prepared_date;
      if (props.data.resource_key === "alert") {
        prepared_date = moment(start_date).format("MM/DD/YYYY HH:mm");
      } else {
        prepared_date = moment(start_date).format("MM/DD/YYYY");
      }
      setFormData({ ...formData, ...{ start_date: prepared_date } });
      setFormValidation({
        ...formValidation,
        ["start_date"]: true,
      });
      setFormDataErrors({
        ...formDataErrors,
        ["start_date"]: false,
      });
    }
    if (
      formData["end_date"] != end_date &&
      props.data.form.filter((e) => e.id === "end_date").length > 0 &&
      end_date !== ""
    ) {
      let prepared_date;
      if (props.data.resource_key === "alert") {
        prepared_date = moment(end_date).format("MM/DD/YYYY HH:mm");
      } else {
        prepared_date = moment(end_date).format("MM/DD/YYYY");
      }
      let element = props.data.form.filter((e) => e.id === "end_date");
      if (element && element.length > 0 && _checkParentSelected(element[0])) {
        setFormData({ ...formData, ...{ end_date: prepared_date } });
        setFormValidation({
          ...formValidation,
          ["end_date"]: true,
        });
        setFormDataErrors({
          ...formDataErrors,
          ["end_date"]: false,
        });
      }
    }
    return {
      start_date: start_date,
      date: date,
      end_date: end_date,
      rent_start_date: rent_start_date,
      rent_end_date: rent_end_date,
      start_date_activity: start_date_activity,
      end_date_activity: end_date_activity,
      start_date_alert: start_date_alert,
      end_date_alert: end_date_alert,
      lease_start_date: lease_start_date,
      lease_end_date: lease_end_date,
      sold_date: sold_date,
      date_proposal: date_proposal,
      next_payment_date: next_payment_date,
      date_bought: date_bought,
      date_created: date_created,
      date_birth: date_birth,
      date_registration: date_registration,
    };
  };



  const getRadioOption = (element) => {
    let item = element.options.filter((item) => item.value === element.value);
    if (item.length > 0) {
      return item[0].label;
    } else {
      return "-";
    }
  };
  const _changePhoneNumber = (value, element) => {
    setFormData({
      ...formData,
      [element.id]: value,
    });
    // setFormValidation({
    //   ...formValidation,
    //   [element.id]: true
    // });
    // setFormDataErrors({
    //   ...formDataErrors,
    //   [element.id]: false
    // });
  };

  const buttonAction = () => {
    let action = props.data.code.split("_", 1);

    if (!manualDisabled) {
      if (action[0] === "edit") {
        return false;
      } else {
        if (props.checkFormValidation === true) {
          return Object.values(formValidation).includes(false);
        } else {
          return Object.values(formDataErrors).includes(true);
        }
      }
    } else {
      return false;
    }
  };
  const getHeadingTitle = (data, show) => {

    let heading_title = "";

    try {
      if (data.show_title !== undefined && data.code !== "edit_document") {
        heading_title = show ? data.show_title : data.edit_title;
      } else if (data.title !== undefined && data.code !== "edit_document") {
        heading_title = data.title;
        // heading_title = heading_title.replace("Add ", "")
      } else {
        //heading_title = data.resource_key.replace(/_/g, " ");
        if(data.code !== "edit_document"){
          heading_title = data.title_modal;
        }else if(data.code === "edit_document" && !show){
          heading_title = data.edit_title;
        }

      }
      if (show) {
        let name_input

        if(getHeadingTitleType(data) !== "Individual"){
          name_input = data.form.filter(
            (e) => e.id === "registered_name"
          );
        }else {
          name_input = data.form.filter(
            (e) => e.id === "name" || e.id === "title" 
          );
        }


        if (name_input.length > 0) {
          heading_title = name_input[0].value;
        }
      }
    } catch (e) {

      heading_title = data.resource_key;
    }
    if(["general_monthly", "general_quarterly", "private_equity_monthly", "private_equity_quarterly"].includes(heading_title)){
      heading_title = data.show_title
    }
    return heading_title;
  };

  const getHeadingTitleType = (data) => {

    let type;

    data.form.map(itemElement => {
      if(itemElement.id === "type_owner"){

        type = itemElement.options.filter(
          (item) => item.id === itemElement.value
        )[0].value
      }else if(itemElement.id === "name" || itemElement.id === "title"){
        if(itemElement.value !== ""){
          type = "Individual"
        }
      }
    })

    return type

  }

  const getOptions = (items, id, options, checkValueShow = true) => {
    if (id === "value_show" && checkValueShow){
      let element = props.data.form.filter(e => e.id === id)[0]
      setShowModalConfirmText({items: items, id: id, options: options, title: languageUserAgent == "es" ? "Alerta" : "Alert", body: element.confirmation_message})
      return
    }
    if (id === "inviteds") {
      let allItems = items.map(obj => obj.id);
      setFormData({
        ...formData,
        [id]: allItems,
      });
      return
    }
    if(id === "installment_value" && formData["installment_type"] !== undefined){
      let installment_type = formData["installment_type"]

      let data = []
      if(installment_type === 0){
         Array.from({length: 36}, (el, index) => data.push({"id": index, "value": index + 1, "related_fields": []})) 
      }else{
        Array.from({length: 30}, (el, index) => data.push({"id": index, "value": index + 1, "related_fields": []}))
      }
      options = data
    }
    
    let temp_form_validation = formValidation
    let related_fields = options.filter(
      (elem) => "" + elem.id === "" + items.item.id
    )[0].related_fields;

    if (dynamicInputs[id] !== undefined) {
      dynamicInputs[id].length > 0 &&
        dynamicInputs[id].map((element) => {
          let parent2 = props.data.form.filter(
            (input) => input.parent === element
          );
          if (parent2.length > 0) {
            parent2.map((p) => {
              delete formValidation[p.id];
              delete formData[p.id];
            });
          }
          delete formValidation[element];
          delete formData[element];
        });
      temp_form_validation = {}
      related_fields.length > 0 &&
        related_fields.map((elem) => {
          let real_elem = props.data.form.filter(
            (input) => input.id === elem
          )[0];
          Object.assign(temp_form_validation, {
            [elem]: real_elem && real_elem.required && (real_elem.value === "") ? false : true,
          });
        });
    }
    //calculate currency conversion

    if(id === "currency_type" && items.item.value !== 'USD' && formData["value"] !== "" && formData["value"] !== undefined){
      props.getConvertedValue(formData["value"], items.item.value, formData["date"], (responseJSON) => convertedValueSuccess(responseJSON) , () => {})
    }

    if(id === "crypto_type" && items.item.value !== '' && formData["value"] !== "" && formData["value"] !== undefined){
      props.getConvertedValue(formData["value"], items.item.value, formData["date"], (responseJSON) => convertedValueSuccess(responseJSON) , () => {})
    }

    let final_value_capital_amortization = undefined;
    let final_value_capital_amortization_raw = undefined;
    if(id === "capital_amortization_frecuency" && formData["principal_amount"] !== "" && formData["start_date"] !== ""  && formData["installment_type"] !== "" && formData["installment_value"] !== ""  ){      
      let new_result = undefined;
      let data = formData
      data["frecuency"] = items.item.id
      data["resource_id"] = props.resource_id
      data["resource_type"] = props.resource_key      
      let temporal_form_data = {...formData, [id]: items.item.id}
      props.getCapitalAmortizationValue(data, (responseJSON) => capitalAmortizationValueSuccess(responseJSON, temporal_form_data), () => console.log("error getCapitalAmortizationValue")) 
    }

    //get form to create asset
    let input_found = props.data.form.filter((input) => input.id === id);
    let wait_to_apply_changes = false
    if (items.item && items.item.end_point === true) {
      props.getLinkForm &&
        props.getLinkForm(items.item.id, (response) =>
          formLinked(response, items)
        );
      // params["liability"]["form_linkable"]["vehicle"][0] ->{hash del ve?chulo}
      //params["real_estate"]["form_linkable"]["liabilities"][0]->{hash del liability}
    } else if (input_found.length > 0 && input_found[0].linking === true) {
      let linked_existent = formData.hasOwnProperty("linked_existent")
        ? formData["linked_existent"]
        : {};
      if (linked_existent.hasOwnProperty(id)) {
        let current_data = linked_existent[id];
        current_data.push(items.item.id);
        linked_existent[id] = current_data;
      } else {
        linked_existent[id] = [items.item.id];
      }
      setFormData({
        ...formData,
        ["linked_existent"]: linked_existent,
        ["associated_liabilities"]: "",
      });
    } else {
      if (
        formData.linked_existent &&
        Object.keys(formData.linked_existent).length === 0
      ) {
        setFormData({
          ...formData,
          [id]: items.item.id,
          ["linked_existent"]: {},
        });
      } else {
        let action = props.data.code.split("_", 1);
        if(props.data.resource_key === "real_estate" && id == "purpose" && action == "edit"){
          // props.getLinkForm(`/balance_principal/tenants?ownerable_id=${props.resourceable_id}&ownerable_type=${props.resourceable_type}`, (response) =>
          //   _setAssignTenants(response)
          // );
         
          if (input_found.length > 0){
            if ((input_found[0].value == 3 || input_found[0].value == 5) && items.item.id !== 3 && items.item.id !== 5){
               if(languageUserAgent == "es"){
                  setShowTenantData({
                    title: "Atención",
                    body: "Estas cambiando a un tipo de Real Estate que no tiene inquilinos. Si realizas este cambio, se borraran todos los inquilinos asociados a este activo.",
                    action: () => {
                      setFormData({
                        ...formData,
                        [id]: items.item.id,
                      });
                    }
                 })
               }
               else{
               setShowTenantData({
                  title: "Warning",
                  body: "You are switching to a Real Estate type that does not have tenants. If you save this change, all tenants associated with this Asset will be deleted.",
                  action: () => {
                    setFormData({
                      ...formData,
                      [id]: items.item.id,
                    });
                  }
               })

               }
               setShowTenantModal(true)

               wait_to_apply_changes = true
            }        
          }
        }
        
        if (!wait_to_apply_changes){
          setFormData({
            ...formData,
            [id]: items.item.id,
          });  
        }
        
      }

      setFormValidation({
        ...temp_form_validation,
        [id]: true,
      });
      setFormDataErrors({
        ...formDataErrors,
        [id]: false,
      });

      if (
        dynamicInputs[dynamicInputs[id]] !== undefined &&
        related_fields.length === 0
      ) {
        setDynamicInputs({
          ...dynamicInputs,
          [id]: related_fields,
          [dynamicInputs[id]]: [],
        });
      } else {
        if(checkSaveRelatedField()){
          setDynamicInputs({
            ...dynamicInputs,
            [id]: related_fields,
          });
        }
      }
    }

  };

  const capitalAmortizationValueSuccess = (responseJSON, temporal_form_data) => {
    
      setTimeout(() => {
      let valuation_converted_html = document.querySelector("#capital_amortizations");
      valuation_converted_html.value = responseJSON.value
      setNativeValue(valuation_converted_html, responseJSON.value) 
      setFormData({
        ...formData,
        ...temporal_form_data,
        ["capital_amortizations_raw"]: responseJSON.raw_value,
        ["capital_amortizations"]: responseJSON.raw_value
      }) 
      props.getConvertedValue(responseJSON.raw_value, formData["currency_type"], formData["date"], (responseJSON) => convertedValueSuccess(responseJSON, {id: "capital_amortizations"}) , () => {})
    },500)
  }
  
  const checkSaveRelatedField = () => {
    if(["life_insurance","add_life_insurance"].includes(props.data.resource_key)){
      if(["add_asset","edit_asset","add_life_insurance"].includes(props.data.code)){
        return true
      }else {
        return false
      }
    }else {
      return true
    }
  }

  const _checkValidated = () => {
    let result
    if(!manualDisabled){
      result =
        Object.values(formDataErrors).every((value) => value === false) &&
        Object.values(formValidation).every((value) => value === true);
    }else {
      result = true
    }


    return !result;
  };


  const _checkValidatedResource = () => {
    let result =
      Object.values(formDataErrors).every((value) => value === false) &&
      Object.values(formValidation).every((value) => value === true) &&
      (props.data.newAsset
        ? true
        : props.data.code === "add_document" && selectOwnerable !== "");

    return !result;
  };

  const formLinked = (responseJSON, item_selected) => {
    let asset_json =
      responseJSON.form_linkable.add_asset ||
      responseJSON.form_linkable.add_liability;
    setFormLinkable({
      ...formLinkable,
      [asset_json.resource_key]: asset_json.inputs,
    });
    setModalData({
      form: asset_json.inputs,
      resource_key: asset_json.resource_key,
      can_manage: true,
      url: "",
      method: "",
      title: item_selected.value,
      code: "",
      assets: [],
      current_position: undefined,
      linked: true,
      input_linked_id: asset_json.input_linked_id,
    });
    setSecondModal(true);
    setShowModal(true);
  };
  const _actionSheetPressed = (item) => {
    setModalData({
      newAsset: true,
      form: item.resource_key === "contact" ? item : item.inputs,
      resource_key: item.resource_key,
      can_manage: item.can_manage,
      url: item.url,
      method: item.method,
      title: item.title,
      code: item.code,
      assets: item.assets || [],
      current_position: undefined,
    });
    setShowModal(true);
    // ActionSheetOnPressAction(item, {[item.code]: }, props);

    actionSheet.current.show();
  };

  const _showActionSheetContent = () => {
    let optionsActionSheet = [];
    props.data.associated_elements.map((item) => {
      let key_i = Object.keys(item)[0];
      if (key_i === "add_alert") {
        return optionsActionSheet.push(
          {
            type: "icon button",
            iconName: "Close",
            message:
            props.buttons.add === "A?adir" ? "A?adir Imagen" : "Add Image",
            onClick: () => {
              actionSheet.current.show();
              loadFileRef.click();
            },
          },
          {
            type: "icon button",
            iconName: "Notification",
            message: item[key_i].title,
            onClick: _getActionSheetMethod(item[key_i]),
          }
        );
      } else {
        return optionsActionSheet.push({
          type: "icon button",
          iconName: "Close",
          message: item[key_i].title,
          onClick: _getActionSheetMethod(item[key_i]),
        });
      }
    });
    setActionSheetOptions([
      ...optionsActionSheet,

      {
        type: "noIcon button",
        message: props.buttons.cancel,
        close: true,
        danger: true,
      },
    ]);

    setTimeout(function () {
      actionSheet !== undefined && actionSheet.current.show();
    }, 100);
  };

  const _getActionSheetMethod = (item) => {
    switch (item.code) {
      case "add_images":
        return () => {
          actionSheet.current.show();
          loadFileRef.click();
        };
      case "add_document":
        return () => {
          _openDocumentsOptions(item);
        };
      case "add_contact":
        return () => {
          _openContactsOptions(item);
        };
      case "add_alerts":
        return () => {
          _actionSheetPressed(item);
        };
      default:
        return () => {
          _actionSheetPressed(item);
        };
    }
  };

  const _openDocumentsOptions = (item) => {
    var that = this;
    let optionsActionSheet = [];
    optionsActionSheet.push({
      type: "icon button",
      iconName: item.code.includes("add")
      ? "Close"
      : item.code.includes("edit")
      ? "Pencil"
      : item.code.includes("show")
      ? "Eye"
      : "Garbage",
      message: item.title,
      onClick: () => _actionSheetPressed(item),
    });
    optionsActionSheet.push({
      type: "icon button",
      message: props.buttons.assign_document,
      iconName: "Assign",
      onClick: () => {
        actionSheet !== undefined && actionSheet.current.show();
        _setAssignDocument();
        setShowModalAssign(true);
      },
    });

    setActionSheetOptions([
      ...optionsActionSheet,
      {
        type: "noIcon button",
        message: "Cancelar",
        close: true,
        danger: true,
      },
    ]);

    // setTimeout(function() {
    //   actionSheet !== undefined && actionSheet.current.show();
    // }, 100);
  };

  const _setAssignDocument = () => {
    let resource_constant = props.data.resource_constant || props.resource_constant
    let assigned_contacts = formData.hasOwnProperty("document_assets") ? formData["document_assets"]["document_ids"] : []
    if(props.data.code == "edit_tenant" || props.data.code == "edit_stake_holder" ){
      assigned_contacts = props.data.documents.map((e) => e.id)
    }
    setAssignData({
      type: "document",
      action_to_refresh: true,
      url_from: "",
      id_from: "",
      url:
      "documents/documents?section_list=true&resourceable_type=" +
      resource_constant,
      id: "document",
      resourceable_id: "",
      resourceable_type: "",
      location: "",
      backAction: () => setShowModalAssign(false),
      fetch: () => console.log("fetch"),
      assigned_contacts: assigned_contacts,
      specialAssign: (id, type, all_section) =>
      _setAssign(id, "document", all_section),
      messageAlert: "Go back",
      modal: true,
    });
  };

  const _setAssignContact = () => {
    let assigned_contacts = formData.hasOwnProperty("contact_assets") ? formData["contact_assets"]["contact_ids"] : []
    if(props.data.code == "edit_tenant" || props.data.code == "edit_stake_holder" ){
      assigned_contacts = props.data.contacts.map((e) => e.id)
    }    
    setAssignData({
      type: "contact",
      action_to_refresh: true,
      url_from: "",
      id_from: "",
      url: "balance_principal/contacts",
      id: "document",
      resourceable_id: "",
      resourceable_type: "",
      location: "",
      backAction: () => setShowModalAssign(false),
      fetch: () => console.log("fetch"),
      assigned_contacts: assigned_contacts,
      specialAssign: (id, type, all_section) =>
      _setAssign(id, "contact", all_section),
      messageAlert: "Go back",
      modal: true,
    });
  };

  const _setAssign = (ids, type, all_section) => {
    let selected_titles = [];
    all_section.map((section, index) => {
      section.data.map((subsection, index) => {
        if (ids.includes(subsection.id)) selected_titles.push(subsection);
      });
    });

    if (type === "contact") {
      setFormData({
        ...formData,
        ["contact_assets"]: { contact_ids: ids },
      });
    } else {
      setFormData({
        ...formData,
        ["document_assets"]: { document_ids: ids },
      });
    }
    setAssignDataTitles({ ...assignDataTitles, [type]: selected_titles });
    setShowModalAssign(false);
  };

  const _openContactsOptions = (item) => {
    var that = this;
    let optionsActionSheet = [];
    optionsActionSheet.push({
      type: "icon button",
      iconName: item.code.includes("add")
      ? "Close"
      : item.code.includes("edit")
      ? "Pencil"
      : item.code.includes("show")
      ? "Eye"
      : "Garbage",
      message: item.title,
      onClick: () => _actionSheetPressed(item),
    });
    optionsActionSheet.push({
      type: "icon button",
      message: props.buttons.assign_contact,
      iconName: "Assign",
      onClick: () => {
        actionSheet !== undefined && actionSheet.current.show();
        _setAssignContact();
        setShowModalAssign(true);
      },
    });
    setActionSheetOptions([
      ...optionsActionSheet,
      {
        type: "noIcon button",
        message: "Cancelar",
        close: true,
        danger: true,
      },
    ]);
  };

  const addResource = (data, current_position) => {
    let prepare_array = {};
    if (modalData.linked === true) {
      let form_linked = formData.hasOwnProperty("form_linked")
        ? formData["form_linked"]
        : {};
      let linked_info = data[modalData.resource_key];
      delete linked_info["resourceable_id"];
      delete linked_info["resourceable_type"];
      if (form_linked.hasOwnProperty(modalData.resource_key)) {
        let current_data = form_linked[modalData.resource_key];
        current_data.push(linked_info);
        form_linked[modalData.resource_key] = current_data;
      } else {
        form_linked[modalData.resource_key] = [linked_info];
      }
      if (
        modalData.input_linked_id === "trusts" ||
        modalData.input_linked_id === "corporations"
      ) {
        setKeyAssetLink("associated_owner");
      }

      setFormData({
        ...formData,
        ["form_linked"]: form_linked,
      });
    }
    if (!["contact", "document", "alert"].includes(modalData.resource_key)) {
      if (current_position !== undefined) {
        let form_linked = formData["form_linked"];
        form_linked = JSON.parse(JSON.stringify(form_linked));
        prepare_array = formData["form_linked"][modalData.resource_key];
        let current_values =
          formData["form_linked"][modalData.resource_key][current_position];
        let linked_info = data[modalData.resource_key];
        Object.keys(current_values).map((key) => {
          if (linked_info[key] === undefined) {
            linked_info[key] = current_values[key];
          }
        });
        prepare_array.splice(current_position, 1);
        prepare_array.push(linked_info);
        delete linked_info["resourceable_id"];
        delete linked_info["resourceable_type"];
        form_linked[modalData.resource_key] = prepare_array;
        setFormData({
          ...formData,
          ["form_linked"]: form_linked,
        });
      }
    }
    if (modalData.resource_key === "contact") {
      prepare_array = formData.hasOwnProperty("contacts")
        ? formData["contacts"]
        : [];
      let contact_info = data.contact;
      if (current_position !== undefined) {
        let current_values = prepare_array[current_position];
        Object.keys(current_values).map((key) => {
          if (contact_info[key] === undefined) {
            contact_info[key] = current_values[key];
          }
        });
      }
      if (current_position !== undefined) {
        prepare_array.splice(current_position, 1);
      }
      delete contact_info["resourceable_id"];
      delete contact_info["resourceable_type"];
      prepare_array.push(data.contact);
      setFormData({
        ...formData,
        ["contacts"]: prepare_array,
      });
    }
    if (modalData.resource_key === "document") {
      prepare_array = formData.hasOwnProperty("documents")
        ? formData["documents"]
        : [];
      let contact_info = data.document;
      if (current_position !== undefined) {
        let current_values = prepare_array[current_position];

        Object.keys(current_values).map((key) => {
          if (contact_info[key] === undefined) {
            contact_info[key] = current_values[key];
          }
        });
      }
      if (current_position !== undefined) {
        prepare_array.splice(current_position, 1);
      }
      delete contact_info["resourceable_id"];
      delete contact_info["resourceable_type"];
      delete contact_info["images"];
      prepare_array.push(data.document);
      setFormData({
        ...formData,
        ["documents"]: prepare_array,
      });
    }
    if (modalData.resource_key === "alert") {
      prepare_array = formData.hasOwnProperty("alerts")
        ? formData["alerts"]
        : [];
      let contact_info = data.alert;
      if (current_position !== undefined) {
        let current_values = prepare_array[current_position];
        Object.keys(current_values).map((key) => {
          if (contact_info[key] === undefined) {
            contact_info[key] = current_values[key];
          }
        });
      }
      if (current_position !== undefined) {
        prepare_array.splice(current_position, 1);
      }
      delete contact_info["resourceable_id"];
      delete contact_info["resourceable_type"];
      prepare_array.push(data.alert);
      setFormData({
        ...formData,
        ["alerts"]: prepare_array,
      });
    }
    setShowModal(false);
    setModalData({});

    // documents
    // contacts
  };

  const deleteResource = (key, index, e) => {
    let prepare_array = {};
    let current_elements = formData[key];
    current_elements.splice(index, 1);
    setFormData({
      ...formData,
      [key]: current_elements,
    });
    e.preventDefault();
    e.stopPropagation();
    return false;
    // documents
    // contacts
  };

  const deleteResourceLinked = (super_key, key, index, e) => {
    let prepare_array = formData[super_key];
    let current_elements = formData[super_key][key];
    current_elements.splice(index, 1);
    prepare_array[key] = current_elements;
    setFormData({
      ...formData,
      [super_key]: prepare_array,
    });
    // e.preventDefault();
    // e.stopPropagation();
    return false;
    // documents
    // contacts
  };

  const deleteResourceFromModal = () => {
    let current_elements = {};
    let key = "";
    if (modalData.resource_key === "contact") {
      current_elements = formData["contacts"];
      key = "contacts";
    }
    if (modalData.resource_key === "document") {
      current_elements = formData["documents"];
      key = "documents";
    }
    if (modalData.resource_key === "alert") {
      current_elements = formData["alerts"];
      key = "alerts";
    }
    current_elements.splice(modalData.current_position, 1);
    setFormData({
      ...formData,
      [key]: current_elements,
    });
    setShowModal(false);
    setModalData({});
  };

  const _getImage = (item) => {
    
    if (
      item.avatar_url &&
      item.avatar_url.avatar_default_id !== null &&
      item.avatar_url.avatar_default_id !== undefined
    ) {
      return avatars[item.avatar_url.avatar_default_id].default;
    } else if (item.avatar_url && item.avatar_url.url !== null) {
      return item.avatar_url.url;
    } else if (item.images && item.images.length > 0) {
      if (item.images[0].id !== undefined) {
        return avatars[item.images[0].id].default;
      } else {
        return (
          "data:" +
          item.images[0].content_type +
          ";base64, " +
          item.images[0].data
        );
      }
    }else {
      return "https://iupac.org/wp-content/uploads/2018/05/default-avatar.png"
    }
    
  };

  const _showAssociatedElement = (type, index, super_key = undefined) => {
    let associated = [];
    let inputs = [];
    let code = "";
    let entered = false; 
    if (type === "contact" && formData["contacts"]) {
      entered = true
      let current_values = formData["contacts"][index];
      associated = props.data.associated_elements
        .filter((e) => Object.keys(e)[0] === "add_contact")
        .slice();

      associated = JSON.parse(JSON.stringify(associated[0].add_contact));

      if (current_values.hasOwnProperty("address_contact")) {
        associated.address.inputs.map((item, index) => {
          current_values.address_contact.map((elem) => {
            if (item.id === "address_contact") {
              item.value = current_values.address_contact
                .filter((elem) => elem["catalogue_id"])
                .map((o) => o["catalogue_id"]);
            } else {
              item.value = current_values.address_contact
                .filter((elem) => elem[item.id])
                .map((o) => o[item.id]);
            }
          });
        });

        associated.address.inputs.map((item) => {
          let allValues = [];
          let allIds = [];
          if (item.value && item.value.length > 0) {
            item.value.map((elem) => {
              if (item.id === "address_contact") {
                allIds.push({ catalogue_id: elem });
                item.value = allIds;
              } else {
                allValues.push({ value: elem });
                item.value = allValues;
              }
            });
          }
        });
      }

      if (current_values.hasOwnProperty("images")) {
        associated.inputs.map((item) => {
          if (item.id === "image") {
            if (current_values.images[0].id !== undefined) {
              item.value = { avatar_default_id: current_values.images[0].id };
            } else {
              current_values["images"].map((elem) => {
                item.value = elem;
              });
            }
          }
        });
      }

      if (current_values.hasOwnProperty("phone")) {
        let allValues = [];
        associated.inputs.map((item) => {
          if (item.id === "phone") {
            current_values["phone"].map((elem) => {
              allValues.push(elem);
            });
            item.value = allValues;
          }
        });
      }

      if (current_values.hasOwnProperty("email")) {
        let allValues = [];
        associated.inputs.map((item) => {
          if (item.id === "email") {
            current_values["email"].map((elem) => {
              allValues.push(elem);
            });
            item.value = allValues;
          }
        });
      }

      if (current_values.hasOwnProperty("special_date")) {
        let allValues = [];
        associated.inputs.map((item) => {
          if (item.id === "special_date") {
            current_values["special_date"].map((elem) => {
              allValues.push(elem);
            });
            item.value = allValues;
          }
        });
      }

      associated.profile.inputs.map((item) => {
        if (current_values.hasOwnProperty(item.id)) {
          item.value = current_values[item.id];
        }
      });

      code = "edit_contact";
    } else if (type === "document") {
      entered = true
      let current_values = formData["documents"][index];
      associated = props.data.associated_elements
        .filter((e) => Object.keys(e)[0] === "add_document")
        .slice();
      associated = JSON.parse(JSON.stringify(associated[0].add_document));
      associated.inputs.map((input) => {
        if (current_values[input.id] !== undefined) {
          if (input.id === "category_id") {
            input.value = parseInt(current_values[input.id]);
          } else {
            input.value = current_values[input.id];
          }
        }
        if (input.id === "content") {
          input.value = current_values["file"].filename;
          input.file_name = current_values["file"].filename;
        }
        inputs.push(input);
      });
      code = "edit_document";
    } else if (type === "alert") {
      entered = true
      let current_values = formData["alerts"][index];
      associated = props.data.associated_elements
        .filter((e) => Object.keys(e)[0] === "add_alert")
        .slice();
      associated = JSON.parse(JSON.stringify(associated[0].add_alert));
      associated.inputs.map((input) => {
        if (current_values[input.id] !== undefined) {
          input.value = current_values[input.id];
        }
        inputs.push(input);
      });
      code = "edit_alert";
    } else if (super_key === "form_linked") {
      entered = true
      let current_values = formData["form_linked"][type][index];

      associated = formLinkable[type];
      associated = JSON.parse(JSON.stringify(associated));
      associated.map((input) => {
        if (current_values[input.id] !== undefined) {
          input.value = current_values[input.id];
        }
        inputs.push(input);
      });
      code = "edit_asset";
    }

    if(entered){
       setModalData({
        form: type === "contact" ? associated : inputs,
        resource_key: type,
        can_manage: true,
        url: associated.url,
        method: associated.method,
        title: associated.title,
        code: code,
        assets: associated.assets || [],
        current_position: index,
      });
      setShowModal(true);   
    }
   
  };

const getExtension = () => {
  let extension = props.data.form.filter((item) => item.extension !== "");
  if (extension.length > 0) return extension[0].extension;
  return "";
};

const getIconName = () => {
  let extension = getExtension();
  switch (extension) {
    case "pdf":
      return "DocPdfSquare";
      break;
    case "xlsx":
      return "DocExcelSquare";
      break;
    case "pptx":
      return "DocPowerPointSquare";
      break;
    case "docx":
      return "DocWordSquare";
      break;
    default:
      return "DocFolderSquare";
  }
};

const currentAsset = (id) => {
  return id === parseInt(props.data.idMain);
};

const assetList = () => {

  let object_resources =
    resourcesToSelect.length == 0 &&
    props.resourcesToSelect &&
    props.resourcesToSelect.length !== 0
    ? props.resourcesToSelect
    : resourcesToSelect;

  if (
    !props.data.files &&
    object_resources.length === 1 &&
    props.data.code !== "add_document"
  ) {
    if (
      props.data.code === "edit_owner" ||
      props.data.code === "edit_alert" ||
      props.data.code === "edit_beneficiary"
    ) {
      return object_resources;
    } else {
      return [];
    }
  } else {
    if (props.data.idMain && typeof object_resources !== "object") {
      let all = object_resources;
      let index = object_resources.findIndex(
        (item) => item.resourceable_id === parseInt(props.data.idMain)
      );
      let current = object_resources[index];
      all.splice(index, 1);
      all.splice(0, 0, current);
      return all;
    } else {
      if (typeof object_resources === "object") {
        return object_resources.length > 0 ? object_resources : [];
      } else {
        return object_resources;
      }
    }
  }
};


const getDisabledOptions = (id) => {
  let input_found = props.data.form.filter((input) => input.id === id);
  if (input_found.length > 0 && input_found[0].linking === true) {
    let linked_existent = formData.hasOwnProperty("linked_existent")
      ? formData["linked_existent"]
      : {};
    if (linked_existent.hasOwnProperty(id)) {
      let current_data = linked_existent[id];
      return current_data;
    } else {
      return [];
    }
  }
  return [];
};

const renderLinkedExistent = (elementId) => {
  
  let newFormData = formData && JSON.parse(JSON.stringify(formData));

  if (
    newFormData.hasOwnProperty("linked_existent") &&
    Object.keys(newFormData["linked_existent"]).length > 0
  ) {
    if (
      props.data.code === "edit_ownership" ||
      props.data.code === "add_corporation" ||
      props.data.code === "add_trust"
    ) {
      if (elementId !== "notes") {
        delete newFormData.linked_existent["liabilities"];
      } else {
        newFormData = formData && JSON.parse(JSON.stringify(formData));
        Object.keys(newFormData.linked_existent).map((item) => {
          if (item !== "liabilities") {
            delete newFormData.linked_existent[item];
          }
        });
      }
    }
  }

  return (
    newFormData.hasOwnProperty("linked_existent") &&
    Object.keys(newFormData["linked_existent"]).length > 0 &&
    Object.keys(newFormData["linked_existent"]).map((element_key, i_key) => {
      let inputs_type = props.data.form.filter(
        (e) => e.id === element_key
      )[0];
      return newFormData["linked_existent"][element_key].map(
        (element_id, i) => {
          let element = inputs_type.options.filter(
            (e) => e.id === element_id
          )[0];
          return (
            <BoxResources
              edit={true}
              iconName={inputs_type.id === "vehicles" ? 
                        getIconAsset(element.type_vehicle) :  
                        getIconAsset(inputs_type.key || inputs_type.id || inputs_type.policy_number)}
              title={element.value}
              description={element.description}
              actionDelete={(e) =>
                  deleteResourceLinked("linked_existent", element_key, i, e)
              }
              paddinBottomTen={true}
            />
          );
        }
      );
    })
  );
};

const renderFormLinked = (elementId) => {

  let newFormData = formData && JSON.parse(JSON.stringify(formData));

  if (
    newFormData.hasOwnProperty("linked_existent") &&
    Object.keys(newFormData["linked_existent"]).length > 0
  ) {
    if (
      props.data.code === "edit_ownership" ||
      props.data.code === "add_corporation" ||
      props.data.code === "add_trust"
    ) {
      if (elementId !== "notes") {
        delete newFormData.linked_existent["liabilities"];
      } else {
        newFormData = formData && JSON.parse(JSON.stringify(formData));
        Object.keys(newFormData.linked_existent).map((item) => {
          if (item !== "liabilities") {
            delete newFormData.linked_existent[item];
          }
        });
      }
    }
  }

  return (
    formData.hasOwnProperty("form_linked") &&
    Object.keys(formData["form_linked"]).length > 0 &&
    Object.keys(formData["form_linked"]).map((element_key, i_key) => {
      return formData["form_linked"][element_key].map((element, i) => {
        return (
          <BoxResources
            edit={true}
            iconName={element.hasOwnProperty("vehicle_type") ? getIconAsset(element.vehicle_type) : getIconAsset(element_key)}
            title={element.name || element.policy_number}
            description={element.value}
            actionDelete={(e) =>
                deleteResourceLinked("form_linked", element_key, i, e)
            }
            actionShow={() =>
                _showAssociatedElement(element_key, i, "form_linked")
            }
            paddinBottomTen={true}
          />
        );
      });
    })
  );
};


const _checkInputId = () => {

  if(formData["type_loan"] === 1){
    return ["capital_amortization_frecuency"]
  }else {
    return["interest_rate_type"]
  }

}

const renderBoxAsset = () => {
  switch (props.data.code) {
    case "add_vehicle":
      return ["country_registration"];
      break;
    case "add_art":
      return ["insurance_company"];
      break;
    case "add_watch":
      return ["annual_premium"];
      break;
    case "add_liability":
      return ["interest_rate_type"];
      break;
    case "add_private_equity":
      return ["description"];
      break;
    case "add_real_estate":
      return ["property_tax_predial"];
      break;
    case "add_private_equity":
      return ["description"];
      break;
    case "add_owner":
      return [
        dynamicInputs.associated_owner &&
        dynamicInputs.associated_owner.length > 0
        ? dynamicInputs.associated_owner[0]
        : keyAssetLink,
      ];
      break;
    case "add_beneficiary":
      return [
        dynamicInputs.associated_owner &&
        dynamicInputs.associated_owner.length > 0
        ? dynamicInputs.associated_owner[0]
        : keyAssetLink,
      ];
      break;
    case "add_other":
      return ["notes"];
      break;
    case "add_asset":
      if (props.data.resource_key === "private_equity") {
        return ["description"];
      } else if (props.data.resource_key === "other") {
        return ["notes"];
      } else if (props.data.resource_key === "real_estate") {
        return ["property_tax_predial"];
      } else if (props.data.resource_key === "liability") {
        return ["interest_rate_type"];
      }else if (props.data.resource_key === "life_insurance") {
        return ["liabilities"];
      }
      break;
    case "add_life_insurance":
      return ["value_show"];
      break;
    case "add_trust":
      return ["notes", "link_liability"];
      break;
    case "add_corporation":
      return ["notes", "link_liability"];
      break;
    case "edit_owner":
      return [
        dynamicInputs.associated_owner &&
        dynamicInputs.associated_owner.length > 0
        ? dynamicInputs.associated_owner[0]
        : keyAssetLink,
      ];
      break;
    case "edit_beneficiary":
      return [
        dynamicInputs.associated_owner &&
        dynamicInputs.associated_owner.length > 0
        ? dynamicInputs.associated_owner[0]
        : keyAssetLink,
      ];
      break;
    case "edit_ownership":
      if (props.data.resource_key === "corporation") {
        return ["notes", "link_liability"];
      } else if (props.data.resource_key === "trust") {
        return ["notes", "link_liability"];
      } else {
        return [];
      }
    case "edit_asset":
      if (props.data.resource_key === "vehicle") {
        return ["country_registration"];
      } else if (props.data.resource_key === "real_estate") {
        return ["property_tax_predial"];
      } else if (props.data.resource_key === "other") {
        return ["notes"];
      } else if (props.data.resource_key === "art") {
        return ["insurance_company"];
      } else if (props.data.resource_key === "watch") {
        return ["annual_premium"];
      } else if (props.data.resource_key === "liability") {
        return _checkInputId();
      } else if (props.data.resource_key === "private_equity") {
        return ["description"];
      } else if (props.data.resource_key === "life_insurance") {
        return ["value_show"];
      } else if (
        props.data.resource_key === "trust" ||
        props.data.resource_key === "corporation"
      ) {
        return ["notes"];
      }
    default:
      return [];
  }
};



const _closeModal = (e) => {
  if (
    e.target.className === "modal-wrapper" ||
    (e.target.closest(".modal-header") !== null &&
      e.target.closest(".modal-header").className.includes("modal-header"))

  ) {
    e.preventDefault();
    e.stopPropagation();
    if (!secondModal) {
      setShowModal(false);
      setModalData({});
    } else {
      setSecondModal(false);
      setShowModal(false);
      setModalData({});
    }
  }
};

const showSiblingEdit = (element) => {
  if ((element.sibling && element.sibling !== "") || element.sibling_right) {
    if (!manualDisabled) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const renderSibling = (element) => {
  if (
    props.data.code === "edit_owner" ||
    props.data.code === "edit_insured" ||
    props.data.code === "edit_beneficiary"
  ) {
    return (
      <div
        className={
          element.sibling !== "" && manualDisabled
            ? "wrapper-form-item disabled"
            : ""
        }
        style={{ display: "flex" }}
      >
        {element.sibling &&
            element.sibling !== "" &&
            manualDisabled &&
            props.data.form
            .filter(
              (sibling) => sibling.sibling !== "" || sibling.sibling_right
            )
            .map((elements, i) => {
              return (
                <div
                  style={{ paddingLeft: elements.sibling_right ? "40px" : 0 }}
                >
                  <p className="input-label">{elements.label}</p>
                  <p>{elements.value}</p>
                </div>
              );
            })}
      </div>
    );
  }
};



const _disabledOfuscated = (element, newOfuscated) => {

  let value = ""
  if(newOfuscated){
    value = elementOfuscated !== undefined ? createOfuscated(elementOfuscated) : ""
  }else {
    value = formData[element.id] !== undefined ? formData[element.id] : elementOfuscated === "" ? "" : element.value
  }
  setOfuscated(newOfuscated)

  setElementOfuscated(value)
}

const checkIfItems = (assets) => {
  for (let key in assets) {
    if(assets[key].length > 0){
      return true
    }else {
      return false
    }
  }
}

const showHintSelect = () => {

  let check = false
  if(formData.hasOwnProperty("linked_existent")){
    check = checkIfItems(formData.linked_existent)
  }else if(formData.hasOwnProperty("form_linked")){
    check = checkIfItems(formData.form_linked)
  }
  
  return check
}

const textInfoAsset = (element) => {
  let text = languageUserAgent === "en" ? "Your" : "Tu"
  return `${text} ${element.label_singularize
  } ${internalization[languageUserAgent].text_assets_select}`
}

const defaultValueSelect = (element) => {

  let selected_value =  formData[element.id] !== undefined
    ? element.options.filter(
      (item) => item.id === formData[element.id]
    ).length > 0
      ? element.options.filter(
        (item) => item.id === formData[element.id]
      )[0]
      : ""
      : element.options.filter(
        (item) => item.id === element.value
      )[0] 

  let prepared_selected = ["", selected_value]

  if (selected_value !== "" && selected_value !== undefined){
    prepared_selected =  [selected_value.id, selected_value.value]
  }

  return prepared_selected
  
}

const checkSpecialHint = (element) => {
  let element_result = defaultValueSelect(element)
  if(props.data.resource_key === "real_estate" && element.id == "purpose" && ["3","5"].includes(""+element_result[0]) ){
    let text = languageUserAgent == "es" ? "Se pueden <b>agregar inquilinos desde el menu de acceso rapido despues de crear el Real Estate </b>" : "You can add tenants <b> once the Real Estate is created, from the quick add menu </b>"
    return [true, text]
  }
  else{
    return [false, ""]
  }
}

const _getCurrencyTypeByField = (element) => {
  
  if(["capital_amortizations"].includes(element.id) && props.data.resource_key !== "liability" && props.data.resource_key !== "receivable_account") {
    return "$"
  }
  else if (formData["crypto_type"] !== "" && formData["type_other_assets"] === 5){
    return formData["crypto_type"]
  }
  else{
    let selected_element = props.data.form.filter((elem) => elem.id === 'currency_type')
    if(selected_element.length > 0){
      let options = selected_element[0].options
      let selected_options = options.filter((elem) => elem.id === formData['currency_type'])
      if(selected_options.length > 0){
        let currency_format = selected_options[0].currency_format || selected_options[0].id
        return currency_format;
      }
    }
    return formData['currency_type'] || 'USD'
  }
}

const _isCryptoType = (element) => {
  if (element.id == "value" && props.data.resource_key === "other" && formData["crypto_type"] !== "" && formData["type_other_assets"] === 5){
    return true
  }else{
    return false
  }

}

const _setCheckboxesInviteds = (id) => {
  let allItems = formData["inviteds"] || []
  let index = allItems.indexOf(id);

  if (index !== -1) {
    // Element found, remove it
    allItems.splice(index, 1);
  } else {
    // Element not found, add it
    allItems.push(id);
  } 
  setFormData({...formData, inviteds: allItems})
}

const deleteAssignedContact = (element, i, e) => {
  let contact_ids = formData["contact_assets"]["contact_ids"]
  let new_contact_ids = contact_ids.filter((e) => ""+e !== ""+element.id)
  setFormData({...formData, contact_assets: {"contact_ids": new_contact_ids}})
  let assignDataTitles_temp = assignDataTitles["contact"].filter((e) => ""+e.id !== ""+element.id)
  setAssignDataTitles({...assignDataTitles, "contact": assignDataTitles_temp})
}

const _cleanXLS = () => {
  setDisabledSaveButton(false);
  setXlsUploaded(false);
  setFormData({
    ...formData,
    ["import_xls"]: {},
    ["budgets"]: [],
  });
  setFormDataErrors({
    ...formDataErrors,
    ["file"]: true,
  });
  setFormValidation({
    ...formValidation,
    ["content"]: false,
    ["file"]: false,
    ["import_xls"]: false,
  });
}

  const getInstallmentValueLabel = (element) => {
    if(formData["installment_type"] !== undefined){
      let installment_type = formData["installment_type"]
      if(installment_type === 0){
        return props.language == "es-ES" ? "¿Cuántos meses?" : "How many months?"  
      }else{
        return props.language == "es-ES" ? "¿Cuántos años?" : "How many years?"
      }
      
    }else{
      return element.label
    }
  }

  const downloadFile = () => {

    let docFile= props.data.form.filter(item => item.id === "content")[0].value

    fetch(docFile).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = props.data.form[0].value;
        alink.click();
      })
    })

  }

  const printFile = () => {

    let docFile= props.data.form.filter(item => item.id === "content")[0].value

    fetch(docFile).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        printJS(fileURL);
      })
    })

  }

  const getSelectOptions = (element) => {
    if(element.id === "interest_frecuency" && formData["frecuency"] !== undefined){
      if(formData["frecuency"] == 6){
        return element.options
      }else{
        return element.options.filter((opt) => opt.id <= parseInt(formData["frecuency"]) && opt.id !== 6 )  
      }
      
    }
    if(element.id === "installment_value" && formData["installment_type"] !== undefined){
      let installment_type = formData["installment_type"]
      let data = []
      if(installment_type === 0){
        Array.from({length: 36}, (el, index) => data.push({"id": index, "value": index + 1, "related_fields": []})) 
      }else{
        Array.from({length: 30}, (el, index) => data.push({"id": index, "value": index + 1, "related_fields": []}))
      }
      return data
    }
    return element.options
  }

  const disabledCheck = (element) => {
    if(props.data.resource_key === "liability_addepar"){
      return element.disabled
    }else{
      return ["capital_amortizations", "created_at", "updated_at", "valuation_converted", "currency_value", "death_benefit_converted", "premium_converted", "policy_value_converted", "valuation_converted", "purchase_price_converted", "principal_amount_converted", "capital_amortizations_converted"].includes(element.id)
      ? element.disabled
      : manualDisabled != null
        ? manualDisabled
        : element.disabled  
    }

    
  }
  console.log(props)
console.log({formData})
return (
  <div
    className="wrapper-form"
    style={{
      paddingTop:
      props.data.code === "add_document" ||
      props.data.code === "edit_document"
        ? 0
        : 20,
    }}
  >
    {/* 
          {
        showModal &&
          <CustomModal 
            close={() => setShowModal(false)}
          >
            <p className="headingH2 block-title">{modalTitle}</p>
            {
              modalDescription.map(description => {
                let subtitle = description.indexOf(':') !== -1 
                return (
                  <p className="body grey-dark description">
                    {
                      subtitle &&
                      <p className="subtitle">
                        {`${description.split(':')[0]}: `}
                      </p>
                    }
                    {subtitle ? `${description.split(':')[1]}` : description}
                  </p>
                )
              })
            }
          </CustomModal>
        }
        */}

    {props.data &&
        props.data.form.map((element, index) => {
          if (
            element.type === "image" &&
            (props.data.code === "add_contact" ||
              props.data.code === "edit_contact")
          ) {
            return (
              <CustomAvatar
                text={{
                  defaultAvatars: props.buttons.default_avatars,
                  yourSelection: props.buttons.your_selection,
                }}
                disabled={
                  manualDisabled != null ? manualDisabled : element.disabled
                }
                avatar_user={element.value}
                newImage={(value, type) => _newImage(value, type)}
                size="medium"
                space={true}
              />
            );
          }
        })}
    {props.data &&
        props.data.form.map((element, index) => {
          return (
            element.type === "file" &&
            element.value !== "" && (
              <>
                <div className="buttons__file">
                  <button
                    className="btn primary small"
                    onClick={() => window.open(element.value, "_blank")}
                  >
                    <span className="btn-text">{internalization[languageUserAgent].open}</span>
                  </button>

                  {(props.data.can_manage ||
                    props.data.can_manage === undefined) &&
                      !manualDisabled && (
                        <button
                          className="btn secondary small"
                          onClick={() =>
                              props.deleteDocument && props.deleteDocument()
                          }
                        >
                          <span className="btn-text">{internalization[languageUserAgent].delete}</span>
                        </button>
                      )}
                </div>
              </>
            )
          );
        })}
      <div className="wrapper-form__title">
        <p className="headingH2">
          {getHeadingTitle(props.data, manualDisabled)}
        </p>
        {props.data.form.filter(item => item.id === "content")[0]?.value !== undefined && (
          <div style={{display: "flex"}}>

            <button onClick={printFile} style={{marginRight: 12}} title="Print">
              <Icon name={"Print"} noWrapper={true}/>
            </button>

            <button onClick={downloadFile} style={{marginRight: 8}} title="Download">
              <Icon name={"DocDownload"} noWrapper={true}/>
            </button>

            {props.data && getExtension() && (
              <Icon name={getIconName()} noWrapper={true} />
            )}

          </div>
        )}

      </div>
      {
        props.data.code === "add_alerts_financial_statements" &&
        <p style={{marginBottom: 10, marginTop: -10}}>
        {
          languageUserAgent == "es" ? 
          "Crea una alerta en caso de que quieras recibir una notificación al momento que se genere un nuevo reporte."
          :
          "Create an alert in case you want to receive a notification when a new report is generated."
        
        }
        </p>
      }

    {props.data.code === "edit_document" && (
      <div className="block__history">
        <p className="block__history-title">
          {internalization[languageUserAgent].history}
        </p>

        {props.data &&
            props.data.form
            .filter((elem) => ["created_at", "updated_at"].includes(elem.id))
            .map((item, index) => {
              return (
                <div className="block__history-item">
                  <p className="block__history-item-label">{item.label}</p>
                  <p className="block__history-item-info">{item.value}</p>
                </div>
              );
            })}
      </div>
    )}

    {props.data &&
        props.data.form.map((element, index) => {
          let hasTooltip = element.tooltip ? element.tooltip.length > 0 : false;
          if (element.multiple) {
            return (
              <MultipleInputs
                colorGrey={props.colorGrey}
                key={index}
                updateMultiple={(type, items) =>
                    _updateFromMultiple(type, items)
                }
                name={element.id}
                type={element.type}
                defaultValue={
                  formData[element.id] ? formData[element.id] : element.value
                }
                value={
                  formData[element.id] ? formData[element.id] : element.value
                }
                options={element.options}
                onChangeInput={
                  element.type === "date"
                    ? (date, bigger) => {
                      _dateShowed(
                        date,
                        element,
                        bigger,
                        props.data.resource_key
                      );
                    }
                    : element.type === "select"
                      ? (e) =>
                    _getInputValues(
                      e,
                      element,
                      "",
                      element.options.filter(
                        (elem) => "" + elem.id === "" + e.target.value
                      )[0].related_fields
                    )
                    :  ["number", "currency", "percentage"].includes(
                      element.type
                    )
                      ? (values) => _getInputValuesNumeric(values, element)
                      : (e, type) => _getInputValues(e, element, type, undefined)
                }
                onFocusInput={(e) => _focusedInput(e, element)}
                onBlurInput={(e) => _bluredInput(e, element)}
                onFocus={element.type === "radio" ? false : true}
                onBlur={element.type === "radio" ? false : true}
                showErrorLabel={

                  element.type === "radio" ? "" 
                  : formDataErrors[element.id]
                }
                showErrorLabelNow={
                  element.type === "radio" ? "" : showErrorLabelNow[element.id]
                }
                showFormatError={
                  element.type === "currency" ||
                    element.type === "percentage" ||
                    element.type === "number"
                      ? formFormatErrors[element.id]
                      : ""
                }
                errorLabel={element.type === "radio" ? "" : props.errors.empty}
                disabled={manualDisabled}
                withButtonIcon={false}
                iconName="Plus"
                buttonIconAction={(e) => _clearInputs(e, element.type)}
                closeIcon={true}
                bgColorIcon="#787D7F"
                formData={formData}
                datesToEdit={datesToEdit}
                manualDisabled={manualDisabled}
                required={element.required}
                hasTooltip={hasTooltip}
                helperTooltip={[element.label, element.tooltip]}
                minimum_date_valuation={
                  ["add_valuation", "valuation"].includes(props.data.code)
                    ? props.data.date_minimum
                    : ""
                }
              />
            );
          } else {

            if (
              element.hidden !== true &&
              _checkParentSelected(element) &&
              _checkTitleEnabled(element) &&
              ((element.value !== "" && manualDisabled) || !manualDisabled) &&
              element.type !== "file" &&
              !["created_at", "updated_at"].includes(element.id)
            ) {
              let add_action = ""
              let edit_action = ""
              let add_action_text = ""
              let edit_action_text = ""
              
              if  (props.language == "es-ES" ) {
                add_action = "Agregue"
                edit_action = "Edite"

                add_action_text = "Añadir"
                edit_action_text = "Editar"
              } else {
                add_action = "Add"
                edit_action = "Edit"

                add_action_text = "Add"
                edit_action_text = "Edit"
              }
              return (
                <>
                  {renderBoxAsset() && renderBoxAsset().includes(element.id) && (
                    <div className="block__box">
                      {renderLinkedExistent(element.id)}
                      {renderFormLinked(element.id)}
                    </div>
                  )}
                  {renderSibling(element)}
                  {showSiblingEdit(element) && (
                    <div
                      key={index}
                      className={`wrapper-form-item ${
                        element.type === "textarea"
                          ? "textarea"
                          : element.type === "image" || element.type === "radio"
                            ? "auto"
                            : 
                            element.id === "inviteds" ? 'inviteds' : ""
                      } ${manualDisabled ? "disabled" : ""}`}
                      style={{
                        paddingLeft:
                        element.id === "age" || element.id === "age_owner"
                          ? "18px"
                          : 0,
                      }}
                    >
                      {element.type !== "file" && element.id === "inviteds" && (
                        <p className="input-label">
                          {element.type === "image" ? "" : element.label}
                          {element.required === true && !manualDisabled && (
                            <span className="asterisk"> *</span>
                          )}
                        </p>
                      )}

                      {element.type !== "file" && element.id !== "inviteds" && element.id !== "installment_value" &&(
                        <span className="input-label">
                          {element.type === "image" ? "" : element.label}
                          {element.required === true && !manualDisabled && (
                            <span className="asterisk"> *</span>
                          )}
                        </span>
                      )}
                      {element.id === "installment_value" && (
                        <span className="input-label">
                          {getInstallmentValueLabel(element)}
                          {element.required === true && !manualDisabled && (
                            <span className="asterisk"> *</span>
                          )}
                        </span>
                      )}

                      {element.type === "button" && (
                        <>
                          <div className="o-display--flex o-display--flex-align-center">
                            <button
                              disabled={props.data.resource_key === "life_insurance" && props.data.code === "add_asset" && formData["import_xls"] && Object.keys(formData["import_xls"]).length > 0}
                              onClick={() => setShowAddBudgetModal(true)}
                              className="btn primary border btn--not-centered"
                            >
                              <span className="btn-text">{formData["budgets"] || props.data.modal.table_evolution[0].rows.length > 0 ? props.buttons.edit_budget :   props.buttons.add_budget }</span>
                            </button>
                            {
                              (!formData["budgets"] && props.data.modal.table_evolution[0].rows.length === 0) ?
                                <p className="o-margin--left-fourteen">
                                {props.buttons.message_budget_text.replace('%{action}', add_action).replace('%{action_text}', add_action_text)}
                                </p>
                                :
                                <p className="o-margin--left-fourteen">
                                  {
                                    props.data.resource_key === "life_insurance" && props.data.code === "add_asset" && Object.keys(formData["import_xls"]).length > 0 ?
                                      <div style={{display: "flex"}}><span className="o-margin--right-fourteen">{formData["import_xls"].filename}</span> <Icon action={() => _cleanXLS()} width={'16px'} height={'16px'} noWrapper={true} color={'#f3b01c'} name="Garbage" orientation={"right"} /></div>
                                    :
                                      props.buttons.message_budget_text.replace('%{action}', edit_action).replace('%{action_text}', edit_action_text)
                                    
                                  }
                                </p>
                            }
                          </div>

                          {/* Table Editable on Modal */}
                          {showAddBudgetModal && (
                            <Modal
                              isShowed={showAddBudgetModal}
                              showBackButton={true}
                              backAction={() => setShowAddBudgetModal(false)}
                              buttonText={props.buttons.back}
                            >
                              <div className="wrapper-form">
                                <div className="wrapper-form__title">
                                  <p className="headingH2">
                                    {props.data.modal.table_evolution[0].title}
                                  </p>
                                </div>
                                <p>
                                  {props.data.modal.table_evolution[0].text}
                                </p>
                                {!xlsUploaded && (
                                  <TableEditable
                                    headers={
                                      props.data.modal.table_evolution[0]
                                        .headers
                                    }
                                    rows_stored={formData["budgets"] ? formData["budgets"] : []}
                                    new_row={
                                      props.data.modal.table_evolution[0]
                                        .new_row
                                    }
                                    rows={
                                      props.data.modal.table_evolution[0].rows
                                    }
                                    saveData={(data) => {
                                      setTableEditableData(
                                        (tableEditableData) => data
                                      );
                                      setDisabledSaveButton(false);
                                    }}
                                  />
                                )}
                                {props.data.modal.table_evolution[0].button && (
                                  <div className="o-margin--top-twentyFour">
                                    <span className="input-label">
                                      {
                                        props.data.modal.table_evolution[0]
                                          .button.tooltip
                                      }
                                    </span>
                                    <div>
                                      <LoadFileButton
                                        element_id={"import_xls"}
                                        colorGrey={props.colorGrey}
                                        inputRef={(node) =>
                                            setLoadFileRef(node)
                                        }
                                        data={props.data}
                                        showAsset={xlsUploaded}
                                        deleteImage={(id) =>
                                            props.deleteImage &&
                                              props.deleteImage(id)
                                        }
                                        loadMultiple={false}
                                        editImages={[]}
                                        type={"file"}
                                        tooltip={false}
                                        action={(state) => {
                                          setDisabledSaveButton(false);
                                          setXlsUploaded(
                                            state.formValidationImage.import_xls
                                          );
                                          setFormData({
                                            ...formData,
                                            ...state.formDataImage,
                                            ["budgets"]: [],
                                          });
                                          setFormDataErrors({
                                            ...formDataErrors,
                                            ["file"]: false,
                                          });
                                          setFormValidation({
                                            ...formValidation,
                                            ["content"]: true,
                                            ["file"]: true,
                                            ...state.formValidationImage,
                                          });
                                        }}
                                      />

                                      <p className="o-margin--top-twentyFour">
                                        {
                                          props.data.modal.table_evolution[0]
                                            .button.text
                                        }
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html:
                                            props.data.modal
                                            .table_evolution[0].button.href,
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </div>
                                )}
                                <button
                                  onClick={() => {
                                    setFormData({
                                      ...formData,
                                      ["budgets"]: tableEditableData,
                                    });
                                    setShowAddBudgetModal(false);
                                    document.body.classList.remove("has-modal");
                                  }}
                                  type="button"
                                  className="btn o-margin--top-twentyFour"
                                  //disabled={disabledSaveButton}
                                >
                                  <span class="btn-text">Save</span>                                </button>
                              </div>
                            </Modal>
                          )}
                          {/* @Table Editable on Modal */}
                        </>
                      )}

                      {element.type !== "select" && (
                        <Input
                          key={element.id === "ssn" ? "input-"+element.id+keyCount : "input-"+element.id}
                          colorGrey={props.colorGrey}
                          name={element.id}
                          id={element.id}
                          allowNegative={element.allowNegative}
                          type={
                            element.id === "year" ?
                              "text"
                              :
                            element.type === "radio" && manualDisabled
                              ? "text"
                              : element.type
                          }
                          defaultValue={
                            element.id === "ssn" ? 
                              elementOfuscated
                              :
                              element.type === "radio" && manualDisabled
                                ? getRadioOption(element)
                                : formData[element.id]
                                  ? formData[element.id]
                                  : element.value
                          }
                          value={
                            element.id === "ssn" ? 
                              elementOfuscated
                              :
                              element.type === "radio" && manualDisabled
                                ? getRadioOption(element)
                                : formData[element.id]
                                  ? formData[element.id]
                                  : element.value
                          }
                          resource_key={props.data.resource_key}
                          options={getSelectOptions(element)}
                          onChangeInput={
                            element.type === "date"
                              ? (date, bigger) => {
                                _dateShowed(
                                  date,
                                  element,
                                  bigger,
                                  props.data.resource_key
                                );
                              }
                              : element.type === "select"
                                ? (e) =>
                              _getInputValues(
                                e,
                                element,
                                "",
                                element.options.filter(
                                  (elem) =>
                                  "" + elem.id === "" + e.target.value
                                )[0].related_fields
                              )
                              : element.type === "phone"
                                ? (value) => _changePhoneNumber(value, element)
                                : element.id !== "year" &&  ["number", "currency", "percentage"].includes(
                                  element.type
                                )
                                  ? (values) =>
                              _getInputValuesNumeric(values, element)
                              : element.id === "ssn"
                                ? (value) => { _getInputOfuscated(value,element)}
                                : (e, type) =>
                              _getInputValues(e, element, type, undefined)
                          }
                          onFocusInput={(e) => _focusedInput(e, element)}
                          onBlurInput={(e) => _bluredInput(e, element)}
                          onFocus={
                            element.type === "radio" && !manualDisabled
                              ? false
                              : true
                          }
                          onBlur={
                            element.type === "radio" && !manualDisabled
                              ? false
                              : true
                          }
                          showErrorLabel={
                            element.type === "radio" && !manualDisabled
                              ? ""
                              : formDataErrors[element.id]
                          }
                          showErrorLabelNow={
                            element.type === "radio" && !manualDisabled
                              ? ""
                              : showErrorLabelNow[element.id]
                          }
                          showFormatError={
                            element.type === "currency" ||
                              element.type === "percentage" ||
                              element.type === "number"
                                ? formFormatErrors[element.id]
                                : ""
                          }
                          showFormatErrorLabel={props.errors.not_a_number}
                          errorLabel={
                            element.type === "radio" && !manualDisabled
                              ? ""
                              :
                            element.id === "year" ?
                            "Only digits can be entered" :
                              element.max_percentage && element.max_percentage !== ""
                                ? props.errors.max_percentage_ownership.replace(
                                  "%{max_percentage}",
                                  element.max_percentage
                                )
                                : props.errors.empty
                          }
                          disabled={disabledCheck(element)}
                          withButtonIcon={["ssn"].includes(element.id) && !manualDisabled ? true : false}
                          iconName={["ssn"].includes(element.id) ? "Eye" : "Plus"}
                          slashed={ofuscated}
                          buttonIconAction={(e) =>
                              element.id === "ssn" ?
                                _disabledOfuscated(element, !ofuscated)
                                :
                                _clearInputs(e, element.type)
                          }
                          closeIcon={true}
                          bgColorIcon="#787D7F"
                          prepare_dates={_prepare_dates()}
                          currency_type={_getCurrencyTypeByField(element)}
                          datesToEdit={datesToEdit}
                          required={element.required}
                          hasTooltip={hasTooltip}
                          helperTooltip={[element.label, element.tooltip]}
                          minimum_date_valuation={
                            ["add_valuation", "valuation"].includes(
                              props.data.code
                            )
                              ? props.data.date_minimum
                              : ""
                          }
                          sqft_type={{value: formData["sqft_type"]}}
                          builded_surface_type={{value: formData["builded_surface_type"]}}
                          crypto_type={_isCryptoType(element)}
                        />
                      )}
                      {element.type === "select" && element.id === "inviteds" &&(
                        <AlertInviteds language={props.language} element={element} formData={formData} setCheckboxesInviteds={(id) => _setCheckboxesInviteds(id)} />
                      )}
                      {element.type === "select" && element.id !== "inviteds" &&(
                        <SelectMultiple
                          key={"select_" + index}
                          index={index}
                          colorGrey={props.colorGrey}
                          setRef={(ref) => {
                            setMultipleRef(ref);
                          }}
                          defaultValue={defaultValueSelect(element)[1]}
                          label={false}
                          options={getSelectOptions(element)}
                          multiple={element.id == "inviteds" ? true : false}
                          openOptions={() => {}}
                          formData={_prepare_dates()}
                          datesToEdit={datesToEdit}
                          getOptions={(items) =>
                              getOptions(items, element.id, element.options)
                          }
                          disabled={manualDisabled}
                          disabledOptions={getDisabledOptions(element.id)}
                          showErrorLabel={
                            !manualDisabled ? "" : formDataErrors[element.id]
                          }
                          showErrorLabelNow={
                            !manualDisabled ? "" : showErrorLabelNow[element.id]
                          }
                          errorLabel={!manualDisabled ? "" : props.errors.empty}
                          hasTooltip={hasTooltip}
                          helperTooltip={[element.label, element.tooltip]}
                          showHint={ ["arts","others","real_estates","watches","private_equities", "vehicles", "life_insurances"].includes(element.id) ? showHintSelect(element) : false}
                          hint={["arts","others","real_estates","watches","private_equities", "vehicles", "life_insurances"].includes(element.id) ? textInfoAsset(element) : ""}
                          specialHint={checkSpecialHint(element)}
                        />
                      )}
                    </div>
                  )}
                </>
              );
            }
          }
        })}
    {props.data &&
        props.data.resource_key !== "contact" &&
        props.data.form.map((element, index) => {
          if (
            (element.type === "file" && element.value === "") ||
            element.type === "image"
          ) {
            return (
              <div
                key={index}
                className={
                  (formData.hasOwnProperty("images") &&
                    formData["images"] !== undefined &&
                    formData["images"].length > 0) ||
                    (element.options && element.options.length > 0)
                      ? `wrapper-form-item`
                      : `wrapper-form-item flex`
                }
                style={{
                  flexDirection:
                  props.data.resource_key === "life_insurance" &&
                  element.id === "import_xls" &&
                  "column",
                  alignItems:
                  props.data.resource_key === "life_insurance" &&
                  element.id === "import_xls" &&
                  "flex-start",
                }}
              >
                {(formData.hasOwnProperty("images") &&
                  formData["images"] !== undefined &&
                  formData["images"].length > 0) ||
                    (element.options && element.options.length > 0) ||
                    (props.data.resource_key === "life_insurance" &&
                      element.id === "import_xls" && (
                        <span className="input-label">
                          {element.label}
                          {element.required === true && !manualDisabled && (
                            <span className="asterisk"> *</span>
                          )}
                        </span>
                      ))}

                <LoadFileButton
                  element_id={element.id}
                  colorGrey={props.colorGrey}
                  inputRef={(node) => setLoadFileRef(node)}
                  data={props.data}
                  showAsset={element.type === "image"}
                  deleteImage={(id) =>
                      props.deleteImage && props.deleteImage(id)
                  }
                  loadMultiple={
                    element.id === "import_xls" ? false : props.loadMultiple
                  }
                  editImages={
                    element.options === null
                      ? [
                        {
                          id:
                          element.id === "image" || element.id === undefined
                            ? element.real_id
                            : element.id,
                          value: element.value,
                        },
                      ]
                      : element.options
                  }
                  type={element.type}
                  tooltip={element.tooltip}
                  action={(state) => {
                    setFormData({ ...formData, ...state.formDataImage });
                    element.type === "file" &&
                      setFormDataErrors({ ...formDataErrors, ["file"]: false });
                    element.type === "file" &&
                      setFormValidation({
                        ...formValidation,
                        ["content"]: true,
                        ["file"]: true,
                        ...state.formValidationImage,
                      });
                  }}
                />
              </div>
            );
          }
        })}

    {formData.hasOwnProperty("contacts") && formData["contacts"].length > 0 && (
      <DetailContentBlock
        title={
          props.data.associated_elements.filter(
            (e) => Object.keys(e)[0] === "add_contact"
          )[0].add_contact.title_section
        }
      >
        <div className="box-wrapper-color">
          {formData["contacts"].map((element, i) => {
            return (
              <div className="box-wrapper-color__content">
                <MediaBlock
                key={i}
                image={
                  <img
                    src={_getImage(element)}
                    className="mediaBlock-image"
                    alt="contactImage"
                  />
                }
                title={element.name}
                subtitle={element.email && element.email[0].value}
                description={element.company}
                rightText={element.phone && element.phone[0].value}
                action={() => _showAssociatedElement("contact", i)}
                deleteIconAction={(e) => deleteResource("contacts", i, e)}
                deleteItem={true}
                imageSeparator={true}
                titleFullWidth={true}
                center={true}
               />
              </div>
            );
          })}
        </div>
        
      </DetailContentBlock>
    )}
    {formData.hasOwnProperty("contact_assets") &&
        formData["contact_assets"]["contact_ids"].length > 0 && (
          <DetailContentBlock title={props.buttons.assigned_contacts}>
            <div className="box-wrapper-color">
              {assignDataTitles["contact"].map((element, i) => {
                return (
                  <div className="box-wrapper-color__content">
                    <MediaBlock
                    key={i}
                    image={
                      <img
                        src={_getImage(element)}
                        className="mediaBlock-image"
                        alt="contactImage"
                      />
                    }
                    title={element.title}
                    subtitle={element.email && element.email[0].value}
                    description={element.company}
                    rightText={element.phone && element.phone[0].value}
                    action={() => _showAssociatedElement("contact", i)}
                    deleteIconAction={(e) => deleteAssignedContact(element, i, e)}
                    deleteItem={true}
                    imageSeparator={true}
                    titleFullWidth={true}
                    center={true}
                  />
                  </div>
                );
              })}
            </div>
            
          </DetailContentBlock>
        )}
    {formData.hasOwnProperty("documents") &&
        formData["documents"].length > 0 && (
          <DetailContentBlock
            title={
              props.data.associated_elements.filter(
                (e) => Object.keys(e)[0] === "add_document"
              )[0].add_document.title_section
            }
          >
            <div className="box-wrapper-color">
              {formData["documents"].map((element, i) => {
                return (
                  <div className="box-wrapper-color__content">
                      <MediaBlock
                    key={i}
                    image={
                      <RenderDocIcon
                        type={element.file ? element.file.content_type : ""}
                      />
                    }
                    title={element.title}
                    description={false}
                    rightText={element.size}
                    documentType={element.type}
                    action={() => this.props.showDocument(element.url)}
                    action={() => _showAssociatedElement("document", i)}
                    deleteIconAction={(e) => deleteResource("documents", i, e)}
                    deleteItem={true}
                    imageSeparator={true}
                    titleFullWidth={true}
                    center={true}
                  />
                  </div>
                );
              })}
            </div>
          </DetailContentBlock>
        )}
    {formData.hasOwnProperty("document_assets") &&
        formData["document_assets"]["document_ids"].length > 0 && (
          <DetailContentBlock title={props.buttons.assigned_documents}>
            <div className="box-wrapper-color">
              {assignDataTitles["document"].map((element, i) => {
                return (
                  <div className="box-wrapper-color__content">
                    <MediaBlock
                      key={i}
                      image={<RenderDocIcon type={element.type} />}
                      title={element.title}
                      description={element.description}
                      rightText={element.size}
                      documentType={element.type}
                      action={() => {
                        _setAssignDocument();
                        setShowModalAssign(true);
                      }}
                      deleteItem={false}
                      imageSeparator={true}
                      titleFullWidth={true}
                      center={true}
                    />
                  </div>
                );
              })}
            </div>
          </DetailContentBlock>
        )}
    {formData.hasOwnProperty("alerts") && formData["alerts"].length > 0 && (
      <DetailContentBlock
        title={
          props.data.associated_elements.filter(
            (e) => Object.keys(e)[0] === "add_alert"
          )[0].add_alert.title_section
        }
      >
        <div className="box-wrapper-color">
        {formData["alerts"].map((element, i) => {
          return (
            <div className="box-wrapper-color__content">
              <MediaBlock
              key={i}
              image={<RenderDocIcon type={"alert"} />}
              title={element.title}
              description={
                element.end_date !== "" && element.end_date !== undefined
                  ? element.start_date + " - " + element.end_date
                  : element.start_date
              }
              action={() => _showAssociatedElement("alert", i)}
              deleteIconAction={(e) => deleteResource("alerts", i, e)}
              deleteItem={true}
              imageSeparator={true}
              titleFullWidth={true}
              center={true}
             />
            </div>
          );
        })}
        </div>
        
      </DetailContentBlock>
    )}

    {props.resourcesToSelect !== undefined &&
        showResourceBox &&
        (props.data.code === "add_document" ||
          props.data.code === "edit_document" ||
          props.data.code === "edit_alert" ||
          props.data.code === "edit_owner" ||
          props.data.code === "edit_beneficiary") && (
            <>
              {!manualDisabled && assetList().length > 0 && (
                <div className="line-separator">
                  <p>{internalization[languageUserAgent].title_linked_assets}</p>
                </div>
              )}
              { assetList() && assetList().length > 0 &&
                <div className="block__box block__box--top block__box--bottom">
                  {assetList().map((asset, index) => {
                    return (
                      <div className="block__box-item">
                        <div
                          className={
                            props.data.code === "add_document"
                              ? asset.id === selectOwnerable
                                ? "block__box-item-content is-selected"
                                : "block__box-item-content"
                                : "block__box-item-content is-selected"
                          }
                          style={{
                            cursor: currentAsset(asset.resourceable_id)
                              ? "auto"
                              : "pointer",
                            border: currentAsset(asset.resourceable_id) ? '1px solid #80abba' : 'none',
                          }}
                          key={"asset_" + index}
                          onClick={() =>
                              props.data.code === "add_document"
                                ? setPolymorphic(asset)
                                : currentAsset(asset.resourceable_id)
                                  ? {}
                                  : navigateToAsset(asset)
                          }
                        >
                          <div className="block__box-item-content-icon">
                            <Icon
                              name={`${
                              asset.resourceable_type !== undefined
                                ? asset.resourceable_type
                                : props.data.resource_key === "alert"
                                  ? "Notification"
                                  : asset.ownerable_type
                            }`}
                            />
                          </div>

                          <TextEllipsis
                            lines={2}
                            tag={"p"}
                            ellipsisChars={"..."}
                            tagClass={"block__box-item-content-text"}
                            debounceTimeoutOnResize={200}
                            useJsOnly={true}
                          >
                            {asset.name}
                          </TextEllipsis>

                          {/* {asset.hasOwnProperty("description") && (
                      <p className="block__box-item-content-text truncate">
                        {asset.description}
                      </p>
                    )} */}
                        </div>

                        {props.data.resource_key !== "alert" &&
                            props.data.resource_key !== "document" &&
                            !manualDisabled &&
                            (props.data.can_manage ||
                              props.data.can_manage === undefined) && (
                                <div
                                  className="block__box-item-icon"
                                  onClick={() => props.resourceDestroyAction(asset)}
                                >
                                  <Icon
                                    name="Plus"
                                    width={"20px"}
                                    height={"20px"}
                                    color="#f3b01c"
                                    bgColor="#F5F5F7"
                                    close={true}
                                  />
                                </div>
                              )}
                        {props.data.resource_key === "document" &&
                            props.data.code !== "add_document" &&
                            !manualDisabled &&
                            (props.data.can_manage ||
                              props.data.can_manage === undefined) && (
                                <button
                                  className="block__box-item-icon"
                                  onClick={() => props.resourceDestroyAction(asset)}
                                >
                                  <Icon
                                    name="Plus"
                                    width={"20px"}
                                    height={"20px"}
                                    color="#f3b01c"
                                    bgColor="#F5F5F7"
                                    close={true}
                                  />
                                </button>
                              )}
                      </div>
                    );
                  })}
                </div>
              }
            </>
          )}

    <div className="container__btn">
      {
        //["alert","contact","document"].includes(props.data.resource_key) && !manualDisabled &&
        (props.data.can_manage || props.data.can_manage === undefined) &&
          [
            "alert",
            "contact",
            "document",
            "owner_beneficiary",
            "insured",
            "stake_holder",
            "tenant"
          ].includes(props.data.resource_key) &&
          [
            "edit_contact",
            "edit_document",
            "edit_alert",
            "edit_owner",
            "edit_beneficiary",
            "edit_insured",
            "edit_stake_holder",
            "edit_tenant"
          ].includes(props.data.code) &&
          !manualDisabled && (
            <button
              onClick={() => {
                let body = document.body;
                body.classList.contains("has-modal") &&
                  body.classList.remove("has-modal");
                props.destroyAssetAssociated &&
                  props.destroyAssetAssociated();
              }}
              className="btn secondary"
              // style={[{ transform: this.state.scaleButton }]}
            >
              <span className="btn-text">{props.buttons.destroy}</span>
            </button>
          )
      }
      {props.data &&
          props.buttons &&
          (props.data.can_manage || props.data.can_manage === undefined) && (
            <button
              onClick={() => {
                if (manualDisabled) {
                  setManualDisabled(!manualDisabled);
                } else {
                  let body = document.body;
                  body.classList.contains("has-modal") &&
                    body.classList.remove("has-modal");
                  props.actionSubmitButton(formData);
                }
              }}
              className="btn primary"
              // style={[{ transform: this.state.scaleButton }]}
              disabled={
                props.data.code === "add_document"
                  ? _checkValidatedResource()
                  : _checkValidated()
              }
            >
              <span className="btn-text">
                {props.data.code.indexOf("add") != -1
                  ? props.buttons.add
                  : manualDisabled
                    ? props.buttons.edit
                    : props.buttons.update}
              </span>
            </button>
          )}
    </div>
    {showModal === true && (
      <AssetsResourcesModal
        language={props.language}
        colorGrey={true}
        data={modalData}
        errors={props.errors}
        closeModal={(e) => _closeModal(e)}
        uploadResource={(data) =>
          {document.body.classList.remove("has-modal")
          addResource(data, modalData.current_position)}
        }
        resources={[]}
        goToResource={(asset) => ""}
        polymorphic={"ownerable_"}
        resourceToSelectDestroy={true}
        resourceDestroyAction={(url) => console.log("resourceDestroyAction")}
        destroyAssetAssociated={() => deleteResourceFromModal()}
        deleteDocument={(url) => ""}
        buttons={props.buttons}
        showModalSessionExpire={(time_left, diffMins, checkTime) => ""}
        getConvertedValue={(value, currency_type, date, success, error) => props.getConvertedValue(value, currency_type, date, (responseJSON) => success(responseJSON),() => error("error"))}
        getCapitalAmortizationValue={(data, success, error) => props.getCapitalAmortizationValue(data, (responseJSON) => success(responseJSON),() => error("error"))}
      />
    )}
    {showModalAssign && (
      <Modal isShowed={showModalAssign}>
        <ViewList colorGrey={true} assign={true} assignData={assignData} />
      </Modal>
    )}
    {Object.keys(showModalConfirmText).length > 0 && (
      <AlertModal
        isShowed={true}
        title={showModalConfirmText.title}
        msg={showModalConfirmText.body}          
        action={() => {
          getOptions(showModalConfirmText.items, showModalConfirmText.id, showModalConfirmText.options, false)
          setShowModalConfirmText(false)
        }}
        textButton={"Ok"}
        textButtonAlertColor={false}
        cancelButton={true}
        cancelTextButton={languageUserAgent == "es" ? "Volver" : "Back"}
        cancelButtonAlertColor={true}
        actionCancelButton={() => {
          setShowModalConfirmText(false)
        }}
      />
    )}
    {showTenantModal && (
       <AlertModal
        isShowed={true}
        title={showTenantData.title}
        msg={showTenantData.body}          
        action={() => {
          showTenantData.action()
          setShowTenantModal(false)
          setShowTenantData({})
        }}
        textButton={languageUserAgent == "es" ? "Confirmar" : "Confirm"}
        textButtonAlertColor={false}
        cancelButton={true}
        cancelTextButton={languageUserAgent == "es" ? "Volver" : "Back"}
        cancelButtonAlertColor={true}
        actionCancelButton={() => {
          setShowTenantModal(false)
          setShowTenantData({})
        }}
      />
    )}
      {
        props.data.associated_elements !== undefined &&
          props.data.associated_elements.length > 0 
          && (["tenant", "stake_holder"].includes(props.data.resource_key) ? !manualDisabled : true) && (
            <>
              <ActionSheet ref={actionSheet} render={actionSheetOptions} />
              <FloatButton
                style={{position: 'sticky', bottom: 20, left: '100%'}}
                children={
                  <Icon
                    name="Close"
                    color="white"
                    iconBig={true}
                    className="wrapper-icon-floatButton"
                    close={false}
                  />
                }
                action={() => _showActionSheetContent()}
              />
            </>
          )}
      {props.data.documents !== undefined &&
          props.data.documents.length > 0 && (
            <DetailContentBlock
              title={internalization[languageUserAgent].models.tenant_document}
            >
              <div className="box-wrapper-color">
                {props.data.documents.map((element, i) => {
                    return (
                      <div className="box-wrapper-color__content">
                        <MediaBlock
                          key={"documents_" + i}
                          image={<RenderDocIcon sizeBig="true" type={element.type} />}
                          title={element.title}
                          description={false}
                          rightText={element.size}
                          documentType={element.type}
                          action={() => props.showDocument(element.url)}
                          imageSeparator={true}
                          titleFullWidth={true}
                          center={true}
                        />
                      </div>
                    );
                  })}
              </div>
            </DetailContentBlock>
          )}
          {props.data.contacts !== undefined &&
            props.data.contacts.length > 0 && (
            <DetailContentBlock title={internalization[languageUserAgent].models.contacts}>
              <div className="box-wrapper-color">
              {props.data.contacts.map((element, i) => {
                  return (
                    <div className="box-wrapper-color__content">
                    <MediaBlock
                      key={"contacts_"+i+"_"+element.title}
                      image={
                        <img
                          src={_getImage(element)}
                          className="mediaBlock-image size-big"
                          alt="contactImage"
                        />
                      }
                      title={element.title}
                      subtitle={element.email}
                      description={element.company}
                      rightText={element.phone}
                      action={() => props.showContact(element.url)}
                      imageSeparator={true}
                      titleFullWidth={true}
                      center={true}
                    />
                    </div>
                  )                
              })}
              </div>
            </DetailContentBlock>
          )}                    
  </div>
);
}
export default FormBlock;
