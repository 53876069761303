// Dependencies
import React, { useEffect, useState, useRef } from "react";

// Components
import Icon from "../components/icons";

const NewNestedList = (props) => {
  const open = (e, item) => {
    if (props.ownership) {
      e.preventDefault();
      e.cancelBubble = true;
      e.stopPropagation();
    }

    let currentTarget = e.currentTarget;

    if (currentTarget.id === "nestedlistItem" && item.descendants.length > 0) {
      if (currentTarget.classList.contains("is-open")) {
        let itemsOpen = currentTarget.nextElementSibling;

        if (itemsOpen !== null) {
          let nodeList = itemsOpen.querySelectorAll(".is-open");
          for (let i = 0; i < nodeList.length; i++) {
            nodeList[i].classList.remove("is-open");
          }
        }

        currentTarget.classList.remove("is-open");
      } else {
        currentTarget.classList.add("is-open");
      }
    } else {
      props.action &&
        item.url &&
        props.action(
          item.id,
          item.url,
          item.files_count > 0 ? "show" : props.can_manage ? "create" : "show"
        );
    }
  };

  const nodeList = (item, first) => {
    return (
      <div
        className={
          first
            ? "nestedlist__container nestedlist__container--first"
            : "nestedlist__container"
        }
      >
        <div
          id="nestedlistItem"
          className={
            item.opened
              ? `nestedlist__item ${props.color} is-open`
              : `nestedlist__item ${props.color}`
          }
          onClick={(e) => {
            open(e, item);
          }}
        >
          <p
            id="nestedListTitle"
            className={
              item.id !== "deleted"
                ? first
                  ? "nestedlist__item-title"
                  : !props.levelColors
                  ? item.descendants.length > 0
                    ? "nestedlist__item-title nestedlist__item-title--grey"
                    : "nestedlist__item-title nestedlist__item-title--blueLight"
                  : "nestedlist__item-title nestedlist__item-title--grey"
                : "nestedlist__item-title nestedlist__item-title--orange"
            }
            onClickCapture={(e) => {
              open(e, item);
            }}
          >
            {item.title}
          </p>
          {item.descendants.length > 0 ? (
            <Icon
              width={"20px"}
              height={"20px"}
              noWrapper={true}
              className="nestedlist__item-arrow"
              name="SingleArrow"
              orientation={"down"}
            />
          ) : props.infoDocuments ? (
            item.files_count > 0 ? (
              <div
                style={{ fontSize: item.files_count > 99 && "11px" }}
                className="nestedlist__item-infoDocs"
              >
                {item.files_count > 99 ? "+99" : item.files_count}
              </div>
            ) : (
              props.can_manage && 
              <Icon noWrapper={true} name="PlusThin" />
              
            )
          ) : (
            <></>
          )}
        </div>
        {item.descendants.length > 0 && (
          <div
            style={{ marginLeft: "18px" }}
            className="nestedlist__container-sibling"
          >
            {item.descendants.map((descendants, i) => {
              return nodeList(descendants, false);
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={
        props.mainClass ? `nestedlist ${props.mainClass}` : "nestedlist"
      }
    >
      {props.data && props.data.map((item, index) => nodeList(item, true))}
    </div>
  );
};

export default NewNestedList;
