// Dependencies
import React from "react";

const ChangeUSer = ({ width, height, color }) => {
  return (
    <svg
      width={width ? width : "24px"}
      height={height ? height : "24px"}
      viewBox="0 0 24 24"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="intercambio-(1)" fill={color ? color : "#80ABBA"}>
          <path
            d="M9.28430023,1 L8.59546088,2.26000414 L9.76362737,2.92996365 C7.91951676,3.40220106 6.23394705,4.39359706 4.88384069,5.82447689 C3.02416202,7.79550257 2,10.3908153 2,13.1324625 C2,13.4229223 2.01195739,13.7122776 2.03493433,14 C2.42962192,13.5916849 2.89535047,13.2556005 3.41139384,13.0129743 C3.44060738,10.6919368 4.32057736,8.49960841 5.89778032,6.82792736 C7.0892051,5.56513772 8.58326903,4.69899294 10.2166483,4.30330861 L9.13148012,5.6416908 L10.2162262,6.56426587 L13,3.13090348 L9.28430023,1 Z"
            id="Path"
          ></path>
          <path
            d="M22.9733055,12 L21.5382763,12 C21.558547,12.2413168 21.5691106,12.4839791 21.5691106,12.7276158 C21.5691106,14.8343238 20.7915453,16.870553 19.3795942,18.4613136 C18.3665848,19.6026153 17.0762576,20.463069 15.6437979,20.9702008 L16.5868117,19.5314869 L15.3812787,18.7801617 L13,22.4131369 L17.0032167,24 L17.5421021,22.7075795 L16.3064961,22.2178007 C17.8971716,21.6228836 19.3291555,20.6500999 20.4615524,19.3743364 C22.0984792,17.5301027 23,15.1695968 23,12.7276158 C22.9999524,12.4842111 22.9908639,12.2415488 22.9733055,12 Z"
            id="Path"
          ></path>
          <path
            d="M7.95722322,19.1624937 C8.45685431,18.6146233 8.76047988,17.8998034 8.76047988,17.1180783 C8.76047988,15.3987398 7.29517684,14 5.4940185,14 C3.69290707,14 2.22760403,15.3987398 2.22760403,17.1180783 C2.22760403,17.9013708 2.53240245,18.6175342 3.03381626,19.1658076 C1.26427776,19.7934805 0,21.4217312 0,23.3267383 L0,24 L11,24 L11,23.3267383 C11.0000938,21.4184173 9.73159383,19.7877932 7.95722322,19.1624937 Z"
            id="Path"
          ></path>
          <path
            d="M20.9571763,5.16249367 C21.4568074,4.61466809 21.760433,3.89975862 21.760433,3.11807829 C21.760433,1.39873982 20.2951299,0 18.4939716,0 C16.6928132,0 15.2275102,1.39873982 15.2275102,3.11803351 C15.2275102,3.90132601 15.5323086,4.6174894 16.0337693,5.1657628 C14.2642308,5.79343577 13,7.42168642 13,9.32673835 L13,10 L24,10 L24,9.32673835 C24,7.41846208 22.7315,5.78779316 20.9571763,5.16249367 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default ChangeUSer;
