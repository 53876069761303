// Dependencies
import React from 'react';

const AlertMoney = ({ width, height, color, bgColor }) => {

  return (

    <svg
      width={width ? width : '54px'}
      height={height ? height : '54px'}
      viewBox="0 0 54 54"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle id="Oval-2" fill={bgColor ? bgColor : "#f3b01c"} fillRule="nonzero" cx="27" cy="27" r="27"></circle>
        <g id="Group" transform="translate(17.000000, 11.000000)" fill={color ? color : '#80abba'}>
          <rect id="Rectangle" x="8.3" y="0" width="2.6" height="33" rx="1"></rect>
          <path d="M9.26159133,29.0979004 C2.67916751,29.0979004 -0.168076855,24.7807617 0.00764335978,20.098053 C0.00764335978,19.8812561 0.0465227543,19.6199341 0.346632618,19.2940674 C0.646742481,18.9170227 1.95341362,18.9682007 2.20243706,19.20401 C2.53727593,19.4868469 2.64829888,19.680542 2.64829888,20.098053 C2.64829888,23.4526672 4.25877464,26.4958496 9.26159133,26.4958496 C13.6143938,26.4958496 16.3966204,24.8651367 16.3966204,22.0794678 C16.3966204,19.2937989 14.2711411,17.9671066 9.26159133,17.1567383 C3.73635258,16.309535 0.646742481,14.5658529 0.646742481,9.88781738 C0.646742481,5.83597564 4.28887646,3.25 9.26159133,3.25 C11.5908315,3.25 14.0716831,3.6794355 15.7590472,5.00036621 C17.5938338,6.43670472 18.2197535,7.52648482 18.5177142,10.5973511 C18.5484691,10.9143202 18.5584238,11.5679321 18.2745501,11.9255981 C17.9906764,12.2832642 16.6870867,12.4448853 16.178847,11.8318481 C15.8459002,11.5441895 15.955592,10.905383 15.9073016,10.5973511 C15.2871844,6.64178467 12.6757567,5.92565918 9.26159133,5.92565918 C5.65176868,5.92565918 3.33021416,7.41987741 3.33021416,9.88781738 C3.33021416,12.4662621 4.44975164,13.6028397 9.82765068,14.413208 C15.6844038,15.3340811 19.0138079,17.1067834 19.0138079,22.0794678 C19.0138079,24.7768555 17.6217181,29.0979004 9.26159133,29.0979004 Z" id="S" fillRule="nonzero"></path>
        </g>
      </g>
    </svg>

  );

}

export default AlertMoney;
