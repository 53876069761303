// Dependencies
import React from "react";

const Other = (props) => {
  return (
    <svg
      width={props.width ? props.width : "49px"}
      height={props.height ? props.height : "49px"}
      viewBox="0 0 49 49"
    >
      <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Ohter" transform="translate(-7.000000, -6.000000)">
              <g id="noun_investment_3117498" transform="translate(8.000000, 7.000000)">
                  <path d="M30.4610825,33.745661 C28.6075244,35.054055 26.3723514,35.8172848 23.9736291,35.8172848 C21.5749068,35.8172848 19.2852174,35.054055 17.4316593,33.745661 C14.5968057,31.7285537 12.7432476,28.4030524 12.7432476,24.5869033 C12.7432476,22.0246318 13.6155102,19.5713931 15.2510027,17.5542857 L15.3600355,17.4452529 C17.4861757,14.8829814 20.6481278,13.4110382 23.9736291,13.4110382 C30.133984,13.4110382 35.2040106,18.4265484 35.2040106,24.6414197 C35.2040106,28.4030524 33.3504525,31.7285537 30.4610825,33.745661 Z" id="Path" stroke={props.color ? props.color : "#80abba"} strokeWidth="2" fillRule="nonzero"></path>
                  <circle id="Oval" stroke={props.color ? props.color : "#80abba"} strokeWidth="2" cx="39.9" cy="6.3" r="5.5"></circle>
                  <circle id="Oval-Copy-3" stroke={props.color ? props.color : "#80abba"} strokeWidth="2" cx="41" cy="41" r="6"></circle>
                  <circle id="Oval-Copy" stroke={props.color ? props.color : "#80abba"} strokeWidth="2" cx="6" cy="11" r="5"></circle>
                  <circle id="Oval-Copy-2" stroke={props.color ? props.color : "#80abba"} strokeWidth="2" cx="5" cy="41" r="5"></circle>
                  <path d="M19.5032831,30.8017746 C20.3210293,31.3469388 21.5203904,31.7830701 22.774268,31.8921029 L22.774268,33.3640461 L25.0639574,33.3640461 L25.0639574,31.7285537 C27.2991304,31.2379059 28.6620408,29.7114463 28.7710736,27.6398225 C28.7165572,24.9685182 26.590417,24.2052884 24.4642768,23.4420586 L24.1916948,23.3330257 C22.7197516,22.7878616 21.9565217,22.5152795 21.9565217,21.3704348 C21.9565217,20.3891393 22.6107187,19.8984916 23.9736291,19.8984916 C24.7913753,19.8984916 25.663638,20.1710736 26.590417,20.7162378 C26.7539663,20.879787 27.0265484,20.9343035 27.1900976,20.8252706 C27.3536469,20.7162378 27.5171961,20.607205 27.6262289,20.3891393 L28.0623602,19.4623602 C28.2259095,19.1352618 28.0623602,18.6991304 27.7897782,18.5355812 C26.9720319,17.990417 25.9907365,17.6633185 25.009441,17.4997693 L25.009441,15.8642768 L22.7197516,15.8642768 L22.7197516,17.4997693 C20.4845785,17.8268678 19.1761846,19.298811 19.1761846,21.4794676 C19.1761846,24.0962555 20.9752263,24.9685182 23.428465,25.8952972 L23.5374978,25.9498137 C25.2820231,26.6040106 25.9907365,26.8765927 25.9907365,27.8033718 C25.9907365,29.3298314 24.4097604,29.4933807 23.701047,29.4933807 C22.7197516,29.4933807 21.7384561,29.2207986 20.811677,28.621118 L20.7026442,28.5666016 C20.6481278,28.4575688 20.5390949,28.4575688 20.4300621,28.4575688 C20.2119965,28.4575688 19.9394144,28.621118 19.7758651,28.8937001 L19.2852174,29.8749956 L19.230701,29.929512 C19.0671517,30.2566105 19.230701,30.583709 19.5032831,30.8017746 Z" id="Path" fill={props.color ? props.color : "#80abba"} fillRule="nonzero"></path>
              </g>
              <path d="M16.5400391,45.0310059 L23,39" id="Path-20" stroke={props.color ? props.color : "#80abba"} strokeWidth="2"></path>
              <path d="M17.5,21.5 L22.4436035,25.3283691" id="Path-2" stroke={props.color ? props.color : "#80abba"} strokeWidth="2"></path>
              <path d="M45.1098633,43 L40.8046875,39" id="Path-21" stroke={props.color ? props.color : "#80abba"} strokeWidth="2"></path>
              <path d="M44.5,17.5 L39.1303711,22.8642768" id="Path-19" stroke={props.color ? props.color : "#80abba"} strokeWidth="2"></path>
          </g>
      </g>
  </svg>
  );
};

export default Other;
