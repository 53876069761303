// Dependencies
import React from 'react';

const Training = ({ width, height, color }) => {

  return (

    <svg
      width={width ? width : '57px'}
      height={height ? height : '39px'}
      viewBox="0 0 57 39"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons-/-Section-/-Formación" transform="translate(-1.000000, -11.000000)" fillRule="nonzero" strokeWidth="1.5">
          <g id="Group" transform="translate(0.000000, -6.000000)">
            <path d="M34.694917,24.6206355 L48.9713008,38.8970192 C43.3269218,41.5378532 38.4304838,44.9325187 34.2819867,49.0810159 C30.1334896,53.229513 26.7358787,58.1288963 24.089154,63.7791659 L9.81277028,49.5027822" id="Rectangle-8" stroke={color ? color : "#80abba"} transform="translate(29.392036, 44.199901) rotate(-315.000000) translate(-29.392036, -44.199901) "></path>
            <path d="M23.1530646,21.029102 C22.6318485,21.3177742 22.2020439,21.7458013 21.9121729,22.264862 L9.71065702,44.1136398 C9.1729701,45.0764548 9.1729701,46.247591 9.71065702,47.210406 C10.5693566,48.7480457 12.5171497,49.3013142 14.0611748,48.4461659 L36.0006844,36.2951099 C36.5219006,36.0064377 36.9517051,35.5784105 37.2415761,35.0593499 L49.443092,13.2105721 C49.9807789,12.2477571 49.9807789,11.0766209 49.443092,10.1138059 C48.5843925,8.57616621 46.6365993,8.02289764 45.0925742,8.87804599 L23.1530646,21.029102 Z" id="Path" stroke={color ? color : "#80abba"} transform="translate(29.576875, 28.662106) rotate(-315.000000) translate(-29.576875, -28.662106) "></path>
          </g>
        </g>
      </g>
    </svg>

  );

}

export default Training;
