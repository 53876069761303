// Dependencies
import React from "react";

// Components
import Icon from "./icons/";
import CustomModal from './customModal'
import Modal from './modal'

//Helpers
import { avatars } from "../assets/resources";



class CustomAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar_selected: {},
      filename: "",
      type: "",
      openModal: false,
      selectAvatar: ""
  
    };
  }

  componentDidMount(){
    if (this.props.avatar_user.avatar_default_id != null) {
      this.setState({
        avatar_selected: {
          id: this.props.avatar_user.avatar_default_id,
          image: avatars[this.props.avatar_user.avatar_default_id].default
        }
      });
    } else if (this.props.avatar_user.url_medium && this.props.avatar_user.url_medium !== null) {
      this.setState({ avatar_selected: { url: this.props.avatar_user.url_medium } });

    } else if (this.props.avatar_user.url_big && this.props.avatar_user.url_big !== null) {
      this.setState({ avatar_selected: { url: this.props.avatar_user.url_big } });
    }
    else if (this.props.avatar_user.url && this.props.avatar_user.url != null) {
      this.setState({ avatar_selected: { url: this.props.avatar_user.url } });
    }
    else if (this.props.avatar_user.data && this.props.avatar_user.data !== null) {
      this.setState({ avatar_selected: { content_type: this.props.avatar_user.content_type, data: this.props.avatar_user.data} });      
    }else {
      this.setState({ avatar_selected: { url: 'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png' } });
    }
  }

  _setFileSelected(value, name){

    this.setState({
      avatar_selected: value,
    });

    this.props.newImage(value, false)
  };

  _setDefaultImg(index){
    this.setState({
      avatar_selected: {
        id: index,
        image: avatars[index]
      }
    })

    this.props.newImage({id: index, image: avatars[index]}, true)
  }

  _clickToLoadFormElement(e) {
    e.preventDefault();
    this.inputElementFile.click();
  }

  _loadFormElement(e, state) {
    const fr = new FileReader();
    let fileIntermediate;
    if (e.target.files !== undefined) {
      fileIntermediate = {
        filename: e.target.files[0].name,
        content_type: e.target.files[0].type
      };
    }
    fr.addEventListener("load", () => {
      fileIntermediate.data = fr.result.split("base64,")[1];

      this._setFileSelected(fileIntermediate, "images");
    });

    fr.readAsDataURL(e.target.files[0]);
  }

  _openModal(e){
    //this._clickToLoadFormElement(e)
    this.setState({openModal: true})
  }

  render() {

    let current_image;
    if (Object.keys(this.state.avatar_selected).length > 0) {
      if (this.state.avatar_selected.id !== undefined) {
        current_image = this.state.avatar_selected.image.default;
      } else if (this.state.avatar_selected.url !== undefined) {
        current_image = this.state.avatar_selected.url;
      } else {
        current_image =
          `data:${this.state.avatar_selected.content_type};base64,` +
          this.state.avatar_selected.data;
      }
    }
    return (
      <div className="wrapper-avatar-selection">
        {Object.keys(this.state.avatar_selected).length > 0 && !this.props.disabled && this.props.title &&(
          <p className="headingH5">{this.props.text && this.props.text.yourSelection}</p>
        )}
        {Object.keys(this.state.avatar_selected).length > 0 && (
          <div className={this.props.space ? `wrapper-avatar ${this.props.size} space`: `wrapper-avatar ${this.props.size}`}
          onClick={e => !this.props.disabled ? this._openModal(e) : {}}
          >
            <img className="avatar bordered" src={current_image} alt="" />
            {
                !this.props.disabled &&
            <button
              className="avatar-button"
            >
                <Icon name="Camera" width={18} height={18} color={"white"} />
            </button>
            }
            <input
              type="file"
              ref={inputFile => (this.inputElementFile = inputFile)}
              onChange={e => this._loadFormElement(e, this.state)}
              accept={"image/*"}
            />
          </div>
        )}
        {Object.keys(this.state.avatar_selected).length === 0 && (
          <p className="headingH5">No avatar</p>
        )}
        
        {
          this.state.openModal &&
          <CustomModal close={() => this.setState({openModal: false})}>
            {!this.props.disabled &&
            <div className="select-image">
              {/* <div className="select-image__item">
                <img className="select-image__item-img" src={current_image} alt="" />
              </div>
              <div className="select-image__buttons">
                <p>Selecciona una imagen</p>
                <button className="btn primary">
                  <span className="btn-text">upload image</span>
                </button>
              </div> */}
               {Object.keys(this.state.avatar_selected).length > 0 && (
                  <div className="wrapper-avatar big space">
                    <img className="avatar bordered" src={current_image} alt="" />
                    {
                        !this.props.disabled &&
                    <button
                      onClick={e => this._clickToLoadFormElement(e)}
                      className="avatar-button"
                    >
                        <Icon name="Camera" width={18} height={18} color={"white"} />
                    </button>
                    }
                    <input
                      type="file"
                      ref={inputFile => (this.inputElementFile = inputFile)}
                      onChange={e => this._loadFormElement(e, this.state)}
                      accept={"image/*"}
                    />
                  </div>
                )}
              <p className="headingH5" style={{marginTop: '20px'}}>{this.props.text && this.props.text.defaultAvatars}</p>
              <div className="default-avatars-container">
                {avatars.map((avatar, index) => {
                  return (
                    <button
                      className={this.state.avatar_selected.id === index ? "default-avatar-button is-select" : "default-avatar-button"}
                      key={"avatar_user_" + index}
                      onClick={() => this._setDefaultImg(index)}>
                      <img className="default-avatar" src={avatars[index].default} alt="" />
                    </button>
                  );
                })}
              </div>
              
            </div>
            }
          </CustomModal>
        }
      </div>
      
    );
  }
}

export default CustomAvatar;
