// Dependencies
import React from "react";
import Input from "./input";
import NumberFormat from "react-number-format";
import TableRow from './tableRow'

const TableEditableEmptyRow = (props) => {
  const [rowNew, setRowNew] = React.useState([]); 
  
  React.useEffect(() => {
    let rowNew_temp = props.new_row.slice()
    rowNew_temp[0].value = props.numberOfRow
    setRowNew(rowNew_temp)
  }, [props.new_row]);

  return (
    <TableRow 
        onBlur={(e) => props.onBlur(e)}
        rowsData={{row: rowNew, id: "", temp_id: props.temp_id}}
        onChange={(rowsData, i, value, temp_id) => props.onChange(rowsData, i, value, temp_id)}
        numberOfRow={props.numberOfRow}
        refInput={props.refInput}
      />
  );
};

export default TableEditableEmptyRow;
    {/*<div
      className="c-table-editable__row"
      data-id={props.dataId}
      ref={rowParent}
    >
      {props.new_row.map((input, index) => {
        return (
          <NumberFormat
            ref={inputRef[index]}
            readOnly={index === 0}
            key={`row-cell-${index}`}
            onValueChange={(value) => {
              props.onChange(
                rowParent.current,
                inputRef[index].current,
                value.value,
                props.temp_id
              );
            }}
            onBlur={(e) => props.onBlur(e)}
            defaultValue={index === 0 ? props.numberOfRow : input.value}
            thousandSeparator={","}
            decimalSeparator={"."}
            inputMode="numeric"
            className="c-table-editable__cell"
          />
        );
      })}
    </div>*/}
