// Dependencies
import React, { useState, useEffect } from "react";


// Components
import ViewList from "../../components/viewList";
import Accordion from '../../components/accordion'



function DocumentsShowScreen(props){

   const  _setBradcrumbTitle = (title) =>{
    
    props.getBreadcrumbs({
          assetUrl: '/documents-show',
          url: '/documents-show',
          assetTitle: title
        });
  }

    return (
      <>
      <ViewList 
        files={true}
        colorGrey={true}
        setBradcrumbTitle = {(title) => _setBradcrumbTitle(title)}
        assignData={
          {id: props.history.location.state !== undefined && props.history.location.state.id,
          url: props.history.location.state !== undefined && props.location.state.url,
          location: props.location,
          modal: false
          }}  {...props}
          />
     </> 
    );
  }

  export default DocumentsShowScreen




