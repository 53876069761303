// Dependencies
import React, { useEffect, useState } from "react";

//Helpers
import { getValueLocalized } from "../helpers/getLocale";

const LinkBlock = ({
  balance,
  date,
  dateBig,
  value,
  description,
  icon,
  action,
  className,
  checkBox,
  isChecked,
  currency,
  colorValue,
  editable
}) => {
  const [checked, setChecked] = useState(isChecked);
  const tempLinkBlockClass = [className && className]
    .filter(elem => elem !== false)
    .join(" ");

    const option = (e) => {
      action()
      setChecked(!checked)      
      
      e.preventDefault()
    }

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);
  
  return (
    <div 
      className={`wrapper-link-block ${tempLinkBlockClass}`} 
      onClick={(e) => editable === false ? "" : option(e)}
    >
      {checkBox && (
        <label className="container-check">
          <input
            id="input-check"
            name="check"
            type="checkbox"
            checked={checked}
            disabled={editable === false}
            defaultChecked={checked}
          />
          <span className="checkmark"/>
        </label>
      )}

      <div className="subwrapper-link-block">
        {balance && (
          <span className={`date ${dateBig === true ? "big" : ""}`}>
            {date}
          </span>
        )}
        <div className="wrapper-description">
            {!balance && icon}
            <span className="text">{description}</span>
        </div>
      </div>

      {value !== 0 && value && (
        <span className={`balance ${ colorValue ? colorValue : value > 0 ? "blue" : "orange"}`}>
          {currency
            ? getValueLocalized("en-US", value, "$", "currency").formated.split(".")[0]
            : value}
        </span>
      )}
    </div>
  );
};

export default LinkBlock;
