// Dependencies
import React from "react";

const PrivateEquity = (props) => {
  return (
    <svg
      width={props.width ? props.width : "40px"}
      height={props.height ? props.height : "41px"}
      viewBox="0 0 40 41"
    >
      
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Private-Equity" transform="translate(-12.000000, -11.000000)" fill={props.color ? props.color : "#80abba"} fillRule="nonzero">
                <g id="noun_Shareholders_1326984" transform="translate(12.000000, 11.000000)">
                    <g id="Group">
                        <path d="M39.8787879,34.0878562 L34.3636364,26.4330393 C34.2424242,26.2536296 34.0606061,26.1938263 33.8787879,26.1938263 L28.7878788,26.1938263 C28.4242424,26.1938263 28.1818182,26.4330393 28.1818182,26.7918589 C28.1818182,27.1506784 28.4242424,27.3898915 28.7878788,27.3898915 L33.5151515,27.3898915 L38.1818182,33.8486431 L1.75757576,33.8486431 L6.42424242,27.3898915 L11.2121212,27.3898915 C11.5757576,27.3898915 11.8181818,27.1506784 11.8181818,26.7918589 C11.8181818,26.4330393 11.5757576,26.1938263 11.2121212,26.1938263 L6.12121212,26.1938263 C5.93939394,26.1938263 5.75757576,26.3134328 5.63636364,26.4330393 L0.121212121,34.0878562 C0.0606060606,34.1476594 0,34.3270692 0,34.4466757 L0,39.4701493 C0,39.8289688 0.242424242,40.0681818 0.606060606,40.0681818 L39.3939394,40.0681818 C39.7575758,40.0681818 40,39.8289688 40,39.4701493 L40,34.3868725 C40,34.3270692 39.9393939,34.1476594 39.8787879,34.0878562 Z M38.7878788,38.8721167 L1.21212121,38.8721167 L1.21212121,34.984905 L38.7878788,34.984905 L38.7878788,38.8721167 Z" id="Shape"></path>
                        <path d="M20,17.7615672 C15.9393939,17.7615672 12.6666667,21.0507463 12.6666667,25.0575645 C12.6666667,29.0643826 15.9393939,32.3535617 20,32.3535617 C24.0606061,32.3535617 27.3333333,29.0643826 27.3333333,25.0575645 C27.3333333,21.0507463 24.0606061,17.7615672 20,17.7615672 Z M20,31.1574966 C16.6060606,31.1574966 13.8787879,28.4065468 13.8787879,25.0575645 C13.8787879,21.7085821 16.6060606,18.9576323 20,18.9576323 C23.3939394,18.9576323 26.1212121,21.7085821 26.1212121,25.0575645 C26.1212121,28.4065468 23.3939394,31.1574966 20,31.1574966 Z" id="Shape"></path>
                        <path d="M20.5454545,24.2801221 L20.4848485,22.0075984 C21.0909091,22.0674016 21.6969697,22.3066147 22.2424242,22.7252374 L23.030303,21.5889756 C22.2424242,21.0507463 21.3939394,20.75173 20.4848485,20.6919267 L20.4848485,20.0938942 L19.6969697,20.0938942 L19.6969697,20.6919267 C18.9090909,20.75173 18.2424242,20.990943 17.7575758,21.4095658 C17.2727273,21.8281886 17.030303,22.4262212 17.030303,23.1438602 C17.030303,23.8614993 17.2727273,24.3997286 17.6969697,24.6987449 C18.1212121,25.0575645 18.7878788,25.3565807 19.6969697,25.5359905 L19.6969697,27.8683175 C18.969697,27.748711 18.2424242,27.3898915 17.5757576,26.7918589 L16.6666667,27.8683175 C17.5757576,28.6457598 18.5454545,29.0643826 19.6969697,29.1839891 L19.6969697,30.081038 L20.4848485,30.081038 L20.4848485,29.1839891 C21.3333333,29.1241859 22,28.8849729 22.4848485,28.4663501 C22.969697,28.0477273 23.2121212,27.4496947 23.2121212,26.7320556 C23.2121212,26.0144166 22.969697,25.4761872 22.5454545,25.1173677 C22.1818182,24.7585482 21.5151515,24.4595319 20.5454545,24.2801221 Z M19.7575758,24.0409091 C19.2727273,23.9213026 18.969697,23.7418928 18.8484848,23.562483 C18.6666667,23.3830733 18.6060606,23.2036635 18.6060606,22.9046472 C18.6060606,22.6056309 18.7272727,22.4262212 18.9090909,22.2468114 C19.0909091,22.0674016 19.3939394,21.9477951 19.7575758,21.9477951 L19.7575758,24.0409091 Z M21.4545455,27.5693012 C21.2121212,27.748711 20.9090909,27.8683175 20.5454545,27.9281208 L20.5454545,25.7154003 C21.030303,25.8350068 21.3939394,26.0144166 21.5757576,26.1938263 C21.7575758,26.3732361 21.8181818,26.6124491 21.8181818,26.8516621 C21.7575758,27.1506784 21.6969697,27.3898915 21.4545455,27.5693012 Z" id="Shape"></path>
                        <path d="M0.606060606,13.993962 L6.12121212,13.993962 L12.1212121,20.0938942 C12.3636364,20.3331072 12.7272727,20.3331072 12.969697,20.0938942 C13.2121212,19.8546811 13.2121212,19.4958616 12.969697,19.2566486 L7.75757576,13.993962 L12.1212121,13.993962 C12.4848485,13.993962 12.7272727,13.754749 12.7272727,13.3959294 L12.7272727,11.6018318 C12.7272727,9.26950475 10.7878788,7.35580054 8.48484848,7.35580054 L8.42424242,7.35580054 C9.51515152,6.69796472 10.1818182,5.50189959 10.1818182,4.24603121 C10.1818182,2.21272049 8.48484848,0.598032564 6.42424242,0.598032564 C6.36363636,0.598032564 6.3030303,0.598032564 6.24242424,0.598032564 C4.24242424,0.657835821 2.66666667,2.27252374 2.66666667,4.24603121 C2.66666667,5.56170285 3.39393939,6.69796472 4.42424242,7.35580054 L4.24242424,7.35580054 C1.87878788,7.35580054 0,9.26950475 0,11.6018318 L0,13.3959294 C0,13.754749 0.242424242,13.993962 0.606060606,13.993962 Z M6.36363636,1.79409769 C6.36363636,1.79409769 6.36363636,1.79409769 6.36363636,1.79409769 C6.42424242,1.79409769 6.42424242,1.79409769 6.42424242,1.79409769 C7.81818182,1.79409769 8.96969697,2.93035957 8.96969697,4.30583446 C8.96969697,5.20288331 8.42424242,6.09993216 7.63636364,6.51855495 C7.27272727,6.69796472 6.90909091,6.81757123 6.48484848,6.81757123 C5.09090909,6.81757123 3.93939394,5.68130936 3.93939394,4.36563772 C3.87878788,2.93035957 4.96969697,1.85390095 6.36363636,1.79409769 Z M1.21212121,11.6018318 C1.21212121,9.92734057 2.60606061,8.55186567 4.24242424,8.55186567 L8.48484848,8.55186567 C10.1818182,8.55186567 11.5151515,9.92734057 11.5151515,11.6018318 L11.5151515,12.7978969 L1.21212121,12.7978969 L1.21212121,11.6018318 Z" id="Shape"></path>
                        <path d="M14.2424242,13.993962 L19.4545455,13.993962 L19.4545455,16.6253053 C19.4545455,16.9841248 19.6969697,17.2233379 20.0606061,17.2233379 C20.4242424,17.2233379 20.6666667,16.9841248 20.6666667,16.6253053 L20.6666667,13.993962 L25.7575758,13.993962 C26.1212121,13.993962 26.3636364,13.754749 26.3636364,13.3959294 L26.3636364,11.6018318 C26.3636364,9.26950475 24.4242424,7.35580054 22.1212121,7.35580054 L22.0606061,7.35580054 C23.0909091,6.69796472 23.8181818,5.56170285 23.8181818,4.24603121 C23.8181818,2.21272049 22.1212121,0.538229308 20.0606061,0.538229308 C18,0.538229308 16.3030303,2.21272049 16.3030303,4.24603121 C16.3030303,5.56170285 17.030303,6.69796472 18.0606061,7.35580054 L17.8787879,7.35580054 C15.5151515,7.35580054 13.6363636,9.26950475 13.6363636,11.6018318 L13.6363636,13.3959294 C13.6363636,13.754749 13.8787879,13.993962 14.2424242,13.993962 Z M20.0606061,1.79409769 C21.4545455,1.79409769 22.6060606,2.93035957 22.6060606,4.30583446 C22.6060606,5.68130936 21.4545455,6.75776798 20.0606061,6.75776798 C18.6666667,6.75776798 17.5151515,5.62150611 17.5151515,4.30583446 C17.5151515,2.87055631 18.6666667,1.79409769 20.0606061,1.79409769 Z M14.8484848,11.6018318 C14.8484848,9.92734057 16.2424242,8.55186567 17.8787879,8.55186567 L22.1212121,8.55186567 C23.8181818,8.55186567 25.1515152,9.92734057 25.1515152,11.6018318 L25.1515152,12.7978969 L14.8484848,12.7978969 L14.8484848,11.6018318 Z" id="Shape"></path>
                        <path d="M27.8181818,13.993962 L32.3636364,13.993962 L27.3939394,19.8546811 C27.1515152,20.0938942 27.2121212,20.512517 27.4545455,20.6919267 C27.6969697,20.9311398 28.1212121,20.8713365 28.3030303,20.6321235 L33.9393939,13.993962 L39.3939394,13.993962 C39.7575758,13.993962 40,13.754749 40,13.3959294 L40,11.6018318 C40,9.26950475 38.0606061,7.35580054 35.7575758,7.35580054 L35.6969697,7.35580054 C36.7272727,6.69796472 37.4545455,5.56170285 37.4545455,4.24603121 C37.3939394,2.27252374 35.7575758,0.598032564 33.6969697,0.598032564 C31.6363636,0.598032564 29.9393939,2.27252374 29.9393939,4.30583446 C29.9393939,5.56170285 30.6060606,6.75776798 31.6969697,7.4156038 L31.5151515,7.4156038 C29.1515152,7.4156038 27.2727273,9.32930801 27.2727273,11.661635 L27.2727273,13.4557327 C27.2121212,13.754749 27.5151515,13.993962 27.8181818,13.993962 Z M33.6969697,1.79409769 C35.0909091,1.79409769 36.1818182,2.93035957 36.1818182,4.30583446 C36.1818182,5.68130936 35.030303,6.75776798 33.6363636,6.75776798 C33.2121212,6.75776798 32.7878788,6.63816147 32.4242424,6.4587517 C31.6363636,6.0401289 31.0909091,5.20288331 31.0909091,4.30583446 C31.1515152,2.87055631 32.2424242,1.79409769 33.6969697,1.79409769 Z M28.4242424,11.6018318 C28.4242424,9.92734057 29.8181818,8.55186567 31.4545455,8.55186567 L35.6969697,8.55186567 C37.3939394,8.55186567 38.7272727,9.92734057 38.7272727,11.6018318 L38.7272727,12.7978969 L28.4242424,12.7978969 L28.4242424,11.6018318 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default PrivateEquity;
