// Dependencies
import React from "react";

const Plus = ({ width, height, color, bgColor, close }) => {
  return (
    <svg
      width={width ? width : "15px"}
      height={height ? height : "16px"}
      viewBox="0 0 15 16"
      style={ close === true ? {transform:  'rotate(45deg)'}: {transform:  'rotate(0deg)'}}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Bullets-/-Bullet-blue" fillRule="nonzero">
          <g id="bullet">
            <circle
              id="Oval-5"
              fill={bgColor ? bgColor : "#80abba"}
              cx="7.5"
              cy="8"
              r="7.5"
            />
            <path
              d="M6.5,6.90909091 L6.5,3.90909091 C6.5,3.40701386 6.94771525,3 7.5,3 C8.05228475,3 8.5,3.40701386 8.5,3.90909091 L8.5,6.90909091 L11.5909091,6.90909091 C12.0929861,6.90909091 12.5,7.39750755 12.5,8 C12.5,8.60249245 12.0929861,9.09090909 11.5909091,9.09090909 L8.5,9.09090909 L8.5,12.0909091 C8.5,12.5929861 8.05228475,13 7.5,13 C6.94771525,13 6.5,12.5929861 6.5,12.0909091 L6.5,9.09090909 L3.40909091,9.09090909 C2.90701386,9.09090909 2.5,8.60249245 2.5,8 C2.5,7.39750755 2.90701386,6.90909091 3.40909091,6.90909091 L6.5,6.90909091 Z"
              id="Combined-Shape"
              fill={color ? color : "#FFFFFF"}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Plus;
