// Dependencies
import React from "react";
import classNames from "classnames";

// Components
import Modal from "../components/modal";
import Loading from "../components/loading";

function AlertModalUniken(props) {

  const removeBodyClass = () => {
    let body = document.body;
    body.classList.remove('has-modal');
  }

  const wrapperMsgClasses = () => {
    let cl = classNames("msg", props.loading && "o-padding--bottom-zero")
    return cl;
  }


  return (
    <Modal modalType="alert" isShowed={props.isShowed} size={props.size}>
      <div className={wrapperMsgClasses()}>
        <p className="title" style={{marginBottom: 10, color: props.dissapproved == true ? "#f3b01c" : "#005774"}}>{props.title}</p>
        <div style={{padding: 6}} dangerouslySetInnerHTML={{ __html: props.msg }} />
        {
          props.dissapproved == true &&
          <div style={{marginTop: 12}} dangerouslySetInnerHTML={{ __html: props.subtitle }} />
        } 
        {
        props.loading == true &&
          <Loading 
            simple 
            width={96} 
            height={96} 
            style={{marginTop: -12}}
          />
        }       
      </div>


      <div className="wrapper-modal-button has-separation">
        {props.cancelButton && (
          <button
            className={
              props.cancelButton
                ? `btn-text alert cancel ${props.cancelButtonAlertColor && "orange"}`
                : "btn-text alert"
            }
            onClick={() => {
              removeBodyClass();
              props.actionCancelButton();
            }}
          >
            {props.cancelTextButton}
          </button>
        )}
        <button
          className={
            props.cancelButton
              ? `btn-text alert cancel ${props.textButtonAlertColor && "orange"}`
              : "btn-text alert"
          }
          onClick={() => {
            removeBodyClass();
            props.action();
          }}
        >
          {props.textButton}
        </button>
        {props.thirdButton && (
          <button
            className={
              props.cancelButton
                ? `btn-text alert cancel ${
                    props.textButtonAlertColorThird && "orange"
                  }`
                : "btn-text alert"
            }
            onClick={() => {
              removeBodyClass();
              props.actionThirdButton();
            }}
          >
            {props.textButtonThird}
          </button>
        )}
      </div>
    </Modal>
  );
}

export default AlertModalUniken;
