// Dependencies
import React from 'react';

const Garbage = ({ width, height, color }) => {

  return (

    <svg
      width={width ? width : '15px'}
      height={height ? height : '20px'}
      viewBox="0 0 15 20"
    >
      <path
        d="M1.76 19.036c.023.538.47.964 1.012.964h9.456c.542 0 .989-.426 1.012-.964l.675-14.166H1.085l.676 14.166zM9.64 8.388a.41.41 0 0 1 .411-.408h.658a.41.41 0 0 1 .412.408v8.093a.41.41 0 0 1-.412.41h-.658a.41.41 0 0 1-.411-.41V8.388zm-2.88 0a.41.41 0 0 1 .412-.408h.658a.41.41 0 0 1 .411.408v8.093a.41.41 0 0 1-.411.41h-.658a.41.41 0 0 1-.411-.41V8.388zm-2.879 0a.41.41 0 0 1 .412-.408h.658a.41.41 0 0 1 .411.408v8.093a.41.41 0 0 1-.411.41h-.658a.41.41 0 0 1-.412-.41V8.388zM14.364 1.03c.351 0 .636.283.636.632v1.984H0V1.662c0-.349.285-.632.636-.632h4.362V.21c0-.116.095-.21.212-.21h4.58c.117 0 .212.094.212.21v.82h4.362z"
        fill={ color ? color : "#80abba" }
        fillRule="evenodd"
      />
    </svg>

  );

}

export default Garbage;
