// Dependencies
import React from "react";
import { connect } from "react-redux";

// Actions
import { getDocDetail, updateDoc, getDocDetailSuccess } from "../../actions/documents";
import { destroyAsset } from "../../actions/balance";

// Components
import AssetsResourcesModal from "../balance/details/assetsResourcesModal";
import DetailContentBlock from "../../components/detailContentBlock";
import TextBlock from "../../components/textBlock";
import ActionSheet from "../../components/actionSheet";
import FloatButton from "../../components/floatButton";
import Icon from "../../components/icons";
import AlertModal from "../../components/alertModal";

// Helpers
// import { openURLHelper } from "../../../lib/openURLHelper";
import ActionSheetOnPressAction from "../../helpers/actionSheetOnPressAction";

class DocumentDetailScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      optionsActionSheet: [],
      showModal: false,
      modalData: {},
      showDeleteModal: false,
      url: ""
    };
    this.actionSheet = React.createRef();
  }

  _success() {
    this.setState({
      loading: false
    });
  }

  _error() {
    alert("error");
  }

  componentDidMount() {
    this._fetch();
  }

  _goToResource(navigate_to_resource) {
    let asset = navigate_to_resource
    if(asset.url.indexOf('ownership_structure') !== -1){
      window.location.href = asset.url
    }else {
      let url_asset = asset.url.slice()
      url_asset = url_asset.split("/")
      url_asset.pop()
      url_asset = url_asset.join("/")
      
      this.props.history.push("balance-detail?url="+url_asset+"&id="+asset.resourceable_id, {
        url: url_asset,
        id: asset.resourceable_id
      });    
    }
  }

  _fetch() {
    this.props.getDocDetail(
      this.props.authentication_token,
      () => this._success(),
      () => this._error(),
      this.props.history.location.state.url
    );
  }

  _actionSheetPressed(item) {
    let response = ActionSheetOnPressAction(
      item,
      this.props.documents_detail,
      this.props,
      () => this._deleteAsset(item.url, item.method)
    );
    this.actionSheet.current.show();
    if (response.status)
      this.setState({ showModal: true, modalData: response.modalData });
  }

  _showActionSheetContent() {
    let that = this;
    let optionsActionSheet = [];
    this.props.documents_detail.actions.map((item, i) => {
      optionsActionSheet.push({
        type: "icon button",
        code: item.code,
        iconName: item.code.includes("add")
          ? "Close"
          : item.code.includes("edit")
          ? "Pencil"
          : item.code.includes("show")
          ? "Eye"
          : "Garbage",
        message: item.title,
        onClick: () => {
          this._actionSheetPressed(item);
        }
      });
    });

    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true
        }
      ]
    });

    setTimeout(function() {
      that.actionSheet.current.show();
    }, 100);
  }

  _successCreateAssetResource() {
    alert("success");
    this._fetch();
  }

  _errorCreateAssetResource() {
    alert("error");
  }

  _uploadResource(data) {
    this.setState({ showModal: false });

    this.props.updateDoc(
      this.props.authentication_token,
      data,
      responseJson => this.props.getDocDetailSuccess(responseJson),
      () => alert("error"),
      this.state.modalData.url
    );
  }

  _deleteAsset(url, method) {
    this.setState({
      showDeleteModal: true,
      url: url,
      method: method
    });
  }

  _successDeleteAsset(url) {
    this.props.history.replace("/documents-show", {
      url: this.props.history.location.state.url_toBack
    });
  }

  render() {

    return (
      <div className="main-wrapper detail">
        <>
          {this.props.documents_detail.form !== undefined &&
            this.props.documents_detail.form.edit_document.inputs.length > 0 &&
            this.props.documents_detail.form.edit_document.inputs
              .filter(
                element =>
                  element.hidden !== true && element.id !== "category_id"
              )
              .map((element, i) => {
                return (
                  <div key={i}>
                    {element.type !== "file" && (
                      <DetailContentBlock title={element.label}>
                        <TextBlock description={element.value} />
                      </DetailContentBlock>
                    )}
                    {element.type === "file" && (
                      <div className="wrapper-buttons">
                        <a href={element.value} rel="noopener noreferrer" target="_blank">
                          <button className="btn not-centered ">
                            <p className="btn-text">Open</p>
                          </button>
                        </a>

                        {this.props.history.location.state
                          .navigate_to_resource !== undefined &&
                          this.props.history.location.state.navigate_to_resource
                            .url !== null && (
                            <button
                              onClick={() =>
                                this._goToResource(
                                  this.props.history.location.state
                                    .navigate_to_resource
                                )
                              }
                              className="btn not-centered "
                            >
                              <p className="btn-text">{this.props.internalization.buttons.go_to_resource}</p>
                            </button>
                          )}
                      </div>
                    )}
                  </div>
                );
              })}
        </>
        <ActionSheet
          ref={this.actionSheet}
          render={this.state.options_action_sheet}
        />
        <FloatButton
          children={
            <Icon
              name="Close"
              color="white"
              iconBig={true}
              className="wrapper-icon-floatButton"
              close={false}
            />
          }
          action={() => this._showActionSheetContent(this.state)}
        />

        {this.state.showModal === true && (
          <AssetsResourcesModal
            language={this.props.user.language}
            data={this.state.modalData}
            errors={this.props.errors}
            closeModal={e => {
              if (
                e.target.className === "modal-wrapper" ||
                (e.target.closest(".modal-header") !== null &&
                  e.target.closest(".modal-header").className.includes("modal-header"))
              ) {
                this.setState({ showModal: false });
              }
            }}
            successSendFiles={() => this._fetch()}
            uploadResource={data => this._uploadResource(data)}
            showModalSessionExpire={(time_left, diffMins, checkTime) => this.props.showModalSessionExpire(time_left, diffMins, () => checkTime())}
          />
        )}
        {this.state.showDeleteModal && (
          <AlertModal
            isShowed={this.state.showDeleteModal}
            title="Delete Document"
            msg="Are you sure you want to delete this document?"
            action={() => {
              this.props.destroyAsset(
                this.props.authentication_token,
                () => this._successDeleteAsset(this.state.url),
                () => alert("error"),
                this.state.url,
                this.state.method
              );
              this.setState({
                showDeleteModal: false
              });
            }}
            textButton="Yes"
            textButtonAlertColor={true}
            cancelButton={true}
            cancelTextButton="Cancel"
            actionCancelButton={() => {
              this.setState({
                showDeleteModal: false
              });
            }}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    documents_detail: state.documents.documents_detail,
    errors: state.session.errors,
    internalization: state.session.internalization,    
    user: state.session.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDocDetail: (authentication_token, success, error, url) =>
      dispatch(getDocDetail(authentication_token, success, error, url)),
    destroyAsset: (authentication_token, success, error, url, method) =>
      dispatch(destroyAsset(authentication_token, success, error, url, method)),
    updateDoc: (authentication_token, attributes, success, error, url) =>
      dispatch(
        updateDoc(authentication_token, attributes, success, error, url)
      ),
    getDocDetailSuccess: payload => dispatch(getDocDetailSuccess(payload))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentDetailScreen);
