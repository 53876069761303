// Dependencies
import React from "react";

// Components
import Input from "../components/input";
import ImageGrid from "../components/imageGrid";

// Helpers
import { getTitle } from "../helpers/getTitle";

let fileResult = [];

class LoadFileButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formDataImage: { images: [], import_xls: {} },
      formValidationImage: this.props.data !== undefined && {
        [this.props.data.code === "add_document" ||
        this.props.data.code === "edit_document" ||
        this.props.element_id === "import_xls"
          ? this.props.element_id === "import_xls"
            ? "import_xls"
            : "file"
          : "images"]: false,
      },
      allFiles: [],
      tempImg: [],
    };

    fileResult =
      this.props.data !== undefined && this.props.data.code === "edit_asset"
        ? this.props.editImages
        : [];
  }

  _setFileSelected = (value, name) => {
    this.setState(
      {
        formDataImage: {
          ...this.state.formDataImage,
          [this.props.element_id == "import_xls" ? "import_xls" : name]: value,
        },
        formValidationImage: {
          ...this.state.formValidationImage,
          [this.props.element_id == "import_xls" ? "import_xls" : name]:
            Object.keys(value).length > 0,
        },
      },
      () => {
        this.props.action(this.state);
      }
    );
  };

  _clickToLoadFormElement(e) {
    e.preventDefault();

    if (!this.props.showAsset) {
      this.inputElementFile.click();
    }
  }

  _loadMultipleFormElement(e, state) {
    var fileIntermediate = [];
    var fileResult = [];
    var cont = 0;
    var sizeFiles = e.target.files.length;

    for (var i = 0; i < e.target.files.length; i++) {
      var files = e.target.files[i];

      fileIntermediate.push({
        filename: files.name,
        content_type: files.type,
      });

      let fr = new FileReader();
      fr.addEventListener("load", (e) => {
        fileIntermediate[cont].data = e.target.result.split("base64,")[1];

        fileResult[cont] = fileIntermediate[cont];

        if (cont === sizeFiles - 1) {
          if (this.state.formDataImage.images !== undefined) {
            let assetImages = this.state.formDataImage.images;

            this.setState({
              formDataImage: {
                ...this.state.formDataImage,
                images: assetImages.concat(fileResult),
              },
            });

            this._setFileSelected(assetImages.concat(fileResult, "images"));
          } else {
            this.setState({
              formDataImage: {
                ...this.state.formDataImage,
                images: fileResult,
              },
            });

            this._setFileSelected(fileResult, "images");
          }
        }

        cont++;
      });

      fr.readAsDataURL(files);
    }
  }

  _loadFormElement(e, state) {
    const fr = new FileReader();
    let fileIntermediate;
    if (e.target.files[0] !== undefined) {
      fileIntermediate = {
        filename: e.target.files[0].name,
        content_type: e.target.files[0].type,
      };
    }

    fr.addEventListener("load", () => {
      fileIntermediate.data = fr.result.split("base64,")[1];
      fileResult.push(fileIntermediate);

      this._setFileSelected(
        this.props.data.code === "add_document" ||
          this.props.data.code === "edit_document" ||
          this.props.element_id === "import_xls"
          ? fileIntermediate
          : fileResult,
        this.props.data.code === "add_document" ||
          this.props.data.code === "edit_document" ||
          this.props.element_id === "import_xls"
          ? "file"
          : "images"
      );
      if (this.props.element_id !== "import_xls")
        this._setFileSelected(fileResult, "images");
      this.setState({
        formValidationImage: {
          ...state.formValidationImage,
          [this.props.data.code === "add_document" ||
          this.props.data.code === "edit_document" ||
          this.props.element_id === "import_xls"
            ? "file"
            : "images"]: true,
        },
      });
    });

    if (e.target.files[0] !== undefined) {
      fr.readAsDataURL(e.target.files[0]);
    }
  }

  _deleteImages(state, index) {
    let object = state.formDataImage.images[index];
    let id = object.id;
    if (id == "image") {
      id = state.formDataImage.images[index].real_id;
    }
    if (id != undefined) {
      this.props.deleteImage(id);
    }

    state.formDataImage.images.splice(index, 1);

    this.setState(
      {
        formDataImage: state.formDataImage,
        formValidationImage: {
          ...this.state.formValidationImage,
          images: state.formDataImage.images.length > 0,
        },
      },
      () => {
        this.props.action(this.state);
      }
    );
  }

  componentDidMount() {
    this.setState({
      formDataImage: {
        ...this.state.formDataImage,
        images: this.props.editImages,
      },
    });
  }

  render() {

    let resourceTexts =
      this.props.data !== undefined && getTitle(this.props.data);

    return (
      <div style={{ flexDirection: "column", margin: "auto" }}>
        {this.props.type === "file" &&
          this.state.formDataImage.hasOwnProperty("file") &&
          Object.keys(this.state.formDataImage["file"]).length > 0 && (
            <div>
              <Input
                colorGrey={this.props.colorGrey}
                onChangeInput={() => ""}
                value={this.state.formDataImage.file.filename}
                type="delete"
                withButtonIcon={true}
                iconName="Garbage"
                //colorIcon="#787D7F"
                buttonIconAction={() => this._setFileSelected({}, "file")}
              />
            </div>
          )}
        {this.props.type === "file" &&
          this.state.formDataImage.hasOwnProperty("import_xls") &&
          this.state.formDataImage["import_xls"] &&
          Object.keys(this.state.formDataImage["import_xls"]).length > 0 && (
            <div>
              <Input
                colorGrey={this.props.colorGrey}
                onChangeInput={() => ""}
                value={this.state.formDataImage.import_xls.filename}
                type="delete"
                withButtonIcon={true}
                iconName="Garbage"
                //colorIcon="#787D7F"
                buttonIconAction={() => this._setFileSelected({}, "import_xls")}
              />
            </div>
          )}
        {(this.props.type === "file"
          ? (!this.state.formDataImage.hasOwnProperty("file") &&
              this.state.formDataImage.hasOwnProperty("import_xls")) ||
            Object.keys(this.state.formDataImage["file"]).length === 0
          : true) && (
          <>
            {!this.props.showAsset && (
              <button
                className={
                  this.props.type === "image"
                    ? "btn primary  with-marginBottom left"
                    : "btn primary border with-marginBottom"
                }
                onClick={(e) => this._clickToLoadFormElement(e)}
              >
                <span className="btn-text">
                  {this.props.type === "file"
                    ? resourceTexts.uploadButton
                    : "Add Images"}
                </span>
              </button>
            )}

            <input
              type="file"
              ref={
                this.props.showAsset
                  ? this.props.inputRef
                  : (inputFile) => (this.inputElementFile = inputFile)
              }
              //ref={inputFile => (this.inputElementFile = inputFile)}
              onChange={(e) =>
                this.props.loadMultiple
                  ? this._loadMultipleFormElement(e, this.state)
                  : this._loadFormElement(e, this.state)
              }
              accept={
                (this.props.data !== undefined &&
                  this.props.data.code === "add_document") ||
                (this.props.data !== undefined &&
                  this.props.data.code === "edit_document") ||
                this.props.element_id === "import_xls"
                  ? ".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                  : "image/*"
              }
              multiple={this.props.loadMultiple ? true : false}
            />
          </>
        )}
        {this.props.tooltip && (
          <div>
            <span
              className=""
              dangerouslySetInnerHTML={{ __html: this.props.tooltip }}
            ></span>
          </div>
        )}

        {this.props.type === "image" && (
          <ImageGrid
            files={
              this.state.formDataImage.images !== undefined
                ? this.state.formDataImage.images
                : []
            }
            deleteImage={(index) => {
              this._deleteImages(this.state, index);
            }}
          />
        )}
      </div>
    );
  }
}

export default LoadFileButton;
