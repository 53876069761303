// Dependencies
import React from "react";
const Unlink = ({ width, height, color, waveColor }) => {
  return (
    <svg
      width={width ? width : "24px"}
      height={height ? height : "22px"}
      viewBox="0 0 24 22.19"
    >
      <g fill={color ? color : "#80abba"}>
      <path class="b" d="M22.51,1.49c-1.98-1.98-5.21-1.98-7.2,0l-3.6,3.6c-.31,.31-.31,.8,0,1.1s.8,.31,1.1,0l3.61-3.6c.66-.66,1.55-1.02,2.49-1.02s1.83,.37,2.49,1.03,1.03,1.55,1.03,2.49-.37,1.83-1.03,2.49l-4.71,4.71c-1.37,1.37-3.62,1.37-4.99,0-.31-.31-.8-.31-1.1,0s-.31,.8,0,1.1c1,1,2.3,1.49,3.6,1.49s2.61-.49,3.6-1.49l4.71-4.71c.96-.96,1.49-2.23,1.49-3.6s-.53-2.65-1.49-3.6h0Z"/>
      <path class="b" d="M10.63,16.55l-3.05,3.05c-.66,.66-1.55,1.03-2.49,1.03s-1.83-.37-2.49-1.03c-1.37-1.37-1.37-3.62,0-4.99l4.43-4.43c.66-.66,1.55-1.02,2.49-1.02s1.83,.37,2.49,1.02c.31,.31,.8,.31,1.1,0s.31-.8,0-1.1c-1.98-1.98-5.21-1.98-7.2,0L1.49,13.51c-.96,.96-1.49,2.23-1.49,3.6s.53,2.65,1.49,3.6c.96,.96,2.23,1.49,3.6,1.49s2.65-.53,3.6-1.49l3.05-3.05c.31-.31,.31-.8,0-1.1s-.8-.31-1.1,0Z"/>
      </g>
      <path fill={color ? color : "#80abba"} class="b" d="M21.23,18.76l2.39-2.39c.24-.24,.24-.62,0-.86s-.62-.24-.86,0h0l-2.39,2.39-2.39-2.39c-.24-.24-.62-.24-.86,0s-.24,.62,0,.86l2.39,2.39-2.39,2.39c-.24,.24-.24,.62,0,.86s.62,.24,.86,0l2.39-2.39,2.39,2.39c.24,.24,.62,.24,.86,0s.24-.62,0-.86l-2.39-2.39Z"/>
    </svg>
  );
};

export default Unlink;

