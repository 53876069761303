// Dependencies
import React from "react";

const Vehicle = (props) => {
  return (
    <svg
      width={props.width ? props.width : "64px"}
      height={props.height ? props.height : "36px"}
      viewBox="0 0 64 36"
    >
      
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Vehicles---Car-/-Motorcycle" transform="translate(-4.000000, -14.000000)" stroke={props.color ? props.color : "#80abba"} strokeWidth="2">
                <g id="lines-BKP" transform="translate(5.000000, 15.000000)">
                    <path d="M0.0832462647,28.9421799 C-0.11759215,20.6084641 0.0449737481,15.7990643 0.570943959,14.5139803 C1.35989928,12.5863545 7.94189305,2.30098264 12.0892486,0.82418142 C16.2366041,-0.652619795 34.1443707,0.102013451 36.5319112,1.16451345 C38.9194517,2.22701345 45.5685932,11.289622 47.4006291,11.6718268 C49.232665,12.0540317 58.9558525,13.2526645 60.286407,15.4970113 C61.1734433,16.9932425 61.5355555,21.373411 61.3727435,28.6375169" id="Path-3" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M19.4394177,27.1129401 L42.0826568,27.1456886" id="Path-4" stroke-linecap="round" stroke-linejoin="round"></path>
                    <ellipse id="Oval" cx="12.6345396" cy="27.1129401" rx="6.42682927" ry="6.42222222"></ellipse>
                    <ellipse id="Oval" cx="48.5491738" cy="27.1129401" rx="6.42682927" ry="6.42222222"></ellipse>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default Vehicle;
