// Dependencies
import React from 'react';

const InfoTooltip = ({ width="20px", height="20px", color="#005774" }) => {

  return (

    <svg 
      width={width}
      height={height}
      viewBox="0 0 330 330"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="infoTooltip" className="filled" fill={color}>
          <path d="M165,0 C256.126984,0 330,73.8730163 330,165 C330,256.126984 256.126984,330 165,330 C73.8730163,330 0,256.126984 0,165 C0,73.8730163 73.8730163,0 165,0 Z M165,20 C84.9187113,20 20,84.9187113 20,165 C20,245.081289 84.9187113,310 165,310 C245.081289,310 310,245.081289 310,165 C310,84.9187113 245.081289,20 165,20 Z" id="Oval" fillRule="nonzero"></path>
          <rect id="Rectangle" x="155" y="120" width="20" height="130" rx="10"></rect>
          <circle id="Oval" cx="165" cy="91" r="10"></circle>
        </g>
      </g>
    </svg>
  )

}

export default InfoTooltip;
