//https://www.itworld.com/article/2832447/how-to-create-a-session-timeout-warning-for-your-web-application-using-jquery.html

import {SESS_POLL_INTERVAL, SESS_EXPIRATION_MINUTES, SESS_WARNING_MINUTES, SESS_EXPIRATION_MINUTES_ADMIN, SESS_WARNING_MINUTES_ADMIN} from '../constants'
var sess_inervalID;
var sess_lastActivity;

export function initSessionMonitor(destroySession, show_expiration_modal = undefined, admin = false){
	sess_lastActivity = new Date();
	sessClearInterval()
	sessSetInterval(destroySession, show_expiration_modal, admin);
	document.addEventListener("keypress.session", function(ed, e){sessKeyPressed(ed,e)})
	// $(document).bind("keypress.session", function(ed, e){sessKeyPressed(ed,e)})
	global.sessKeyPressed = () => sessKeyPressed("","")
}

function sessInterval(destroySession, show_expiration_modal, admin){
	var now = new Date();
	var diff = now - sess_lastActivity;
	var diffMins = (diff/1000/60)
	if(diffMins > (admin ? SESS_WARNING_MINUTES_ADMIN : SESS_WARNING_MINUTES)){
		sessClearInterval()
		//if(diffMins < SESS_EXPIRATION_MINUTES && window.confirm("Your session will expire in "+(SESS_EXPIRATION_MINUTES - (admin ? SESS_WARNING_MINUTES_ADMIN : SESS_WARNING_MINUTES))+" minutes, press OK to remain logged in" )){
		if(diffMins < (admin ? SESS_EXPIRATION_MINUTES_ADMIN : SESS_EXPIRATION_MINUTES)){
			if(show_expiration_modal == undefined){
				if(window.confirm("Your session will expire in "+((admin ? SESS_EXPIRATION_MINUTES_ADMIN : SESS_EXPIRATION_MINUTES) - (admin ? SESS_WARNING_MINUTES_ADMIN : SESS_WARNING_MINUTES))+" minutes, press OK to remain logged in") ){
					now = new Date();
					diff = now - sess_lastActivity;
					diffMins = (diff/1000/60);

					if(diffMins > (admin ? SESS_EXPIRATION_MINUTES_ADMIN : SESS_EXPIRATION_MINUTES) ){
						sessLogOut(destroySession)
					}
					else{
						sessPingServer();
						sessSetInterval(destroySession,show_expiration_modal, admin);
						sess_lastActivity = new Date();
					}
				}
				else{
				sessLogOut(destroySession)
				}
			}
			else{
				show_expiration_modal((admin ? SESS_EXPIRATION_MINUTES_ADMIN : SESS_EXPIRATION_MINUTES) - (admin ? SESS_WARNING_MINUTES_ADMIN : SESS_WARNING_MINUTES), diffMins, () => checkTime(now,diff,diffMins,destroySession, show_expiration_modal, admin))	
			}
			
			
		}
		else{
			sessLogOut(destroySession)
		}
	}
	else{
		sessPingServer()
	}

}

function checkTime(now,diff,diffMins, destroySession, show_expiration_modal, admin){
	now = new Date();
	diff = now - sess_lastActivity;
	diffMins = (diff/1000/60);
	if(diffMins > (admin ? SESS_EXPIRATION_MINUTES_ADMIN : SESS_EXPIRATION_MINUTES) ){
		sessLogOut(destroySession)
	}
	else{
		sessPingServer();
		sessSetInterval(destroySession,show_expiration_modal, admin);
		sess_lastActivity = new Date();
	}
	console.log("checkTime")
}
function sessSetInterval(destroySession, show_expiration_modal, admin){
	sessClearInterval()
	sess_inervalID = setInterval(function(){sessInterval(destroySession, show_expiration_modal, admin)}, SESS_POLL_INTERVAL);
}

function sessClearInterval(){
	clearInterval(sess_inervalID);
}

function sessKeyPressed(ed,e){
	sess_lastActivity = new Date();
}

function sessPingServer(){
}

function sessLogOut(destroySession,){
	// destroySession()
	// localStorage.removeItem("authentication_token");
	// sessionStorage.removeItem("authentication_token");
	// window.location.href = "/"
}

