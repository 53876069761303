// Dependencies
import React from 'react';

const Home = ({ width, height, color }) => {

    return (

        <svg 
            width={ width ? width : '20px'}
            height={ height ? height : '20px'}
            viewBox="0 0 20 20" 
        >

            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M11.8539195,19.8994949 L11.8539195,14.8994949 L7.85391949,14.8994949 L7.85391949,19.8994949 L3.85391949,19.8994949 C3.30163474,19.8994949 2.85391949,19.4517797 2.85391949,18.8994949 L2.85391949,10.8994949 C2.85391949,10.3472102 3.30163474,9.89949494 3.85391949,9.89949494 L15.8539195,9.89949494 C16.4062042,9.89949494 16.8539195,10.3472102 16.8539195,10.8994949 L16.8539195,18.8994949 C16.8539195,19.4517797 16.4062042,19.8994949 15.8539195,19.8994949 L11.8539195,19.8994949 Z" id="Combined-Shape" fill={ color ? color : "#80abba" } fillRule="nonzero"></path>
                <path d="M17.5287996,5.59325298 L17.5287996,17.5287996 C17.5287996,18.0810843 17.0810843,18.5287996 16.5287996,18.5287996 L4.59325298,18.5287996 C4.04096823,18.5287996 3.59325298,18.0810843 3.59325298,17.5287996 C3.59325298,17.2635831 3.69860982,17.0092292 3.8861462,16.8216928 L15.8216928,4.8861462 C16.2122171,4.49562191 16.8453821,4.49562191 17.2359063,4.8861462 C17.4234427,5.07368258 17.5287996,5.32803649 17.5287996,5.59325298 Z" id="RectanGle" fill={ color ? color : "#80abba" } fillRule="nonzero" transform="translate(9.853919, 10.853919) rotate(-135.000000) translate(-9.853919, -10.853919) "></path>
            </g>
        </svg>
    );

}

export default Home;
