import React from "react";

import LinkBlock from "./linkBlock";
import SelectMultipleInviteds from "./selectMultipleInviteds";
import Icon from "./icons";

class AlertInviteds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };

  }

  handleInvitedOpen () {
    this.setState({show: !this.state.show})
  }

  _getInvitedTitle(inviteds){
    if(inviteds.length == 1) {return this.props.language === "es-ES" ? "Usuario Principal" : "Main User"}
    return this.props.language === "es-ES" ? "Invitados" : "Guests"

  }
  render() {
    let inviteds_to_string = []
    if(this.props.formData["inviteds"]){
      inviteds_to_string = this.props.formData["inviteds"].map(number => number.toString()); 
    }
     
    let mirrors = this.props.element.options.filter(e => e.id !== "" && e.key === "mirror")
    let inviteds = this.props.element.options.filter(e => e.id !== "" && e.key === "invited")
    return (
      <div className={this.state.show ? "c-invited__selector is-active" : "c-invited__selector" }>   

        <div className="c-invited__selector-label" onClick={() => this.handleInvitedOpen()}>
          {this._getInvitedTitle(inviteds)}
          <div className={this.state.show ? "c-invited__selector-label-chevron rotate" : "c-invited__selector-label-chevron"}>
            <Icon
              className={"icon"}
              noWrapper={true}
              name="SingleArrow"
              orientation="down"
              width="20px"
              height="20px"
            />
          </div>
        </div>

        <div className="c-invited__selector-content">
          { 
            inviteds.map((option, index) => {
              let checked = this.props.formData["inviteds"] ? inviteds_to_string.includes(""+option.id) : this.props.element.value.includes(option.id)
              return (
                <LinkBlock
                  key={"linkinviteds_"+option.id}
                  id={"linkinviteds_"+option.id}
                  className={"wrapper-link-block__inline"}
                  balance={false}
                  date={""}
                  description={option.value}
                  action={() => this.props.setCheckboxesInviteds(option.id) }
                  checkBox={true}
                  editable={true}
                  isChecked={checked}
                  // 
                />
              )
            })
          }

          {
            mirrors.length > 0 && <div style={{marginBottom: 5, marginTop: 20, marginLeft: 16}}><label style={{color: "#005774"}}>{this.props.language === "es-ES" ? "Equipo Bulltick" : "Bulltick Team"}</label></div>
          }
          { 
            mirrors.map((option, index) => {
              let checked = this.props.formData["inviteds"] ? inviteds_to_string.includes(""+option.id) : this.props.element.value.includes(option.id)
              return (
                <LinkBlock
                  key={"linkinviteds_"+option.id}
                  id={"linkinviteds_"+option.id}
                  className={"wrapper-link-block__inline"}
                  balance={false}
                  date={""}
                  description={option.value}
                  action={() => this.props.setCheckboxesInviteds(option.id) }
                  checkBox={true}
                  editable={true}
                  isChecked={checked}
                  // 
                />
              )
              })
          }
        </div>




      {/* <SelectMultipleInviteds
          key={"select_inviteds"}
          index={87}
          colorGrey={true}
          setRef={(ref) => {
            
          }}
          element={this.props.element}
          setCheckboxesInviteds={(id) => this.props.setCheckboxesInviteds(id) }
          defaultValue={"select"}
          label={false}
          options={this.props.element.options}
          multiple={true}
          openOptions={() => {}}
          formData={this.props.formData}
          datesToEdit={[]}
          getOptions={(items) =>
              console.log(items)
          }
          disabled={false}
          disabledOptions={[]}
          showErrorLabel={
           ""
          }
          showErrorLabelNow={
            ""
          }
          errorLabel={""}
          hasTooltip={false}
          helperTooltip={[]}
          showHint={ false}
          hint={""}
          specialHint={""}
        /> */}


      </div> 
    ) 

  }  
}

export default AlertInviteds;

