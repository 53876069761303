// Dependencies
import React from "react";
import Lottie from 'react-lottie-player'

import lottieJson from "../assets/json/loading.json";

const Loading = (props) => {

  return (

    <div 
      style={props.style}
      className={props.simple ? "wrapper-loading--simple": "wrapper-loading"}>

      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: props.width ? props.width : 150, height: props.height ? props.height : 150 }}
      />

    </div>
  );
};

export default Loading;
