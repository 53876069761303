// Dependencies
import React from "react";

// Components
import Icon from "./icons/";


class Advertisement extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        return (
            <div class="wrapper-advertisement">
                {
                    this.props.textTop &&
                        <p className="headingH2">{this.props.textTop}</p>
                }
                <Icon name={this.props.icon} noWrapper={true} className="advertisement__icon" color={this.props.iconColor}/>
                {
                    this.props.textBottom &&
                        <p className="headingH2">{this.props.textBottom}</p>
                }
            </div>
        );
    }
}

export default Advertisement;
