import { HOST_URL } from "../constants";

import get_header from "./api_header";
import {parseHeaders} from './api_header'

export function setNotifications(notifications, page) {
  return {
    type: "SET_NOTIFICATIONS",
    notifications,
    page
  };
}

export function successgetInputsNotification(form) {
  return {
    type: "FORM_NOTIFICATION",
    form,
    
  };
}

export function getNotification(authentication_token, page, url, success, error) {
  
  return dispatch => {
    return fetch(HOST_URL + "api/v1/activities?page=" + page + url, {
      method: "GET",
      headers: get_header(authentication_token)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            dispatch(setNotifications(responseJson, page));
            success(responseJson);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function getInputsNotification(authentication_token, url , success, error) {
  
  return dispatch => {
    return fetch(HOST_URL + "api/v1/activities", {
      method: "GET",
      headers: get_header(authentication_token)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            
            dispatch(successgetInputsNotification(responseJson.form));
            success(responseJson.form);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}


