// Dependencies
import React from "react";

// Components
import Icon from "./icons";

const DetailContentBlock = ({
  title,
  withIcons,
  children,
  editAction,
  saveAction,
  style,
  button,
  buttonText,
  buttonAction,
  paddingBottomSixteen
}) => {

  const classContentDetailContentBlock = [
    "content-detailContentBlock",
    paddingBottomSixteen && "o-padding o-padding--bottom-sixteen",
  ]
    .filter((elem) => elem !== false)
    .join(" ");


  return (
    <div className="wrapper-detailContentBlock" style={style && style}>
      <div className="header-detailContentBlock">
        {title !== undefined && (
          <span className="title1 blueSoft">{title}</span>
        )}
        {withIcons && (
          <div className="flex">
            <button onClick={() => editAction()}>
              <Icon name="Pencil" />
            </button>
            <button onClick={() => saveAction()}>
              <Icon name="Save" />
            </button>
          </div>
        )}
      </div>
      <div className={classContentDetailContentBlock}>{children}</div>

      {button && (
        <button onClick={() => buttonAction()} className="btn primary">
          <span className="btn-text"> {buttonText} </span>
        </button>
      )}
    </div>
  );
};

export default DetailContentBlock;
