export function navigateTo(item, props) {
  if(item.url === undefined){
    return
  }
  item.id === undefined
    ? props.history.push("/balance-show?url=" + item.url, {
        url: item.url
      })
    : props.history.push("/balance-detail?url=" + item.url + "&id=" + item.id, {
        url: item.url,
        id: item.id
      });
}
