import {API_TOKEN, APP_VERSION, PASSWORD_CHANGE_REQUIRED, UNAUTHORIZED_REQUEST} from '../constants';

import {getHoursToUTC} from '../helpers/getLocale'

export function setPasswordChangeRequired(password_change_required) {
  return {
      type: 'PASSWORD_CHANGE_REQUIRED',
      password_change_required
  }
}

export function setUnauthorized(unauthorized_request) {
  return {
      type: UNAUTHORIZED_REQUEST,
      unauthorized_request
  }
}

export default function get_header(token){
	const headers = {
    'Content-Type': 'application/json',
    'API-TOKEN': API_TOKEN,
    'AUTH-TOKEN': token,
    "APP-VERSION": APP_VERSION,
    "APP-LOCALE": (/^es/).test(navigator.language || navigator.userLanguage || 'en') ? 'es' : 'en',
    "Access-Control-Expose-Headers": 'password_expired',
    'Access-Control-Allow-Origin': '*',
    "PLATFORM": "web",
    "UTC-HOURS": getHoursToUTC()
  }
  return headers
}

export function parseHeaders(response, dispatch) {
  const password_expired = response.headers.get("password_expired")

  if (password_expired === 'true') {
  	dispatch(setPasswordChangeRequired(password_expired))
  } 
  if (response.status === 401){
    dispatch(setUnauthorized(true))
  }
  return response;
}
