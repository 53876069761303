// Dependencies
import React from 'react';

const Balance = ({ width, height, colorPrimary, colorSecondary }) => {

  return (

    <svg
      width={width ? width : '51px'}
      height={height ? height : '51px'}
      viewBox="0 0 51 51"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons-/-Section-/-Balance" transform="translate(-5.000000, -4.000000)" fillRule="nonzero" strokeWidth="1.5">
          <path d="M28,32 C28,46.6666667 28,54 28,54 C40.1502645,54 50,44.1502645 50,32 C50,19.8497355 40.1502645,10 28,10 C15.8497355,10 6,19.8497355 6,32 C6,32 13.3333333,32 28,32 Z" id="Oval-2" stroke={colorPrimary ? colorPrimary : "#005774"} transform="translate(28.000000, 32.000000) rotate(-180.000000) translate(-28.000000, -32.000000) "></path>
          <path d="M55,27 C55,14.8497355 45.1502645,5 33,5 C33,5 33,12.3333333 33,27 C47.6666667,27 55,27 55,27 Z" id="Oval-2-Copy" stroke={colorSecondary ? colorSecondary : "#f3b01c"} strokeLinecap="square"></path>
        </g>
      </g>
    </svg>

  );

}

export default Balance;
