// Dependencies
import React from "react";

// Components
import MediaBlock from "./mediaBlock";
import RenderDocIcon from "../helpers/renderDocIcon";
import { avatars } from "../assets/resources";
import Icon from "../components/icons";

//helper
import { highlightWord } from "../helpers/highlightWord";

const SectionItem = (props) => {
  const _getImage = (item) => {
    if (
      item.avatar_default_id !== null &&
      item.avatar_default_id !== undefined
    ) {
      return avatars[item.avatar_default_id].default;
    } else if (item.url !== null) {
      return item.url;
    }
  };
  return (
    <>
      {props.documentSection === "contact" && (
        <div className="wrapper-item">
          {props.avatar !== undefined && (
            <div className="wrapper-avatar little">
              <img
                className="avatar"
                src={_getImage(props.avatar)}
                alt="avatar"
              />
            </div>
          )}
          <div className="section-item-texts">
            {props.title !== undefined &&  
                <p className="title">
                  {props.isSearch ? 
                    highlightWord(props.title, props.isSearch) :
                    props.title
                  }
                </p>
            }
            {props.description !== undefined && 
              <p>
                {props.isSearch ? 
                    highlightWord(props.description, props.isSearch) :
                    props.description
                  }
              </p>
            }
            {props.rightText !== undefined && <p>{props.rightText}</p>}
            {props.documentType !== undefined && <p>{props.documentType}</p>}
          </div>
          {props.checked && props.checked != null && (
            <div className="checked">{props.checked}</div>
          )}
        </div>
      )}

      {props.documentSection === "assignDocument" && (
        <>
          <RenderDocIcon type={props.documentType} />
          <div className="section-item-texts">
            {props.title !== undefined && (
              <p className="title">
                {props.isSearch ? 
                    highlightWord(props.title, props.isSearch) :
                    props.title
                  }
              </p>
            )}
          </div>
          {props.checked && props.checked != null && (
            <div className="checked">{props.checked}</div>
          )}
        </>
      )}

      {props.documentSection === "tenant" && (
        <>
          <Icon name="Tenant" />
          <div className="section-item-texts">
            {props.title !== undefined && (
              <p className="title">
                {props.isSearch ? 
                    highlightWord(props.title, props.isSearch) :
                    props.title
                  }
              </p>
            )}
          </div>
          {props.checked && props.checked != null && (
            <div className="checked">{props.checked}</div>
          )}
        </>
      )}


      {props.documentSection === "trust" && (
        <>
          <Icon name="Trust" />
          <div className="section-item-texts">
            {props.title !== undefined && (
              <p className="title">
                {props.isSearch ? 
                    highlightWord(props.title, props.isSearch) :
                    props.title
                  }
              </p>
            )}
          </div>
          {props.checked && props.checked != null && (
            <div className="checked">{props.checked}</div>
          )}
        </>
      )}
    {props.documentSection === "account" && (
        <>
          {props.checked && props.checked != null && (
            <div className="checked">{props.checked}</div>
          )}
        </>
      )}      
      {props.documentSection === "photo" && (
        <MediaBlock
          fullWidth={true}
          image={<img className="avatar" src={props.avatar} alt="avatar" />}
          title={props.title}
          rightText={props.rightText}
          documentType={props.documentType}
          alignLeft={true}
          isSearch={props.isSearch}
        />
      )}

      {props.documentSection !== "assignDocument" &&
        props.documentSection === "document" && (
          <MediaBlock
            fullWidth={true}
            image={<RenderDocIcon type={props.documentType} />}
            title={props.title}
            description={props.description}
            rightText={props.rightText}
            documentType={props.documentType}
            alignLeft={true}
            isSearch={props.isSearch}
          />
        )}

      {props.documentSection !== "contact" &&
        props.documentSection !== "tenant" &&
        props.documentSection !== "trust" &&
        props.documentSection !== "photo" &&
        props.documentSection !== "assignDocument" && (
          <MediaBlock
            date={props.date}
            fullWidth={true}
            image={<RenderDocIcon type={props.documentType} />}
            title={props.title}
            description={props.description}
            rightText={props.rightText}
            documentType={props.documentType}
            deleteItem={props.deleteItem}
            deleteIconAction={
              props.deleteItem ? (e) => props.deleteIconAction(e) : ""
            }
            alignLeft={true}
            isSearch={props.isSearch}
          />
        )}
    </>
  );
};

export default SectionItem;
