// Dependencies
import React from "react";
import { connect } from "react-redux";
import {internalization} from '../../constants.js'
import classNames from "classnames"

// Actions
import { createSession, autoLogin } from "../../actions/session";
import { resendSMSRecovery, recoveryAccount, getSecurityQuestions } from "../../actions/recovery";
import { validateAttribute } from '../../actions/validate_attribute';

// Helpers
import AutoLogin from "../../helpers/autoLogin";

// Components
import Input from "../../components/input";
import Icon from "../../components/icons";
import AlertModal from "../../components/alertModal";
import SuccessRecovery from "../../components/successRecovery";
import SelectMultiple from "../../components/selectMultiple";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

var languageUserAgent = (/^es/).test(navigator.language || navigator.userLanguage || 'en') ? 'es' : 'en'; 
// const languageUserAgent = 'en'; 

class Recovery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: true,
      pin: "",
      password: "",
      code: "", 
      code_error: "",
      security_questions: this.props.location.state && this.props.location.state.security_questions ? this.props.location.state.security_questions : {},
      form_errors: {} ,
      question_1_code: this.props.location.state && this.props.location.state.security_questions ? Object.keys(this.props.location.state.security_questions.pool_1)[0] : "",
      question_1_answer: "",
      question_2_code: this.props.location.state && this.props.location.state.security_questions ?  Object.keys(this.props.location.state.security_questions.pool_2)[0] : "",
      question_1_answer: "",
      successSentEmail: false,
      recovery_step: "",
      password: "",
      type: this.props.location.state && this.props.location.state.type ? this.props.location.state.type : "",
      password_confirmation: "",
      user_id: this.props.location.state && this.props.location.state.user_id ? this.props.location.state.user_id : "",
      keyInput: [],
      slashed: false,
      submitting: false,
      successSentEmailSubtitle: "",
      step: 1,
      phone_number: "",
      recovery_step_intermediate: 0,
      checksPasswordItems: [],
      modal_title: "",
      modal_body: "",
      modal_action: "",
      modal_button: "",
      showSMSModal: false,
      showOldCodeMismatch: false
    };
  }

  
  componentDidMount(){

    let params = this.props.location.search.split("token=")
    if(params.length >1){
    
      this.props.getSecurityQuestions(params[1], (security_questions, user_language) => this._successGetSecurityQuestions(security_questions, params[1], user_language), () => alert("error"))
    }
    
    if(this.props.location && this.props.location.state && this.props.location.state.language !== undefined){
      languageUserAgent = (/^es/).test(this.props.location.state.language) ? 'es' : 'en'; 
      this.setState({showLoading: false})
    }
    document.addEventListener("click", this.handleClickOutside);
  }

  handleClickOutside(e) {

    let allMultiSelects = document.querySelectorAll('.options-multiple-select');
    let containerMultiSelects = document.querySelectorAll('.container-multiple-select')
    let multiSelectIcon = document.querySelectorAll('.icons-multiple-select')

    for (let i = 0; i < allMultiSelects.length; i++) {
      if (allMultiSelects.length > 0 && multiSelectIcon.length > 0 && containerMultiSelects.length > 0 && containerMultiSelects && !containerMultiSelects[i].contains(e.target)) {

        containerMultiSelects[i].classList.remove('open')
        multiSelectIcon[i] && multiSelectIcon[i].classList.remove('rotate')
        allMultiSelects[i].style.height = 0

        setTimeout(function () { containerMultiSelects[i].style.zIndex = 0 }, 500);

        allMultiSelects[i].classList.remove('open')

      }
    }
      
  }
  
  _successGetSecurityQuestions(security_questions, user_id, user_language){
    languageUserAgent = (/^es/).test(user_language) ? 'es' : 'en'; 
    this.setState({
        security_questions: security_questions, 
        question_1_code: Object.keys(security_questions["pool_1"])[0],
        question_2_code: Object.keys(security_questions["pool_2"])[0],
        user_id: user_id,
        type: 'locked'
    })
  }

  onChangeInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _success() {
    window.location.replace("/home");
  }

  _errorRecovery(responseJSON, state){
   
    let error_object = {};
    if(responseJSON.errors && responseJSON.errors.includes(state.question_1_code)){
      error_object["question_1_answer_error"] = internalization[languageUserAgent].errors.mismatch
    }
    if(responseJSON.errors && responseJSON.errors.includes(state.question_2_code)){
      error_object["question_2_answer_error"] = internalization[languageUserAgent].errors.mismatch
    } 
    if(responseJSON.errors === undefined || responseJSON.errors.length === 0){
      this._error()
    }    
    this.setState({submitting:false, form_errors: error_object})
    
  }

	_validateForm(state){
    let error_object = {};
    !state.password || state.password.length === 0 ? error_object['password_error'] = internalization[languageUserAgent].errors.blank : (delete error_object['password_error'])
    !state.phone_number || state.phone_number.length === 0 ? error_object['phone_number_error'] = internalization[languageUserAgent].errors.blank  : (delete error_object['phone_number_error'])
    if(Object.keys(error_object).length === 0){
      var phoneno =  /^\+?.{8,70}$/;
      if(!phoneno.test(state.phone_number)){
        error_object["phone_number_error"] = "Not a valid phone"
      }
    }
    if(Object.keys(error_object).length === 0){
      this.setState({form_errors: {}})
      return true
    }
    else{
      this.setState({form_errors: error_object})
      return false
    }
  } 

  _submit(state){
    if(this._validateForm(state) && !state.submitting){
      this.setState({submitting: true})
      this.props.recoveryAccount(this.props.authentication_token, 'email', {'password': state.password, 'phone_number': state.phone_number}, (responseJSON) => this._emailSent(), (responseJSON) => {this._errorRecovery(responseJSON, state)})
    }
  }

_validateStep1(state){
    let error_object = {};
    !state.pin || state.pin.length === 0 ? error_object['pin_error'] = internalization[languageUserAgent].errors.blank : (delete error_object['pin_error'])
    !state.phone_number || state.phone_number.length === 0 ? error_object['phone_number_error'] = internalization[languageUserAgent].errors.blank  : (delete error_object['phone_number_error'])
    if(Object.keys(error_object).length === 0){
      var phoneno =  /^\+?.{8,70}$/;
      if(!phoneno.test(state.phone_number)){
        error_object["phone_number_error"] = "Not a valid phone"
      }
    }
  
    if(error_object["pin_error"] === undefined){
      if(state.pin.length < 6){
        error_object["pin_error"] = internalization[languageUserAgent].errors.equal_to.replace('%{count}',6) 
      }
    }
    if(Object.keys(error_object).length === 0){
      this.setState({form_errors: {}})
      return true
    }
    else{
      this.setState({form_errors: error_object})
      return false
    }

  }
  _submitNextStep(state){
    if(this._validateStep1(state) && !state.submitting){
      this.setState({submitting: true})
      this.props.recoveryAccount(this.props.authentication_token, 'security_questions', {'pin': state.pin, 'phone_number': state.phone_number}, 
        (responseJSON) => {
          languageUserAgent = (/^es/).test(responseJSON.language) ? 'es' : 'en';
          this.setState({submitting: false, user_id: responseJSON.user_id, step: 2, security_questions: responseJSON.security_questions, question_1_code: Object.keys(responseJSON.security_questions["pool_1"])[0], question_2_code: Object.keys(responseJSON.security_questions["pool_2"])[0]})
        }, 
        (responseJSON) => {this._errorRecovery(responseJSON, state);
      })
    }
  }
  _submitSecurityQuestions(state){
   if(this._validateSecurityQuestions(state) && !state.submitting){
      this.setState({submitting: true})
      let type = state.type
      let prepare_hash = {}
      prepare_hash = {
        security_questions: [{question_code: state.question_1_code, answer: state.question_1_answer},
        {question_code: state.question_2_code, answer: state.question_2_answer}],
        user_id: state.user_id
        }
    
      this.props.recoveryAccount(this.props.authentication_token, 'check_security_questions', prepare_hash, (responseJSON) => state.type == "locked" ? this.setState({phone_number: responseJSON.phone_number, submitting:false, recovery_step: 1}) : this._emailSent(), (responseJSON) => {this._errorRecovery(responseJSON, state)})
      
      // this.props.updateUser(this.props.authentication_token, {security_questions: prepare_hash, pin: state.pin, user: {phone_number: state.phone_number}}, () => this._success(), () => alert("Error mismatch"))
    } 
  }

  _validateSecurityQuestions(state){
    let error_object = {};
    
    !state.hasOwnProperty('question_1_code') ? error_object['question_1_code_error'] = internalization[languageUserAgent].errors.blank : (delete error_object['question_1_code_error'])
    !state.hasOwnProperty('question_2_code') ? error_object['question_2_code_error'] = internalization[languageUserAgent].errors.blank : (delete error_object['question_2_code_error'])

    !state.hasOwnProperty('question_1_answer') || state.question_1_answer.length === 0  ? error_object['question_1_answer_error'] = internalization[languageUserAgent].errors.blank : (delete error_object['question_1_answer_error'])
    !state.hasOwnProperty('question_2_answer') || state.question_2_answer.length === 0  ? error_object['question_2_answer_error'] = internalization[languageUserAgent].errors.blank   : (delete error_object['question_2_answer_error'])


      
    if(Object.keys(error_object).length === 0){
      this.setState({form_errors: {}})
      return true
    }
    else{
      this.setState({form_errors: error_object})
      return false
    }

    
  }

  _sendCode(state){
    let error_object = {};
    state.code.length === 0 ? error_object['code_error'] = internalization[languageUserAgent].errors.blank : (delete error_object['code_error'])

    if(Object.keys(error_object).length === 0 && !state.submitting){

      this.setState({submitting: true, form_errors: {}})
      this.props.recoveryAccount(
        this.props.authentication_token,
        "code",
        { code: state.code },
        (responseJSON) =>
          this.setState({
            submitting: false,
            recovery_step: 2,
            user_id: responseJSON.user_id,
          }),
        (responseJSON) => {
          this.setState({ 
            submitting: false ,
            showOldCodeMismatch: true
          });
        }
      );
      //send code and next step this.setState({recovery_step: 2})
    }
    else{
      this.setState({form_errors: error_object})
    }
  }

  _changePassword(state){
    if(this._validateChangePassword(state)){
    }
  }

  _validateChangePassword(state){
    let error_object = {} 

    state.password.length === 0 ? error_object['password_error'] = internalization[languageUserAgent].errors.blank : (delete error_object['password_error'])
    state.password_confirmation.length === 0 ? error_object['password_confirmation_error'] = internalization[languageUserAgent].errors.blank : (delete error_object['password_confirmation_error'])

    if(state.password.length > 0 && error_object['password_error'] === undefined){
      error_object = this._validatePassword(state, error_object)
    }
    if(error_object["password_error"] === undefined){
      state.password !== state.password_confirmation ? error_object['password_confirmation_error'] = internalization[languageUserAgent].errors.no_match  : (delete error_object['password_confirmation_error'])
    }
   
    if(Object.keys(error_object).length === 0){
      this.setState({submitting: true, form_errors: {}})
      this.props.recoveryAccount(this.props.authentication_token, 'change_password', {'user_id': state.user_id, 'password': state.password}, (responseJSON) => this.setState({submitting:false, recovery_step: 3}), (responseJSON) => {this.setState({submitting: false});alert("Old code mismatch")} )

    }
    else{
      this.setState({submitting: false, form_errors: error_object})
      return false
    }    
  }

  _testRegexp(text){
    let reg = new RegExp(/^(?!.*([A-Za-z0-9])\1{3})(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[@#_*()+{}\/?~:,.-]).{8,70}$/)
    return reg.test(text)
  }

  _validatePassword(state, error_object){
    if(!this._testRegexp(state.password)){
      error_object['password_error'] = internalization[languageUserAgent].errors.password_requirement
    }   
    return error_object
  }
  _emailSent(title, subtitle){
  	this.setState({
      submitting:false,
      showModal: true,
      modal_title: this.state.type === "email" ? internalization[languageUserAgent].alerts.email_sent : internalization[languageUserAgent].alerts.forgot_email_password,
      modal_body: this.state.type === "email" ? `${internalization[languageUserAgent].alerts.forgot_email} \n\n ${internalization[languageUserAgent].alerts.password_forgot_subtitle_check}` : internalization[languageUserAgent].alerts.forgot_email_password_subtitle,
      modal_action: () => this.props.history.push("/"),
      modal_button: internalization[languageUserAgent].ok
    })
  }
  _error(){
    this.setState({
      submitting:false,
      showModal: true,
      modal_title: this.state.type === "email" ? internalization[languageUserAgent].alerts.forgot_email_error : internalization[languageUserAgent].alerts.forgot_email_password_error,
      modal_body: this.state.type === "email" ? internalization[languageUserAgent].alerts.forgot_email_subtitle_error : internalization[languageUserAgent].alerts.forgot_email_password_subtitle_error,
      modal_action: () => this.setState({showModal:false}),
      modal_button: internalization[languageUserAgent].try_again
    })
  }

   _checksPassword(text, passwordTexts) {
    let checkeds = []

    let regLength = new RegExp(/[A-Za-z0-9@#_*()+{}\/?~:,.-].{8,70}$/)
    let regUppercase = new RegExp(/^(?=.*?[A-Z])/)
    let regLowercase = new RegExp(/^(?=.*?[a-z])/)
    let regDigit = new RegExp(/^(?=.*?[0-9])/)
    let regSpecial = new RegExp(/[@#_*()+{}\/?~:,.-]/)
    let regRepeated = new RegExp(/^(?!.*([A-Za-z0-9])\1{2})/)

    if(regLength.test(text)) checkeds.push(passwordTexts[0])
    if(regUppercase.test(text) && regLowercase.test(text) && regDigit.test(text) && regSpecial.test(text)) checkeds.push(passwordTexts[1])
    if(regSpecial.test(text)) checkeds.push(passwordTexts[2])
    if(regRepeated.test(text) && text.length > 0) checkeds.push(passwordTexts[3])   

    return checkeds;
  }  


  _disableOfuscated(e, key, state){
    e.stopPropagation()
    e.preventDefault()

		let keys = state.keyInput
		if(!keys.includes(key)){
			keys.push(key)
		}else {
      keys = keys.filter(i => i !== key)
    }
		this.setState({
			keyInput: keys,
		})
	}

  _reSendCode(state, next_step){
    this.props.resendSMSRecovery(
      this.props.authentication_token,
      { user_id: state.user_id, code_type: "locked" },
      () => {
        next_step != -1
          ? this.setState({ recovery_step_intermediate: 1 })
          : this.setState({ showSMSModal: true })
      },
      () => alert("Error")
    );
  }

  _passwords_match() {
    return this.state.password_confirmation !== "" ? this.state.password === this.state.password_confirmation : undefined ;
  }

  _showModal(error){
// modal_title
// modal_body
// modal_action
// modal_button    
  }

  _getQuestion(obj){
    for (let key in obj) {
      return obj[key]
    }
  }

  render() {
   
    let wrapperClasses = classNames(
      "signIn-wrapper", 
      this.state.successSentEmail || this.state.recovery_step === 3 && "recovery"
    )

    return (
      <div className={wrapperClasses}>

        { this.state.showSMSModal && (
          <AlertModal
            isShowed={this.state.showSMSModal}
            title={"Error"}
            msg={`SMS ${internalization[languageUserAgent].resent}`}
            action={() => { this.setState({ showSmsModal: false }) }}
            textButton="Ok"
          />
        )}

        {this.state.showOldCodeMismatch && (
          <AlertModal
            isShowed={this.state.showOldCodeMismatch}
            title={"Error"}
            msg={internalization[languageUserAgent].old_code_mismatch}
            action={() => { this.setState({ showOldCodeMismatch: false }) }}
            textButton="Ok"
          />
        )}

	      <form className="signIn-content">

          {
            this.state.recovery_step !== 3 && !this.state.successSentEmail &&
              <div className="wrapper-signIn-logo">
                <Icon name="Logo" className="logo-big" width={160} height={52} />
              </div>
          }
	          {
	          	!this.state.successSentEmail && Object.keys(this.state.security_questions).length == 0 && this.state.type === "email_password" &&
	          	<>
              <span className="headingH2 o-text-align--center">{internalization[languageUserAgent].recovery_email_password}</span>
              <p className="headingH4 o-color--grey-dark o-text-align--center" style={{marginBottom: 12}}>{internalization[languageUserAgent].recovery_email_password_subtitle}</p>

                <div className="wrapper-form-item">

                  <span className="body small">{internalization[languageUserAgent].phone_number}:</span>

                  <div className="wrapper-input with-icon">
                    <PhoneInput
                      key={"email_phone_number"}
                      placeholder="Enter phone number"
                      value={ this.state.phone_number}
                      onChange={ value => 
                          this.setState({phone_number: value})
                      } />
                    {
                      this.state.form_errors["phone_number_error"] &&
                        <span className="label error">{this.state.form_errors["phone_number_error"]}</span>
                    }
                  </div>

                </div>

                <div className="wrapper-form-item">
                  <span className="body small">{internalization[languageUserAgent].pin}:</span>
                  <Input
                    key={1}
                    onChangeInput={e => /^-?\d*$/.test(e.target.value) ? this.onChangeInput(e) : ""}
                    value={this.state.pin}
                    type={this.state.keyInput.includes(1) ? 'text' : 'password'}
                    name="pin"
                    placeholder="XXXXXX"
                    placeholderTextColor="grey-placeholder"
                    onFocus={true}
                    onBlur={true}
                    withButtonIcon={true}
                    iconName={"Eye"}
                    slashed={this.state.keyInput.includes(1) ? true : false}
                    buttonIconAction={e => this._disableOfuscated(e,1,this.state)}
                  />
                </div>




              <div className="signIn-wrapper-buttons">
                <button
                  type="submit"
                  className="btn primary"
                  onClick={e => {e.preventDefault();this._submitNextStep(this.state)}}
                >
                  <span className="btn-text">{internalization[languageUserAgent].next}</span>
                </button>
              </div> 
            	</>  
	          }
	          {
	          	!this.state.successSentEmail && Object.keys(this.state.security_questions).length == 0 && this.state.type === "email" &&
	          	<>
              <span className="headingH2 o-text-align--center">{internalization[languageUserAgent].recovery_email}</span>
                <p className="headingH4 o-color--grey-dark o-text-align--center" style={{marginBottom: 12}}>{internalization[languageUserAgent].recovery_email_subtitle}</p>

                <div className="wrapper-form-item">
                  <span className="body small">{internalization[languageUserAgent].phone_number}:</span>

                  <div className="wrapper-input with-icon">
                    <PhoneInput
                      key={"phone_number"}
                      placeholder="Enter phone number"
                      value={ this.state.phone_number}
                      onChange={ value => 
                          this.setState({phone_number: value})
                      } />
                  </div>
                </div>

                <div className="wrapper-form-item">
                  <span className="body small">{internalization[languageUserAgent].password}:</span>
                  <Input
                    key={2}
                    onChangeInput={e => this.onChangeInput(e)}
                    value={this.state.password}
                    type={this.state.keyInput.includes(2) ? 'text' : 'password'}
                    name="password"
                    placeholder="XXXXXX"
                    placeholderTextColor="grey-placeholder"
                    onFocus={true}
                    onBlur={true}
                    withButtonIcon={true}
                    iconName={"Eye"}
                    slashed={this.state.keyInput.includes(2) ? true : false}
                    buttonIconAction={e => this._disableOfuscated(e,2,this.state)}
                  />
                </div>              

              <div className="signIn-wrapper-buttons">
                <button
                  type="submit"
                  className="btn primary"
                  onClick={e => {e.preventDefault();this._submit(this.state)}}
                >
                  <span className="btn-text">{internalization[languageUserAgent].next}</span>
                </button>
              </div> 
            	</>  
	          }
	          {
	          	this.state.recovery_step == "" && !this.state.successSentEmail && Object.keys(this.state.security_questions).length > 0 && 
	          	<>
              <p className="headingH2 o-text-align--center" style={{textAlign: 'center', marginTop: '40px'}}>{internalization[languageUserAgent].recovery_email_password_questions}</p>
              <p className="headingH4 o-color--grey-dark o-text-align--center" style={{textAlign: 'center'}}>{internalization[languageUserAgent].recovery_email_password_subtitle_questions}</p>
              <div style={{marginTop: 20}}>
  	          	<div className="wrapper-form-item" style={{height: "auto"}}>
  				        <span className="input-label">{internalization[languageUserAgent].security_question}</span>
                  <p className="body grey-dark bold">{this._getQuestion(this.state.security_questions.pool_1)}</p>
  			      	</div>
                
  			      	<div className="wrapper-form-item" >
  							<span className="input-label">{internalization[languageUserAgent].answer}</span>
  				        <Input
  				          name={"question_1_answer"}
  				          type={'text'}
  				          defaultValue={""}
  				          onChangeInput={e => this.setState({question_1_answer:e.target.value})}
  				          // onFocusInput={e => _focusedInput(e, element)}
  				          // onBlurInput={e => _bluredInput(e, element)}
  				          onFocus={() => {}}
  				          onBlur={() => {}}
  				          errorLabel={this.state.form_errors["question_1_answer_error"]}
  				          showErrorLabelNow={this.state.form_errors["question_1_answer_error"]}
  		              showErrorLabel={Object.keys(this.state.form_errors).includes("question_1_answer_error")}
  				        />
  				      </div>
  				      <div className="wrapper-form-item" style={{height: "auto"}}>
  							<span className="input-label">{internalization[languageUserAgent].security_question}</span>

                      <p className="body grey-dark bold">{this._getQuestion(this.state.security_questions.pool_2)}</p>
  			      	</div>	
  			      	<div className="wrapper-form-item">
  							<span className="input-label">{internalization[languageUserAgent].answer}</span>
  				        <Input
  				          name={"question_2_answer"}
  				          type={'text'}
  				          defaultValue={""}
  				          onChangeInput={e => this.setState({question_2_answer:e.target.value})}
  				          // onFocusInput={e => _focusedInput(e, element)}
  				          // onBlurInput={e => _bluredInput(e, element)}
  				          onFocus={() => {}}
  				          onBlur={() => {}}
  				          errorLabel={this.state.form_errors["question_2_answer_error"]}
  				          showErrorLabelNow={this.state.form_errors["question_2_answer_error"]}
  		              showErrorLabel={Object.keys(this.state.form_errors).includes("question_2_answer_error")}
  				        />	
  				      </div>
  	          	<div className="signIn-wrapper-buttons">
                  <button
                    type="submit"
                    className="btn primary"
                    onClick={e => {e.preventDefault();this._submitSecurityQuestions(this.state)}}
                  >
                    <span className="btn-text">{internalization[languageUserAgent].next}</span>
                  </button>
                </div> 
              </div>
	          	</>
	          }
            {
              this.state.recovery_step === 2 &&
              <>
              <span className="headingH2">{internalization[languageUserAgent].account_blocked_code_title}</span>
              <div style={{marginTop: 20}}>
                <div className="wrapper-form-item has-requirementOptions">
                <span className="input-label">{internalization[languageUserAgent].new_password}</span>
                <Input
                  name={'password'}
                  type={this.state.keyInput.includes(1) ? 'text' : 'password'}
                  defaultValue={""}
                  onChangeInput={e => this.setState({checksPasswordItems: this._checksPassword(e.target.value, internalization[languageUserAgent].password_requirements_texts), password:e.target.value})}
                  // onFocusInput={e => _focusedInput(e, element)}
                  // onBlurInput={e => _bluredInput(e, element)}
                  onFocus={() => {}}
                  onBlur={() => {}}
                  withButtonIcon={true}
                  errorLabel={this.state.form_errors['password_error']}
                  showErrorLabelNow={this.state.form_errors['password_error']}
                  showErrorLabel={Object.keys(this.state.form_errors).includes('password_error')}
                  iconName={"Eye"}
                  slashed={this.state.keyInput.includes(1) ? true : false}
                  buttonIconAction={e => this._disableOfuscated(e,1,this.state)} 
                  requirementOptions={internalization[languageUserAgent].password_requirements_texts}
                  showRequirementCheck={true} 
                  requirementCheck={this.state.checksPasswordItems}


                />
              </div>
              <div className="wrapper-form-item">
                <span className="input-label">{internalization[languageUserAgent].new_password_confirmation}</span>
                <Input
                  name={'password_confirmation'}
                  type={this.state.keyInput.includes(2) ? 'text' : 'password'}
                  defaultValue={""}
                  onChangeInput={e => this.setState({password_confirmation:e.target.value})}
                  iconName={"Eye"}
                  withButtonIcon={true}
                  slashed={this.state.keyInput.includes(2) ? true : false}
                  buttonIconAction={e => this._disableOfuscated(e,2,this.state)}  
                  onFocus={() => {}}
                  onBlur={() => {}}
                  errorLabel={this.state.form_errors['password_confirmation_error']}
                  showErrorLabelNow={this.state.form_errors['password_confirmation_error']}
                  showErrorLabel={Object.keys(this.state.form_errors).includes('password_confirmation_error')}
                  password_match_text={this._passwords_match() ? internalization[languageUserAgent].password_match : internalization[languageUserAgent].password_not_match}
                  passwords_match={this._passwords_match()}
                  passwords_match_enabled={true}

                />
              </div>
              <div className="signIn-wrapper-buttons">
                  <button
                    type="submit"
                    className="btn primary"
                    onClick={e => {e.preventDefault();this._changePassword(this.state)}}
                  >
                    <span className="btn-text">{internalization[languageUserAgent].next}</span>
                  </button>
                </div>
              </div>
            </>
            }
            {
              this.state.recovery_step === 1 && this.state.recovery_step_intermediate === 0 &&
                  <div>
                    <h2 className="headingH2" style={{textAlign: 'center', marginTop: 30, marginBottom: 20}}>{internalization[languageUserAgent].recovery_code_title}</h2>
                    <h3 style={{textAlign: 'center', marginBottom: 20}}>{internalization[languageUserAgent].recovery_code_info}</h3>
                    <h3 style={{textAlign: 'center', marginBottom: 20}}>{internalization[languageUserAgent].recovery_code_send_info}</h3>
                    <h3 style={{textAlign: 'center', marginBottom: 30}}>{internalization[languageUserAgent].recovery_code_send_confirmation}{this.state.phone_number}.</h3>
                    <button
                        className="btn primary"
                        style={{marginTop: 0}}
                        onClick={(e) => {e.preventDefault();this._reSendCode(this.state, 1)} }
                      >
                        <span className="btn-text">{internalization[languageUserAgent].send_code}</span>
                      </button>
                  </div>
              }
              {
                this.state.recovery_step === 1 && this.state.recovery_step_intermediate === 1 &&
                <>
                  <span className="headingH2 o-text-align--center">{internalization[languageUserAgent].account_blocked_code_title}</span>
                  <span className="headingH4 o-color--grey-dark o-text-align--center">{internalization[languageUserAgent].account_blocked_code_subtititle}{this.state.phone_number}</span>
                  <div style={{marginTop: 20}}>
                    <div className="wrapper-form-item">
                      <span className="input-label">{internalization[languageUserAgent].sms_code}</span>
                      <Input
                        name={"code"}
                        type={'text'}
                        defaultValue={this.state.code}
                        onChangeInput={e => this.setState({code:e.target.value})}
                        autoComplete={"one-time-code"}
                        // onFocusInput={e => _focusedInput(e, element)}
                        // onBlurInput={e => _bluredInput(e, element)}
                        onFocus={() => {}}
                        onBlur={() => {}}
                        errorLabel={this.state.form_errors["code_error"]}
                        showErrorLabelNow={this.state.form_errors["code_error"]}
                        showErrorLabel={Object.keys(this.state.form_errors).includes("code_error")}
                      />
                    </div> 
                    <div style={{ display: 'flex'}}>
                      <button
                          className="btn primary"
                          style={{marginTop: 0}}
                          onClick={(e) => {e.preventDefault();this._reSendCode(this.state, -1)} }
                        >
                          <span className="btn-text">{internalization[languageUserAgent].resend}</span>
                        </button>
                      <button
                          className="btn primary"
                          style={{marginTop: 0}}
                          disabled={
                            Object.keys(this.state.code).length === 0
                          }
                          onClick={e => {e.preventDefault();this._sendCode(this.state)}}
                            // this._sendInvitation(this.state)
                          
                        >
                          <span className="btn-text">{internalization[languageUserAgent].next}</span>
                        </button>
                      </div>
                    </div>
                  </>
              
            }	
            {
              this.state.recovery_step === 3 &&
              <>
              {/* <span className="headingH2">{internalization[languageUserAgent].account_unblocked}</span>
              <div className="signIn-wrapper-buttons">
                <button
                  type="submit"
                  className="btn"
                  onClick={e => {e.preventDefault();this.props.history.push("/")}}
                >
                  <span className="btn-text">{internalization[languageUserAgent].login}</span>
                </button>
              </div> */}
              <SuccessRecovery icon={'Unlock'} buttonText={internalization[languageUserAgent].login} successTextSubtitle={internalization[languageUserAgent].account_unblocked_title} successText={internalization[languageUserAgent].account_unblocked} action={e => {e.preventDefault();this.props.history.push("/")}}/>
              </>        
            }
	      </form>
        {
          this.state.successSentEmail &&
          <SuccessRecovery icon={'Email'} buttonText={"OK"} successTextSubtitle={this.state.successSentEmailSubtitle} successText={internalization[languageUserAgent].alerts.email_sent} action={() => this.props.history.push("/")}/>
        } 
        {
          this.state.recovery_step !== 3 &&
          <button onClick={(e) => {e.preventDefault();this.props.history.push("/")} }>
            <span className="btn-text orangeMedium">
              Login
            </span>
          </button>
        }
        {this.state.showModal &&
          <AlertModal
            isShowed={this.state.showModal}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.setState({
                showSuccesModal: false
              }, () => this.state.modal_action != "" ? this.state.modal_action() : "");
              
            }}
            textButton={this.state.modal_button}
          />
        }

 
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token
  };
}

function mapDispatchToProps(dispatch) {
  return {
      getSecurityQuestions: (token, success, error) => dispatch(getSecurityQuestions(token, success, error)),
     validateAttribute: (authentication_token, type, attributes, success, error) => dispatch(validateAttribute(authentication_token, type, attributes , success, error)),
     recoveryAccount: (authentication_token, type, attributes, success, error) => dispatch(recoveryAccount(authentication_token, type, attributes , success, error)),              	
    resendSMSRecovery:(authentication_token, attributes, success, error) => dispatch(resendSMSRecovery(authentication_token, attributes, success, error)),           
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Recovery);
