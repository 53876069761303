// Dependencies
import React from "react";
import NumberFormat from "react-number-format";
import TableRow from './tableRow'

const TableEditableRow = (props) => {
  
  return props.rows.map((rowsData, index) => {

    if(props.stored && props.new_rows !== true){
      let found = props.stored.filter(element => ""+element.id === ""+rowsData.id || (element.temp_id &&""+element.temp_id === ""+rowsData.temp_id) )
      if(found.length > 0){
        rowsData.row = found[0].row
      }
    }
    

    return (
      <TableRow
        key={`TableRow-${rowsData.id !== "" ? rowsData.id : rowsData.temp_id}-${props.new_rows === true ? "new_row" : "back"}`}
        onBlur={(e) => props.onBlur(e)}
        rowsData={rowsData}
        onChange={(rowsData, i, value, temp_id) => props.onChange(rowsData, i, value, rowsData.temp_id)}
        refInput={props.refInput}
      />
    );
  });
};

export default TableEditableRow;
