// Dependencies
import React from 'react';

const AlertInfo = ({ width, height, color, bgColor }) => {

  return (

    <svg
      width={width ? width : '54px'}
      height={height ? height : '54px'}
      viewBox="0 0 54 54"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons-/-alerts-/-Info" transform="translate(-3.000000, -3.000000)" fillRule="nonzero">
          <circle id="Oval-2" fill={bgColor ? bgColor : "#80abba"} cx="30" cy="30" r="27"></circle>
          <path d="M27.5,15.5 C27.5,13.8431458 28.8431458,12.5 30.5,12.5 C32.1568542,12.5 33.5,13.8431458 33.5,15.5 L33.5,31 C33.5,32.6568542 32.1568542,34 30.5,34 C28.8431458,34 27.5,32.6568542 27.5,31 L27.5,15.5 Z" id="Line-2" fill={color ? color : '#FFFFFF'}></path>
          <path d="M27.25,41.5 C27.25,39.8431458 28.5931458,38.5 30.25,38.5 C31.9068542,38.5 33.25,39.8431458 33.25,41.5 L33.25,43 C33.25,44.6568542 31.9068542,46 30.25,46 C28.5931458,46 27.25,44.6568542 27.25,43 L27.25,41.5 Z" id="Line-2-Copy" fill={color ? color : 'white'}></path>
        </g>
      </g>
    </svg>

  );

}

export default AlertInfo;
