// Dependencies
import React, { useEffect, useState } from "react";

// Components
import Icon from "./icons";
import NoResources from "./noResources";

const ContentBlock = ({
  title,
  icon,
  children,
  help,
  helpAction,
  className,
  button,
  buttonText,
  buttonAction,
  balance,
  header,
  maxHeight,
  contentClass,
  multipleButtons,
  buttonTextSecond,
  buttonActionSecond,
  anchor,
  titleExtraBig,
  style,
  titleStyle,
  date,
  animationButton,
  titleBig,
  isInsurance,
  wrapperStyle
}) => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    let item = document.querySelector(".js-limit-scroll");

    item &&
      item.addEventListener("scroll", (e) => {
        if (e.currentTarget.scrollTop === 30) {
          setAnimation(true);
        } else {
          setAnimation(false);
        }
      });
  }, []);

  const tempContentBlockClass = [className && className]
    .filter((elem) => elem !== false)
    .join(" ");

  return (
    <div
      id={anchor || title}
      className={`wrapper-content-block ${tempContentBlockClass}`}
      onClick={() => isInsurance ? buttonAction() : {}}
      style={wrapperStyle && wrapperStyle}
    >
      {header && (
        <div
          onClick={() => buttonAction && buttonAction()}
          className={`header ${icon !== undefined ? "" : "small"} ${
            buttonAction && "pointer"
          }`}
          style={style && style}
        >
          <div
            className={`wrapper-title ${balance === true ? "start" : "center"}`}
          >
            {icon}
            <span
              className={`
              ${titleBig ? "headingH1" : "headingH2"} 
              ${icon ? "with-icon" : ""} 
              ${titleExtraBig ? "extra-big" : ""}
              `}
              style={titleStyle && titleStyle}
            >
              {title}
            </span>
          </div>
          {help && (
            <button onClick={() => helpAction()}>
              <Icon name="Help" />
            </button>
          )}
        </div>
      )}
      {date && <span className="date">{date}</span>}
      <div
        className={`content ${
          maxHeight === true ? "max-height" : ""
        } ${contentClass}`}
      >
        {children}
      </div>
      {button && (
        <div className="footer multiple-buttons">
          <button
            onClick={() => buttonAction()}
            className={
              animation && animationButton
                ? "btn primary animation-color"
                : "btn primary"
            }
          >
            <span className="btn-text">{buttonText}</span>
          </button>
          {multipleButtons && (
            <button
              onClick={() => buttonActionSecond()}
              className="btn primary"
            >
              <span className="btn-text">{buttonTextSecond}</span>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ContentBlock;
