// Dependencies
import React from 'react';

const Checkbox = ({ width, height, color }) => {

    return (

        <svg 
            width={ width ? width : '21px'}
            height={ height ? height : '17px'}
            viewBox="0 0 21 17" 
        >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group" transform="translate(0.600000, 0.688699)">
                    <path d="M18.2124095,0.542261453 L6.44001735,14.492342 C5.89977534,15.1337483 5.98320254,16.0907993 6.62823726,16.6300257 L6.62823726,16.6300257 C7.27327197,17.1692521 8.23675441,17.0852825 8.77801383,16.4418529 L8.77801383,16.4418529 L20.550406,2.49278399 C21.0916654,1.85036604 21.0082382,0.893315043 20.3621861,0.355100317 L20.3621861,0.355100317 C20.0762953,0.116343409 19.7283428,0 19.3814078,0 L19.3814078,0 C18.9459585,0 18.5135614,0.18412609 18.2124095,0.542261453 L18.2124095,0.542261453 Z" id="Clip-2" fill={ color ? color : "#80abba" }></path>
                    <path d="M0.389469,9.65873531 L6.47033635,16.4804127 C7.02975191,17.1087251 7.98802875,17.1597245 8.61005038,16.5936313 C9.23106224,16.0285582 9.28054124,15.0605899 8.72112567,14.4332975 L2.64025833,7.61060011 C2.34136481,7.27502418 1.92937646,7.10468624 1.51536855,7.10468624 C1.15386896,7.10468624 0.791359602,7.23422467 0.501554067,7.49738148 C-0.120467568,8.06347463 -0.170956337,9.03042292 0.389469,9.65873531 Z" id="Clip-5" fill={ color ? color : "#80abba" }></path>
                </g>
            </g>
        </svg>
    );

}

export default Checkbox;
