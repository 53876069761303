// Dependencies
import React from "react";

const RoundedArrow = ({ width, height, color, orientation }) => {
  return (
    <svg
      width={
        width
          ? width
          : orientation === "down" || orientation === "up"
          ? "25px"
          : "18px"
      }
      height={
        height
          ? height
          : orientation === "down" || orientation === "up"
          ? "18px"
          : "25px"
      }
      viewBox={
        orientation === "down" || orientation === "up"
          ? "0 0 25 18"
          : "0 0 18 25"
      }
    >
      {orientation === "right" && (
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Iconos-/-Miscelanea-/-Play"
            transform="translate(-24.000000, -17.000000)"
            fill={color ? color : "#80abba"}
            fillRule="nonzero"
          >
            <g id="Rectangle-9">
              <path
                d="M35.7823515,21.01597 L37.8037644,37.5761693 C37.9375998,38.6726007 37.1572615,39.669929 36.0608301,39.8037644 C35.8998738,39.8234115 35.7371256,39.8234115 35.5761693,39.8037644 L19.01597,37.7823515 C17.9195386,37.6485161 17.1392004,36.6511878 17.2730357,35.5547564 C17.3271643,35.1113146 17.5281988,34.6987613 17.8440869,34.3828732 L32.3828732,19.8440869 C33.1639218,19.0630383 34.4302518,19.0630383 35.2113003,19.8440869 C35.5271884,20.1599749 35.728223,20.5725283 35.7823515,21.01597 Z"
                transform="translate(27.538400, 29.538400) rotate(-45.000000) translate(-27.538400, -29.538400) "
              />
            </g>
          </g>
        </g>
      )}

      {orientation === "down" && (
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Iconos-/-Miscelanea-/-Play"
            transform="translate(-15.000000, -26.000000)"
            fill={color ? color : "#80abba"}
            fillRule="nonzero"
          >
            <g id="Rectangle-9">
              <path
                d="M35.7823515,21.01597 L37.8037644,37.5761693 C37.9375998,38.6726007 37.1572615,39.669929 36.0608301,39.8037644 C35.8998738,39.8234115 35.7371256,39.8234115 35.5761693,39.8037644 L19.01597,37.7823515 C17.9195386,37.6485161 17.1392004,36.6511878 17.2730357,35.5547564 C17.3271643,35.1113146 17.5281988,34.6987613 17.8440869,34.3828732 L32.3828732,19.8440869 C33.1639218,19.0630383 34.4302518,19.0630383 35.2113003,19.8440869 C35.5271884,20.1599749 35.728223,20.5725283 35.7823515,21.01597 Z"
                transform="translate(27.538400, 29.538400) rotate(-315.000000) translate(-27.538400, -29.538400) "
              />
            </g>
          </g>
        </g>
      )}

      {orientation === "left" && (
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Iconos-/-Miscelanea-/-Play"
            transform="translate(-13.000000, -17.000000)"
            fill={color ? color : "#80abba"}
            fillRule="nonzero"
          >
            <g id="Rectangle-9">
              <path
                d="M35.7823515,21.01597 L37.8037644,37.5761693 C37.9375998,38.6726007 37.1572615,39.669929 36.0608301,39.8037644 C35.8998738,39.8234115 35.7371256,39.8234115 35.5761693,39.8037644 L19.01597,37.7823515 C17.9195386,37.6485161 17.1392004,36.6511878 17.2730357,35.5547564 C17.3271643,35.1113146 17.5281988,34.6987613 17.8440869,34.3828732 L32.3828732,19.8440869 C33.1639218,19.0630383 34.4302518,19.0630383 35.2113003,19.8440869 C35.5271884,20.1599749 35.728223,20.5725283 35.7823515,21.01597 Z"
                transform="translate(27.538400, 29.538400) rotate(-225.000000) translate(-27.538400, -29.538400) "
              />
            </g>
          </g>
        </g>
      )}

      {orientation === "up" && (
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Iconos-/-Miscelanea-/-Play"
            transform="translate(-15.000000, -15.000000)"
            fill={color ? color : "#80abba"}
            fillRule="nonzero"
          >
            <g id="Rectangle-9">
              <path
                d="M35.7823515,21.01597 L37.8037644,37.5761693 C37.9375998,38.6726007 37.1572615,39.669929 36.0608301,39.8037644 C35.8998738,39.8234115 35.7371256,39.8234115 35.5761693,39.8037644 L19.01597,37.7823515 C17.9195386,37.6485161 17.1392004,36.6511878 17.2730357,35.5547564 C17.3271643,35.1113146 17.5281988,34.6987613 17.8440869,34.3828732 L32.3828732,19.8440869 C33.1639218,19.0630383 34.4302518,19.0630383 35.2113003,19.8440869 C35.5271884,20.1599749 35.728223,20.5725283 35.7823515,21.01597 Z"
                transform="translate(27.538400, 29.538400) rotate(-135.000000) translate(-27.538400, -29.538400) "
              />
            </g>
          </g>
        </g>
      )}
    </svg>
  );
};

export default RoundedArrow;
