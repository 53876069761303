// Dependencies
import React from "react";


// Components
import SectionItem from "../components/sectionItem";
import Icon from "../components/icons";
import SearchBlock from './searchBlock'

// Constants
import { TYPE_OF_ASSETS, TYPE_OF_OWNERSHIP } from "../constants";



class SectionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ownerable: {},
      showImageModal: false,
      id: this.props.modal  ? this.props.id : this.props.history.location.state.id ,
      type: this.props.type !== undefined  ? this.props.type :  this.props.location.state.id  
    };
  }

  _successUploadImages() {
    this.props.fetch();
  }

  _errorUploadImages() {
    alert("error uploading");
  }

  _setFileSelected = (images, state) => {
    this.props.createImageAsset(
      this.props.authentication_token,
      () => this._successUploadImages(),
      () => this._errorUploadImages(),
      images,
      state.ownerable.ownerable_id,
      state.ownerable.ownerable_type
    );
  };

  _clickToLoadFormElement(e, item) {
    e.preventDefault();

    this.setState({
      ownerable: {
        ownerable_id: item.data[0].ownerable_id,
        ownerable_type: item.data[0].ownerable_type
      }
    });

    this.inputElementFile.click();
  }

  _loadFormElement(e, state) {
    const fr = new FileReader();
    let fileIntermediate;
    if (e.target.files[0] !== undefined) {
      fileIntermediate = {
        filename: e.target.files[0].name,
        content_type: e.target.files[0].type
      };
    }
    fr.addEventListener("load", () => {
      fileIntermediate.data = fr.result.split("base64,")[1];

      this._setFileSelected([fileIntermediate], this.state);
    });

    fr.readAsDataURL(e.target.files[0]);
  }


  checkType(type){
    let regAssets = new RegExp(TYPE_OF_ASSETS)
    let regOwnership = new RegExp(TYPE_OF_OWNERSHIP)

    if(regAssets.test(type)){
      return "balance"
    }else if (regOwnership.test(type)){
      return "ownership"
    }else if( type === "Document"){
      return "document"
    }else if( type === "Alert"){
      return "alert"      
    }else if(type === "Contact"){
      return "contact"
    }else if(type === "Image"){
      return "image"
    }else if(type === "RelatedPermit"){
      return "related_permit"
    }

  }

  
  newSectionItem(item, index) {
    if (item.data === undefined) {
      console.log({item})
      return (
        <li
          key={index}
          className={this.props.searchAll ? "item-content border" : "item-content"}
          onClick={
            !this.props.searchAll ?
             item.deleted ? () => this.props.actionContent(item) : 
             this.state.type === "contact"
                ? () => this.props.actionContact(item, "contact")
                : this.state.type === "document"
                ? () => this.props.actionContact(item, "document")
                : this.state.type === "photo" ?
                  () => this.props.actionShowImages(item)
                : () => this.props.actionContent(item)
            :
            () => this.props.actionContent(item, this.checkType(item.type))
          }
        >
          {
            !this.props.searchAll ?
          
             item.model_class === "contact"  ? (
            <SectionItem
              documentSection={item.model_class}
              title={item.title}
              description={item.company}
              avatar={item.avatar_url}
              checked={
                this.props.assigned_contacts !== undefined &&
                this.props.assigned_contacts.indexOf(item.id) !== -1 ? (
                  <Icon name="Check"  bgColor="transparent" />
                ) : null
              }
              isSearch={this.props.isSearch}
            />
          ) : item.model_class === "image" ? (
            <SectionItem
              documentSection={'photo'}
              title={item.title}
              rightText={item.size}
              documentType={item.type}
              avatar={item.urls.medium}
              isSearch={this.props.isSearch}
            />
          ) : this.props.assignDataType === 'document' ? (
            <SectionItem
              documentSection={"assignDocument"}
              title={item.title || item.name}
              description={item.description}
              checked={
                this.props.assigned_contacts !== undefined &&
                this.props.assigned_contacts.indexOf(item.id) !== -1 ? (
                  <Icon name="Check" bgColor="transparent" />
                ) : null
              }
              documentType={item.type}
              isSearch={this.props.isSearch}
            />
       
          ) : item.model_class === 'document_file' ? (
            <>
            <SectionItem
              date={item.created_at}
              documentSection={item.model_class}
              title={item.title}
              description={item.description}
              documentType={item.type}
              isSearch={this.props.isSearch}
            />
            </>
            ) : this.props.resource_key === 'tenant' ? 
            <>
            <SectionItem
              date={item.created_at}
              documentSection={this.props.resource_key}
              title={item.title}
              description={item.description}
              documentType={this.props.resource_key}
              isSearch={this.props.isSearch}
              checked={
                this.props.assigned_contacts !== undefined &&
                this.props.assigned_contacts.indexOf(item.id) !== -1 ? (
                  <Icon name="Check" bgColor="transparent" />
                ) : null
              }
            />
            </>
            : ["trust", "account"].includes(this.props.resource_key) ? 
             <SectionItem
              date={this.props.resource_key == "trust" ? item.created_at : ""}
              documentSection={this.props.resource_key}
              title={this.props.resource_key == "trust" ? item.title : item.name}
              description={item.address}
              documentType={this.props.resource_key}
              isSearch={this.props.isSearch}
              checked={
                this.props.assigned_contacts !== undefined &&
                this.props.assigned_contacts.indexOf(item.id) !== -1 ? (
                  <Icon name="Check" bgColor="transparent" />
                ) : null
              }
            />
            : (

            <SectionItem
           
              title={item.title || item.name}
              description={item.description}
              rightText={item.size}
              documentType={item.type}
              deleteItem={item.deleted === true}
              deleteIconAction={item.deleted === true ? (e) => this.props.deleteIconAction(item, e) : ""} 
              isSearch={this.props.isSearch}
            />
            
          )
          
          :
            this.props.searchAll &&
            <SearchBlock
              documentSection={this.checkType(item.type)}
              title={item.title || item.name}
              description={item.description}
              avatar={item.image_url}
              iconName={this.checkType(item.type) === 'balance' ? 'Info' : this.checkType(item.type) === 'ownership' ? 'Diagram' : item.type == "RelatedPermit" ? "User" : 'ClosedFolder' }
              iconColor={this.checkType(item.type) === 'document' ? 'white' : '#FF9900'}
              documentType={this.checkType(item.type) === 'document' ? item.file_type : false}
              documentSize={this.checkType(item.type) === 'document' ? item.file_size : false}
              isSearch={this.props.isSearch}
            /> 
          }

        </li>
      );
    }
   
    return (
      
      <li key={index} className="section-list-item">
        {
          item.data.length > 0 &&
          <ul>
          <li
            className={this.props.searchAll || item.title === ""  ? "item-title hidden" : "item-title"}
            style={{
              cursor: "inherit"
            }}
          >
            {this.props.title !== false &&
              <p className="headingH5">{item.title}</p>
            } 
            {/*(this.props.location !== undefined && this.props.location.state) && this.state.id !== "contact" && (
              <div>
                <Icon
                  name="Plus"
                  bgColor="transparent"
                  width={20}
                  height={20}
                />
              </div>
            )*/}
          </li>

          {(this.props.location !== undefined && this.props.location.state) && this.state.id === "photo" && (
            <input
              type="file"
              ref={inputFile => (this.inputElementFile = inputFile)}
              onChange={e => this._loadFormElement(e, this.state)}
              accept="image/*"
            />
          )}

          {item.data.map((item, index) => this.newSectionItem(item, index))}
        </ul>
        }
        
      </li>
    );
  }

  render() {
    console.log(this.props.data)
    return (
      <>
        {this.props.data.map((elem, index) => this.newSectionItem(elem, index))}
        
      </>
    );
  }
}

export default SectionList;
