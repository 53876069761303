// Dependencies
import React from 'react';

const Menu = ({ width, height, color }) => {

  return (

    <svg
      width={ width ? width : '28px'}
      height={ height ? height : '20px'}
      viewBox="0 0 28 20"
    >

      <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd" fill={ color ? color : "#80abba" }>
        <g id="icons-/-menu-/-hamburguer" transform="translate(-4.000000, -8.000000)">
          <path d="M5.61538462,11.1578947 C4.72323233,11.1578947 4,10.4509759 4,9.57894737 C4,8.70691882 4.72323233,8 5.61538462,8 L30.3846154,8 C31.2767677,8 32,8.70691882 32,9.57894737 C32,10.4509759 31.2767677,11.1578947 30.3846154,11.1578947 L5.61538462,11.1578947 Z M14.2307692,19.5789474 C13.3386169,19.5789474 12.6153846,18.8720286 12.6153846,18 C12.6153846,17.1279714 13.3386169,16.4210526 14.2307692,16.4210526 L30.3846154,16.4210526 C31.2767677,16.4210526 32,17.1279714 32,18 C32,18.8720286 31.2767677,19.5789474 30.3846154,19.5789474 L14.2307692,19.5789474 Z M11,28 C10.1078477,28 9.38461538,27.2930812 9.38461538,26.4210526 C9.38461538,25.5490241 10.1078477,24.8421053 11,24.8421053 L30.3846154,24.8421053 C31.2767677,24.8421053 32,25.5490241 32,26.4210526 C32,27.2930812 31.2767677,28 30.3846154,28 L11,28 Z" id="path-1"></path>
        </g>
      </g>
    </svg>

  );

}

export default Menu;
