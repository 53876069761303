// Dependencies
import React from 'react';

const Print = ({ width, height, color }) => {

  return (

    <svg 
      width={ width ? width : '24px'}
      height={ height ? height : '24px'}
      viewBox="0 0 23 23"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.91001 5.83002H20.0947C20.8375 5.83389 21.5488 6.13069 22.0741 6.65595C22.5993 7.18121 22.8961 7.89252 22.9 8.63534L22.9 8.64002L22.9 15.32C22.9 16.0653 22.604 16.78 22.077 17.307C21.55 17.834 20.8353 18.13 20.09 18.13H17.23V16.33H20.09C20.3579 16.33 20.6148 16.2236 20.8042 16.0342C20.9936 15.8448 21.1 15.5879 21.1 15.32V8.64282C21.0981 8.37482 20.9908 8.11829 20.8013 7.92874C20.6118 7.73922 20.3553 7.63193 20.0873 7.63002H2.91271C2.64474 7.63193 2.38826 7.73922 2.19873 7.92874C2.00921 8.11827 1.90192 8.37475 1.90001 8.64272V15.32C1.90001 15.5879 2.00642 15.8448 2.19583 16.0342C2.38524 16.2236 2.64214 16.33 2.91001 16.33H5.77001V18.13H2.91001C2.16475 18.13 1.45001 17.834 0.923036 17.307C0.396059 16.78 0.100006 16.0653 0.100006 15.32V8.63534C0.103865 7.89252 0.400675 7.18121 0.925939 6.65595C1.4512 6.13069 2.16251 5.83389 2.90533 5.83003L2.91001 5.83002Z" fill={color ? color : "#80ABBA"}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.87 13.46H18.12V22.9H4.87V13.46ZM6.67 15.26V21.1H16.32V15.26H6.67Z" fill={color ? color : "#80ABBA"}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.87 0.100006H18.12V7.63001H4.87V0.100006ZM6.67 1.90001V5.83001H16.32V1.90001H6.67Z" fill={color ? color : "#80ABBA"}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.14 15.26H3.85999V13.46H19.14V15.26Z" fill={color ? color : "#80ABBA"}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.14 11.45H17.23V9.64999H19.14V11.45Z" fill={color ? color : "#80ABBA"}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.36 19.08H8.64001V17.28H14.36V19.08Z" fill={color ? color : "#80ABBA"}/>
    </svg>

  );

}

export default Print;
