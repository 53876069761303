// Dependencies
import React from 'react';

const Filter = ({ width, height, color }) => {

  return (

    <svg
      width={width ? width : '20px'}
      height={height ? height : '14px'}
      viewBox="0 0 20 14"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons-/-tools-/-filter" transform="translate(-8.000000, -11.000000)" fill={color ? color : "#80abba"} fillRule="nonzero">
          <path d="M21.2385672,19.0638648 L21.2385672,24.2 L15.1192836,24.2 L15.1192836,19.5034634 L9.0441607,12.0398162 C8.82192638,11.7667882 8.86310278,11.3652988 9.13613077,11.1430645 C9.24982032,11.0505255 9.39193114,11 9.53852162,11 L26.4615127,11 C26.813553,11 27.0989381,11.2853851 27.0989381,11.6374254 C27.0989381,11.7840159 27.0484125,11.9261267 26.9558736,12.0398162 L21.2385672,19.0638648 Z" id="Combined-Shape"></path>
        </g>
      </g>
    </svg>

  );

}

export default Filter;
