import { HOST_URL, URL_VERSION, BALANCE_VALUATIONS } from "../constants";

import get_header from "./api_header";
import {parseHeaders} from './api_header'

export function getBCIndexSuccess(payload) {
  return {
    type: "BALANCE_INDEX",
    payload
  };
}

export function getBCShowSuccess(payload) {
  return {
    type: "BALANCE_SHOW",
    payload
  };
}

export function getBCShowFormsSuccess(payload) {
  return {
    type: "BALANCE_SHOW_FORM",
    payload
  };
}

export function getBCDetailSuccess(payload) {
  return {
    type: "BALANCE_DETAIL",
    payload
  };
}

export function updateAssets(response) {
  return {
    type: "UPDATE_ASSETS",
    response
  };
}

export function getBCValuationsSuccess(payload) {
  return {
    type: BALANCE_VALUATIONS,
    payload
  }
}

export function getBCIndex(authentication_token, success, error) {
  return dispatch => {
    return fetch(HOST_URL + "api/v1/balance_principal/home", {
      method: "GET",
      headers: get_header(authentication_token)
    })
      .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            dispatch(getBCIndexSuccess(responseJson.balance_index));
            success();
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function getBCShow(authentication_token, historical_date, success, error, url) {
  return dispatch => {
    return fetch(HOST_URL + URL_VERSION + url + "?historical_date="+historical_date, {
      method: "GET",
      headers: get_header(authentication_token)
    })
      .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            dispatch(getBCShowSuccess(responseJson.bc_show));
            success();
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function getBCShowForms(authentication_token, success, error, url) {
  return dispatch => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: "GET",
      headers: get_header(authentication_token)
    })
      .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            dispatch(getBCShowFormsSuccess(responseJson.bc_show));
            success(responseJson);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function getBCDetail(authentication_token, success, error, url, id) {
  return dispatch => {
    return fetch(HOST_URL + URL_VERSION + url + "/" + id, {
      method: "GET",
      headers: get_header(authentication_token)
    })
      .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            dispatch(getBCDetailSuccess(responseJson.bc_show));
            success();
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function createAsset(
  authentication_token,
  success,
  error,
  url,
  method,
  attributes
) {

  return dispatch => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: method,
      headers: get_header(authentication_token),
      body: JSON.stringify(attributes)
    })
      .then((response) => parseHeaders(response, dispatch))
      .then(response => {
       
        if (response.ok) {
          response.json().then(responseJson => {
            
            dispatch(updateAssets(responseJson.bc_show));
            success(responseJson.bc_show);
          });
        } else if(response.status !== 401){
          response.json().then(responseJson => {
            
            error(responseJson);
          });
          
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function editAsset(
  authentication_token,
  success,
  error,
  url,
  method,
  attributes
) {
  
  return dispatch => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: method,
      headers: get_header(authentication_token),
      body: JSON.stringify(attributes)
    })
      .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            dispatch(updateAssets(responseJson.bc_show));
            success(responseJson);
          });
        }else if(response.status !== 401){
          response.json().then(responseJson => {
            
            error(responseJson);
          });
          
        } else {
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function destroyAsset(
  authentication_token,
  success,
  error,
  url,
  method, 
  force = false
) {
  return dispatch => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: method,
      headers: get_header(authentication_token),
      body: JSON.stringify({force: force})
    })
      .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            success();
          });
        } else {
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function createAssetResource(
  authentication_token,
  success,
  error,
  url,
  method,
  attributes
) {
  return dispatch => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: method,
      headers: get_header(authentication_token),
      body: JSON.stringify(attributes)
    })
      .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            success(responseJson);
          });
        } else {
          response.json().then(responseJson => {
            error(responseJson);
          });
          
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function getBCValuations(authentication_token, success, error, url, resourceable_id, resourceable_type) {
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + url + "?resourceable_id="+resourceable_id+"&resourceable_type="+resourceable_type, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
      .then((response) => parseHeaders(response, dispatch))
      .then((response) => {
        if (response.ok) {
          response.json().then((responseJson) => {
            dispatch(getBCValuationsSuccess(responseJson.bc_show))
            success()
          })
        }
        else {
          error()
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export function editValuation(authentication_token, success, error, url, method, attributes) {
  return (dispatch) => {

    return fetch(HOST_URL + URL_VERSION + url, {
      method: method,
      headers: get_header(authentication_token),
      body: JSON.stringify(attributes)
    })
      .then((response) => parseHeaders(response, dispatch))
      .then((response) => {
        if (response.ok) {
          response.json().then((responseJson) => {
            dispatch(getBCValuationsSuccess(responseJson.bc_show))
            success();
          })
        }
        else {
          error();
        }

      })
      .catch((error) => {
        console.error(error);
      });
  }
}


export function getLifeInsuranceDetail(authentication_token, success, error, url) {
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
      .then((response) => {
        if (response.ok) {
          response.json().then((responseJson) => {
            success(responseJson.data)
          })
        }
        else {
          error()
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}


export function getRecentOrdersActivities(authentication_token, params, success, error) {

  return dispatch => {
    return fetch( `${HOST_URL}api/v1/balance_principal/financial_assets/activities/${params}&elements=5`, {
      method: "GET",
      headers: get_header(authentication_token)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            success(responseJson);
          });
        } else if(response.status !== 401){
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}
