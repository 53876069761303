import React from "react";
import NumberFormat from "react-number-format";

const TableRow = (props) => {
    return (
      <div
        className="c-table-editable__row"
        data-id={props.rowsData.id}
        key={`row-${props.rowsData.id !== "" ? props.rowsData.id : props.rowsData.temp_id}`}
      >
        {props.rowsData.row.map((input, i) => {
          return (
            <div className="c-table-editable__cell-wrap">
            <NumberFormat
              getInputRef={(el) => props.refInput.current[i] = el}
              readOnly={i === 0}
              key={`row-cell-${i}-${props.rowsData.id !== "" ? props.rowsData.id : props.rowsData.temp_id}`}
              onValueChange={(value) => {
                props.onChange(props.rowsData, i, value.value, props.rowsData.temp_id)
              }}
              onBlur={(e) => props.onBlur(e)}
              decimalScale={0}
              defaultValue={input.value}
              thousandSeparator={","}
              decimalSeparator={"."}
              inputMode="numeric"
              className="c-table-editable__cell"
            />
            </div>
          );
        })}
      </div>
    );
};

export default TableRow;
