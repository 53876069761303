// Dependencies
import React from "react";

const ZoomFit = ({ width, height, color, orientation, bgColor }) => {
  return (
    <svg width={width ? width : "24px"} height={height ? height : "24px"} viewBox="0 0 24 24">
      <path
        d="M18.25 4C20.3211 4 22 5.67893 22 7.75V16.25C22 18.3211 20.3211 20 18.25 20H5.75C3.67893 20 2 18.3211 2 16.25V7.75C2 5.67893 3.67893 4 5.75 4H18.25ZM18.25 13C17.8703 13 17.5565 13.2822 17.5068 13.6482L17.5 13.75V15C17.5 15.2454 17.3231 15.4496 17.0899 15.4919L17 15.5H15.75C15.3358 15.5 15 15.8358 15 16.25C15 16.6297 15.2822 16.9435 15.6482 16.9932L15.75 17H17C18.0544 17 18.9182 16.1841 18.9945 15.1493L19 15V13.75C19 13.3358 18.6642 13 18.25 13ZM5.75 13C5.3703 13 5.05651 13.2822 5.00685 13.6482L5 13.75V15C5 16.0544 5.81588 16.9182 6.85074 16.9945L7 17H8.25C8.66421 17 9 16.6642 9 16.25C9 15.8703 8.71785 15.5565 8.35177 15.5068L8.25 15.5H7C6.75454 15.5 6.55039 15.3231 6.50806 15.0899L6.5 15V13.75C6.5 13.3358 6.16421 13 5.75 13ZM8.25 7H7L6.85074 7.00549C5.86762 7.07802 5.08214 7.86123 5.00604 8.84335L5 9V10.25L5.00685 10.3518C5.05651 10.7178 5.3703 11 5.75 11C6.1297 11 6.44349 10.7178 6.49315 10.3518L6.5 10.25V9L6.50806 8.91012C6.5451 8.70603 6.70603 8.5451 6.91012 8.50806L7 8.5H8.25L8.35177 8.49315C8.71785 8.44349 9 8.1297 9 7.75C9 7.3703 8.71785 7.05651 8.35177 7.00685L8.25 7ZM17 7H15.75C15.3358 7 15 7.33579 15 7.75C15 8.1297 15.2822 8.44349 15.6482 8.49315L15.75 8.5H17C17.2454 8.5 17.4496 8.67688 17.4919 8.91012L17.5 9V10.25C17.5 10.6642 17.8358 11 18.25 11C18.6297 11 18.9435 10.7178 18.9932 10.3518L19 10.25V9C19 7.94564 18.1841 7.08183 17.1493 7.00549L17 7Z"
        fill="#ffffff"
      />
    </svg>
  );
};

export default ZoomFit;
