import React, {createRef} from "react";

// Components
import Icon from "../components/icons";
import Modal from "../components/modal";

class ActionSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };

    this.modalWrapperRef = createRef(null)
  }

  show() {
    this.setState({ showModal: !this.state.showModal });
  }

  isRecursiveModal(el) {
    let contentParent = el.closest('.modal-wrapper').closest('.modal-content')
    let isDoubleModal = contentParent ? true : null
    return isDoubleModal;
  }

  handleCloseModal(e,element) {
    if(this.state.showModal && !this.isRecursiveModal(e.currentTarget)) {
      let body = document.body;
      body.classList.remove('has-modal');
    }
  }

  renderChildren() {

    return (
      this.props.render &&
      this.props.render.map((element, index) => {
        let lastItemMargin =
          index === this.props.render.length - 1
            ? {
                marginTop: 20
              }
            : {
                marginTop: 0
              };
         if(this.props.show === true && ["add_asset", "add_new_asset"].includes(element.code)){
          lastItemMargin = {
                marginTop: 20
              }
         }
        switch (element.type) {
          case "title":
            return (
              <div
                key={index}
                className="actionSheet-title"
                style={lastItemMargin}
              >
                <span
                  className="actionSheet-title-text"
                  style={element.styleText}
                >
                  {element.message}
                </span>
              </div>
            );
          case "icon button":
            return (
              <button
                key={index}
                onClick={(e) => {
                  if(element.close) {
                    this.handleCloseModal(e,element)
                    this.setState({ showModal: !this.state.showModal })
                  }else {

                    this.handleCloseModal(e,element)
                    // let body = document.body;
                    // body.classList.remove('has-modal');
                    element.onClick()
                  }
                }}
                className="actionSheet-button"
                style={lastItemMargin}
              >
                <Icon
                  name={element.iconName}
                  width={element.iconName === "Notification" ? 20 : 16}
                  height={element.iconName === "Notification" ? 20 : 16}
                  close={false}
                  color={element.iconName === 'Garbage' || element.code === 'liability' || element.code === 'add_liability'  ? '#f3b01c' : ''}
                />
                <span
                  className={element.danger ? "actionSheet-button-text alert" : 'actionSheet-button-text'}
                >
                  {element.message}
                </span>
              </button>
            );
          case "noIcon button":
            return (
              <button
                key={index}
                onClick={
                  element.close
                    ? (e) => {
                      this.handleCloseModal(e, element)
                      this.setState({ showModal: !this.state.showModal }, element.onClick) 
                    }
                    : element.onClick
                }
                className="actionSheet-button"
                style={lastItemMargin}
              >
                <span
                  className={element.danger ? "actionSheet-button-text alert" : 'actionSheet-button-text'}
                  
                >
                  {element.message}
                </span>
              </button>
            );
          case "view":
            return (
              <div key={index} style={lastItemMargin}>
                <span
                  className="actionSheet-title-text"
                  style={element.styleText}
                >
                  {element.message}
                </span>
              </div>
            );
          case "object":
            return (
              <div key={index} style={[element.style, lastItemMargin]}>
                {element.object}
              </div>
            );
          case "text":
            return (
              <span
                key={index}
                className="actionSheet-title-text"
                style={lastItemMargin}
              >
                {element.message}
              </span>
            );
          default:
            return (
              <div key={index} style={lastItemMargin}>
                {element.object}
              </div>
            );
        }
      })
    );
  }

  _hideModal = e => {
    if (e.target.className === "modal-wrapper") {
      let body = document.body;
      body.classList.remove('has-modal');
      this.setState({
        showModal: false
      });
    }
  };

  componentDidMount() {
    document.addEventListener("keyup", e => {
      if (e.keyCode === 27) {

        let body = document.body;
        body.classList.remove('has-modal');
        this.setState({
          showModal: false
        });
      }
    });
  }

  
  render() {
 
    return (
      <>
        {
          this.state.showModal === true && (

            <Modal 
              modalType="actionSheet" 
              backAction={e => this._hideModal(e)} 
              isShowed={this.state.showModal}
            >
              <div ref={el => this.modalWrapperRef = el}>
                {this.renderChildren()}
              </div>
            </Modal>

          )
        }
      </>
    );
  }
}

export default ActionSheet;
