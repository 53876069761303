const initialState = {
  notifications: [],
  form : []
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_NOTIFICATIONS":
      return Object.assign({}, state, {
        notifications:
          action.page === 1
            ? action.notifications.activities
            : state.notifications.concat(action.notifications.activities),
          total_pages: action.notifications.total_pages
      });

    default:
      return state;
  }
}
