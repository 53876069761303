import {HOST_URL} from '../constants';
 
import get_header from './api_header'
import {parseHeaders} from './api_header'

export function searchAll(authentication_token, text, success, error) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/searches/search_index', {
      method: 'POST',
      headers: get_header(authentication_token),
      body: JSON.stringify({ search: {search_word: text}})
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })	
      }
      else if(response.status !== 401){
      	error()
      }

    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function getSearch(authentication_token, success, error) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/searches', {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })  
      }
      else if(response.status !== 401){
        error()
      }

    })
    .catch((error) => {
      console.error(error);
    });
  }
}