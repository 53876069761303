// Dependencies
import React from 'react';

const Construction = ({ width, height, color }) => {

    return (

        <svg 
            width={ width ? width : '72px'}
            height={ height ? height : '71px'}
            viewBox="0 0 72px 71px"
        >
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="handyman-tools" fill={color ? color : "#80abba"} fillRule="nonzero">
                  <path d="M14.7537398,27.5454185 C16.1977069,27.5454185 17.6162086,27.3192624 18.9827708,26.8835903 L27.1049912,35 L36,26.1070451 L27.8815616,17.981811 C29.4465526,13.0915934 28.1606731,7.67292177 24.5133135,4.02694136 C21.9168454,1.42980118 18.4603507,0 14.7902991,0 C12.7396994,0 10.6835528,0.464666475 8.83869385,1.34433079 C8.56033875,1.47694857 8.36543975,1.73562887 8.31324817,2.03893531 C8.26256939,2.34224175 8.36014495,2.65008644 8.57672741,2.86867886 L17.1225317,11.4162224 C17.7531169,12.046283 18.1010608,12.8828339 18.1010608,13.7708183 C18.1010608,14.6610719 17.7526127,15.4961101 17.1225317,16.1261706 C15.8671602,17.3852831 13.6723403,17.3852831 12.4146997,16.122893 L3.86586983,7.5776185 C3.64676604,7.35852183 3.33185161,7.26246218 3.03710785,7.31339144 C2.73303515,7.36558133 2.47510284,7.560474 2.34248075,7.83882005 C-0.168262121,13.1001657 0.907842577,19.4007708 5.02543063,23.5151995 C7.62341161,26.1130961 11.0781414,27.5454185 14.7537398,27.5454185 Z" id="path"></path>
                  <path d="M57.2010507,43.3508885 C55.7487661,43.3508885 54.3227993,43.5794061 52.9502272,44.0177145 L43.9336125,35 L35,43.9324317 L44.0191452,52.9516646 C42.4517205,57.858593 43.7412905,63.2959453 47.3997114,66.9577944 C50.0064344,69.5646159 53.47557,71 57.1587905,71 C59.2179017,71 61.2813149,70.5325891 63.1329208,69.6498988 C63.4122939,69.5170397 63.6079057,69.2571421 63.6602881,68.952705 C63.7126706,68.6482679 63.6132198,68.3390226 63.3958453,68.1198684 L54.8190408,59.5386908 C54.1874147,58.9078 53.8384514,58.0696491 53.8376922,57.1783545 C53.8376922,56.2855416 54.1866555,55.4473906 54.8205592,54.8142222 C56.0845706,53.5494039 58.2826092,53.5501631 59.54409,54.8149814 L68.1224129,63.39211 C68.3415588,63.610505 68.6475027,63.7076819 68.955218,63.657322 C69.2596435,63.6041784 69.5195314,63.4093184 69.6523854,63.1299348 C72.1723104,57.8497357 71.0930294,51.5264031 66.9603828,47.3958779 C64.3536597,44.7885502 60.8870547,43.3508885 57.2010507,43.3508885 Z" id="path"></path>
                  <path d="M54.3147231,22.8332279 C54.3147231,22.8332279 55.7850185,24.142775 56.9972581,23.5309769 C58.3673231,22.8299318 62.2271915,26.6994978 63.4586294,27.6048981 C63.4626186,27.6079406 63.4710958,27.6079406 63.4855569,27.6150398 L62.8123676,28.3026472 C61.9232589,29.1963845 61.9240069,30.6557753 62.8101236,31.5510339 C63.6967389,32.448828 65.1224044,32.4480674 66.0092691,31.5510339 L71.3394329,26.1379011 C72.2200643,25.2378252 72.2208123,23.7809698 71.3379369,22.8824151 C70.4583028,21.986903 69.0234121,21.986903 68.1405368,22.8831758 L67.2529241,23.7850265 C66.5174024,22.7548833 65.2951897,20.9991013 65.4502726,20.4174748 C66.1002743,17.870133 60.9935096,11.8530619 60.9935096,11.8530619 C48.3106219,-1.04160359 39.839157,-0.748508432 35.8089967,0.660680899 C34.1913476,1.22658781 34.4912908,1.58509693 36.1715216,1.8860519 C48.3986352,4.05637588 49.9153059,11.3888256 47.8426306,14.217853 C47.0455246,15.3101142 48.2081475,16.6287889 48.2081475,16.6287889 L48.645222,17.0702064 L28,38.0604846 C28.2799969,38.2321329 28.5520153,38.4273606 28.7918702,38.6720292 L32.2647793,42.2003269 C32.5006449,42.4409387 32.6933765,42.7155253 32.8606766,43 L53.5061479,22.0089612 L54.3147231,22.8332279 Z" id="path"></path>
                  <path d="M26.8609253,39.6141518 C26.0874855,38.8398299 24.7711699,38.7899634 23.9438222,39.5085969 L0.654831162,60.3909879 C-0.176312911,61.108862 -0.222628187,62.3307161 0.552330142,63.1057974 L6.89575129,69.4456687 C7.6712158,70.22075 8.8905983,70.177465 9.60759926,69.3507453 L30.4930046,46.0512247 C31.2074747,45.224505 31.1621718,43.9125369 30.3864542,43.1384682 L26.8609253,39.6141518 Z" id="path"></path>
              </g>
          </g>
        </svg>
    );

}

export default Construction;
