// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";

//Actions
import { autoLogin, destroySession } from "../../actions/session";

// Components
import Icon from "../../components/icons";
import AlertModal from "../../components/alertModal";

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLogOutModal: false,
    };
  }

  _navigateHelper(element) {
    let reload = true;
    if (
      element.code !== "log_out" &&
      element.code !== "settings" &&
      element.code !== "ownership" &&
      element.code !== "wealth_map" &&
      Object.keys(this.props.history).length > 0
    ) {
      switch (element.navigate) {
        case "Home":
          this.props.history.push("/home");
          break;
        case "NetWorthEvolution":
          if (window.location.href.includes("/home")) {
            reload = false;
            window.location = "#net_worth_evolution";
          } else {
            this.props.history.push("/home#" + element.code);
          }

          break;
        case "BalanceStack":
          this.props.history.push("/balance-index", { from_sidebar: true });
          break;
        case "Documents":
          this.props.history.push("/documents-index", { from_sidebar: true });
          break;
        case "Research":
          this.props.history.push("/researchs/" + element.navigation_id, {
            from_sidebar: true,
            id: element.navigation_id,
          });  
          break;          
        case "Services":
          this.props.history.push("/services/" + element.navigation_id, {
            from_sidebar: true,
            id: element.navigation_id,
          });
          break;
        case "Markets":
          this.props.history.push("/market", { from_sidebar: true });
          break;
        case "Notification":
          this.props.history.push("/notifications", { from_sidebar: true });
          break;
        default:
          this.props.history.push("/inProgress");
      }
      if (reload) window.location.reload();
    } else if (
      element.code === "log_out" &&
      Object.keys(this.props.history).length > 0
    ) {
      this.setState({
        showLogOutModal: true,
      });
    } else if (
      element.code === "ownership" &&
      Object.keys(this.props.history).length > 0
    ) {
      this.props.history.push("/ownership-index", { from_sidebar: true });
      window.location.reload();
    } else if (
      element.code === "wealth_map" &&
      Object.keys(this.props.history).length > 0
    ) {
      this.props.history.push("/wealth-map", { from_sidebar: true });
      window.location.reload();
    } else if (
      element.code === "settings" &&
      Object.keys(this.props.history).length > 0
    ) {
      this.props.history.push("/profile", { from_sidebar: true });
      window.location.reload();
    } else {
    }
  }

  _getIcon(icon) {
    switch (icon) {
      case "Home":
        return <Icon name="Home" width={24} height={24} />;
      case "Chart":
        return <Icon name="Chart" />;
      case "Ownership":
        return <Icon name="Ownership" />;
      case "Diagram":
        return <Icon name="Diagram" />;
      case "News":
        return <Icon name="News" width={28} height={28} strokeWidth={3.5} />;
      case "Performance":
        return (
          <Icon name="Performance" width={28} height={28} strokeWidth={3.5} />
        );
      case "LogoB":
        return (
          <Icon
            name="LogoB"
            color="#80abba"
            bgColor="transparent"
            width={28}
            height={28}
          />
        );
      case "Info":
        return <Icon name="Info" />;
      case "Learning":
        return <Icon name="Learning" />;
      case "Folder":
        return <Icon name="Folder" />;
      case "Gear":
        return <Icon name="Gear" />;
      case "LastActivity":
        return (
          <Icon name="LastActivity" strokeWidth={3.5} width={26} height={26} />
        );
      case "Off":
        return <Icon name="Off" color="#f3b01c" />;
      default:
        return <Icon name="Info" />;
    }
  }

  _successDestroy() {
    window.location.replace("/");
  }

  _errorDestroy() {}

  _closeSideBar() {
    let isSidebarOpen = document.body.classList.contains("open-sidebar");
    isSidebarOpen !== false && document.body.classList.remove("open-sidebar");
  }

  componentDidMount() {
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        let isSidebarOpen = document.body.classList.contains("open-sidebar");
        isSidebarOpen !== false &&
          document.body.classList.remove("open-sidebar");
      }
    });

  }

  render() {
    return (
      <aside className="wrapper-sidebar" key={`sidebar-${Math.random()}`}>
        <div className="container">
          <div>
            <div className="sidebar-header">
              <button
                className="wrapper-close"
                onClick={() => this._closeSideBar()}
              >
                <Icon name="Close" />
              </button>
            </div>

            <div className="sidebar-items">
              {Object.keys(this.props.menu).length > 0 &&
                this.props.menu.list.map((element, index) => {
                  return (
                    <>
                      <button
                        key={"menu_buttons_" + index}
                        className="wrapper-item"
                        onClick={() => this._navigateHelper(element)}
                      >
                        {this._getIcon(element.icon)}
                        <p className="title1 item-text">{element.title}</p>
                      </button>
                      <div className="item-separator"></div>
                    </>
                  );
                })}
            </div>

          </div>

          <div className="sidebar-items prefooter info-settings">
            {Object.keys(this.props.menu).length > 0 &&
              this.props.menu.prefooter.map((element, index) => {
                return (
                  <>
                    <button
                      key={"menu_buttons_prefooter_" + index}
                      className="wrapper-item"
                      onClick={() => this._navigateHelper(element)}
                    >
                      {this._getIcon(element.icon)}
                      <p className="title1 item-text">{element.title}</p>
                    </button>
                    <div className="item-separator"></div>
                  </>
                );
              })}
          </div>
        </div>
        {this.state.showLogOutModal && (
          <AlertModal
            isShowed={this.state.showLogOutModal}
            title={this.props.internalization.modals.profile.logout.title}
            msg={this.props.internalization.modals.profile.logout.body_web}
            action={() => {
              this.props.destroySession(
                this.props.authentication_token,
                () => this._successDestroy(),
                () => this._errorDestroy()
              );
              this.setState({
                showLogOutModal: false,
              });
            }}
            textButton={this.props.internalization.alerts.confirmation}
            textButtonAlertColor={true}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={false}
            actionCancelButton={() => {
              this.setState({
                showLogOutModal: false,
              });
            }}
          />
        )}
      </aside>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    user: state.session.user,
    menu: state.session.menu,
    internalization: state.session.internalization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    autoLogin: (authentication_token, success, error) =>
      dispatch(autoLogin(authentication_token, success, error)),
    destroySession: (authentication_token, success, error) =>
      dispatch(destroySession(authentication_token, success, error)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
