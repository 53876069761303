// Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Actions
import { selectUser } from "../actions/profile";

// Constants
import { internalization } from "../constants.js";

// Components
import AlertModal from "./alertModal";
import CustomModal from "./customModal";
import SelectMultiple from "./selectMultiple";

const ModalChangeUser = (props) => {
  const [showModalInfo, setShowModalInfo] = useState(true);
  const [selectRef, setSelectRef] = useState(null);
  const [options, setOptions] = useState([]);
  const [defaultValue, setDefultValue] = useState({});
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = () => {
    let option = [];
    props.assignedUsers.map((item) => {
      option.push({
        value: item["name"],
        id: item.id,
        current: item.current,
        related_permit_id: item.related_permit_id,
      });
    });

    setOptions(option);
  };

  const selectOption = (item) => {
    setDefultValue(item);
  };

  const sendData = () => {
    props.selectUser(
      props.authentication_token,
      () => success(),
      () => console.log("error"),
      defaultValue.item.id
    );
  };

  const success = () => {
    props.action();
    props.closeModal();
  };
  console.log("defaultValue", Object.keys(defaultValue).length > 0)
  let current = ""
  if(options.length > 0){
    let item = options.filter((item) => item.current)[0]
    if(item){
      current = item.value
    }
  }
  return (
    <>
      <CustomModal
        isShowed={showModalInfo}
        close={() => props.closeModal()}
        size="middle"
      >
        <div className="modal-change-user">
          <p className="modal-change-user__title">Choose a user to mirror</p>
          <div className="wrapper-form-item">
            <SelectMultiple
              key="select_user"
              label={"Select user"}
              colorGrey={true}
              setRef={(ref) => {
                setSelectRef(ref);
              }}
              defaultValue={
                Object.keys(defaultValue).length > 0
                  ? defaultValue.item.value
                  : current
              }
              options={options}
              multiple={false}
              openOptions={() => {}}
              getOptions={(item) => {
                selectOption(item);
              }}
              language={false}
              hasInput={true}
              editMirroName={(item) => props.editMirroName(item)}
            />
          </div>
          <button
            disabled={Object.keys(defaultValue).length > 0 ? false : true}
            onClick={() => setShowModalConfirmation(true)}
            className="btn primary"
          >
            Change
          </button>
        </div>
      </CustomModal>
      {showModalConfirmation && (
        <AlertModal
          isShowed={showModalConfirmation}
          title={`The user will be changed to:`}
          titleRegular={true}
          msg={defaultValue.item.value}
          msgBold={true}
          action={() => sendData()}
          textButton={"Confirm"}
          textButtonAlertColor={false}
          cancelButton={true}
          cancelTextButton={"Cancel"}
          cancelButtonAlertColor={true}
          actionCancelButton={() => setShowModalConfirmation(false)}
          maxHeight={'auto'}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectUser: (authentication_token, success, error, id) =>
      dispatch(selectUser(authentication_token, success, error, id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalChangeUser);
