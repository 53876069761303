// Dependencies
import React from 'react';


const Play = ({ width, height, color, orientation, bgColor}) => {

  return (

    <svg
      width={width ? width : '64px'}
      height={height ? height : '64px'}
      viewBox="0 0 64 64"
    >

      {
        orientation === "right" &&

        <g id="Design-System" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Iconos" transform="translate(-462.000000, -580.000000)">
            <g id="Iconos-/-Miscelanea-/-Play" transform="translate(464.000000, 582.000000)">
              <g>
                <circle id="Oval-5" fill={bgColor ? bgColor : '#80abba'} fillRule="nonzero" cx="30" cy="30" r="30"></circle>
                <path d="M37.7823515,21.01597 L39.8037644,37.5761693 C39.9375998,38.6726007 39.1572615,39.669929 38.0608301,39.8037644 C37.8998738,39.8234115 37.7371256,39.8234115 37.5761693,39.8037644 L21.01597,37.7823515 C19.9195386,37.6485161 19.1392004,36.6511878 19.2730357,35.5547564 C19.3271643,35.1113146 19.5281988,34.6987613 19.8440869,34.3828732 L34.3828732,19.8440869 C35.1639218,19.0630383 36.4302518,19.0630383 37.2113003,19.8440869 C37.5271884,20.1599749 37.728223,20.5725283 37.7823515,21.01597 Z" id="Rectangle-9" fill={color ? color : '#FFF'} fillRule="nonzero" transform="translate(29.538400, 29.538400) rotate(-45.000000) translate(-29.538400, -29.538400) "></path>
              </g>
            </g>
          </g>
        </g>
      }

      {
        orientation === "left" &&

        <g id="Design-System" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Iconos" transform="translate(-462.000000, -580.000000)">
            <g id="Iconos-/-Miscelanea-/-Play" transform="translate(464.000000, 582.000000)">
              <g>
                <circle id="Oval-5" fill={bgColor ? bgColor : '#FFFFFF'} fillRule="nonzero" cx="30" cy="30" r="30"></circle>
                <path d="M37.7823515,21.01597 L39.8037644,37.5761693 C39.9375998,38.6726007 39.1572615,39.669929 38.0608301,39.8037644 C37.8998738,39.8234115 37.7371256,39.8234115 37.5761693,39.8037644 L21.01597,37.7823515 C19.9195386,37.6485161 19.1392004,36.6511878 19.2730357,35.5547564 C19.3271643,35.1113146 19.5281988,34.6987613 19.8440869,34.3828732 L34.3828732,19.8440869 C35.1639218,19.0630383 36.4302518,19.0630383 37.2113003,19.8440869 C37.5271884,20.1599749 37.728223,20.5725283 37.7823515,21.01597 Z" id="Rectangle-9" fill={color ? color : '#f3b01c'} fillRule="nonzero" transform="translate(29.538400, 29.538400) rotate(-225.000000) translate(-29.538400, -29.538400) "></path>
              </g>
            </g>
          </g>
        </g>
      }

    </svg>

  );

}

export default Play;
