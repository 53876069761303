const ActionSheetOnPressAction = (item, balance, props, action) => {
  let status = false;
  let modalData = {};
  let title = "";

  switch (item.code) {
    case "add_asset":
    case "add_life_insurance":
    case "add_other":
    case "add_liability":
    case "add_valuation":
    case "add_private_equity":
    case "add_real_estate":
    case "add_owner":
    case "add_insured":
    case "add_beneficiary":
    case "add_tenant":
      
      let associated_elements = [];
      try {
        if (balance.hasOwnProperty("form_document")) {
          associated_elements = [
            { add_document: balance["form_document"].add_document },
            { add_contact: balance["form_contact"].add_contact },
          ];
          if (
            balance["form_alerts"] &&
            Object.keys(balance["form_alerts"]).length > 0
          ) {
            associated_elements.push({
              add_alert: balance["form_alerts"].add_alerts,
            });
          }
           if (
            balance["form_images"] &&
            Object.keys(balance["form_images"]).length > 0
          ) {
            associated_elements.push({
              add_image: balance["form_images"],
            });
          }
        } else {
          if (balance.hasOwnProperty("multiple_new")) {
            associated_elements = [
              {
                add_document:
                  balance["multiple_new"][item.code]["form_document"]
                    .add_document,
              },
              {
                add_contact:
                  balance["multiple_new"][item.code]["form_contact"]
                    .add_contact,
              },
            ];
            if (
              balance["multiple_new"][item.code]["form_alerts"] &&
              Object.keys(balance["multiple_new"][item.code]["form_alerts"])
                .length > 0
            ) {
              associated_elements.push({
                add_alert:
                  balance["multiple_new"][item.code]["form_alerts"].add_alerts,
              });
            }
            
            if (
              balance["multiple_new"][item.code]["form_images"] &&
              Object.keys(balance["multiple_new"][item.code]["form_images"])
                .length > 0
            ) {
              associated_elements.push({
                add_images:
                  balance["multiple_new"][item.code]["form_images"],
              });
            }
          }
        }
        if(item.code == "add_tenant"){
          associated_elements = [
              {
                add_document:
                  balance["form"][item.code]["form_document"]
                    .add_document,
              },
              {
                add_contact:
                   balance["form"][item.code]["form_contact"]
                    .add_contact,
              },
            ];
        }
      } catch (e) {}
      let new_date_minimum = undefined;
      if (item.code === "add_valuation") {
        try {
          new_date_minimum = props.location.state.date_minimum;
        } catch (e) {
          new_date_minimum = undefined;
        }
      }
      props.history.push("/balance-new", {
        modal: balance.form[item.code].modal,
        form: balance.form[item.code].inputs,
        resource_key: balance.form[item.code].resource_key,
        resource_constant: balance.form[item.code].resource_constant,
        url: item.url,
        method: item.method,
        title: item.title,
        code: item.code,
        date_minimum: new_date_minimum,
        last_breadcrumb: {
          title: title,
          code: "real_estate",
          url: props.history.location.pathname + props.history.location.search,
        },
        associated_elements: associated_elements,
        temp_identifier: balance.identifier
      });
      break;
    case "add_vehicle":
    case "add_art":
    case "add_watch":
      let associated_elements_multiple = [];
      try {
        associated_elements_multiple = [
          {
            add_document:
              balance["multiple_new"][item.code]["form_document"].add_document,
          },
          {
            add_contact:
              balance["multiple_new"][item.code]["form_contact"].add_contact,
          },
        ];
        if (
          balance["multiple_new"][item.code]["form_alerts"] &&
          Object.keys(balance["multiple_new"][item.code]["form_alerts"])
            .length > 0
        ) {
          associated_elements_multiple.push({
            add_alert:
              balance["multiple_new"][item.code]["form_alerts"].add_alerts,
          });
        }
      } catch (e) {}

      props.history.push("/balance-new", {
        form: balance.form[item.code].inputs,
        resource_key: balance.form[item.code].resource_key,
        resource_constant: balance.form[item.code].resource_constant,
        url: item.url,
        method: item.method,
        title: item.title,
        code: item.code,
        last_breadcrumb: {
          title: title,
          code: "real_estate",
          url: props.history.location.pathname + props.history.location.search,
        },
        associated_elements: associated_elements_multiple,
      });
      break;
    case "edit_asset":
      try {
        title = balance.form[item.code].inputs.filter((e) => e.id === "name");
        if (title.length > 0) {
          title = title[0].value;
        } else {
          title = item.title;
        }
      } catch (e) {
        title = item.title;
      }
      props.history.push("/balance-edit", {
        modal: balance.form[item.code].modal,
        form: balance.form[item.code].inputs,
        resource_key: balance.form[item.code].resource_key,
        resource_id: item.resource_id,
        url: item.url,
        method: item.method,
        title: title,
        code: item.code,
        // last_breadcrumb: props.history.location
      });
      break;
    case "show_valuations":
      try {
        title =
          balance.form["edit_asset"] &&
          balance.form["edit_asset"].inputs.filter((e) => e.id === "name");
        if (title.length > 0) {
          title = title[0].value;
        } else {
          title = item.title;
        }
      } catch (e) {
        title = item.title;
      }
      let date_minimum = undefined;
      try {
        date_minimum =
          balance.form["edit_asset"] &&
          balance.form["edit_asset"].inputs.filter(
            (e) => e.id === "date_bought"
          );
        if (date_minimum.length > 0) {
          date_minimum = date_minimum[0].value;
        } else {
          date_minimum = undefined;
        }
      } catch (e) {
        date_minimum = undefined;
      }
      props.history.push("/valuations", {
        resourceable_id: item.resourceable_id,
        resourceable_type: item.resourceable_type,
        url: item.url,
        method: item.method,
        title: item.title,
        code: item.code,
        date_minimum: date_minimum,
        last_breadcrumb: {
          title: title,
          code: "real_estate",
          url: props.history.location.pathname + props.history.location.search,
        },
      });
      break;
    case "delete_asset":
    case "delete_document":
    case "delete_valuation":
      action();
      break;
    case "add_document":
    case "add_alerts":
    case "add_alerts_financial_statements":
    case "edit_document":
    case "add_contact":
    case "edit_contact":
      status = true;
      modalData = {
        form:
          item.code === "add_contact"
            ? balance.form[item.code]
            : balance.form[item.code].inputs,
        resource_key: balance.form[item.code].resource_key,
        can_manage: balance.form[item.code].can_manage,
        url: item.url,
        method: item.method,
        title: item.title,
        code: item.code,
        assets: balance.form[item.code].assets || [],
      };
      break;
    case "add_trust":
    case "add_investment_account":
    case "add_corporation":
    case "add_beneficiary_account":
    case "add_stake_holder":
    case "add_new_asset": 
      status = true;
      let associated_elements_stake = []
      if(item.code == "add_stake_holder"){
        associated_elements_stake = [
          {
            add_document:
              balance["form"][item.code]["form_document"]
                .add_document,
          },
          {
            add_contact:
               balance["form"][item.code]["form_contact"]
                .add_contact,
          },
        ];
      }
      
      modalData = {
        form: balance.form[item.code].inputs,
        resource_key: balance.form[item.code].resource_key,
        url: item.url,
        method: item.method,
        title: item.title,
        code: item.code,
        associated_elements: associated_elements_stake
      };

      break;
    case "delete_trust":
    case "delete_investment_account":
    case "delete_corporation":
    case "delete_beneficiary_account":
    case "delete_stake_holder":
      action();
      break;
    case "edit_corporation":
    case "edit_trust":
    case "edit_beneficiary_account":
    case "edit_investment_account":
      action();
    default:
      break;
  }
  return { status, modalData };
};

export default ActionSheetOnPressAction;
