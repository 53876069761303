// Dependencies
import React from 'react';

const DocPowerPoint = ({ width, height, color, bgColor }) => {

  return (

    <svg
      width={width ? width : '30px'}
      height={height ? height : '30px'}
      viewBox="0 0 30 30"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons-/-docs-/-powerpoint">
          <g id="doc-powerpoint">
            <circle id="Combined-Shape" fill={bgColor ? bgColor : "#80abba"} cx="15" cy="15" r="15"></circle>
            <path d="M20.25,8.25 L9.75,8.25 C8.925,8.25 8.25,8.925 8.25,9.75 L8.25,20.25 C8.25,21.075 8.925,21.75 9.75,21.75 L20.25,21.75 C21.075,21.75 21.75,21.075 21.75,20.25 L21.75,9.75 C21.75,8.925 21.075,8.25 20.25,8.25 L20.25,8.25 Z M13.35,16.05 L13.35,18.75 L12,18.75 L12,11.25 L15.225,11.25 C16.35,11.25 16.875,11.475 17.325,11.925 C17.85,12.375 18,12.975 18,13.65 C18,14.4 17.775,15 17.325,15.375 C16.875,15.75 16.35,15.975 15.225,15.975 L13.35,15.975 L13.35,16.05 Z" id="Shape" fill={color ? color : '#FFFFFF'}></path>
            <path d="M13.5,15.075 L13.5,12.375 L15.225,12.375 C15.75,12.375 16.125,12.525 16.35,12.825 C16.575,13.125 16.725,13.35 16.725,13.725 C16.725,14.175 16.575,14.4 16.35,14.7 C16.125,14.925 15.825,15.075 15.3,15.075 L13.5,15.075 L13.5,15.075 Z" id="Shape" fill={color ? color : '#FFFFFF'}></path>
          </g>
        </g>
      </g>
    </svg>

  );

}

export default DocPowerPoint;
