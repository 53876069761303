// Dependencies
import React from 'react';

const Performance = ({ width, height, colorPrimary, colorSecondary }) => {

  return (

    <svg
      width={width ? width : '45px'}
      height={height ? height : '43px'}
      viewBox="0 0 45 43"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g id="icons-/-Section-/-Rendimiento" transform="translate(-8.000000, -9.000000)" fillRule="nonzero" strokeWidth="1.5">
          <polyline id="Path-7" stroke={colorPrimary ? colorPrimary: "#80abba"} points="9.45214844 10.4707031 9.45214844 50.3242188 51.7041016 50.3242188"></polyline>
          <polyline id="Path-6" stroke={colorSecondary ? colorSecondary : "#80abba"} points="9.59472656 49.5 30.2216797 23.5888672 41.2519531 38.0546875 50.5898438 16.7314453"></polyline>
        </g>
      </g>
    </svg>

  );

}

export default Performance;
