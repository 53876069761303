// Dependencies
import React, {useEffect} from "react";
import classNames from "classnames";

// Components
import Icon from './icons';

const CustomModal = (props) => {

  useEffect(() => {
    let modal = document.querySelector(".custom_modal-wrapper");
    let hasSelect = modal.querySelector('.container-multiple-select')
    if(!hasSelect) {
      modal.addEventListener('click',removeBodyClass)
    }
  }, []);



  if(props.isShowed) {

    let body = document.body;

    setTimeout(()=> {
      let modal = document.querySelector(".custom_modal-wrapper");
      modal && body.classList.add('has-modal');
    }, 10)

  }

  const removeBodyClass = (e) => {

    let body = document.body;
    
    if(e && e.target === e.currentTarget){
      body.classList.remove('has-modal');
      props.close && props.close()
    }
  }

  const close = () => {
    let body = document.body;
    props.close();
    body.classList.remove('has-modal');
  }

  const customModalBlockClasses = () => {
    let cl = classNames(
      "custom_modal-block", 
      props.size === "small" && "custom_modal-block--small",
      props.size === "middle" && "custom_modal-block--middle",
      props.size === "big" && "custom_modal-block--big",
    )
    return cl;
  }


  return (
    <div className="custom_modal-wrapper" style={props.style ? props.style : {}}>
      <div className={customModalBlockClasses()}>
      {
        props.close &&
        <button
          className="close"
          onClick={() => {
            close()
          }}
        >
          <Icon name="Close" color="white" />
        </button>
 
      }
        {props.children}
      </div>
    </div>
  )
}

export default CustomModal;
