// Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Components
import Icon from "./icons/";
import Avatar from "./avatar";
import Logo from "./logo";
import HeaderNavigation from "./headerNavigation";

const Header = (props) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (props.user.avatar_user.url != null)
      setImages([props.user.avatar_user.url_big]);
    else {
    }
  }, []);

  const _openSideBar = () => {
    let isSidebarOpen = document.body.classList.contains("open-sidebar");
    isSidebarOpen === false && document.body.classList.add("open-sidebar");
  };
  return (
    <header className="c-header">
      <Logo url={window.location.pathname} />
      <div className="c-header__content">
        <div className="c-header__content-top">
          <div className="c-header__content-tools">
            <div className="c-header__content-tools-center">
              <Avatar
                withIcon={false}
                image={props.user.avatar_user}
                action={() => props.editProfile()}
                iconName="Gear"
                stroke={"#F5F5F7"}
                strokeWidth={2}
              />
              <div
                className="c-header__content-tools-center-info-user"
                onClick={() => props.editProfile()}
              >
                <span className="headingH4">
                  {props.user.name} {props.user.surname}
                </span>
                <span className="body grey-dark truncate">
                  {props.user.id_bulltick}
                </span>
              </div>
            </div>
            <div className="c-header__content-tools-center">
              <span className="c-header__content-tools-center-info-date">
                {props.date}
              </span>
              <button className="burger-menu" onClick={() => _openSideBar()}>
                <Icon name="Menu" />
              </button>
            </div>
          </div>
        </div>
        <div className="c-header__content-bottom">
          <HeaderNavigation
            documentsAction={() => props.documentsAction()}
            messagesAction={() => props.messagesAction()}
            alertsAction={() => props.alertsAction()}
            statementAction={() => props.statementAction()}
            newStatementLoaded={props.addepar_notifications > 0}
            searchAction={() => props.searchAction()}
            notificationsAction={() => props.notificationsAction()}
            nameCurrentUser={() => props.nameCurrentUser()}
            selectedUser={() => props.selectedUser()}
            financial_statements_enabled={props.financial_statements_enabled}
            {...props}
          />
        </div>
      </div>
    </header>

  );
};

function mapStateToProps(state) {
  return {
    addepar_notifications: state.session.addepar_notifications,
    insurance_user: state.session.insurance_user,
  };
}

export default connect(mapStateToProps, null)(Header);
