const ActionSheetAction = (item, action, props, setParent) => {
 
  switch (action.code) {
    case "new":
      switch (item.code) {
        case "contacts":
          props.history.push("/documents-show?url=" + item.url, {
            url: item.new,
            id: "contact",
            modalData: {
              form: item.form.add_contact.inputs,
              resource_key: item.form.add_contact.resource_key,
              url: item.new,
              method: "POST",
              code: "add_contact"
            },
            from: "profile"
          });
          break;
          case "ownership":
            setParent()
            props.history.push("/ownership-index");
            break;          
        default:
          setParent()
           let associated_elements_multiple = []
            try{
              associated_elements_multiple = [{add_document: props.cm["multiple_new"][item.code]["form_document"].add_document}, {add_contact: props.cm["multiple_new"][item.code]["form_contact"].add_contact}]
              if(props.cm["multiple_new"][item.code]["form_alerts"] && Object.keys(props.cm["multiple_new"][item.code]["form_alerts"]).length > 0){
                associated_elements_multiple.push({add_alert: props.cm["multiple_new"][item.code]["form_alerts"].add_alerts})
              }
            }catch(e){
              
            }
          props.history.push("/balance-new", {
            form: item.form.add_asset.inputs,
            resource_key: item.form.add_asset.resource_key,
            url: item.new,
            method: "POST",
            code: "add_asset",
            title: item.form_title || item.title,
            from: props.from,
            associated_elements: associated_elements_multiple
          });
          break;
      }
      break;
    case "show":
      setParent()
      switch (item.code) {
        case "contacts":
          props.history.push("/documents-show?url=" + item.url, {
            url: item.index,
            id: "contact"
          });
          break;
        case "documents":
          props.history.push("documents-index");
          break;
        case "ownership":
          props.history.push("/ownership-index");
          break;          
        default:
          props.history.push("/balance-show?url=" + item.url, {
            url: item.index
          });
          break;
      }
      break;
    default:
      break;
  }
};

export default ActionSheetAction;
