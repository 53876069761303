// Dependencies
import React from "react";
import { connect } from "react-redux";

// Actions
import { allMarket } from "../../actions/market";

// Components
import ContentBlock from "../../components/contentBlock";
import Tooltip from "../../components/tooltip";
import Icon from "../../components/icons";
import StackHeader from "../../components/stackHeader";

class MarketIndexScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: 0,
      showTabs: true,
      showTooltip: false,
      title: ""
    };
  }

  componentDidMount() {
    this.props.allMarket(
      this.props.authentication_token,
      responseJson => this.successAllMarket(responseJson),
      () => {}
    );
  }

  successAllMarket(responseJson) {
    this.setState({ title: responseJson.title });
    this.props.getBreadcrumbs({
        url: "/market",
        assetUrl: "/market",
        assetTitle: responseJson.title
    });    
  }

  handleTabs(e) {
    let tabs = document.querySelectorAll(".wrapper-tabs .btn");
    let tabText = e.currentTarget.querySelector("span");
    tabs.forEach(tab => {
      tab.classList.remove("bordered");
      tab.classList.add("light");
      let tabText = tab.querySelector("span");
      tabText.classList.remove("grey-dark");
      tabText.classList.add("orangeMedium");
    });
    e.currentTarget.classList.add("bordered");
    tabText.classList.add("grey-dark");
  }

  handleTooltip(e) {
    e.stopPropagation();
    this.setState({ showTooltip: !this.state.showTooltip });
    let body = document.body;
    body.addEventListener("click", e => this.setState({ showTooltip: false }));
  }

  getColumn() {
    let num = Object.keys(this.props.indices).length;

    switch (num) {
      case 1:
        return "one";
      case 2:
        return "two";
      case 3:
        return "three";
      default:
        return "four";
    }
  }

  render() {

    let big =
      Object.keys(this.props.marketNews).length > 0
        ? this.props.marketNews.filter(element => element.type === "big")
        : [];
    let medium =
      Object.keys(this.props.marketNews).length > 0
        ? this.props.marketNews.filter(element => element.type === "medium")
        : [];
    let small =
      Object.keys(this.props.marketNews).length > 0
        ? this.props.marketNews.filter(element => element.type === "small")
        : [];
    return (
      <div className="main-wrapper market">
        <StackHeader
          title={this.state.title}
          icon={<Icon name="News" colorPrimary={'#005774'} colorSecondary="#f3b01c" iconBig={true} />}
          help={false}
          helpAction={() => alert("boton de info del stackHeader")}
        />
        {/*<div className="wrapper-cards">
          <div className="eighty-percent">
            <div className="container-cards">
              {Object.keys(this.props.indices).length > 0 &&
                this.props.indices.map((item, index) => {
                  return (
                    <div
                      key={"indeces_" + index}
                      className={`card big ${this.getColumn()} ${item.closed && 'closed'}`}
                      data-closed={item.closed ? 'Closed' : ''}
                      style={{backgroundColor: item.background}}
                    >
                      <p className="card-text blue-medium">{item.name}</p>
                      <p
                        style={{color: item.color}}
                        className={
                          item.value2.indexOf("-") !== -1
                            ? "card-text red"
                            : "card-text green"
                        }
                      >
                        {item.value2}
                      </p>
                      <p className="card-text blue-light">{item.value}</p>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="twenty-percent">
            <div className="container-cards small">
              {Object.keys(this.props.currencies).length > 0 &&
                this.props.currencies.map((item, index) => {
                  return (
                    <div key={"currencies_" + index} className="card small">
                      <p className="card-text blue-light small">{item.name}</p>
                      <p className="card-text blue-light small">
                        {item.value}&nbsp;
                        <span
                          style={{color: item.color}}
                          className={
                            item.percentage.indexOf("-") !== -1
                              ? "small card-text red"
                              : "small card-text green"
                          }
                        >
                          {" "}
                          ({item.percentage})
                        </span>
                      </p>
                    </div>
                  );
                })}
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                flexDirection: 'column'
              }}
            >
              <p className="cards-info">{this.props.text.split('*')[0]}</p>
              <p className="cards-info" style={{marginTop: 0}}>{this.props.text.split('*')[1] && `*${this.props.text.split('*')[1]}`}</p>
            </div>
          </div>
        </div>*/}
        {this.state.content === 0 && (
          <>
            <div className="super-wrapper-content-block">
              {Object.keys(this.props.marketNews).length > 0 && (
                <ContentBlock maxHeight={false}>
                  <div
                    className="block-news"
                    onClick={() => window.open(big[0].url, "_blank")}
                  >
                    <h4 className="title1 blue u-text-ellipsis-3">{big[0].title}</h4>
                    <div className="block-news--container">
                      <div className="block-news--img">
                        <img src={big[0].image} className="news-img" alt="" />
                      </div>
                      <div className="block-news--text">
                        <p className="news-text u-text-ellipsis-6">{big[0].description}</p>
                      </div>
                      <p className="legend">{big[0].source}</p>
                    </div>
                  </div>
                </ContentBlock>
              )}

              <ContentBlock maxHeight={false}>
                {Object.keys(this.props.marketNews).length > 0 &&
                  medium.map((item, index) => {
                    return (
                      <div
                        key={"news_" + index}
                        className="block-news small"
                        onClick={() => window.open(item.url, "_blank")}
                      >
                        <h4 className="title1 blue u-text-ellipsis-2">{item.title}</h4>
                        <div className="block-news--container">
                          <div className="block-news--img">
                            <img src={item.image} alt="" className="news-img" />
                          </div>
                          <div className="block-news--text full-width">
                            <p className="block-news__title u-text-ellipsis-3">{item.description}</p>
                            <p className="block-news__legend">{item.source}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </ContentBlock>
            </div>

            <div className="container-box-link">
              {Object.keys(this.props.marketNews).length > 0 &&
                small.map((item, index) => {
                  return (
                    <div
                      key={"link_" + index}
                      className="box-link"
                      onClick={() => window.open(item.url, "_blank")}
                    >
                      <div className="container-add--icon">
                        <Icon
                          width="10"
                          height="10"
                          name="Close"
                          color="white"
                          iconBig={false}
                          className="add-icon"
                          close={false}
                        />
                      </div>
                      <div className="container-add--text">
                        <p className="box-link--text">{item.title}</p>
                        <p className="o-color--grey-dark u-text-ellipsis-2">{item.description}</p>
                        <p className="legend o-margin--zero">{item.source}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
        {this.state.content === 1 && (
          <div className="super-wrapper-content-block">
            <div className="sixty-percent">
              <ContentBlock
                title="Private Debt Fund"
                header={true}
                maxHeight={false}
                className="only-text"
              >
                <p>
                  Bulltick Wealth Management LLC ("Bulltick") is organizing a
                  fund, to be known as the Bulltick Diversified U.S. Real Estate
                  Capital Fund.
                </p>

                <p>
                  Private credit offers a larger and well under penetrated
                  opportunity in Latin America. The stock of public (DCM) and
                  private debt in developed economies, usually exceeds GDP
                  (105%), while in our target markets averages less than 35%
                </p>

                <p>
                  Increasing global regulation, compliance and market volatility
                  has made global banks retreat and local banks and
                  institutional investor focus mostly on local blue chip clients
                  (Companies larger than USD300 MM). Other than in selected
                  cases in Brazil, access to public market financing remains
                  very limited
                </p>
                <p className="headingH4">Investing Information</p>
                <ul className="list">
                  <li className="list-item">
                    <p>USD 15mm debt deal</p>
                  </li>
                  <li className="list-item">
                    <p>
                      30month term; 16% yield; 9-month grace for capital
                      amortization; equal quarterly installments from month 6
                      through 30
                    </p>
                  </li>
                  <li className="list-item">
                    <p>Transaction and origination costs of 100K</p>
                  </li>
                  <li className="list-item">
                    <p>
                      Management fee of 2% of AUM (only gets accrued once
                      invested, paid only when interest is collected, and goes
                      down as capital is amortized on the loan)
                    </p>
                  </li>
                  <li className="list-item">
                    <p>
                      Preferred return to investors of 6% (after management fee)
                      before 20% sponsor carry
                    </p>
                  </li>
                </ul>
              </ContentBlock>
            </div>
            <div className="fourty-percent">
              <ContentBlock
                title="Fund Managers"
                header={true}
                maxHeight={false}
                className="only-text"
              >
                <p className="headingH4">Fund Managers</p>
                <ul className="list">
                  <li className="list-item">
                    <p>20+ years of experience in investments</p>
                  </li>
                  <li className="list-item">
                    <p>
                      Head of Structured Alternative Investments at Bulltick
                    </p>
                  </li>
                  <li className="list-item">
                    <p>
                      Previously CFO of Grupo Garnero, Executive
                      DirectoratGuggenheim PartnersandExecutive Director &mdash;
                      Head of Equity Trading at Barclays and
                      GoldmanSachsinLondon
                    </p>
                  </li>
                </ul>
                <div className="centered">
                  <div
                    className="container-tooltip"
                    onClick={e => this.handleTooltip(e)}
                  >
                    <span className="link-tooltip">
                      Contact for more information
                    </span>
                    {this.state.showTooltip && (
                      <Tooltip>
                        <span className="text-tooltip">
                          Your requesto for more information has been sent. We
                          will respond to you quickly and shall contact you
                          within 1 to 2 business day. At times, replies may take
                          longer due to peak support demand
                        </span>
                        <span className="text-tooltip">
                          {" "}
                          For more information please visit:{" "}
                          <a
                            href="https://bulltick.com"
                            className="link"
                            target="_blank"
                          >
                            {" "}
                            https://bulltick.com
                          </a>
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </ContentBlock>
              <ContentBlock
                title="Fund Documents"
                header={true}
                maxHeight={false}
                className="centered-and-full only-text"
                className="only-text"
              >
                <ul className="list">
                  <li className="list-item">
                    <a href="#" className="link">
                      Fund Corporate Presentation
                    </a>
                  </li>
                  <li className="list-item">
                    <a href="#" className="link">
                      Factsheet July 2019
                    </a>
                  </li>
                </ul>
              </ContentBlock>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    marketNews: state.market.news,
    indices: state.market.indices,
    currencies: state.market.currencies,
    article: state.market.article,
    text: state.market.text
  };
}

function mapDispatchToProps(dispatch) {
  return {
    allMarket: (authentication_token, success, error) =>
      dispatch(allMarket(authentication_token, success, error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketIndexScreen);
