// Dependencies
import React from "react";

const LogoB = ({ width, height, color, waveColor }) => {
  return (
    <svg
      width={width ? width : "144px"}
      height={height ? height : "158px"}
      viewBox="0 0 144 158"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="bulltick-tear" fillRule="nonzero">
          <g id="Group" fill={color ? color : "#005773"}>
            <path
              d="M76.404,67.488 C68.892,67.488 62.372,72.024 58.403,77.41 C56.277,80.387 55.143,85.064 55.143,90.308 L55.143,114.12 C58.97,115.679 63.789,117.097 71.585,117.097 C85.475,117.097 97.24,109.868 97.24,90.875 C97.24,75.426 89.728,67.488 76.404,67.488 Z"
              id="Path"
            ></path>
            <path
              d="M58.331,0 C30.672,0 13.579,7.292 0,12.231 L0,84.825 C0,101.431 4.03,119.159 11.564,128.583 C25.643,145.639 48.772,157.083 75.423,157.083 C122.689,157.083 143.498,129.032 143.498,80.113 C143.498,19.973 107.603,0 58.331,0 Z M70.451,128.72 C60.529,128.72 51.033,126.594 42.528,123.759 L42.528,22.84 L54.86,22.84 L54.86,69.614 C59.679,60.968 69.884,55.723 80.09,55.723 C99.225,55.723 109.997,68.48 109.997,90.024 C109.996,119.223 89.869,128.72 70.451,128.72 Z"
              id="Shape"
            ></path>
          </g>
          <g
            id="Group"
            transform="translate(19.000000, 72.000000)"
            fill={waveColor ? waveColor : "#F3B01C"}
          >
            <path
              d="M1.495,34.577 C1.495,34.577 -3.944,16.629 20.803,16.901 C35.76,17.173 46.094,27.507 73.832,23.7 C101.57,19.893 113.536,6.839 113.536,6.839 C113.536,6.839 87.157,34.577 48.541,33.218 C36.782,32.803 16.724,27.506 1.495,34.577 Z"
              id="Path"
            ></path>
            <path
              d="M43.864,18.767 C47.034,19.578 50.473,20.284 54.343,20.707 C69.746,13.312 77.555,0.856 77.639,0.732 C65.971,14.789 44.079,18.737 43.864,18.767 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoB;
