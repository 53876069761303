export function getIconAsset(name){

  switch(name) {
    case "real_estates":
    case "real_estate":
      return "RealEstate"
      break;
    case "others":
    case "other":
      return "Other"
      break;
    case "private_equities":
    case "private_equity":
      return "PrivateEquity"
      break;
    case "vehicles":
    case "vehicle":
    case "car":  
    case 0:
    case 3:
      return "Vehicle"
      break;
    case "arts":
    case "art":
      return "Art"
      break;
    case "watches":
    case "watch":
      return "Watch"
      break;
    case "life_insurances":
    case "life_insurance":
      return "LifeInsurance"
      break;
    case "liabilities":
    case "liability":
    case"liabilities_real_estate":
      return "Liability"
      break;
    case "trusts":
    case "trust":
      return "Trust"
      break;
    case "corporations":
    case "corporation":
      return "Corporation"
    case "boat":
    case 2:
      return "Boat"
    case "aircraft":
    case 1:
      return "Airplane"
      break;
      default:
      return "Box"
  }
}
