// Dependencies
import React from "react";
import { gsap } from "gsap";

// Components
import Icon from "./icons/";
import SingleArrow from "./icons/singleArrow";
import Check from "./icons/check";
import LinkBlock from "./linkBlock";

let usersModified = [];

class SelectMultipleCustodianCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items:
        props.itemsSelected !== undefined && props.itemsSelected.length > 0
          ? props.itemsSelected
          : [],
      showTooltip: false,
      inputValue: {},
      canEdit: false,
      selecting: false
    };
    this.checkIconButton = React.createRef();
    this.editIconButton = React.createRef();
  }

  componentDidMount() {
    const body = document.querySelector("body");
    if(this.props.allSelected){
      // this.setState({items: this.props.options})
    }
    // body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    const body = document.querySelector("body");
    body.style.overflow = "visible";
  }

  componentDidUpdate(prevProps) {
    if (this.props.itemsSelected !== prevProps.itemsSelected) {
      this.setState({ items: this.props.itemsSelected });
    }
  }

  _openOptionsModal(e) {
    if (!this.props.disabled) {
      this.props.openOptions(e);

      this.dropdown();
    }
  }

  dropdown() {
    if (!this.containerElement.classList.contains("open")) {
      this.optionsElement.style.height =
        this.props.options.filter((item) => item.id !== "").length * 41 + 32 + "px";

      this.containerElement.style.zIndex = 10;
      this.containerElement.classList.add("open");
      this.iconElement.classList.add("rotate");
      if (this.props.options.filter((item) => item.id !== "").length * 41 + 32 > 217) {
        this.optionsElement.classList.add("scroll");
      }
    } else {
      this.optionsElement.style.height = 0;
      this.containerElement.classList.remove("open");

      this.iconElement.classList.remove("rotate");
    }
  }

  _selectOption(e, item, state, keepOpenDropdown) {
    if (this.props.currentCustodianName === item.value) return;
    e !== undefined && e.stopPropagation();
    this.setState({selecting: true})
    if (state.selecting) return;

    if (this.props.multiple) {
      let found = state.items.filter((element) => element.value === item.value);
      if (found.length > 0) {
        let allItems = state.items.filter((element) => element.id !== item.id);
        this.setState({selecting: false, items: allItems });
        this.props.getOptions(allItems);
      } else {
        this.setState({selecting: false,  items: [...state.items, item] });
        this.props.getOptions([...state.items, item]);
      }
    } else {
      this.setState({selecting: false})
      this.props.getOptions({ item });

      !keepOpenDropdown && this.dropdown();
    }
  }

  /*
   * _handleTooltip(e, index) {
   *   e.stopPropagation();
   *   this.state.showTooltip === false && this.setState({showTooltip: index})
   *   let body = document.body;
   *   body.addEventListener('click', e => {
   *     this.setState({ showTooltip: false })
   *   });
   * }
   */

  _handleTooltip(e) {
    e.stopPropagation();

    if (this.state.showTooltip !== false) {
      let modalContent = document.querySelector(".modal-content");
      if (modalContent) {
        let modalBody = document.querySelector(".modal-body");
        modalBody.style.paddingTop = "0px";
      }
      this.setState({ showTooltip: false });
    } else {
      this.setState({ showTooltip: true });
      let modalContent = document.querySelector(".modal-content");
      if (modalContent) {
        setTimeout(() => {
          this.modalPaddingTop();
        }, 0);
      }
    }
  }

  modalPaddingTop() {
    let tooltip = document.querySelector(".container-tooltip");
    let wrapperHelper = document.querySelector(".wrapper-helper");
    let containerTooltip = document.querySelector(".container-tooltip");
    let containerTootlipHeight = containerTooltip.offsetHeight + 36;
    let containerTootlipTop = containerTooltip.getBoundingClientRect().y;
    let modalContent = document.querySelector(".modal-content");
    let modalContentTop = modalContent.getBoundingClientRect().y;
    let dif = containerTootlipTop - (modalContentTop + 112);

    if (dif < 0) {
      let difPositive = -dif;
      let modalBody = document.querySelector(".modal-body");
      modalBody.style.paddingTop = `${difPositive}px`;
    }
  }

  _getLanguageLegible(language) {
    switch (language) {
      case "es-ES":
        return "Español";
      case "en-US":
        return "English";
      default:
        return "English";
    }
  }

  // Option type input

  _editInput(e, item, index) {
    let button = e.currentTarget;
    let input = button.previousSibling;
    let parent = e.currentTarget.closest(".options-multiple-select");
    let allInputs = [...parent.querySelectorAll(".options-multiple-select__input")];

    allInputs.forEach((input) => (input.disabled = true));

    input.disabled = false;
    input.focus();

    gsap.to(".js-edit-button", {
      opacity: 0,
      duration: 0.3,
      onComplete: (e) => {
        this.setState({
          inputValue: {
            value: input.value,
            index,
          },
          canEdit: true,
        });
        gsap.from(".js-check-button", {
          opacity: 0,
          duration: 1,
          onComplete: (e) => {},
        });
      },
    });
  }

  _inputOnchange(e, index) {
    this.setState({ inputValue: { index, value: e.target.value } });
  }

  _inputValue(item, index) {
    if (this.state.inputValue.index == index) {
      return this.state.inputValue.value;
    } else {
      if (usersModified.length > 0) {
        let uid = usersModified.findIndex((user) => user.id === item.id);

        if (uid !== -1) {
          return usersModified[uid].value;
        } else {
          return item.value;
        }
      } else {
        return item.value;
      }
    }
  }

  _confirmUserChanged(e, item, state) {
    e.stopPropagation();

    let button = e.currentTarget;
    let input = button.previousSibling;
    input.disabled = true;
    input.blur();

    let newItem = { ...item, value: state.inputValue.value ? state.inputValue.value : item.value };

    if (usersModified.length > 0) {
      let uid = usersModified.findIndex((user) => user.id === item.id);

      if (uid !== -1) {
        usersModified[uid] = newItem;
      } else {
        usersModified.push(newItem);
      }
    } else {
      usersModified.push(newItem);
    }

    gsap.to(button, {
      opacity: 0,
      duration: 0.3,
      onComplete: (e) => {
        this.props.editMirroName(newItem);
        this.setState({ canEdit: false });
        gsap.from(".js-edit-button", {
          opacity: 0,
          duration: 1,
          onComplete: (e) => {},
        });
      },
    });
  }

  _optionOnClick(e, item) {
    let currentTarget = e.target;

    if (currentTarget.nodeName === "DIV") {
      if (usersModified.length > 0) {
        let uid = usersModified.findIndex((user) => user.id === item.id);

        if (uid !== -1) {
          this.setState({ canEdit: false });
          this._selectOption(e, usersModified[uid], this.state);
        } else {
          this.setState({ canEdit: false });
          this._selectOption(e, item, this.state);
        }
      } else {
        if (this.props.disabledOptions && this.props.disabledOptions.includes(item.id)) {
          return "";
        } else {
          this.setState({ canEdit: false });
          this._selectOption(e, item, this.state);
        }
      }
    }
  }

  // @Option type input

  render() {
console.log("this.props.currentCustodianName", this.props.currentCustodianName)
    return (
      <div
        ref={(ref) => this.props.setRef(ref)}
        className={
          this.props.colorGrey
            ? "wrapper-multiple-select grey"
            : this.props.noBorder
            ? "wrapper-multiple-select no-border"
            : "wrapper-multiple-select"
        }
      >
        <p className="label-multiple-select">{this.props.label && this.props.label}</p>

        <div className={this.props.hasTooltip ? "has-tooltip" : ""}>
          <div
            ref={(el) => (this.containerElement = el)}
            className="container-multiple-select "
            onClick={(e) => {
              this.props.options !== undefined &&
                this.props.options.length > 0 &&
                this._openOptionsModal(e);
            }}
          >
            <p className="text-multiple-select">{this.props.defaultValue}</p>
            {!this.props.disabled && (
              <div ref={(el) => (this.iconElement = el)} className="icons-multiple-select">
                <Icon
                  className={"icon"}
                  noWrapper={true}
                  name="SingleArrow"
                  orientation="down"
                  width="20px"
                  height="20px"
                />
              </div>
            )}
          </div>

          <div
            ref={(el) => (this.optionsElement = el)}
            className={`${
              this.props.label ? "options-multiple-select c-invited__selector-content" : "options-multiple-select top c-invited__selector-content"
            } 
              ${this.props.hasTooltip ? "with-helper" : ""}
              ${this.props.optionsNoAbsolute ? "no-absolute" : ""}`}
          >            

            {this.props.options !== undefined &&
              !this.props.hasInput &&
              this.props.options.map((item, index) => {
                
                let checked = this.props.currentCustodianName === item.value ? true : this.state.items.filter((element) => element.value === item.value).length > 0
                if (item.value !== "Select") {
                  return (
                    <div
                      key={`option_${index}`}
                      className={`option ${
                        this.props.currentCustodianName === item.value ? "selected" : ""
                      }`}
                      style={{
                        color:
                          this.props.disabledOptions && this.props.disabledOptions.includes(item.id)
                            ? "gray"
                            : "",
                      }}
                      onClick={(e) =>
                        this.props.disabledOptions && this.props.disabledOptions.includes(item.id)
                          ? ""
                          : this._selectOption(e, item, this.state)
                      }
                    >
                      <LinkBlock
                        key={"linkinviteds_"+item.id}
                        id={"linkinviteds_"+item.id}
                        className={"wrapper-link-block__inline no-padding"}
                        balance={false}
                        date={""}
                        description={item.value}
                        action={() => this._selectOption(undefined, item, this.state)}
                        checkBox={true}
                        editable={this.props.currentCustodianName !== item.value}
                        isChecked={checked}
                      // 
                />
                    </div>
                  );
                }
              })}
          </div>

        </div>
      </div>
    );
  }
}

export default SelectMultipleCustodianCheckbox;
