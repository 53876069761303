// Dependencies
import React from 'react';

// Components
import Icon from './icons';

const VideoBlock = ({ videoUrl, date, preTitle, title }) => {

  return (

    <div className="wrapper-video-block">
      <div className="wrapper-video">
        <iframe className="video" title="bulltick video" width="100%" height="100%" src={videoUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <Icon name="Play" orientation="right" />
      </div>

      <div className="wrapper-pre-title">
        <span className="date">{date}</span>
        <span className="headingH5">{preTitle}</span>
      </div>

      <div className="wrapper-video-title">
        <span className="title1">
          {title}
        </span>
      </div>
    </div>

  );

}

export default VideoBlock;
