import { HOST_URL, URL_VERSION } from '../constants';

import get_header from "./api_header";

export function articleSuccess(payload) {
  
  return {
      type: 'ARTICLES',
      payload
  }
}

export function getArticleSuccess(payload) {
  return {
      type: 'ARTICLE',
      payload
  }
}


export function allArticles(authentication_token, success,error) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/articles/', {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          
          dispatch(articleSuccess(responseJson.articles));
          success()
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function getArticle(authentication_token, success,error, id) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/articles/'+id, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          dispatch(getArticleSuccess(responseJson.articles));
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function buttonClicked(url, authentication_token, success,error) {
  return (dispatch) => {
    return fetch(HOST_URL+url, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success()
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function getArticleFiles(authentication_token, id, text, success, error) {
  return (dispatch) => {
    return fetch(HOST_URL+'/api/v1/search_files/search_index', {
      method: 'POST',
      headers: get_header(authentication_token),
      body: JSON.stringify({id: id, text: text})
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}



