// Dependencies
import React from "react";

const Logo = ({ width, height, color, waveColor }) => {
  return (
    <svg
      width={width ? width : "125px"}
      height={height ? height : "50px"}
      viewBox="0 0 125 50"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="bt360-(1)">
          <g
            id="Group"
            transform="translate(0.000000, 7.724719)"
            fill={color ? color : "#005773"}
          >
            <path
              d="M0,0.0308988764 L4.90168539,0.0308988764 L4.90168539,18.6242978 C6.81741573,15.1875 10.8735955,13.1025281 14.9304775,13.1025281 C22.5372191,13.1025281 26.8195225,18.1734551 26.8195225,26.7380618 C26.8195225,38.3441011 18.818118,42.119382 11.0997191,42.119382 C7.15519663,42.119382 3.38061798,41.2738764 0,40.1467697 L0,0.0308988764 Z M11.5498596,37.4992978 C17.070927,37.4992978 21.747191,34.6257022 21.747191,27.0758427 C21.747191,20.934691 18.7619382,17.7794944 13.4648876,17.7794944 C10.4782303,17.7794944 7.8869382,19.5821629 6.30898876,21.7233146 C5.46488764,22.9066011 5.0133427,24.7654494 5.0133427,26.8504213 L5.0133427,36.3160112 C6.53511236,36.9360955 8.4508427,37.4992978 11.5498596,37.4992978 Z"
              id="Shape"
              fillRule="nonzero"
            ></path>
            <path
              d="M40.3827247,36.5414326 C40.2141854,36.0344101 40.1580056,35.0765449 40.1580056,34.119382 L40.1580056,17.8918539 L47.3904494,17.8918539 L46.3764045,13.7787921 L40.1580056,13.7787921 L40.1580056,3.46839888 L35.2563202,5.46980337 L35.2563202,34.2872191 C35.2563202,36.4852528 35.3679775,38.1186798 35.8757022,39.2457865 C36.8335674,41.3869382 38.636236,42.119382 41.1720506,42.119382 C42.5800562,42.119382 43.8195225,41.8377809 44.8897472,41.3869382 L44.8897472,36.8792135 C43.9880618,37.2738764 43.0308989,37.4992978 42.4108146,37.4992978 C41.1720506,37.4992978 40.6650281,37.1615169 40.3827247,36.5414326 Z"
              id="Path"
            ></path>
          </g>
          <path
            d="M78.0919944,44.5351124 C76.4978933,41.8448034 75.9494382,38.7064607 75.9494382,35.2689607 C75.9494382,27.8960674 79.9845506,19.4768258 89.0519663,19.4768258 C93.0379213,19.4768258 95.5287921,20.3735955 97.1727528,21.0210674 L97.1727528,25.255618 C95.2794944,24.4087079 92.7886236,23.5119382 89.7492978,23.5119382 C82.8251404,23.5119382 81.0308989,29.0414326 80.5330056,32.8279494 C80.4332865,33.7247191 80.4332865,34.5716292 80.4332865,35.4185393 C81.7289326,32.5786517 85.6144663,30.7851124 89.1516854,30.7851124 C94.4325843,30.7851124 99.5639045,33.7247191 99.5639045,40.0014045 C99.5639045,46.4283708 94.1832865,49.9157303 88.1551966,49.9157303 C83.3728933,49.9150281 79.9852528,47.7233146 78.0919944,44.5351124 Z M95.0800562,39.8518258 C95.0800562,36.613764 92.7886236,34.1720506 88.255618,34.1720506 C83.7717697,34.1720506 81.0814607,37.1615169 81.0814607,39.8518258 C81.0814607,43.5884831 84.7183989,45.7801968 87.9066011,45.7801968 C92.5400281,45.7808989 95.0800562,43.1896067 95.0800562,39.8518258 Z"
            id="Shape"
            fill={color ? color : "#005773"}
            fillRule="nonzero"
          ></path>
          <path
            d="M112.927669,49.9655899 C105.554775,49.9655899 101.518961,43.3398876 101.518961,34.6713483 C101.518961,25.9529494 106.400983,19.4269663 113.126404,19.4269663 C120.649579,19.4269663 124.634831,25.9529494 124.634831,34.6713483 C124.634831,43.3391854 120.400281,49.9655899 112.927669,49.9655899 Z M113.126404,23.5610955 C107.896067,23.5610955 105.952949,29.1404494 105.952949,34.7205056 C105.952949,40.25 107.746489,45.8799157 113.077247,45.8799157 C118.408006,45.8799157 120.151685,40.2507022 120.151685,34.7205056 C120.151685,28.991573 118.507022,23.5610955 113.126404,23.5610955 Z"
            id="Shape"
            fill={color ? color : "#005773"}
            fillRule="nonzero"
          ></path>
          <g
            id="Group"
            transform="translate(43.539326, 0.000000)"
            fill={waveColor ? waveColor : "#F3B01C"}
          >
            <path
              d="M0.507022472,16.5688202 C0.507022472,16.5688202 -2.15238764,7.78230337 9.94733146,7.91502809 C17.2598315,8.04845506 22.3125,13.1074438 35.8742978,11.2436798 C49.4360955,9.37991573 55.2865169,2.98946629 55.2865169,2.98946629 C55.2865169,2.98946629 42.3890449,16.5688202 23.5091292,15.9030899 C17.7598315,15.7008427 7.95294944,13.1074438 0.507022472,16.5688202 Z"
              id="Path"
            ></path>
            <path
              d="M21.2226124,8.82935393 C22.7724719,9.22682584 24.4543539,9.57233146 26.3462079,9.77949438 C33.8771067,6.15870787 37.6952247,0.0610955056 37.7359551,0 C32.0308989,6.88132022 21.3272472,8.81460674 21.2226124,8.82935393 Z"
              id="Path"
            ></path>
          </g>
          <path
            d="M68.0280899,33.7247191 L67.8286517,33.625 C70.8679775,33.0273876 73.258427,30.5863764 73.258427,27.0990169 C73.258427,21.2205056 67.6292135,19.4768258 63.5933989,19.4768258 C60.1580056,19.4768258 57.383427,20.3918539 55.7626404,21.073736 L54.5800562,25.8019663 C55.7296348,25.2521067 59.4620787,23.5617978 62.6966292,23.5617978 C65.1875,23.5617978 68.4754213,24.6081461 68.4754213,28.1453652 C68.4754213,30.636236 66.0344101,32.5793539 61.4016854,32.5793539 L61.4016854,35.6186798 C65.3370787,35.6186798 69.0238764,36.7647472 69.0238764,40.4508427 C69.0238764,44.4360955 65.8356742,45.8314607 63.0456461,45.8314607 C59.5582865,45.8314607 55.8216292,44.3363764 53.9789326,43.5393258 L53.9789326,47.7738764 C55.6228933,48.5210674 58.761236,49.9164326 63.9929775,49.9164326 C67.2310393,49.9164326 74.0063202,48.1228933 74.0063202,41.0983146 C74.0063202,37.3110955 71.6151685,34.4712079 68.0280899,33.7247191 Z"
            id="Path"
            fill={color ? color : "#005773"}
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
