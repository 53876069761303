import { HOST_URL, URL_VERSION } from '../constants';

import get_header from "./api_header";


export function getLinkForm(url, authentication_token, success,error) {
  return (dispatch) => {
    return fetch(HOST_URL+url, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function getConvertedValue(value, currency_type, date, authentication_token, success,error) {
  return (dispatch) => {
    return fetch(HOST_URL+"api/v1/currency_exchanges/new?currency="+currency_type+"&amount="+value+"&date="+date, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function getCapitalAmortizationValue(data, authentication_token, success,error) {
  return (dispatch) => {
    return fetch(HOST_URL+"api/v1/balance_principal/receivable_accounts/calculate_capital_amortization", {
      method: 'POST',
      headers: get_header(authentication_token),
      body: JSON.stringify(data)      
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success(responseJson)
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}




