// Dependencies
import React, { useRef, useEffect } from "react";
import classNames from "classnames";

// Components
import Icon from "../components/icons";

function Modal(props) {
  const modalWrapperRef = useRef(null);

  useEffect(() => {
    showModal();
  }, [props.isShowed]);

  const showModal = () => {
    if (props.isShowed && !isRecursiveModal()) {
      setTimeout(() => {
        let body = document.body;
        let modal = document.querySelector(".modal-wrapper");
        modal && body.classList.add("has-modal");
      }, 10);
    }
  };

  const isRecursiveModal = () => {
    let contentParent = modalWrapperRef.current.closest(".modal-content");
    let isDoubleModal = contentParent ? true : null;
    return isDoubleModal;
  };

  const removeBodyClass = () => {
    let body = document.body;
    if (!isRecursiveModal()) {
      body.classList.remove("has-modal");
    }
  };

  const classModal = [
    "modal-content",
    props.modalType === "alert" && "modal-content-alert",
    props.modalType === "notificationsModal" && "modal-content-notificationsModal",
    props.modalType === "actionSheet" && "modal-content-actionSheet",
    props.modalType === "zoom-images" && "modal-content-zoom-images",
    props.modalClasses && props.modalClasses,
    props.size === "small" && "modal-content--small",
    props.size === "middle" && "modal-content--middle",
    props.size === "big" && "modal-content--big",
  ]
    .filter((elem) => elem !== false)
    .join(" ");

  const classModalHeader = ["modal-header", props.paddingS && "padding-s"]
    .filter((elem) => elem !== false)
    .join(" ");

  const classModalBody = () => {
    const cl = classNames(
      "modal-body",
      props.paddingBody && "padding",
      props.heightAuto && "height-auto",
      props.maxHeightBig && "max-height-big",
      !props.maxHeight && "max-height-initial",
      props.bodyClasses && props.bodyClasses
    );
    return cl;
  };

  return (
    <div
      ref={modalWrapperRef}
      className={`modal-wrapper ${props.classWrapper ? props.classWrapper : ""}`}
      onClick={(e) => {
        !props.showBackButton && props.backAction !== undefined && props.backAction(e);
      }}
    >
      <div className={classModal}>
        {props.showBackButton && (
          <div className={classModalHeader}>
            <div className="modal-header-icons">
              <button
                type="button"
                className="header-icons"
                onClick={(e) => {
                  props.backAction(e);
                  removeBodyClass();
                }}
              >
                <Icon name="ChevronArrow" orientation="left" noWrapper={true} />
                <span className="title2 o-margin--left-eight">
                  {props.buttonText ? props.buttonText : "Back"}
                </span>
              </button>
              {props.showDeleteButton && (
                <div className="header-icons" onClick={(e) => props.deleteAction(e)}>
                  <Icon name="Garbage" orientation="left" bgColor="white" color="#f3b01c" />
                </div>
              )}
            </div>
          </div>
        )}

        <div className={classModalBody()}>{props.children}</div>
      </div>
    </div>
  );
}

export default Modal;
