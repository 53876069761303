// Dependencies
import React from 'react';

const DocFolderSquare = ({ width, height, color, bgColor }) => {

  return (

    <svg
      width={width ? width : '29px'}
      height={height ? height : '29px'}
      viewBox="0 0 29 29"
    >

          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="icons-/-docs-/-folder-2">
                  <g id="icons-/-docs-/-folder">
                      <g id="doc-folder">
                          <rect id="Rectangle" fill={bgColor ? bgColor : "#80abba"} x="0" y="0" width="29" height="29" rx="4"></rect>
                          <path d="M13,9 L8.5,9 C7.675,9 7,9.675 7,10.5 L7,19.5 C7,20.325 7.675,21 8.5,21 L20.5,21 C21.325,21 22,20.325 22,19.5 L22,12 C22,11.175 21.325,10.5 20.5,10.5 L14.5,10.5 L13,9 Z" id="Shape" fill={color ? color : "#fff"}></path>
                      </g>
                  </g>
              </g>
          </g>
      </svg>

  );

}

export default DocFolderSquare;
