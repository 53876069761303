// Dependencies
import React from 'react';

const DocExcelSquare = ({ width, height, color, bgColor }) => {

  return (

    <svg
      width={width ? width : '29px'}
      height={height ? height : '29px'}
      viewBox="0 0 29 29"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="doc-excel" fill={bgColor ? bgColor : "#80abba"} fillRule="nonzero">
                <path d="M25.7777778,0 L3.22222222,0 C1.45,0 0,1.45 0,3.22222222 L0,25.7777778 C0,27.55 1.45,29 3.22222222,29 L25.7777778,29 C27.55,29 29,27.55 29,25.7777778 L29,3.22222222 C29,1.45 27.55,0 25.7777778,0 Z M21.2666667,22.5555556 L18.0444444,22.5555556 L14.5,16.4333333 L10.9555556,22.5555556 L7.73333333,22.5555556 L12.8888889,14.5 L7.73333333,6.44444444 L10.9555556,6.44444444 L14.5,12.5666667 L18.0444444,6.44444444 L21.2666667,6.44444444 L16.1111111,14.5 L21.2666667,22.5555556 Z" id="Shape"></path>
            </g>
        </g>
    </svg>

  );

}

export default DocExcelSquare;
