// Dependencies
import React from 'react';


const TextBlock = ({ description, colorGrey }) => {

  return (

    <div className={colorGrey ?"textBlock-wrapper-description grey" : "textBlock-wrapper-description"}>
      <span className="body blue-dark">
        {description}
      </span>
    </div>

  );

}

export default TextBlock;