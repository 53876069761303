// Dependencies
import React from "react";

const Ownership = ({ width, height, colorPrimary, colorSecondary }) => {
  return (
    <svg
      width={width ? width : "32px"}
      height={height ? height : "45px"}
      viewBox="0 0 32 45"
    >
      <defs>
        <clipPath id="5698511141215610912099102">
          <path d="M8.78248175,0 L8.78248175,1.06345196 C3.80805922,2.02274993 1.08634502,3.941278 1.08634502,6.5176509 C1.08634502,10.9468089 8.31365685,12.5474799 15.0834851,12.5474799 C21.8532531,12.5474799 29.0748049,10.9632738 29.0748049,6.5176509 C29.0748049,4.01805321 26.3245756,2.10495414 21.3215195,1.1127956 L21.3215195,0.0164679874 C24.8551244,0.679734278 30.1612095,2.36804984 30.1612095,6.50673181 C30.1612095,10.9358898 24.5234648,13.5725171 15.0834851,13.5725171 C5.63774311,13.5725171 0,10.9358898 0,6.5176509 C0,2.31322441 5.27180693,0.646805843 8.78248175,0 Z"></path>
        </clipPath>
        <clipPath id="1091085010410011311311311098">
          <path d="M16.8112364,0 C18.6269526,0.495366713 21.1128647,1.51659306 21.1128647,3.3940409 C21.1128647,6.11540975 15.8031377,7.54028728 10.5621479,7.54028728 C5.31544258,7.54028728 0,6.11540975 0,3.3940409 C0,1.55945205 2.45151705,0.513674531 4.25009047,0.0183681398 L4.25009047,1.24144798 C2.6634323,1.72457681 1.0940331,2.5134492 1.0940331,3.3940409 C1.0940331,4.61712375 4.69112187,6.37836898 10.5621479,6.37836898 C16.4331739,6.37836898 20.0245449,4.65991865 20.0245449,3.3940409 C20.0245449,2.50120378 18.4207484,1.70620867 16.8112364,1.22307984 L16.8112364,0 Z"></path>
        </clipPath>
        <clipPath id="48555510410310510311857115">
          <path d="M8.76553872,0 C10.5853041,0 12.0614209,1.49223039 12.0644597,3.33504644 L12.0644597,10.5694771 C12.0558637,12.0612852 11.0745707,13.367836 9.65713999,13.7746948 L9.33396328,13.8706224 L9.33396328,21.8668927 L8.3754872,21.8668927 L8.3754872,12.9339302 L8.77115624,12.9339302 C10.0615784,12.9339302 11.108502,11.8762088 11.1115483,10.5694771 L11.1115483,3.33504644 C11.108502,2.02831466 10.0615784,0.970593319 8.77115624,0.970593319 L3.29892093,0.970593319 C2.00849878,0.970593319 0.961514813,2.02831466 0.958468537,3.33504644 L0.958468537,10.5694771 C0.961514813,11.8762088 2.00849878,12.9339302 3.29892093,12.9339302 L3.69458242,12.9339302 L3.69458242,21.8668927 L2.73055669,21.8668927 L2.73055669,13.8706224 L2.40731966,13.7746948 C0.996501746,13.3581467 0.0195972066,12.0575377 0,10.5694771 L0,3.33504644 C0.00303873577,1.49223039 1.47915554,0 3.29892093,0 L8.76553872,0 Z"></path>
        </clipPath>
        <clipPath id="5111395118110985149100122">
          <path d="M3.77014364,0 C5.85235627,0 7.54028728,1.68794609 7.54028728,3.7701738 C7.54028728,5.85234119 5.85235627,7.54028728 3.77014364,7.54028728 C1.68793101,7.54028728 0,5.85234119 0,3.7701738 C0,1.68794609 1.68793101,0 3.77014364,0 Z M3.77014364,0.996405755 C2.25015745,0.993402688 1.01549573,2.22322354 1.01254976,3.74320974 C1.00961431,5.26325625 2.23941254,6.49784257 3.75939873,6.50079614 C5.27938492,6.50374661 6.51399386,5.27398608 6.5169949,3.75399235 L6.5169949,3.74320974 C6.51399386,2.22742348 5.28594497,0.999359515 3.77014364,0.996405755 Z"></path>
        </clipPath>
      </defs>
      <g transform="translate(1.0 0.5759029388427734)">
        <g transform="translate(1.900701818158268e-13 30.427482891699015)">
          <g clipPath="url(#5698511141215610912099102)">
            <polygon
              points="0,0 30.1612095,0 30.1612095,13.5725171 0,13.5725171 0,0"
              stroke="none"
              fill="#005774"
            ></polygon>
          </g>
        </g>
        <g transform="translate(4.524172369433879 33.44359780465478)">
          <g clipPath="url(#1091085010410011311311311098)">
            <polygon
              points="0,0 21.1128647,0 21.1128647,7.54028728 0,7.54028728 0,0"
              stroke="none"
              fill="#005774"
            ></polygon>
          </g>
        </g>
        <g transform="translate(9.048344738867378 14.592879598681195)">
          <g clipPath="url(#48555510410310510311857115)">
            <polygon
              points="0,0 12.0644597,0 12.0644597,21.8668927 0,21.8668927 0,0"
              stroke="none"
              fill="#F3B01C"
            ></polygon>
          </g>
        </g>
        <g transform="translate(11.31043092358422 4.036477403335985)">
          <g clipPath="url(#5111395118110985149100122)">
            <polygon
              points="0,0 7.54028728,0 7.54028728,7.54028728 0,7.54028728 0,0"
              stroke="none"
              fill="#F3B01C"
            ></polygon>
          </g>
        </g>
        <path
          d="M0,8.50544553 L15.0806047,0 L30.1612095,8.50544553"
          stroke="#005774"
          strokeWidth="1"
          fill="none"
          strokeMiterlimit="10"
        ></path>
      </g>
    </svg>
  );
};

export default Ownership;
