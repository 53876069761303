// Dependencies
import React from 'react';


const Eye = ({ width, height, color, slashed }) => {


    return (
        <svg
            width={width ? width : '22px'}
            height={height ? height : slashed ? '17px' : '19px'}
            viewBox={slashed ? "0 0 22 17" : "0 0 22 19"}
        >


        {
            !slashed &&
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="eyeSlash">
                        <path d="M11,2 C6,2 1.73,5.11 0,9.5 C1.73,13.89 6,17 11,17 C16,17 20.27,13.89 22,9.5 C20.27,5.11 16,2 11,2 Z M11,14.5 C8.24,14.5 6,12.26 6,9.5 C6,6.74 8.24,4.5 11,4.5 C13.76,4.5 16,6.74 16,9.5 C16,12.26 13.76,14.5 11,14.5 Z M11,6.5 C9.34,6.5 8,7.84 8,9.5 C8,11.16 9.34,12.5 11,12.5 C12.66,12.5 14,11.16 14,9.5 C14,7.84 12.66,6.5 11,6.5 Z" id="path-1" fill={ color ? color : '#80abba' } fillRule="nonzero"></path>

                    </g>
                </g>
        }

        {
            slashed &&
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="eyeSlash" transform="translate(0.000000, -3.000000)" fill={ color ? color : '#80abba' }>
                        <path d="M3.11061992,15.9751665 C1.75414504,14.755786 0.680544473,13.2269308 0,11.5 C1.73,7.11 6,4 11,4 C12.2350096,4 13.4254819,4.18974093 14.544141,4.54164546 L12.3898334,6.69595299 C11.9485437,6.56837529 11.4822083,6.5 11,6.5 C8.24,6.5 6,8.74 6,11.5 C6,11.9822083 6.06837529,12.4485437 6.19595299,12.8898334 L3.11061992,15.9751665 Z M4.72160917,17.1926044 L7.1841744,14.7300392 C8.1015674,15.8125338 9.47098851,16.5 11,16.5 C13.76,16.5 16,14.26 16,11.5 C16,9.97098851 15.3125338,8.6015674 14.2300392,7.6841744 L16.5368755,5.37733803 C19.0112379,6.69462338 20.9624354,8.86710495 22,11.5 C20.27,15.89 16,19 11,19 C8.69240305,19 6.54029621,18.3375695 4.72160917,17.1926044 Z M12.8093424,9.10487119 C13.5332097,9.6519664 14,10.520247 14,11.5 C14,13.16 12.66,14.5 11,14.5 C10.020247,14.5 9.1519664,14.0332097 8.60487119,13.3093424 L12.8093424,9.10487119 Z M8.0329165,11.0528699 C8.22637553,9.75169566 9.25169566,8.72637553 10.5528699,8.5329165 L8.0329165,11.0528699 Z" id="Combined-Shape" fillRule="nonzero"></path>
                        <g id="slash" transform="translate(10.000000, 10.500000) rotate(-315.000000) translate(-10.000000, -10.500000) translate(8.000000, -1.000000)">
                            <rect id="Rectangle" x="3" y="-8.8817842e-15" width="1" height="23"></rect>
                        </g>
                    </g>
                </g>
        }

    </svg>

    );

}

export default Eye;
