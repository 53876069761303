// Dependencies
import React from "react";
import { connect } from "react-redux";

//Components
import Invitations from "./invitations";
import AlertModal from "./alertModal";
import ModalDeleteUser from "./modalDeleteUser";

// Actions
import { getInvitation, updateInvitation, deleteInvitation } from "../actions/permission";

class ProfilePermissionScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      checkboxes: [],
      permissions_selected: { manage: {}, read: {} },
      new_invitation_values: { manage: {}, read: {} },
      info_user: [],
      showButtons : false,
      modal_body: "",
      modal_title: "",
      modal_action: "",
      showDeleteUserModal: false,
      invitation_id: "",
      full_name: ""
    };
  }

  componentDidMount() {
    this.props.getInvitation(
      this.props.idInvitation,
      this.props.authentication_token,
      responseJSON => this._successUser(responseJSON),
      () => this._error()
    );
  }

  _successUser(responseJSON) {
    let info_user = responseJSON.invitation.form.update_user.inputs.filter(e => ["name", "email" ,"phone_number"].includes(e.id))

    let read_checkboxes = responseJSON.invitation.form.update_user.inputs.filter(
      e => e.id.indexOf("permits[read]") !== -1
    );
    let manage_checkboxes = responseJSON.invitation.form.update_user.inputs.filter(
      e => e.id.indexOf("permits[manage]") !== -1
    );
    let init_read = {};
    let init_manage = {};
    read_checkboxes
      .filter(e => e.value === true)
      .map(e => (init_read[e.key] = 1));
    manage_checkboxes
      .filter(e => e.value === true)
      .map(e => (init_manage[e.key] = 1));
    this.setState({
      loading: false,
      checkboxes: { manage: manage_checkboxes, read: read_checkboxes },
      permissions_selected: {  manage: init_manage, read: init_read },
      new_invitation_values: {  manage: init_manage, read: init_read },
      info_user: info_user,
      showDeleteUserModalData: responseJSON.invitation.privacy,
      invitation_id: responseJSON.invitation.identifier.id,
      full_name: responseJSON.invitation.full_name
    });
  }

  _deleteUserPermissions(){
    this.props.deleteInvitation(this.props.idInvitation, this.props.authentication_token, () => {}, () => alert("error"))
  }

  _save(state){
    this.props.updateInvitation(this.props.idInvitation, {permits: state.new_invitation_values}, this.props.authentication_token, () => this.props.showSuccesUpdateDataModal() )

  }

  _checkPermissions(){
    return Object.values(this.state.new_invitation_values.manage).every((value) => value === 0) && Object.values(this.state.new_invitation_values.read).every((value) => value === 0) 
   }

  render() {
    console.log("new_invitation_values",this.state.new_invitation_values)
    console.log("checkboxes", this.state.checkboxes)
    return (
      <div className="wrapper-profile modal">
        <div className="wrapper-access">
          <div className="wrapper-data">
            {
              this.state.info_user.map((e) => {
                return (
                  <div className="data">
                    <p className="input-label">{e.label}</p>
                    <p className="body blue medium">{e.id === "name" ? this.state.full_name : e.value}</p>
                  </div>
                )
              })
            }
          </div>
          {
            this.props.insurance_user !== true && 
              <Invitations
                invitationAttributes={this.state.checkboxes}
                new_invitation_values={this.state.new_invitation_values}
                setInvitationAttributesValues={new_invitation_values =>
                    this.setState({ new_invitation_values: new_invitation_values })
                }
                permissions_selected={this.state.permissions_selected}
                setInvitationAttributes={permissions_selected =>
                    this.setState({ permissions_selected: permissions_selected })
                }
                editable={this.state.showButtons}
                language_user={this.props.user.language}
              />

          }
          <div className="flex">
            {
              !this.state.showButtons &&
                <button className="btn primary" onClick={() => this.setState({showButtons: true})} >
                  <span className="btn-text">{this.props.internalization.buttons.edit_permissions}</span>
                </button>
            }
            {
              this.state.showButtons &&
                <>
                  <button
                    className="btn secondary"
                    onClick={() => {
                      this.props.showDeleteUserModal(this.state.invitation_id, this.state.showDeleteUserModalData)
                      this.setState({showDeleteUserModal: true})
                    }}
                  >
                    <span className="btn-text">{this.props.internalization.buttons.destroy}</span>
                  </button>
                  <button
                    className="btn primary"
                    onClick={() => this._save(this.state)}
                    disabled={this._checkPermissions()}
                  >
                    <span className="btn-text">{this.props.internalization.buttons.update}</span>
                  </button>
                </>
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    internalization: state.session.internalization,
    user: state.session.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getInvitation: (id, authentication_token, success, error) =>
      dispatch(getInvitation(id, authentication_token, success, error)),
    deleteInvitation: (id, authentication_token, success, error) =>
      dispatch(deleteInvitation(id, authentication_token, success, error)),
    updateInvitation: (id, attributes, authentication_token, success, error) =>
      dispatch(
        updateInvitation(id, attributes, authentication_token, success, error)
      )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePermissionScreen);
