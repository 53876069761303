import React, { useState, useEffect, useRef } from "react";
import Search from "../components/search";

const TableDropdownSearcherOwnership = (props) => {
  const tableSearcherRef = useRef(null);

  useEffect(() => {
    setWidth();
    window.addEventListener("resize", setWidth);
  }, []);

  const setWidth = () => {
    const table = document.querySelector(".c-table-dropdown");

    if (table) {
      const wrapperWidth = document.querySelector(".main-wrapper").getBoundingClientRect().width;
      const tableBodyWidth = table
        .querySelector(`.${table.classList.value}__body`)
        .getBoundingClientRect().width;

      tableSearcherRef.current.style.width =
        tableBodyWidth > wrapperWidth ? `100%` : `${tableBodyWidth}px`;
    }
  };

  const search = (text) => {
    let prepareResult = [];

    if (text !== "" && text.length > 1) {
      props.originData.forEach((row) => {
        const rowsResult = row.some((cell) => {
          if (cell.value.toLowerCase().includes(text.toLowerCase())) {
            prepareResult = [...prepareResult, row];
            return row;
          }
        });
      });

      setResult(prepareResult, text);
    } else {
      props.result(props.originData, false);
    }
  };

  const setResult = (result, text) => {
    const resultParentsId = result.map((row) => row[0].parent_id);

    // Include any parent doesn't match the search text but has child in result
    // Wath out! => this can include any sibling with same parent_id !!
    let compost = props.originData.filter((row, index) => {
      if (resultParentsId.includes(row[0].parent_id)) return row;
    });

    // Catch the index of siblings which doesn't match the search text
    // and include them into array in order to removed them
    let itemsToRemove = [];
    compost.forEach((row, index) => {
      if (!row[0].main) {
        let counter = 0;
        row.forEach((cell) => {
          if (!cell.value.toLowerCase().includes(text.toLowerCase())) {
            counter = counter + 1;
            // if counter is equal to row.length so no one of its position matches to search text
            // get its position and add it to itemsToRemove in order to remove it later
            counter >= row.length && itemsToRemove.push(index);
          }
        });
      }
    });

    // Remove sibligns included above which don't match the search text
    itemsToRemove.forEach((pos, index) => {
      compost.splice(pos - index, 1);
    });

    props.result(compost, true);
  };

  return (
    <div className="c-table__searcher" ref={tableSearcherRef}>
      <Search getValue={(text) => search(text)} />
    </div>
  );
};

export default TableDropdownSearcherOwnership;
