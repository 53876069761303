import React, { useState, useRef, useEffect } from "react";
import TextEllipsis from "react-text-ellipsis";
import classNames from "classnames";

import TableDropdownSearcher from "./TableDropdownSearcher";

//Helpers
import Icon from "./icons";

let rowIndex = 0;

const TableDropdown = (props) => {

  const [showTooltip, setShowTooltip] = useState(false);
  const [showSecurity, setShowSecurity] = useState(false);
  const [widthHeaderCell, setWidthHeaderCell] = useState(182);
  const [dataTable, setDataTable] = useState(props.dataTable);
  const [isSearchFilterd, setIsSeachFilterd] = useState(false);
  const [hasLink, setHasLink] = useState(false);

  const headerCell = useRef();
  const tableWrapperRef = useRef();
  const tableRef = useRef();
  const rowRef = useRef([]);

  useEffect(() => {
    checkWidthToStikcyFirstColumn()
  }, []);

  useEffect(() => {
    if (headerCell.current) {
      setWidthHeaderCell(headerCell.current.offsetWidth);
    }
    window.addEventListener("resize", () => {
      if (headerCell.current) {
        setWidthHeaderCell(headerCell.current.offsetWidth);
      }
    });

    tableRef.current.style.opacity = 1;
  }, []);

  useEffect(() => {
    tableClasses();
  }, [props.selectedIndex]);

  useEffect(() => {
    setHasLink(dataTable.rows && dataTable.rows.some((row, indexRows) => row[0].url));
  }, [dataTable]);

  const checkWidthToStikcyFirstColumn = () => {
    let wrapperWidth = tableWrapperRef.current.offsetWidth;
    let tableWidth = tableRef.current.offsetWidth;

    if(tableWidth > wrapperWidth) {
      tableRef.current.classList.add("is-first-column-sticky")
    }
  }

  const tableClasses = () => {
    let cl = classNames(
      "c-table-dropdown", 
      props.selectedIndex >= 0 && "has-row-selected",
      props.isStatement && "is-statement"
    );

    return cl;
  };

  const headerCells = (index, isTag) => {
    let cl = classNames(
      "c-table-dropdown__head-cell",
      isTag && "c-table-dropdown__head-cell--tag",
      index === 0 && "c-table-dropdown__head-cell--first"
    );

    return cl;
  };

  // Remove arrows in parent row without children
  useEffect(() => {
    let children = [...tableRef.current.querySelectorAll(`[data-has-parent]`)];
    let childrenIds = children.map((child) => child.getAttribute("data-has-parent"));
    let childrenIdsArray = [...new Set(childrenIds)].filter( (child) => child !== undefined && child !== null);
    let parents = tableRef.current.querySelectorAll(`[data-is-parent]`);

    parents.forEach(parent => {
      const parentId = parent.getAttribute("data-is-parent")
      if(childrenIdsArray.includes(parentId)) {
        parent.querySelector(".c-table-dropdown__icon-arrow").style.visibility = "visible"
      }
    })
  }, [tableRef.current])

  const rowClasses = (index, rowName, entity_id) => {

    let cl = classNames(
      "c-table-dropdown__row",
      isTotals(index) && "c-table-dropdown__row c-table-dropdown__row--totals",
      isHighlighted().includes(index) && `c-table-dropdown__row--main`,
      props.pieSliceSelected === rowName && "is-selected",
      props.fromSearch !== undefined && entity_id+"" === props.fromSearch+"" && "preselected is-shown"
    );
    return cl;
  };

  const rowCellClasses = (index) => {
    let cl = classNames(
      "c-table-dropdown__cell",
      index === 0 && "c-table-dropdown__cell--first",
      index === "last-icon" && "c-table-dropdown__cell--last-icon",
      index === "last-icon" && props.isStatement && "is-statement"
    );

    return cl;
  };

  const rowCellTextClasses = (indexRows, indexRow, value) => {
    let cl = classNames(
      "c-table-dropdown__text",
      "u-text-ellipsis-6",
      indexRow === 0 && !isTotals(indexRows)
        ? value.main
          ? "c-table-dropdown__text--left"
          : "c-table-dropdown__text--right"
        : "",
      value.highlighted && "c-table-alternatives__text--highlighted"
    );
    return cl;
  };

  const cellHeaderTextClasses = (tooltip, sort) => {
    let cl = classNames(
      "c-table-dropdown__text",
      "c-table-dropdown__head-cell-text",
      tooltip && "c-table-dropdown__text--tooltip"
    );
    return cl;
  };

  const isTotals = (index) => {
    return (
      dataTable.rows.find((row) => row[0].value === "Total") && dataTable.rows.length - 1 === index
    );
  };

  const isHighlighted = () => {
    let rowIndex = [];

    dataTable.rows.map((rows, indexRows) =>
      rows.map((row) => {
        if (row.main) {
          rowIndex = [...rowIndex, indexRows];
        }
      })
    );

    return rowIndex;
  };

  const handleTooltip = (e, index) => {
    e.stopPropagation();

    if (showTooltip === false || showTooltip !== index) {
      setShowTooltip(index);
    } else {
      setShowTooltip(false);
    }
  };

  const handleSecurity = (e, entity_id) => {
    e.stopPropagation();

    if (showSecurity === false || showSecurity !== entity_id) {
      setShowSecurity(entity_id);
    } else {
      setShowSecurity(false);
    }
  }

  const getWidthHeaderCell = () => {
    return {
      width: `${widthHeaderCell * 3}px`,
      // right: hasLink ? `${widthHeaderCell + 182}px` : `${widthHeaderCell}px`, // 48 = Link cell width
      right: hasLink ? props.isStatement ? `${widthHeaderCell + 182}px` : `${widthHeaderCell + 48}px` : `${widthHeaderCell}px`, // 48 = Link cell width
    };
  };

  const handleArrowClick = (parent) => {
    let parentIndex = parent.dataset.isParent;

    let arrow = parent.querySelector(".c-table-dropdown__icon-arrow");
    arrow.classList.toggle("is-rotate");

    let offspring = [...tableRef.current.querySelectorAll(`[data-has-parent="${parentIndex}"]`)];
    offspring.forEach((item) => {
      item.classList.toggle("is-shown");
      if(item.classList.contains("preselected")){
        if(item.classList.contains("is-shown")){
          item.classList.remove("is-shown")
        }
        else{
          item.classList.add("is-shown")
        }
      }
    });
  };

  const _navigateToAsset = (row) => {
    props.history.push("balance-detail?url=" + row.link, {
      url: "balance_principal/alternative_assets/",
      id: row.id,
    });
  };

  const navigateTo = (row) => {
    if (row[0].url.includes("custodians") || row[0].url.includes("accounts")) {
      props.history.push("balance-detail?url=" + row[0].url + "&id=" + row[0].id, {
        url: row[0].url,
        id: row[0].id,
      });
    } else if(row[0].url.includes("documents")){
      window.open(row[0].url, '_blank');

    } else {
      window.location.href = row[0].url;
    }
  };

  const renderCells = (row, indexRows) => {
    let element = {};

    return row.map((value, indexRow) => {

      element[value.key] = value.value;

      return (

        <>
          <div key={`${indexRow}-indexRow-custodian`} className={rowCellClasses(indexRow)}>
            {indexRow === 0 && !isTotals(indexRows) && value.main && (
              <button onClick={() => handleArrowClick(rowRef.current[indexRows])}>
                <Icon
                  name="SingleArrow"
                  orientation="down"
                  className="c-table-dropdown__icon-arrow"
                />
              </button>
            )}

            {indexRow !== 0 && (
              <span className={rowCellTextClasses(indexRows, indexRow, value)}>{value.value}</span>
            )}

            {indexRow === 0 && (
              <span
                onClick={() => row[0].url ? navigateTo(row) : ''}
                className={rowCellTextClasses(indexRows, indexRow, value)}
                style={row[0].url && { cursor: "pointer" }}
              >
                {value.value}
              </span>
            )}

            {value.iconLink && (
              <div className="c-table-dropdown__icon-link" onClick={() => _navigateToAsset(value)}>
                <Icon name="Link" color={"#005774"} width={"16px"} height={"16px"} />
              </div>
            )}
            {value.tooltip && (
              <span
                className="wrapper-helper no-margin"
                onClick={(e) => handleTooltip(e, `${indexRow}-indexRow-${indexRows}`)}
              >
                <div className="helper-text">
                  <Icon name="InfoTooltip" noWrapper={true} />
                </div>
                {showTooltip === `${indexRow}-indexRow-custodian-${indexRows}` && (
                  <div
                    className="container-tooltip top container-tooltip--no-triangle"
                    onClick={(e) => handleTooltip(e, false)}
                  >
                    <span className="tooltip-title">{value.title}</span>
                    <span className="tooltip-text">{value.tooltip_text}</span>
                  </div>
                )}
              </span>
            )}
            {
              props.onClickSecurity && showSecurity === value.entity_id && props.security_info[value.entity_id] &&(
                <span
                className="wrapper-helper no-margin"
                onClick={(e) => setShowSecurity(false)}
                style={{minWidth: 0}}
              >
                  <div
                    className="container-tooltip top container-tooltip--no-triangle"
                    onClick={(e) => setShowSecurity(false)}
                    style={{left: 20, top: 0}}
                  >
                    <span className="tooltip-title">{props.security_info[value.entity_id].name}</span>
                    { props.security_info[value.entity_id].ticker_symbol && <span className="tooltip-text"><span style={{fontFamily: 'Averta-Bold'}}> Ticker Symbol:</span> {props.security_info[value.entity_id].ticker_symbol}</span>}
                    { props.security_info[value.entity_id].cusip && <span className="tooltip-text"><span style={{fontFamily: 'Averta-Bold'}}> CUSIP:</span>  {props.security_info[value.entity_id].cusip}</span>}
                    { props.security_info[value.entity_id].isin && <span className="tooltip-text"><span style={{fontFamily: 'Averta-Bold'}}> ISIN:</span>  {props.security_info[value.entity_id].isin}</span>}
                  </div>
              </span>
            )}
          </div>

          {indexRow === row.length - 1 && row[0] && row[0].url && (
            <div key={`${indexRow}-indexRow-dropdown`} className={rowCellClasses("last-icon")}>
              <button onClick={() => navigateTo(row)}>
                <Icon 
                  name="Link" 
                  color={"#005774"} 
                  className="c-table-dropdown__icon-eye" 
                  width={"16px"}
                  height={"16px"}
                />
              </button>
              { props.isStatement  && props.action && !row[0].main && (
                <button onClick={
                  props.action.show ? () => props.action.show(row[0]) : ""
                }>
                  <Icon 
                    name="Eye" 
                    color={"#005774"} className="c-table-dropdown__icon-eye" 
                    width={"16px"}
                    height={"16px"}
                  />
                </button>
              )}
            </div>
          )}

        </>

      );

    });
  };

  const closeAllRows = () => {
    let rows = [...tableRef.current.querySelectorAll(".c-table-dropdown__row")];
    rows.forEach((row) => {
      row.removeAttribute("style");
      if (row.classList.contains("is-shown")) {
        row.classList.remove("is-shown");
      }
      if (row.classList.contains("preselected")){
        row.classList.remove("is-shown"); 
      }
    });

    let arrows = [...tableRef.current.querySelectorAll(".c-table-dropdown__icon-arrow ")];
    arrows.forEach((arrow) => {
      if (arrow.classList.contains("is-rotate")) {
        arrow.classList.remove("is-rotate");
      }
    });
  };

  const showAllArrows = () => {
    const arrows = [...tableRef.current.querySelectorAll(".c-table-dropdown__icon-arrow")];
    arrows.forEach((arrow) => (arrow.style.display = "flex"));
  };

  useEffect(() => {
    openChildrenAfterSearch();
  }, [dataTable]);

  const openChildrenAfterSearch = () => {
    let children = [...tableRef.current.querySelectorAll(`[data-has-parent]`)];
    let childrenIds = children.map((child) => child.getAttribute("data-has-parent"));
    // Remove duplicated ids in childrenIds array
    let childrenIdsArray = [...new Set(childrenIds)].filter(
      (child) => child !== undefined && child !== null
    );

    // Uncollapse children row
    if (isSearchFilterd) {
      children.forEach((child) => {
        child.classList.add("is-shown");
      });
    }
  };

  const filterResult = (result) => {
    let rows = [...tableRef.current.querySelectorAll(".c-table-dropdown__row")];
    let parentId = rows.map((row) => row.getAttribute("data-has-parent"));
    let parentIdArray = [...new Set(parentId)].filter(
      (item) => item !== undefined && item !== null
    );
    let mainRows = [...tableRef.current.querySelectorAll("[data-is-parent]")];


    if (parentIdArray.length > 0) {
      mainRows.forEach((mainRow) => {
        let mainRowCode = mainRow.getAttribute("data-is-parent");
        if (parentIdArray.indexOf(mainRowCode) === -1) {
          mainRow.style.display = "none";
        }
      });
    } else {
      // Here we're mapping result to access to showIt key
      result.map((row, index) => {
        if (!row[0].showIt) {
          mainRows[index].style.display = "none";
        } else {
          // hide arrow when main row without children is shown
          let arrow = mainRows[index].querySelector(".c-table-dropdown__icon-arrow");
          arrow.style.display = "none";
        }
      });
    }
  };

  const handleSearchResult = (result, isFiltered) => {
    setIsSeachFilterd(isFiltered);
    closeAllRows();

    // paint new rows
    setDataTable((prevDataTable) => ({ ...prevDataTable, rows: result }));

    // reset All arrows
    showAllArrows();

    // if isFiltered we gonna delete unnecessary mainRows
    if (isFiltered) {
      filterResult(result);

    }
    else{
      props.clearSearch && props.clearSearch()

      setTimeout(() => {
        let arrows = document.querySelectorAll(".c-table-dropdown__body .c-table-dropdown__icon-arrow")
        arrows.forEach((arrow) => (arrow.style.visibility = "visible"));

      }, 300)
      
    }
  };

  return (
    <div id={"table-dropdown"} className="c-table-dropdown__wrapper" ref={tableWrapperRef}>
      {props.search && (
        <TableDropdownSearcher
          fromSearchText={props.fromSearchText}
          originData={props.dataTable.rows}
          result={(result, isFiltered) => handleSearchResult(result, isFiltered)}
          isDropDown={true}
          isFinancial={props.isFinancial}
        />
      )}
      <div className={tableClasses()} ref={tableRef}>
        <div className="c-table-dropdown__head">
          <div className="c-table-dropdown__head-row">
            {dataTable.headerTag && dataTable.rows && dataTable.rows.length > 0 &&(
              <div
                className="c-table-dropdown__tag"
                style={{
                  width: getWidthHeaderCell().width,
                  right: getWidthHeaderCell().right,
                }}
              >
                {dataTable.extra_data && (
                  <span className="c-table-dropdown__tag__text">
                    {dataTable.extra_data["total_weighted_return"].title}
                  </span>
                )}

                <span
                  className="wrapper-helper height-auto no-margin"
                  onClick={(e) => handleTooltip(e, -1)}
                >
                  <div className="helper-text white">
                    <Icon name="InfoTooltip" noWrapper={true} />
                  </div>
                  {showTooltip === -1 && (
                    <div
                      className="container-tooltip top container-tooltip--no-triangle"
                      onClick={(e) => handleTooltip(e, false)}
                    >
                      <span className="tooltip-title">
                        {dataTable.extra_data["total_weighted_return"].title}
                      </span>
                      <span
                        className="tooltip-text"
                        dangerouslySetInnerHTML={{
                          __html: dataTable.extra_data["total_weighted_return"].tooltip_text,
                        }}
                      ></span>
                    </div>
                  )}
                </span>
              </div>
            )}

            {dataTable.headers && dataTable.rows && dataTable.rows.length > 0 &&
              dataTable.headers.map((header, indexHead) => {
                let tooltipWidth = header.tooltip ? 40 : 0;
                let sortWidth = header.sort ? 24 : 0;

                if (header.key !== "url") {
                  return (
                    <div
                      key={`${indexHead}-indexHead-table`}
                      className={headerCells(indexHead, header.tag)}
                      style={{
                        paddingLeft: (tooltipWidth + sortWidth) / 2,
                        paddingRight: (tooltipWidth + sortWidth) / 2,
                      }}
                      ref={headerCell}
                    >
                      <div
                        className={cellHeaderTextClasses(header.tooltip)}
                        style={{ maxWidth: `calc(100% - (${tooltipWidth}px + ${sortWidth}px))` }}
                      >
                        <span>{header.title}</span>
                      </div>

                      <div className="c-table__head-actions">
                        {header.tooltip && (
                          <span
                            className="wrapper-helper no-margin"
                            style={{ minWidth: 22, marginLeft: 4 }}
                            onClick={(e) => handleTooltip(e, indexHead)}
                          >
                            <div className="helper-text white">
                              <Icon name="InfoTooltip" noWrapper={true} />
                            </div>
                            {showTooltip === indexHead && (
                              <div
                                className="container-tooltip top container-tooltip--no-triangle"
                                onClick={(e) => handleTooltip(e, false)}
                              >
                                <span className="tooltip-title">{header.title}</span>
                                <span className="tooltip-text">{header.tooltip_text}</span>
                              </div>
                            )}
                          </span>
                        )}

                        {header.sort && (
                          <div className="c-table__head-sort">
                            <div
                              className="c-table__head-sort--up"
                              onClick={(e) => alert("Sorting up...")}
                            >
                              <Icon name="SingleArrow" orientation="up" noWrapper={true} />
                            </div>
                            <div
                              className="c-table__head-sort--down"
                              onClick={(e) => alert("Sorting down...")}
                            >
                              <Icon name="SingleArrow" orientation="down" noWrapper={true} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );

                }

              })}

            {props.action && props.action.title && props.isStatement && dataTable.rows && dataTable.rows.length > 0 &&(
              <div className={headerCells()} ref={headerCell} >
                {/* <span className={cellHeaderTextClasses()}> {action.title} </span> */}
                <span className={cellHeaderTextClasses()}></span>
              </div>
            )}

          </div>
        </div>

        <div className="c-table-dropdown__body">
          {dataTable.rows &&
            dataTable.rows.map((row, indexRows) => {
              if (isHighlighted().includes(indexRows)) {
                rowIndex = indexRows;
              }

              return (
                <div
                  onClick={(e) => handleSecurity(e, row[0].entity_id)}
                  key={`${indexRows}-indexHead-custodian`}
                  className={rowClasses(indexRows, row[0].value, row[0].entity_id)}
                  ref={(el) => (rowRef.current[indexRows] = el)}
                  data-has-parent={ !isHighlighted().includes(indexRows) && !isTotals(indexRows) ? rowIndex : null }
                  data-is-parent={isHighlighted().includes(indexRows) ? indexRows : null}
                  style={props.onClickSecurity ? {cursor: 'pointer'} : {}}
                >
                  {renderCells(row, indexRows)}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TableDropdown;
