// Dependencies
import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";

// Actions
import { getDocIndex, getDocSearch, getDocDetail} from "../../actions/documents";
import {
  getContact,
  destroyContactAsset,
  destroyAssignAssetByResourceable
} from "../../actions/contact";
import {destroyAsset, createAssetResource } from "../../actions/balance";
import { destroyImageAsset, createImageAsset } from "../../actions/image";

import { canManage, canRead } from "../../helpers/permits";

// Components
import ContentBlock from "../../components/contentBlock";
import NestedList from "../../components/nestedList";
import Search from "../../components/search";
import SectionList from "../../components/sectionList";
import AssetsResourcesModal from "../../screens/balance/details/assetsResourcesModal";
import Modal from "../../components/modal";
import AlertModal from "../../components/alertModal";
import StackHeader from "../../components/stackHeader";
import Icon from "../../components/icons";
import EmptySearch from "../../components/emptySearch";
import NewNestedList from "../../components/newNestedList";

const slider_settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};
class DocumentsIndexScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isSearching: false,
      search_result: [],
      tempData: {},
      formData: {},
      resources: [],
      firstImages: [],      
      url_contact: "",
      showModal: false, 
      modalData: [],
      imagesToZoom: [],
      deletedAssets: [],
      updatedSuccess: false,
      text: "",
      title: "",
      modal_title: "",
      modal_body: "",
      modal_url: "",
      modal_action: undefined,
      destoyedSuccess: false,
      textSearch : false
    };
  }

  _success(title) {
    this.setState({
      loading: false,
      search_result: [],
      title: title
    });
  }

  _error() {
    alert("error");
  }

  componentDidMount() {
    this._fetch()
  };

  _fetch(){
    this.props.getDocIndex(
      this.props.authentication_token,
      (title) => this._success(title),
      () => this._error()
    );
  }

  _action(id, url, type){

      this.props.history.push("/documents-show?url=" + url, {url: url, id: id, type: type})
  }

 _search(text) {

    if(text.length >=3){
      this.props.getDocSearch(this.props.authentication_token, text, (response) => this._successSearch(response,text), () => this._errorSearch())
    }else {
      this.setState({
        search_result: []
      })
    }
    this.setState({isSearching: text.length > 3, text: text })
  } 

  _errorSearch(){
    this.setState({loading: false, isSearching: false, search_result:[]})
  }

  _successSearch(responseJSON,text){
    this.setState({updatedSuccess:false, loading: false, isSearching: true, search_result: responseJSON })
    this.setState({textSearch: text})
    // this.matchedItems = [{ title: "Búsqueda", data: this.matchedItems }];
  }  

  _showContactSuccess(responseJSON, url) {
    this.setState({
      url_contact: url,
      firstImages: {
        images: responseJSON.form.edit_contact.inputs.filter(
          e => e.id === "image"
        )
      },
      formData: {
        images: responseJSON.form.edit_contact.inputs.filter(
          e => e.id === "image"
        )
      },
      showModal: true,
      resources: responseJSON.assets,
      modalData: {
        form: responseJSON.form.edit_contact,
        resource_key: responseJSON.form.edit_contact.resource_key,
        url: url,
        method: responseJSON.actions[0].method,
        code: responseJSON.actions[0].code
      }
    });
  }

  _showContact(id, url, state){
    
    this.props.getContact(
      this.props.authentication_token,
      responseJSON => this._showContactSuccess(responseJSON, url),
      () => alert("error"),
      url
    );    
  }

  _goToResource(navigate_to_resource) {
    let asset = navigate_to_resource
    if(asset.url.indexOf('ownership_structure') !== -1){
      window.location.href = asset.url
    }else {
      let url_asset = asset.url.slice()
      url_asset = url_asset.split("/")
      url_asset.pop()
      url_asset = url_asset.join("/")
      
      this.props.history.push("balance-detail?url="+url_asset+"&id="+asset.resourceable_id, {
        url: url_asset,
        id: asset.resourceable_id
      });    

    }
  }  

  _successCreateAssetResource() {
    this.setState({updatedSuccess: true})
  }

  _errorCreateAssetResource() {
    alert("error");
  }

  _uploadResource(data) {

    let id = this.state.url_contact.split('/')
    this.setState({ showModal: false });
    this.props.createAssetResource(
      this.props.authentication_token,
      () => this._successCreateAssetResource(),
      () => this._errorCreateAssetResource(),
      this.state.modalData.url,
      this.state.modalData.method,
      data
    );

    // this.props.destroyContactAsset(
    //   this.props.authentication_token,
    //   () => this._showContact(-1, this.state.url_contact, this.state),
    //   () => alert("ko"),
    //   this.state.deletedAssets
    // )
    if(this.state.deletedAssets.length > 0){
      if(this.state.modalData.code === 'edit_contact'){
        this.state.deletedAssets.map((item,index) => {
          this.props.destroyAssignAssetByResourceable(
            this.props.authentication_token,
            () => console.log("delete"),
            () => alert("ko delete"),
            id[2],
            item.resourceable_id,
            item.resourceable_type, 
            '/balance_principal/contacts_assets/',
            'contact_id'
          );
        })
      }else {
        //authentication_token, success, error, id, resourceable_id, resourceable_type, url, type
        this.state.deletedAssets.map((item,index) => {

          this.props.destroyAssignAssetByResourceable(
            this.props.authentication_token,
            () => console.log("delete"),
            () => alert("ko delete"),
            id[2],
            item.resourceable_id,
            item.resourceable_type,
            "/documents/documents_assets/",
            "document_id"
          );
        })
      }
      
    }

  }

  _showImage(item){
    this.setState({imagesToZoom: [item]})
  }

  _successDetailDoc(responseJSON, url){
    
    this.setState({
      url_contact: url,
      showModal: true,
      resources: responseJSON.assets,
      modalData: {
        form: responseJSON.form.edit_document.inputs,
        resource_key: responseJSON.form.edit_document.resource_key,
        url: url,
        method: responseJSON.actions[0].method,
        code: responseJSON.actions[0].code
      }
    });
  }

  
  _showDocument(url) {

    this.props.getDocDetail(
      this.props.authentication_token,
      responseJSON => this._successDetailDoc(responseJSON, url),
      () => this._error(),
      url
    );
  }

  _destroyAssets(url, state){
    let allAssets = state.resources
    let deletedAssets = state.deletedAssets

    allAssets = allAssets.filter(deleted => deleted.resourceable_id !== url.resourceable_id)

    deletedAssets.push(url)

    this.setState({
      resources: allAssets,
      deletedAssets: deletedAssets
    })

  }

  _destroyAssetAssociated(modalData){
    
    this.setState({
      showDeleteModal: true,
      modal_title: this.props.internalization.modals[modalData.resource_key].delete.title,
      modal_body: this.props.internalization.modals[modalData.resource_key].delete.body,
      modal_url: modalData,
      modal_action: () => this._successDestroyAssetAssociated(modalData)
    });

  }

  _successDestroyAssetAssociated(modalData){
    
    this.setState({destoyedSuccess: true,
      modal_title: this.props.internalization.modals[modalData.resource_key].deleted.title,
      modal_body: this.props.internalization.modals[modalData.resource_key].deleted.body,
      modal_action: () => this._fetch()
    })

  } 

  deleteItemSearch(modalData){
    
   let allResults = this.state.search_result
   allResults.map((item) => 
     {
      item.data.map((elem,index) => {
        if(elem.url === modalData.url){
          item["data"] = item.data.filter(i => i.url !== modalData.url)
        }
      })
      }
    )
      this.setState({search_result: allResults})
  }
  
  render() {

    let descendats_not_deleted = this.props.documents_index && this.props.documents_index.length > 0 ? this.props.documents_index[0].descendants.filter(item => item.id !== 'deleted') : []
    let descendats_deleted = this.props.documents_index && this.props.documents_index.length > 0  ? this.props.documents_index[0].descendants.filter(item => item.id == 'deleted') : []

    return (
      <div className="main-wrapper">
            <StackHeader
              title={this.state.title}
              icon={<Icon name="FolderBig" iconBig={true} color={"#005774"} />}
              help={false}
              //helpAction={() => alert("boton de info del stackHeader")}
            />
        <Search
          colorGrey={true}
          iconLeft="with-icon-left"
          iconRight={false}
          placeholder={this.props.internalization.buttons.search}
          action={() => this._actionTags()}
          getValue={(value) => this._search(value)}
        />

        
          { !this.state.isSearching && this.props.documents_index.length > 0 && (

            <NewNestedList can_manage={canManage(this.props.permits, "all_assets")} action={(id, url, type) => this._action(id, url, type)} infoDocuments={true} color={'nestedlist__item--grey'} data={descendats_not_deleted}
            square={''}
            />
          )}  

          {
            !this.state.isSearching && this.props.documents_index.length > 0 &&
            descendats_deleted.length > 0 &&
            <NewNestedList can_manage={canManage(this.props.permits, "all_assets")} action={(id, url, type) => this._action(id, url, type)} infoDocuments={true} color={'nestedlist__item--grey'} data={descendats_deleted} mainClass={"nestedlist--top"} square={''}/>
          }      
      
        
        {
            this.state.search_result.length > 0 && this.state.isSearching &&
            <ul className="section-list-list">
              <SectionList
                data={this.state.search_result
                }
                modal={true}
                location={this.props.location}
                actionContact={item => {
                  // this._showContact(item.id, item.url, this.state);
                }}
                actionContent={item => {
                  
                  item.model_class == "contact" ? this._showContact(item.id, item.url, this.state) :
                  item.model_class == "image" ? this._showImage(item) : this._showDocument(item.url)
                }}
                actionTitle={ () => ""}
                history={this.props.history}
                createImageAsset={""}
                fetch={""}
                authentication_token={this.props.authentication_token}
                type={""}
                searchAll={false}
                isSearch={this.state.textSearch}
              />
            </ul>
          }

          {
            this.state.search_result.length === 0 && this.state.isSearching &&
            <EmptySearch nameIcon="BoxEmpty" text="No data" />
          }
        {this.state.showModal === true && (
          <AssetsResourcesModal
            language={this.props.user.language}
            buttons={this.props.internalization.buttons}
            colorGrey={true}
            data={this.state.modalData}
            errors={this.props.errors}
            closeModal={e => {
              if (
                e.target.className === "modal-wrapper" ||
                (e.target.closest(".modal-header") !== null &&
                  e.target.closest(".modal-header").className.includes("modal-header"))
              ) {
                this.setState({ showModal: false });
              }
            }}
            uploadResource={data => this._uploadResource(data)}
            resources={this.state.resources}
            resourceToSelectDestroy={
              true
            }
            destroyAssetAssociated= {() => this._destroyAssetAssociated(this.state.modalData)}
            resourceDestroyAction={url => this._destroyAssets(url,this.state)}
            deleteImage={(id) => this.props.destroyImageAsset(this.props.authentication_token, () => "", () => "", id)}
            polymorphic={
              "resourceable_"
            }
            goToResource={asset => this._goToResource(asset)}
            showModalSessionExpire={(time_left, diffMins, checkTime) => this.props.showModalSessionExpire(time_left, diffMins, () => checkTime())}
          />
        )}
        {this.state.imagesToZoom.length > 0 && (
          <Modal
            modalType="zoom-images"
            showBackButton={true}
            backAction={e => this.setState({imagesToZoom: []})}
            buttonText={this.props.internalization.buttons.back}
          >
            <Slider {...slider_settings}>
              {this.state.imagesToZoom.map((img, index) => {
                return (
                  <img
                    name={img.id}
                    key={index}
                    className="img-carousel"
                    src={img.urls.medium}
                    alt=""
                  />
                );
              })}
            </Slider>
          </Modal>
        )}
        {this.state.updatedSuccess && (
            <AlertModal
              isShowed={this.state.updatedSuccess}
              title={this.props.internalization.modals[this.state.modalData.resource_key].updated.title}
              msg={this.props.internalization.modals[this.state.modalData.resource_key].updated.body}
              action={() => {
                 this.props.getDocSearch(this.props.authentication_token, this.state.text, (response) => this._successSearch(response), () => this._errorSearch()) 
              }}
              textButton="OK"
            />
          )} 
          {this.state.showDeleteModal && (
            <AlertModal
              isShowed={this.state.showDeleteModal}
              title={this.state.modal_title}
              msg={this.state.modal_body}
              action={() => {
                this.props.destroyAsset(
                  this.props.authentication_token,
                  () => this.state.modal_action(),
                  () => alert("error"),
                  this.state.modal_url.url,
                  "DELETE"
                );
                this.setState({
                  showDeleteModal: false
                });
              }}
              textButton="Yes"
              textButtonAlertColor={true}
              cancelButton={true}
              cancelTextButton="Cancel"
              cancelButtonAlertColor={false}
              actionCancelButton={() => {
                this.setState({
                  showDeleteModal: false
                });
              }}
            />
          )}
          {this.state.destoyedSuccess && (
            <AlertModal
              isShowed={this.state.destoyedSuccess}
              title={this.state.modal_title}
              msg={this.state.modal_body}
              action={() => {
                this.deleteItemSearch(this.state.modal_url)
                this.setState({destoyedSuccess: false, showModal: false})
              }}
              textButton={this.props.internalization.buttons.ok}
            />
          )}       
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    documents_index: state.documents.documents_index,
    authentication_token: state.session.authentication_token,
    internalization: state.session.internalization,
    errors: state.session.errors,
    user: state.session.user,
    permits: state.session.permits,    

  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDocSearch: (authentication_token, text, success, error) => dispatch(getDocSearch(authentication_token, text, success, error)),
    getDocIndex: (authentication_token, success, error) =>
      dispatch(getDocIndex(authentication_token, success, error)),
    getContact: (authentication_token, success, error, url) => dispatch(getContact(authentication_token, success, error, url)),      
    destroyContactAsset: (authentication_token, success, error, url) => dispatch(destroyContactAsset(authentication_token, success, error, url)),
    destroyAssignAssetByResourceable: (authentication_token,success,error,id,resourceable_id,resourceable_type,url,type) => dispatch(destroyAssignAssetByResourceable(authentication_token,success,error,id,resourceable_id,resourceable_type, url,type)),    
    destroyImageAsset: (authentication_token, success, error, id) => dispatch(destroyImageAsset(authentication_token, success, error, id)),
    createAssetResource: (authentication_token,success,error,url,method,attributes) => dispatch(createAssetResource(authentication_token,success,error,url,method,attributes)),
    getDocDetail: (authentication_token, success, error, url) =>
    dispatch(getDocDetail(authentication_token, success, error, url)),
    destroyAsset: (authentication_token, success, error, url, method, force) => dispatch(destroyAsset(authentication_token, success, error, url, method, force))    
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsIndexScreen);
