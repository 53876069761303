// Dependencies
import React from "react";

const PlusThin = ({ width, height, color, bgColor, close }) => {
  return (
    <svg
      width={width ? width : "17px"}
      height={height ? height : "16px"}
      viewBox="0 0 17 16"
      style={ close === true ? {transform:  'rotate(45deg)'}: {transform:  'rotate(0deg)'}}
    >
      
      <g id="Files_v0.1-(mobile-App)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Desktop-HD" transform="translate(-404.000000, -597.000000)" fill={color ? color : "#80abba"} fillRule="nonzero">
              <g id="01_A_Consolited_Balance-Copy" transform="translate(70.000000, 153.000000)">
                  <g id="screen" transform="translate(7.000000, 5.000000)">
                      <g id="Bulltick_UI">
                          <g id="Group-4" transform="translate(17.000000, 110.000000)">
                              <g id="ContentBlock" transform="translate(0.000000, 133.000000)">
                                  <g id="Item-Copy-3" transform="translate(0.000000, 180.000000)">
                                      <g id="more" transform="translate(309.300000, 16.000000)">
                                          <path d="M16.0999048,7.19997802 L9.70002198,7.19997802 L9.70002198,0.799948721 C9.70002198,0.358446181 9.3415758,0 8.89992674,0 C8.4584242,0 8.09997802,0.358446181 8.09997802,0.799948721 L8.09997802,7.19997802 L1.69994872,7.19997802 C1.25844618,7.19997802 0.9,7.5584242 0.9,7.99992674 C0.9,8.4415758 1.25844618,8.80002198 1.69994872,8.80002198 L8.09997802,8.80002198 L8.09997802,15.1999048 C8.09997802,15.6415538 8.4584242,16 8.89992674,16 C9.3415758,16 9.70002198,15.6415538 9.70002198,15.1999048 L9.70002198,8.80002198 L16.0999048,8.80002198 C16.5415538,8.80002198 16.9,8.4415758 16.9,7.99992674 C16.9,7.5584242 16.5415538,7.19997802 16.0999048,7.19997802 Z" id="Path"></path>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
  );
};

export default PlusThin;
