// Dependencies
import React from "react";
import { connect } from "react-redux";
import { Treant } from "treant-js";

// Actions
import { getOwnershipEdit, getElementDetail } from "../../actions/ownership";
import { createAssetResource, destroyAsset, editAsset } from "../../actions/balance";

import {
  getContact,
  destroyAssignAssetByResourceable,
} from "../../actions/contact";
import {paginateTab} from "../../actions/tabs_pagination";
import { getDocDetail } from "../../actions/documents";
import { getAlert } from "../../actions/alerts";
import { deleteLinkedResource } from "../../actions/linked_resources";
import { getLinkForm, getConvertedValue} from "../../actions/formblock";
import {setEnabledAssignContacts, setEnabledAssignDocuments} from "../../actions/session";

// Components
import Icon from "../../components/icons";
import ActionSheet from "../../components/actionSheet";
import FloatButton from "../../components/floatButton";
import AssetsResourcesModal from "../../screens/balance/details/assetsResourcesModal";
import AlertModal from "../../components/alertModal";
import Modal from "../../components/modal";
import ViewList from "../../components/viewList";
import { canManage } from "../../helpers/permits";
import DetailTypeOne from "../balance/details/detailTypeOne";

// Helpers
import ActionSheetOnPressAction from "../../helpers/actionSheetOnPressAction";


class OwnershipShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ownership_show: [],
      options_action_sheet: [],
      showModal: false,
      showSuccesModal: false,
      delete_object: {
        url: "",
        method: "",
      },
      showDeleteModal: false,
      showModalAddContact: false,
      assignData: {},
      showNavigateModal: false,
      asset: {},
      deletedAssets: [],
      destoyedSuccess: false,
      modal_title: "",
      modal_body: "",
      modal_url: "",
      modal_action: "",
      showAlertAsk: false,
      linkedDestroyModal: false,
      tab_index: 0,
      ownershipLinked: false,
      ownershipLinkedResource: {},
      ownershipLinkedAccount: false
    };
    this.actionSheet = React.createRef();
    this.chart = "";
  }


  _success(responseJSON) {
    this.setState({
      loading: false,
      ownership_show: responseJSON.ownership,
    });

    this.props.getBreadcrumbs({
      assetUrl: "/ownership_show",
      url: "/ownership_show",
      assetTitle: responseJSON.ownership.breadcrumb_title,
    });
  }

  _error() {}

  _getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
  }

  _successFetch(responseJSON, set_tab_key) {
    this.setState({
      ownership_show: responseJSON.ownership,
      destoyedSuccess: false,
      deletedAssets: [],
      showModal: false,
      showDeleteModal: false,
      modalData: {},
      showSuccesModal: false,
    });    
    this.props.getBreadcrumbs({
      assetUrl: "/ownership_show",
      url: "/ownership_show",
      assetTitle: responseJSON.ownership.breadcrumb_title,
    });
    let code_selected = this._getUrlVars()["code"]
    if(set_tab_key === undefined && code_selected !== undefined){
      set_tab_key = code_selected
      this.props.location.search = this.props.location.search.replace("&code="+code_selected, "")
      window.history.replaceState({}, "bt360", this.props.location.pathname + this.props.location.search)
    }
    
    if(set_tab_key !== undefined){
      let to_go = 0
      if(set_tab_key === "contact" || set_tab_key === "contacts"){
        to_go = this._getTabIndexFromCode('contacts')
      }
      if(set_tab_key === "alert" || set_tab_key === "alerts"){
        to_go = this._getTabIndexFromCode('alerts')
      }
      if(set_tab_key === "document" || set_tab_key === "documents"){
        to_go = this._getTabIndexFromCode('documents')
      }
      if(set_tab_key === "tenant" || set_tab_key === "tenants"){
        to_go = this._getTabIndexFromCode('tenants')
      }
      if(set_tab_key === "image" || set_tab_key === "images"){
        to_go = this._getTabIndexFromCode('images')
        // if(this._differType()){
        //  
        // }else{
        //   to_go = this._getTabIndexFromCode('main')  
        // }
        
      }
      
      if(set_tab_key === "stake_holder" || set_tab_key === "stake_holders"){
        to_go = this._getTabIndexFromCode('stake_holders')
      }
      if(set_tab_key === "linked"){
        to_go = this._getTabIndexFromCode('linked')
      }
      this.setState({tab_index: to_go})
    }

  }

  _getTabIndexFromCode(code) {
    return this.state.ownership_show.tabs.findIndex((tab) => tab.code === code)
  }

  _fetch(set_tab_key = undefined){
    this.props.getOwnershipEdit(
      this.props.authentication_token,
      this.props.location.pathname,
      (responseJSON) => this._successFetch(responseJSON, set_tab_key),
      () => this._error()
    );
  }
  componentDidMount() {
    this.props.getOwnershipEdit(
      this.props.authentication_token,
      this.props.location.pathname,
      (responseJSON) => this._successFetch(responseJSON, undefined),
      () => this._error()
    );
  }

  _actionSheetPressed(item, state) {
    let response = ActionSheetOnPressAction(
      item,
      state.ownership_show,
      this.props,
      () =>
        item.code.indexOf("edit_") !== -1
          ? this._edit()
          : this._deleteAsset(item.url, item.method)
    );

    this.actionSheet.current.show();

    if (response.status)
      this.setState({ showModal: true, modalData: response.modalData });
  }

  _deleteAsset(url, method) {
    this.setState({
      showDeleteModal: true,
      modal_title: this.props.internalization.modals.ownership.delete.title,
      modal_body: this.props.internalization.modals.ownership.delete.body,
      delete_object: {
        url: url,
        method: method,
      },
      modal_action: () => this._successDeleteAsset(),
    });
  }

  _getActionSheetMethod(item, state) {
    switch (item.code) {
      case "add_images":
        return () => {
          this.setState({ loadImage: true }, this.inputNode.click());
          this.actionSheet.current.show();
        };
      case "add_document":
        return () => {
          this._openDocumentsOptions(item, state);
        };
      case "add_contact":
        return () => {
          this._openContactsOptions(item, state);
        };
      case "add_alerts":
        return () => {
          this._actionSheetPressed(item, state);
        };
        case "link_account": {
          return () => this._openLinkAccount(item, state)
        }        
      default:
        return () => {
          this._actionSheetPressed(item, state);
        };
    }
  }

    _openLinkAccount(item, state) {
    this.actionSheet.current.show();
    this.setState({
      showModalAddContact: true,
        assignData: {
          type:'account',
          action_to_refresh: true,
          url_from: this.props.history.location.state ? this.props.history.location.state.url : "",
          id_from : this.props.history.location.state && this.props.history.location.state.id !== undefined
                ? this.props.history.location.state.id
                : "",
          url: "ownership_structure/accounts",
          id: "account",
          resourceable_id: this.state.ownership_show.identifier.resourceable_id,
          resourceable_type: this.state.ownership_show.identifier.resourceable_type,
          location: this.props.location,
          backAction: () => this.setState({showModalAddContact: false}),
          fetch: () => this._fetch(),
          assigned_contacts: this._getAssignedIdsFromDetailTabs("accounts"),
          messageAlert: "Go back",
          modal: true,
          special_type: true,
          specialAssign: (saveIds,type, data, interacted_elements) => this._linkOwnership(saveIds, 'accounts', this._getAssignedIdsFromDetailTabs("accounts"), interacted_elements)
          
        }
      })
    }

  _getAssignedIdsFromDetailTabsLinked(class_name, type){
    if(this.state.ownership_show && this.state.ownership_show.tablesv2 !== undefined && this.state.ownership_show.tablesv2[type] !== undefined){
      try{
        let rows = this.state.ownership_show.tablesv2[type].rows
        return rows.map(r => r.filter(e => e.key == "class_name")[0].value === class_name && r.filter(e => e.key == "id")[0].value).filter(e => e !== false) 
      }catch(e){
        return []
      }
      
    }else{
      return []
    }
  }   
  
  _linkOwnership(ids, type, previously_assigned, interacted_elements) {    
    this.props.editAsset(
        this.props.authentication_token,
        (responseJSON) => { this._successFetch(responseJSON.data, "accounts"); this.setState({showModalAddContact: false, ownershipLinkedAccount: true})},
        (responseJSON) => this._error(responseJSON),
        this.state.ownership_show.identifier.url+""+this.state.ownership_show.identifier.resourceable_id,
        "PUT",
        {[""+this.state.ownership_show.form.edit.resource_key]: {"linked_existent":{[type]:interacted_elements}}, "id":this.state.ownership_show.identifier.resourceable_id}
      );
    // 

  }   
  _getAssignedIdsFromDetailTabs(type){
    if(this.state.ownership_show && this.state.ownership_show.tablesv2 !== undefined && this.state.ownership_show.tablesv2[type] !== undefined){
      try{
        return this.state.ownership_show.tablesv2[type].all_ids
      }catch(e){
        return []
      }
      
    }else{
      return []
    }
  }
  _openDocumentsOptions(item, state) {
    var that = this;
    let optionsActionSheet = [];
    optionsActionSheet.push({
      type: "icon button",
      iconName: item.code.includes("add")
        ? "Close"
        : item.code.includes("edit")
        ? "Pencil"
        : item.code.includes("show")
        ? "Eye"
        : item.code.includes("link")
        ? "LinkChain"
        : "Garbage",
      message: item.title,
      onClick: () => this._actionSheetPressed(item, state),
    });
    this.props.enabled_assign_documents &&
      optionsActionSheet.push({
        type: "icon button",
        message: this.props.internalization.buttons.assign_document,
        iconName: "Assign",
        onClick: () => {
          this.actionSheet.current.show();
          this.setState({
            showModalAddContact: true,
            assignData: {
              type: "document",
              action_to_refresh: true,
              url_from: this.props.location.pathname,
              id_from: "",
              url:
                "documents/documents?section_list=true&resourceable_type=" +
                this.state.ownership_show.identifier.resourceable_type,
              id: "document",
              resourceable_id: this.state.ownership_show.identifier
                .resourceable_id,
              resourceable_type: this.state.ownership_show.identifier
                .resourceable_type,
              location: this.props.location,
              backAction: () => this.setState({ showModalAddContact: false }),
              fetch: () => this._fetch('document'),
              assigned_contacts: this._getAssignedIdsFromDetailTabs("documents"),
              messageAlert: "Back to Real State",
              modal: true,
            },
          });
        },
      });

    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true,
          onClick: () => {
            this._showActionSheetContent(state);
          },
        },
      ],
      loadingImages: false,
      showModal: false,
    });
  }

  _openContactsOptions(item, state) {
    var that = this;
    let optionsActionSheet = [];
    optionsActionSheet.push({
      type: "icon button",
      iconName: item.code.includes("add")
        ? "Close"
        : item.code.includes("edit")
        ? "Pencil"
        : item.code.includes("show")
        ? "Eye"
        : "Garbage",
      message: item.title,
      onClick: () => this._actionSheetPressed(item, state),
    });
    this.props.enabled_assign_contacts &&
      optionsActionSheet.push({
        type: "icon button",
        message: this.props.internalization.buttons.assign_contact,
        iconName: "Assign",
        onClick: () => {
          this.actionSheet.current.show();
          this.setState({
            showModalAddContact: true,
            assignData: {
              type: "contact",
              action_to_refresh: true,
              url_from: this.props.location.pathname,
              id_from: "",
              url: "balance_principal/contacts",
              id: "contact",
              resourceable_id: this.state.ownership_show.identifier
                .resourceable_id,
              resourceable_type: this.state.ownership_show.identifier
                .resourceable_type,
              location: this.props.location,
              backAction: () => this.setState({ showModalAddContact: false }),
              fetch: () => this._fetch('contact'),
              assigned_contacts: this._getAssignedIdsFromDetailTabs("contacts"),
              messageAlert: "Go back",
              modal: true,
            },
          });
        },
      });

    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true,
          onClick: () => {
            this._showActionSheetContent(state);
          },
        },
      ],
      loadingImages: false,
      showModal: false,
    });
  }

  _showActionSheetContent(state) {
    let that = this;
    let optionsActionSheet = [];
    this.state.ownership_show.actions.map((item) => {
      return optionsActionSheet.push({
        type: "icon button",
        code: item.code,
        iconName:
          item.code === "add_alerts"
            ? "Notification"
            : item.code.includes("add")
            ? "Close"
            : item.code.includes("edit")
            ? "Pencil"
            : item.code.includes("show")
            ? "Eye"
            : item.code.includes("link")
            ? "LinkChain"
            : "Garbage",
        message: item.title,
        onClick: this._getActionSheetMethod(item, state),
      });
    });

    this.setState({
      options_action_sheet: [
        ...optionsActionSheet,
        {
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true,
        },
      ],
    });

    setTimeout(function () {
      that.actionSheet.current.show();
    }, 100);
  }

  _successCreateAssetResource(edit, key, responseJson) {
    if (key == "stake_holder") {
      this.setState({
        modal_title: this.props.internalization.modals.ownership
          .added_stake_holder.title,
        modal_body: this.props.internalization.modals.ownership
          .added_stake_holder.body,
        showSuccesModal: true,
        loading: false,
      });
    } else if (["contact", "document", "alert"].includes(key)) {

      if(key === "contact"){
        this.props.setEnabledAssignContacts()
      }
  
      if(key === "document"){
        this.props.setEnabledAssignDocuments()
      }

      this.setState({
        modal_title: edit
          ? this.props.internalization.modals[key].updated.title
          : this.props.internalization.modals[key].created.title,
        modal_body: edit
          ? this.props.internalization.modals[key].updated.body
          : this.props.internalization.modals[key].created.body,
        showSuccesModal: true,
        modal_key: 'linked',
        loading: false,
      });
    } else {
      
      let is_ownership_linked =  false
      try{
        is_ownership_linked = responseJson.data.ownership.is_ownership_linked
      }catch(e){
        is_ownership_linked = false
      }
      
      //FAlta decidir si hay que mostrar esto o no. 
      
        this.setState({
          modal_title: edit
            ? this.props.internalization.modals.ownership.updated.title
            : this.props.internalization.modals.ownership.created.title,
          modal_body: edit
            ? this.props.internalization.modals.ownership.updated.body
            : this.props.internalization.modals.ownership.created.body,
          showSuccesModal: true,
          modal_key: is_ownership_linked ? "linked" : "main",
          loading: false,
        });  
      
      
    }
  }

  _errorCreateAssetResource() {
    alert("error");
  }

  _uploadResource(data) {
    let promises = [];

    this.setState({ showModal: false });

    if (this.state.deletedAssets.length > 0) {
      if (this.state.modalData.code === "edit_contact") {
        this.state.deletedAssets.map((item, index) => {
          let promiseMap = new Promise((resolve, reject) => {
            this.props.destroyAssignAssetByResourceable(
              this.props.authentication_token,
              () => {
                resolve();
              },
              () => {
                reject();
              },
              this.state.modalData.id,
              item.resourceable_id,
              item.resourceable_type,
              "/balance_principal/contacts_assets/",
              "contact_id"
            );
          });
          promises.push(promiseMap);
        });
        Promise.all(promises).then((completed) => this._fetch());
      } else {
        this.state.deletedAssets.map((item, index) => {
          let promiseMap = new Promise((resolve, reject) => {
            this.props.destroyAssignAssetByResourceable(
              this.props.authentication_token,
              () => resolve(),
              () => reject(),
              this.state.modalData.id,
              item.resourceable_id,
              item.resourceable_type,
              "/documents/documents_assets/",
              "document_id"
            );
          });
          promises.push(promiseMap);
        });
        Promise.all(promises).then((completed) => this._fetch());
      }
    }

    this.props.createAssetResource(
      this.props.authentication_token,
      (responseJson) =>
        this._successCreateAssetResource(
          this.state.modalData.method === "PUT",
          this.state.modalData.resource_key,
          responseJson
        ),
      () => this._errorCreateAssetResource(),
      this.state.modalData.url,
      this.state.modalData.method,
      data
    );
  }

  _showAlert(id, edit_mode = false) {
    this.props.getAlert(
      this.props.authentication_token,
      id,
      (responseJSON) => this._successAlert(responseJSON, edit_mode),
      () => alert("error")
    );
  }

  _successAlert(responseJSON, edit_mode) {
    this.setState({
      showModal: true,
      modalData: {
        idMain: this.state.ownership_show.identifier.id,
        id: responseJSON.bc_show.identifier.id,
        form: responseJSON.bc_show.form.edit_alert.inputs,
        can_manage: responseJSON.bc_show.can_manage,
        resource_key: responseJSON.bc_show.form.edit_alert.resource_key,
        url: /alerts/ + responseJSON.bc_show.identifier.id,
        title: responseJSON.bc_show.title,
        show_title: responseJSON.bc_show.show_title,
        edit_title: responseJSON.bc_show.edit_title,
        method: "PUT",
        code: "edit_alert",
        edit_mode: edit_mode
      },
    });
  }

  _showContactSuccess(responseJSON, url, idMain = undefined, edit_mode = false) {
    this.setState({
      url_contact: url,
      firstImages: {
        images: responseJSON.form.edit_contact.inputs.filter(
          (e) => e.id === "image"
        ),
      },
      formData: {
        images: responseJSON.form.edit_contact.inputs.filter(
          (e) => e.id === "image"
        ),
      },
      showModal: true,
      resources: responseJSON.assets,
      modalData: {
        idMain: idMain !== undefined ? idMain : this.state.ownership_show.identifier.id,        
        id: responseJSON.identifier.id,
        form: responseJSON.form.edit_contact,
        can_manage: responseJSON.form.edit_contact.can_manage,
        resource_key: responseJSON.form.edit_contact.resource_key,
        title: responseJSON.title,
        show_title: responseJSON.show_title,
        edit_title: responseJSON.edit_title,
        url: url,
        method: "PUT",
        code: "edit_contact",
        edit_mode: edit_mode
      },
    });
  }

  _showContact(url, idMain = undefined, modalData = undefined, edit_mode = false) {
    let resourceable_id = this.state.ownership_show.identifier.resourceable_id
    let resourceable_type = this.state.ownership_show.identifier.resourceable_type
    if(modalData !== undefined && modalData && Object.keys(modalData).length > 0){
      resourceable_id = modalData.idMain
      resourceable_type = modalData.resource_type
    }

    this.props.getContact(
      this.props.authentication_token,
      (responseJSON) => this._showContactSuccess(responseJSON, url, idMain, edit_mode),
      () => alert("error"),
      url +
        "?resourceable_id=" +
        resourceable_id +
        "&resourceable_type=" +
        resourceable_type
    );
  }

  _successDetailDoc(responseJSON, url, edit_mode) {
    this.setState({
      url_contact: url,
      showModal: true,
      resources: responseJSON.assets,
      modalData: {
        idMain: this.state.ownership_show.identifier.id,
        id: responseJSON.identifier.id,
        can_manage: responseJSON.form.edit_document.can_manage,
        form: responseJSON.form.edit_document.inputs,
        resource_key: responseJSON.form.edit_document.resource_key,
        title: responseJSON.title,
        show_title: responseJSON.show_title,
        edit_title: responseJSON.edit_title,
        url: url,
        method: responseJSON.actions[0].method,
        code: responseJSON.actions[0].code,
        edit_mode: edit_mode
      },
    });
  }

  _showDocument(url, modalData = undefined, edit_mode = false) {
    let resourceable_id = this.state.ownership_show.identifier.resourceable_id
    let resourceable_type = this.state.ownership_show.identifier.resourceable_type
    if(modalData !== undefined && modalData && Object.keys(modalData).length > 0){
      resourceable_id = modalData.idMain
      resourceable_type = modalData.resource_type
    }
    this.props.getDocDetail(
      this.props.authentication_token,
      (responseJSON) => this._successDetailDoc(responseJSON, url, edit_mode),
      () => this._error(),
      url +
        "?resourceable_id=" +
        resourceable_id +
        "&resourceable_type=" +
        resourceable_type
    );
  }

  _showStakeHolder(url, edit_mode = false) {

    this.props.getElementDetail(
      this.props.authentication_token,
      url,
      (responseJSON) => this._successElementDetail(responseJSON, edit_mode),
      () => this._error()
    );
  }

  _successElementDetail(responseJSON, edit_mode = false) {
    let modalData = {
      edit_title: responseJSON.ownership.actions[0].title,
      show_title: true,
      resource_type: responseJSON.ownership.identifier.resourceable_type,
      idMain: responseJSON.ownership.identifier.id,
      form: responseJSON.ownership.form.edit.inputs,
      resource_key: responseJSON.ownership.form.edit.resource_key,
      url:
        responseJSON.ownership.identifier.url +
        "/" +
        responseJSON.ownership.identifier.id,
      method: responseJSON.ownership.actions[0].method,
      code: responseJSON.ownership.actions[0].code,
      can_manage: responseJSON.ownership.form.edit.can_manage,
      code: "edit_stake_holder",
      documents: responseJSON.ownership.documents,
      contacts: responseJSON.ownership.contacts,
      edit_mode: edit_mode,
      associated_elements: [{add_document: responseJSON.ownership.form.add_document}, {add_contact: responseJSON.ownership.form.add_contact}]      
    };
    this.setState({ showModal: true, modalData: modalData });
  }

  _successDeleteAsset(url) {
    this.setState({
      destoyedSuccess: true,
      modal_title: this.props.internalization.modals.ownership.deleted.title,
      modal_body: this.props.internalization.modals.ownership.deleted.body,
      modal_action: () => this.props.history.replace("/ownership-index"),
    });
  }

  _edit() {
    let modalData = {
      form: this.state.ownership_show.form.edit.inputs,
      resource_key: this.state.ownership_show.form.edit.resource_key,
      url:
        this.state.ownership_show.identifier.url +
        "/" +
        this.state.ownership_show.identifier.id,
      method: "PUT",
      code: "edit_ownership",
    };
    this.setState({ showModal: true, modalData: modalData });
  }

  _typeSelect(elem) {
    let selected = elem.options.filter((e) => "" + e.id === "" + elem.value);
    if (selected.length > 0) {
      if (selected[0].value === undefined) {
        return "Select again";
      } else {
        return selected[0].value;
      }
    }
  }

  _typeRadio(elem) {
    if (elem.value === true) {
      return this.props.internalization.buttons.afirmative;
    } else {
      return this.props.internalization.buttons.negative;
    }
  }

  _goToResource(asset) {
    this.setState({
      showNavigateModal: true,
      asset: asset,
    });
  }

  _destroyAssets(url, state) {
    let allAssets = state.resources;
    let deletedAssets = state.deletedAssets;

    allAssets = allAssets.filter(
      (deleted) => deleted.resourceable_id !== url.resourceable_id
    );

    deletedAssets.push(url);
    this.setState({
      resources: allAssets,
      deletedAssets: deletedAssets,
    });
  }
  _deleteDocumentSuccess(){
    this.setState({
      showModal: false,
      showDeleteModalDocument: false,
      showDeleteModal: false,
      showSuccesModal: true,
      modal_title: this.props.internalization.modals.document.deleted.title,
      modal_body: this.props.internalization.modals.document.deleted.body,
    });
  }
  _deleteDocument(item, success) {
    
    this.props.destroyAssignAssetByResourceable(
      this.props.authentication_token,
      () => this._deleteDocumentSuccess(),
      () => alert("ko delete"),
      [item],
      this.state.ownership_show.identifier.resourceable_id,
      this.state.ownership_show.identifier.resourceable_type,
      "/documents/documents_assets/",
      "document_id"
    );
  }

  _destroyAssetAssociated(modalData) {
    
    this.setState({
      showDeleteModal: true,
      modal_title: this.props.internalization.modals[modalData.resource_key]
        .delete.title,
      modal_body: this.props.internalization.modals[modalData.resource_key]
        .delete.body,
      modal_url: modalData.url,
      delete_object: {
        url: modalData.url,
        method: "DELETE",
      },
      modal_action: () => this._successDestroyAssetAssociated(modalData),
    });
  }

  _successDestroyAssetAssociated(modalData) {
    this.setState({
      destoyedSuccess: true,
      modal_title: this.props.internalization.modals[modalData.resource_key]
        .deleted.title,
      modal_body: this.props.internalization.modals[modalData.resource_key]
        .deleted.body,
      modal_action: () => this._fetch(modalData.resource_key),
    });
  }

  _navigateToAssetFromLinkedResource(item) {
    if (item.url.collection.indexOf("ownership_structure") !== -1) {
      window.location.href = item.url;
    } else {
      let url_temp = item.url.collection.replace("private_equities", "direct_investments")
      this.props.history.push(
        "/balance-detail?url=" + item.url.collection + "&id=" + item.id,
        {
          url: item.url.collection,
          id: item.id,
        }
      );
      setTimeout(function () {
        window.location.reload();
      }, 200);
    }
  }

  _navigateToAsset(state){
    let asset = state.asset
    let url = 
      asset.url_recover ? 
        asset.url_recover ? 
          asset.url.collection 
          : asset.url.collection
            : asset.url
    if(url.indexOf('ownership_structure') !== -1){
      window.location.href = asset.url.resource || url
    }else {
      let url_asset = url.slice()
      url_asset = url_asset.split("/")
      url_asset.pop()
      url_asset = url_asset.join("/")
      this.props.history.push("/balance-detail?url="+url_asset+"&id="+asset.resourceable_id, {
        url: url_asset,
        id: asset.resourceable_id
      });
      setTimeout(function(){
        window.location.reload()
      },200)
    }
    
    this.setState({
      showNavigateModal: false,
      showModal: false
    });
  }


  _deleteLinkedResource(item) {
    this.props.deleteLinkedResource(
      this.props.authentication_token,
      this.state.ownership_show.identifier.resourceable_type,
      this.state.ownership_show.identifier.id,
      item.class_name,
      item.id,
      () => this._showSuccessDeleteLinkModal(),
      () => alert("error")
    );
  }

  _showSuccessDeleteLinkModal() {
    this.setState({
      showDeleteModalDocument: false,
      showModal: false,
      showSuccesModal: true,
      linkedDestroyModal: false,
      modal_title: this.props.internalization.modals.linked.deleted.title,
      modal_body: this.props.internalization.modals.linked.deleted.body,
      modal_action: () => this._fetch(),
    });
  }

  _showDeleteLinkModal(item) {
    this.setState({
      showDeleteModalDocument: false,
      showModal: false,
      linkedDestroyModal: true,
      modal_title: this.props.internalization.modals.linked.delete.title,
      modal_body: this.props.internalization.modals.linked.delete.body,
      modal_action: () => this._deleteLinkedResource(item),
    });
  }

  paginateTab(params, success, error) {
    params["resourceable_id"] = this.state.ownership_show.identifier.resourceable_id
    params["resourceable_type"] = this.state.ownership_show.identifier.resourceable_type
    this.props.paginateTab(this.props.authentication_token, params, (responseJSON) => {
      console.log("re", responseJSON)
      let current_show = this.state.ownership_show
      if(Object.keys(responseJSON.tables).length === 0){
        current_show.tablesv2[params["source"]]["rows"] = []
      }else{
        current_show.tablesv2 = {...current_show.tablesv2, ...responseJSON.tables}  
      }
      
      console.log("current_show", current_show)
      this.setState({ownership_show: current_show})
    }, () => error(), false)
  }

  _unlinkContact(contact){
    this.setState({
      showUnlink: true,
      modal_title: this.props.internalization.modals['contact'].unlink.title,
      modal_body: this.props.internalization.modals['contact'].unlink.body,
      modal_action: () => this.props.destroyAssignAssetByResourceable(
              this.props.authentication_token,
              () => {this.setState({
                destoyedSuccess: true,
                showUnlink:false,  
                modal_title: this.props.internalization.modals['contact'].unlinked.title,
                modal_body: this.props.internalization.modals['contact'].unlinked.body,
                modal_action: () => {this._fetch('contact')}

              })},
              () => {alert("Something went wrong")},
              contact.id,
              this.state.ownership_show.identifier
                .resourceable_id,
              this.state.ownership_show.identifier
                .resourceable_type, 
              '/balance_principal/contacts_assets/',
              'contact_id'
            )
    })
  }


  render() {
    console.log("ownership show", this.state.ownership_show)
    return (
      <div className="main-wrapper ownership-show">
        {/* <div className="wrapper-title o-display--flex o-display--flex-justify-center o-display--flex-align-center">
          {<Icon name="Structure" iconBig={true} />}
          <span className="headingH1">
            {Object.keys(this.state.ownership_show).length > 0 &&
              this.state.ownership_show.section_titles.title_head +
                " / " +
                this.state.ownership_show.title}
          </span>
        </div> */}

        {Object.keys(this.state.ownership_show).length > 0 && (
          <DetailTypeOne
            screen_step_index={this.state.tab_index}
            handleTabChange={(index) => this.setState({tab_index: index})} 
            location={this.props.location}         
            colorGrey={true}
            data={
              Object.keys(this.state.ownership_show).length > 0
                ? this.state.ownership_show
                : []
            }
            errors={this.props.errors}
            showContact={(url, edit_mode) => this._showContact(url, undefined, undefined, edit_mode)}
            unlinkContact={(contact) => this._unlinkContact(contact)}                          
            showDocument={(url, edit_mode) => this._showDocument(url, undefined, edit_mode)}
            showAlert={(id, edit_mode) => this._showAlert(id, edit_mode)}
            showStakeHolder={(url, edit_mode) =>  this._showStakeHolder(url, edit_mode)}
            history={this.props.history}
            internalization={this.props.internalization}
            navigateTo={(item) => this._navigateToAssetFromLinkedResource(item)}
            deleteLinkedResource={(item) => this._showDeleteLinkModal(item)}
            insurance_user={
              this.props.insurance_user == true
                ? true
                : !canManage(this.props.permits, "rest_assets")
            }
            paginateTab={(params,success, error) => this.paginateTab(params, success, error)}                        
          />
        )}

        {this.state.showModal === true && (
          <AssetsResourcesModal
            language={this.props.user.language}
            identifier={this.state.ownership_show.identifier}
            colorGrey={true}
            categorieOptions={this.state.ownership_show.form}
            successSendFiles={() => this._fetch()}
            data={this.state.modalData}
            errors={this.props.errors}
            closeModal={(e) => {
              if (
                e &&
                e.target.className === "modal-wrapper" ||
                e &&
                (e.target.closest(".modal-header") !== null &&
                  e.target.closest(".modal-header").className.includes("modal-header"))
              ) {
                this.setState({ showModal: false, resources: {} });
              }
            }}
            uploadResource={(data) => this._uploadResource(data)}
            resources={this.state.resources}
            goToResource={(asset) => this._goToResource(asset)}
            polymorphic={
              this.state.modalData.resource_key === "contact"
                ? "resourceable_"
                : "ownerable_"
            }
            resourceToSelectDestroy={
              this.state.modalData.resource_key === "contact"
            }
            resourceDestroyAction={(url) =>
              this._destroyAssets(url, this.state)
            }
            deleteDocument={() =>
              this.setState({ showDeleteModalDocument: true })
            }
            destroyAssetAssociated={() =>
              this._destroyAssetAssociated(this.state.modalData)
            }
            buttons={this.props.internalization.buttons}
            showModalSessionExpire={(time_left, diffMins, checkTime) =>
              this.props.showModalSessionExpire(time_left, diffMins, () =>
                checkTime()
              )
            }
            getLinkForm={(url, success_fromblock, error_fromblock) =>
              this.props.getLinkForm(
                url,
                this.props.authentication_token,
                (responseJSON) => success_fromblock(responseJSON),
                () => error_fromblock("error")
              )
            }
            showDocument={url => this._showDocument(url, this.state.modalData)}
            showContact={(url, idMain) => this._showContact(url, idMain, this.state.modalData)}
            getConvertedValue={(value, currency_type, date, success_fromblock, error_fromblock) => this.props.getConvertedValue(value, currency_type, date, this.props.authentication_token, (responseJSON) => success_fromblock(responseJSON),() => error_fromblock("error"))}            
          />
        )}
        {this.state.showNavigateModal && (
          <AlertModal
            isShowed={this.state.showNavigateModal}
            title={this.props.internalization.alerts.navigate_to_asset}
            msg={
              this.props.internalization.alerts.navigate_to +
              " " +
              this.state.asset.name
            }
            action={() => {
              this._navigateToAsset(this.state)
            }}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={false}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={true}
            actionCancelButton={() => {
              this.setState({
                showNavigateModal: false,
              });
            }}
          />
        )}
          {this.state.showUnlink && (
            <AlertModal
              isShowed={this.state.showUnlink}
              title={this.state.modal_title}
              msg={this.state.modal_body}
              action={() => {
                this.state.modal_action()
              }}
              textButton={this.props.internalization.buttons.ok}
              textButtonAlertColor={true}
              cancelButton={true}
              cancelTextButton={this.props.internalization.buttons.cancel}
              cancelButtonAlertColor={false}
              actionCancelButton={() => {
                this.setState({
                  showUnlink: false
                });
              }}
            />
          )}

        {this.state.showSuccesModal && (
          <AlertModal
            isShowed={this.state.showSuccesModal}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.setState({
                showSuccesModal: false,
              });
              this._fetch(this.state.modalData ? this.state.modalData.resource_key : this.state.modal_key ? this.state.modal_key : "");
            }}
            textButton={this.props.internalization.buttons.ok}
          />
        )}
        {this.state.showDeleteModalDocument && (
          <AlertModal
            isShowed={this.state.showDeleteModalDocument}
            title={this.props.internalization.modals.document.delete.title}
            msg={this.props.internalization.modals.document.delete.body}
            action={() => {
                this.setState(
                  {
                    showDeleteModal: false,
                    loading: true,
                  },
                  () =>
                    this._deleteDocument(
                      this.state.modalData.id,
                      this.state.modalData.url
                    )
                );
            }}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={true}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={false}
            actionCancelButton={() => {
              this.setState({
                showDeleteModalDocument: false,
              });
            }}
          />
        )}
        {this.state.destoyedSuccess && (
          <AlertModal
            isShowed={this.state.destoyedSuccess}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.state.modal_action();
            }}
            textButton={this.props.internalization.buttons.ok}
          />
        )}
        {Object.keys(this.state.ownership_show).length > 0 &&
          this.state.ownership_show.actions != undefined &&
          this.state.ownership_show.actions != null &&
          this.state.ownership_show.actions.length > 0 && (
            <>
              <ActionSheet
                ref={this.actionSheet}
                render={this.state.options_action_sheet}
                show={true}
              />
              <FloatButton
                children={
                  <Icon
                    name="Close"
                    color="white"
                    iconBig={true}
                    className="wrapper-icon-floatButton"
                    close={false}
                  />
                }
                action={() => this._showActionSheetContent(this.state)}
              />
            </>
          )}
        {this.state.showDeleteModal && (
          <AlertModal
            isShowed={this.state.showDeleteModal}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.props.destroyAsset(
                this.props.authentication_token,
                () => this.state.modal_action(),
                () => alert("error"),
                this.state.delete_object.url,
                this.state.delete_object.method
              );
              this.setState({
                showDeleteModal: false,
              });
            }}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={true}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={false}
            actionCancelButton={() => {
              this.setState({
                showDeleteModal: false,
              });
            }}
          />
        )}
        {this.state.showModalAddContact && (
          <Modal isShowed={this.state.showModalAddContact}>
            <ViewList assign={true} assignData={this.state.assignData} />
          </Modal>
        )}

        {this.state.showAlertAsk && (
          <AlertModal
            isShowed={this.state.showAlertAsk}
            title={
              this.props.insurance_user
                ? this.props.internalization.alerts.navigate_to_asset
                : this.props.internalization.alerts.navigate_to_or_delete
            }
            // msg={"Mensaje"}
            action={() => {
              this.setState({ showAlertAsk: false });
              this._navigateToAssetFromLinkedResource(
                this.state.linkedResource
              );
            }}
            textButton={this.props.internalization.alerts.navigate_to}
            textButtonAlertColor={false}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={false}
            actionCancelButton={() => this.setState({ showAlertAsk: false })}
            thirdButton={this.props.insurance_user ? false : true}
            textButtonAlertColorThird={true}
            textButtonThird={this.props.internalization.buttons.unlink}
            actionThirdButton={() => {
              this.setState({ showAlertAsk: false });
              this._showDeleteLinkModal(this.state.linkedResource);
            }}
          />
        )}
        {this.state.linkedDestroyModal && (
          <AlertModal
            isShowed={this.state.linkedDestroyModal}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.state.modal_action();
            }}
            textButton={this.props.internalization.buttons.ok}
            textButtonAlertColor={true}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={false}
            actionCancelButton={() => {
              this.setState({
                linkedDestroyModal: false,
                showModal: false,
                showDeleteModal: false,
                destoyedSuccess: false,
              });
            }}
          />
        )}
      {this.state.ownershipLinked && (
          <AlertModal
            isShowed={this.state.ownershipLinked}
            title={"Linked resource selected"}
            msg={"Linked resource, needs to be linked "}
            action={() => {
              this.props.history.push("/wealth-map", {"is_ownership_linked": true, "id": this.state.ownershipLinkedResource.resourceable_id, class: this.state.ownershipLinkedResource.resourceable_type});
            }}
            textButton={this.props.internalization.buttons.ok}
          />
        )}
        {this.state.ownershipLinkedAccount && (
          <AlertModal
            isShowed={true}
            title={"Linked resource selected"}
            msg={"Linked resource, needs to be linked "}
            action={() => {
              window.location.reload()
            }}
            textButton={this.props.internalization.buttons.ok}
          />
        )}      
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    permits: state.session.permits,
    internalization: state.session.internalization,
    errors: state.session.errors,
    enabled_assign_documents: state.session.enabled_assign_documents,
    enabled_assign_contacts: state.session.enabled_assign_contacts,
    user: state.session.user,
    insurance_user: state.session.insurance_user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLinkForm: (url, authentication_token, success, error) =>
      dispatch(getLinkForm(url, authentication_token, success, error)),
    deleteLinkedResource: (
      authentication_token,
      type_1,
      id_1,
      type_2,
      id_2,
      success,
      error
    ) =>
      dispatch(
        deleteLinkedResource(
          authentication_token,
          type_1,
          id_1,
          type_2,
          id_2,
          success,
          error
        )
      ),
    getAlert: (authentication_token, id, success, error) =>
      dispatch(getAlert(authentication_token, id, success, error)),
    getOwnershipEdit: (authentication_token, url, success, error) =>
      dispatch(getOwnershipEdit(authentication_token, url, success, error)),
    getElementDetail: (authentication_token, url, success, error) =>
      dispatch(getElementDetail(authentication_token, url, success, error)),
    createAssetResource: (
      authentication_token,
      success,
      error,
      url,
      method,
      attributes
    ) =>
      dispatch(
        createAssetResource(
          authentication_token,
          success,
          error,
          url,
          method,
          attributes
        )
      ),
    getContact: (authentication_token, success, error, url) =>
      dispatch(getContact(authentication_token, success, error, url)),
    destroyAsset: (authentication_token, success, error, url, method) =>
      dispatch(destroyAsset(authentication_token, success, error, url, method)),
    getDocDetail: (authentication_token, success, error, url) =>
      dispatch(getDocDetail(authentication_token, success, error, url)),
    destroyAssignAssetByResourceable: (
      authentication_token,
      success,
      error,
      id,
      resourceable_id,
      resourceable_type,
      url,
      type
    ) =>
      dispatch(
        destroyAssignAssetByResourceable(
          authentication_token,
          success,
          error,
          id,
          resourceable_id,
          resourceable_type,
          url,
          type
        )
      ),
    editAsset: ( authentication_token, success, error, url, method, attributes ) => dispatch( editAsset(authentication_token, success, error, url, method, attributes)
      ),      
    setEnabledAssignContacts: () => dispatch(setEnabledAssignContacts()),
    setEnabledAssignDocuments: () => dispatch(setEnabledAssignDocuments()),
    getConvertedValue: (value, currency_type, date, authentication_token, success, error) => dispatch(getConvertedValue(value, currency_type, date, authentication_token, success, error)),
    paginateTab: (authentication_token, params, success, error, dispatch_enabled) => dispatch(paginateTab(authentication_token, params, success, error, dispatch_enabled))    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnershipShow);
