// Dependencies
import React from 'react';

const Folder = ({ width, height, color }) => {

    return (

        <svg 
            width={ width ? width : '26px'}
            height={ height ? height : '18px'}
            viewBox="0 0 26 18" 
        >

            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M23.3615422,15.709299 C23.2830843,16.9585378 22.0678554,17.9584124 20.5648193,17.9962926 C20.5505542,17.9962926 20.5368675,17.9993552 20.5218313,17.9993552 L19.1866988,18 L2.8900241,17.9993552 C1.29677108,17.9993552 0,16.9151771 0,15.5827632 L0,2.41659204 C0,1.08433929 1.29677108,0 2.89079518,0 L8.38110843,0 L11.0933976,2.05842318 L20.5218313,2.05842318 C22.1152771,2.05842318 23.4118554,3.14260128 23.4118554,4.47630476 L23.4118554,5.11285238 L26,5.11349715 L23.3615422,15.709299 Z M19.9833804,3.88190217 L9.73231524,3.88190217 L7.0330101,1.8 L2.43175605,1.8 C2.07942246,1.8 1.79310345,2.0426087 1.79310345,2.34130435 L1.79310345,15.6580435 C1.79310345,15.9567391 2.07942246,16.2 2.43175605,16.2 L2.91611878,16.2 L5.46247737,5.06853261 L20.6206897,5.06853261 L20.6206897,4.42402174 C20.6206897,4.12597826 20.3341787,3.88190217 19.9833804,3.88190217 Z" id="Combined-Shape" fill={ color ? color : "#80abba" } fillRule="nonzero"></path>
            </g>
        </svg>
    );

}

export default Folder;
