// Dependencies
import React, { useState, useEffect } from "react";
import { HOST_URL_WEB } from "../constants";
import { connect } from "react-redux";

// Components
import Icon from "./icons";
import Modal from "./modal";

// Images
import googlePlay from "../assets/images/google-play.png";
import appleStore from "../assets/images/apple-store.png";

function DownloadApp(props) {
  const [platform, setPlatform] = useState("");

  useEffect(() => {
    let userAgent = navigator.userAgent || navigator.vendor;

    setPlatform(checkPlatform(userAgent));
  }, []);

  const checkPlatform = (userAgent) => {
    if (/android/i.test(userAgent)) {
      return "Android";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    } else {
      return "";
    }
  };

  return (
    <Modal classWrapper="full" style={{ overflow: "hidden" }} maxHeight={false}>
      <div
        className="wrapper-full-modal"
        style={{ overflow: "hidden", height: "100%" }}
      >
        <Icon
          name={props.insurance_user ? "LogoInsurance" : "Logo"}
          width={200}
          height={72}
          noWrapper={true}
        />
        <Icon
          style={{ marginTop: "20px" }}
          name="OpenApp"
          width="140"
          height="147"
          noWrapper={true}
        />
        <p
          style={{
            marginTop: "10px",
            textAlign: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "16px",
          }}
        >
          {props.mainText}
        </p>
        <button className="btn primary border">
          <a
            href={
              HOST_URL_WEB.indexOf("staging") !== -1
                ? "bulltick360dev://open"
                : "bulltick360://open"
            }
          >
            <span className="btn-text">{props.textButton}</span>
          </a>
        </button>
      </div>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    insurance_user: state.session.insurance_user,
  };
}

export default connect(mapStateToProps, null)(DownloadApp);
