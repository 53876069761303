// Dependencies
import React from "react";

const Boat = (props) => {
  return (
    <svg
      width={props.width ? props.width : "46px"}
      height={props.height ? props.height : "49px"}
      viewBox="0 0 46 49"
    >

        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Vehicles---Boat" transform="translate(-8.000000, -6.000000)" fill={props.color ? props.color : "#80abba"} fillRule="nonzero">
                <g id="noun_Boat_1425019" transform="translate(8.000000, 6.000000)">
                    <path d="M26.5815708,48.5485714 L17.933457,48.5485714 C4.09647495,48.5485714 1.09239332,40.32 1.00136054,39.9542857 C0.910327767,39.68 0.910327767,39.4057143 1.09239332,39.1314286 C1.27445887,38.8571429 1.5475572,38.7657143 1.82065553,38.7657143 L44.3329623,38.7657143 C44.6060606,38.7657143 44.8791589,38.9485714 45.0612245,39.1314286 C45.24329,39.4057143 45.24329,39.68 45.1522573,39.9542857 C45.0612245,40.2285714 42.2392084,48.5485714 26.5815708,48.5485714 Z M3.27717996,40.5028571 C4.64267161,42.6057143 8.37501546,46.6285714 17.8424242,46.6285714 L26.490538,46.6285714 C37.3234385,46.6285714 41.3288806,42.5142857 42.6943723,40.5028571 L3.27717996,40.5028571 Z" id="Shape"></path>
                    <path d="M22.029932,37.0285714 L1.82065553,37.0285714 C1.45652443,37.0285714 1.1834261,36.8457143 1.00136054,36.5714286 C0.819294991,36.2971429 0.819294991,35.9314286 1.00136054,35.6571429 L21.3016698,0.914285714 C21.4837353,0.548571429 21.9388992,0.365714286 22.3030303,0.457142857 C22.6671614,0.548571429 22.9402597,0.914285714 22.9402597,1.37142857 L22.9402597,36.1142857 C22.9402597,36.6628571 22.5761286,37.0285714 22.029932,37.0285714 Z M3.36821274,35.2 L21.1196042,35.2 L21.1196042,4.75428571 L3.36821274,35.2 Z" id="Shape"></path>
                    <path d="M44.3329623,37.3942857 C44.3329623,37.3942857 44.2419295,37.3942857 44.3329623,37.3942857 L25.3071119,37.0285714 C24.8519481,37.0285714 24.3967842,36.5714286 24.3967842,36.1142857 L24.3967842,9.41714286 C24.3967842,9.05142857 24.6698825,8.68571429 25.0340136,8.50285714 C25.3981447,8.41142857 25.8533086,8.50285714 26.0353741,8.86857143 L45.0612245,36.0228571 C45.24329,36.2971429 45.24329,36.6628571 45.1522573,36.9371429 C44.9701917,37.2114286 44.6060606,37.3942857 44.3329623,37.3942857 Z M26.2174397,35.2914286 L42.5123067,35.5657143 L26.2174397,12.3428571 L26.2174397,35.2914286 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default Boat;
