import React, { useState } from "react";
// Components
import Modal from "../../components/modal";
import Tabs from "../../components/Tabs";
import Icon from "./../icons";


// Images
import general from "../../assets/images/goJs/slider/general.png";
import functionalities from "../../assets/images/goJs/slider/functionalities_es.png";
import wealthMapHead01 from "../../assets/images/goJs/slider/wealth_map_head_01.png";
import wealthMapHead02 from "../../assets/images/goJs/slider/wealth_map_head_02_es.png";
import ownershipStructure01 from "../../assets/images/goJs/slider/ownership_structure_01.png";
import ownershipStructure02 from "../../assets/images/goJs/slider/ownership_structure_02_es.png";
import assetsLiabilites01 from "../../assets/images/goJs/slider/assets_liabilities_01.png";
import assetsLiabilites02 from "../../assets/images/goJs/slider/assets_liabilities_02_es.png";
import otherCards01 from "../../assets/images/goJs/slider/other_cards_01.png";
import otherCards02 from "../../assets/images/goJs/slider/other_cards_02_es.png";


const TABS = [
  { code: "general", title: "General" },
  { code: "functionalities", title: "Funcionalidades" },
  { code: "wealthMapHead", title: "Cabeza Wealth Map" },
  { code: "ownershipStructure", title: "Estructura patrimonial" },
  { code: "assestLiabilities", title: "Activo y pasivos" },
  { code: "otherCards", title: "Otras tarjetas" },
];

const ModalInfoES = ({ isShowed, backAction }) => {
  const [step, setStep] = useState("general");

  const changeStepTab = (tab, index) => {
    setStep(TABS[index].code);
  };

  return (
    <Modal
      modalType="notificationsModal"
      isShowed={isShowed}
      showBackButton
      backAction={backAction}
      modalClasses="c-gojs__modal-info"
      bodyClasses="c-gojs__modal-info-body"
      maxHeight={true}
    >
      <Tabs
        tabs={TABS}
        onClick={(tab, index) => changeStepTab(tab, index)}
        tabActive={0}
        location={null}
        classNameWrapper="c-gojs__modal-info-tabs-wrapper"
        classNameArrowRight="c-gojs__modal-info-tabs-arrow-right"
        classNameArrowLeft="c-gojs__modal-info-tabs-arrow-left"
      />

      {step === "general" && (
        <div className="c-tabs__content is-selected c-gojs__modal-info-tabs-content">
          <div className="c-tabs__content-top">
            <div className="c-gojs__modal-info-tabs-slide">
              <p>
                El <strong>Wealth Map</strong> proporciona un organigrama visual de su Patrimonio
                donde puede agregar estructuras patrimoniales, Activos y/o Pasivos y vincularlos
                usando flechas para crear dependencias o relaciones.
              </p>
              <p>
                Las estructuras patrimoniales, activos o pasivos ya existentes se pueden vincular
                fácilmente arrastrando y soltando desde el menú en el lado derecho del canvas. Si
                aún no existe, se puede crear fácilmente con el botón <strong>Crear</strong>.
              </p>
              <img src={general} alt="general" />
            </div>
          </div>
        </div>
      )}
      {step === "functionalities" && (
        <div className="c-tabs__content is-selected c-gojs__modal-info-tabs-content">
          <div className="c-tabs__content-top">
            <div className="c-gojs__modal-info-tabs-slide">
              <div style={{ display: "flex" }}>
                <div>
                  <p className="c-gojs__modal-info-text">
                    <div
                      className="btn-circle-icon"
                      style={{ backgroundColor: "#408197", marginRight: 12 }}
                    >
                      <Icon name={"Edit"} color="white" width={"18px"} height={"18px"} />
                    </div>
                    <strong>Modo de edición:</strong> Para editar, navegar por tu Wealth Map y
                    vincular o crear tarjetas, tienes que hacer clic en el icono del lapiz para
                    activar el modo de edición. Cuando se active el modo de edición, se desplegará
                    el menu completo de tarjetas y elementos que puedes arrastrar y soltar.
                  </p>

                  <p className="c-gojs__modal-info-text">
                    <div className="btn-circle-icon" style={{ marginRight: 12 }}>
                      <Icon name="Menu" color="white" width="18px" height="18px" />
                    </div>
                    <strong>Acciones:</strong> Botón para más acciones
                  </p>

                  <p className="c-gojs__modal-info-text">
                    <div className="c-gojs__layout-switch is-active" style={{marginRight: 12}}>
                      <span className="c-gojs__layout-switch-text">Manual</span>
                      <span className="c-gojs__layout-switch-text">Auto</span>
                    </div>
                    <p style={{"flex":1}}>
                      <strong>Layout:</strong> Cómo se organizará tu Wealth Map:
                      <p><u>Manual</u> – Organización manual de tu Wealth Map en función de tus criterios.</p>
                      <p><u>Auto</u> – Reorganización automática de tu Wealth Map en función de una serie de criterios de la plataforma. Al tener seleccionada la función de Auto-Layout y realizar cambios adicionales,  la reorganización automática aparecerá una vez que cierres el modo de edición.</p>
                    </p>
                  </p>

                  <p className="c-gojs__modal-info-text">
                    <div className="btn-circle-icon" style={{ marginRight: 12, backgroundColor: "#005774" }}>
                      <Icon name="Dollar" color="white" width="18px" height="18px" />
                    </div>
                    <strong>Mostrar / Ocultar Cantidades:</strong> Botón para mostrar/ocultar las cantidades en tu Wealth Map.
                  </p>

                  <p className="c-gojs__modal-info-text">
                    <div className="btn-circle-icon" style={{ marginRight: 12, backgroundColor: "#005774", }}>
                      <Icon name="DocDownload" color="white" width="18px" height="18px" />
                    </div>
                    <strong>Descargar:</strong> Botón para descargar tu Wealth Map en formato png . 
                  </p>

                  <p className="c-gojs__modal-info-text">
                    <div className="btn-circle-icon" style={{ marginRight: 12, backgroundColor: "#005774" }}>
                      <Icon name="Print" color="white" width="18px" height="18px" />
                    </div>
                    <strong>Imprimir:</strong> Botón para imprimir tu Wealth Map.
                  </p>

                  <p className="c-gojs__modal-info-text">
                    <div className="btn-circle-icon" style={{ marginRight: 12 }}>
                      <Icon name={"ZoomFit"} color="white" width={"18px"} height={"18px"} />
                    </div>
                    <strong>Ajuste de Zoom:</strong> Ajusta el Wealth Map para que el organigrama se
                    vea complete en el canvas.
                  </p>
                  <p className="c-gojs__modal-info-text" style={{alignItems: "center"}}>
                    <div style={{ marginRight: 12 }}>
                      <div className="btn-circle-icon">
                        <Icon name={"ZoomIn"} color="white" width={"18px"} height={"18px"} />
                      </div>
                      <div className="btn-circle-icon" style={{ marginTop: 12 }}>
                        <Icon name={"ZoomOut"} color="white" width={"18px"} height={"18px"} />
                      </div>
                    </div>
                    <strong>Zoom In/Out:</strong>Botones para hacer zoom in o zoom out de forma
                    manual.
                  </p>
                </div>
                <img
                  src={functionalities}
                  alt="functionalities"
                  style={{ height: "max-content" }}
                />
              </div>
              <p style={{ marginLeft: 16 }}>
                <strong>Arrastrar Elementos:</strong> Para agregar estructuras patrimoniales,
                activos o pasivos al Wealth Map, tienes que estar primero en modo de edición para
                que aparezca el menu completo de elementos. Una vez estando en modo de edición, haz
                clic y arrastra el elemento que quieras incluir en el canvas en la posicion donde
                quieras colocarlo.
              </p>
              <p style={{ marginLeft: 16 }}>
                <strong>Vinculando tarjetas / flechas:</strong> Las tarjetas estan vinculadas y las
                dependencias o relaciones se crean a traves del uso de flechas. Para crear una
                vinculacion nueva o dependencia/relación, pase el cursor sobre el borde de la
                tarjeta que quieras vincular y desde ahi saldrá una flecha que puedes arrastrar a la
                otra tarjeta o element que quieras vincular. Esta vinculación tambien se reflejará
                en la ficha de la estructura patrimonial, activo o pasivo en su sección concreta
                (fuera del Wealth Map) en el tab de Recursos Vinculados..
              </p>
              <p style={{ marginLeft: 16 }}>
                <strong>Navegando por el canvas: </strong> Para moverse por el canvas debes hacer
                clic y arrastrar el canvas hacia donde quieras navegar.
              </p>
              <p style={{ marginLeft: 16 }}>
                <strong>Navegar a elementos:</strong> Para navegar a un fidecomiso, corporación, ,
                activo o pasivo desde el Wealth Map, haz clic en el icono de la fleche situado
                arriba a la derecha de las tarjetas. Las tarjetas que no estan vinculadas a una
                estructura, activo o pasivo como comentarios y divisiones no tendran ese icono.
              </p>
              <p style={{ marginLeft: 16 }}>
                <strong>Eliminar vs. Borrar: </strong> Eliminar un elemento de tu Wealth Map no lo
                borra de bt360. Simplemente se desvincula y desaparecerá del canvas, pero la
                estructura, activo o pasivo seguira existiendo en su seccion propia dentro de bt360.
                Cualquier elemento eliminado del Wealth Map se puede agregar despues en cualquier
                momento.
              </p>
              <p classNam style={{ marginLeft: 16 }}>
                <strong>Editando nombres: </strong> Para editar el nombre de cualquier tarjeta, haga
                clic en el icono de lápiz en las tarjetas{" "}
              </p>
            </div>
          </div>
        </div>
      )}
      {step === "wealthMapHead" && (
        <div className="c-tabs__content is-selected c-gojs__modal-info-tabs-content">
          <div className="c-tabs__content-top">
            <div className="c-gojs__modal-info-tabs-slide">
              <p>
                La <strong>Cabeza del Wealth Map</strong> es la persona física, entidad u otro que
                actúa como la cabeza del organigrama
              </p>
              <p>
                El nombre de la cabeza se puede editar usando el icono de lápiz de arriba a la
                derecha de la tarjeta.
              </p>
              <div style={{ display: "flex" }}>
                <img src={wealthMapHead01} alt="wealthMapHead01" style={{ width: "60%" }} />

                <img
                  src={wealthMapHead02}
                  alt="wealthMapHead02"
                  style={{ width: "40%", marginLeft: 12 }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {step === "ownershipStructure" && (
        <div className="c-tabs__content is-selected c-gojs__modal-info-tabs-content">
          <div className="c-tabs__content-top">
            <div className="c-gojs__modal-info-tabs-slide">
              <p>
                Crea o vincula <strong>Fideicomisos</strong> y/o <strong>Corporaciones</strong>{" "}
                existentes a tu Wealth Map.
              </p>
              <div style={{ display: "flex" }}>
                <img
                  src={ownershipStructure01}
                  alt="ownershipStructure01"
                  style={{ width: "60%" }}
                />
                <img
                  src={ownershipStructure02}
                  alt="ownershipStructure02"
                  style={{ width: "40%", marginLeft: 12 }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {step === "assestLiabilities" && (
        <div className="c-tabs__content is-selected c-gojs__modal-info-tabs-content">
          <div className="c-tabs__content-top">
            <div className="c-gojs__modal-info-tabs-slide">
              <p>
                {" "}
                Crea o vincula <strong>Activos</strong> y/o <strong>Pasivos</strong> existentes a tu
                Wealth Map.{" "}
              </p>
              <div style={{ display: "flex" }}>
                <img src={assetsLiabilites01} alt="assetsLiabilites01" style={{ width: "50%" }} />
                <img
                  src={assetsLiabilites02}
                  alt="assetsLiabilites02"
                  style={{ width: "50%", marginLeft: 12 }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {step === "otherCards" && (
        <div className="c-tabs__content is-selected c-gojs__modal-info-tabs-content">
          <div className="c-tabs__content-top">
            <div className="c-gojs__modal-info-tabs-slide">
              <p>
                La sección de <strong>Otras tarjetas</strong> te permite organizar mejor tu Wealth
                Map agregando elementos útiles al canvas como Comentarios o % de Participación,
                Divisiones para separar diferentes elementos y Tarjetas Personalizadas para agregar
                entradas personalizadas
              </p>
              <p>
                El siguiente ejemplo muestra una división de arte dentro de un Wealth Map con
                Tarjetas Personalizadas para separar la division Arte por artista, y Comentarios
                para indicar donde se encuentran las obras.
              </p>
              <div style={{ display: "flex" }}>
                <img src={otherCards01} alt="otherCards01" style={{ width: "60%" }} />
                <img
                  src={otherCards02}
                  alt="otherCards02"
                  style={{ width: "40%", marginLeft: 12 }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ModalInfoES;
