// Dependencies
import React, {useState, useEffect, useRef} from "react";
import DatePicker from "react-datepicker";
import PhoneInput from 'react-phone-number-input';
import smoothscroll from 'smoothscroll-polyfill';

// Components
import Icon from "./icons";
import CustomModal from "./customModal";
import SelectMultiple from "./selectMultiple"


// Constants
import {internalization} from '../constants.js'



const AddData = (props) => {
  smoothscroll.polyfill();

var languageUserAgent = (/^es/).test(props.language) ? 'es' : 'en';
  
const [form, setForm] = useState()
const [showModal, setShowModal] = useState(false)
const [cont, setCont] = useState(0)
const [added, setAdded] = useState([])
const [deleteAdded, setDeleteAdded] = useState(false)
const [idLabelChange, setIdLabelChange] = useState("")
const [indexDelete, setIndexDelete] = useState("")
const [multipleRef, setMultipleRef] = useState(null);
const [modalOptions, setModalOptions] = useState(false)
const divRef = useRef(null)


const OPTIONS = props.type === 'address_contact' ?  props.form.filter(item => item.id === "address_contact")[0].options : props.form.options

useEffect(() => {

  if(props.type !== "address_contact"){
    if(!props.edit && props.form){
      preparedData(OPTIONS, props.form.id)
    }else {
      editData()
      preparedData(OPTIONS,props.form.id)
    }
  }else {
    if(props.edit){
      editDataAddress()
      preparedData(OPTIONS,props.type)
    }else {
      let inputAddress = props.form.filter(item => item.id === "address_contact")[0]
      preparedData(inputAddress.options, inputAddress.id)
    }
  }
  


  document.removeEventListener("click", handleClickOutside.bind());
  document.addEventListener("click", handleClickOutside.bind());

  return () => {
    document.removeEventListener("click", handleClickOutside.bind());
  };
   
}, []);

const handleClickOutside = (e) => {

  if (divRef.current && !divRef.current.contains(e.target)) {
    setIndexDelete("")
  }
  
}

const getTag = (id) => {
  return OPTIONS.filter(item => item.id === id)[0].value
} 

const editData = () => {
  let data = props.form
  let newData = []

   data.value.map((item) => {
    if(props.type !== "address_contact"){
      let _getTag = getTag(item.catalogue_id)
      let valueItem = item.value
      if(props.type === "special_date"){
        valueItem = new Date(item.value)
      }
        newData.push({value:  valueItem, id: item.id, catalogue_id:item.catalogue_id, tag: _getTag, order: item.order })
      
    }else {
      newData.push({value: item.value, input: item.related_fields})
    }
    
  })

  setAdded(newData)
  props.sendData(added,props.type)
}

const editDataAddress = () => {
  let data = props.form
  let arrayAllValues = []
  let allValues = {}
  let arraySeparatedValues = []
  let separatedValues = {}

  data.map((item, i) => {
   item.value.length > 0 && item.value.map((value, j) => {
      allValues = {...allValues, value: data[i].value[j].value, id: data[i].id, cont:j }
      arrayAllValues.push(allValues)
    })
    
  })

  data.filter(item => item.id === "address_contact")[0].value.map((item, i) => {

    arrayAllValues.map((value, j) => {
      if(value.cont === i){
        if(value.id !== "address_contact"){
          let valueItem = data.filter(elem => elem.id === "address_contact")[0].value[i]
          let catalogueId = valueItem.catalogue_id
          let valueId = valueItem.id
          let _getTag = getTag(catalogueId)
          arraySeparatedValues[i] =  
                separatedValues = 
                    {...separatedValues,[value.id]: value.value, catalogue_id: catalogueId, tag: _getTag, order: value.order, id:valueId, input: props.form.filter(item => item.id !== "address_contact") }
        }
      }
    })
  })


  setAdded(arraySeparatedValues)

  props.sendData(added,props.type)

}

const preparedData = (options, id) => {
  
  let newOptions = {id: id, options: []}
    options.map((item, index) => {
      if(item.id !== ""){
       
        newOptions.options.push({catalogue_id: item.id, order: item.order, tag : item.value}) 
      }
      
    })

    setForm(newOptions)
}


const addItem = () => {
 
  let _form = form && JSON.parse( JSON.stringify( form ) )
  let _added = added.slice()
  let _cont = cont
  let isAdded = [] 
  let lessAdded = []
  
  if(_added.length > 0){
      
        _added.map((item) => {
          if(item.order !== undefined){
            isAdded.push(item.order)
          }else {
            isAdded = []
          }
        })

      _form.options.map((item) => {
        if(!isAdded.includes(item.order)){
          lessAdded.push(item.order)
        }
      })

      lessAdded.sort()

      let _lessAdded
      if(lessAdded.length > 0){
          _lessAdded  = lessAdded[0]
      }else {
          _lessAdded = 0
      }
        
      if(props.type === "address_contact"){

        let newObj = _form.options.filter(item => item.order === _lessAdded)[0]
        newObj["input"] = props.form.filter(item => item.id !== "address_contact")
       _added.push(newObj)
  
      }else {
       
        if(props.type === "special_date"){
          if(_form.options.filter(item => item.order === _lessAdded)[0].value === undefined){
            _added.push(_form.options.filter(item => item.order === _lessAdded)[0])
            props.sendData(_added, props.type)
          }else {
            _added.push(_form.options.filter(item => item.order === _lessAdded)[0])
          }
          
        }else {
          _added.push(_form.options.filter(item => item.order === _lessAdded)[0])
        }
        
      }
      
    
  }else {
    
    if(props.type === "special_date"){
      if(_form.options.filter(item => item.order === _cont)[0].value === undefined){
        _added.push(_form.options.filter(item => item.order === _cont)[0])
        props.sendData(_added, props.type)
      }else {
        _added.push(_form.options.filter(item => item.order === _cont)[0])
      }
    }else{
      _added.push(_form.options.filter(item => item.order === _cont)[0])

      if(props.type === "address_contact"){
          _added[0]["input"] = props.form.filter(item => item.id !== "address_contact")
      }
    }
  }
      
  setAdded(_added)
  setCont(_cont)
}

const _showDelete = (e,index) => {

  setDeleteAdded(true)
  setIndexDelete(index)
}

const _changeLabel = (value) => {
  let _added = added.slice()

  _added[idLabelChange].tag = value
  _added[idLabelChange].catalogue_id = OPTIONS.filter(item => item.value === value)[0].id


  setShowModal(false)
  setAdded(_added)
  props.sendData(_added, props.type)
}

const _openSelectLabel = (id) => {
  setShowModal(true)
  setIdLabelChange(id)
}

const _deleteAdded = (deleteItem) => {
  let _added = added.slice()

  if(!props.edit){
    _added.splice(deleteItem, 1)
  }
  else{
     _added[deleteItem]["destroy"]= true
  }
 
  props.sendData(_added, props.type )

  setAdded(_added)
  
  setIndexDelete("")
}

const getValue = (data, id, dynamicKey) => {
  let _added = added.slice()
 
  if(props.type !== "address_contact"){
    _added[id]["value"] = props.form.id === "special_date" ? data : props.form.id === "phone" ? data : data.currentTarget.value
  }else {
    _added[id][dynamicKey] =  data.currentTarget.value
  }
  
  setAdded(_added)
  props.sendData(_added, props.type)
}



const getSelectOptions = (item, id, dynamicKey) => {
  
  let _added = added.slice()
  
  _added[id][dynamicKey] = item.item.id
  _added[id]["country_address"] = item.item.id

  setAdded(_added)
  props.sendData(_added, props.type)
}

const getSelectData = (id,options)=> {
  
  if(id !== "n/a"){
   let value = options.filter(option => option.id === id)
   if(value.length > 0){
    return value[0].value
   }else {
    return props.language === "es-ES" ? "País" : "Country"
   }
    
  }else{
    return props.language === "es-ES" ? "País" : "Country"
  }
} 

const scrollToBottom = (e) => {
  
  let modalBody = document.querySelector('.modal-body');
  let geOffsetScroll = modalBody.scrollTop + 217;
  modalBody.scrollTo({top:geOffsetScroll, behavior:'smooth'})
}

  return (
    
    <>
  
          <div ref={divRef} className="add-data js-clickOutside">
          {
            added.length > 0 &&
            added.map((itemAdded,index)=>{
              if(!itemAdded.destroy){
                return(
                  <div 
                    key={"item-added_"+index} 
                    className={props.type === "address_contact" ? "add-data__item add-data__item--border add-data__item--full-height" : "add-data__item add-data__item--border"}>
                    {
                      props.disabled === props.edit &&
                      <div  className="add-data__item-icon  add-data__item-icon--color-orange" onClick={(e) => props.edit === props.disabled && _showDelete(e,index)}>
                      <Icon width="10" height="10" name="Minus" color="white" iconBig={false} className="add-data__icon" close={false}/>
                    </div>
                    }
                    
                    <div className="add-data__item-label">
                      <div 
                        className="add-data__item-label-text"
                        onClick={() => props.edit === props.disabled && _openSelectLabel(index)}
                      >
                        <p>{itemAdded.tag}</p>
                        <Icon width={'16px'} className="add-data__icon"  height={'16px'} noWrapper={true} color={'#E4E4E4'}  name="SingleArrow" orientation={"right"} />
                      </div>
                      {
                        props.type !== "address_contact" &&
                        props.form && props.form.id === 'special_date' &&
                        <div className='add-data__datepicker'>
                          <DatePicker 
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select" 
                            selected={itemAdded.value}  
                            onChange={(date)=> getValue(date,index)}
                            />
                        </div>
                      }
                      {
                        props.type !== "address_contact" &&
                          props.form && props.form.id === 'phone' &&
                            <PhoneInput
                              key={"add_"+props.form.id}
                              //placeholder="Enter phone number"
                              value={ itemAdded.value || "" }
                              disabled={props.edit === props.disabled ? "" : "disabled"}
                              className=" wrapper-input grey add-data__phoneInput"
                              onChange={ value => getValue(value,index)} />
                      }
                      {
                        props.type !== "address_contact" &&
                        props.form && props.form.id !== 'special_date' && props.form.id !== 'phone' &&
                        <input className="add-data__item-label-input" value={itemAdded.value} type="text" disabled={props.edit === props.disabled ? "" : "disabled"} placeholder={props.form.label} onChange={(e) => getValue(e,index)}/>
                      }
                      <div className="add-data__item-label-input-wrapper">
                      {
                        props.type === "address_contact" &&
                        itemAdded.input.map((addressInput,i) => {
                          
                            if(addressInput.id !== "country_address"){
                              if(itemAdded[addressInput.id] !== "" && !props.disabled){
                                return(
                                  <input key={"add-input_"+i} className="add-data__item-label-input add-data__item-label-input--height-fourty" value={itemAdded[addressInput.id]} type="text" placeholder={addressInput.label} 
                                  disabled={props.edit === props.disabled ? "" : "disabled"} onChange={(e) => getValue(e,index, addressInput.id)}/>
                                )
                              }else if( props.disabled || (!props.diabled && !props.edit)){
                                return(
                                  <input key={"add-input_"+i} className="add-data__item-label-input add-data__item-label-input--height-fourty" value={itemAdded[addressInput.id] === "" ? "" : itemAdded[addressInput.id]} type="text" placeholder={addressInput.label} 
                                  disabled={props.edit === props.disabled ? "" : "disabled"} onChange={(e) => getValue(e,index, addressInput.id)}/>
                                )
                              }
                              
                            }else {
                              if(itemAdded[addressInput.id] !== "" && !props.disabled){
                                return(
                                  <SelectMultiple
                                  noBorder={true}
                                  colorGrey={false}
                                  setRef={ref => { setMultipleRef(ref) }}
                                  defaultValue={itemAdded.country_address !== undefined ? getSelectData(itemAdded[addressInput.id],props.form.filter(item => item.id === "country_address")[0].options)  : props.language === "es-ES" ? "País" : "Country"}
                                  //label={options.label}
                                  options={props.form.filter(item => item.id === "country_address")[0].options}
                                  multiple={false}
                                  openOptions={(e) =>{scrollToBottom(e); setModalOptions(!modalOptions)}}
                                  disabled={props.edit !== props.disabled}
                                  modalOptions={modalOptions}
                                  getOptions={ items => getSelectOptions(items, index, addressInput.id)}
                                  // itemsSelected={
                                  //   this.state.inputsValue.type !== undefined
                                  //     ? this.state.inputsValue.type
                                  //     : []
                                  // }
                                  language={false}
                                  />
                                )
                              }else if(props.disabled) {
                                return(
                                  <SelectMultiple
                                  noBorder={true}
                                  colorGrey={false}
                                  setRef={ref => { setMultipleRef(ref) }}
                                  defaultValue={itemAdded.country_address !== undefined ? getSelectData(itemAdded[addressInput.id],props.form.filter(item => item.id === "country_address")[0].options)  : props.language === "es-ES" ? "País" : "Country"}
                                  //label={options.label}
                                  options={props.form.filter(item => item.id === "country_address")[0].options}
                                  multiple={false}
                                  openOptions={(e) =>{scrollToBottom(e); setModalOptions(!modalOptions)}}
                                  disabled={props.edit !== props.disabled}
                                  modalOptions={modalOptions}
                                  getOptions={ items => getSelectOptions(items, index, addressInput.id)}
                                  // itemsSelected={
                                  //   this.state.inputsValue.type !== undefined
                                  //     ? this.state.inputsValue.type
                                  //     : []
                                  // }
                                  language={false}
                                  />
                                )
                              }
                              
                            }

                          
                        })
                      }
                      </div>
                      
                    </div>
                    <div className={deleteAdded && indexDelete === index ? "add-data__item-icon-float add-data__item-icon-float--large is-visible" : "add-data__item-icon-float add-data__item-icon--large" } onClick={() => props.edit === props.disabled && _deleteAdded(index)}>
                      <Icon width={'16px'} height={'16px'} noWrapper={true} color={'#f3b01c'} name="Garbage" orientation={"right"} />
                    </div>
                  </div>
                )
              }
            })
          }
          {
            props.disabled === props.edit &&
            <div className="add-data__item">
              <div className="add-data__item-icon add-data__item-icon--color-blue" onClick={() => props.edit === props.disabled && addItem()}>
              <Icon width="10" height="10" name="Close" color="white" iconBig={false} className="add-icon" close={false}/>
              </div>
              <div className="add-data__item-label">{props.type === "address_contact" ? props.label : props.form && props.form.label}</div>
            </div>
         }
          
        </div>

      {
        showModal &&
        <CustomModal close={() => setShowModal(false)}>
          <div className="add-data__label">
            <div className="add-data__label-title">{internalization[languageUserAgent].select_label}</div>
            <ul className="add-data__label-list">
            {
                OPTIONS.map((options, index) => {
                  if(options.value !== "Select"){
                    return(
                      <li className="add-data__label-list-item" onClick={() => _changeLabel(options.value)}><p>{options.value}</p></li>
                    )
                  }
                })
              }
            </ul>
          </div>
        </CustomModal>
      }
  
    </>
    
  );
};

export default AddData;
