// Dependencies
import React from "react";
import TableEditableHeaderRow from "./TableEditableHeaderRow";
import TableEditableRow from "./TableEditableRow";
import TableEditableEmptyRow from "./TableEditableEmptyRow";

class TableEditable extends React.Component {
  constructor(props) {
    super(props);
    this.tableEditable = React.createRef();
    this.input = React.createRef();
    this.state = {
      inputValue: "",
      dataRows: [],
      numbersOfPrevRows: 0,
      rowsEmpty: [],
      buttonDisabled: false,
      showEmpty:false
    };

    this.input.current = []
  }

  _onChange = (rowsData, i, value, temp_id) => {
    this.setState({ inputValue: value }, () =>
      this._storeRow(rowsData, i, value, temp_id)
    );
  };

  componentDidMount(){
    this.setState({showEmpty: this.props.rows.length === 0, dataRows: this.props.rows_stored ? this.props.rows_stored : []})
  }
  _onBlur = (e) => {
    this.setState({ inputValue: "" });
  };

  _storeRow = (rowsData, i, new_value, temp_id) => {
    // let rowParent = parent;
    let rowParentId = rowsData.id
    // let rowParentChildren = rowParent.children;
    let rows = [];
    let rowsStore = {};
    
    let rowFirstCellValue = parseInt(rowParentId, 10);
    this.setState({ buttonDisabled: false });
    
    [].forEach.call(rowsData.row, (element, index) => {
      rows.push({ value: index === i ? new_value : element.value });
    });

    // Store local array into dataRows
          
      if (this.state.dataRows.length === 0) {
        // Always store first row modified
        rowsStore.id = rowParentId;
        rowsStore.row = rows;
        rowsStore.temp_id = temp_id;
        this.setState({ showEmpty: false, dataRows: [...this.state.dataRows, rowsStore] }, () => {
          console.log("First time always store it!", this.state.dataRows);
          this.props.saveData(this.state.dataRows); 
          this.input.current[i].focus() 
        });
        
      } else {
        let isRepeated = this.state.dataRows.find((row) => {

          return (parseInt(row.row[0].value, 10) === rowFirstCellValue || (rowParentId === "" && row.temp_id && row.temp_id === rowsData.temp_id));
        });
        
        
        
        if (isRepeated == undefined) {
          let new_date_rows = JSON.parse(JSON.stringify(this.state.dataRows))

          rowsStore.id = rowParentId;
          rowsStore.row = rows;
          rowsStore.temp_id = temp_id
          new_date_rows.push(rowsStore)
          
          this.setState({ showEmpty: false, dataRows: new_date_rows }, () => {
            console.log("It's not repeated so store it!", this.state.dataRows);
            this.props.saveData(this.state.dataRows);
            this.input.current[i].focus()
          } );
          
        } else {
          
          let new_data_rows = ""+isRepeated.id === "" ? this.state.dataRows.filter(element => ""+isRepeated.temp_id !== ""+element.temp_id) : this.state.dataRows.filter(element => ""+element.id !== ""+isRepeated.id)
          
          rowsStore.id = rowParentId;
          rowsStore.row = rows;
          rowsStore.temp_id = temp_id;
          this.setState({ showEmpty: false, dataRows: [...new_data_rows, rowsStore] }, () => {
            console.log("it was repeated so updated it!", this.state.dataRows);
            this.props.saveData(this.state.dataRows);      
          });
          
        }
      }
  };

  _addNewRow = (tableEditable) => {
    let lastRow = tableEditable.current.lastElementChild;
    let headerRow = lastRow.classList.contains("c-table-editable__row-header");
    let new_current_rows_count =  
      this.state.dataRows.filter(e => e.temp_id !== undefined).length > 0 ? 
      Math.max.apply(Math, this.state.dataRows.filter(e => e.temp_id !== undefined).map(function(o) { return o.temp_id; }))
      :
      0
    
    let temp_id = new_current_rows_count + 1
    if (!headerRow) {
      
      let rowChildren = lastRow.children;
      let checkAllValues = [];

      [].forEach.call(rowChildren, (input, index) => {
        let inputValue = input.value;
        if (index > 0 && inputValue !== "") checkAllValues.push(inputValue);
      });
      this.setState({showEmpty:true})
    }
  };

  _incrementAmount = (tableEditable) => {
    
    let last_existing_row = 0;
    if(this.props.rows.length > 0){
      last_existing_row = this.props.rows[this.props.rows.length - 1].row[0].value  
    }
    if(this.state.dataRows.length > 0 && this.state.dataRows.filter(e => e.id === "").length > 0){
      last_existing_row = last_existing_row + Math.max.apply(Math, this.state.dataRows.filter(e => e.temp_id !== undefined).map(function(o) { return o.temp_id; }))
    }

    return last_existing_row + 1;
  };

  _removeTextFromRowElements = (row) => {
    let elementsInRowCloned = row.querySelectorAll("*");
    elementsInRowCloned.forEach((elem, index) => {
      elem.value = "";
    });
  };

  _renderEmptyRow = () => {
    
   let numbersOfPrevRows = this._incrementAmount(this.tableEditable);
    // this.setState({ numbersOfPrevRows: numbersOfPrevRows })
    let new_current_rows_count =  
      this.state.dataRows.filter(e => e.temp_id !== undefined).length > 0 ? 
      Math.max.apply(Math, this.state.dataRows.filter(e => e.temp_id !== undefined).map(function(o) { return o.temp_id; }))
      :
      0
    
    let temp_id = new_current_rows_count + 1  
 
    return (
      <TableEditableEmptyRow
        key={numbersOfPrevRows}
        dataId={""}
        new_row={this.props.new_row}
        temp_id={temp_id}
        numberOfRow={numbersOfPrevRows}
        onChange={(rowParent, input, inputValue) =>
          this._onChange(rowParent, input, inputValue, temp_id)
        }
        onBlur={(e) => this._onBlur(e)}
        refInput={this.input}
      />
    );
  };

  render() {
    let all_rows = this.props.rows.slice()
    if(this.props.rows_stored.length > 0) all_rows.push(this.props.rows_stored)

    return (
      <>
        <div ref={this.tableEditable} className="c-table-editable">
          {/* Headers */}
          {this.props.headers && (
            <TableEditableHeaderRow headers={this.props.headers} />
          )}

          {/* Rows */}
          {this.props.rows.length > 0 && 
            <TableEditableRow
              // key={`row-${this.state.numbersOfPrevRows}`}
              rows={this.props.rows}
              all_rows={all_rows}
              onChange={(rowParent, input, inputValue) =>
                this._onChange(rowParent, input, inputValue)
              }
              stored={this.state.dataRows}
              onBlur={(e) => this._onBlur(e)}
              refInput={this.input}
            />
          }

          {this.state.dataRows.length > 0 && this.state.dataRows.filter(element => element.id === "") && (
            <TableEditableRow
              // key={`row-stored-stored-${this.state.numbersOfPrevRows}`}
              // rows={this.props.rows_stored}
              rows={this.state.dataRows.filter(element => element.id === "").sort(function(a, b) {return a["temp_id"] - b["temp_id"]}) }
              all_rows={all_rows}
              new_rows={true}
              stored={this.state.dataRows.filter(element => element.id === "")}              
              onChange={(rowsData, i, value, temp_id) =>
                this._onChange(rowsData, i, value, temp_id)
              }
              onBlur={(e) => this._onBlur(e)}
              refInput={this.input}
            />
          )}

          {/* Empty Row */}
          {
            this.state.showEmpty &&
             (this._renderEmptyRow())
          }
        </div>

        <button
          onClick={() => this._addNewRow(this.tableEditable)}
          type="button"
          className="btn"
          disabled={this.state.showEmpty}
        >
          <span class="btn-text">Save</span>    
        </button>
      </>
    );
  }
}

export default TableEditable;
