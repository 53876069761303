// Dependencies
import React from "react";

// Components
import Icon from "./icons/";

const ImageGrid = props => {

  return (
    <>
      {props.files.length > 0 && (
        <div className="wrapper-imageGrid">
          {!props.loadingImages &&
            props.files.map((image, index) => {
              return (
                <>
                  {image.value !== "" && (
                    <div
                      key={"images_edit_" + index}
                      className="item-imageGrid"
                    >
                      <img
                        src={
                          image.id !== undefined ||
                          image.hasOwnProperty("id") ||
                          !image.hasOwnProperty("filename")
                            ? image.value
                            : `data:${image.content_type};base64,` + image.data
                        }
                        className="image-imageGrid"
                        alt=""
                      />
                      <button
                        className="delete-icon"
                        onClick={() => props.deleteImage(index)}
                      >
                        <Icon name="Plus" close={true} />
                      </button>
                    </div>
                  )}
                </>
              );
            })}
        </div>
      )}
    </>
  );
};

export default ImageGrid;
