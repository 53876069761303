// Dependencies
import React from "react";

const InvestmentAccount = (props) => {
  return (
    <svg
      width={props.width ? props.width : "47px"}
      height={props.height ? props.height : "49px"}
      viewBox="0 0 47 49"
    >
      
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Investment-Account" transform="translate(-10.000000, -6.000000)">
            <g id="noun_payment_1947558" transform="translate(10.000000, 39.000000)" fill={props.color ? props.color : "#80abba"} fillRule="nonzero">
                <g id="Group">
                    <path d="M45.8756192,1.72944414 L35.0948773,1.72944414 C31.2032982,-0.422090597 26.4802165,-0.427515759 22.583705,1.7150732 L16.2883875,1.72944414 C15.2350795,1.7292523 14.2418401,2.21991243 13.601928,3.05655474 C12.962016,3.89319705 12.7485264,4.98025458 13.0244931,5.9967683 L5.33688498,2.47588773 C4.21972678,1.96430347 2.91901075,2.05643651 1.88511855,2.72038515 C0.851226344,3.38433379 0.22630864,4.5288112 0.226747386,5.7575344 L0.226747386,5.94182058 C0.222048807,7.30958521 0.995467892,8.56102253 2.2209268,9.16851954 L12.0777017,14.096907 C13.6045444,14.8573893 15.2868143,15.2537962 16.9925636,15.2550358 L45.8756192,15.2550358 C46.3424928,15.2550358 46.7209686,14.8765599 46.7209686,14.4096863 L46.7209686,2.57479362 C46.7209686,2.10791999 46.3424928,1.72944414 45.8756192,1.72944414 Z M12.8342895,12.5845768 L2.97751458,7.65618932 C2.32622551,7.33355509 1.91507904,6.66863879 1.91744634,5.94182058 L1.91744634,5.7575344 C1.91573996,5.10398574 2.24735455,4.49466605 2.79709637,4.14123614 C3.34683819,3.78780624 4.03883599,3.73904387 4.63270886,4.01188773 L14.2451778,8.41531315 C14.3557761,8.4660249 14.4760181,8.49226476 14.5976885,8.49223996 L23.0511833,8.49223996 C23.5180569,8.49223996 23.8965327,8.1137641 23.8965327,7.64689048 C23.8965327,7.18001685 23.5180569,6.801541 23.0511833,6.801541 L16.2883875,6.801541 C15.3546402,6.801541 14.5976885,6.0445893 14.5976885,5.11084205 C14.5976885,4.1770948 15.3546402,3.42014309 16.2883875,3.42014309 L22.7696819,3.42014309 C22.9107431,3.41984879 23.0495471,3.3847122 23.1737589,3.31785581 L23.3901684,3.19950688 C26.6970188,1.38058244 30.6945509,1.33647919 34.0407265,3.0820033 L34.0407265,13.5643368 L16.9925636,13.5643368 C15.5493942,13.5633844 14.1260756,13.2280264 12.8342895,12.5845768 Z M45.0302697,13.5643368 L35.7314254,13.5643368 L35.7314254,3.42014309 L45.0302697,3.42014309 L45.0302697,13.5643368 Z" id="Shape" transform="translate(23.473837, 7.683495) scale(-1, 1) translate(-23.473837, -7.683495) "></path>
                </g>
            </g>
            <g id="Group-2" transform="translate(17.000000, 7.000000)">
                <g id="Group-3" transform="translate(6.000000, 5.500000)">
                    <path d="M18.5,3.46677091 C17.6847826,3.46677091 17,4.15155352 17,4.96677091 C17,5.78198831 17.6847826,6.46677091 18.5,6.46677091 C19.3152174,6.46677091 20,5.78198831 20,4.96677091 C20,4.15155352 19.3152174,3.46677091 18.5,3.46677091 Z" id="Path" stroke={props.color ? props.color : "#80abba"}></path>
                    <path d="M1.5,3.46677091 C0.684782609,3.46677091 0,4.15155352 0,4.96677091 C0,5.78198831 0.684782609,6.46677091 1.5,6.46677091 C2.31521739,6.46677091 3,5.78198831 3,4.96677091 C3,4.15155352 2.34782609,3.46677091 1.5,3.46677091 Z" id="Path" stroke={props.color ? props.color : "#80abba"}></path>
                    <path d="M15,5 C15,7.77108434 12.7409639,10 10,10 C7.22891566,10 5,7.74096386 5,5 C5,2.22891566 7.25903614,0 10,0 C12.7710843,0 15,2.25903614 15,5 Z" id="Path" stroke={props.color ? props.color : "#80abba"}></path>
                    <polygon id="Path" fill={props.color ? props.color : "#80abba"} points="13 3.6261912 11.8639053 2.46677091 9.23668639 5.14793034 8.13609467 4.02474193 7 5.18416222 8.10059172 6.30735062 9.23668639 7.46677091 10.3727811 6.30735062"></polygon>
                </g>
                <path d="M32,3.27119896 L32,17.6623429 C32,19.2121584 30.7579909,20.4667709 29.2237443,20.4667709 L2.77625571,20.4667709 C1.24200913,20.4667709 0,19.2121584 0,17.6623429 L0,3.27119896 C0,1.72138346 1.24200913,0.466770915 2.77625571,0.466770915 L29.2237443,0.466770915 C30.7579909,0.503671284 32,1.75828383 32,3.27119896 Z" id="Path" stroke={props.color ? props.color : "#80abba"}></path>
                <path d="M29.5,6.41404358 C29.2828418,6.48668281 29.0656836,6.48668281 28.8123324,6.48668281 C27.2922252,6.48668281 26.0254692,5.25181598 26.0254692,3.69007264 C26.0254692,3.47215496 26.0616622,3.21791768 26.0978552,3 L5.72117962,3 C5.75737265,3.18159806 5.75737265,3.32687651 5.75737265,3.50847458 C5.75737265,5.03389831 4.52680965,6.30508475 2.97050938,6.30508475 C2.78954424,6.30508475 2.64477212,6.30508475 2.5,6.26876513 L2.5,14.7312349 C2.64477212,14.6949153 2.82573727,14.6949153 2.97050938,14.6949153 C4.49061662,14.6949153 5.75737265,15.9297821 5.75737265,17.4915254 C5.75737265,17.6731235 5.75737265,17.8547215 5.72117962,18 L26.0616622,18 C26.0616622,17.8910412 26.0254692,17.7820823 26.0254692,17.6368039 C26.0254692,16.1113801 27.2560322,14.8401937 28.8123324,14.8401937 C29.0656836,14.8401937 29.2828418,14.8765133 29.5,14.9128329 L29.5,6.41404358 Z" id="Path" stroke={props.color ? props.color : "#80abba"}></path>
            </g>
            <path d="M16.5668261,31.5 L49,31.5" id="Line" stroke={props.color ? props.color : "#80abba"} strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M16.5668261,35 L49,35" id="Line-Copy" stroke={props.color ? props.color : "#80abba"} strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </g>
    </g>
</svg>
  );
};

export default InvestmentAccount;
