import { HOST_URL, URL_VERSION } from '../constants';

import get_header from './api_header'
import {parseHeaders} from './api_header'

export function getHomeSuccess(payload) {
  
  return {
      type: 'HOME',
      payload
  }
}

export function inputsHomeForm(payload) {
  
  return {
      type: 'HOME_FORM',
      payload
  }
}

export function getHome(authentication_token, success,error) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/home', {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          dispatch(getHomeSuccess(responseJson.home_data))        
          success()
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function getFilteredDataBarsGraphSuccess(payload) {
  return {
      type: 'FILTER_DATA_BARS_GRAPH',
      payload
  }
}

export function getFilteredDataBarsGraph(authentication_token, url, success, error) {
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          if(responseJson.balance_index){
            dispatch(getFilteredDataBarsGraphSuccess(responseJson.balance_index.bars_graphic_data))    
          }  
          success()   
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function inputsForm(authentication_token, success, error) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/inputs_form', {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          dispatch(inputsHomeForm(responseJson))
          success()   
        })
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}
