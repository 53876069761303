// Dependencies
import React from "react";

// Helpers
import renderIcon from "../../helpers/renderIcon";

const Icon = (props) => {
  const iconClass = [
    props.noWrapper !== true && "wrapper-icon",
    props.iconBig === true && "big",
    props.iconSmall === true && "small",
    props.onInput === true && "wrapper-icon-on-input",
    props.className && props.className,
    props.opacity === true && "opacity"
  ]
    .filter(elem => elem !== false)
    .join(" ");

  return (

    <div className={iconClass} onClick={props.action ? () => props.action() : () => ''} style={props.style && props.style}>
      {
        props.badgeNumber && (
          <div className="wrapper-badge">
            <span className="headingH4 number">
              {props.badgeNumber < 99 ? props.badgeNumber : "+"}
            </span>
          </div>
        )
      }
      {renderIcon({props})}
    </div>

  );
};

export default Icon;
