// Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {internalization} from '../constants.js'

// Components

import Modal from './modal'
import Input from './input'

// Actions
import { updateUser } from "../actions/session";
function VerifyPassword(props) {
	var languageUserAgent = (/^es/).test(props.user.language) ? 'es' : 'en';

  const [user_attributes, setUser_attributes] = useState({})
  const [form_errors, setForm_errors] = useState({})
  const [keyInput, setKeyInput] = useState([])
  const [checksPasswordItems, setChecksPasswordItems] = useState([])


 const  _getInputValues = (e) => {

			if(e.target.value.length === 0){
				setForm_errors({})
			}
      setUser_attributes({
        ...user_attributes,
        [e.target.name]: e.target.value
      })


  }

    const  _passwords_match = () => {
        return user_attributes['password_confirmation'] !== "" ? user_attributes['password'] === user_attributes['password_confirmation'] : undefined ;
    }

   const  _checksPassword = (text, passwordTexts) => {
    let checkeds = []

    let regLength = new RegExp(/[A-Za-z0-9@#_*()+{}\/?~:,.-].{8,70}$/)
    let regUppercase = new RegExp(/^(?=.*?[A-Z])/)
    let regLowercase = new RegExp(/^(?=.*?[a-z])/)
    let regDigit = new RegExp(/^(?=.*?[0-9])/)
    let regSpecial = new RegExp(/[@#_*()+{}\/?~:,.-]/)
    let regRepeated = new RegExp(/^(?!.*([A-Za-z0-9])\1{2})/)

    if(regLength.test(text)) checkeds.push(passwordTexts[0])
    if(regUppercase.test(text) && regLowercase.test(text) && regDigit.test(text) && regSpecial.test(text)) checkeds.push(passwordTexts[1])
    if(regSpecial.test(text)) checkeds.push(passwordTexts[2])
    if(regRepeated.test(text) && text.length > 0) checkeds.push(passwordTexts[3])   

    return checkeds;
  }  

  
 const  _testRegexp = (text) => {
	
    let reg = new RegExp(/^(?!.*([A-Za-z0-9])\1{3})(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[@#_*()+{}\/?~:,.-]).{8,70}$/)
    return reg.test(text)
  }

  const _validatePassword = (error_object) => {
		if(!_testRegexp(user_attributes.password)){
      error_object['password_error'] = props.errors.password_requirement
    }
    if(!_testRegexp(user_attributes.password_confirmation)){
      error_object['password_confirmation_error'] = props.errors.password_requirement
    }  
    if(!_testRegexp(user_attributes.old_password)){
      error_object['old_password_error'] = props.errors.password_requirement
    }	
  	return error_object
  }

 const _validateGeneric = (type) => {

  	let error_object = {};
		!user_attributes[type] || user_attributes[type].length === 0  
		? error_object[type+"_error"] = props.errors.blank 
		: (delete error_object[type+"_error"])

		!user_attributes[type+"_confirmation"] || user_attributes[type+"_confirmation"].length === 0 || user_attributes[type] !== user_attributes[type+"_confirmation"] 
		? error_object[type+'_confirmation_error'] = props.errors.blank+" / "+props.errors.no_match  
		: (delete error_object[type+'_confirmation_error'])
		
    if(type === "password"){
    	error_object = _validatePassword(error_object)
		}

		
		if(Object.keys(error_object).length === 0){
			setForm_errors({})
      return true
    }
    else{
			setForm_errors({...error_object})
      return false
		}
		
		
   }

   const  _disableOfuscated = (e, key) => {
   
		let keys = keyInput
		if(!keys.includes(key)){
			keys.push(key)
		}else {
			keys = keys.filter(i => i !== key)
		}

    setKeyInput([...keys])
	}

    const _submit = () => {

      if(_validateGeneric('password')){
        props.updateUser(
					props.authentication_token,
					 {user: user_attributes, expired_password: true,} ,
					() => props.close(),
					(responseJSON) => _error(responseJSON)
				);
      }
    }

  const _error = (responseJSON) => {
    let error_object = {}
    if(responseJSON.code == "new"){
      error_object["password_error"] = responseJSON.errors
    }
    else{
      error_object["old_password_error"] = responseJSON.errors
    }
    setForm_errors({...error_object})
  }


 
  return(
  <div>
    <Modal showBackButton={false} maxHeightBig={true}>
      <div className="wrapper-change-password">
        <p className="title o-margin--bottom-size-l" style={{textAlign: 'center'}}>{props.internalization.alerts.password_expired}</p>
        <div className="wrapper-form-item ">
						<span className="input-label">{internalization[languageUserAgent].old_password}</span>
		        <Input
							key={3}
		          name={"old_password"}
		          type={keyInput.includes(3) ? 'text' : 'password'}
		          defaultValue={""}
		          onChangeInput={e => _getInputValues(e) }
		          onFocus={() => {}}
		          onBlur={() => {}}
		          errorLabel={form_errors["old_password_error"]}
		          showErrorLabelNow={form_errors["old_password_error"]}
							showErrorLabel={Object.keys(form_errors).includes("old_password_error")}
							withButtonIcon={true}
							iconName={"Eye"}
							slashed={keyInput.includes(3) ? true : false}
              buttonIconAction={ e => _disableOfuscated(e,3)}
		        />
		    </div>
		    <div className="wrapper-form-item has-requirementOptions">    
		        <span className="input-label">{internalization[languageUserAgent].new_password}</span>
		        <Input
							key={1}
		          name={"password"}
		          type={keyInput.includes(1) ? 'text' : 'password'}
		          defaultValue={""}
		          onChangeInput={e => {_getInputValues(e); setChecksPasswordItems(_checksPassword(e.target.value, internalization[languageUserAgent].password_requirements_texts))} }
		          onFocus={() => {}}
		          onBlur={() => {}}
		          errorLabel={form_errors["password_error"]}
		          showErrorLabelNow={form_errors["password_error"]}
							showErrorLabel={Object.keys(form_errors).includes("password_error")}
							withButtonIcon={true}
							iconName={"Eye"}
							slashed={keyInput.includes(1) ? true : false}
              buttonIconAction={ e => _disableOfuscated(e,1)}
              requirementOptions={internalization[languageUserAgent].password_requirements_texts}
              showRequirementCheck={true} 
              requirementCheck={checksPasswordItems}
		        />
	      	</div>
	        <div className="wrapper-form-item">
		        <span className="input-label">{internalization[languageUserAgent].new_password_confirmation}</span>
		        <Input
							key={2}
		          name={"password_confirmation"}
              type={keyInput.includes(2) ? 'text' : 'password'}
		          defaultValue={""}
		          onChangeInput={e => _getInputValues(e)}
		          onFocus={() => {}}
		          onBlur={() => {}}
		          errorLabel={form_errors['password_confirmation_error']}
		          showErrorLabelNow={form_errors['password_confirmation_error']}
							showErrorLabel={Object.keys(form_errors).includes('password_confirmation_error')}
							withButtonIcon={true}
							iconName={"Eye"}
							slashed={keyInput.includes(2) ? true : false}
							buttonIconAction={e => _disableOfuscated(e,2)}
              password_match_text={_passwords_match() ? internalization[languageUserAgent].password_match : internalization[languageUserAgent].password_not_match}
              passwords_match={_passwords_match()}
              passwords_match_enabled={user_attributes.password_confirmation !== undefined}
		        />
	      	</div>
					<button 
						disabled={user_attributes.password_confirmation === undefined || user_attributes.password_confirmation === ""} 
						className="btn primary"
						onClick={() => _submit()}
						>{internalization[languageUserAgent].send}
          </button>
      </div>
    </Modal>

  </div>)
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    internalization: state.session.internalization,
    user: state.session.user 
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateUser: (authentication_token, attributes, success, error) =>
      dispatch(updateUser(authentication_token, attributes, success, error)),
  };
}

export default connect(
	mapStateToProps,
  mapDispatchToProps
)(
  VerifyPassword
);
