// Dependencies
import React, { useEffect, useState, useRef } from "react";
import TextEllipsis from "react-text-ellipsis";

// Components
import Icon from "../components/icons";

const NestedlistOwnership = (props) => {
  const [nestedList, setNestedList] = useState(props.data);
  const [id, setId] = useState([]);
  const [heightLast, setHeightLast] = useState("");
  const [closeAll, setCloseAll] = useState(false);

  useEffect(() => {
    controllerFirst();

    scrollToTop();
  }, []);

  const scrollToTop = () => {
    let wrapper = document.querySelector(".detailTypeOne-wrapper-nestedList");

    setTimeout(() => {
      let opened = document.getElementById("id-open");

      if (wrapper && opened) {
        opened.click();
        wrapper.scroll({
          top:
            opened.getBoundingClientRect().top -
            50 -
            wrapper.getBoundingClientRect().top,
          behavior: "smooth",
        });
      }
    }, 1000);
  };

  const controllerFirst = () => {
    let items = document.querySelectorAll(".nestedlist-ownership__item");

    for (let i = 0; i < items.length; i++) {
      items[i].classList.add("is-visible");
    }
  };

  const paintingLargeLines = (e) => {
    let allIsVisibles = document.querySelectorAll(".is-visible");

    [].forEach.call(allIsVisibles, (isVisible) => {
      let hasHookParent = isVisible.closest(
        ".nestedlist-ownership__item-parent"
      );

      setTimeout(() => {
        let hasHookParentHeight = hasHookParent.offsetHeight;

        let hasHookParentSibling = hasHookParent.nextSibling;
        if (hasHookParentSibling) {
          let hasHookParentSiblingBorder = hasHookParentSibling.querySelector(
            ".has-hook .has-hook__border"
          );
          setTimeout(() => {
            hasHookParentSiblingBorder.setAttribute(
              "style",
              `height:${hasHookParentHeight + 20}px`
            );
          }, 150);
        }
      }, 350);
    });
  };

  const opened = (e, idSelected, item, first) => {
    e.preventDefault();
    e.cancelBubble = true;
    e.stopPropagation();

    let idsSelected = id.slice();
    let parentElement = e.currentTarget.parentElement;
    setTimeout(() => {
      let sibling = parentElement.querySelector(
        ".nestedlist-ownership__item-sibling"
      );
      let items = sibling.querySelectorAll(".nestedlist-ownership__item");
      if (first) {
        let closeAllItems;
        let isVisibleItems = document.querySelectorAll(".is-visible");

        if (isVisibleItems.length === 1) {
          closeAllItems = false;
          controllerFirst();
        } else {
          for (let i = 1; i < isVisibleItems.length; i++) {
            isVisibleItems[i].classList.remove("is-visible");
          }
          closeAllItems = true;
        }
        setCloseAll(closeAllItems);
      } else {
        for (let i = 0; i < items.length; i++) {
          items[i].classList.add("is-visible");
        }
      }
    }, 50);

    if (idsSelected.length > 0) {
      if (idsSelected.includes(idSelected)) {
        idsSelected = idsSelected.filter((item) => item !== idSelected);

        idsSelected = closeDescendants(e, item, idsSelected);
      } else {
        idsSelected.push(idSelected);
      }
    } else {
      idsSelected.push(idSelected);
    }

    idsSelected && setId(idsSelected);

    paintingLargeLines(e);
  };

  const closeDescendants = (e, allItems, ids) => {
    if (allItems && allItems.descendants.length > 0) {
      allItems.descendants.map((item) => {
        if (ids.includes(item.show)) {
          ids = ids.filter((element) => element !== item.show);

          if (item.descendants.length > 0) {
            for (let i = 0; i < item.descendants.length; i++) {
              ids = ids.filter(
                (element2) => element2 !== item.descendants[i].show
              );
            }
          }
        } else {
          if (item.descendants.length > 0) {
            closeDescendants(item.descendants);
          }
        }
      });

      return ids;
    }
  };

  const getHeightLastElement = () => {
    setTimeout(() => {
      let parent = document.getElementById("first");
      setHeightLast(parent && parent.childNodes[1].lastChild.offsetHeight);
    }, 100);
  };

  const goTo = (e, item, first) => {
    e.preventDefault();
    e.cancelBubble = true;
    e.stopPropagation();

    if (first) {
      window.location.href = "/ownership-index";
    } else {
      props.action && item.url && props.action(item.id, item.url);
    }
  };

  const renderItems = (item, idItem, first, index, last) => {
    getHeightLastElement();

    return (
      <div
        key={idItem}
        className="nestedlist-ownership__item-parent"
        id={first ? "first" : null}
        data-show={item.show}
      >
        <div
          className={
            first
              ? "nestedlist-ownership__item-wrapper hide"
              : "nestedlist-ownership__item-wrapper has-hook"
          }
          onClick={(e) => {
            opened(e, idItem, item, first);
          }}
          style={{ display: first ? "block" : !closeAll ? "block" : "none" }}
        >
          <div className="has-hook__border"></div>
          <span
            className="nestedlist-ownership__item-level-color"
            style={{ backgroundColor: item.colour }}
          ></span>
          <div
            key={`${item.show}-item`}
            id={item.show === props.ownershipId ? "id-open" : null}
            className={
              first
                ? "nestedlist-ownership__item is-visible"
                : item.show === props.ownershipId
                ? `nestedlist-ownership__item nestedlist-ownership__item--border-color `
                : `nestedlist-ownership__item ${item.special ? "special" : ""}`
            }
          >
            <div
              className={
                first
                  ? "nestedlist-ownership__item-text nestedlist-ownership__item-text--color-blue"
                  : "nestedlist-ownership__item-text"
              }
              onClick={(e) => (item.special ? "" : goTo(e, item, first))}
            >
              {window.innerWidth > 1024 ? (
                <p>{item.title}</p>
              ) : (
                <TextEllipsis
                  lines={2}
                  tag={"p"}
                  ellipsisChars={"..."}
                  tagClass={""}
                  debounceTimeoutOnResize={200}
                  useJsOnly={true}
                >
                  {item.title}
                </TextEllipsis>
              )}
            </div>

            {item.descendants.length > 0 && (
              <Icon
                width={"20px"}
                height={"20px"}
                noWrapper={true}
                className={
                  !first
                    ? item.descendants.length > 0 &&
                      (id.includes(idItem) || closeAll)
                      ? "nestedlist-ownership__item-icon nestedlist-ownership__item-icon--up"
                      : "nestedlist-ownership__item-icon"
                    : closeAll
                    ? "nestedlist-ownership__item-icon"
                    : "nestedlist-ownership__item-icon nestedlist-ownership__item-icon--up"
                }
                name="SingleArrow"
                orientation={"down"}
              />
            )}
          </div>
        </div>

        <div
          className="nestedlist-ownership__item-sibling"
          style={{ marginLeft: first ? 0 : 18 }}
          data-first={first}
        >
          {item.descendants.length > 0 &&
            (item.preopened || item.opened || id.includes(idItem)) &&
            item.descendants.map((descendants, index) => {
              return renderItems(
                descendants,
                item.descendants.length > 0 && descendants.show,
                false,
                index,
                item.descendants.length - 1 === index
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <div className="nestedlist-ownership nestedlist-ownership--full-heigth">
      {nestedList.map((item, index) => {
        return renderItems(item, "", nestedList.length - 1 === 0, index, false);
      })}
    </div>
  );
};

export default NestedlistOwnership;
