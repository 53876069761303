import * as go from "gojs";

// Images
import editButtonImage from "../../assets/images/goJs/edit_button_white.png";
import goButtonImage from "../../assets/images/goJs/go_button.png";

import icon from "../../assets/images/goJs/icon_division.png";

const $ = go.GraphObject.make;

const categoryItem = (diagram, editButtonClick, nodeStyle, makePort, colors, titleStyle,
avoidNodeOverlap) => {
  return diagram.nodeTemplateMap.add(
    "Division", // Avoid to default category
$(
      go.Node,
      "Table",
      nodeStyle(),
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      $(
        go.Shape,
        "RoundedRectangle",
        {
          name: "Default shape",
          fill: colors.blueLighter,
          strokeWidth: 0,
          alignment: go.Spot.TopRight,
        },
        new go.Binding("desiredSize", "isPalette", (isPalette) =>
          isPalette ? new go.Size(222, 40) : new go.Size(224, 86)
        )
      ),
      $(
        go.Panel,
        "Horizontal",
        {
          width: 208,
          // margin: new go.Margin(20, 8, 0, 8),
        },
        new go.Binding("margin", "isPalette", (isPalette) =>
          isPalette ? new go.Margin(2, 8, 0, 8) : new go.Margin(20, 8, 0, 8)
        ),
        new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 24 : 48)),
        $(
          go.Panel,
          "Vertical",
          {
            margin: new go.Margin(10, 8, 0, 8),
          },
          $(go.TextBlock, titleStyle(),new go.Binding("font", "isPalette", (isPalette) => (isPalette ? "14px Averta-Regular" : "14px Averta-Bold")), new go.Binding("text", "title")),
          new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 24 : 48))
        )
      ),
      $(
        go.Panel,
        "Position",
        {
          alignment: go.Spot.TopRight,
        },
        new go.Binding("width", "isPalette", (isPalette) => (isPalette ? 0 : 212)),
        new go.Binding("height", "isPalette", (isPalette) => (isPalette ? 0 : 24)),
        new go.Binding("margin", "isPalette", (isPalette) =>
          isPalette ? new go.Margin(0, 8, 0, 0) : new go.Margin(8, 8, 0, 0)
        ),
        $(
          go.Panel,
          "Button",
          {
            name: "Edit button",
            position: new go.Point(188, 0),
            width: 24,
            height: 24,
            cursor: "pointer",
            click: editButtonClick,
          },
          $(go.Picture, editButtonImage, {
            width: 24,
            height: 24,
          })
        )
      ),
      { dragComputation: avoidNodeOverlap },
      // four named ports, one on each side:
      makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
      makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
      makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
      makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
    )
  );
};

export default categoryItem;
