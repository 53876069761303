// Dependencies
import React from "react";
import { connect } from "react-redux";

// Components
import ContentBlock from "../../components/contentBlock";
import Tooltip from "../../components/tooltip";

// Actions
import { allArticles } from "../../actions/articles";

class ServicesScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            content : 0,
            showTabs: true,
            showTooltip: false
        }
    }

    componentDidMount() {
        this.props.getBreadcrumbs({
            url:
            this.props.history.location.pathname +
            this.props.history.location.search,
            assetUrl: this.props.history.location.pathname
        });

        this.props.allArticles(this.props.authentication_token,() =>{},() =>{})
    }


    handleTabs(e) {
        let tabs = document.querySelectorAll('.wrapper-tabs .btn');
        let tabText = e.currentTarget.querySelector('span');
        tabs.forEach(tab => {
            tab.classList.remove('bordered')
            tab.classList.add('light');
            let tabText = tab.querySelector('span');
            tabText.classList.remove('grey-dark')
            tabText.classList.add('orangeMedium');
        });
        e.currentTarget.classList.add('bordered');
        tabText.classList.add('grey-dark');
    }

    handleTooltip(e) {
        e.stopPropagation();
        this.setState({ showTooltip: !this.state.showTooltip });
        let body = document.body;
        body.addEventListener('click', e => this.setState({ showTooltip: false }) );
    }

    goToArticle(item){
        
        this.props.history.push("/services/"+item.id, {id: item.id});
    }

    render() {
       
        return (
            <div className="main-wrapper services">

                {
                    this.props.articles.services !== undefined && this.props.articles.services.map((item,index) => {
                        return(
                            <>
                            {
                                item.show_in_home &&
                                <ContentBlock
                                title={this.props.articles.title}
                                header={true}
                                className="centered-and-full contacts"
                                >
                                <div className="block-news" onClick={() => this.goToArticle(item)}>
                                <h4 className="title2 blue">{item.title}</h4>
                                    <div className="block-news--container">
                                        <div className="block-news--img">
                                            <img src={Object.keys(item.image).length > 0 && item.image.urls.large} alt="" className="news-img"/>
                                        </div>
                                        <div className="block-news--text">
                                            <p className="news-text">{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                                </ContentBlock>
                            }
                            </>
                        )
                        })

                        
                    }
                
                
                
                <ContentBlock
                title={this.props.articles.title}
                header={false}
                className="centered-and-full contacts"
                >
                
                     
                    {
                       this.props.articles.services !== undefined && this.props.articles.services.filter((item) => item.show_in_home != true).map((item,index) => {
                            return(
                                <div className="block-news"  onClick={() => this.goToArticle(item)}>
                                <h4 className="title2 blue">{item.title}</h4>
                                <div className="block-news--container">
                                    <div className="block-news--img small">
                                        <img src={Object.keys(item.image).length > 0 && item.image.urls.large} alt="" className="news-img"/>
                                    </div>
                                    <div className="block-news--text full-width">
                                        <p className="news-text">{item.description}</p>
                                    </div>
                                </div>
                                </div>
                            )
                        })
                    }
                    
                
                </ContentBlock>
        
               
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
      articles: state.articles.articles,
      authentication_token: state.session.authentication_token,
      
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      allArticles: (authentication_token,success, error) => dispatch(allArticles(authentication_token,success, error))
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ServicesScreen);
