import React, { useState } from "react";
// Components
import Modal from "../../components/modal";
import Tabs from "../../components/Tabs";
import Icon from "./../icons";

// Images
import general from "../../assets/images/goJs/slider/general.png";
import functionalities from "../../assets/images/goJs/slider/functionalities.png";
import wealthMapHead01 from "../../assets/images/goJs/slider/wealth_map_head_01.png";
import wealthMapHead02 from "../../assets/images/goJs/slider/wealth_map_head_02.png";
import ownershipStructure01 from "../../assets/images/goJs/slider/ownership_structure_01.png";
import ownershipStructure02 from "../../assets/images/goJs/slider/ownership_structure_02.png";
import assetsLiabilites01 from "../../assets/images/goJs/slider/assets_liabilities_01.png";
import assetsLiabilites02 from "../../assets/images/goJs/slider/assets_liabilities_02.png";
import otherCards01 from "../../assets/images/goJs/slider/other_cards_01.png";
import otherCards02 from "../../assets/images/goJs/slider/other_cards_02.png";

const TABS = [
  { code: "general", title: "General" },
  { code: "functionalities", title: "Functionalities" },
  { code: "wealthMapHead", title: "Wealth Map Head" },
  { code: "ownershipStructure", title: "Ownership Structure" },
  { code: "assestLiabilities", title: "Assest & Liabilities" },
  { code: "otherCards", title: "Other Cards" },
];

const ModalInfo = ({ isShowed, backAction }) => {
  const [step, setStep] = useState("general");

  const changeStepTab = (tab, index) => {
    setStep(TABS[index].code);
  };

  return (
    <Modal
      modalType="notificationsModal"
      isShowed={isShowed}
      showBackButton
      backAction={backAction}
      modalClasses="c-gojs__modal-info"
      bodyClasses="c-gojs__modal-info-body"
      maxHeight={true}
    >
      <Tabs
        tabs={TABS}
        onClick={(tab, index) => changeStepTab(tab, index)}
        tabActive={0}
        location={null}
        classNameWrapper="c-gojs__modal-info-tabs-wrapper"
        classNameArrowRight="c-gojs__modal-info-tabs-arrow-right"
        classNameArrowLeft="c-gojs__modal-info-tabs-arrow-left"
      />

      {step === "general" && (
        <div className="c-tabs__content is-selected c-gojs__modal-info-tabs-content">
          <div className="c-tabs__content-top">
            <div className="c-gojs__modal-info-tabs-slide">
              <p>
                The <strong>Wealth Map</strong> provides a visual and clean organization chart of
                your Wealth where you can add Ownership Structures, Assets, and/or Liabilities and
                link them using arrows to create dependencies or relationships.
              </p>
              <p>
                Already existing ownership structures, assets, or liabilities can be easily linked
                by dragging and dropping from the menu on your right side of each corresponding
                section. If it does not already exist, it can be easily created using the{" "}
                <strong>Create</strong> button.
              </p>
              <img src={general} alt="general" />
            </div>
          </div>
        </div>
      )}
      {step === "functionalities" && (
        <div className="c-tabs__content is-selected c-gojs__modal-info-tabs-content">
          <div className="c-tabs__content-top">
            <div className="c-gojs__modal-info-tabs-slide">
              <div style={{ display: "flex" }}>
                <div>
                  <p className="c-gojs__modal-info-text">
                    <div
                      className="btn-circle-icon"
                      style={{ backgroundColor: "#408197", marginRight: 12 }}
                    >
                      <Icon name={"Edit"} color="white" width={"18px"} height={"18px"} />
                    </div>
                    <strong>Edit Mode:</strong> To edit, navigate around your Wealth Map, or link or
                    create new elements, the blue pencil icon must be clicked to activate Edit Mode.
                    Edit Mode will drop down the full menu of elements you can drag and drop. Click
                    the blue “x” to get out of Edit Mode
                  </p>
                  <p className="c-gojs__modal-info-text">
                    <div className="btn-circle-icon" style={{ marginRight: 12 }}>
                      <Icon name="Menu" color="white" width="18px" height="18px" />
                    </div>
                    <strong>Actions:</strong> More actions button
                  </p>

                  <p className="c-gojs__modal-info-text">
                    <div className="c-gojs__layout-switch is-active" style={{marginRight: 12}}>
                      <span className="c-gojs__layout-switch-text">Manual</span>
                      <span className="c-gojs__layout-switch-text">Auto</span>
                    </div>
                    <p style={{"flex":1}}>
                      <strong>Layout:</strong> How your Wealth Map will be organized:
                      <p><u>Manual</u> – Manual organization of your WealthMap based on your criteria</p>
                      <p><u>Auto</u> – Automatic reorganization of your WealthMap based on a set of criteria of the platform. When you have the Auto-Layout function selected and make additional changes, the automatic reorganization will appear once you close the edit mode.</p>
                    </p>
                  </p>

                  <p className="c-gojs__modal-info-text">
                    <div className="btn-circle-icon" style={{ marginRight: 12, backgroundColor: "#005774" }}>
                      <Icon name="Dollar" color="white" width="18px" height="18px" />
                    </div>
                    <strong>Show / Hide figures:</strong> Allow you to show/hide all dollar amounts in your Wealth Map
                  </p>

                  <p className="c-gojs__modal-info-text">
                    <div className="btn-circle-icon" style={{ marginRight: 12, backgroundColor: "#005774", }}>
                      <Icon name="DocDownload" color="white" width="18px" height="18px" />
                    </div>
                    <strong>Download:</strong> Allows you to download your Wealth Map in png format 
                  </p>

                  <p className="c-gojs__modal-info-text">
                    <div className="btn-circle-icon" style={{ marginRight: 12, backgroundColor: "#005774" }}>
                      <Icon name="Print" color="white" width="18px" height="18px" />
                    </div>
                    <strong>Print:</strong> Allows you to print your Wealth Map
                  </p>

                  <p className="c-gojs__modal-info-text">
                    <div className="btn-circle-icon" style={{ marginRight: 12 }}>
                      <Icon name={"ZoomFit"} color="white" width={"18px"} height={"18px"} />
                    </div>
                    <strong>Zoom Fit:</strong> Zooms your Wealth Map in or out to fit the complete
                    Map on the canvas
                  </p>
                  <p className="c-gojs__modal-info-text" style={{alignItems: "center"}}>
                    <div style={{ marginRight: 12 }}>
                      <div className="btn-circle-icon">
                        <Icon name={"ZoomIn"} color="white" width={"18px"} height={"18px"} />
                      </div>
                      <div className="btn-circle-icon" style={{ marginTop: 12 }}>
                        <Icon name={"ZoomOut"} color="white" width={"18px"} height={"18px"} />
                      </div>
                    </div>
                    <strong>Zoom In/Out:</strong>Allows you to manually zoom in or out
                  </p>
                </div>
                <img
                  src={functionalities}
                  alt="functionalities"
                  style={{ height: "max-content" }}
                />
              </div>
              <p style={{ marginLeft: 16 }}>
                <strong>Drag and Drop Elements:</strong> To add new or link existing Ownership
                Structures, Assets, or Liabilities, Wealth Map must be in Edit Mode so that the full
                menu of elements appears, and you click and drag whatever you’d like to create or
                link and drop in the position of your choice. You can relocate the cards by clicking
                on them once they're in the canvas and dragging them.
              </p>
              <p style={{ marginLeft: 16 }}>
                <strong>Linking Cards / Arrows:</strong> Cards are linked, and dependencies or
                relationships are created by using arrows. To create a new link, dependency, or
                relationship, the Ownership Structure, Asset, Liability, or Custom Card must already
                be on the canvas. Hover over the edge of the card you’d like to link, and an arrow
                will appear from that edge which you can drag to the other element. This will also
                be reflected within the factsheet of the Ownership Structure, Asset or Liability in
                their individual sections (outside of Wealth Map) in the Linked Resources Tab.
              </p>
              <p style={{ marginLeft: 16 }}>
                <strong>Moving around the Canvas: </strong> To move around the canvas, get Wealth
                Map into Edit Mode and you can click directly on the canvas and drag around to
                wherever you’d like to navigate
              </p>
              <p style={{ marginLeft: 16 }}>
                <strong>Navigate to Element:</strong> To navigate to the Ownership Structure, Asset
                or Liability from your Wealth Map, click on the arrow icon on the top right of the
                card. Cards that aren’t linked to Ownership Structures, Assets or Liabilities such
                as Comments or Divisions, won't have this icon.
              </p>
              <p style={{ marginLeft: 16 }}>
                <strong>Removing versus Deleting: </strong> Removing an element from your Wealth Map
                will not delete it from bt360. It will simply disappear from the canvas, but the
                Ownership Structure, Asset or Liability will still exist in its corresponding
                section and be re-added later.
              </p>
              <p classNam style={{ marginLeft: 16 }}>
                <strong>Editing Names: </strong> To edit names, click on the pencil icon on the top
                right of each card and click Save.{" "}
              </p>
            </div>
          </div>
        </div>
      )}
      {step === "wealthMapHead" && (
        <div className="c-tabs__content is-selected c-gojs__modal-info-tabs-content">
          <div className="c-tabs__content-top">
            <div className="c-gojs__modal-info-tabs-slide">
              <p>
                The Wealth Map Head is the physical person, entity or other, that acts as the head
                of the of the organization chart.
              </p>
              <p>
                The Head’s name can be edited using the pencil icon on the top right of the card.
              </p>
              <div style={{ display: "flex" }}>
                <img src={wealthMapHead01} alt="wealthMapHead01" style={{ width: "60%" }} />

                <img
                  src={wealthMapHead02}
                  alt="wealthMapHead02"
                  style={{ width: "40%", marginLeft: 12 }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {step === "ownershipStructure" && (
        <div className="c-tabs__content is-selected c-gojs__modal-info-tabs-content">
          <div className="c-tabs__content-top">
            <div className="c-gojs__modal-info-tabs-slide">
              <p>Create or link existing Trusts or Corporations to your Wealth Map.</p>
              <div style={{ display: "flex" }}>
                <img
                  src={ownershipStructure01}
                  alt="ownershipStructure01"
                  style={{ width: "60%" }}
                />
                <img
                  src={ownershipStructure02}
                  alt="ownershipStructure02"
                  style={{ width: "40%", marginLeft: 12 }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {step === "assestLiabilities" && (
        <div className="c-tabs__content is-selected c-gojs__modal-info-tabs-content">
          <div className="c-tabs__content-top">
            <div className="c-gojs__modal-info-tabs-slide">
              <p>
                {" "}
                Create or link existing <strong>Assets</strong> and/ or <strong>Liabilities</strong>{" "}
                to your Wealth Map.{" "}
              </p>
              <div style={{ display: "flex" }}>
                <img src={assetsLiabilites01} alt="assetsLiabilites01" style={{ width: "50%" }} />
                <img
                  src={assetsLiabilites02}
                  alt="assetsLiabilites02"
                  style={{ width: "50%", marginLeft: 12 }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {step === "otherCards" && (
        <div className="c-tabs__content is-selected c-gojs__modal-info-tabs-content">
          <div className="c-tabs__content-top">
            <div className="c-gojs__modal-info-tabs-slide">
              <p>
                The <strong>Other Cards</strong> section allows you to better organize your Wealth
                Map by adding helpful components to the canvas such as Comments or % of Ownership,
                Divisions to separate different elements and Custom Cards to add custom entries.
              </p>
              <p>
                The example below shows an Art division within a Wealth Map with Custom Cards to
                separate the Art by artist, and comments to indicate where the art is located.
              </p>
              <div style={{ display: "flex" }}>
                <img src={otherCards01} alt="otherCards01" style={{ width: "60%" }} />
                <img
                  src={otherCards02}
                  alt="otherCards02"
                  style={{ width: "40%", marginLeft: 12 }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ModalInfo;
