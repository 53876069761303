// Dependencies
import React from 'react';

// Components
import Icon from './icons/';

//Helpers
import { avatars } from '../assets/resources';


const Avatar = ({ image, withIcon, action, iconName, strokeWidth, stroke }) => {
 
  let avatar_selected = null
  if(image.avatar_default_id !== null) 
    avatar_selected = avatars[image.avatar_default_id].default
  else if(image.url !== null) 
    avatar_selected =  image.url
  else{

  }

  return (

    <div className="wrapper-avatar" onClick={() => action()}>

      <img 
        className="avatar" 
        src={avatar_selected}
        alt=""
      />
      {
        withIcon === true &&
        <button className="wrapper-icon-avatar">
          <Icon name={iconName} stroke={stroke} strokeWidth={strokeWidth}/>
        </button>
      }
      
    </div>

  );

}

export default Avatar;
