// Dependencies
import React from 'react';


const Minus = ({ width, height, color }) => {

  return (
    <svg
      width={width ? width : '10px'}
      height={height ? height : '2px'}
      viewBox="0 0 10 2"
    >

      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons/Add-Bis-Circle-Copy" transform="translate(-7.000000, -11.000000)" fill={color ? color : "#80abba"} fillRule="nonzero">
              <path d="M16,13 L13,13 L11,13 L8,13 C7.45,13 7,12.55 7,12 C7,11.45 7.45,11 8,11 L11,11 L13,11 L16,11 C16.55,11 17,11.45 17,12 C17,12.55 16.55,13 16,13 Z" id="Path"></path>
          </g>
      </g>
    </svg>

  );

}

export default Minus;
