// Dependencies
import React from "react";

const Corporation = (props) => {
  return (
    <svg
      width={props.width ? props.width : "50px"}
      height={props.height ? props.height : "41px"}
      viewBox="0 0 50 41"
    >

        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Corporate" transform="translate(-7.000000, -11.000000)">
                <g id="noun_Suitcase_1431612" transform="translate(6.600000, 20.000000)">
                    <g id="Group" transform="translate(0.522936, 0.000000)">
                        <path d="M24.8348624,0.740183486 C23.357578,0.740183486 22.1004771,1.96322936 22.1004771,3.45695413 C22.1004771,4.95185321 23.357578,6.16785321 24.8348624,6.16785321 C26.3121468,6.16785321 27.5692477,4.95185321 27.5692477,3.45695413 C27.5692477,1.96322936 26.3115596,0.740183486 24.8348624,0.740183486 Z" id="Path" fill={props.color ? props.color : "#80abba"} fillRule="nonzero"></path>
                        <path d="M0.87706422,9.3 L49.3486109,9.3" id="Path-3" stroke={props.color ? props.color : "#80abba"} strokeWidth="2"></path>
                    </g>
                </g>
                <rect id="Rectangle" stroke={props.color ? props.color : "#80abba"} strokeWidth="2" x="8" y="17" width="48" height="34" rx="4"></rect>
                <rect id="Rectangle-Copy" stroke={props.color ? props.color : "#80abba"} strokeWidth="2" x="24.4" y="12" width="15" height="5" rx="2"></rect>
            </g>
        </g>
    </svg>
  );
};

export default Corporation;
