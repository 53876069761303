// Dependencies
import React from 'react';

const AlertWarning = ({ width, height, color, bgColor }) => {

  return (

    <svg
      width={width ? width : '54px'}
      height={height ? height : '54px'}
      viewBox="0 0 54 54"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons-/-alerts-/-Aviso" transform="translate(-3.000000, -3.000000)" fillRule="nonzero">
          <circle id="Oval-2" fill={bgColor ? bgColor : '#80abba'} cx="30" cy="30" r="27"></circle>
          <path d="M35.9275996,22.9614223 L19.9621233,38.9268985 L41.1380441,44.137343 L35.9275996,22.9614223 Z M39.3879196,20.2832139 L45.4170734,44.7864749 C45.8129434,46.3953418 44.8296151,48.0205023 43.2207481,48.4163723 C42.7499124,48.5322238 42.2580116,48.5322238 41.7871759,48.4163723 L17.283915,42.3872185 C15.675048,41.9913485 14.6917197,40.3661881 15.0875897,38.7573211 C15.2184073,38.2256616 15.4922276,37.73994 15.8793807,37.3527868 L34.3534879,18.8786797 C35.5250608,17.7071068 37.4245557,17.7071068 38.5961286,18.8786797 C38.9832817,19.2658328 39.257102,19.7515544 39.3879196,20.2832139 Z" id="Rectangle-10" fill={color ? color : '#FFFFFF'} transform="translate(30.252332, 33.251630) rotate(-135.000000) translate(-30.252332, -33.251630) "></path>
        </g>
      </g>
    </svg>

  );

}

export default AlertWarning;
