// Dependencies
import React from 'react';

// Components
import Icon from './icons';

const StackHeader = ({ title, icon, help, helpAction }) => {

  return (
    <div className="wrapper-stackHeader">
      <div className="wrapper-stackHeader-title">
        {icon}
        <span className="headingH1 stackHeader-title">{title}</span>
      </div>
      {
        help &&
        <button onClick={() => helpAction()} >
          <Icon name="Help" />
        </button>
      }
    </div>
  )
};

export default StackHeader;