import { HOST_URL, URL_VERSION } from '../constants';

import get_header from "./api_header";

export function updateBalanceDetailTab(payload, source) {
  return {
    type: "BALANCE_DETAIL_TAB",
    payload,
    source
  };
}
//http://localhost:3000/api/v1/balance_principal/linkables/destroy_linkable/?type_1=Corporation&id_1=15&type_2=Liability&id_2=21
export function paginateTab(authentication_token, params, success, error, dispatch_enabled = true) {
  var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  return (dispatch) => {
    return fetch(HOST_URL+'/api/v1/detail_tabs?'+queryString, {
      method: 'GET',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          console.log(responseJson)
          if(dispatch_enabled) {dispatch(updateBalanceDetailTab(responseJson.tables, params["source"]))}
          success(responseJson)
        })
        
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}



