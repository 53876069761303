const initialState = {
  home_data: {},
  filtered_data_bars_graph: {}
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case "HOME":
      return Object.assign({}, state, {
        home_data: action.payload
      });
    case "FILTER_DATA_BARS_GRAPH":
      return Object.assign({}, state, {
        filtered_data_bars_graph: action.payload
      });
    case "HOME_FORM":
    let home_data = JSON.parse(JSON.stringify(state.home_data))
    let tree_graphic_data = home_data.tree_graphic_data
    tree_graphic_data.multiple_new = action.payload.multiple_new
    tree_graphic_data.short_cuts = action.payload.short_cuts
    home_data.tree_graphic_data = tree_graphic_data
      return Object.assign({}, state, {
        home_data: home_data
      })
    default:
      return state;
  }
}
