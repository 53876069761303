// Dependencies
import React from "react";

// Components
import Modal from "../../components/modal";
import Icon from "../../components/icons";
import {TYPE_BUY, TYPE_DEPOSIT, TYPE_SELL, TYPE_SHELL_SHORT, TYPE_WITHDRAWAL, TYPE_ASSET_CREATED, TYPE_ASSET_DELETED, TYPE_VALUE_PLUS, TYPE_VALUE_MINUS} from '../../constants'

// Helpers
import { getValueLocalized } from "../../helpers/getLocale";

function notificationModalShow(props) {

  const isPositiveByType = (type) => {
    return [TYPE_VALUE_PLUS, TYPE_ASSET_CREATED].includes(type) 
  }

  return (
    <Modal
      modalType="notificationsModal"
      showBackButton={true}
      backAction={props.hideModal}
      buttonText={props.internalization.buttons.back}
    >
      {
        props.notification.value !== 0 && props.notification.value &&
        <p
          className="quantity"
          style={{
            color: props.notification.value > 0 ? "#408197" : "#f3b01c"
          }}
        >
          {
            getValueLocalized("en-US", props.notification.value, "$", "currency")
              .formated
          }
        </p>
      }

      <div className="subwrapper">
        <p className="body grey-dark item semi-bold">
          {props.notification.operation}
        </p>
        <p className="body grey-dark item regular">
          {props.notification.summary}
        </p>
        <p className="headingH5 blue-dark item " style={{marginTop: 15, fontWeight: "bold", fontSize: 20}}>
          {props.notification.asset_name}
        </p>
        <p className="headingH5 blue-dark item ">
          {props.notification.asset_type}
        </p>                
        <p className="body grey-dark item regular">
          {props.notification.description}
        </p>
      </div>
      <div className="subwrapper">
        {Object.keys(props.notification).length > 0 &&
          props.notification.content.map((element, index) => {
            return (
              <p
                key={"notification_show_" + index}
                className="body grey-dark item semi-bold"
              >
                {`${element.label}: `}
                <span className="body grey-dark item regular">
                  {element.value}
                </span>
              </p>
            );
          })}
      </div>
      {props.notification.asset && props.notification.url && Object.keys(props.notification).length > 0 && (
        <button
          className="btn primary"
          onClick={() => props.goToResource(props.notification.url)}
        >
          <span className="btn-text">{props.go_to_resource_text}</span>
        </button>
      )}
      {Object.keys(props.notification).length > 0 && props.notification.deleted && props.notification.permanently === false && (
         <div className="subwrapper">
          <p className="body grey-dark item regular " style={{marginTop: 15, marginBottom: 15}}>{props.notification.deleted_text}</p>
         </div>
      )}     
    </Modal>
  );
}
export default notificationModalShow;
