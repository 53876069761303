import React, {useState, useEffect} from 'react';


// Components
import Input from './input'
import CustomModal from './customModal'
import SelectMultiple from './selectMultiple'



const ContentModal = (props) => {

  const [inputstActualPerformance, setInputsActualPerformance] = useState({}) 
  const [requirementInput, setRequirementInput] = useState({})
  const [multipleRef, setMultipleRef] = useState(null);
  const [dynamicInputs, setDynamicInputs] = useState([])

  useEffect(() => {
    getParentElements()
    requirementInputs()
  }, [])

  const requirementInputs = () => {
    let getRequirementInputsObj = {}
    let getRequirementInputs = props.inputs.filter(item => item.required && !item.hidden)
    getRequirementInputs.map(item => {
      getRequirementInputsObj = {...getRequirementInputsObj,[item.id] : item.value !== "" ? true : false }
    })

    setRequirementInput(getRequirementInputsObj)
  }
  const getParentElements = (option) => {
 
    let parentElements
    if(option){
      parentElements = props.inputs.filter(item => item.parent !== "" && item.id !== option)
                        .map(elem => elem.id)
    }else{
      parentElements = props.inputs.filter(item => item.parent !== "" && item.value === "")
                        .map(elem => elem.id)
    }

    setDynamicInputs(parentElements)
  }

  const _getInputValues = (e, item) => {
    
    let regMaxDigits = new RegExp(/^\d{1,9}$/)

    let testRegex = e.floatValue !== undefined && regMaxDigits.test(parseFloat(e.floatValue))

    let inputsRequired = requirementInput
    
        if(item.id === "interest_rate" || testRegex || e.floatValue === undefined){
          setInputsActualPerformance({
            ...inputstActualPerformance,
            [item.id]: item.id === "interest_rate" ? e.floatValue :  Math.abs(e.floatValue),
          });
        }
 
        if(item.parent !== ""){
          inputsRequired[item.parent] = true
          setRequirementInput(inputsRequired)
        }
    

        if(item.required){
          setRequirementInput({
            ...inputsRequired,
            [item.id] :  e.floatValue !== undefined ? true : false
          })
        }
  }

  const _checkActualPerformanceInputs = () => {
    return !Object.values(requirementInput).every(elem => elem === true)
  }


  const _saveInputs = () => {
    props.saveActualPerformance(inputstActualPerformance)
  }

  const getOptions = (items, id, element) => {

    let allInputs = inputstActualPerformance
    let inputsRequired = requirementInput
    if(element.parent === ""){
      inputsRequired[element.id] = false
      getParentElements(items.item.related_fields[0])

      element.options.filter(item => items.item.id !== item.id)
                      .map(item => {
                        if(item.related_fields.length > 0){
                         delete allInputs[item.related_fields[0]]
                        }
                      } )
    }else {
      inputsRequired[element.parent] = true
    }


    setRequirementInput(inputsRequired)
    setInputsActualPerformance({
      ...inputstActualPerformance,
      [id]: items.item.id,
    });
  }

  const checkParentSelected = (element) => {
    if(dynamicInputs.includes(element.id)){
      return false
    }else {
      return true
    }
  }

 
  return (

    <CustomModal  close={() => props.closeModal()}>
      <div style={{width: "330px"}}>
      <div className="wrapper-form">
      <p style={{textAlign: 'center', marginBottom: '10px'}}>Actual Performance Year {props.toolTableTitle}</p>
        {
          props.inputs.map((element, index) => {
            
            if(element.hidden !== true && checkParentSelected(element)){
              return(

                <div className="wrapper-form-item">
                  <span className="input-label" style={{textTransform: 'uppercase'}}>{element.label}
                  <span className="asterisk"> *</span>
                  </span>
                  {
                    element.type !== "select" && 
                    <Input 
                      colorGrey={true}
                      defaultValue={inputstActualPerformance[element.id] ? inputstActualPerformance[element.id] : element.value}
                      value={inputstActualPerformance[element.id]}
                      type={element.type}
                      onBlurInput={() => ""}
                      name={element.id}
                      onChangeInput={(e) => _getInputValues(e,element)}
                    />
                  }
                  {
                    element.type === "select" && 
                    <SelectMultiple
                        key={"select_" + index}
                        index={index}
                        colorGrey={true}
                        setRef={(ref) => {
                          setMultipleRef(ref);
                        }}
                        defaultValue={
                          inputstActualPerformance[element.id] !== undefined
                            ? element.options.filter(
                                (item) => item.id === inputstActualPerformance[element.id]
                              ).length > 0
                              ? element.options.filter(
                                  (item) => item.id === inputstActualPerformance[element.id]
                                )[0].value
                              : ""
                            : element.options.filter(
                                (item) => item.id === element.value
                              )[0].value
                        }
                        label={false}
                        options={element.options}
                        multiple={false}
                        openOptions={() => {}}
                        //formData={_prepare_dates()}
                        //datesToEdit={datesToEdit}
                        getOptions={(items) =>
                          getOptions(items, element.id, element)
                        }
                      />
                  }
                    
                </div>
              )
            }
            
          })
        }
      </div>

      <button className="btn primary" style={{textTransform: 'uppercase'}} disabled={_checkActualPerformanceInputs()} onClick={() => _saveInputs()}>save</button> 
      </div>
    </CustomModal>
  );

}

export default ContentModal;
