import { HOST_URL, URL_VERSION } from '../constants';

import get_header from './api_header'
import {parseHeaders} from './api_header'


export function resetPassword(email, success, error) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/passwords', {
      method: 'POST',
      headers: get_header(""),
      body: JSON.stringify({ email: email})
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {
          success();
        })
      }
      else{
        error();
      }

    })
    .catch((error) => {
      console.error(error);
    });
  }
}