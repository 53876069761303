// Dependencies
import React from 'react';

const DocImageSquare = ({ width, height, color, bgColor }) => {

  return (

    <svg
      width={width ? width : '29px'}
      height={height ? height : '29px'}
      viewBox="0 0 29 29"
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group">
                <g id="SVG-Layer">
                    <g id="Symbols">
                        <g id="icons-/-docs-/-Image">
                            <g id="doc-Imagen">
                                <rect id="Rectangle" fill={bgColor ? bgColor : "#80abba"} x="0" y="0" width="29" height="29"></rect>
                                <path d="M20.8093974,19.815351 C21.0926378,19.4046468 21.25,18.9134817 21.25,18.3965336 L21.25,10.8761137 C21.25,9.49610664 20.131388,8.375 18.7539124,8.375 L11.2460876,8.375 C9.86922871,8.375 8.75,9.49548823 8.75,10.8761137 L8.75,18.3965336 C8.75,19.0558478 9.00682776,19.6726482 9.44953084,20.1322239 L12.4043478,17.3817632 L14.9283843,19.7312363 L18.2059742,16.0701437 L20.8093974,19.815351 Z M12.4127852,15.0708168 C11.3082157,15.0708168 10.4127852,14.1753863 10.4127852,13.0708168 C10.4127852,11.9662473 11.3082157,11.0708168 12.4127852,11.0708168 C13.5173547,11.0708168 14.4127852,11.9662473 14.4127852,13.0708168 C14.4127852,14.1753863 13.5173547,15.0708168 12.4127852,15.0708168 Z" id="Combined-Shape" stroke={color ? color : "#fff"} fill={color ? color : "#fff"} fillRule="nonzero"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>

  );

}

export default DocImageSquare;
