// Dependencies
import React from "react";

const LogoInsurance = ({ width, height, color, waveColor }) => {
  return (
    <svg
      width={width ? width : "125px"}
      height={height ? height : "63px"}
      viewBox="0 0 125 63"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="bt360-insurance-(1)">
          <g
            id="Group"
            transform="translate(0.000000, 7.440476)"
            fill={color ? color : "#005773"}
          >
            <path
              d="M0.798363095,47.8318452 C0.908482143,47.7224702 1.03794643,47.6622024 1.1875,47.6622024 C1.34747024,47.6622024 1.4672619,47.7224702 1.5766369,47.8318452 C1.67633929,47.9516369 1.72619048,48.0811012 1.72619048,48.2410714 C1.72619048,48.4002976 1.67633929,48.530506 1.56622024,48.640625 C1.46651786,48.75 1.33630952,48.8102679 1.18675595,48.8102679 C1.03645833,48.8102679 0.906994048,48.75 0.797619048,48.640625 C0.697916667,48.530506 0.648065476,48.4010417 0.648065476,48.2410714 C0.648809524,48.0818452 0.698660714,47.9419643 0.798363095,47.8318452 Z M1.62723214,49.1897321 L1.62723214,54.6116071 L0.758928571,54.6116071 L0.758928571,49.5491071 L1.62723214,49.1897321 Z"
              id="Shape"
              fillRule="nonzero"
            ></path>
            <path
              d="M12.686756,54.6116071 L12.686756,49.6688988 L13.5558036,49.6688988 L13.5558036,50.5580357 C13.8452381,50.0089286 14.5840774,49.5595238 15.4129464,49.5595238 C16.1622024,49.5595238 16.6912202,49.828869 16.9702381,50.3578869 C17.139881,50.6674107 17.1800595,51.186756 17.1800595,51.5565476 L17.1800595,54.6116071 L16.3013393,54.6116071 L16.3013393,51.796131 C16.3013393,51.4464286 16.2611607,51.0669643 16.1212798,50.8377976 C15.9315476,50.5081845 15.5721726,50.3683036 15.1324405,50.3683036 C14.6733631,50.3683036 14.09375,50.6078869 13.7946429,51.077381 C13.6651786,51.2671131 13.5751488,51.5267857 13.5751488,51.8861607 L13.5751488,54.6116071 L12.686756,54.6116071 Z"
              id="Path"
            ></path>
            <path
              d="M27.9092262,53.4040179 C28.4784226,53.733631 29.0074405,53.9032738 29.6465774,53.9032738 C30.3556548,53.9032738 30.6852679,53.6733631 30.6852679,53.1941964 C30.6852679,52.8645833 30.4255952,52.7552083 29.8363095,52.5453869 L29.2172619,52.3258929 C28.4880952,52.0565476 28.0491071,51.6770833 28.0491071,50.9479167 C28.0491071,50.0796131 28.6681548,49.5505952 29.8861607,49.5505952 C30.4151786,49.5505952 30.9546131,49.6607143 31.3936012,49.8303571 L31.3936012,50.7291667 C30.9040179,50.499256 30.4747024,50.3697917 29.90625,50.3697917 C29.296875,50.3697917 28.9575893,50.5595238 28.9575893,51.0089286 C28.9575893,51.2886905 29.1175595,51.4084821 29.6666667,51.6078869 L30.2358631,51.828125 C31.1547619,52.1778274 31.6034226,52.4672619 31.6034226,53.2358631 C31.6034226,54.234375 30.8244048,54.7135417 29.6361607,54.7135417 C29.0572917,54.7135417 28.4278274,54.59375 27.9084821,54.3244048 L27.9084821,53.4040179 L27.9092262,53.4040179 Z"
              id="Path"
            ></path>
            <path
              d="M46.5572917,53.813244 C46.1183036,54.4129464 45.4486607,54.7120536 44.5602679,54.7120536 C44.1205357,54.7120536 43.7217262,54.6421131 43.3616071,54.5119048 C43.0022321,54.3720238 42.7328869,54.1525298 42.5625,53.8325893 C42.4129464,53.483631 42.3325893,53.0639881 42.3325893,52.5647321 L42.3325893,49.9888393 L43.2008929,49.6599702 L43.2008929,52.5453869 C43.2008929,52.9345238 43.2708333,53.2440476 43.4002976,53.484375 C43.6696429,53.7938988 44.0595238,53.953125 44.5684524,53.953125 C45.0974702,53.953125 45.4873512,53.7938988 45.7462798,53.4642857 C45.875744,53.1941964 45.9456845,52.8950893 45.9456845,52.5453869 L45.9456845,49.9895833 L46.8244453,49.6607143 L46.8244453,52.5662202 C46.8266369,53.0446429 46.7366071,53.4635417 46.5572917,53.813244 Z"
              id="Path"
            ></path>
            <path
              d="M57.8950893,54.6116071 L57.8950893,49.6495536 L58.7641369,49.6495536 L58.7641369,50.6473214 C58.983631,50.0684524 59.563244,49.5691964 60.3519345,49.5691964 C60.7715774,49.5691964 61.1108631,49.6688988 61.390625,49.8690476 L60.9412202,50.6674107 C60.671875,50.4575893 60.3720238,50.3876488 60.1123512,50.3876488 C59.453125,50.3876488 58.9546131,51.0766369 58.8645833,51.4962798 C58.7946429,51.8459821 58.7849702,52.264881 58.7849702,52.5840774 L58.7849702,54.6108631 L57.8950893,54.6108631 L57.8950893,54.6116071 Z"
              id="Path"
            ></path>
            <path
              d="M76.5825893,49.7589286 L76.5825893,54.5215774 L75.6741071,54.5215774 L75.6741071,53.9627976 C75.1949405,54.4620536 74.6153274,54.7120536 73.936756,54.7120536 C73.2581845,54.7120536 72.6785714,54.4620536 72.1897321,53.9627976 C71.7105655,53.453869 71.4709821,52.844494 71.4709821,52.1354167 C71.4709821,51.4263393 71.7105655,50.8177083 72.1897321,50.3177083 C72.6793155,49.8184524 73.2581845,49.5691964 73.936756,49.5691964 C74.6160714,49.5691964 75.1949405,49.8087798 75.6741071,50.3080357 L75.6741071,49.9486607 L76.5825893,49.7589286 Z M75.1547619,53.3936012 C75.4739583,53.0438988 75.6339286,52.624256 75.6339286,52.1354167 C75.6339286,51.6458333 75.4739583,51.2366071 75.1547619,50.8876488 C74.8355655,50.5379464 74.4456845,50.3683036 73.9962798,50.3683036 C73.546875,50.3683036 73.1577381,50.5379464 72.8377976,50.8876488 C72.5186012,51.2373512 72.358631,51.6465774 72.358631,52.1354167 C72.358631,52.624256 72.5186012,53.0438988 72.8377976,53.3936012 C73.156994,53.7328869 73.546875,53.9025298 73.9962798,53.9025298 C74.4456845,53.9025298 74.8355655,53.733631 75.1547619,53.3936012 Z"
              id="Shape"
              fillRule="nonzero"
            ></path>
            <path
              d="M87.421131,54.6116071 L87.421131,49.6688988 L88.2901786,49.6688988 L88.2901786,50.5580357 C88.5796131,50.0089286 89.3184524,49.5595238 90.1473214,49.5595238 C90.8965774,49.5595238 91.4255952,49.828869 91.7046131,50.3578869 C91.874256,50.6674107 91.9144345,51.186756 91.9144345,51.5565476 L91.9144345,54.6116071 L91.0357143,54.6116071 L91.0357143,51.796131 C91.0357143,51.4464286 90.9955357,51.0669643 90.8556548,50.8377976 C90.6659226,50.5081845 90.3065476,50.3683036 89.8668155,50.3683036 C89.4077381,50.3683036 88.828125,50.6078869 88.5290179,51.077381 C88.3995536,51.2671131 88.3095238,51.5267857 88.3095238,51.8861607 L88.3095238,54.6116071 L87.421131,54.6116071 Z"
              id="Path"
            ></path>
            <path
              d="M105.030506,54.7217262 C103.403274,54.7217262 102.574405,53.6629464 102.574405,52.1354167 C102.574405,50.5379464 103.622768,49.5290179 105.15997,49.5290179 C105.739583,49.5290179 106.158482,49.6190476 106.578125,49.8087798 L106.578125,50.6875 C106.168899,50.4880952 105.80878,50.3482143 105.220238,50.3482143 C103.962054,50.3482143 103.473214,51.2269345 103.473214,52.1160714 C103.473214,53.203869 104.031994,53.9032738 105.160714,53.9032738 C105.68006,53.9032738 106.188988,53.7537202 106.678571,53.4241071 L106.678571,54.2827381 C106.198661,54.5721726 105.688988,54.7217262 105.030506,54.7217262 Z"
              id="Path"
            ></path>
            <path
              d="M119.573661,54.7217262 C117.926339,54.7217262 117.02753,53.6235119 117.02753,52.1153274 C117.02753,50.7075893 117.956101,49.5394345 119.583333,49.5394345 C121.040923,49.5394345 121.879464,50.438244 121.879464,51.905506 C121.879464,52.0052083 121.879464,52.2752976 121.869792,52.375 L117.915923,52.375 C117.925595,53.2135417 118.575149,53.9025298 119.653274,53.9025298 C120.691964,53.9025298 121.171131,53.5729167 121.650298,53.2633929 L121.650298,54.1324405 C121.251488,54.3824405 120.622024,54.7217262 119.573661,54.7217262 Z M121.031994,51.6264881 C121.031994,50.8876488 120.482887,50.3087798 119.574405,50.3087798 C118.665923,50.3087798 118.046875,50.828125 117.966518,51.6264881 L121.031994,51.6264881 Z"
              id="Shape"
              fillRule="nonzero"
            ></path>
            <path
              d="M0,0.324404762 L4.9077381,0.324404762 L4.9077381,18.9389881 C6.82514881,15.4977679 10.8869048,13.4107143 14.9479167,13.4107143 C22.563244,13.4107143 26.8504464,18.4873512 26.8504464,27.061756 C26.8504464,38.6815476 18.8400298,42.4605655 11.1130952,42.4605655 C7.16294643,42.4605655 3.38392857,41.6145833 0,40.4866071 L0,0.324404762 Z M11.563244,37.8355655 C17.0907738,37.8355655 21.7723214,34.9590774 21.7723214,27.4002976 C21.7723214,21.2522321 18.7834821,18.093006 13.4806548,18.093006 C10.4910714,18.093006 7.89583333,19.8980655 6.31622024,22.0416667 C5.47098214,23.2261905 5.01860119,25.0877976 5.01860119,27.1748512 L5.01860119,36.6510417 C6.54315476,37.2715774 8.46056548,37.8355655 11.563244,37.8355655 Z"
              id="Shape"
              fillRule="nonzero"
            ></path>
            <path
              d="M40.4293155,36.8764881 C40.2604167,36.3683036 40.2046131,35.4099702 40.2046131,34.4516369 L40.2046131,18.2061012 L47.4456845,18.2061012 L46.4300595,14.0885417 L40.2046131,14.0885417 L40.2046131,3.765625 L35.296875,5.76934524 L35.296875,34.6205357 C35.296875,36.8206845 35.4092262,38.4568452 35.9174107,39.5848214 C36.8764881,41.7284226 38.6815476,42.4613095 41.2202381,42.4613095 C42.6294643,42.4613095 43.8712798,42.1793155 44.9427083,41.7284226 L44.9427083,37.2157738 C44.0401786,37.6108631 43.0818452,37.8363095 42.4613095,37.8363095 C41.219494,37.8355655 40.7113095,37.4970238 40.4293155,36.8764881 Z"
              id="Path"
            ></path>
            <path
              d="M78.1822917,37.1458333 C76.5863095,34.452381 76.0372024,31.3102679 76.0372024,27.8690476 C76.0372024,20.4873512 80.077381,12.0587798 89.1547619,12.0587798 C93.1450893,12.0587798 95.6383929,12.9561012 97.2849702,13.6049107 L97.2849702,17.844494 C95.3891369,16.9962798 92.8958333,16.0989583 89.8534226,16.0989583 C82.921131,16.0989583 81.125,21.6354167 80.6264881,25.4255952 C80.5267857,26.3236607 80.5267857,27.171131 80.5267857,28.0193452 C81.8236607,25.1763393 85.7135417,23.3809524 89.2552083,23.3809524 C94.5416667,23.3809524 99.6793155,26.3236607 99.6793155,32.6078869 C99.6793155,39.0416667 94.2924107,42.5334821 88.2574405,42.5334821 C83.46875,42.531994 80.077381,40.3377976 78.1822917,37.1458333 Z M95.1897321,32.4575893 C95.1897321,29.2157738 92.8958333,26.7715774 88.3571429,26.7715774 C83.8683036,26.7715774 81.1748512,29.7641369 81.1748512,32.4575893 C81.1748512,36.1986607 84.8162202,38.3928571 88.0081845,38.3928571 C92.6465774,38.3928571 95.1897321,35.7991071 95.1897321,32.4575893 Z"
              id="Shape"
              fillRule="nonzero"
            ></path>
            <path
              d="M113.058036,42.5825893 C105.676339,42.5825893 101.636161,35.9494048 101.636161,27.2708333 C101.636161,18.5424107 106.52381,12.0089286 113.256696,12.0089286 C120.78869,12.0089286 124.778274,18.5424107 124.778274,27.2708333 C124.778274,35.9486607 120.539435,42.5825893 113.058036,42.5825893 Z M113.256696,16.1480655 C108.020089,16.1480655 106.075149,21.734375 106.075149,27.3199405 C106.075149,32.8563988 107.87128,38.4918155 113.207589,38.4918155 C118.544643,38.4918155 120.290179,32.8556548 120.290179,27.3199405 C120.289435,21.5848214 118.643601,16.1480655 113.256696,16.1480655 Z"
              id="Shape"
              fillRule="nonzero"
            ></path>
          </g>
          <path
            d="M44.0974702,16.5877976 C44.0974702,16.5877976 41.4352679,7.79092262 53.5483631,7.92410714 C60.8697917,8.05729167 65.9278274,13.1220238 79.5052083,11.2559524 C93.0825893,9.38988095 98.9397321,2.99181548 98.9397321,2.99181548 C98.9397321,2.99181548 86.0275298,16.5870536 67.125744,15.9203869 C61.3697917,15.71875 51.5513393,13.1227679 44.0974702,16.5877976 Z"
            id="Path"
            fill={waveColor ? waveColor : "#F3B01C"}
          ></path>
          <path
            d="M64.8363095,8.83928571 C66.3876488,9.23735119 68.0714286,9.58258929 69.9657738,9.79017857 C77.5052083,6.16592262 81.328125,0.0610119048 81.3690476,8.8817842e-16 C75.656994,6.88988095 64.9412202,8.82440476 64.8363095,8.83928571 Z"
            id="Path"
            fill={waveColor ? waveColor : "#F3B01C"}
          ></path>
          <path
            d="M68.1071429,33.7633929 L67.906994,33.6636905 C70.9494048,33.0654762 73.343006,30.6212798 73.343006,27.1302083 C73.343006,21.2447917 67.7068452,19.499256 63.6666667,19.499256 C60.2276786,19.499256 57.4501488,20.4151786 55.827381,21.0974702 L54.6428571,25.8318452 C55.7938988,25.28125 59.530506,23.5892857 62.7686012,23.5892857 C65.2619048,23.5892857 68.5543155,24.6369048 68.5543155,28.1778274 C68.5543155,30.671875 66.110119,32.6168155 61.4724702,32.6168155 L61.4724702,35.6592262 C65.4122024,35.6592262 69.1034226,36.8065476 69.1034226,40.4970238 C69.1034226,44.4873512 65.9114583,45.8839286 63.1183036,45.8839286 C59.6272321,45.8839286 55.8861607,44.3876488 54.0409226,43.5892857 L54.0409226,47.828869 C55.686756,48.5766369 58.828869,49.9739583 64.0662202,49.9739583 C67.3080357,49.9739583 74.090774,48.1785714 74.090774,41.1458333 C74.0915179,37.3541667 71.6979167,34.5111607 68.1071429,33.7633929 Z"
            id="Path"
            fill={color ? color : "#005773"}
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default LogoInsurance;
