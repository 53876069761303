import { combineReducers } from 'redux'

import session from './session'
import home from './home'
import balance from './balance'
import documents from './documents'
import notifications from './notifications'
import articles from './articles'
import market from './market'

import ownership from './ownership'

const rootReducer = combineReducers({
  session,  
  home,
  balance,
  documents,
  notifications,
  ownership,
  articles,
  market
})

export default rootReducer
