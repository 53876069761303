// Dependencies
import React from "react";
import Slider from "react-slick";

// Constants
import {internalization} from '../constants.js'

// Components
import Icon from "./icons";

const ModalPermission = (props) => {
 const IMAGES = [{image: './WEB-SCREENSHOT-1.jpg'}, {image: './WEB-SCREENSHOT-2.jpg'}, {image: './WEB-SCREENSHOT-3.jpg'}]

  var languageUserAgent = (/^es/).test(props.language) ? 'es' : 'en';

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", zIndex: 1, right:'18px' }}
      onClick={onClick}
    >
      <Icon  color={'white'} name="SingleArrow" orientation={"right"}/>
    </div>
  );
}

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", zIndex: 1, left: '10px' }}
      onClick={onClick}
    >
        <Icon  color={'white'} name="SingleArrow" orientation={"left"}/>
    </div>
  );
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  adaptiveHeight: true,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
};



  return (
    <div className="modal-permission" >
      <p className="headingH3 modal-permission__title">{internalization[languageUserAgent].text_access_restricted_title}</p>
      
      <div className="modal-permission__content">
      <div className="modal-permission__content__text"
          dangerouslySetInnerHTML={{
            __html: internalization[languageUserAgent].text_access_restricted
          }}
        >
        </div>
        <Slider {...settings}>
        {
          IMAGES.map((item, index) => {
            return(
              <img key={"permission_"+index} src={item.image} alt=""/>
            )
          })
        }
        </Slider>
        
      </div>
    </div>
  );
};

export default ModalPermission;
