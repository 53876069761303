// Dependencies
import React from 'react';


const ZoomIn = ({ width, height, color, orientation, bgColor}) => {

  return (

    <svg
      width={width ? width : '18px'}
      height={height ? height : '18px'}
      viewBox="0 0 18 18"
    >
<defs><clipPath id="111122103119116991019710199"><path d="M2,0 C3.1045695,0 4,0.80588745 4,1.8 L4,16.2 C4,17.1941125 3.1045695,18 2,18 C0.8954305,18 0,17.1941125 0,16.2 L0,1.8 C0,0.80588745 0.8954305,0 2,0 Z"></path></clipPath><clipPath id="106100989711254106048118"><path d="M16.2,0 C17.1941125,0 18,0.8954305 18,2 C18,3.1045695 17.1941125,4 16.2,4 L1.8,4 C0.80588745,4 0,3.1045695 0,2 C0,0.8954305 0.80588745,0 1.8,0 L16.2,0 Z"></path></clipPath></defs><g transform="translate(7.0 0.0)"><g clip-path="url(#111122103119116991019710199)"><polygon points="0,0 4,0 4,18 0,18 0,0" stroke="none" fill="#FFFFFF"></polygon></g></g><g transform="translate(0.0 7.0)"><g clip-path="url(#106100989711254106048118)"><polygon points="0,0 18,0 18,4 0,4 0,0" stroke="none" fill="#FFFFFF"></polygon></g></g>
    </svg>
  );

}

export default ZoomIn
