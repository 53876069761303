import React, { useState, useEffect, useRef } from 'react';
import TextEllipsis from "react-text-ellipsis";
import classNames from "classnames";

import TableDropdownSearcher from './TableDropdownSearcher'

//Helpers
import Icon from "./icons";

let rowIndex = 0;

const TableAlternatives = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [dataTable, setDataTable] = useState(props.dataTable);

  const tableRef = useRef();
  const rowRef = useRef([]);

  useEffect(() => {
    tableRef.current.style.opacity = 1;
  }, []);

 useEffect(() => {
   tableClasses();
  }, [props.selectedIndex]);

  const tableClasses = () => {
    let cl = classNames(
      "c-table-alternatives",
      props.selectedIndex >= 0 && 'has-row-selected',
    );

    return cl;
  };

  const headerCells = (isTag) => {
    let cl = classNames(
      "c-table-alternatives__head-cell",
      isTag && "c-table-alternatives__head-cell--tag"
    );

    return cl;
  };

  const rowClasses = (index, rowName, entity_id) => {
    console.log(props.fromSearch)
    let cl = classNames(
      "c-table-alternatives__row",
      isTotals(index) && "c-table-alternatives__row--totals",
      isHighlighted().includes(index) && "c-table-alternatives__row--main",
      props.pieSliceSelected === rowName && "is-selected",
      props.fromSearch !== undefined && entity_id+"" === props.fromSearch+"" && "preselected is-shown"
    );
    return cl;
  };

  const rowCellTextClasses = (indexRows, indexRow, value) => {
    let cl = classNames(
      "c-table-alternatives__text",
      indexRow === 0 && !isTotals(indexRows)
        ? value.main
          ? "c-table-alternatives__text--left"
          : "c-table-alternatives__text--right"
        : "",
      value.highlighted && "c-table-alternatives__text--highlighted"
    );
    return cl;
  };

  const cellHeaderTextClasses = (tooltip) => {
    let cl = classNames(
      "c-table-alternatives__text",
      tooltip && "c-table-alternatives__text--tooltip"
    );
    return cl;
  };

  const isTotals = (index) => {
    // return dataTable.totals && dataTable.rows.length - 1 === index;
    return dataTable.rows.find(row => row[0].value === 'Total') && dataTable.rows.length - 1 === index;
  };

  const isHighlighted = (index) => {
    let rowIndex = [];

    dataTable.rows.map((rows, indexRows) =>
      rows.map((row) => {
        if (row.main) {
          rowIndex = [...rowIndex, indexRows];
        }
      })
    );

    return rowIndex;
  };

  const handleTooltip = (e, index) => {
    e.stopPropagation();

    if (showTooltip === false || showTooltip !== index) {
      setShowTooltip(index);
    } else {
      setShowTooltip(false);
    }
  };

 const _navigateToAsset = (row) => {
    props.history.push(
      "balance-detail?url=" + row.link,
      {
        url: "balance_principal/alternative_assets/",
        id: row.id,
      }
    );
  }


  const handleArrowClick = (parent) => {

    let parentIndex = parent.dataset.isParent

    let arrow = parent.querySelector('.c-table-alternatives__icon-arrow')
    arrow.classList.toggle('is-rotate')

    let offspring = [...tableRef.current.querySelectorAll(`[data-has-parent="${parentIndex}"]`)] 
    offspring.forEach(item => {
      item.classList.toggle('is-shown')
      if(item.classList.contains("preselected")){
        if(item.classList.contains("is-shown")){
          item.classList.remove("is-shown")
        }
        else{
          item.classList.add("is-shown")
        }
      }
    })

  }


  const renderCells = (row, indexRows) => {
    return row.map((value, indexRow) => {
      return (
        <div 
          key={`${indexRow}-indexRow-alternatives`} 
          className="c-table-alternatives__cell"
        >

          {indexRow === 0 && !isTotals(indexRows) && value.main && (
            <button onClick={() => handleArrowClick(rowRef.current[indexRows])}> 
              <Icon name="SingleArrow" orientation="down" className="c-table-alternatives__icon-arrow" />
            </button>
          )}

          <span className={rowCellTextClasses(indexRows, indexRow, value)}>
            {value.value}
          </span>

          {value.link && value.link !== "" && (
            <div onClick={() => _navigateToAsset(value)} className="c-table-alternatives__icon-link">
              <Icon
                name="Link"
                color={"#005774"}
                width={"16px"}
                height={"16px"}
              />
            </div>
          )}
          {value.tooltip && (
            <span
              className="wrapper-helper no-margin"
              onClick={(e) =>
                  handleTooltip(e, `${indexRow}-indexRow-alternatives-${indexRows}`)
              }
            >
              <div className="helper-text">
                <Icon name="InfoTooltip" noWrapper={true}/>
              </div>
              {showTooltip ===
                  `${indexRow}-indexRow-alternatives-${indexRows}` && (
                    <div
                      className="container-tooltip top container-tooltip--no-triangle"
                      onClick={(e) => handleTooltip(e, false)}
                    >
                      <span className="tooltip-title">{value.title}</span>
                      <span className="tooltip-text">{value.tooltip_text}</span>
                    </div>
                  )}
            </span>
          )}
        </div>
      );
    })
  }

  const closeAllRows = () => {
    let rows = [...tableRef.current.querySelectorAll('.c-table-alternatives__row')]

    rows.forEach(row => {
      row.removeAttribute("style")
      if (row.classList.contains("is-shown")) {
        row.classList.remove("is-shown");
      }
      if (row.classList.contains("preselected")){
        row.classList.remove("is-shown"); 
      }
    })

    let arrows = [...tableRef.current.querySelectorAll('.c-table-alternatives__icon-arrow ')]
    arrows.forEach(arrow => {
      if(arrow.classList.contains('is-rotate')) {
        arrow.classList.remove('is-rotate')
      }
    })
  }


  const showAllArrows = () => {
    const arrows = [...tableRef.current.querySelectorAll('.c-table-alternatives__icon-arrow')]
    arrows.forEach(arrow => arrow.style.display = 'flex')
  }

  const filterResult = (result) => {
    let rows = [...tableRef.current.querySelectorAll('.c-table-alternatives__row')] 
    let parentId = rows.map(row => row.getAttribute('data-has-parent'))
    let parentIdArray = [...new Set(parentId)].filter(item => item !== undefined && item !== null)
    let mainRows = [...tableRef.current.querySelectorAll('[data-is-parent]')]

    if (parentIdArray.length > 0) {
      mainRows.forEach(mainRow => {
        let mainRowCode = mainRow.getAttribute('data-is-parent')
        if (parentIdArray.indexOf(mainRowCode) === -1) {
          mainRow.style.display = 'none'
        }       
      })
    } else {
      // Here we're mapping result to access to showIt key
      result.map((row, index) => {
        if (!row[0].showIt) {
          mainRows[index].style.display = 'none'
        }else {
          // hide arrow when main row without children is shown
          let arrow = mainRows[index].querySelector('.c-table-alternatives__icon-arrow')
          arrow.style.display = 'none'
        }         
      })
    }
  }

  const handleSearchResult = (result, isFiltered) => {
    
    closeAllRows()

    // paint new rows
    setDataTable(prevDataTable => ({...prevDataTable, rows: result}))

    // reset All arrows
    showAllArrows()

    // if isFiltered we gonna delete unnecessary mainRows
    if (isFiltered) {
      filterResult(result)
    }else{
      props.clearSearch && props.clearSearch()
    }
    
  }

  return (
    <>
      {
        props.search && (
          <TableDropdownSearcher 
            fromSearchText={props.fromSearchText}
            originData={props.dataTable.rows} 
            result={(result, isFiltered) => handleSearchResult(result, isFiltered)}
            isDropDown={true}
          />
        )
      }


      <div className={tableClasses()} ref={tableRef}>
        <div className="c-table-alternatives__head">
          <div className="c-table-alternatives__head-row">
            {dataTable.headerTag && (
              <div className="c-table-alternatives__tag">
                <span className="c-table-alternatives__tag__text">Total Weighted Return</span>
                <span
                  className="wrapper-helper height-auto"
                  onClick={(e) => handleTooltip(e, -1)}
                >
                  <div className="helper-text white">
                    <Icon name="InfoTooltip" noWrapper={true}/>
                  </div>
                  {showTooltip === -1 && (
                    <div
                      className="container-tooltip top container-tooltip--no-triangle"
                      onClick={(e) => handleTooltip(e, false)}
                    >
                      <span className="tooltip-title">{dataTable.extra_data["total_weighted_return"].title}</span>
                      <span className="tooltip-text" dangerouslySetInnerHTML={{ __html: dataTable.extra_data["total_weighted_return"].tooltip_text }}></span>
                    </div>
                  )}
                </span>
              </div>
            )}

            {dataTable.headers && dataTable.headers.map((header, indexHead) => {
              let tooltipWidth = header.tooltip ? 40 : 0
              let sortWidth = header.sort ? 24 : 0
              return (
                <div
                  key={`${indexHead}-indexHead-alternatives`}
                  className={headerCells(header.tag)}
                  style={{paddingLeft: (tooltipWidth + sortWidth) / 2, paddingRight: (tooltipWidth + sortWidth) / 2}}
                >
                  <div 
                    className={cellHeaderTextClasses(header.tooltip)} 
                    style={{maxWidth: `calc(100% - (${tooltipWidth}px + ${sortWidth}px))` }}
                  >
                    <span>{header.title}</span>
                  </div>

                  <div className="c-table__head-actions">
                    {header.tooltip && (
                      <span
                        className="wrapper-helper no-margin"
                        onClick={(e) => handleTooltip(e, indexHead)}
                      >
                        <div className="helper-text white">
                          <Icon name="InfoTooltip" noWrapper={true}/>
                        </div>
                        {showTooltip === indexHead && (
                          <div
                            className="container-tooltip top container-tooltip--no-triangle"
                            onClick={(e) => handleTooltip(e, false)}
                          >
                            <span className="tooltip-title">{header.title}</span>
                            <span className="tooltip-text">{header.tooltip_text}</span>
                          </div>
                        )}
                      </span>
                    )}

                    {
                      header.sort && (
                        <div className="c-table__head-sort">
                          <div className="c-table__head-sort--up" onClick={(e) => alert('Sorting up...')}>
                            <Icon name="SingleArrow" orientation='up' noWrapper={true} />
                          </div>
                          <div className="c-table__head-sort--down" onClick={(e) => alert('Sorting down...')}>
                            <Icon name="SingleArrow" orientation='down' noWrapper={true} />
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="c-table-alternatives__body">
          {dataTable.rows && dataTable.rows.map((row, indexRows) => {

            if (isHighlighted().includes(indexRows)) {
              rowIndex = indexRows
            }

            return (
              <div
                key={`${indexRows}-indexHead-alternatives`}
                className={rowClasses(indexRows, row[0].value,row[0].entity_id)}
                ref={el => rowRef.current[indexRows] = el}
                data-has-parent={!isHighlighted().includes(indexRows) && !isTotals(indexRows) ? rowIndex : null}
                data-is-parent={isHighlighted().includes(indexRows) ? indexRows : null}
              >

                { renderCells(row, indexRows) }

              </div>
            );
          })}
        </div>
      </div>
    </>
  )

}

export default TableAlternatives;
