// Dependencies
import React from "react";

const SingleArrow = ({ width, height, color, orientation }) => {
  return (
    <svg
      width={
        width
          ? width
          : orientation === "down" || orientation === "up"
          ? "14px"
          : "8px"
      }
      height={
        height
          ? height
          : orientation === "down" || orientation === "up"
          ? "8px"
          : "14px"
      }
      viewBox={
        orientation === "down" || orientation === "up" ? "0 0 14 8" : "0 0 8 14"
      }
    >
      {orientation === "up" && (
        <g
          id="Desktop"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="Net-Worth"
            transform="translate(-36.000000, -1460.000000)"
            fillRule="nonzero"
            stroke={color ? color : "#80abba"}
            strokeWidth="2"
          >
            <g id="Assets" transform="translate(15.000000, 813.000000)">
              <g id="Actividades" transform="translate(0.000000, 70.000000)">
                <g id="tabla" transform="translate(0.000000, 559.000000)">
                  <g id="Tabla-actividades">
                    <g
                      id="actividad"
                      transform="translate(10.000000, 4.000000)"
                    >
                      <g
                        id="icons-/-xtras-/-arrow-down"
                        transform="translate(18.000000, 18.000000) rotate(-180.000000) translate(-18.000000, -18.000000) "
                      >
                        <polyline id="Path-10" points="12 15 18 21 24 15" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      )}

      {orientation === "right" && (
        <g
          id="Desktop"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="Net-Worth"
            transform="translate(-39.000000, -1457.000000)"
            fillRule="nonzero"
            stroke={color ? color : "#80abba"}
            strokeWidth="2"
          >
            <g id="Assets" transform="translate(15.000000, 813.000000)">
              <g id="Actividades" transform="translate(0.000000, 70.000000)">
                <g id="tabla" transform="translate(0.000000, 559.000000)">
                  <g id="Tabla-actividades">
                    <g
                      id="actividad"
                      transform="translate(10.000000, 4.000000)"
                    >
                      <g
                        id="icons-/-xtras-/-arrow-down"
                        transform="translate(18.000000, 18.000000) rotate(-90.000000) translate(-18.000000, -18.000000) "
                      >
                        <polyline id="Path-10" points="12 15 18 21 24 15" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      )}

      {orientation === "down" && (
        <g
          id="Desktop"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="Net-Worth"
            transform="translate(-36.000000, -1460.000000)"
            fillRule="nonzero"
            stroke={color ? color : "#80abba"}
            strokeWidth="2"
          >
            <g id="Assets" transform="translate(15.000000, 813.000000)">
              <g id="Actividades" transform="translate(0.000000, 70.000000)">
                <g id="tabla" transform="translate(0.000000, 559.000000)">
                  <g id="Tabla-actividades">
                    <g
                      id="actividad"
                      transform="translate(10.000000, 4.000000)"
                    >
                      <g
                        id="icons-/-xtras-/-arrow-down"
                        transform="translate(18.000000, 18.000000) rotate(0.000000) translate(-18.000000, -18.000000) "
                      >
                        <polyline id="Path-10" points="12 15 18 21 24 15" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      )}

      {orientation === "left" && (
        <g
          id="Desktop"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="Net-Worth"
            transform="translate(-39.000000, -1457.000000)"
            fillRule="nonzero"
            stroke={color ? color : "#80abba"}
            strokeWidth="2"
          >
            <g id="Assets" transform="translate(15.000000, 813.000000)">
              <g id="Actividades" transform="translate(0.000000, 70.000000)">
                <g id="tabla" transform="translate(0.000000, 559.000000)">
                  <g id="Tabla-actividades">
                    <g
                      id="actividad"
                      transform="translate(10.000000, 4.000000)"
                    >
                      <g
                        id="icons-/-xtras-/-arrow-down"
                        transform="translate(18.000000, 18.000000) rotate(90.000000) translate(-18.000000, -18.000000) "
                      >
                        <polyline id="Path-10" points="12 15 18 21 24 15" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      )}
    </svg>
  );
};

export default SingleArrow;
