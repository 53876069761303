// Dependencies
import React from 'react';

const Download = ({ width, height, color }) => {

  return (

    <svg
      width={width ? width : '20px'}
      height={height ? height : '20px'}
      viewBox="0 0 20 20"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons-/-tools-/-download" transform="translate(-8.000000, -8.000000)" fill={ color ? color : "#80abba" } fillRule="nonzero">
          <path d="M17,19.7892053 L17,9.08280255 C17,8.48478721 17.5155509,8 18.1515152,8 C18.7874794,8 19.3030303,8.48478721 19.3030303,9.08280255 L19.3030303,19.6523591 L22.110609,16.41776 C22.5639406,15.9644284 23.2809944,15.9464864 23.7121936,16.3776855 C24.1433927,16.8088847 24.1254508,17.5259385 23.6721191,17.9792701 L18.8288574,23.4343262 C18.3755258,23.8876578 17.658472,23.9055997 17.2272728,23.4744006 L12.3839978,17.9792701 C11.9527987,17.548071 11.9707406,16.8310172 12.4240723,16.3776855 C12.8774039,15.9243539 13.5944577,15.906412 14.0256568,16.3376111 L17,19.7892053 Z M8.90909091,28 C8.40701386,28 8,27.5152128 8,26.9171975 C8,26.3191821 8.40701386,25.8343949 8.90909091,25.8343949 L27.0909091,25.8343949 C27.5929861,25.8343949 28,26.3191821 28,26.9171975 C28,27.5152128 27.5929861,28 27.0909091,28 L8.90909091,28 Z" id="Combined-Shape"></path>
        </g>
      </g>
    </svg>

  );

}

export default Download;
