// Dependencies
import React from "react";

const Fullscreen = ({ width, height, color, orientation, bgColor }) => {
  return (
    <svg width={width ? width : "19px"} height={height ? height : "18px"} viewBox="0 0 19 18">
      <defs>
        <clipPath id="12199112106551114855100121">
          <path d="M0.761574001,0 C1.18217971,0 1.523148,0.340968294 1.523148,0.761574001 L1.523148,7.23495301 C1.523148,7.65555871 1.18217971,7.99652701 0.761574001,7.99652701 C0.340968294,7.99652701 0,7.65555871 0,7.23495301 L0,0.761574001 C0,0.340968294 0.340968294,0 0.761574001,0 Z"></path>
        </clipPath>
        <clipPath id="102114107120122495410411548">
          <path d="M1.34296528,0.269657319 L3.43729379,2.74477282 C3.70898078,3.06585745 3.66893608,3.54639379 3.34785145,3.81808079 C3.02676683,4.08976778 2.54623048,4.04972308 2.27454349,3.72863846 L0.180214988,1.25352295 C-0.0914720046,0.932438325 -0.0514273093,0.451901981 0.269657319,0.180214988 C0.590741946,-0.0914720046 1.07127829,-0.0514273093 1.34296528,0.269657319 Z"></path>
        </clipPath>
        <clipPath id="1109550011210545111109119">
          <path d="M1.34296528,0.269657319 L3.43729379,2.74477282 C3.70898078,3.06585745 3.66893608,3.54639379 3.34785145,3.81808079 C3.02676683,4.08976778 2.54623048,4.04972308 2.27454349,3.72863846 L0.180214988,1.25352295 C-0.0914720046,0.932438325 -0.0514273093,0.451901981 0.269657319,0.180214988 C0.590741946,-0.0914720046 1.07127829,-0.0514273093 1.34296528,0.269657319 Z"></path>
        </clipPath>
        <clipPath id="11011510110452951044511345">
          <path d="M0.761574001,0 C1.18217971,0 1.523148,0.340968294 1.523148,0.761574001 L1.523148,7.23495301 C1.523148,7.65555871 1.18217971,7.99652701 0.761574001,7.99652701 C0.340968294,7.99652701 0,7.65555871 0,7.23495301 L0,0.761574001 C0,0.340968294 0.340968294,0 0.761574001,0 Z"></path>
        </clipPath>
        <clipPath id="104114954510811211212148120">
          <path d="M1.34296528,0.269657319 L3.43729379,2.74477282 C3.70898078,3.06585745 3.66893608,3.54639379 3.34785145,3.81808079 C3.02676683,4.08976778 2.54623048,4.04972308 2.27454349,3.72863846 L0.180214988,1.25352295 C-0.0914720046,0.932438325 -0.0514273093,0.451901981 0.269657319,0.180214988 C0.590741946,-0.0914720046 1.07127829,-0.0514273093 1.34296528,0.269657319 Z"></path>
        </clipPath>
        <clipPath id="484548103119955610550111">
          <path d="M1.34296528,0.269657319 L3.43729379,2.74477282 C3.70898078,3.06585745 3.66893608,3.54639379 3.34785145,3.81808079 C3.02676683,4.08976778 2.54623048,4.04972308 2.27454349,3.72863846 L0.180214988,1.25352295 C-0.0914720046,0.932438325 -0.0514273093,0.451901981 0.269657319,0.180214988 C0.590741946,-0.0914720046 1.07127829,-0.0514273093 1.34296528,0.269657319 Z"></path>
        </clipPath>
        <clipPath id="11410845108110116106112117117">
          <path d="M0.761574001,0 C1.18217971,0 1.523148,0.340968294 1.523148,0.761574001 L1.523148,7.23495301 C1.523148,7.65555871 1.18217971,7.99652701 0.761574001,7.99652701 C0.340968294,7.99652701 0,7.65555871 0,7.23495301 L0,0.761574001 C0,0.340968294 0.340968294,0 0.761574001,0 Z"></path>
        </clipPath>
        <clipPath id="0541125201171075312154">
          <path d="M1.34296528,0.269657319 L3.43729379,2.74477282 C3.70898078,3.06585745 3.66893608,3.54639379 3.34785145,3.81808079 C3.02676683,4.08976778 2.54623048,4.04972308 2.27454349,3.72863846 L0.180214988,1.25352295 C-0.0914720046,0.932438325 -0.0514273093,0.451901981 0.269657319,0.180214988 C0.590741946,-0.0914720046 1.07127829,-0.0514273093 1.34296528,0.269657319 Z"></path>
        </clipPath>
        <clipPath id="107102120103113555410650114">
          <path d="M1.34296528,0.269657319 L3.43729379,2.74477282 C3.70898078,3.06585745 3.66893608,3.54639379 3.34785145,3.81808079 C3.02676683,4.08976778 2.54623048,4.04972308 2.27454349,3.72863846 L0.180214988,1.25352295 C-0.0914720046,0.932438325 -0.0514273093,0.451901981 0.269657319,0.180214988 C0.590741946,-0.0914720046 1.07127829,-0.0514273093 1.34296528,0.269657319 Z"></path>
        </clipPath>
        <clipPath id="115116991011221095652120109">
          <path d="M0.761574001,0 C1.18217971,0 1.523148,0.340968294 1.523148,0.761574001 L1.523148,7.23495301 C1.523148,7.65555871 1.18217971,7.99652701 0.761574001,7.99652701 C0.340968294,7.99652701 0,7.65555871 0,7.23495301 L0,0.761574001 C0,0.340968294 0.340968294,0 0.761574001,0 Z"></path>
        </clipPath>
        <clipPath id="53109102501175611811610499">
          <path d="M1.34296528,0.269657319 L3.43729379,2.74477282 C3.70898078,3.06585745 3.66893608,3.54639379 3.34785145,3.81808079 C3.02676683,4.08976778 2.54623048,4.04972308 2.27454349,3.72863846 L0.180214988,1.25352295 C-0.0914720046,0.932438325 -0.0514273093,0.451901981 0.269657319,0.180214988 C0.590741946,-0.0914720046 1.07127829,-0.0514273093 1.34296528,0.269657319 Z"></path>
        </clipPath>
        <clipPath id="11810948012050119106560">
          <path d="M1.34296528,0.269657319 L3.43729379,2.74477282 C3.70898078,3.06585745 3.66893608,3.54639379 3.34785145,3.81808079 C3.02676683,4.08976778 2.54623048,4.04972308 2.27454349,3.72863846 L0.180214988,1.25352295 C-0.0914720046,0.932438325 -0.0514273093,0.451901981 0.269657319,0.180214988 C0.590741946,-0.0914720046 1.07127829,-0.0514273093 1.34296528,0.269657319 Z"></path>
        </clipPath>
      </defs>
      <g transform="translate(0.11534533610577569 0.08490208556690959)">
        <g transform="translate(8.704148513061227e-14 4.316618325456615) rotate(-47.0)">
          <g transform="translate(2.2847381370967748 1.6134894741171024e-05)">
            <g clip-path="url(#12199112106551114855100121)">
              <polygon
                points="-2.84217094e-14,-5.68434189e-14 1.523148,-5.68434189e-14 1.523148,7.99652701 -2.84217094e-14,7.99652701 -2.84217094e-14,-5.68434189e-14"
                stroke="none"
                fill="#FFFFFF"
              ></polygon>
            </g>
          </g>
          <g transform="translate(2.284722002202169 0.0)">
            <g clip-path="url(#102114107120122495410411548)">
              <polygon
                points="-4.26325641e-14,-5.68156633e-14 3.61750877,-5.68156633e-14 3.61750877,3.99829577 -4.26325641e-14,3.99829577 -4.26325641e-14,-5.68156633e-14"
                stroke="none"
                fill="#FFFFFF"
              ></polygon>
            </g>
          </g>
          <g transform="translate(3.617508773276114 0.0) scale(-1.0 1.0)">
            <g clip-path="url(#1109550011210545111109119)">
              <polygon
                points="2.83939539e-14,-5.68156633e-14 3.61750877,-5.68156633e-14 3.61750877,3.99829577 2.83939539e-14,3.99829577 2.83939539e-14,-5.68156633e-14"
                stroke="none"
                fill="#FFFFFF"
              ></polygon>
            </g>
          </g>
        </g>
        <g transform="translate(17.999999999999922 4.316618325456659) rotate(47.0) scale(-0.9999999999999999 0.9999999999999999)">
          <g transform="translate(2.2847381370966757 1.6134894741171024e-05)">
            <g clip-path="url(#11011510110452951044511345)">
              <polygon
                points="-5.68434189e-14,2.84217094e-14 1.523148,2.84217094e-14 1.523148,7.99652701 -5.68434189e-14,7.99652701 -5.68434189e-14,2.84217094e-14"
                stroke="none"
                fill="#FFFFFF"
              ></polygon>
            </g>
          </g>
          <g transform="translate(2.284722002202181 -2.4646951146678475e-14)">
            <g clip-path="url(#104114954510811211212148120)">
              <polygon
                points="2.77555756e-17,2.8449465e-14 3.61750877,2.8449465e-14 3.61750877,3.99829577 2.77555756e-17,3.99829577 2.77555756e-17,2.8449465e-14"
                stroke="none"
                fill="#FFFFFF"
              ></polygon>
            </g>
          </g>
          <g transform="translate(3.6175087732761146 1.2434497875801753e-14) scale(-1.0 1.0)">
            <g clip-path="url(#484548103119955610550111)">
              <polygon
                points="5.68711744e-14,2.13440376e-14 3.61750877,2.13440376e-14 3.61750877,3.99829577 5.68711744e-14,3.99829577 5.68711744e-14,2.13440376e-14"
                stroke="none"
                fill="#FFFFFF"
              ></polygon>
            </g>
          </g>
        </g>
        <g transform="translate(-8.881784197001252e-16 12.930135329612218) rotate(47.0) scale(0.9999999999999999 -0.9999999999999999)">
          <g transform="translate(2.2847381370967748 1.613489476559593e-05)">
            <g clip-path="url(#11410845108110116106112117117)">
              <polygon
                points="0,4.97379915e-14 1.523148,4.97379915e-14 1.523148,7.99652701 0,7.99652701 0,4.97379915e-14"
                stroke="none"
                fill="#FFFFFF"
              ></polygon>
            </g>
          </g>
          <g transform="translate(2.2847220022020833 4.929390229335695e-14)">
            <g clip-path="url(#0541125201171075312154)">
              <polygon
                points="-5.68434189e-14,2.8449465e-14 3.61750877,2.8449465e-14 3.61750877,3.99829577 -5.68434189e-14,3.99829577 -5.68434189e-14,2.8449465e-14"
                stroke="none"
                fill="#FFFFFF"
              ></polygon>
            </g>
          </g>
          <g transform="translate(3.617508773276115 0.0) scale(-1.0 1.0)">
            <g clip-path="url(#107102120103113555410650114)">
              <polygon
                points="5.68156633e-14,2.13440376e-14 3.61750877,2.13440376e-14 3.61750877,3.99829577 5.68156633e-14,3.99829577 5.68156633e-14,2.13440376e-14"
                stroke="none"
                fill="#FFFFFF"
              ></polygon>
            </g>
          </g>
        </g>
        <g transform="translate(17.999999999999993 12.930135329612211) rotate(133.0)">
          <g transform="translate(2.284738137096676 1.613489483975883e-05)">
            <g clip-path="url(#115116991011221095652120109)">
              <polygon
                points="0,5.68434189e-14 1.523148,5.68434189e-14 1.523148,7.99652701 0,7.99652701 0,5.68434189e-14"
                stroke="none"
                fill="#FFFFFF"
              ></polygon>
            </g>
          </g>
          <g transform="translate(2.2847220022020585 9.880984919163893e-14)">
            <g clip-path="url(#53109102501175611811610499)">
              <polygon
                points="-2.83939539e-14,5.55111512e-17 3.61750877,5.55111512e-17 3.61750877,3.99829577 -2.83939539e-14,3.99829577 -2.83939539e-14,5.55111512e-17"
                stroke="none"
                fill="#FFFFFF"
              ></polygon>
            </g>
          </g>
          <g transform="translate(3.61750877327614 0.0) scale(-1.0 1.0)">
            <g clip-path="url(#11810948012050119106560)">
              <polygon
                points="1.41830991e-14,5.55111512e-17 3.61750877,5.55111512e-17 3.61750877,3.99829577 1.41830991e-14,3.99829577 1.41830991e-14,5.55111512e-17"
                stroke="none"
                fill="#FFFFFF"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Fullscreen;
