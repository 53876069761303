// Dependencies
import React from "react";

const BankAccount = (props) => {
  return (
    <svg
      width={props.width ? props.width : "45px"}
      height={props.height ? props.height : "48px"}
      viewBox="0 0 45 48"
    >
      
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Bank-Account" transform="translate(-8.000000, -7.000000)">
            <g id="Group-2" transform="translate(9.129883, 8.062744)">
                <g id="dollar" transform="translate(17.958489, 16.239581)" fill={props.color ? props.color : "#80abba"} fillRule="nonzero">
                    <path d="M4.0214093,6.06976744 L2.67626512,6.06976744 C1.90992558,6.06976744 1.28649767,5.44633953 1.28649767,4.68004186 C1.28649767,3.91370233 1.90996744,3.29027442 2.67626512,3.29027442 L5.36651163,3.29027442 C5.71328372,3.29027442 5.9944186,3.00913953 5.9944186,2.66236744 C5.9944186,2.31559535 5.71328372,2.03446047 5.36651163,2.03446047 L3.97674419,2.03446047 L3.97674419,0.644693023 C3.97674419,0.29792093 3.6956093,0.0167860465 3.34883721,0.0167860465 C3.00206512,0.0167860465 2.72093023,0.29792093 2.72093023,0.644693023 L2.72093023,2.03446047 L2.67630698,2.03446047 C1.21751163,2.03446047 0.0307255814,3.22128837 0.0307255814,4.68004186 C0.0307255814,6.13879535 1.21755349,7.3255814 2.67630698,7.3255814 L4.02145116,7.3255814 C4.78774884,7.3255814 5.41117674,7.94905116 5.41117674,8.71534884 C5.41117674,9.48164651 4.78774884,10.1051163 4.02145116,10.1051163 L1.33120465,10.1051163 C0.984432558,10.1051163 0.703297674,10.3862512 0.703297674,10.7330233 C0.703297674,11.0797953 0.984432558,11.3609302 1.33120465,11.3609302 L2.72093023,11.3609302 L2.72093023,12.7507395 C2.72093023,13.0975116 3.00206512,13.3786465 3.34883721,13.3786465 C3.6956093,13.3786465 3.97674419,13.0975116 3.97674419,12.7507395 L3.97674419,11.3609302 L4.0214093,11.3609302 C5.48016279,11.3609302 6.66694884,10.1741023 6.66694884,8.71534884 C6.66694884,7.25659535 5.48016279,6.06976744 4.0214093,6.06976744 Z" id="Path"></path>
                </g>
                <path d="M20.6933788,16.2535698 L20.6375649,29.6154303 C20.6360198,29.9853294 20.9346297,30.2864444 21.3045288,30.2879896 C21.6744279,30.2895347 21.975543,29.9909247 21.9770881,29.6210256 L22.032902,16.2591652 C22.0344472,15.889266 21.7358372,15.588151 21.3659381,15.5866059 C20.996039,15.5850608 20.694924,15.8836707 20.6933788,16.2535698 Z" id="Path-2" fill={props.color ? props.color : "#80abba"} fillRule="nonzero"></path>
                <polygon id="Path-10" stroke={props.color ? props.color : "#80abba"} strokeWidth="1.6" stroke-linejoin="round" points="41.6081543 12.4697266 21.2236056 0 1.10267533 12.4697266"></polygon>
                <path d="M6.68407068,12.4697266 L6.68407068,32.5205078" id="Path-11" stroke={props.color ? props.color : "#80abba"} strokeWidth="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M13.8701172,12.4697266 L13.8701172,32.5205078" id="Path-11-Copy" stroke={props.color ? props.color : "#80abba"} strokeWidth="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M28.4701172,12.4697266 L28.4701172,32.5205078" id="Path-11-Copy-2" stroke={props.color ? props.color : "#80abba"} strokeWidth="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M35.6701172,12.4697266 L35.6701172,32.5205078" id="Path-11-Copy-3" stroke={props.color ? props.color : "#80abba"} strokeWidth="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                <polyline id="Path-12" stroke={props.color ? props.color : "#80abba"} strokeWidth="1.6" stroke-linecap="round" stroke-linejoin="round" points="2.86254883 39.6814419 2.86254883 35.8266602 39.5898437 35.8266602 39.5898438 39.6814419"></polyline>
                <polygon id="Path-13" stroke={props.color ? props.color : "#80abba"} strokeWidth="1.6" stroke-linejoin="round" points="0 40.5372559 0 46.0372559 42.4736328 46.0372559 42.4736328 40.5372559"></polygon>
            </g>
        </g>
    </g>
</svg>
  );
};

export default BankAccount;
