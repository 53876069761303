import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";

import SelectMultiple from "./selectMultiple";
import Icon from "./icons";
import CheckSuccess from "./checkSuccess";
import RenderDocIcon from "../helpers/renderDocIcon";
import { FileMimeType } from "../helpers/mimeTypes";

// Actions
import { createAssetResource } from "../actions/balance";

import { internalization } from "../constants.js";

const DragAndDrop = (props) => {
  var languageUserAgent = /^es/.test(props.language) ? "es" : "en";
  const [files, setFiles] = useState([]);
  const [multipleRef, setMultipleRef] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [categories, setCategories] = useState([]);
  const [optionCategory, setOptionCategory] = useState([]);
  const [editing, setEditing] = useState({});  
  const boxStatusRef = useRef([]);
  const prevStateFiles = useRef([]);
  const inputFileRef = useRef(null);
  const dropRef = useRef(null);
  const inputEditFileRef = useRef([]);
  const filenameRef = useRef([]);

  const uploadModal = (e, parent) => {
    e.preventDefault();
    e.stopPropagation();
    removeClassDropZone();
    let preparedFiles = files;
    if (prevStateFiles.current !== files) {
      preparedFiles = prevStateFiles.current;
    }
    setFiles([...preparedFiles, ...e.dataTransfer.files]);
    loadFiles([...e.dataTransfer.files]);
  };

  const dragOver = (e, parent) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";

    if (dropRef.current) {
      dropRef.current?.classList.add("isOver");
    }
  };

  const removeClassDropZone = () => {
    if (dropRef.current) {
      dropRef.current?.classList.remove("isOver");
    }
  };

  const loadFiles = (files) => {
    const fileIntermediate = [];
    let cont = 0;
    const sizeFiles = files.length;
    const load = (fr) =>
      fr.addEventListener("load", () => {
        cont += 1;
      });

    const loadEnd = (fr) =>
      fr.addEventListener("loadend", (e) => {
        if (e.target && e.target.result !== undefined) {
          fileIntermediate[cont - 1].content = String(e.target.result).split(
            "base64,"
          )[1];
        }
        if (cont === sizeFiles) {
          let preparedFiles = prevStateFiles.current.filter(
            (item) => item.content_type
          );
          if (preparedFiles.length > 0) {
            let names = new Set(preparedFiles.map((d) => d.name));
            preparedFiles = [
              ...preparedFiles,
              ...fileIntermediate.filter((d) => !names.has(d.name)),
            ];
          } else {
            preparedFiles = fileIntermediate;
          }
          setFiles([...preparedFiles]);

          if (inputFileRef.current) {
            inputFileRef.current.value = "";
          }
        }
      });

    for (let i = 0; i < files.length; i += 1) {
      const currentFile = files[i];
      fileIntermediate.push({
        name: currentFile.name,
        content_type: currentFile.type,
      });

      const fr = new FileReader();
      load(fr);
      loadEnd(fr);

      fr.readAsDataURL(currentFile);
    }
  };

  const deleteFile = (index) => {
    let stateFiles = files;
    let options = optionCategory;
    options.splice(index, 1);
    stateFiles.splice(index, 1);
    setFiles([...stateFiles]);
    setOptionCategory([...options]);
  };

  const success = () => {
    setTimeout(() => {
      props.successSendFiles();
      setIsSending(false);
      setFiles([]);
      setOptionCategory([]);
    }, 3000);
  };

  const sendFiles = () => {
    let stateFiles = files;
    setIsSending(true);
    boxStatusRef.current.forEach((item) => {
      if (item) {
        item.childNodes[0].classList.add("isDisabled");
        item.childNodes[1].classList.remove("isDisabled");
        item.childNodes[2].classList.add("isDisabled");
      }
    });

    let promiseArr = stateFiles.map((elem, index) => {
      const data = {
        document: {
          ownerable_id: props.identifier.resourceable_id,
          ownerable_type: props.identifier.resourceable_type,
          category_id: optionCategory[index].item.id,
          title: elem.isEdit
            ? `${elem.name}.${FileMimeType[elem.content_type] ? FileMimeType[elem.content_type] : elem.content_type}`
            : elem.name,
          file: {
            filename: elem.isEdit
              ? `${elem.name}.${FileMimeType[elem.content_type] ? FileMimeType[elem.content_type] : elem.content_type}`
              : elem.name,
            content_type: elem.content_type,
            data: elem.content,
          },
        },
      };
      return props.createAssetResource(
        props.authentication_token,
        () => {
      
          boxStatusRef.current[index].childNodes[0].classList.add("isDisabled");
          boxStatusRef.current[index].childNodes[1].classList.add("isDisabled");
          boxStatusRef.current[index].childNodes[2].classList.remove(
            "isDisabled"
          );
        },
        () => {},
        "documents/documents",
        "post",
        data
      );
    });

    Promise.all(promiseArr)
      .then(() => success())
      .catch(() => console.log("error"));
  };

  const checkOptionsCategories = () => {
    let disabled = false;
    if (isSending) {
      disabled = isSending;
    } else if (
      !isSending &&
      optionCategory.every((item) => item !== undefined) &&
      optionCategory.length !== files.length
    ) {
      disabled = true;
    }
    return disabled;
  };

  const checkEmptyFilenames = () => {
    return files.every((file) => file.name !== "");
  };

  const handleClickUploadFile = (e) => {
    inputFileRef.current.click();
  };

  const handleUploadFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFiles([...files, ...e.target.files]);
    loadFiles([...e.target.files]);
  };

  const handleClickOutside = (e) => {
    let allMultiSelects = document.querySelectorAll(".options-multiple-select");
    let containerMultiSelects = document.querySelectorAll(
      ".container-multiple-select"
    );
    let multiSelectIcon = document.querySelectorAll(".icons-multiple-select");

    for (let i = 0; i < allMultiSelects.length; i++) {
      if (
        allMultiSelects.length > 0 &&
        multiSelectIcon.length > 0 &&
        containerMultiSelects.length > 0 &&
        containerMultiSelects &&
        !containerMultiSelects[i].contains(e.target)
      ) {
        containerMultiSelects[i].classList.remove("open");
        multiSelectIcon[i] && multiSelectIcon[i].classList.remove("rotate");
        allMultiSelects[i].style.height = 0;

        setTimeout(function () {
          containerMultiSelects[i].style.zIndex = 0;
        }, 100);

        allMultiSelects[i].classList.remove("open");
      }
    }
  };

  const editFilename = (index) => {
    if(editing[index] == true){
      inputEditFileRef.current[index].style.display = "none";
      filenameRef.current[index].style.display = "block";
    }
    else{
      inputEditFileRef.current[index].style.display = "block";
      filenameRef.current[index].style.display = "none";  
    }
    
    setEditing({...editing, [index]: editing[index] !== undefined ? !editing[index] : true})
  };

  const onChangeEditFilename = (e, index) => {
    let editFiles = JSON.parse(JSON.stringify(files));
    editFiles[index].name = e.target.value;
    editFiles[index].isEdit = true;
    setFiles([...editFiles]);
  };

  useEffect(() => {
    prevStateFiles.current = files;
  }, [files]);

  useEffect(() => {
    let getCategories = props.categorieOptions.add_document.inputs.filter(
      (categorie) => categorie.id === "category_id"
    );

    if (getCategories.length > 0) {
      let transformCat = getCategories[0].options.map((option) => ({
        value: option.label,
        id: option.value,
      }));
      setCategories([...transformCat]);
    }

    document.removeEventListener("click", handleClickOutside.bind(multipleRef));
    document.addEventListener("click", handleClickOutside.bind(multipleRef));

    return () => {
      document.removeEventListener(
        "click",
        handleClickOutside.bind(multipleRef)
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <input
        style={{ display: "none" }}
        className="documents__input-file"
        ref={inputFileRef}
        type="file"
        multiple
        name="myFile"
        onChange={(e) => handleUploadFile(e)}
      />
      <div className="main-wrapper">
        <div
          ref={dropRef}
          id="dragAndDrop"
          className="drag-and-drop"
          draggable="true"
          onClick={(e) => handleClickUploadFile(e)}
          onDrop={(e) => uploadModal(e)}
          onDragEnter={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onDragOver={(e) => dragOver(e)}
          onDragEnd={() => removeClassDropZone()}
          onDragLeave={() => removeClassDropZone()}
        >
          <div className="drag-and-drop__box">
            <div className="drag-and-drop__box-wrapper-icon">
              <div className="drag-and-drop__icon">
                <Icon
                  name="Upload"
                  width="130px"
                  height="130px"
                  color={"#005774"}
                  noWrapper
                />
              </div>
              <p className="drag-and-drop__box-title" dangerouslySetInnerHTML={{ __html: internalization[languageUserAgent].drag_and_drop.title }}
                >
              </p>
            </div>
            <p className="drag-and-drop__box-subtitle">
              {internalization[languageUserAgent].drag_and_drop.max_size}
            </p>
          </div>
        </div>
        {files.length > 0 && (
          <div className="drag-and-drop__content">
            {files.map((item, index) => (
              <div className="drag-and-drop__item">
                <div className="drag-and-drop__item-wrapper">
                  <RenderDocIcon type={item.type} />
                  <div
                    className="drag-and-drop__item-input-title">
                    <input
                      className="drag-and-drop__item-input"
                      ref={(el) => (inputEditFileRef.current[index] = el)}
                      type="text"
                      name="drag-and-drop-edit"
                      id="drag-and-drop-edit"
                      defaultValue={item.name}
                      value={item.name}
                      onChange={(e) => onChangeEditFilename(e, index)}
                    />
                    <p
                      ref={(el) => (filenameRef.current[index] = el)}
                      className="drag-and-drop__item-title-text"
                    >
                      {item.name}
                    </p>
                    <div
                      className="drag-and-drop__item-icon"
                      onClick={() => editFilename(index)}
                    >
                      {
                        editing[index] === true && <Icon name={"Check"} width={"18px"} height={"18px"} />   
                      }
                      {
                       editing[index] !== true && <Icon name={"Edit"} width={"18px"} height={"18px"} />
                      }
                    </div>
                  </div>

                  <div
                    className="drag-and-drop__item-status"
                    ref={(el) => (boxStatusRef.current[index] = el)}
                  >
                    <button className="close" onClick={() => deleteFile(index)}>
                      <Icon name="Close" />
                    </button>

                    <div className="isDisabled">
                      <div class="spinner"></div>
                    </div>
                    <div className="isDisabled">
                      <CheckSuccess />
                    </div>
                  </div>
                </div>              
                <SelectMultiple
                  key="select_category"
                  colorGrey={true}
                  setRef={(ref) => {
                    setMultipleRef(ref);
                  }}
                  label={internalization[languageUserAgent].drag_and_drop.category}
                  defaultValue={
                    optionCategory.length > 0 && optionCategory[index]
                      ? optionCategory[index].item.value
                      : ""
                  }
                  required
                  options={categories}
                  openOptions={() => {}}
                  getOptions={(item) => {
                    let newItem = optionCategory;
                    newItem[index] = item;
                    setOptionCategory([...newItem]);
                  }}
                  hasTooltip={true}
                  helperTooltip={[
                    internalization[languageUserAgent].drag_and_drop.category,
                    internalization[languageUserAgent].drag_and_drop.tooltip,
                  ]}
                />
              </div>
            ))}
            <div className="drag-and-drop__button">
              <button
                className="btn primary"
                onClick={() => sendFiles()}
                disabled={checkOptionsCategories() || !checkEmptyFilenames()}
              >
                Enviar
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createAssetResource: (
      authentication_token,
      success,
      error,
      url,
      method,
      attributes
    ) =>
      dispatch(
        createAssetResource(
          authentication_token,
          success,
          error,
          url,
          method,
          attributes
        )
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DragAndDrop);
