import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import SelectMultipleCustodian from "./selectMultipleCustodian";
import SelectMultipleCustodianCheckbox from "./selectMultipleCustodianCheckbox";

let colors_final = {};

const LineHighchart = (props) => {
  const colors = [
    "#b1cbff",
    "#d3efce",
    "#aaaaaa",
    "#84081e",
    "#679750",
    "#1d1d1d",
    "#ffd14f",
    "#ff8686",
    "#f9b46c",
    "#50c7c7",
  ];
  const [currentData, setCurrentData] = useState([]);
  const [activeButton, setActiveButton] = useState(0);
  const [custodians, setCustodians] = useState([]);
  const selectRef = useRef(null);
  const [modalOptions, setModalOptions] = useState(false);
  const [itemsSelected, setItemsSelected] = useState([]);
  const [filterType, setFilterType] = useState("last_month");
  const [crossTooltip, setCrossTooltip] = useState(true);
  const allCustodians = JSON.parse(JSON.stringify(props.allCustodians));

  useEffect(() => {
    setCurrentData([{ name: props.currentCustodianName, data: props.currentData }]);
    setCustodians(props.allSelected ? props.allSelected : [props.currentCustodianName]);
    
    setItemsSelected(props.allSelected ? selectOptions() : [{ id: props.currentCustodianName, value: props.currentCustodianName }]);
  }, []);

  useEffect(() => {
    setData();
  }, [custodians, filterType]);

  useEffect(() => {
    Object.keys(allCustodians).map((key, index) => {
      colors_final[key] = colors[index];
    });
  }, [props.allCustodians]);
  const getConsolidated = (data_temp) => {
    //prepare consolidated
    let temp_values = [];
    let currentDataTemp = JSON.parse(JSON.stringify(data_temp));

    temp_values = currentDataTemp.map((element) => {
      return element.data;
    });
    let result = [];
    for (var i = 0; i < temp_values[0].length; i++) {
      var num = 0;
      let check_nulls = 0      
      //still assuming all arrays have the same amount of numbers
      for (var i2 = 0; i2 < temp_values.length; i2++) {
        num += temp_values[i2][i];
        if(temp_values[i2][i] === null){
          check_nulls += 1  
        }
        
      }
      let result_temp = (num / (temp_values.length - check_nulls));
      result_temp = parseFloat(result_temp.toFixed(2))
      if (Number.isNaN(result_temp)){
        result_temp = null
      }
      result.push(result_temp);
    }
    return result;
  };

  const getStartPoint = () => {
    // console.log("getStartPoint", somePointInDate())
    if(props.languageUserAgent.includes("es") && props.dates.hasOwnProperty(filterType+"_es")){
      return props.dates[filterType+"_es"];
    }else{
      return props.dates[filterType];  
    }
    
  };

  const somePointInDate = () => {
    // currentData[0].data.
    let new_dates = []
    if(currentData[0] && currentData[0].data){
      for (var i = 0; i < currentData[0].data.length; i++) {
        if(currentData[0].data[i] !== null){
          new_dates.push(props.dates[filterType][i])
        }
      }  
    }
    else{
      new_dates = props.dates[filterType]
    }
    
    return new_dates;
  }

  const filter = (index, type) => {
    setActiveButton(index);
    setFilterType(type);
    props.setFilterType && props.setFilterType(type)
  };

  const setData = () => {
    let data_temp = [];
    if (Object.keys(allCustodians).length === 0) {
      if(["year_to_date", "since_inception", "trailing_12"].includes(filterType)){
            data_temp.push({
              type: 'column',
              name: props.currentCustodianName+" TWR",
              data: props.accountData[filterType+"_bars"],
              color: '#efeeee',
              dashStyle: "solid"
            }) 
            setCrossTooltip(false) 
          }
          else{
            setCrossTooltip(true) 
          }
      data_temp.push(
        { type: 'line', dashStyle: "solid", name: props.currentCustodianName + " Cumulative TWR", data: props.accountData[filterType], color: "#005774", dashStyle: "solid" },
      );
    } else {
      custodians.forEach( function(custodian, indice, array) {
          if(custodians.length == 1 && ["year_to_date", "since_inception", "trailing_12"].includes(filterType)){
            data_temp.push({
              type: 'column',
              name: custodian+" TWR",
              data: allCustodians[custodian][filterType+"_bars"],
              color: '#efeeee',
              dashStyle: "solid"
            }) 
            setCrossTooltip(false)  
          }
          else{
            setCrossTooltip(true) 
          }
          data_temp.push({
          name: custodian + " Cumulative TWR",
          type: "line",
          data: allCustodians[custodian][filterType],
          color: Object.keys(allCustodians).length === 1 ? "#005774" : colors_final[custodian],
          dashStyle: "solid"
        })
      });
      // data_temp = custodians.map((custodian, index) => {
      //   return {
      //     name: custodian,
      //     data: allCustodians[custodian][filterType],
      //     color: Object.keys(allCustodians).length === 1 ? "#005774" : colors_final[custodian],
      //     dashStyle: "solid"
      //   };
      // });
    }
    // props.updateData(itemsSelected, data_temp)
    if (currentData && custodians.length > 1) {
      data_temp.unshift({
        name: props.languageUserAgent.includes("es") ? "Consolidado" : "Consolidated",
        data: getConsolidated(data_temp),
        color: "#005774",
        lineWidth: 3,
        type: "line",
        dashStyle: "dot"
      });
    }
    

    setCurrentData(data_temp);
  };

  const selectOptions = () => {
    let data_options = Object.keys(allCustodians).map((c) => {
      return { id: c, value: c };
    });
    return data_options;
  };

  const selectedItems = (items) => {
    let new_custodians = items.map((item) => {
      return item.id;
    });
    setCustodians(new_custodians);
  };
  return (
    <div className="c-charts">
      <div 
        className="c-charts__filter-buttons filter-buttons-wrapper"
        style={props.accountData &&  props.preheader ? {justifyContent: 'flex-start'} : {}}
      >
        <button
          key={"last_month_button"}
          onClick={() => filter(0, "last_month")}
          className={`filter-buttons btn primary ${activeButton === 0 ? "" : "border"}`}
        >
          <span className="btn-text">{props.languageUserAgent.includes("es") ? "Últimos 30 días" : "Last 30 days"}</span>
        </button>
        <button
          key={"year_to_date_button"}
          onClick={() => filter(1, "year_to_date")}
          className={`filter-buttons btn primary ${activeButton === 1 ? "" : "border"}`}
        >
          <span className="btn-text">{props.languageUserAgent.includes("es") ? "Año hasta la fecha" : "Year to Date"}</span>
        </button>
        <button
          key={"trailing_12_button"}
          onClick={() => filter(2, "trailing_12")}
          className={`filter-buttons btn primary ${activeButton === 2 ? "" : "border"}`}
        >
          <span className="btn-text">{props.languageUserAgent.includes("es") ? "Últimos 12 Meses" : "Trailing 12 Month"}</span>
        </button>
        <button
          key={"since_inception_button"}
          onClick={() => filter(3, "since_inception")}
          className={`filter-buttons btn primary ${activeButton === 3 ? "" : "border"}`}
        >
          <span className="btn-text">{props.languageUserAgent.includes("es") ? "Anual" : "Yearly"}</span>
        </button>
        {props.type === "custodian" &&  (
          <SelectMultipleCustodianCheckbox
            colorGrey={true}
            itemsSelected={itemsSelected}
            setRef={(ref) => selectRef}
            defaultValue={custodians.join(", ")}
            label={props.languageUserAgent.includes("es") ? "Elegir custodios" : "Select Custodians"}
            options={selectOptions()}
            multiple={true}
            openOptions={() => setModalOptions(!modalOptions)}
            disabled={false}
            modalOptions={modalOptions}
            getOptions={(items) => selectedItems(items)}
            type={"custodian"}
            currentCustodianName={props.currentCustodianName}
            isGraphic={true}
            preSelected={props.preSelected}
            allSelected={props.allSelected !== undefined}
            // itemsSelected={
            //   this.state.inputsValue.type !== undefined
            //     ? this.state.inputsValue.type
            //     : []
            // }
          />
        )}
      </div>

      { props.currentData && props.preheader && (
        <div className="c-charts__value-close-market">
          <div className="c-charts__bottom-text">
            <li style={{listStyle: 'none'}}><p dangerouslySetInnerHTML={{ __html: props.preheader }} className="c-charts__bottom-text-paragraph"></p> </li>
            <li style={{listStyle: 'none'}}><p className="c-charts__bottom-text-paragraph">{props.accountData.last_31.high}</p> </li>
            <li style={{listStyle: 'none'}}><p className="c-charts__bottom-text-paragraph">{props.accountData.last_31.low}</p></li>
          </div>
        </div>
      )}



      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            type: "spline",
            style: {
              fontFamily: 'Averta-Regular'
            }
          },
          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },
          xAxis: {
            categories: getStartPoint(),
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
            crosshair: {
              snap: true
            }
          },
          tooltip: {
            shared: crossTooltip,
            valueSuffix: " %",
            // valueDecimals: 4
            // formatter: function () {
            //     return  "<b>"+this.series.name +"</b>: " + this.y + '%';
            // },
            // useHTML: true
          },
          yAxis: {
            title: {
            text: props.yAxisTitle
            // align: 'center'
            },
            labels: {
              format: "{value:.2f} %",
              // formatter: function () {
              //     // return this.axis.defaultLabelFormatter.call(this) + ' %';
              //   return this.value:.2f + " %";
              // }
            },
          },
          //   xAxis: {
          //     type: 'datetime',
          //     dateTimeLabelFormats: {
          //         minute: '%d %b %Y'
          //     },
          //     startOnTick: true,
          //     endOnTick: true,
          //     showLastLabel: true,
          //     // labels: {
          //     //     rotation: -45
          //     // },
          // },
          // plotOptions: {
          //     series: {
          //         label: {
          //             connectorAllowed: false
          //         },
          //         pointStart: getStartPoint(),
          //         pointInterval: 24 * 3600 * 1000 // one day
          //         }
          // },
          series: currentData,
        }}
      />
      {/*
        props.extra_title &&
        <div style={{textAlign: 'center', marginBottom: 15}}>
        <span className="input-label" style={{color: '#005774'}}>{props.extra_title}</span>
        </div>
      */}
    </div>
  );
};

export default LineHighchart;
