import React, { useState, useEffect, useRef } from 'react'
import Search from  '../components/search'

const TableSearcher = (props) => {

  const searcherRef = useRef(null)

  useEffect(() => {

      matchTableWidth()

  }, [])

  const matchTableWidth = () => {
    let table = document.querySelector('.c-table')
    let tableWidth = table.offsetWidth
    if (tableWidth < 1280) {
      searcherRef.current.style.width = `${table.offsetWidth}px`
      searcherRef.current.style.margin = 'auto'
    } else {
      searcherRef.current.style.width = `1280px`
    }
  }

  const search = (text) => {
    props.search(text)
  }

  return (
    <div className="c-table__searcher" ref={searcherRef}>
      <Search searchText={props.searchText} getValue={(text) => search(text)}/>
    </div>
  )

}

export default TableSearcher
