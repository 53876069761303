// Dependencies
import React from 'react';

const LastActivity = ({ width, height, colorPrimary, colorSecondary, strokeWidth }) => {

  return (

    <svg
      width={width ? width : '46px'}
      height={height ? height : '46px'}
      viewBox="0 0 46 46"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons-/-Section-/-Últimas" transform="translate(-7.000000, -7.000000)" fillRule="nonzero" strokeWidth="1.5">
          <circle id="Oval-2" stroke={colorPrimary ? colorPrimary : "#80abba"} cx="30" cy="30" r="21" strokeWidth={strokeWidth ? strokeWidth : 2} ></circle>
          <polyline id="Path-4" stroke={colorSecondary ? colorSecondary : "#80abba"} strokeLinecap="round" points="40.8095703 40.0917969 30.1796875 30.0644531 40.8095703 15.1796875"></polyline>
        </g>
      </g>
    </svg>

  );

}

export default LastActivity;
