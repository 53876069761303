// Dependencies
import React, { useEffect, useRef } from 'react';

const FloatButton = ({action, children, style}) => {

  const buttonRef = useRef(null);

  useEffect(() => {

    checkLeft();
    window.addEventListener('resize', checkLeft);

  }, [])

  const checkLeft = () => {
    let button = document.querySelector('.c-dropdown-menu__button');
    if(button) {
      let buttonLeft =button.getBoundingClientRect().left;
      if(buttonRef.current) {
        buttonRef.current.style.right = `initial`;
        buttonRef.current.style.left = `${buttonLeft - 14}px`;
      }
    }
  }

  return (

    <button
      style={style && style}
      className="wrapper-floatButton"
      onClick={() => action()}
      ref={buttonRef}
    >
      {children}
    </button>

  );

}

export default FloatButton;
