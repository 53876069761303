import { HOST_URL, URL_VERSION } from '../constants';

import get_header from "./api_header";

//http://localhost:3000/api/v1/balance_principal/linkables/destroy_linkable/?type_1=Corporation&id_1=15&type_2=Liability&id_2=21
export function deleteLinkedResource(authentication_token, type_1, id_1, type_2, id_2, success, error) {
  
  return (dispatch) => {
    return fetch(HOST_URL+'/api/v1/balance_principal/linkables/destroy_linkable?type_1='+type_1+'&id_1='+id_1+'&type_2='+type_2+'&id_2='+id_2, {
      method: 'POST',
      headers: get_header(authentication_token),
    })
    .then((response) => {
      if (response.ok) {
        success()
      }
      else if(response.status !== 401){
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}



