// Dependencies
import React, { useState, useEffect } from "react";


// Components
import Icon from "./icons/";
import TextEllipsis from "react-text-ellipsis";

const BoxResources = (props) => {
 const [title, setTitle] = useState(props.title)

  useEffect(() => {
   setTitle(props.title)
  }, [props.title])


  const classBlockBoxItem = [
    "block__box-item",
    props.paddinBottomTen && "block__box-item block__box-item--padding-bottom-ten",
  ]
    .filter((elem) => elem !== false)
    .join(" ");

  return (
  
      <div className={classBlockBoxItem}>
        <div className={props.noMarginTop ? "block__box-item-content is-selected block__box-item-content--margin-top-zero" : "block__box-item-content is-selected"} onClick={() => props.actionShow && props.actionShow()}>
          <div className="block__box-item-content-icon">
            <Icon name={props.iconName}  />
          </div>
          <TextEllipsis
            key={title+"_title"}
            lines={2}
            tag={"p"}
            ellipsisChars={"..."}
            tagClass={"block__box-item-content-text"}
            debounceTimeoutOnResize={200}
            useJsOnly={true}
          >
            {title}
          </TextEllipsis>
          <p className="block__box-item-content-text truncate">
            {props.description}
          </p>
        </div>
        {
          props.edit &&
          <div className="block__box-item-icon"
          onClick={() => props.actionDelete && props.actionDelete()}
          >
          <Icon name="Plus" width={"20px"} height={"20px"} color="#f3b01c"  bgColor="#F5F5F7" close={true} />
        </div>
        }
        
      </div>
 
  );
};

export default BoxResources;
