let API_TOKEN_environment;
let HOST_URL_environment;
let HOST_URL_WEB_environment;
let sentry_tracking;
let google_analytics_id;

var environment = require("./environment.js");

const APP_VERSION = "1.1.1"
const URL_VERSION = "api/v1/"
const UNIKEN_ENABLED = true

switch (environment.APP_ENVIRONMENT) {
  case "local":
    API_TOKEN_environment = "5AAxJQfsx4KvAQ6rDqfPrB_iE3CrjQ";
    HOST_URL_environment = "http://localhost:3000/"; //android
    HOST_URL_WEB_environment = "http://localhost:3001/";
    sentry_tracking =
      "https://8f947efb0e1c44b4a59fbd8343b61c3b@sentry.io/1421657";
    google_analytics_id = "";
    break;
  case "staging":
    API_TOKEN_environment = "5AAxJQfsx4KvAQ6rDqfPrB_iE3CrjQ";
    HOST_URL_environment = "https://bulltick360.string-projects.com/";
    HOST_URL_WEB_environment = "https://bulltick360-web.string-projects.com//";
    sentry_tracking =
      "https://8f947efb0e1c44b4a59fbd8343b61c3b@sentry.io/1421657";
    google_analytics_id = "";
    break;
  case "preproduction":
    API_TOKEN_environment = "5AAxJQfsx4KvAQ6rDqfPrB_iE3CrjQ";
    HOST_URL_environment = "https://api-staging.bulltick360.com/";
    HOST_URL_WEB_environment = "https://staging.bulltick360.com/";
    sentry_tracking =
      "https://8f947efb0e1c44b4a59fbd8343b61c3b@sentry.io/1421657";
    google_analytics_id = "";
    break;
  case "production":
    API_TOKEN_environment = "NVescazFjryb2V1TvyzzTUVDxXePsA";
    HOST_URL_environment = "https://api.bulltick360.com/";
    HOST_URL_WEB_environment = "https://bulltick360.com/";
    sentry_tracking =
      "https://8516de7e99194c40a6964a9bf10e065f@sentry.io/1421672";
    google_analytics_id = "UA-154455639-1";
    break;
  default:
    break;
}

const API_TOKEN = API_TOKEN_environment;
const HOST_URL = HOST_URL_environment;
const HOST_URL_WEB = HOST_URL_WEB_environment;

const ENVIRONMENT_VAR = environment;
const SENTRY_TRACKING = sentry_tracking;
const GOOGLE_ANALYTICS_ID = google_analytics_id;
const OWNERSHIP_INDEX = "OWNERSHIP_INDEX";

const DETAIL_TYPE_ONE_CODES =
  "real_estates|direct_investments|arts|bank_accounts|watches|vehicles|deferred_assets/others|others|alternative_assets|custodians|accounts|liability_addepars";

const BALANCE_VALUATIONS = "BALANCE_VALUATIONS";

const TYPE_OF_ASSETS =
  "RealEstate|Watch|PrivateEquity|Liability|LiabilityAddepar|Other|LifeInsurance|Vehicle|BankAccount|Art|OtherDeferredAsset|ReceivableAccount|AlternativeAsset|Custodian|Account";
const TYPE_OF_OWNERSHIP =
  "Trust|Corporation|InvestmentAccount|BeneficiaryAccount|Corporation|StakeHolder";

const NOT_PASSIVES =
  "life_insurance|real_estates|direct_investments|arts|bank_accounts|watches|vehicles|deferred_assets/others|others|receivable_accounts|real_estate|private_equity|art|bank_account|watch|vehicle|deferred_assets/other|other|receivable_account";

const CAROUSEL_ENABLED_CODES = "real_estates|arts|watches|vehicles|others|direct_investments|bank_accounts";

const TYPE_BUY = 0;
const TYPE_DEPOSIT = 1;
const TYPE_SELL = 2;
const TYPE_SHELL_SHORT = 3;
const TYPE_WITHDRAWAL = 4;
const TYPE_ASSET_CREATED = 5;
const TYPE_ASSET_DELETED = 6;
const TYPE_VALUE_PLUS = 7;
const TYPE_VALUE_MINUS = 8;

const PASSWORD_CHANGE_REQUIRED = "PASSWORD_CHANGE_REQUIRED";
const UNAUTHORIZED_REQUEST = "UNAUTHORIZED_REQUEST";

const internalization = {
  es: {
    welcome: "Bienvenido",
    enter_email: "Para iniciar sesión, por favor introduce tu email",
    email: "Email",
    app_required: "Se requiere la aplicación bt360 para la autorización de inicio de sesión.",
    sure_signed: "Asegúrese de haber iniciado sesión en la aplicación para ingresar correctamente.",
    open: "Abrir",
    delete: "Eliminar",
    phone_number: "Número de teléfono",
    phone_number_confirmation: "Confirmación número de teléfono",
    pin: "PIN",
    password: "Contraseña",
    old_password: "Contraseña actual",
    password_confirmation: "Confirmar contraseña",
    login: "Login",
    forgot_password: "¿Has olvidado tu contraseña?",
    forgot_password_email: "¿Has olvidado tu contraseña y el email?",
    password_requirements_texts: [
      "Debe tener al menos 8 caracteres",
      "Debe tener 1 Mayúscula, 1 Minúscula, 1 Número y 1 Carácter Especial",
      "Sólo se pueden utilizar los siguientes caracteres especiales: @ # * ( ) + { } / ? ~ : , . - _",
      "No se pueden repetir más de tres veces letras/dígitos/caracteres especiales",
    ],
    pin_requirements_texts: ["Debe tener al menos 6 números"],
    forgot_email: "¿Has olvidado tu email?",
    biometric_identify: "Identificar con touchID / FaceID",
    authentication_required: "Authentication required",
    send: "Enviar",
    send_email: "Enviar Email",
    try_again: "Volver a Intentar",
    ok: "Ok",
    back: "Volver",
    email_not_valid: "Email no válido",
    next: "Siguiente",
    login_failed: "Email y contraseña no coinciden",
    failed_attempts: "Quedan %{count} intento(s)",
    account_blocked: "Cuenta Bloqueada",
    account_blocked_title:
      "Por razones de seguridad tu cuenta ha sido bloqueada.",
    account_blocked_subtitle:
      "Pincha en el link y sigue las instrucciones para desbloquear tu cuenta.",
    account_blocked_subtitle_uniken: "Por favor póngase en conctacto con el administrador.",
    account_blocked_button: "Recuperar Cuenta",
    account_blocked_code_title: "Cambiar Contraseña",
    account_blocked_code_subtititle:
      "Introduce el código que te hemos enviado al número de teléfono que acaba en ",
    account_unblocked: "Cuenta Desbloqueada",
    account_unblocked_title:
      "Tu cuenta ha sido desbloqueada y la contraseña cambiada satisfactoriamente.",
    recovery_email: "Recuperar Email",
    recovery_email_subtitle:
      "Introduce tu teléfono y contraseña para proceder a la recuperación.",
    recovery_email_password: "Recuperar Email y Contraseña",
    recovery_email_password_questions: "Preguntas de seguridad",
    recovery_email_password_subtitle:
      "Introduce tu teléfono y el PIN para proceder a la recuperación",
    recovery_email_password_subtitle_questions:
      "Por favor responde a las preguntas de seguridad para proceder",
    recovery_password_subtitle:
      "Introduce tu email y te enviaremos un link para recuperar tu contraseña.",
    recovery_password: "Recuperar contraseña",
    recovery_confirmation_title: "Recuperar contraseña",
    recovery_confirmation_description: "Recibirás un email con instrucciones",
    password_match: "Contraseñas coinciden",
    password_not_match: "Contraseñas no coinciden",
    pin_match: "Números pin coinciden",
    pin_not_match: "Números pin no coinciden",
    remember_me: "Recordarme",
    loading: "Cargando...",
    security_question: "Preguntas Seguridad",
    answer: "Respuesta",
    confirmation: "Confirmación",
    sms_code: "Código SMS",
    pin_code: "Código PIN",
    send_code: "Enviar Código",
    resend: "Reenviar",
    resent: "Reenviado",
    old_code_mismatch: "Falta de coincidencia de código antiguo",
    question: "Pregunta",
    new_password: "Nueva contraseña",
    new_password_confirmation: "Confirmación nueva contraseña",
    recovery_code_title: "Vamos a comprobar que realmente eres tú",
    recovery_code_info: "Queremos mantener su información segura.",
    recovery_code_send_info:
      "Le enviaremos un código para confirmar su identidad.",
    recovery_code_send_confirmation: "Le enviaremos un código a XXX-XXX-",
    add_image: "Añadir Imagen",
    cancel: "Cancelar",
    download_app_text:
      "Para mejorar la experiencia con bt360 tienes que acceder a través de la app en tu celular",
    download_app_button: "Abrir en la aplicación de Bulltick360",
    download_app_question: "¿Aún no tienes la aplicación?",
    download_app: "Descárgala aquí:",
    change: "Cambiar",
    old_a: "${element} Actual",
    old_o: "${element} Actual",
    new_a: "${element} Nuevo",
    new_o: "${element} Nuevo",
    confirm: "Confirmar",
    select_label: "Seleccionar etiqueta",
    history: "Historial",
    legend: "Leyenda",
    text_access_restricted_title:
      "Acceso restringido al Área de Wealth and Asset Management",
    text_access_restricted: `<p>Disculpa, pero el acceso a esta sección solo es valido para clientes del Área de Wealth and Asset Management de Bulltick.</p>
    <p style="margin-top: 10px">A través de esta sección tendrás acceso a la plataforma de consolidación <strong style="color: #005774">bt360</strong>, en la que podrás gestionar de una manera completa todo tu balance, así como la posibilidad de crear tu estructura patrimonial, vinculando todo tipo de documentos, contactos y fotos. Todo ello de una forma segura y sencilla.</p>
    <p style="margin-top: 10px">Si quieres obtener mas información sobre lo que ofrece la plataforma <strong style="color: #005774">bt360</strong> de Bulltick, ponte en contacto con tu asesor o escríbenos a <strong style="color: #005774"> bt360@bulltick.com.</strong></p>
    <p style="margin-top: 10px">Atentamente.</p>`,
    title_linked_assets: "Linkados actuales",
    text_assets_select: "ha sido seleccionado. No dude en agregar cualquier otro activo que pueda aplicar.",
    alerts: {
      email_sent: "Email Enviado",
      email_sent_subtitle:
        "El email para recuperar la contraseña ha sido enviado exitosamente.",
      email_sent_subtitle_forgot_email:
        "Verifique su correo electrónico y siga las instrucciones para resetear su contraseña.",
      email_forgot_subtitle:
        "El email para recuperar la contraseña ha sido enviado exitosamente.",
      email_forgot_subtitle_check:
        "Verifique su correo electrónico y siga las instrucciones para resetear su contraseña.",
      password_sent_subtitle:
        "El email para recuperar la contraseña ha sido enviado exitosamente.",
      password_forgot_subtitle_check:
        "Verifique su correo electrónico y siga las instrucciones para resetear su contraseña.",
      email_error_title: "El email no coincide",
      email_error_subtitle:
        "El correo electrónico facilitado no coincide con ninguno en nuestros registros.",
      forgot_email:
        "El correo electrónico para recuperar tu correo electrónico ha sido enviado exitosamente.",
      forgot_email_subtitle:
        "Verifique su correo electrónico y siga las instrucciones para reiniciar su contraseña.",
      forgot_email_error: "El número de teléfono o la contraseña no coinciden",
      forgot_email_subtitle_error:
        "El número de teléfono o la contraseña facilitada no coinciden con ninguno en nuestros registros.",
      forgot_email_password: "Email Enviado",
      forgot_email_password_subtitle:
        "El email para recuperar la contraseña ha sido enviado exitosamente.",
      forgot_email_password_error: "Error",
      forgot_email_password_subtitle_error: "Error not found",
      uniken_waiting_title: "Esperando autorización",
      uniken_waiting_subtitle: "Hemos enviado una notificación a tu móvil a través de la aplicación de bt360.</br></br> Asegúrate de estar registrado en la App con el mismo usuario. </br></br> Por favor aprueba el acceso en la aplicación para ser autorizado.",
      uniken_waiting_subtitle_2: "Si has seleccionado Rechazar por error, puedes volver a solicitarlo.",
      uniken_not_authorized_title: "Intento de login erroneo",
      uniken_not_authorized_subtitle: "El login no ha sido aprobado por el usuario.",
      uniken_not_authorized_profile_title: "Actualización de perfil erroneo",
      uniken_not_authorized_profile_subtitle: "El usuario no aprobó el intento de actualización de perfil y el campo no se modificó. <br/> Si hizo clic en Desaprobar por error, por favor vuelva a intentarlo.",
      add_alert: {
        title: "te ha suscrito a la ALERTA:",
        question: "¿Quieres recibir esta alerta?",
        footer: "Para cambiar la configuración del alerta, dirigirse al Tab Alertas",
        reject: "Rechazar",
        confirm: "Confirmar"
      },
      reject_alert: {
        title: "ha eliminado la ALERTA:",
        question: "¿Quieres mantener esta alerta?",
        footer: "Para cambiar la configuración de la alerta, dirigirse al Tab Alertas",
        delete: "Eliminar",
        confirm: "Confirmar"
      }
    },
    errors: {
      password_requirement:
        "La constraseña debe tener al menos, una mayúscula, una minúscula, un número , un símbolo, no ha de repetir más de dos veces una letra/número/símbolo y ha de ser de 8 dígitos como mínimo.",
      accepted: "Debe ser aceptado",
      confirmation: "No coincide %{attribute}",
      blank: "No puede estar en blanco",
      no_match: "No coinciden",
      match: "Coinciden",
      email_not_found: "La dirección de email con la que has intentado acceder no es correcta o no esta asociada a ningún usuario, por favor revísala e inténtalo de nuevo.",
      empty: "No puede estar vacío",
      equal_to: "Debe ser igual a %{count}",
      mismatch: "Mismatch",
      connection_error_title: "bt360 perdió conexión con el servidor.",
      connection_error_body: "Actualice su navegador y vuelva a intentar. Gracias."
    },
    models: {
      home: "Home",
      custodians: "Custodios",
      accounts: "Cuentas",
      fixed_assets: "Activos Fijos",
      financial_assets: "Activos Financieros",
      collections: "Colecciones",
      arts: "Arte",
      watches: "Relojes",
      vehicles: "Vehículos",
      real_estates: "Propiedades Inmobiliarias",
      real_estate: "Propiedad Inmobiliaria",
      private_equities: "Private Equities",
      private_equity: "Private Equity",
      vehicles_collection: "Vehículos y Colecciones",
      documents_deleted: "Documentos Eliminados",
      others: "Otros",
      other: "Otro",
      art: "Arte",
      watch: "Reloj",
      vehicle: "Vehículo",
      bank_accounts: "Cuentas Bancarias",
      bank_account: "Cuenta Bancaria",
      contacts: "Contactos",
      ownership: "Estructura Patrimonial",
      documents: "Documentos",
      files: "Archivos",
      profile: "Perfil",
      receivable_account: "Cuentas por Cobrar",
      valuations: "Valoraciones",
      deferred_assets: "Seguros de Vida",
      life_insurances: "Seguros de Vida",
      liabilities: "Pasivos",
      settings: "Configuración",
      balance: "Patrimonio Consolidado",
      content_manager: "Gestor de Contenidos",
      tenant_document: "Documentos",
      research: "Bulltick Investment Strategy",
      products_services: "Productos y Servicios",
      financial_statements: "Estados de Cuenta",    
      alerts: "Alertas"    

    },
    update_warning: `AVISO: Se ha realizado una actualización en <b>bt360</b> para mejorar la seguridad y la experiencia de nuestros clientes. <br/> A partir de ahora, es necesario haber descargado la nueva versión de la App (<a target='_blank' class='c-warning-banner__link' href='https://apps.apple.com/us/app/bulltick-360/id1473561665'>Apple Store</a> o <a target='_blank' class='c-warning-banner__link' href='https://play.google.com/store/apps/details?id=com.bulltick360'>Google Play</a>) y estar registrado a través de la App para poder acceder a la versión web de <b>bt360</b>. `,
    action: 'Acción',
    security_measures: {
      title: 'Medidas de Seguridad',
      subtitle: ' En Bulltick, tenemos un fuerte compromiso de satisfacer las necesidades de seguridad y privacidad de nuestros clientes y estamos decididos a no comprometer la seguridad y experiencia del cliente en nuestra plataforma:',
      list: {
        coding: '<strong>Codificación / Cifrado:</strong> Misma seguridad que las principales instituciones financieras (cifrado punto a punto, 256 bits-AES).', 
        registration_access: '<strong>Plataforma de seguridad en el registro y acceso:</strong> Every time bt360 is accessed, the App performs a Security Check on the device, the network from which the connection is made and the App itself.</li> <li className="o-margin--bottom-size-xs"><strong>Access:</strong> via biometric credentials (Face ID or Touch ID) or Security PIN.',
        access: '<strong>Acceso:</strong> a través de credenciales biométricas (Face ID o Touch ID) o PIN de seguridad.',
        registration: '<strong>Registro:</strong> a través de 2FA (autenticación de dos factores).',
        servers: '<strong>Servidores:</strong> Datos y Plataforma alojados en los centros de alta seguridad de la nube de Microsoft Azure y AWS (Amazon Web Services).',
        websites: '<strong>Sitio web:</strong> Certificado SSL, tecnología de seguridad estándar que permite comunicaciones cifradas entre un navegador y un servidor web.',
        blur_mode: '<strong>Modo Desenfoque:</strong> Los usuarios tienen la posibilidad de ocultar todos los valores en dólares ($) en toda la plataforma (solo para la versión de la aplicación).',
        inactivity: '<strong>Tiempo de espera de inactividad:</strong> Por razones de seguridad, después de un período de inactividad, la plataforma se cierra automáticamente.',
        third_party: '<strong>Acceso a terceros de confianza:</strong> El usuario principal es el que otorga o elimina el acceso a terceros de confianza.',
        password: '<strong>Restablecimiento periódico de contraseña:</strong> La plataforma requiere una actualización de contraseña cada cierto tiempo, sin poder reutilizar ninguna contraseña utilizada anteriormente. Debe ser una contraseña segura con al menos 8 caracteres, una letra mayúscula, un número y un símbolo.',
      }
    },
    general_recomendations: {
      footer_title: "Recomendaciones Generales",
      title: "Recomendaciones Generales de Seguridad",
      subtitle: " Para garantizar la máxima seguridad, Bulltick le anima a seguir estas recomendaciones generales de seguridad:",
      secure_connection: "Conexión segura",
      receiving_email: "Recepción del Correo Electrónico",
      using_your_device: "Uso del Dispositivo",
      list: {
        log_in: "Inicie sesión en cualquier plataforma desde su propio dispositivo y desde una red inalámbrica (WiFi) segura y privada.",
        not_share: "No comparta con nadie sus contraseñas o cualquier otro código de acceso personal.",
        secure_password: "Asegure sus contraseñas con al menos 8 caracteres, una letra mayúscula, un número y un símbolo.",
        different_passwords: "Usar contraseñas diferentes para las diferentes plataformas y sitios de Internet en los que inicia sesión.",
        clear_cache: "Borre la memoria caché de su navegador después de cada sesión en línea.",
        dont_open_reply: "No abra o responda  el correo electrónico de un remitente desconocido o sospechoso. En caso de dudas, elimine el mensaje.",
        dont_open_email: "No abra un archivo adjunto de correo electrónico de un remitente desconocido o sospechoso. En caso de dudas, elimine el mensaje.",
        delete_junk: "Eliminar correos electrónicos basura o en cadena.",
        update: "Actualice el sistema operativo y otros programas en su computadora regularmente.",
        install: "Instale el software de seguridad habitual en su dispositivo, como antivirus, firewall y programas antispam.",
        remove: "Elimine las opciones de uso compartido de archivos e impresoras, especialmente en computadoras conectadas a Internet.",
        consider: "Considerar el uso de tecnología de cifrado para proteger información altamente sensible o confidencial.",
        dont_install_software: "No instale software ni ejecute programas de fuentes desconocidas.",
        dont_disclose: "No divulgue información personal o financiera a sitios web poco conocidos o sospechosos.",
        use_trusted_computers: "Usar un equipo o dispositivo de confianza.",
      },
      additional_info: 'Para obtener información adicional, no dude en ponerse en contacto con nosotros en  <a href="mailto:bt360@bulltick.com">bt360@bulltick.com</a>',
    },
    layout: "Disposición",
    show_hide_figures: "Mostrar / Ocultar cantidades",
    download: "Descargar",
    print: "Imprimir",
    drag_and_drop: {
      title: "Arrastra y suelta aquí o <u>haz click para cargar</u>",
      max_size: "El tamaño máximo del archivo es 100MB",
      tooltip: "Categorizar los documentos es necesario para poder clasificarlos y guardarlos correctamente en la sección Files. Así como para encontrarlos desde el buscador.",
      category: "Categoría"
    },
    change_user_by_link: {
      title: "Cambio de Perfil",
      body: "En tu última sesión estabas navegando en otro perfil , ¿estás seguro que quieres cambiar de perfil (el que tiene un reporte nuevo) para ver los nuevos reportes?",
      accept: "Aceptar",
      cancel: "Cancelar"
    }

  },
  en: {
    welcome: "Welcome",
    enter_email: "In order to login, please enter your email below",
    email: "Email",
    app_required: "The bt360 app is required for login authorization.",
    sure_signed: "Please make sure you are signed into the app in order to enter successfully.",
    open: "Open",
    delete: "Delete",
    pin: "PIN",
    next: "Next",
    phone_number: "Phone Number",
    phone_number_confirmation: "Phone confirmation",
    password: "Password",
    old_password: "Current password",
    password_confirmation: "Password confirmation",
    password_requirements_texts: [
      "Should be at least 8 characters long",
      "Must contain 1 Uppercase, 1 Lowercase, 1 Number, and 1 Special Character",
      "Must only use the following special characters: @ # * ( ) + { } / ? ~ : , . - _",
      "No more than three repeated letter/digit/special character",
    ],
    pin_requirements_texts: ["Should be at least 6 numbers long"],
    login: "Login",
    forgot_password: "Forgot your password?",
    forgot_password_email: "Forgot your email and password?",
    forgot_email: "Forgot your email?",
    email_not_valid: "Email not valid",
    biometric_identify: "Identify with touchID / FaceID",
    authentication_required: "Authentication required",
    login_failed: "Email or password incorrect",
    failed_attempts: "You have %{count} more tries",
    account_blocked: "Account Locked",
    account_blocked_title:
      "For security measures your account has been locked.",
    account_blocked_subtitle:
      "Click the link below and follow the steps to unlock your account.",
    account_blocked_subtitle_uniken: "Please contact the administrator.",  
    account_blocked_button: "Recover Account",
    account_blocked_code_title: "Change Password",
    account_blocked_code_subtititle:
      "Enter the code we sent to your number ending in ",
    account_unblocked: "Account Unlocked",
    account_unblocked_title:
      "Your account has been unlocked and your password changed successfully.",
    send: "Send",
    send_email: "Send Email",
    try_again: "Try again",
    ok: "Ok",
    back: "Back",
    recovery_email: "Recover Email",
    recovery_email_subtitle:
      "Enter your Phone Number and Password and we’ll send you a link to reset your email.",
    recovery_email_password: "Recover Email and Password",
    recovery_email_password_questions: "Security Questions",
    recovery_email_password_subtitle:
      "Enter your Phone Number and PIN to start the recovery process",
    recovery_email_password_subtitle_questions:
      "Please answer your security questions to proceed",
    recovery_password: "Recover Password",
    recovery_password_subtitle:
      "Enter your email and we’ll send you a link to reset your password.",
    recovery_confirmation_title: "Recuperar Contraseña",
    recovery_confirmation_description:
      "Recibirás un email con las instrucciones.",
    password_match: "Password matches",
    password_not_match: "Password doesn't match",
    pin_match: "Pin number matches",
    pin_not_match: "Pin number doesn't match",
    remember_me: "Remember me",
    loading: "Loading...",
    security_question: "Security Question",
    answer: "Answer",
    confirmation: "Confirmation",
    sms_code: "SMS Code",
    send_code: "Send Code",
    pin_code: "PIN Code",
    resend: "Resend",
    resent: "Resent",
    old_code_mismatch: "Old code mismatch",
    question: "Question",
    new_password: "New password",
    new_password_confirmation: "Confirm new password",
    recovery_code_title: "Let’s double-check that it’s really you.",
    recovery_code_info: "We want to keep your information safe.",
    recovery_code_send_info: "We’ll send you a code to confirm your identity.",
    recovery_code_send_confirmation: "We’ll send you a code to XXX-XXX-",
    add_image: "Add Image",
    cancel: "Cancel",
    download_app_text:
      "To improve the user experience with BT360 you have to access using the app in your cellphone",
    download_app_button: "Open Bulltick360 app",
    download_app_question: "Don't have the application?",
    download_app: "Download here:",
    change: "Change",
    old_a: "Current ${element}",
    old_o: "Current ${element}",
    new_a: "New ${element}",
    new_o: "New ${element}",
    confirm: "Confirmation",
    select_label: "Select label",
    history: "History",
    legend: "Legend",
    text_access_restricted_title:
      "Access restricted to the Wealth and Asset Management Area",
    text_access_restricted: `<p>Sorry, but access to this section is only available to Bulltick Wealth and Asset Management clients.</p>
    <p style="margin-top: 10px">Through this section you will have access to the <strong style="color: #005774">bt360</strong> consolidation platform, where you can fully manage your entire net worth, create and visualize your ownership structure, and link all kinds of documents, contacts and photos. All in a safe and simple way.</p>
    <p style="margin-top: 10px">
    If you want more information about what the Bulltick <strong style="color: #005774">bt360</strong> platform offers, contact your advisor or contact us at<strong style="color: #80abba"> bt360@bulltick.com.</strong></p>
    <p style="margin-top: 10px">Sincerely.</p>`,
    title_linked_assets: "Current linked",
    text_assets_select: "has been selected. Feel free to add any other Assets that may apply.",
    alerts: {
      success: "Success",
      email_sent: "Email Sent",
      email_sent_subtitle:
        "Please check your email for a link to reset your password.",
      email_sent_subtitle_forgot_email: "Please check your email.",
      email_forgot_subtitle: "Reset Password email has been sent successfully.",
      email_forgot_subtitle_check:
        "Please check your email and follow the instructions to reset your password.",
      password_sent_subtitle:
        "Reset Password email has been sent successfully.",
      password_forgot_subtitle_check:
        "Please check your email and follow the instructions to reset your password.",
      email_error_title: "Email does not match",
      email_error_subtitle:
        "The email provided does not match any email in our records.",
      forgot_email: "Email sent to recover your email successfully.",
      forgot_email_subtitle:
        "Please check your email and follow the instructions.",
      forgot_email_error: "Phone Number or Password does not match.",
      forgot_email_subtitle_error:
        "The Phone Number or Password provided does not match any in our records.",
      forgot_email_password: "Email Sent",
      forgot_email_password_subtitle:
        "Please check your email for a link to reset your password.",
      forgot_email_password_error: "Error",
      forgot_email_password_subtitle_error: "Error not found",
      uniken_waiting_title: "Waiting Authorization",
      uniken_waiting_subtitle: "We have sent a notification to your mobile device via the bt360 app. </br></br> Make sure that you are registered in the App with the same user. </br></br>Please approve Access in the App in order to be authorized.",
      uniken_waiting_subtitle_2: "If you clicked Disapprove by mistake, please try again.",
      uniken_not_authorized_title: "Login Attempt Unsuccessful",
      uniken_not_authorized_subtitle: "Login attempt was not approved by user. </br>You do not have access to bt360.",
      uniken_not_authorized_profile_title: "Profile Update Unsuccessful",
      uniken_not_authorized_profile_subtitle: "Profile Update attempt was not approved by user, and the field was not changed. <br/>If you clicked Disaprove by mistake, please try again.",
      add_alert: {
        title: "subscribed you to ALERT:",
        question: "Would you like to receive this alert?",
        footer: "To change alert configurations please go to Alerts Tab",
        reject: "Reject",
        confirm: "Confirm"
      },
      reject_alert: {
        title: "Deleted ALERT:",
        question: "Would you like to keep this alert?",
        footer: "To change alert configurations please go to Alerts Tab",
        delete: "Delete",
        confirm: "Confirm"
      }
    },
    errors: {
      password_requirement:
        "Length should be 8-70 characters and include: 1 uppercase, 1 lowercase, 1 digit, 1 special character and no more than two repeated letter/digit/special character",
      accepted: "Must be accepted",
      blank: "Can't be blank",
      confirmation: "Doesn't Match %{attribute}",
      no_match: "Doesn't Match",
      match: "Match",
      empty: "Can't be empty",
      email_not_found: "The email address you have entered is either incorrect or not associated to any users. Please try again",
      equal_to: "Must be equal to %{count}",
      mismatch: "Mismatch",
      connection_error_title: "bt360 momentarily lost connection to the server.",
      connection_error_body: "Please refresh your browser and try again. Thank you."

    },
    models: {
      home: "Home",
      custodians: "Custodians",
      accounts: "Accounts",
      fixed_assets: "Fixed Assets",
      financial_assets: "Financial Assets",
      collections: "Collections",
      arts: "Art",
      watches: "Watches",
      vehicles: "Vehicles",
      real_estates: "Real Estates",
      real_estate: "Real Estate",
      private_equities: "Private Equities",
      private_equity: "Private Equity",
      vehicles_collection: "Vehicles & Collections",
      bank_accounts: "Bank Accounts",
      others: "Others",
      other: "Other",
      art: "Art",
      watch: "Watch",
      vehicle: "Vehicle",
      bank_account: "Bank Account",
      contacts: "Contacts",
      ownership: "Ownership Structure",
      documents: "Files",
      files: "Files",
      profile: "Profile",
      receivable_account: "Account Receivable",
      valuations: "Valuations",
      deferred_assets: "Life Insurance",
      life_insurances: "Life Insurance",
      liabilities: "Liabilities",
      settings: "Settings",
      balance: "Consolidated Net Worth",
      content_manager: "Content Manager",
      tenant_document: "Documents",
      research: "Bulltick Investment Strategy",
      products_services: "Services & Products",
      financial_statements: "Financial Statements",
      alerts: "Alerts"  
    },
    update_warning: "NOTICE: There has been an update in <b>bt360</b> to improve the security and experience of our customers.<br/> From now on, it is necessary to have downloaded the new version of the App (<a target='_blank' class='c-warning-banner__link' href='https://apps.apple.com/us/app/bulltick-360/id1473561665'>Apple Store</a> or <a target='_blank' class='c-warning-banner__link' href='https://play.google.com/store/apps/details?id=com.bulltick360'>Google Play</a>) and be signed in there in order to access the web version of <b>bt360</b>.",
    action: 'Action',
    security_measures: {
      title: 'Security Measures',
      subtitle: 'At Bulltick, we have a strong commitment to meeting the security and privacy needs of our  clients and are determined to not compromise their customer experience:',
      list: {
        coding: '<strong>Coding / Encryption:</strong> Same security as the top financial institutions (point-to-point encryption, 256 bits-AES).',
        registration_access: '<strong>Registration and Access Security Platform:</strong> Every time bt360 is accessed, the App performs a Security Check on the device, the network from which the connection is made and the App itself',
        access: '<strong>Access:</strong> via biometric credentials (Face ID or Touch ID) or Security PIN',
        registration: '<strong>Registration:</strong> via 2FA (Two Factor Authentication).',
        servers: '<strong>Servers:</strong> Data and Platform hosted in the high security cloud centers of Microsoft Azure and AWS (Amazon Web Services).',
        websites: '<strong>Website:</strong> SSL Certificate, standard security technology that allows encrypted communications between a browser and a web server.',
        blur_mode: '<strong>Blur Mode:</strong> Users have the possibility of hiding all dollar values ($) across the entire platform (only app version).',
        inactivity: '<strong>Inactivity Timeout:</strong> For security reasons, after a period of inactivity, the platform automatically closes.',
        third_party: '<strong>Third Party Trusted Access:</strong> Primary user is the one who gives or removes access to trusted third parties.',
        password: '<strong>Password Periodic Reset:</strong> The platform requires a password update every certain time, without being able to reuse any previously used passwords. It must be a strong password with at  least 8 characters, a capital letter, a number and a symbol.',
      }
    },
    general_recomendations: {
      footer_title: "General Recomendations",
      title: "General Security Recomendations",
      subtitle: "In order to ensure maximum security, Bulltick encourages you to follow these general security recommendations:",
      secure_connection: "Secure Connection",
      receiving_email: "Receiving an E-mail",
      using_your_device: "Using your device",
      list: {
        log_in: "Log in to any platform from your own device and from a secure and private wireless (WiFi) networks",
        not_share: "Do not share with anyone your passwords or any other personal access codes",
        secure_password: "Secure your passwords with at  least 8 characters, a capital letter, a number and a symbol",
        different_passwords: "Use different passwords for the different platforms and internet sites you sign in",
        clear_cache: "Clear your browser’s cache after each online session",
        dont_open_reply: "Do not open or reply to an e-mail from an unknown or suspicious sender. In case of doubts, delete the message",
        dont_open_email: "Do not open an e-mail attachment from an unknown or suspicious sender. In case of doubts, delete the message",
        delete_junk: "Delete junk or chain e-mails",
        update: "Update your operating system and other programs on your computer regularly",
        update: "Update your operating system and other programs on your computer regularly",
        install: "Install the usual security software on your device, such as anti-virus, firewall and anti-spam programs",
        remove: "Remove file and printer sharing options, especially on computers connected to the Internet",
        consider: "Consider using encryption technology to protect highly sensitive or confidential information",
        dont_install_software: "Do not install software or run programs from unknown sources",
        dont_disclose: "Do not disclose personal or financial information to little-known or suspicious websites",
        use_trusted_computers: "Use trusted computer or a devices",
      },
      additional_info: 'For additional information please do not hesitate to contact us at <a href="mailto:bt360@bulltick.com">bt360@bulltick.com</a>',
    },
    layout: "Layout",
    show_hide_figures: "Show / Hide figures",
    download: "Download",
    print: "Print",
    drag_and_drop: {
      title: "Drag and drop here or <u>click to upload</u>",
      max_size: "The maximum file size is 100MB",
      tooltip: "Categorizing documents is necessary to be able to classify and save them correctly in the Files section. As well as to find them from the search engine.",
      category: "Category"
    },
    change_user_by_link: {
      title: 'Change Profile',
      body: "In your last session you were browsing in another profile, are you sure you want to change profile to see the new reports?",
      accept: "Accept",
      cancel: "Cancel"
    }
  },
};

const SESS_POLL_INTERVAL = 60000;
const SESS_EXPIRATION_MINUTES = 10;
const SESS_EXPIRATION_MINUTES_ADMIN = 30;
const SESS_WARNING_MINUTES = 8;
const SESS_WARNING_MINUTES_ADMIN = 28;

export {
  API_TOKEN,
  APP_VERSION,
  HOST_URL,
  HOST_URL_WEB,
  ENVIRONMENT_VAR,
  SENTRY_TRACKING,
  DETAIL_TYPE_ONE_CODES,
  URL_VERSION,
  OWNERSHIP_INDEX,
  BALANCE_VALUATIONS,
  TYPE_BUY,
  TYPE_DEPOSIT,
  TYPE_SELL,
  TYPE_SHELL_SHORT,
  TYPE_WITHDRAWAL,
  TYPE_ASSET_CREATED,
  TYPE_ASSET_DELETED,
  TYPE_VALUE_PLUS,
  TYPE_VALUE_MINUS,
  internalization,
  PASSWORD_CHANGE_REQUIRED,
  TYPE_OF_ASSETS,
  TYPE_OF_OWNERSHIP,
  GOOGLE_ANALYTICS_ID,
  CAROUSEL_ENABLED_CODES,
  NOT_PASSIVES,
  SESS_POLL_INTERVAL,
  SESS_EXPIRATION_MINUTES,
  SESS_WARNING_MINUTES,
  UNAUTHORIZED_REQUEST,
  UNIKEN_ENABLED,
  SESS_EXPIRATION_MINUTES_ADMIN,
  SESS_WARNING_MINUTES_ADMIN
};
