// Dependencies
import React from "react";

// Helpers
import { navigateTo } from "../helpers/navigateTo";
import Icon from "./icons";

let scrollLeft = 0;
let scrollTop = 0;

class TableBlock extends React.Component {
  constructor(props) {
    super(props);

    this.wrapperTableRef = React.createRef();

    this.state = {
      firstColumnPosition: 0,
      firstRowPosition: 0,
      cellsWidth: this.props.data.rows.length > 0 ? this.props.data.rows[0].length * 200 + 160 : 0,
      showHandPointer: false,
      showMore: this.props.lengthShowActive,
      rows: this.props.data.rows,
      titles: this.props.data.titles,
      showTooltip: false,
      truncateLines: !this.props.noTruncate,
    };
  }

  componentDidMount() {
    this.props.lengthShowActive && this.props.tableEvolution && this.buttonMoveOut();

    setTimeout(() => {
      this.tableContent &&
        this.tableContent.offsetWidth < this.state.cellsWidth &&
        this.setState({ showHandPointer: true }, () => {
          setTimeout(() => {
            this.setState({ showHandPointer: false });
          }, 5000);
        });
    }, 2000);
    if (
      this.props.detail === true &&
      this.props.data &&
      this.props.data.rows.length > 0 &&
      this.props.data.rows[0].length === 1
    ) {
      this._prepareTableNoBlanks(true);
    }

    this.setCellsHeight();
    this.noTruncateSetHeight();

    this.props.tableEvolution && this.tableHeaderFixed();
  }

  buttonMoveOut() {
    let wtParent = this.wrapperTableRef.parentElement;
    let button = this.wrapperTableRef.querySelector(".btn.primary");
    if(button){
      this.wrapperTableRef.after(button);  
    }
    
  }

  tableHeaderFixed() {
    // Get originals elements
    let wt = this.wrapperTableRef;
    let originalTable = this.tableContent;
    let wtParent = wt.parentElement;

    // Clone  table
    let tableWrapper = this.wrapperTableRef.cloneNode(true);

    // Get cloned elements
    let firstColumn = tableWrapper.querySelector(".first-column");
    let restColumn = firstColumn.querySelector(".rest-column");
    let tableContent = tableWrapper.querySelector(".table-content");

    if (originalTable.offsetHeight > tableWrapper.offsetHeight) {
      // Set style on div parent
      wtParent.style.position = "relative";
      wtParent.style.overflow = "hidden";

      tableWrapper.style.width = `${originalTable.getBoundingClientRect().width}px`;

      // Set style on original wrapperTable
      let wtXPos = wt.getBoundingClientRect().left;

      //  Include false table
      let divParent = document.createElement("div");
      wt.after(divParent);
      divParent.classList.add("wrapper-table-false-parent");
      divParent.width = wt.getBoundingClientRect().width;
      divParent.style.overflow = "hidden";
      divParent.style.left = `${wtXPos}px`;
      divParent.style.width = `${wt.getBoundingClientRect().width}px`;
      divParent.append(tableWrapper);

      // remove unused elements
      firstColumn.removeChild(restColumn);
      tableContent.removeChild(tableContent.lastChild);

      // set tableWrapper styles
      tableWrapper.classList.add("wrapper-table-false");
      tableWrapper.classList.remove("min-height-md");
      tableWrapper.removeChild(tableWrapper.lastChild);

      // Handle table horizontal scroll
      wt.addEventListener("scroll", (e) => {
        if (wt.scrollLeft) {
          tableWrapper.style.left = `-${wt.scrollLeft}px`;
        } else {
          tableWrapper.style.left = 0;
        }
      });

      // Handle window vertical scroll
      const top = originalTable.getBoundingClientRect().top - 12;

      window.onscroll = (e) => {
        if (window.scrollY >= top) {
          let tableWrapperHeight = tableWrapper.offsetHeight === 0 ? 180 : tableWrapper.offsetHeight - 16;
          let limit = wt.offsetHeight + tableWrapperHeight;

          tableWrapper.style.opacity = 1;
          tableWrapper.style.maxHeight = "400px";

          if (window.scrollY > limit) {
            tableWrapper.style.opacity = 0;
            tableWrapper.style.maxHeight = 0;
          } else {
            tableWrapper.style.opacity = 1;
            tableWrapper.style.maxHeight = "400px";
          }
        } else {
          tableWrapper.style.opacity = 0;
          tableWrapper.style.maxHeight = 0;
        }
      };

      // Resize
      let doit;
      window.onresize = function () {
        clearTimeout(doit);
        doit = setTimeout(() => {
          divParent.style.width = `${wt.getBoundingClientRect().width}px`;
          divParent.style.left = `${wt.getBoundingClientRect().left}px`;
          tableWrapper.style.width = `${originalTable.getBoundingClientRect().width}px`;
        }, 10);
      };
    }
  }

  setCellsHeight() {
    let firstColCellsText = document.querySelectorAll(".first-column-cell__text");

    if (firstColCellsText.lenght > 0) {
      [].forEach.call(firstColCellsText, (item) => {
        let firstColCellHeight = item.offsetHeight;

        if (firstColCellHeight > 48) {
          let parent = item.closest(".td");
          let parentId = parent.getAttribute("id").split("-")[3];
          let contentRow = document.querySelectorAll(`#content-row-${parentId} .td`);
          for (let i = 0; i <= contentRow.length - 1; i++) {
            contentRow[i].style.height = `${firstColCellHeight + 8}px`;
          }
          parent.style.height = `${firstColCellHeight + 8}px`;
        }
      });
    }
  }

  noTruncateSetHeight() {
    // if content row is bigger than first column cell
    let table_id = this.props.id_key !== undefined ? "tableContent-"+this.props.id_key : "tableContent"

    let contentCells = [...document.querySelectorAll(`#${table_id} .table-content .each-row`)];
    contentCells.forEach((el, i) => {
      let h = el.getBoundingClientRect().height;
      if (h > 48) {
        let id = el.getAttribute("id").split("-")[2];
        let firstColSibling = document.querySelector(`#${table_id} #first-column-cell-${i}`);
        if(firstColSibling){
          firstColSibling.style.height = `${h}px`;
        }
      }
    });

    // if first column cell is bigger than content row
    let allFirstColCell = [...document.querySelectorAll(`#${table_id} .rest-column > div`)];
    let that = this;
    allFirstColCell.forEach((el, i) => {
      let h = el.getBoundingClientRect().height;
      if (h > 48) {
        // debugger
        console.log("tableid", table_id)
        let firstColSibling = document.querySelector(`#${table_id} #content-row-${i}`);
        if(firstColSibling){
          firstColSibling.style.height = `${h}px`;  
        }
        
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.data.rows !== prevProps.data.rows) {
      let table;
      if (
        this.props.detail === true &&
        this.props.data &&
        this.props.data.rows.length > 0 &&
        this.props.data.rows[0].length === 1
      ) {
        table = this._prepareTableNoBlanks(false);
      } else {
        table = {
          rows: this.props.data.rows,
          titles: this.props.data.titles,
        };
      }

      this.setState({
        rows: table.rows,
        titles: table.titles,
      });
    }
  }

  scrolled(event) {
    if (scrollLeft !== event.target.scrollLeft) {
      // horizontally scrolled

      this.setState({
        firstRowPosition: -event.target.scrollLeft,
      });

      scrollLeft = event.target.scrollLeft;
    }

    if (scrollTop !== event.target.scrollTop) {
      // vertically scrolled

      this.setState({
        firstColumnPosition: -event.target.scrollTop,
      });

      scrollTop = event.target.scrollTop;
    }
  }

  _handleHandPointer() {
    return (
      <Icon
        name="HandPointer"
        noWrapper={true}
        className={this.state.showHandPointer === true && "animated-handPointer"}
      />
    );
  }

  _prepareTableNoBlanks(update) {
    let titles = [];
    let rows = [];

    this.props.data.titles.map((item, index) => {
      let temp = [];

      try {
        this.props.data.rows[index].map((item2, index2) => {
          if (item2.value !== "" && item2.value !== null && item2.value !== undefined) {
            if (temp[index] === undefined) {
              temp[index] = [];
            }

            if (temp[index][index2] === undefined) {
              temp[index][index2] = [];
            }
            temp[index][index2].push(item2);
            rows[index] = [item2];
          }
          //
        });
        if (temp[index] && temp[index].length > 0) titles.push(item);
      } catch (e) {}
    });

    if (update) {
      this._updateTable(titles, rows);
    } else {
      return { titles: titles, rows: rows };
    }
  }

  _updateTable(titles, rows) {
    this.setState({ titles: titles, rows: rows });
    setTimeout(() => {
      this.noTruncateSetHeight();
    }, 100);
  }

  _handleTooltip(e, index) {
    e.stopPropagation();
    let lastIndex = -1;

    if (this.state.showTooltip === false || this.state.showTooltip !== lastIndex) {
      this.setState({ showTooltip: index });
    } else {
      this.setState({ showTooltip: false });
    }

    lastIndex = index;
  }

  _handleActionButton(action) {
    if (action) {
      setTimeout(() => {
        let height = this.tableContent.offsetHeight;
        height += 500;

        window.scroll({ top: height, behavior: "smooth" });
      }, 50);
    }
    this.setState({ showMore: !this.state.showMore });
  }

  _toolsTable(e, index, url, action, id) {
    e.preventDefault();
    e.stopPropagation();

    this.props.showToolsTable(index, url, action, id);
  }

  _actions(item) {
    if (this.props.tableProfileAccess) return;
    if (this.props.onSelect !== undefined) {
      this.props.onSelect(item);
    } else {
      this.props.setLoading && this.props.setLoading(true);
      navigateTo(item, this.props);
    }
  }

  _isPending() {
    let actionsColumn = this.props.data.rows.map((row) => row[2]);
    let findPending = actionsColumn.findIndex((elem) => elem.pending);
    return findPending !== -1 ? true : false;
  }

  _getTitle(){
    if(this.props.language && this.props.data.hasOwnProperty("title_multilanguage")){
      let languageUserAgent = (/^es/).test(this.props.language) ? 'es' : 'en';
      return this.props.data.title_multilanguage[languageUserAgent]
    }else{
      return this.props.data.title  
    }
    
  }

  render() {
    return (
      <React.Fragment key={this.props.data.toString()}>
        <div
          ref={(o) => (this.wrapperTableRef = o)}
          style={{
            overflow: "hidden",
            overflowX: this.props.tableEvolution ? "scroll" : "inherit",
          }}
          className={
            this.state.showMore
              ? this.props.tableEvolution && this.props.lengthShowActive
                ? "wrapper-table min-height-md center"
                : "wrapper-table min-height center"
              : "wrapper-table center"
          }
        >
          <div
            className="table-preheader__wrapper"
            style={{
              overflow: "hidden",
              marginTop: this.props.tableEvolution || this.props.noMargin ? 0 : 40,
            }}
          >
            {/* 
          {this.props.data.preheader && (
            <div className="table-preheader">
              <p dangerouslySetInnerHTML={{ __html: this.props.data.preheader }} ></p>
            </div>
          )}
           */}

            {this.state.rows.length > 0 && (
              <div
                className={`table ${
                  this.props.pieSelectedElement !== undefined && this.props.pieSelectedElement !== -1
                    ? "has-row-selected"
                    : 'null'
                }`}
                ref={(o) => (this.tableContent = o)}
                id={this.props.id_key !== undefined ? "tableContent-"+this.props.id_key : "tableContent"}
                style={{
                  maxWidth: this.props.tableEvolution && "inherit",
                  paddingTop: this.props.tableEvolution && "120px",
                  alignItems: this.state.showMore ? "flex-start" : "center",
                }}
              >
                {/* {this.state.showHandPointer === true && this._handleHandPointer()} */}

                {/* FirstColumn */}
                <div
                  className={
                    this.props.tableEvolution
                      ? "first-column table-height-initial width-s"
                      : "first-column table-height-initial"
                  }
                >
                  <div
                    style={
                      (this.props.data.headers.length === 1 &&
                        this.props.data.headers[0] === "" && {
                          width: "200%",
                          alignItems: "center",
                          borderTopRightRadius: 8,
                          justifyContent: "center",
                        },
                      this.props.tableEvolution && {
                        width: "60px",
                        minWidth: "60px",
                        textAlign: "center",
                        height: "64px",
                      })
                    }
                    className={
                      this.props.tableEvolution ? "first-cell td width-s tds-first-row" : "first-cell td tds-first-row"
                    }
                  >
                    {this._getTitle()}
                  </div>

                  <div className="rest-column" style={{ top: this.state.firstColumnPosition }}>
                    {this.state.titles.map((item1, index1) => {
                      let tdClasses = [this.props.tableEvolution ? "td width-s" : "td"];
                      if (index1 === this.props.pieSelectedElement) {
                        tdClasses.push("is-selected");
                      }
                      item1.font_style !== undefined && tdClasses.push("font-bold");
                      item1.summatory && tdClasses.push("td-summatory");
                      item1.total_return_row && tdClasses.push("td-total_return_row");
                      item1.highlighted && tdClasses.push("td-highlighted");
                      // item1.highlighted_two && tdClasses.push("td-highlighted");
                      item1.url !== undefined && !this.props.tableProfileAccess && tdClasses.push("with-link");
                      item1.tooltip !== undefined && tdClasses.push("tooltip");

                      return item1.url !== undefined ? (
                        <div
                          id={`first-column-cell-${index1}`}
                          key={index1}
                          onClick={() => (!this.props.tableEvolution ? this._actions(item1) : {})}
                          className={tdClasses.join(" ")}
                          style={
                            (item1.background_color && {
                              backgroundColor: item1.background_color,
                            },
                            this.props.tableEvolution && {
                              height: "52px",
                              width: "60px",
                              minWidth: "60px",
                              textAlign: "center",
                            })
                          }
                        >
                          {this.state.truncateLines ? (
                            <span className="text u-text-ellipsis-2">{item1.title}</span>
                          ) : (
                            <span className="text first-column-cell__text">{item1.title}</span>
                          )}

                          {/*{item1.help && item1.help}*/}
                          {item1.tooltip !== undefined && (
                            <span
                              className="wrapper-helper"
                              onClick={(e) => {
                                this._handleTooltip(e, index1);
                              }}
                            >
                              <div className="helper-text">
                                <Icon name="InfoTooltip" noWrapper={true} />
                              </div>
                              {this.state.showTooltip === index1 && (
                                <div className="container-tooltip" onClick={(e) => this._handleTooltip(e, -99)}>
                                  <span className="tooltip-title">{item1.title}</span>
                                  <span className="tooltip-text">{item1.tooltip}</span>
                                </div>
                              )}
                            </span>
                          )}
                        </div>
                      ) : (
                        <div
                          id={`first-column-cell-${index1}`}
                          key={index1}
                          className={tdClasses.join(" ")}
                          style={
                            item1.background_color && {
                              backgroundColor: item1.background_color,
                            }
                          }
                        >
                          {this.state.truncateLines ? (
                            <span className=" text u-text-ellipsis-2">{item1.title}</span>
                          ) : (
                            <span className="text first-column-cell__text"> {item1.title} </span>
                          )}

                          {/*{item1.help && item1.help}*/}
                          {item1.tooltip !== undefined && (
                            <span
                              className="wrapper-helper"
                              onClick={(e) => {
                                this._handleTooltip(e, index1);
                                // this.props.showModal();
                                // this.props.getModalContent(item1.title, item1.tooltip)}
                              }}
                            >
                              <div className="helper-text">
                                <Icon name="InfoTooltip" noWrapper={true} />
                              </div>

                              {this.state.showTooltip === index1 && (
                                <div className="container-tooltip" onClick={(e) => this._handleTooltip(e, -99)}>
                                  <span className="tooltip-title">{item1.title}</span>
                                  <span className="tooltip-text">{item1.tooltip}</span>
                                </div>
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* @FirstColumn */}

                {/* Content */}
                <div className="table-content" style={{ maxWidth: this.props.tableEvolution && "inherit" }}>
                  <div
                    className="wrapper-first-row"
                    style={{
                      position: "relative",
                      height: this.props.tableEvolution && "64px",
                    }}
                  >
                    {this.props.tableEvolution && (
                      <div className="table-tag">
                        <div className="table-tag__item table-tag__item--width-s">
                          {this.props.proposal !== "" && (
                            <div className="title-evolution">
                              <p className="title-evolution__text">{this.props.proposal}</p>
                            </div>
                          )}
                          {this.props.titlesTableEvolution &&
                            this.props.titlesTableEvolution.title_initial_assumed_rate}
                        </div>
                        <div className="table-tag__item table-tag__item--width-m">
                          {this.props.titlesTableEvolution && this.props.titlesTableEvolution.title_actual_performance}
                        </div>
                      </div>
                    )}

                    <div className="first-row" style={{ left: this.state.firstRowPosition }}>
                      {this.props.data.headers.map((item1, index1) => {
                        return (
                          <div
                            key={index1}
                            className={
                              this.props.tableEvolution
                                ? index1 === 0 || index1 === this.props.data.headers.length - 1
                                  ? "td width-xs tds-first-row"
                                  : "td width-s tds-first-row"
                                : "td tds-first-row"
                            }
                            style={{
                              width: this.props.tableEvolution && [0].includes(index1) && "60px",
                              minWidth: this.props.tableEvolution && [0].includes(index1) && "60px",
                              backgroundColor:
                                this.props.tableEvolution &&
                                [1, 2, 3, 4].includes(index1) &&
                                "rgba(118, 157, 199, 0.3)",
                              marginLeft: this.props.tableEvolution && index1 === 5 ? "5px" : 0,
                              fontFamily: this.props.tableEvolution && [7, 8].includes(index1) && "Averta-Regular",
                              height: this.props.tableEvolution && "64px",
                            }}
                          >
                            <p className="title">
                              {item1}
                              {this.props.data.subtitles && this.props.data.subtitles.length > 0 && (
                                <span>{this.props.data.subtitles[index1]}</span>
                              )}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div onScroll={(event) => this.scrolled(event)}>
                    {this.state.rows.map((item1, index1) => {
                      let class_name = "each-row";
                      if (index1 === this.props.pieSelectedElement) {
                        class_name = class_name + " is-selected";
                      }

                      let firstColumnTitle = this.state.titles[index1];

                      return (
                        <>
                          <div
                            key={index1}
                            className={class_name}
                            id={`content-row-${index1}`}
                            style={
                              item1[0].background_color && {
                                backgroundColor: item1[0].background_color,
                              }
                            }
                          >
                            {item1.map((item2, index2) => {
                              let tdClasses = [
                                this.props.tableEvolution
                                  ? item2.title === "eoy_age" || item2.title === "action"
                                    ? "td width-xs"
                                    : "td width-s"
                                  : "td",
                                item2.table_tag_line && "bg-light",
                              ];
                              if (index1 === this.props.pieSelectedElement) {
                                tdClasses.push("is-selected");
                              }
                              item2.font_style && tdClasses.push("font-bold");
                              item2.url !== undefined &&
                                this.props.onSelect !== undefined &&
                                tdClasses.push("with-link");
                              item2.summatory && tdClasses.push("td-summatory");
                              item2.total_return_row && tdClasses.push("td-total_return_row");
                              item2.highlighted && tdClasses.push("td-highlighted");
                              // item2.highlighted_two && tdClasses.push("td-highlighted");
                               
                              return (
                                <>
                                  <div
                                    key={index2}
                                    className={tdClasses.join(" ")}
                                    style={
                                      (item2.color && { color: item2.color },
                                      {
                                        marginLeft:
                                          this.props.tableEvolution && item2.title === "policy_value_actual_performance"
                                            ? "5px"
                                            : 0,
                                        fontSize: item2.title === "index_value_actual_performance" ? "12px" : "14px",
                                        height: this.props.tableEvolution && "52px",
                                        width: item2.title === "eoy_age" && "60px",
                                        minWidth: item2.title === "eoy_age" && "60px",
                                        cursor: item2.url !== undefined && item2.url !== "" && !this.props.tableEvolution ? 'pointer' : 'inherit'
                                      })
                                    }
                                    onClick={() => {
                                      if (item2.url !== undefined && item2.url !== "" && !this.props.tableEvolution) {
                                        this._actions(item2);
                                      }
                                    }}
                                  >
                                    {item2.value === "Create" ? (
                                      <div
                                        className="icon-table-evolution td-action-icon"
                                        onClick={(e) => this._toolsTable(e, index1, item2.url, "create", item2.id)}
                                      >
                                        <Icon name="Close" close={false} width={"8px"} color={"#fff"} />
                                        <div className="td-action-icon__tooltip">
                                          <span className="td-action-icon__tooltip-text">{`Add Actual Performance Year ${this.props.data.titles[index1].title}`}</span>
                                        </div>
                                      </div>
                                    ) : item2.value === "Edit" ? (
                                      <div
                                        className="td-action-icon"
                                        onClick={(e) => this._toolsTable(e, index1, item2.url, "edit", item2.id)}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Icon name="Edit" width={"18px"} height={"18px"} />
                                        <div className="td-action-icon__tooltip">
                                          <span className="td-action-icon__tooltip-text">{`Edit Actual Performance Year ${this.props.data.titles[index1].title}`}</span>
                                        </div>
                                      </div>
                                    ) : this.props.tableEvolution && item2.value === "" && item2.title === "" ? (
                                      <div>
                                        <div>
                                          {item2.current_variation !== undefined && (
                                            <p
                                              key={item2.value + "_" + index2 + "_p2_" + item2.current_variation}
                                              style={{
                                                color:
                                                  "" + item2.current_variation.indexOf("-") != -1
                                                    ? "#f3b01c"
                                                    : "#80abba",
                                              }}
                                            >
                                              {item2.current_variation}
                                            </p>
                                          )}
                                        </div>
                                        <div>
                                          {item2.percent_variation !== undefined && (
                                            <p
                                              key={item2.value + "_" + index2 + "_p1_" + item2.percent_variation}
                                              style={{
                                                color:
                                                  "" + item2.percent_variation.indexOf("-") != -1
                                                    ? "#f3b01c"
                                                    : "#80abba",
                                              }}
                                            >
                                              {item2.percent_variation}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    ) : item2.title === "action_profile" ? (
                                      <div
                                        className={
                                          this._isPending()
                                            ? "tools-invitation"
                                            : "tools-invitation tools-invitation--center"
                                        }
                                      >
                                        <div
                                          className="tools-invitation__icon"
                                          onClick={() => this.props.openEditInvitations(item2)}
                                        >
                                          <Icon name="Edit" width="22px" height="22px" />
                                        </div>
                                        {item2.pending && (
                                          <div
                                            className="tools-invitation__icon"
                                            onClick={() => this.props.openResendInvitation(item2)}
                                          >
                                            <Icon name="Email" width="28px" height="28px" />
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <p className="text u-tet-elis-2">
                                        {(item2.value !== null &&
                                          typeof item2.value !== "boolean" &&
                                          item2.value.toString()) ||
                                          ""}{" "}
                                      </p>
                                    )}
                                  </div>

                                  {index2 === item1.length - 1 && !this.props.tableEvolution && !this.props.tableProfileAccess && (
                                    <div
                                      // key={`${indexRow}-indexRow-custodian`} className={rowCellClasses("last-icon")}
                                      className={tdClasses.join(" ")}
                                      style={{ maxWidth: 48 }}
                                    >
                                      {firstColumnTitle && firstColumnTitle.url && (
                                        <button onClick={() => this._actions(firstColumnTitle)}>
                                          <Icon name="Link" className="c-table-dropdown__icon-eye" color="#005774" />
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                {/* @Content */}
              </div>
            )}
          </div>

          {this.props.footer &&this.props.footer != "" && (
            <span style={{ textAlign: "center", marginBottom: 5, fontWeight: 900 }}>{this.props.footer}</span>
          )}
        </div>
        {this.props.lengthShowActive && (
          <button
            ref={this.props.refButton}
            className="btn primary"
            onClick={() => this._handleActionButton(this.state.showMore)}
            style={{ marginBottom: 24 }}
            id="tableButton"
          >
            <span className="btn-text" style={{ padding: "20px 0" }}>
              {this.state.showMore ? "Show more" : "Show less"}
            </span>
          </button>
        )}
      </React.Fragment>
    );
  }
}

export default TableBlock;
