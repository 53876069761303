// Dependencies
import React from 'react';

const DocWordSquare = ({ width, height, color, bgColor }) => {

  return (

    <svg
      width={width ? width : '29px'}
      height={height ? height : '29px'}
      viewBox="0 0 29 29"
    >

        <g id="Files-Document_v0.1-(mobile-App)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Desktop-HD" transform="translate(-863.000000, -341.000000)" fill={color ? color : "#80abba"} fillRule="nonzero">
                <g id="01_A_Consolited_Balance-Copy-17" transform="translate(525.000000, 186.000000)">
                    <g id="screen">
                        <g transform="translate(7.000000, 5.000000)" id="Bulltick_UI">
                            <g>
                                <g id="Title" transform="translate(17.000000, 132.000000)">
                                    <g id="doc" transform="translate(314.000000, 18.000000)">
                                        <path d="M25.7777778,0 L3.22222222,0 C1.45,0 0,1.45 0,3.22222222 L0,25.7777778 C0,27.55 1.45,29 3.22222222,29 L25.7777778,29 C27.55,29 29,27.55 29,25.7777778 L29,3.22222222 C29,1.45 27.55,0 25.7777778,0 Z M20.1388889,22.5555556 L17.7222222,22.5555556 L14.5,10.4722222 L11.2777778,22.5555556 L8.86111111,22.5555556 L4.99444444,6.44444444 L7.73333333,6.44444444 L10.15,18.5277778 L13.3722222,6.44444444 L15.6277778,6.44444444 L18.85,18.5277778 L21.2666667,6.44444444 L24.0055556,6.44444444 L20.1388889,22.5555556 Z" id="Shape"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>

  );

}

export default DocWordSquare;
