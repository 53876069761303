// Dependencies
import React, { useState, useEffect } from "react";
import { VictoryPie, VictoryLabel } from "victory";

// Helpers
import { navigateTo } from "../helpers/navigateTo";

// Components
import Icon from "../components/icons";
import pieGraphick_fake from '../assets/images/pieGraphick_fake.png'

function PieGraphic(props) {

  const [colorsArr, setColorsArr] = useState("");
  const [color, setColor] = useState("");
  const [text, setText] = useState([
    props.data.data.length > 0 && props.data.data[0].x.length > 25
      ? props.data.data[0].x.slice(0, 22) + "..."
      : props.data.data && props.data.data.filter(item => item.y > 0)[0].x,
    props.data.data.length > 0 && props.data.data[0].value.length > 25
      ? props.data.data[0].value.slice(0, 22) + "..."
      :  props.data.data && props.data.data.filter(item => item.y > 0)[0].value
  ]);

  useEffect(() => {
    
    let colors =
    props.data.data.filter(item => item.y > 0).map(data => data.color);
   
    setColorsArr(colors);
    
    setColor(colors[0]);
  }, [props.colorScale]);

  const _collapse = event => {
    let element = event.currentTarget.parentElement.children[1];

    if (element !== undefined) {
      if (element.classList.contains("flex")) {
        element.classList.remove("flex");
        element.classList.add("hide");
        event.currentTarget.children[0].classList.remove("rotate");
      } else {
        element.classList.remove("hide");
        element.classList.add("flex");
        event.currentTarget.children[0].classList.add("rotate");
      }
    }
  };

  return (
    <div className="wrapper-pieGraphic">

            {/*<div className="content-fake">
                <div className="content-fake__icon">
                    <Icon name="Lock" color="#005774"/>
                    <p className="headingH4">Not granted</p>
                </div>
                <img src={pieGraphick_fake} className="content-fake__image"/>
            </div>*/}






 
      {props.showTotal === true && (
        <span className={`text-total  ${ ["Liabilities", "Pasivos"].includes(props.data.name) ? "orange" : "blue" }`} >
          {props.data.total}
        </span>
      )}

      {/* {props.data.title && (
        <span className="headingH2">{props.data.title}</span>
      )} */}
      <div className="wrapper-pieGraphic-content">
        <div className={props.from === "balance-index" ? "container-pieGraphic min-height" : "container-pieGraphic"}>
          <div className="wrapper-svg-pieGraphic">
            <svg viewBox={"0 0 310 310"} width={310} height={310}>
              <VictoryPie
                key={""+props.data.title + "_"+ props.data.total}
                events={[
                  {
                    target: "data",
                    eventHandlers: {
                      onClick: (event, props) => {
                        setText([
                          props.datum.x.length > 25
                            ? props.datum.x.slice(0, 22) + "..."
                            : props.datum.x,
                          props.datum.value.length > 25
                            ? props.datum.value.slice(0, 22) + "..."
                            : `${props.datum.value}`
                        ]);

                        setColor(props.datum.color);
                      }
                    }
                  }
                ]}
          
                standalone={false}
                innerRadius={props.innerRadius}
                data={props.data.data.length > 0 && props.data.data.filter(e => e.y > 0)}
                labels={d => (d.y > 1 ? `${d.y}%` : "")}
                colorScale={colorsArr === "" ? [] : colorsArr}
                width={310}
                height={310}
               
                style={{
                  labels: {
                    fill: d => colorsArr[d._x - 1],
                    fontSize: colorsArr.length > 14 ? 11 : 15
                  }
                }}
                labelComponent={<VictoryLabel angle={-45} />}
                padAngle={props.centerLabel === true ? 2 : 0}
              />

              {props.centerLabel && (
                <VictoryLabel
                  key={"labels_"+props.data.title + "_"+ props.data.total}
                  textAnchor="middle"
                  verticalAnchor="middle"
                  style={{
                    fontFamily: "Averta-Regular",
                    fontSize: 14,
                    fill: color
                  }}
                  lineHeight={1.5}
                  x={155}
                  y={155}
                  text={text}
                />
              )}
            </svg>
          </div>
          {props.from === "balance-index" && (
            <div className="wrapper-pieGraphic-preLegend">
              {props.data.data.length > 0 &&
                props.data.data.map((item, index) => {
                  return (
                    <div className="alone-item" key={index}>
                      <div
                        className="legend-item-color"
                        style={{ backgroundColor: item.color }}
                      />
                      <span className="$title2 truncate">{item.x}</span>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        <div className="wrapper-pieGraphic-legend">
          {props.data.data.length > 0 &&
            props.data.data.filter((it) => it.y > 0).map((item, index) => {
              return (
                <div className="wrapper-pieGraphic-legend-item" key={index}>
                  <div className="legend-text">
                    <div
                      className="wrapper-main-legend-text"
                      onClick={event => _collapse(event)}
                    >
                      {item.breakdown !== undefined && (
                        <Icon
                          name="SingleArrow"
                          orientation="down"
                          iconSmall={true}
                        />
                      )}
                      {props.from === "balance-show" && (
                        <div
                          className="legend-item-color"
                          style={{ backgroundColor: item.color }}
                        />
                      )}

                      <div
                        className="main-legend-text"
                        style={{
                          paddingLeft:
                            item.breakdown !== undefined
                              ? 0
                              : props.from ===
                                "balance-show"
                              ? 0
                              : 24
                        }}
                      >
                        <span className="title2 truncate">{item.x}</span>
                        <span
                          className="body blue"
                          style={{ color: item.color }}
                        >
                          {item.value}
                        </span>
                      </div>
                    </div>
                    {item.breakdown !== undefined && (
                      <div className="wrapper-main-legend-list hide">
                        <div className="subcategories-wrapper">
                          {item.breakdown.map((item2, index) => (
                            <span key={index} className="truncate">
                              {" "}
                              {item2.subcategory}{" "}
                            </span>
                          ))}
                        </div>
                        <div className="quantities-wrapper">
                          {item.breakdown.map((item2, index) => (
                            <span key={index}> {item2.quantity} </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  {item.url !== undefined ? (
                    <button onClick={() => navigateTo(item, props)}>
                      <Icon name="ChevronArrow" orientation="right" />
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
        </div>
      </div>

    </div>
  );
}

export default PieGraphic;
