import React, { useState, useRef, useEffect } from "react";
import TextEllipsis from "react-text-ellipsis";
import classNames from "classnames";

import TableDropdownSearcherOwnership from "./TableDropdownSearcherOwnership";

//Helpers
import Icon from "./icons";
import { hasBasename } from "history/PathUtils";

let rowIndex = 0;

const TableDropdownOwnership = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [widthHeaderCell, setWidthHeaderCell] = useState(182);
  const [dataTable, setDataTable] = useState(props.dataTable);
  const [isSearchFilterd, setIsSeachFilterd] = useState(false);

  const headerCell = useRef();
  const tableRef = useRef();
  const rowRef = useRef([]);

  useEffect(() => {
    if (headerCell.current) {
      setWidthHeaderCell(headerCell.current.offsetWidth);
    }
    window.addEventListener("resize", () => {
      if (headerCell.current) {
        setWidthHeaderCell(headerCell.current.offsetWidth);
      }
    });

    tableRef.current.style.opacity = 1;

    console.log("TABLE DATA", dataTable);
  }, []);

  useEffect(() => {
    tableClasses();
    console.log("dataTable", dataTable);
  }, [props.selectedIndex]);

  const tableClasses = () => {
    let cl = classNames("c-table-dropdown", props.selectedIndex >= 0 && "has-row-selected");

    return cl;
  };

  const headerCells = (index, isTag) => {
    let cl = classNames(
      "c-table-dropdown__head-cell",
      isTag && "c-table-dropdown__head-cell--tag",
      index === 0 && "c-table-dropdown__head-cell--first"
    );

    return cl;
  };

  const rowClasses = (index, rowName) => {
    let cl = classNames(
      "c-table-dropdown__row",
      isTotals(index) && "c-table-dropdown__row c-table-dropdown__row--totals",
      isHighlighted().includes(index) && `c-table-dropdown__row--main`,
      props.pieSliceSelected === rowName && "is-selected"
    );
    return cl;
  };

  const rowCellClasses = (index) => {
    let cl = classNames(
      "c-table-dropdown__cell",
      index === 0 && "c-table-dropdown__cell--first",
      index === "last-icon" && "c-table-dropdown__cell--last-icon"
    );
    return cl;
  };

  const rowCellTextClasses = (indexRows, indexRow, value) => {
    let cl = classNames(
      "c-table-dropdown__text",
      "u-text-ellipsis-6",
      indexRow === 0 && !isTotals(indexRows)
        ? value.main
          ? "c-table-dropdown__text--left"
          : "c-table-dropdown__text--right"
        : "",
      value.highlighted && "c-table-alternatives__text--highlighted"
    );
    return cl;
  };

  const cellHeaderTextClasses = (tooltip, sort) => {
    let cl = classNames(
      "c-table-dropdown__text",
      "c-table-dropdown__head-cell-text",
      tooltip && "c-table-dropdown__text--tooltip"
    );
    return cl;
  };

  const isTotals = (index) => {
    return (
      dataTable.rows.find((row) => row[0].value === "Total") && dataTable.rows.length - 1 === index
    );
  };

  const isHighlighted = () => {
    let rowIndex = [];

    dataTable.rows.map((rows, indexRows) => {
      rows.map((row) => {
        if (row.main) {
          rowIndex = [...rowIndex, indexRows];
        }
      });
    });

    return rowIndex;
  };

  const handleTooltip = (e, index) => {
    e.stopPropagation();

    if (showTooltip === false || showTooltip !== index) {
      setShowTooltip(index);
    } else {
      setShowTooltip(false);
    }
  };

  const getWidthHeaderCell = () => {
    return {
      width: `${widthHeaderCell * 3 - 12}px`,
      right: `${widthHeaderCell}px`,
    };
  };

  const handleArrowClick = (parent) => {
    let parentIndex = parent.dataset.isParent;

    let arrow = parent.querySelector(".c-table-dropdown__icon-arrow");
    arrow.classList.toggle("is-rotate");

    let offspring = [...tableRef.current.querySelectorAll(`[data-has-parent="${parentIndex}"]`)];
    offspring.forEach((item) => {
      item.classList.toggle("is-shown");
    });
  };

  const _navigateToAsset = (row) => {
    props.history.push("balance-detail?url=" + row.link, {
      url: "balance_principal/alternative_assets/",
      id: row.id,
    });
  };

  const navigateTo = (row) => {
    props.history.push("/" + row[0].url);
    // window.location.reload()
  };

  const renderCells = (row, indexRows) => {
    return row.map((value, indexRow) => {
      let [firstRowCell] = row;

      return (
        <>
          <div key={`${indexRow}-indexRow-custodian`} className={rowCellClasses(indexRow)}>
            {indexRow === 0 && !isTotals(indexRows) && value.main && (
              <button
                className="c-table-dropdown__icon-arrow-button"
                onClick={() => handleArrowClick(rowRef.current[indexRows])}
              >
                <Icon
                  name="SingleArrow"
                  orientation="down"
                  className="c-table-dropdown__icon-arrow"
                />
              </button>
            )}

            {indexRow !== 0 && (
              <span className={rowCellTextClasses(indexRows, indexRow, value)}>{value.value}</span>
            )}

            {indexRow === 0 && (
              <span
                onClick={() => (firstRowCell.url ? navigateTo(row) : null)}
                className={rowCellTextClasses(indexRows, indexRow, value)}
                style={firstRowCell.url && { cursor: "pointer" }}
              >
                {value.value}
              </span>
            )}

            {value.iconLink && (
              <div className="c-table-dropdown__icon-link" onClick={() => _navigateToAsset(value)}>
                <Icon name="Link" color={"#005774"} width={"16px"} height={"16px"} />
              </div>
            )}
            {value.tooltip && (
              <span
                className="wrapper-helper no-margin"
                onClick={(e) => handleTooltip(e, `${indexRow}-indexRow-${indexRows}`)}
              >
                <div className="helper-text">
                  <Icon name="InfoTooltip" noWrapper={true} />
                </div>
                {showTooltip === `${indexRow}-indexRow-custodian-${indexRows}` && (
                  <div
                    className="container-tooltip top container-tooltip--no-triangle"
                    onClick={(e) => handleTooltip(e, false)}
                  >
                    <span className="tooltip-title">{value.title}</span>
                    <span className="tooltip-text">{value.tooltip_text}</span>
                  </div>
                )}
              </span>
            )}
          </div>

          {indexRow === row.length - 1 && firstRowCell.url && (
            <div key={`${indexRow}-indexRow-custodian`} className={rowCellClasses("last-icon")}>
              <button onClick={() => navigateTo(row)}>
                <Icon name="Link" className="c-table-dropdown__icon-eye" color="#005774" />
              </button>
            </div>
          )}
        </>
      );
    });
  };

  const closeAllRows = () => {
    let rows = [...tableRef.current.querySelectorAll(".c-table-dropdown__row")];
    rows.forEach((row) => {
      row.removeAttribute("style");
      if (row.classList.contains("is-shown")) {
        row.classList.remove("is-shown");
      }
    });

    let arrows = [...tableRef.current.querySelectorAll(".c-table-dropdown__icon-arrow ")];
    arrows.forEach((arrow) => {
      if (arrow.classList.contains("is-rotate")) {
        arrow.classList.remove("is-rotate");
      }
    });
  };

  useEffect(() => {
    removeArrowFromSingleParent();
  }, [dataTable]);

  const removeArrowFromSingleParent = () => {
    if (tableRef.current) {
      let children = [...tableRef.current.querySelectorAll(`[data-has-parent]`)];
      let childrenIds = children.map((child) => child.getAttribute("data-has-parent"));
      // Remove duplicated ids in childrenIds array
      let childrenIdsArray = [...new Set(childrenIds)].filter(
        (child) => child !== undefined && child !== null
      );

      // Uncollapse children row
      if (isSearchFilterd) {
        children.forEach((child) => child.classList.add("is-shown"));
      }

      let parents = [...tableRef.current.querySelectorAll(`[data-is-parent]`)];

      // Find out main rows without children and remove its arrow
      parents.forEach((parent) => {
        let mainRowCode = parent.getAttribute("data-is-parent");
        console.log(mainRowCode)
        let arrow = parent.querySelector(".c-table-dropdown__icon-arrow-button");
          arrow.style.opacity = 1;
          arrow.style.pointerEvents = "all";
        showAllArrows()
        if (childrenIdsArray.indexOf(mainRowCode) === -1) {
          arrow.style.opacity = 0;
          arrow.style.pointerEvents = "none";
        } else {
          arrow.style.opacity = 1;
          arrow.style.pointerEvents = "all";
          showAllArrows()
        }
      });
    }
  };

  const handleSearchResult = (result, isFiltered) => {
    setIsSeachFilterd(isFiltered);
    closeAllRows();
    setDataTable((prevDataTable) => ({ ...prevDataTable, rows: result }));
  };


  const showAllArrows = () => {
    const arrows = [...tableRef.current.querySelectorAll(".c-table-dropdown__icon-arrow")];
    arrows.forEach((arrow) =>  {
      arrow.style.display = "flex"; 
      arrow.style.visibility = "visible"
    });
  };


  const filterResult = (result) => {
    let rows = [...tableRef.current.querySelectorAll(".c-table-dropdown__row")];
    let parentId = rows.map((row) => row.getAttribute("data-has-parent"));
    let parentIdArray = [...new Set(parentId)].filter(
      (item) => item !== undefined && item !== null
    );
    let mainRows = [...tableRef.current.querySelectorAll("[data-is-parent]")];


    if (parentIdArray.length > 0) {
      mainRows.forEach((mainRow) => {
        let mainRowCode = mainRow.getAttribute("data-is-parent");
        if (parentIdArray.indexOf(mainRowCode) === -1) {
          mainRow.style.display = "none";
        }
      });
    } else {
      // Here we're mapping result to access to showIt key
      result.map((row, index) => {
        if (!row[0].showIt) {
          mainRows[index].style.display = "none";
        } else {
          // hide arrow when main row without children is shown
          let arrow = mainRows[index].querySelector(".c-table-dropdown__icon-arrow");
          arrow.style.display = "none";
        }
      });
    }
  };


  return (
    <>
      {props.search && (
        <TableDropdownSearcherOwnership
          originData={props.dataTable.rows}
          result={(result, isFiltered) => handleSearchResult(result, isFiltered)}
          isDropDown={true}
        />
      )}
      <div className={tableClasses()} ref={tableRef}>
        <div className="c-table-dropdown__head">
          <div className="c-table-dropdown__head-row">
            {dataTable.headerTag && (
              <div
                className="c-table-dropdown__tag"
                style={{
                  width: getWidthHeaderCell().width,
                  right: getWidthHeaderCell().right,
                }}
              >
                <span className="c-table-dropdown__tag__text">
                  {dataTable.extra_data && dataTable.extra_data["total_weighted_return"].title}
                </span>
                <span
                  className="wrapper-helper height-auto no-margin"
                  onClick={(e) => handleTooltip(e, -1)}
                >
                  <div className="helper-text white">
                    <Icon name="InfoTooltip" noWrapper={true} />
                  </div>
                  {showTooltip === -1 && (
                    <div
                      className="container-tooltip top container-tooltip--no-triangle"
                      onClick={(e) => handleTooltip(e, false)}
                    >
                      <span className="tooltip-title">
                        {dataTable.extra_data["total_weighted_return"].title}
                      </span>
                      <span
                        className="tooltip-text"
                        dangerouslySetInnerHTML={{
                          __html: dataTable.extra_data["total_weighted_return"].tooltip_text,
                        }}
                      ></span>
                    </div>
                  )}
                </span>
              </div>
            )}

            {dataTable.headers &&
              dataTable.headers.map((header, indexHead) => {
                let tooltipWidth = header.tooltip ? 40 : 0;
                let sortWidth = header.sort ? 24 : 0;

                return (
                  <div
                    key={`${indexHead}-indexHead-custodian`}
                    className={headerCells(indexHead, header.tag)}
                    style={{
                      paddingLeft: (tooltipWidth + sortWidth) / 2,
                      paddingRight: (tooltipWidth + sortWidth) / 2,
                    }}
                    ref={headerCell}
                  >
                    <div
                      className={cellHeaderTextClasses(header.tooltip)}
                      style={{
                        maxWidth: `calc(100% - (${tooltipWidth}px + ${sortWidth}px))`,
                      }}
                    >
                      <span>{header.title}</span>
                    </div>

                    <div className="c-table__head-actions">
                      {header.tooltip && (
                        <span
                          className="wrapper-helper no-margin"
                          style={{ minWidth: 22, marginLeft: 4 }}
                          onClick={(e) => handleTooltip(e, indexHead)}
                        >
                          <div className="helper-text white">
                            <Icon name="InfoTooltip" noWrapper={true} />
                          </div>
                          {showTooltip === indexHead && (
                            <div
                              className="container-tooltip top container-tooltip--no-triangle"
                              onClick={(e) => handleTooltip(e, false)}
                            >
                              <span className="tooltip-title">{header.title}</span>
                              <span className="tooltip-text">{header.tooltip_text}</span>
                            </div>
                          )}
                        </span>
                      )}

                      {header.sort && (
                        <div className="c-table__head-sort">
                          <div
                            className="c-table__head-sort--up"
                            onClick={(e) => alert("Sorting up...")}
                          >
                            <Icon name="SingleArrow" orientation="up" noWrapper={true} />
                          </div>
                          <div
                            className="c-table__head-sort--down"
                            onClick={(e) => alert("Sorting down...")}
                          >
                            <Icon name="SingleArrow" orientation="down" noWrapper={true} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="c-table-dropdown__body">
          {dataTable.rows.length > 0 &&
            dataTable.rows.map((row, indexRows) => {
              if (isHighlighted().includes(indexRows)) {
                rowIndex = indexRows;
              }
              return (
                <div
                  key={`${indexRows}-indexHead-custodian`}
                  className={rowClasses(indexRows, row[0].value)}
                  ref={(el) => (rowRef.current[indexRows] = el)}
                  data-has-parent={
                    !isHighlighted().includes(indexRows) && !isTotals(indexRows) ? rowIndex : null
                  }
                  data-is-parent={isHighlighted().includes(indexRows) ? indexRows : null}
                >
                  {renderCells(row, indexRows)}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default TableDropdownOwnership;
